import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import Select from 'react-select';
import jsPDF from "jspdf";
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import reactCSS from 'reactcss'
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { imageCellRendererList } from '../../common/validate';
import showSwalDialog from '../../utilities/Modal/swal';
import ErrorResponse from '../../utilities/Error/errorstatus';

const initialState = {
    modalType: "Add",
    columnApi: '',
    gridApi: '',
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true,
    },
    tabposition: 'roomype',
    columnDefs: [
        { headerName: '#', valueGetter: "node.rowIndex+1", width: 100, sortable: false, filter: false, cellStyle: { 'text-align': 'center' }, hide: 'true' },
        {
            headerName: 'Logo', width: 100, field: 'hotellogo', sortable: false, filter: false, "resizable": false,
            cellRenderer: imageCellRendererList,
        },
        { headerName: 'Hotel', field: 'hotelName', width: 250, floatingFilter: true },
        { headerName: 'Floor', field: 'floor', width: 150, cellStyle: { 'text-align': 'left' }, floatingFilter: true },
        { headerName: 'Room Type', field: 'roomtype', width: 200, cellStyle: { 'text-align': 'left' }, floatingFilter: true },
        { headerName: 'Room #', field: 'roomno', width: 150, cellStyle: { 'text-align': 'left' }, floatingFilter: true },
        {
            headerName: 'Auto Deleted', field: 'updatedAt', width: 200, cellStyle: { 'text-align': 'center' },
            cellRenderer: function (params) {
                var date1 = new Date(moment(params.data.updatedAt).tz(params.data.tzone).format(params.data.dFormat))
                var date2 = new Date(moment(new Date()).tz(params.data.tzone).format(params.data.dFormat))
                var difference = date2.getTime() - date1.getTime();
                var TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
                var value = 30 - TotalDays
                if (value > 5) {
                    return value + ' days'
                } else {
                    if (value <= 0) {
                        return `<div style="color:red">` + 'Today' + `</div>`
                    } else {
                        if (value == 1) {
                            return `<div style="color:red">` + value + ' day' + `</div>`
                        } else {
                            return `<div style="color:red">` + value + ' days' + `</div>`
                        }
                    }
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                return '<div class="status_style"><span type="button" class="tag tag-danger" data-action-type="Restore">Restore</span></div>';
            }
        },
    ],
    rowData: [],
    spinner: true

}
class Room extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    onRowClicked = event => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Restore') {
            showSwalDialog('Do you want to recover data?', '', 'Yes', 'No', 'assets/images/restore.png').then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeleteSettingRoom + "/" + event.data._id)
                        .then((res) => {
                            Success((languageData && languageData.ROOM_TYPE ? languageData.ROOM_TYPE : "Room") + (languageData && languageData.RECOVERD_SUCCESSFULLY ? languageData.RECOVERD_SUCCESSFULLY : " recoverd successfully"));
                            axios.get(ImportedURL.API.listTrashSettingRoom)
                                .then((res) => {
                                    this.setState({ rowData: res.data })
                                })
                        }).catch((err) => { console.log(err); });
                }
            })
        }
    }

    componentDidMount() {
        this.setState({ spinner: true })
        axios.get(ImportedURL.API.listTrashSettingRoom)
            .then((res) => {
                this.setState({ rowData: res.data, spinner: false })
            })
    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }
    render() {
        const { fixNavbar, AccountState, RoomtypeState, HotelState } = this.props;
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        const account = this.props.AccountState.account;
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
        }
        var dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY');
        if (this.state.rowData) {
            this.state.rowData.forEach(object => {
                object.dFormat = dFormat;
                object.tzone = tzone;
            });
        }
        const languageData = this.props.AccountState.account.SelectedlanguageData
        return (
            <>
                <div>

                    <div>
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-trash-o mr-2"></i></div>
                                            <h3 className="card-title">{((languageData && languageData.ROOM_SETTINGS) ? languageData.ROOM_SETTINGS : "Room")} Trash List</h3>
                                            <div className="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab">
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                    <label className="form-label">Limit</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                            className='limit_size'
                                                        />
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={this.state.rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        rowDragManaged={true}
                                                        animateRows={true}
                                                        onGridReady={this.onGridReady}
                                                        overlayNoRowsTemplate={"No rows to display"}
                                                    >
                                                    </AgGridReact>
                                                </div>
                                                <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div>
                                                {this.state.spinner ?
                                                    <div className='common_loader_ag_grid'>
                                                        <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                        </Spinner>
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    RoomState: state.settingroom,
    HotelState: state.hotel,
    AccountState: state.account
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

        ListHotel: AC_LIST_HOTEL,

    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Room);

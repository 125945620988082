import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react'
import { Offcanvas, Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import { AC_LIST_GROUP } from '../../actions/groupAction';
import { AC_LIST_USER } from '../../actions/userAction';
import { AC_LIST_ROOM } from '../../actions/roomAction';
import { onErrorImage } from '../../common/validate';

const initialState = {
    modalType: "Add",
    id: "",
    previlegeupdated: false,
    saving: false,
    listRedirect: false,
    initial: true,
    owner: false,
    hotelError: false,
    saving: false,
    spinner: false,
    dateCalendar: '',
    userId: '',
    hotelId: '',
    editValue: false,
    fetchData: false,
    viewData: '',
    starttimeValidateError: false,
    endtimeValidateError: false,
    historyStatusCanvas: false,
    serachOption: false,
    hotel: ''
}

class AddGuest extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        this.props.ListHotel();
        this.props.ListGroup();
        this.props.ListUser();
        this.props.ListRoom();
    }
    setGroup = e => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const { name, value, label } = e;
        this.setState({ group: value });

        this.setState({ 'groupoption': e })
        this.props.handlegrouphotel({ groupoption: e, group: value, groupAll: label == 'All' ? true : false })
        this.props.reset({ group: true })
    }
    handleChange = e => {
        const { name, value, label } = e;
        this.setState({
            [name]: value,
            [name + 'option']: e,
        });
        this.props.handlegrouphotel({ hoteloption: e, hotel: value, hotelAll: label == 'All' ? true : false })
        this.props.reset({ hotel: true })
    }
    SelectHandleChange = e => {
        const { name, value, label } = e;
        if (name === 'category') {
            this.setState({
                [name]: value,
                [name + 'option']: e,
            });
            this.props.handlegrouphotel({ categoryoption: e, category: value, categoryAll: label == 'All' ? true : false })
            this.props.reset({ category: true })
        }
        if (name === 'user') {
            this.setState({
                [name]: value,
                [name + 'option']: e,
            });
            this.props.handlegrouphotel({ useroption: e, user: value, userAll: label == 'All' ? true : false })
        }
        if (name === 'status') {
            this.setState({
                [name]: value,
                [name + 'option']: e,
            });
            this.props.handlegrouphotel({ statusoption: e, status: value, statusAll: label == 'All' ? true : false })
        }
        if (name === 'priority') {
            this.setState({
                [name]: value,
                [name + 'option']: e,
            });
            this.props.handlegrouphotel({ priorityoption: e, priority: value, priorityAll: label == 'All' ? true : false })
        }
        if (name === 'roomno') {
            this.setState({
                [name]: value,
                [name + 'option']: e,
            });
            this.props.handlegrouphotel({ roomnooption: e, roomno: value, roomnoAll: label == 'All' ? true : false })
        }
    }
    refreshtype = e => {
        this.props.selfreset(e)
    }
    submit = e => {
        this.props.save({ hotel: this.state.hotel, group: this.state.group, groupoption: this.state.groupoption, hoteloption: this.state.hoteloption })
    }

    render() {
        const { HotelState, AccountState, GroupState, UserState, RoomState } = this.props;

        const languageData = AccountState.account.SelectedlanguageData
        const account = AccountState.account
        const listHotels = HotelState.listhotels;
        const listGroup = GroupState.listgroups;

        const { user, category, hotel, group, status, priority, roomno } = this.props.temp
        var all = false
        if (user || category || hotel || status || priority || roomno || group) {
            all = true
        }
        // -------------------------group--start-------------------------
        const groupOptions = []
        let selectedgroup = ""
        if (listGroup.length > 0) {
            listGroup && listGroup?.filter(filterItem => filterItem.status === true).map(item => {
                groupOptions.push({ label: item.name, value: item._id, name: 'group' });
                if (group == item._id) selectedgroup = { label: item.name, value: item._id, name: 'group' }
            })
        }
        // -------------------------group-end-----------------------------
        // -------------------------hotel start---------------------------
        var hotelOptions = [];
        let selectedhotel = ''
        if (listHotels.length > 0) {
            listHotels.filter(filterItem => filterItem.status === true).map(item => {
                if (group) {
                    if (item.group._id == group) {
                        if (ImportedURL.SUPERADMIN.includes(account.role)) {
                            hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
                            if (hotel == item._id) selectedhotel = { label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' }
                        } else {
                            hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item.hotelid, logo: item.logo, name: 'hotel' });
                            if (hotel == item._id) selectedhotel = { label: `${item.name} ${item.city} ${item.state}`, value: item.hotelid, logo: item.logo, name: 'hotel' }
                        }
                    }
                } else {
                    if (ImportedURL.SUPERADMIN.includes(account.role)) {
                        hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
                        if (hotel == item._id) selectedhotel = { label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' }
                    } else {
                        hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item.hotelid, logo: item.logo, name: 'hotel' });
                        if (hotel == item._id) selectedhotel = { label: `${item.name} ${item.city} ${item.state}`, value: item.hotelid, logo: item.logo, name: 'hotel' }
                    }
                }
            })
        }
        // -------------------------hotel end-----------------------------
        // -------------category
        const UserData = UserState.listusers;
        const CategoryArray = [];
        let selectedcategory = ''
        if (ImportedURL.SUPERADMIN.includes(account.role)) {
            if (hotel) {
                UserData.filter(filterItem => filterItem.status === true).map((item, i) => {
                    if (hotel == item.hotelid) {
                        CategoryArray.push(
                            item
                        )
                        if (category == item.categoryid) selectedcategory = { value: item.categoryid, label: item.category, name: 'category' }
                    }
                })
            }
        } else {
            UserData.filter(filterItem => filterItem.status === true).map((item, i) => {
                if (account.hotel == item.hotelid) {
                    CategoryArray.push(
                        item
                    )
                    if (category == item.categoryid) selectedcategory = { value: item.categoryid, label: item.category, name: 'category' }
                }
            })
        }
        const uniqueIds = [];
        const uniqueCategory = CategoryArray.filter(element => {
            const isDuplicate = uniqueIds.includes(element.category);
            if (!isDuplicate) {
                uniqueIds.push(element.category);
                return true;
            }
            return false;
        });
        if (uniqueCategory && uniqueCategory.length > 0) {
            uniqueCategory.sort((a, b) => a.categorysort - b.categorysort)
        }
        // ------------------------user
        const UserArray = [];
        let selecteduser = ''
        if (category) {
            UserData.filter(filterItem => filterItem.status === true).map((item, i) => {
                if (hotel == item.hotelid || account.hotel == item.hotelid) {
                    if (category == item.categoryid) {
                        UserArray.push(
                            item
                        )
                        if (user && user == item._id) selecteduser = { value: item._id, label: item.name, name: 'user' }
                    }
                }
            })
        } else {
            UserData.filter(filterItem => filterItem.status === true).map((item, i) => {
                if (hotel == item.hotelid || account.hotel == item.hotelid) {
                    UserArray.push(
                        item
                    )
                    if (user && user == item._id) selecteduser = { value: item._id, label: item.name, name: 'user' }
                }
            })
        }
        // if (UserArray.length > 1) {
        //     UserArray.splice(0, 0, { _id: '', name: 'All' });
        // }
        const unique = [];
        const uniqueUser = UserArray.filter(element => {
            const isDuplicate = unique.includes(element._id);
            if (!isDuplicate) {
                unique.push(element._id);
                return true;
            }
            return false;
        });
        // ---------------------------------status-----------------
        const progressStatus = [
            // { value: '', label: 'All', name: 'status' },
            { value: 'open', label: 'Open', name: 'status' },
            { value: 'assigned', label: 'Assigned', name: 'status' },
            { value: 'inprogress', label: 'Inprogress', name: 'status' },
            { value: 'inreview', label: 'Inreview', name: 'status' },
            { value: 'completed', label: 'Completed', name: 'status' },
            { value: 'isDeleted', label: 'Deleted', name: 'status' },
        ]
        let selectedstatus = ""
        progressStatus.map((item, i) => {
            if (status && status == item.value) selectedstatus = item
        })

        const priorityOptions = [
            { value: '1', label: 'High', name: 'priority' },
            { value: '2', label: 'Medium', name: 'priority' },
            { value: '3', label: 'Low', name: 'priority' },
        ]
        let selectedpriority = ""
        priorityOptions.map((item, i) => {
            if (priority && priority == item.value) selectedpriority = item
        })
        // -------------Room---------------------
        const RoomArray = [];
        let selectedroom = ''
        const roomData = RoomState.listrooms;
        RoomArray.splice(0, 0, { room: "Other Place", _id: 'Other Place', label: "Other Place" });
        if (roomData && roomData.length > 0) {
            roomData.filter(filterItem => filterItem.status === true).map((item, i) => {
                if (hotel == item.hotelId || account.hotel == item.hotelId) {
                    RoomArray.push(item)
                    if (roomno) {
                        if (roomno == item._id) {
                            selectedroom = { value: item._id, label: item.roomno, name: 'roomno' }
                        } else if (roomno == "Other Place") {
                            selectedroom = RoomArray.find(o => o._id == roomno);
                        }
                    }
                }
            })
        }
        RoomArray?.sort((a, b) => Number(a.room) - Number(b.room));

        return (
            <>
                <div className='offcanvas_width_changes'>
                    <>
                        <Offcanvas className="offcanvas_width_changes" show={this.props.showCanvas} onHide={this.props.onHide} placement={'end'} onClick={this.closedSearch}>
                            <Offcanvas.Header closeButton>
                                <Offcanvas.Title><i className="fa fa-filter mr-2"></i>Filter {all && <i className="fa fa-refresh ml-2 icon_vendor_singup" name="type" onClick={() => this.refreshtype('all')} />}</Offcanvas.Title>
                            </Offcanvas.Header>
                            <Offcanvas.Body>
                                <div className="row clearfix">
                                    <div className="col-md-12">
                                        <div className="card-body ">
                                            {
                                                (ImportedURL.SUPERADMIN.includes(account.role)) &&
                                                <>
                                                    <div className="pl-0 col-lg-12 col-md-12 col-sm-12 col-xl-12 pr-0">
                                                        <label className="form-label">{languageData && languageData.GROUP ? languageData.GROUP : "Group"}{group ? <i className="fa fa-refresh ml-2 icon_vendor_singup" name="type" onClick={() => this.refreshtype('group')} aria-hidden="true"></i> : ''}</label>
                                                        <div className="form-group">
                                                            <Select
                                                                value={selectedgroup ? { label: selectedgroup.label } : ''}
                                                                onChange={this.setGroup}
                                                                options={groupOptions}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="pl-0 col-xl-12 col-lg-12 col-md-12 col-sm-12 pr-0">
                                                        <label className="form-label">{languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel"}{hotel ? <i className="fa fa-refresh ml-2 icon_vendor_singup" name="type" onClick={() => this.refreshtype('hotel')} aria-hidden="true"></i> : ''} </label>
                                                        <div className="form-group" style={{ marginBottom: '10px' }}>
                                                            <Select
                                                                value={selectedhotel ? { label: selectedhotel.label, logo: selectedhotel.logo } : ''}
                                                                onChange={this.handleChange}
                                                                options={hotelOptions}
                                                                formatOptionLabel={(e) => (
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                        <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                            {hotel || account.hotel ?
                                                <>
                                                    <div className='pl-0 col-xl-12 col-lg-12 col-md-12 col-sm-12 pr-0'>
                                                        <div className='form-group'>
                                                            <label className="form-label">Users Category{category ? <i className="fa fa-refresh ml-2 icon_vendor_singup" name="type" onClick={() => this.refreshtype('category')} aria-hidden="true"></i> : ''}</label>
                                                            <Select
                                                                value={selectedcategory ? { label: selectedcategory.label } : ''}
                                                                onChange={this.SelectHandleChange}
                                                                options={uniqueCategory && uniqueCategory.filter(filterItem => filterItem.status === true).map((item, i) => {
                                                                    return {
                                                                        label: item.category, value: item.categoryid, name: 'category',
                                                                    }
                                                                })} />
                                                            <div className="invalid-feedback" style={{ display: this.state.categoryError ? "block" : 'none' }}>Users Category is Required</div>
                                                        </div>
                                                    </div>
                                                    <div className='pl-0 col-xl-12 col-lg-12 col-md-12 col-sm-12 pr-0'>
                                                        <div className='form-group'>
                                                            <label className="form-label">User Name{user ? <i className="fa fa-refresh ml-2 icon_vendor_singup" name="type" onClick={() => this.refreshtype('user')} aria-hidden="true"></i> : ''}</label>
                                                            <Select
                                                                value={selecteduser ? { label: selecteduser.label } : ''}
                                                                onChange={this.SelectHandleChange}
                                                                options={uniqueUser && uniqueUser.map((item, i) => {
                                                                    return {
                                                                        label: item.name, value: item._id, name: 'user',
                                                                    }
                                                                })} />
                                                            <div className="invalid-feedback" style={{ display: this.state.userError ? "block" : 'none' }}> User Name is required</div>
                                                        </div>
                                                    </div>
                                                    <div className="pl-0 col-xl-12 col-lg-12 col-md-12 col-sm-12 pr-0 ">
                                                        <div className="form-group">
                                                            <label className="form-label">Room #{roomno ? <i className="fa fa-refresh ml-2 icon_vendor_singup" name="type" onClick={() => this.refreshtype('roomno')} aria-hidden="true"></i> : ''}</label>
                                                            <Select
                                                                value={selectedroom ? { label: selectedroom.label } : ''}
                                                                onChange={this.SelectHandleChange}
                                                                options={RoomArray && RoomArray.map((item, i) => {
                                                                    return {
                                                                        label: item.room, value: item._id, name: 'roomno',
                                                                    }
                                                                })} />
                                                        </div>
                                                    </div>
                                                </>
                                                : ''}
                                            <div className="pl-0 col-xl-12 col-lg-12 col-md-12 col-sm-12 pr-0 ">
                                                <label className="form-label">Status{status ? <i className="fa fa-refresh ml-2 icon_vendor_singup" name="type" onClick={() => this.refreshtype('status')} aria-hidden="true"></i> : ''}</label>
                                                <div className="form-group">
                                                    <Select
                                                        value={selectedstatus ? { label: selectedstatus.label } : ''}
                                                        onChange={this.SelectHandleChange}
                                                        options={progressStatus && progressStatus.map((item2, i) => {
                                                            return {
                                                                label: item2.label, value: item2.value, name: 'status'
                                                            }
                                                        })} />
                                                </div>
                                            </div>
                                            <div className="pl-0 col-xl-12 col-lg-12 col-md-12 col-sm-12 pr-0 ">
                                                <label className="form-label">Priority{priority ? <i className="fa fa-refresh ml-2 icon_vendor_singup" name="type" onClick={() => this.refreshtype('priority')} aria-hidden="true"></i> : ''}</label>
                                                <div className="form-group">
                                                    <Select
                                                        value={selectedpriority ? { label: selectedpriority.label } : ''}
                                                        onChange={this.SelectHandleChange}
                                                        options={priorityOptions && priorityOptions.map((item2, i) => {
                                                            return {
                                                                label: item2.label, value: item2.value, name: 'priority'
                                                            }
                                                        })} />
                                                </div>
                                            </div>
                                            <div className="card-footer text-right mandatory" style={{ padding: '20px 0px' }}>
                                                <div className="text-right d-flex">
                                                    {
                                                        this.state.saving ?
                                                            <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Applying</button>
                                                            :
                                                            <button type="submit" className="btn commor_save" onClick={this.submit}>
                                                                <i className="fe fe-save mr-2"></i>Apply
                                                            </button>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </Offcanvas.Body>
                            <div id='overlay-model' style={{ display: (this.props.canvasSpinner) ? 'block' : 'none' }}></div>
                            {(this.props.canvasSpinner) ?
                                <div className='model_loader_poss'>
                                    <img className='loader_img_style_model' src='../../assets/images/load.png' />
                                    <Spinner className='spinner_load_model' animation="border" variant="info" >
                                    </Spinner>
                                </div>
                                : ''}
                        </Offcanvas>
                    </>
                </div>
            </>
        );
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    HotelState: state.hotel,
    AccountState: state.account,
    GroupState: state.group,
    UserState: state.user,
    RoomState: state.room,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ListGroup: AC_LIST_GROUP, ListUser: AC_LIST_USER, ListHotel: AC_LIST_HOTEL, ListRoom: AC_LIST_ROOM, }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AddGuest);
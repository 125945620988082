import React, { Component } from 'react'
import ErrorResponseComponent from '../../../utilities/ErrorStatus/errorresponse';
import { Offcanvas } from 'react-bootstrap';
import ImportedURL from '../../../common/api';
import axios from 'axios';
import { Success, Error } from '../../../common/swal';

class AddTagandMylistModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalType: "Add",
            name: ''
        };
        this.nameInputRef = React.createRef();
    }
    handleChange = e => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const { name, value } = e.target;
        const ErrorName = name + "Error";
        this.setState({ [ErrorName]: false });
        if (value.length <= 50) {
            this.setState({ [name]: value, });
        } else {
            Error(languageData && languageData.MAXIMUM_CHAR_LIMIT_REACHED ? languageData.MAXIMUM_CHAR_LIMIT_REACHED : "Maximum character limit reached")
        }
    }
    resetRefFunction = e => {
        this.setState({ name: '', nameError: false })
    }
    resetRefTagFunction = e => {
        this.setState({ name: '', nameError: false })
    }
    submit = e => {
        const { type, AccountState, title } = this.props
        const data = this.state;
        const languageData = AccountState.account.SelectedlanguageData
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        }
        let valid = 1;
        if (!data.name) {
            valid = 0;
            this.setState({ nameError: true });
            this.nameInputRef.current.focus();
        }
        if (valid) {
            var formData = {
                hotel: this.props.data.hotel ? this.props.data.hotel : ''
            }
            if (type == 'tag') {
                formData['tag'] = data.name
            } else {
                formData['filename'] = data.name
            }
            this.setState({ saving: true });
            const api = type == 'tag' ? 'addTodoTag' : 'addTodoMyList';
            this.setState({ saving: true })
            axios.post(ImportedURL.API[api], formData)
                .then((res) => {
                    this.props.submit()
                    this.setState({ saving: false })
                    Success(title + " added successfully");
                }).catch(({ response }) => {
                    this.setState({ saving: false })
                    ErrorResponseComponent(languageData, response);
                });
        }
    }
    render() {
        const { title, showcanvas, onHidecanvas } = this.props
        return (
            <>
                <Offcanvas className="offcanvas_width_changes" show={showcanvas} onHide={() => onHidecanvas()} placement={'end'} style={{ width: '35%' }}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><h4>{this.state.modalType + " " + title}</h4></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="row">
                            <div className="form-group">
                                <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                <input type='text' style={{ width: "100%" }} ref={this.nameInputRef} className="form-control" name="name" placeholder="Name" onChange={this.handleChange} value={this.state.name} />
                                <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Name is required</div>
                            </div>
                        </div>
                        <div className='position_fixed_cnavas_small_contant'>
                            <div className="card-footer">
                                <div className="canvas_footer_button_todo">
                                    <div className='mr-5'>
                                        <button type="button" className="btn btn-secondary" onClick={() => onHidecanvas()} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Cancel</button>
                                    </div>
                                    <div>
                                        {
                                            this.state.saving ?
                                                <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>save</button>
                                                :
                                                <button type="submit" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        )
    }
}

export default AddTagandMylistModal;
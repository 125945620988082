import React, { useEffect, useState } from 'react';
import { Container, Row } from 'react-bootstrap';
import { BsEmojiSmile } from "react-icons/bs";
import { TbBrandTripadvisor } from "react-icons/tb";
import { BsGoogle } from "react-icons/bs";
import { Link, Redirect } from 'react-router-dom';
import Banner from './banner.png';
import '../../assets/css/job_apply_form.css';
import axios from 'axios';
import ImportedURL from '../../common/api';
import good from '../../assets/images/smile-good.png'
import { Form } from 'react-bootstrap';
import { Success, Error } from '../../common/swal';

export default function Feedback(props) {

  const [hotel, setValue] = useState({});
  const [review, setReview] = useState();
  const [reviewOption, setReviewOption] = useState(false)
  const [sendonce, setSendOnce] = useState(false)
  const [thanksRedirect, setRedirect] = useState(false)
  const [reviewsStatus, setReviewsStatus] = useState(false)
  const [negative, setNegative] = useState(false)
  const [guestStatusArr, setGuestStatusArr] = useState([])
  let barWidth = 0;
  const animate = () => {
    barWidth++;
    if (document.getElementById("bar")) {
      document.getElementById("bar").style.width = `${barWidth}%`;
    }
    setTimeout(() => {
      if (document.getElementById("loading")) {
        document.getElementById("loading").innerHTML = `${barWidth}% Completed`;
      }
    }, 25);
  };
  // animation starts 2 seconds after page load

  setTimeout(() => {
    let intervalID = setInterval(() => {
      if (barWidth === 90) {
        clearInterval(intervalID);
      } else {
        animate();
      }
    }, 10); //this sets the speed of the animation
  }, 500);

  useEffect(() => {
    const queryparams = new URLSearchParams(window.location.search);
    const review = queryparams.get('review');
    axios.get(ImportedURL.API.viewReviews + "/" + review)
      .then((res) => {
        if (res.data != undefined) {
          // setReviewsStatus(res.data.negativefeedbackstatus)
          setNegative(res.data.negativefeedbackstatus)
          setGuestStatusArr(res.data)
        }
      }).catch((err) => { console.log(err); });
    axios.get(ImportedURL.API.getReview + "/" + review)
      .then((res) => {
        setValue(res.data)
      }).catch((err) => { console.log(err); });
  }, []);

  const openTab = (event) => {
    if (event.target.name === 'tripadvisor' && hotel.tripsadvisorurl) {
      window.open(hotel.tripsadvisorurl);
      // window.open('https://www.tripadvisor.com/UserReviewEdit-g55181-d858468-Lx_Hotel_Powered_By_ACW_Circle-Manchester_Tennessee.html');
    }
    if (event.target.name === 'google' && hotel.googlereviewurl) {
      window.open(hotel.googlereviewurl);
      // window.open('https://www.google.com/travel/hotels/lx%20hotel%20manchester%20tn/entity/CgsI2ZW9rt7_o6jdARAB/reviews?q=lx%20hotel%20manchester%20tn&g2lb=2502548%2C2503771%2C2503781%2C4258168%2C4270442%2C4284970%2C4291517%2C4306835%2C4308227%2C4515404%2C4597339%2C4649665%2C4722900%2C4723331%2C4741665%2C4757164%2C4758194%2C4758493%2C4762561%2C4786153%2C4786958%2C4787395%2C4790336%2C4794648%2C4797296%2C4797698&hl=en-IN&gl=in&ssta=1&rp=ENmVva7e_6Oo3QEQ2ZW9rt7_o6jdATgCQABIAcABAg&ictx=1&ved=0CAAQ5JsGahcKEwjg97ffpbT4AhUAAAAAHQAAAAAQAw&utm_campaign=sharing&utm_medium=link&utm_source=htls&ts=CAESCgoCCAMKAggDEAEaSQorEicyJTB4ODg2MTY5MmQ0NGFkN2Y0ZjoweGRkNTA4ZmZkZTVjZjRhZDkaABIaEhQKBwjmDxAGGBoSBwjmDxAGGBsYATICEAAqCQoFOgNJTlIaAA');
    }
    const paramsId = props.location.search.split('=')[1];
    axios.post(ImportedURL.API.reviewUpdate + "/" + paramsId, { complete: event.target.name })
      .then((res) => {
      }).catch((err) => { console.log(err); });
  }
  const feddBackClick = () => {
    setReviewOption(true)
  }
  const paramsId = props.location.search.split('=')[1];

  if (reviewOption) {
    return <Redirect to={'/review/' + paramsId} />
  }
  if (thanksRedirect) {
    return <Redirect to={'/thanks'} />
  }
  const reviewChange = (event) => {
    const { value } = event.target
    setReview(value)
  }
  const sendFeedback = (event) => {
    let data = guestStatusArr;

    let formData = {
      hotel: data.hotel,
      review: review,
      phone: data.phone,
      cc: data.cc,
      name: data.name,
      email: data.email ? data.email : '',
      roomnopms: data.roomno,
      reviewid: data._id,
      rating: 5,
      checkoutdate: data.checkoutdate
    }
    setSendOnce(true)
    axios.post(ImportedURL.API.badGuestFeedback, formData)
      .then((res) => {
        // axios.post(ImportedURL.API.reviewUpdate + "/" + data._id, { negativefeedbackstatus: false })
        // .then((res) => {
        // })
        setRedirect(true)
      }).catch(({ response }) => {
        if (response.status == 500) {
          Error(response.status + (' Internal Server Error'))
        } else if (response.status == 502) {
          Error(response.status + (' Bad Gateway'))
        } else {
          Error(response.statusMessage)
        }
      });
  }


  if (reviewsStatus) {
    return (
      <div>
        <section className='review_sec'>
          <Container>
            <div className='icemote'>
              <div className='img_size'>
                <img src={hotel.hotelcard ? ImportedURL.FILEURL + hotel.hotelcard : Banner} alt='not found' />
              </div>
              <div className='content'>
                <div className='icons_sm'>
                  <div className="order_confirm_img">
                    <img src="../assets/images/negative-status.jpg" />
                  </div>
                </div>
                <p className='help_t already_review'>This reviews are Already reviewed</p>
              </div>
            </div>
          </Container>
        </section>
      </div >
    )
  } else {
    return (
      <div>
        <section className='review_pro_sec'>
          <Container >
            <div className='iprotex'>
              <div className='img_size'>
                <img src={hotel.hotelcard ? ImportedURL.FILEURL + hotel.hotelcard : Banner} alt='not found' />
              </div>
              {guestStatusArr && guestStatusArr.gueststatustype ?
                <div className='content'>
                  <div className='icons_smile'>
                    <img className='img_rev_size_success mb-3' src={good} />
                    <p style={{ color: '#1c3078', fontWeight: '600', fontSize: '25px' }}>Thank You. Please complete your review.</p>
                  </div>
                  <Form>
                    <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                      <Form.Control as="textarea" name="review" onChange={reviewChange} value={review} placeholder='Write your feedback....' rows={5} />
                    </Form.Group>
                  </Form>
                  <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <div>
                      <button type="button" className="btn common_bc" onClick={feddBackClick} style={{ marginTop: "0px" }} id="Add"><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button>
                    </div>
                    <div className='submit_btn'>
                      <button className='btn commor_save' style={!sendonce ? { display: 'block' } : { display: 'none' }} onClick={sendFeedback} type='button'><i class="fe fe-save mr-2"></i>Submit</button>
                    </div>
                  </div>
                </div>
                :
                <div className='content'>
                  <div className='icons_smile'>
                    <img className='img_rev_size_success mb-3' src={good} />
                    {/* <BsEmojiSmile className='smile' /> */}
                    <p style={{ color: '#1c3078', fontWeight: '600', fontSize: '25px' }}>Thank You. Please complete your review.</p>
                  </div>
                  <div className="progress text-center">
                    <div className='percent_text' id="bar" style={{
                      backgroundColor: "#4267b2",
                      width: '10px',
                      height: '50px',
                      position: 'absolute'
                    }}> </div>
                  </div>
                  <p className="text" id="loading" style={{ marginTop: '10px', fontSize: '25px', fontFamily: 'sans-serif' }}></p>
                  <div className='complete_login'>
                    <Row>
                      <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                        <button type='button' onClick={openTab} name="tripadvisor" className='btn btn_1' >Complete with <TbBrandTripadvisor className='tr' /> Tripadvisor</button>
                      </div>
                      <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12 col-12'>
                        <button type='button' onClick={openTab} name="google" className='btn btn_2' >Complete with <BsGoogle className='google' /> Google</button>
                      </div>
                    </Row>
                    {!negative && <div>
                      <button type="button" onClick={feddBackClick} className="btn common_bc" id="Add" style={{ width: '140px' }}><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button>
                    </div>}
                  </div>
                </div>}
            </div>
          </Container>
        </ section>
      </div>
    )

  }
}

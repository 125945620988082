import React, { Component } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import moment from 'moment';
import { onErrorImage } from '../../common/validate';
import ImportedURL from '../../common/api';
import ModalSpinnerComponent from '../../utilities/Spinner/modalspinner';

class ViewReviewModal extends Component {
    getRatings = (rating) => {
        let data = []
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) data.push(<i className="fa fa-star text-orange" key={i}></i>)
            else data.push(<i className="fa fa-star" key={i}></i>)
        }
        return data;
    }
    render() {
        const { state, AccountState, LandingState } = this.props;
        const { modalType, reviewSpin, viewNotificationData } = state;
        const account = AccountState.account;
        const listHotel = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 5 ? LandingState.landingdata.data[0] : [];

        var idHotel = '';
        var listHotels = '';
        if (account?.allhotels != undefined) {
            listHotels = account?.allhotels;
            idHotel = account?.allhotels
        } else {
            listHotels = listHotel;
        }

        var hotelName = ''
        var reviewer_name = viewNotificationData.reviewer_name;
        var title = viewNotificationData.title;
        var text = viewNotificationData.text;
        var reviewer_picture_url = viewNotificationData.reviewer_picture_url;
        var rating = viewNotificationData.rating;
        var published_at = viewNotificationData.published_at;
        var url = viewNotificationData.url;
        var supplier = viewNotificationData.supplier;

        var imageSet = ''
        if (supplier == "hotels") {
            imageSet = '../../assets/images/hotels.png'
        } else if (supplier == "trip-advisor") {
            imageSet = '../../assets/images/tripadviser.png'
        } else if (supplier == "booking") {
            imageSet = '../../assets/images/booking.png'
        } else if (supplier == "google") {
            imageSet = '../../assets/images/google.webp'
        } else if (supplier == "facebook") {
            imageSet = '../../assets/images/fb.svg'
        } else if (supplier == "yelp") {
            imageSet = '../../assets/images/yelp.png'
        } else if (supplier == "expedia") {
            imageSet = '../../assets/images/ex.png'
        }

        var supplierVal = ""
        if (supplier) {
            if (supplier == "hotels" || supplier == "booking") {
                supplierVal = supplier + ".com"
            } else if (supplier == "trip-advisor") {
                supplierVal = "tripadvisor"
            } else {
                supplierVal = supplier
            }
        }

        var hotel = viewNotificationData.hotel;
        if (listHotels) {
            listHotels.map((item) => {
                if (item._id === hotel) {
                    hotelName = item.name + " " + item.city + " " + item.state
                }
            })
        }
        var dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY');
        return (
            <>
                <div className="modal fade" id="latest_view_reviews" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
                    <div className="modal-dialog modal-xl latest_review_modal" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className='rounded_icon'><i className="icon-star mr-2 "></i></div>
                                <h5 className="modal-title" id="exampleModalLongTitle" style={{ marginTop: '5px' }}>{modalType} View Review</h5>
                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <article className="media">
                                    <div className="mr-3">
                                        <img className="avatar avatar-xl" src={reviewer_picture_url ?
                                            reviewer_picture_url : ImportedURL.LOCALURL + 'assets/images/defaultuser.png'}
                                            onError={(e) => { if (e.target.src !== ImportedURL.LOCALURL + "assets/images/defaultuser.png") { e.target.onerror = null; e.target.src = ImportedURL.LOCALURL + "assets/images/defaultuser.png"; } }} alt=""
                                        />

                                    </div>
                                    <div className="media-body">
                                        <div className="content">
                                            <span className="h5"><span className='pr-2 socail_reviews'>{reviewer_name}</span>
                                                {this.getRatings(rating)}
                                                <small className="float-right">{moment(published_at).format(dFormat)}</small>
                                            </span>
                                            <p>

                                                <small className='pr-2'>
                                                    <span className='review_Logo'>
                                                        <a href={url} target='_blank'>
                                                            <img src={imageSet} onError={onErrorImage} />
                                                        </a>
                                                    </span>
                                                    <a href={url} style={{ color: '#1c3078' }} target='_blank'>{supplierVal?.charAt(0).toUpperCase() + supplierVal?.substr(1).toLowerCase()}</a></small>
                                                <small className="float-right">{hotelName}</small>
                                            </p>
                                            <p className='label_black'>{title}</p>
                                            <p className='text' dangerouslySetInnerHTML={{ __html: text }}></p>
                                        </div>
                                    </div>
                                </article>
                            </div>
                            <ModalSpinnerComponent spinner={reviewSpin} />
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default ViewReviewModal;
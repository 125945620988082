import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import moment from 'moment';
import ImportedUrl from '../../common/api';
import { Error } from '../../common/swal';
import ImportedURL from '../../common/api';
import Spinner from 'react-bootstrap/Spinner';
import { AC_LIST_CATEGORY } from '../../actions/categoryAction';
import { bindActionCreators } from 'redux';
import { onErrorImage } from '../../common/validate';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';

class TicketReports extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userTickets: [],
            hotel: '',
            month: '',
            selectedOption: '',
            initial: true,
            spinner: true,
            reportspin: false,
            isFilterShow: false
        }
    }
    componentWillUpdate(nextProps) {
        // const account = this.props.AccountState.account;
        const newaccount = nextProps.AccountState.account;
        if (this.state.initial) {
            if (!ImportedURL.SUPERADMIN.includes(nextProps.AccountState.role)) {
                this.setState({ hotel: newaccount.hotel });
            }
            this.setState({ initial: false })
        }
    }

    handleChange = e => {
        const { name, value } = e;
        this.setState({ reportspin: true, isFilterShow: true })
        const languageData = this.props.AccountState.account.SelectedlanguageData
        axios.post(ImportedUrl.API.ticketReport + "/" + value)
            .then((res) => {
                if (res.data) this.setState({ userTickets: res.data, reportspin: false });
            }).catch(({ response }) => {
                if (response.status == 500) {
                    Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                } else if (response.status == 502) {
                    Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                } else {
                    Error(response.statusMessage)
                }
            })
        this.setState({
            hotel: value,
            selectedOption: e,
            fromDateReport: '', toDateReport: '', month: ''
        });
    }

    getMonthlyReport = e => {
        const { value } = e.target;
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        if (this.state.hotel) {
            this.setState({ isFilterShow: true })
            let hotelId = this.state.hotel;
            const newval = value == 0 ? 0 : value == 1 ? 1 : 2;

            let date = new Date();
            let startDate = new Date(date.getFullYear(), date.getMonth() - newval, 1);
            let endDate = new Date(date.getFullYear(), date.getMonth() - newval + 1, 0);
            const formData = {
                toDate: moment(endDate).format('YYYY-MM-DD') + "T23:59:59",
                fromDate: moment(startDate).format('YYYY-MM-DD') + "T00:00:00"
            }
            this.setState({ reportspin: true })
            axios.post(ImportedUrl.API.ticketReport + '/' + hotelId, formData)
                .then((res) => {
                    if (res.data) {
                        if (res.data) this.setState({ userTickets: res.data, reportspin: false });
                    }
                }).catch((err) => { console.log(err); });
            this.setState({ fromDateReport: moment(startDate).format('yyyy-MM-DD'), toDateReport: moment(endDate).format('yyyy-MM-DD'), month: value });
        } else {
            Error((languageData && languageData.SELECT_A_HOTEL ? languageData.SELECT_A_HOTEL : "Select a hotels"));
        }
    }

    componentDidMount() {
        const { AccountState } = this.props;
        const account = AccountState?.account;
        if (account?.hotel) {
            this.setState({ reportspin: true })
            axios.post(ImportedUrl.API.ticketReport + '/' + account?.hotel)
                .then((res) => {
                    if (res.data) {
                        if (res.data) this.setState({ userTickets: res.data, reportspin: false });
                    }
                }).catch((err) => {
                    this.setState({ reportspin: false })
                    console.log(err);
                });
        }
    }

    setDateReport = (e) => {
        const { name, value } = e.target;

        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        if (this.state.hotel) {
            // this.setState({ isFilterShow: true })
            let hotelId = this.state.hotel;
            if (name == 'fromDateReport') {
                this.setState({ fromDateReport: value, toDateReport: '', month: '' });
                const formData = {
                    fromDate: value ? value + "T00:00:00" : '',
                    toDate: ''
                }
                if (!value) {
                    this.setState({ isFilterShow: false });
                } else {
                    this.setState({ isFilterShow: true });
                }
                axios.post(ImportedUrl.API.ticketReport + '/' + hotelId, formData)
                    .then((res) => {
                        if (res.data) {
                            if (res.data) this.setState({ userTickets: res.data });
                        }
                    }).catch((err) => { console.log(err); });
            }
            if (name == 'toDateReport') {
                this.setState({ toDateReport: value, month: '' });
                const formData = {
                    toDate: value ? value + "T23:59:59" : '',
                    fromDate: this.state.fromDateReport ? this.state.fromDateReport + "T00:00:00" : ''
                }
                if (!this.state.fromDateReport && !value) {
                    this.setState({ isFilterShow: false });
                } else {
                    this.setState({ isFilterShow: true });
                }
                axios.post(ImportedUrl.API.ticketReport + '/' + hotelId, formData)
                    .then((res) => {
                        if (res.data) {
                            if (res.data) this.setState({ userTickets: res.data });
                        }
                    }).catch((err) => { console.log(err); });
            }
        } else {
            Error((languageData && languageData.SELECT_A_HOTEL ? languageData.SELECT_A_HOTEL : "Select a hotels"));
            this.setState({ fromDateReport: '', toDateReport: '' })
        }
    }
    getMonths = (mon) => {
        let date = new Date();
        date.setDate(1);
        date.setMonth(date.getMonth() - mon);
        let monthName = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        return `${monthName}`;
    }
    filterReset = (e) => {
        const account = this.props.AccountState?.account;
        this.setState({
            isFilterShow: false,
            fromDateReport: '',
            toDateReport: '',

            selectedOption: '',
            searchhotel: '',
            fromdate: '',
            todate: '',
            month: '',
            hotelName: '', status: '',
            statusProgress: { label: "All", value: 0 }
        });
        if (ImportedURL.SUPERADMIN.includes(account.role)) {
            this.setState({ hotel: '' })
            this.setState({ userTickets: [] })
        }
        if (!ImportedURL.SUPERADMIN.includes(account.role)) {
            let hotelId = this.state.hotel;
            axios.post(ImportedUrl.API.ticketReport + '/' + hotelId)
                .then((res) => {
                    if (res.data) {
                        if (res.data) this.setState({ userTickets: res.data, reportspin: false });
                    }
                }).catch((err) => { console.log(err); });
        }
    }
    render() {
        const { AccountState, LandingState } = this.props;
        const listCategory = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[7] : [];
        const account = AccountState?.account;
        const languageData = AccountState.account.SelectedlanguageData
        const listHotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[0] : [];
        if (this.state.modalType === 'View') {
            return <Redirect to={'view-ticket/' + this.state.id} />
        }
        var hotelSelect = '';
        if (account.hotel) {
            hotelSelect = listHotels.find(e => e._id === account.hotel)
        } else {
            hotelSelect = this.state.selectedOption
        }
        const hotelOptions = [];

        if (listHotels.length > 0) {
            listHotels.filter(filterItem => filterItem.status === true).map(item => {
                hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
            })
        }
        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/ticket-report"><h6 className="highlights_breadcrump">{languageData && languageData.USER_REPORTS ? languageData.USER_REPORTS : "Users Reports"}</h6></Link></p>
                        </div>
                        <div className="section-body pt-3">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="fa fa-bar-chart mr-2"></i></div>
                                                <h5 className="card-title" id="exampleModalLabel" style={{ marginTop: '5px' }}> {languageData && languageData.USER_REPORTS ? languageData.USER_REPORTS : "Users Reports"}</h5>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    {ImportedURL.SUPERADMIN.includes(account.role) ?
                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-xl-6 mb-3">
                                                            <div className='form-group' style={ImportedURL.SUPERADMIN.includes(account.role) ? {} : { fontSize: "18px", marginBottom: "30px" }}>
                                                                <label className='form-label' style={ImportedURL.SUPERADMIN.includes(account.role) ? {} : { paddingTop: "15px", display: "none" }}>{languageData && languageData.SEARCH ? languageData.SEARCH : "Search"} {languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel"}{ImportedURL.SUPERADMIN.includes(account.role) ? '' : <span className="ml-1" style={{ paddingLeft: "8px", margin: "0px 13px" }}>:</span>}</label>
                                                                <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                    <Select
                                                                        value={this.state.selectedOption}
                                                                        onChange={this.handleChange}
                                                                        options={hotelOptions}
                                                                        formatOptionLabel={(e) => (
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                                <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                            </div>
                                                                        )}
                                                                        isDisabled={this.state.modalType == "View"}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                    {ImportedURL.SUPERADMIN.includes(account.role) ?
                                                        <>
                                                            <div className="col-lg-2 col-md-2 col-sm-6 col-xl-3 mb-3">
                                                                <label className='form-label'>From Date</label>
                                                                <div className='site_pass_calander'>
                                                                    <input type='date' className="form-control" name="fromDateReport" value={this.state.fromDateReport} onChange={this.setDateReport}></input>
                                                                    <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-2 col-md-4 col-sm-6 col-xl-3 mb-3">
                                                                <label className='form-label'>To Date</label>
                                                                <div className='site_pass_calander'>
                                                                    <input type='date' className="form-control" name="toDateReport" min={this.state.fromDateReport ? this.state.fromDateReport : ''} onChange={this.setDateReport} value={this.state.toDateReport}></input>
                                                                    <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-2 col-md-4 col-sm-6 col-xl-3">
                                                                <div className="form-group">
                                                                    <label className='form-label'>Archived Reports</label>
                                                                    <div className="selectgroup w-100">
                                                                        <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                            <input type="radio" name="month" className="selectgroup-input" value={0} onChange={this.getMonthlyReport} checked={this.state.month === '0'} />
                                                                            <span className="selectgroup-button first_month">{this.getMonths(0)}</span>
                                                                        </label>
                                                                        <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                            <input type="radio" name="month" className="selectgroup-input" value={1} onChange={this.getMonthlyReport} checked={this.state.month === '1'} />
                                                                            <span className="selectgroup-button">{this.getMonths(1)}</span>
                                                                        </label>
                                                                        <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                            <input type="radio" name="month" className="selectgroup-input" value={2} onChange={this.getMonthlyReport} checked={this.state.month === '2'} />
                                                                            <span className="selectgroup-button last_month">{this.getMonths(2)}</span>
                                                                        </label>
                                                                        {/* <label className="selectgroup-item">
                                                                    <input type="radio" name="value" className="selectgroup-input" value="200" />
                                                                    <span className="selectgroup-button">XL</span>
                                                                </label> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.state.isFilterShow &&
                                                                <div className="col-lg-2 col-md-4 col-sm-6 col-xl-3 d-flex align-items-center">
                                                                    <button className='btn social_rest_btn btn-primarys' type='button' onClick={this.filterReset}><i className="fa fa-refresh" aria-hidden="true" style={{ fontSize: '20px !importent', marginRight: '7px' }}></i> Reset</button>
                                                                </div>
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <div className="col-lg-4 col-md-6 col-sm-12 col-xl-4 mb-3">
                                                                <label className='form-label'>From Date</label>
                                                                <div className='site_pass_calander'>
                                                                    <input type='date' className="form-control" name="fromDateReport" value={this.state.fromDateReport ? this.state.fromDateReport : ''} onChange={this.setDateReport} ></input>
                                                                    <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-4 col-md-6 col-sm-12 col-xl-4 mb-3">
                                                                <label className='form-label'>To Date</label>
                                                                <div className='site_pass_calander'>
                                                                    <input type='date' className="form-control" name="toDateReport" min={this.state.fromDateReport ? this.state.fromDateReport : ''} onChange={this.setDateReport} value={this.state.toDateReport}></input>
                                                                    <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-4 col-md-6 col-sm-12 col-xl-4">
                                                                <div className="form-group">
                                                                    <label className='form-label'>Archived Reports</label>
                                                                    <div className="selectgroup w-100">
                                                                        <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                            <input type="radio" name="month" className="selectgroup-input" value={0} onChange={this.getMonthlyReport} checked={this.state.month === '0'} />
                                                                            <span className="selectgroup-button first_month">{this.getMonths(0)}</span>
                                                                        </label>
                                                                        <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                            <input type="radio" name="month" className="selectgroup-input" value={1} onChange={this.getMonthlyReport} checked={this.state.month === '1'} />
                                                                            <span className="selectgroup-button">{this.getMonths(1)}</span>
                                                                        </label>
                                                                        <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                            <input type="radio" name="month" className="selectgroup-input" value={2} onChange={this.getMonthlyReport} checked={this.state.month === '2'} />
                                                                            <span className="selectgroup-button last_month">{this.getMonths(2)}</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.state.isFilterShow &&
                                                                <div className="col-lg-2 col-md-4 col-sm-6 col-xl-3 d-flex align-items-center">
                                                                    <button className='btn social_rest_btn btn-primarys' type='button' onClick={this.filterReset}><i className="fa fa-refresh" aria-hidden="true" style={{ fontSize: '20px !importent', marginRight: '7px' }}></i> Reset</button>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid no_records">
                                <div className="tab-content pt-3">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="row clearfix">
                                            {this.state.userTickets.length == 0 ?
                                                <div className="card-body no_records text-center d-flex align-items-center justify-content-center">
                                                    <div style={{ maxWidth: "340px" }}>
                                                        <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid mb-4 mt-0" style={{ maxWidth: "272px" }} />
                                                        <h5 className="mb-2">{this.state.hotel ? "No records to display" : "Select a hotel to display records"}</h5>
                                                    </div>
                                                </div>
                                                : ''}
                                            {this.state.userTickets.map((rows, i) => {
                                                let categoryName = ''
                                                if (listCategory.length > 0) {
                                                    listCategory.map((item) => {
                                                        if (rows.category != undefined && rows.category != null)
                                                            if (rows.category == item._id) {
                                                                categoryName = item.name
                                                            }
                                                    })
                                                }
                                                return (<div className="col-lg-4 col-md-6" key={i} >
                                                    <Link to={'/user-ticket/' + rows.id + "/" + (rows.assigned ? 'assignee' : 'reporter')}>
                                                        <div className="card" style={{ cursor: 'pointer' }} >
                                                            {
                                                                categoryName
                                                                &&
                                                                <div className='ribbon'>
                                                                    <div className="ribbon-box orange ">{categoryName}</div>
                                                                </div>
                                                            }
                                                            <div className=" text-center" style={{ padding: '52px 20px 20px 20px' }}>
                                                                <img className="img-thumbnail rounded-circle avatar-xxl" src={(rows.hotel_logo && rows.hotel_logo != undefined) ? ImportedUrl.FILEURL + rows.hotel_logo : "../assets/images/xs/avatar2.jpg"} onError={onErrorImage} alt="" />
                                                                <h6 className="pt-3">{rows.name}</h6>
                                                                <label className="text-center form-label mb-3">{rows.email}</label>
                                                            </div>
                                                            <div className="card-footer text-center" style={{ height: "150px" }}>
                                                                <div className="row clearfix">
                                                                    <div className='d-flex'>
                                                                        <div className="col-6">
                                                                            <h5 className="mb-0">{rows.open ? rows.open : 0}</h5>
                                                                            <label className="form-label" style={{ fontSize: "13px" }}>Created</label>
                                                                        </div>
                                                                        <div className="col-6">
                                                                            <h5 className="mb-0">{rows.assigned ? rows.assigned : 0}</h5>
                                                                            <label className="form-label" style={{ fontSize: "13px" }}>Assigned</label>
                                                                        </div>
                                                                    </div>
                                                                    <div className='mt-3 d-flex'>
                                                                        <div className="col-12">
                                                                            <h5 className="mb-0">{rows.isDeleted ? rows.isDeleted : 0}</h5>
                                                                            <label className="form-label" style={{ fontSize: "13px" }}>Deleted</label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Link>
                                                </div>)
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="overlay" style={{ display: this.state.reportspin ? 'block' : 'none' }}></div>
                {this.state.reportspin ?
                    <div className='common_loader'>
                        <img className='loader_img_style_common' src='../../assets/images/load.png' />
                        <Spinner className='spinner_load_common' animation="border" variant="info" >
                        </Spinner>
                    </div>
                    : ''
                }
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    LandingState: state.landing,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(TicketReports);
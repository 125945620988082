import axios from 'axios';

export async function translateContent(text, lang) {
    if (text) {
        const encodedParams = new URLSearchParams();
        encodedParams.set('q', text);
        encodedParams.set('target', lang);

        const options = {
            method: 'POST',
            url: 'https://google-translate1.p.rapidapi.com/language/translate/v2',
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
                'Accept-Encoding': 'application/gzip',
                'X-RapidAPI-Key': '298491970amshce79c06bd8c4a02p1d6ae5jsn153cc4d92782',
                'X-RapidAPI-Host': 'google-translate1.p.rapidapi.com'
            },
            data: encodedParams,
        };

        try {
            const response = await axios.request(options);
            if (response && response.data && response.data.data && response.data.data.translations && response.data.data.translations[0]) {
                return response.data.data.translations[0].translatedText;
            } else {
                return text;
            }
        } catch (error) {
            return text;
        }
    }
    else {
        return text;
    }
}


import React, { Component } from 'react'
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../common/api';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import { Link, Redirect } from 'react-router-dom';
import Papa from "papaparse";
import { AiOutlineZoomIn } from "react-icons/ai";
import { Modal } from 'react-bootstrap';
import { Error, Success } from '../../common/swal';
import showSwalDialog from '../../utilities/Modal/swal';
import { Capitalize } from '../../common/validate';

const initialState = {
    columnDefs: [
        {
            headerName: 'Name', field: 'name', cellStyle: { 'text-align': 'left' }, floatingFilter: true,
        },
        {
            headerName: 'Phone Number', field: 'phoneno', cellStyle: { 'text-align': 'left' }, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data.phoneno) {
                    return "+" + params.data.phoneno
                } else {
                    return "---"
                }
            }
        },
    ],
    defaultColumDef: {
        "flex": 1,
        "filter": true,
        "sortable": true,
        "resizable": true,
    },
    spinner: false,
    rowData: [],
    tableRows: [],
    parsedData: '',
    invitefile: '',
    show: false,
    seencount: 0,
    totalcount: 0,
    notseencount: 0,
    totalClick: true
}
class SendInvite extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }


    handleShow = () => {
        this.setState({ show: true })
    }
    handleClose = () => {
        this.setState({ show: false })
    }
    handleFileChange = (e) => {
        e.stopPropagation();
        let superThis = this;
        superThis.setState({ show: true })
        if (e.target.files[0]) {
            const inputFile = e.target.files[0];
            const allowedExtensions = ["csv"];
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                return;
            }
            const reader = new FileReader();
            reader.onload = async ({ target }) => {
                const csv = Papa.parse(target.result, {
                    header: true,
                    skipEmptyLines: true,
                    complete: function (results) {
                        const rowsArray = [];
                        const valuesArray = [];
                        let csvData = results.data
                        for (let i = 0; i < csvData.length; i++) {
                            if (csvData[i] && csvData[i].phoneno) {
                                rowsArray.push(Object.keys(csvData[i]));
                                valuesArray.push(Object.values(csvData[i]));
                            }
                        }
                        superThis.setState({ parsedData: results.data })
                        superThis.setState({ tableRows: rowsArray[0] });
                        superThis.setState({ values: valuesArray });
                    },
                });
            };
            reader.readAsText(inputFile);
            this.setState({ invitefile: inputFile });
        } else {
            const file = new File(['Dummy File Content'], 'sample.csv', { type: 'text/plain' });
            const fileList = new DataTransfer();
            fileList.items.add(file);
            const fileInput = document.getElementById('fileSet');
            fileInput.files = fileList.files;
        }
    };

    SubmitForm = () => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        if (!this.state.invitefile) {
            Error("Upload valid file");
        }
        // if (!this.state.message) {
        //     Error("Message is required");
        // }
        if (this.state.invitefile) {
            showSwalDialog('Are you sure you are sending the message?', '', 'Send', 'Cancel', '../../assets/images/invitenew.png').then((result) => {
                if (result.isConfirmed) {
                    this.setState({ spinner: true })
                    axios.post(ImportedURL.API.sendDeveshInvite, { data: this.state.parsedData, message: this.state.message })
                        .then((res) => {
                            Success("Invite sent successfully")
                            this.setState({ invitefile: '', message: '', parsedData: '', spinner: false });
                            document.getElementById('fileSet').value = '';
                        }).catch((err) => {
                            this.setState({ spinner: false });
                            console.log(err);
                        });
                }
            })
        }

    }
    componentDidMount() {
        this.setState({ spinner: true })
        this.onFetchData()
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    onFetchData = (query) => {
        axios.post(ImportedURL.API.listDeveshGuestMessageCount, query)
            .then((res) => {
                this.setState({ seencount: res.data.seencount, totalcount: res.data.totalcount, spinner: false, notseencount: res.data.notseencount, rowData: res.data.rowdata })
            }).catch((err) => {
                this.setState({ spinner: false })
            })
    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    }
    total = e => {
        this.setState({ activeClick: false, inactiveClick: false, totalClick: true, status: "" })
        this.onFetchData()
    }
    active = e => {
        this.setState({ activeClick: true, inactiveClick: false, totalClick: false, status: true })
        this.onFetchData({ seen: true })
    }
    inactive = e => {
        this.setState({ inactiveClick: true, activeClick: false, totalClick: false, status: false })
        this.onFetchData({ seen: false })
    }
    render() {
        if (this.state.modalType === "View") return <Redirect to={'/view-roomtype-enquiry/' + this.state.id} />
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const formate = this.state.invitefile?.name && this.state.invitefile?.name.toLowerCase().split('.')[1];

        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span><h6 className="highlights_breadcrump">{languageData && languageData.SEND_INVITE ? languageData.SEND_INVITE : "Send Invite"} </h6></p>
                    </div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div>
                                <div className="d-flex justify-content-between align-items-center ">
                                    <ul className="nav nav-tabs page-header-tab bulk_tab">
                                        <li className="nav-item"><a className="nav-link active" id="user-tab" data-toggle="tab" href="#sendinvite">Send Invite</a></li>
                                        <li className="nav-item"><a className="nav-link " id="user-tab" data-toggle="tab" href="#history_invite">History</a></li>
                                    </ul>
                                </div>
                                <div className="tab-content pt-3">
                                    <div className="tab-pane fade active show" id="sendinvite" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="fa fa-paper-plane mr-2 "></i></div>
                                                <h3 className="card-title">{languageData && languageData.SEND_INVITE ? languageData.SEND_INVITE : "Send Invite"}</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="card">
                                                            <div className="card-body-bulk-message">
                                                                <div className="row">
                                                                    <div className="col-sm-8 col-md-8">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Upload CSV file<span className="ml-1" style={{ color: 'red' }}>*</span> <span class="form-label-small"></span></label>
                                                                            <input type="file" accept=".csv" className="form-control" id='fileSet' onChange={this.handleFileChange} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-4 col-md-4">
                                                                        <div className="form-group">
                                                                            <div className="page-header" style={{ marginTop: "15px", marginLeft: "45px" }}>
                                                                                <div className="right">
                                                                                    <ul className="nav nav-pills">
                                                                                        <li className="nav-item dropdown" style={{ background: '#be7b08', borderRadius: "8px" }}>
                                                                                            <a href="assets/sample.csv" download={"sample.csv"} style={{ color: '#fff' }} className="nav-link" aria-haspopup="true"><i className='fa fa-download px-2'></i> Download Sample CSV</a>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {this.state.invitefile != '' ?
                                                                    <div className='csv_log_setup mb-2'>
                                                                        <div className='img_icon' >
                                                                            <div className='img_size'>
                                                                                <img src='../assets/images/csv-viewer.png' alt='not found' />
                                                                            </div>
                                                                            <div className='csv_overlay' onClick={this.handleShow}></div>
                                                                            <span onClick={this.handleShow}><AiOutlineZoomIn className='zoom_i' /></span>
                                                                        </div>
                                                                    </div>
                                                                    : ''}
                                                                {/* --------------- csv data shhowin model --------------------- */}

                                                                <div className='csv_model'>
                                                                    <Modal className={'info-modal'} show={this.state.show} onHide={this.handleClose} size={"lg"} >
                                                                        <Modal.Header closeButton>
                                                                            <Modal.Title>{formate === 'csv' ? "SEND INVITE PREVIEW" : "Invalid file format"}</Modal.Title>
                                                                        </Modal.Header>
                                                                        <Modal.Body style={{ height: '500px', overflow: 'auto' }}>
                                                                            {formate == 'csv' ?
                                                                                <>
                                                                                    <div className='Over_all_table_style table_head_top'>
                                                                                        <table className='w-100 bulk_msg_table'>
                                                                                            <thead>
                                                                                                <tr>
                                                                                                    {this.state.tableRows && this.state.tableRows.map((rows, index) => {
                                                                                                        return <th key={index} style={{ padding: '10px !important' }}>{Capitalize(rows)}</th>;
                                                                                                    })}
                                                                                                </tr>
                                                                                            </thead>
                                                                                            <tbody>
                                                                                                {this.state.values && this.state.values.length > 0 && this.state.values.map((value, index) => {
                                                                                                    return (
                                                                                                        <tr key={index} className='px-2'>
                                                                                                            {value.map((val, i) => {
                                                                                                                return <td key={i} style={{ padding: '10px !important' }}>{val}</td>;
                                                                                                            })}
                                                                                                        </tr>
                                                                                                    );
                                                                                                })}
                                                                                            </tbody>
                                                                                        </table>
                                                                                    </div>
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <label className='wrong_file_text'>
                                                                                        <p style={{ color: '#d9534f' }}>Invalid file formate.
                                                                                            Upload only (csv) file.
                                                                                        </p>
                                                                                    </label>
                                                                                </>
                                                                            }
                                                                        </Modal.Body>
                                                                    </Modal>
                                                                </div>
                                                                <div className="card-footer text-right" style={{ padding: "20px 0px" }}>
                                                                    {
                                                                        this.state.saving ?
                                                                            <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>sending</button>
                                                                            :
                                                                            <button type="submit" className="btn commor_save" onClick={this.SubmitForm}>
                                                                                <i className="fa fa-paper-plane-o mr-2" aria-hidden="true"></i> Send
                                                                            </button>
                                                                    }

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="history_invite" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="fa fa-paper-plane mr-2 "></i></div>
                                                <h3 className="card-title">{languageData && languageData.HISTORY ? languageData.HISTORY : "History"}</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="row clearfix common_top_section">
                                                    <div className="col-12 col-md-12 col-xl-4">
                                                        <div className="card">
                                                            <div className="card-body ribbon" style={{ backgroundColor: this.state.totalClick ? '#1c3078' : '', borderRadius: this.state.totalClick ? '10px' : '', border: this.state.totalClick ? "2px solid grey" : '', boxShadow: this.state.totalClick ? '0 0 5px #555' : '' }} >
                                                                <div className={`${!this.state.totalClick ? "ribbon-box orange" : "ribbon-box text-color white"} `}>{this.state.totalcount}</div>
                                                                <a className="my_sort_cut text-muted" href="#" onClick={this.total}>
                                                                    <span className="dashbord_icons">
                                                                        <i className="fa fa-paper-plane" style={{ color: this.state.totalClick ? '#1c3078' : '#1c3078' }} id='total'></i>
                                                                    </span>
                                                                    <span className='dash_total' style={{ color: this.state.totalClick ? 'white' : '' }}>Total Invite Sent</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-xl-4">
                                                        <div className="card">
                                                            <div className="card-body ribbon" style={{ backgroundColor: this.state.activeClick ? '#1c3078' : '', borderRadius: this.state.activeClick ? '10px' : '', border: this.state.activeClick ? "2px solid grey" : '', boxShadow: this.state.activeClick ? '0 0 5px #555' : '' }}>
                                                                <div className={`${!this.state.activeClick ? "ribbon-box orange" : "ribbon-box text-color white"} `}>{this.state.seencount}</div>
                                                                <a className="my_sort_cut text-muted" href="#" onClick={this.active}>
                                                                    <span className="dashbord_icons">
                                                                        <i className="fa fa-eye" style={{ color: this.state.activeClick ? '#1c3078' : '#1c3078' }}></i>
                                                                    </span>
                                                                    <span className='dash_total' style={{ color: this.state.activeClick ? 'white' : '' }}>Seen</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-12 col-xl-4">
                                                        <div className="card">
                                                            <div className="card-body ribbon" style={{ backgroundColor: this.state.inactiveClick ? '#1c3078' : '', borderRadius: this.state.inactiveClick ? '10px' : '', border: this.state.inactiveClick ? "2px solid grey" : '', boxShadow: this.state.inactiveClick ? '0 0 5px #555' : '' }}>
                                                                <div className={`${!this.state.inactiveClick ? "ribbon-box orange" : "ribbon-box text-color white"} `}>{this.state.notseencount}</div>
                                                                <a className="my_sort_cut text-muted" href="#" onClick={this.inactive}>
                                                                    <span className="dashbord_icons dashboard_icons_1">
                                                                        <i className="fa fa-eye-slash" style={{ color: this.state.inactiveClick ? '#1c3078' : '#1c3078' }}></i>
                                                                    </span>
                                                                    <span className='dash_total' style={{ color: this.state.inactiveClick ? 'white' : '' }}>Not Seen</span>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="table-responsive">
                                                    <div className="ag-theme-alpine">
                                                        <AgGridReact
                                                            rowHeight={82}
                                                            paginationPageSize={25}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            rowData={this.state.rowData}
                                                            columnDefs={this.state.columnDefs}
                                                            rowDragManaged={true}
                                                            animateRows={true}
                                                            onGridReady={this.onGridReady}
                                                            overlayNoRowsTemplate={"No rows to display"}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                    {this.state.spinner ?
                                                        <div className='common_loader_ag_grid'>
                                                            <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                            </Spinner>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(SendInvite);

const initialState = {
    momreminder: {},
    tabposition: 'reminder',
    spinner: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_TAB_CHANGE_REMINDER':
            return Object.assign({}, state, {
                tabposition: action.value
            })
        case 'HANDLE_MOM_REMINDER_CHANGE':
            return Object.assign({}, state, {
                momreminder: {
                    ...state.momreminder,
                    [action.name]: action.value
                }
            })
        case 'VIEW_MOM_REMINDER':
            return Object.assign({}, state, {
                momreminder: action.payload,
                spinner: false,
            })
        case 'EMPTY_MOM_REMINDER':
            return Object.assign({}, state, {
                momreminder: {},
            })
        case 'EMPTY_MOM_REMINDER':
            return Object.assign({}, state, {
                spinner: true,
            })

        default:
            return state;
    }
}
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { AC_EMPTY_DESIGNATION, AC_LIST_DESIGNATION, AC_VIEW_DESIGNATION, AC_HANDLE_DESIGNATION_CHANGE, AC_RESET_DESIGNATION } from '../../actions/designationAction';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import ErrorResponse from '../../utilities/Error/errorstatus';
import showSwalDialog from '../../utilities/Modal/swal';
import { imageCellRendererList, onErrorImage } from '../../common/validate';

const initialState = {
    modalType: "Add",
    columnApi: '',
    gridApi: '',
    columnDefs: [
        { headerName: '#', valueGetter: "node.rowIndex+1", width: 50, sortable: false, filter: false, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', hide: 'true' },
        {
            headerName: 'Logo', width: 100, field: 'logo', hide: true, sortable: false, filter: false, "resizable": false, cellStyle: { 'text-align': 'center' },
            cellRenderer: imageCellRendererList,
        },
        {
            headerName: 'Hotel', field: 'hotelName', hide: true, width: 130, cellStyle: { 'text-align': 'left' },
            valueGetter: function (params) {
                if (params.data && params.data.hotelName) {
                    return params.data.hotelName
                } else {
                    return "---"
                }
            }
        },
        { headerName: 'Category', field: 'department', width: 200, headerClass: 'ag-center-header', },
        { headerName: 'Designation', field: 'name', width: 200, floatingFilter: true, headerClass: 'ag-center-header', },
        {
            headerName: 'Status', width: 100, field: 'status', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header',
            cellRenderer: function (params) {
                if (params.data && params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else if (params.value == 0) {
                    return '<span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                if (params.value) {
                    return params.value;
                } else {
                    return "---"
                }
            }
        },

    ],
    defaultColumDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
    },
    nameError: false,
    id: '',
    initial: true,
    saving: false,
    hotelError: false,
    searchhotel: '',
    searchhoteloption: { label: 'All', value: '', name: 'searchhotel' },
    searchcategoryoption: { label: 'All', value: '', name: 'searchcategory' },
    hotelName: '',
    columnreset: true,
    hideExport: false,
    perPage: 25
}

class ListDesignation extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    changeModal = (e) => {
        this.props.AC_RESET_DESIGNATION();
        this.setState({ modalType: e.target.id, nameError: false, departmentError: false, hotelError: false })
        const { AccountState } = this.props;
        const account = AccountState.account;
        if (!ImportedURL.SUPERADMIN.includes(AccountState.role)) {
            this.props.AC_HANDLE_DESIGNATION_CHANGE('hotel', account.hotel);
        }
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.setState({ modalType: "Edit", id: event.data._id, departmentError: false, nameError: false, hotelError: false });
            this.props.AC_VIEW_DESIGNATION(event.data._id);
        }
        if (value === 'Delete') {
            showSwalDialog('Are you sure you want to delete?', '', 'Ok', 'Cancel', 'assets/images/delete.png').then((result) => {
                if (result.isConfirmed) {
                    this.setState({ searchhoteloption: { label: 'All', value: '', name: 'searchhotel' }, searchhotel: '', searchcategoryoption: { label: 'All', value: '', name: 'searchcategory' } })
                    axios.get(ImportedURL.API.softDeleteDesignation + '/' + event.data._id)
                        .then((res) => {
                            Success((languageData && languageData.DESIGNATION ? languageData.DESIGNATION : 'Designation') + (languageData && languageData.DELETED_SUCCESSFULLY ? languageData.DELETED_SUCCESSFULLY : ' deleted successfully'));
                            const dataSource = this.getDataSource({ landing: true })
                            this.gridApi.setDatasource(dataSource);
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData, "Users")
                        });
                }
            })
        }
        if (value === 'Status') {
            showSwalDialog('Are you sure you want to change the status?', '', 'Ok', 'Cancel', 'assets/images/status.png').then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'designations' })
                        .then((data) => {
                            this.setState({ searchhoteloption: { label: 'All', value: '', name: 'searchhotel' }, searchhotel: '', searchcategoryoption: { label: 'All', value: '', name: 'searchcategory' } })
                            const dataSource = this.getDataSource({ landing: true })
                            this.gridApi.setDatasource(dataSource);
                            Success((languageData && languageData.STATUS ? languageData.STATUS : 'Status') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " updated successfully"))
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
    }

    HotelChange = e => {
        const { name, value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.AC_HANDLE_DESIGNATION_CHANGE(name, value);
    }
    AddSelectHandleChange = e => {
        const { name, value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.AC_HANDLE_DESIGNATION_CHANGE(name, value);
    }
    searchUser = e => {
        const { name, value, label } = e;
        if (name == 'searchhotel') {
            const dataSource = this.getDataSource({ hotel: value, category: this.state.searchcategory ? this.state.searchcategory : '' })
            this.gridApi.setDatasource(dataSource);
            this.setState({ searchhoteloption: e, searchhotel: value, hotelName: label })
        }
        if (name == 'searchcategory') {
            const dataSource = this.getDataSource({ category: value, hotel: this.state.searchhotel ? this.state.searchhotel : '' })
            this.gridApi.setDatasource(dataSource);
            this.setState({ searchcategoryoption: e, searchcategory: value })
        }
    }
    onChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false })
        this.props.AC_HANDLE_DESIGNATION_CHANGE(name, value);
    }
    submit = () => {
        const { DesignationState, AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const data = DesignationState.designation;
        let valid = 1;
        data['name'] = data.name.trim()
        if (!data.hotel) {
            this.setState({ hotelError: true });
            valid = 0;
        }
        // data["name"]=data.name.trim();
        if (!data.name) {
            this.setState({ nameError: true });
            valid = 0;
        }
        if (!data.department) {
            this.setState({ departmentError: true });
            valid = 0;
        }

        if (valid) {
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addDesignation, data)
                    .then((res) => {
                        this.setState({ searchhoteloption: { label: 'All', value: '', name: 'searchhotel' }, searchhotel: '', saving: false, searchcategoryoption: { label: 'All', value: '', name: 'searchcategory' } })
                        Success((languageData && languageData.DESIGNATION ? languageData.DESIGNATION : 'Designation') + (languageData && languageData.CREATED_SUCCESSFULLY ? languageData.CREATED_SUCCESSFULLY : " created successfully"));
                        // Success(res.statusText);
                        this.props.AC_RESET_DESIGNATION();
                        const dataSource = this.getDataSource({ landing: true })
                        this.gridApi.setDatasource(dataSource);
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        if (response.status == 409) {
                            Error((languageData && languageData.DESIGNATION ? languageData.DESIGNATION : 'Designation') + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : ' already exist'))
                        } else if (response.status == 400) {
                            Error((languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request'))
                        }
                        else if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else {
                            Error(response.status)
                        }
                        this.setState({ saving: false })
                    });
            } else {
                axios.post(ImportedURL.API.updateDesignation + '/' + this.state.id, data)
                    .then((res) => {
                        this.setState({ searchhoteloption: { label: 'All', value: '', name: 'searchhotel' }, searchhotel: '', saving: false, searchcategoryoption: { label: 'All', value: '', name: 'searchcategory' } })
                        Success((languageData && languageData.DESIGNATION ? languageData.DESIGNATION : 'Designation') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " updated successfully"));
                        this.props.AC_RESET_DESIGNATION();
                        const dataSource = this.getDataSource({ landing: true })
                        this.gridApi.setDatasource(dataSource);
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 400) {
                            Error((languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else if (response.status == 409) {
                            Error((languageData && languageData.DESIGNATION ? languageData.DESIGNATION : 'Designation') + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : ' already exist'))
                        } else {
                            Error(response.status)
                        }
                    });
            }
        }
    }

    componentDidMount() {
        const { AccountState } = this.props;
        this.setState({ hotelName: AccountState.account.hotelName })
    }

    exportToCSV = () => {
        const account = this.props.AccountState.account;
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: ['hotelName', 'department', 'name'],
            fileName: "Designation" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }
    exportPDF = () => {
        const account = this.props.AccountState.account;
        const exportData = this.props.DesignationState.listDesignation;
        const selectedProperties = (this.state.hotelName && this.state.hotelName != "All") ? ['department', 'name'] : ['hotelName', 'department', 'name'];
        const selected = exportData.map((item, i) => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    selectedData[property] = item[property] ? item[property] : '---';
                }
            });
            return selectedData;
        });
        let data = {
            title: (this.state.hotelName) ? (this.state.hotelName != "All" ? this.state.hotelName + " - Designation List" : "Designation List") : "Designation List",
            head: (this.state.hotelName && this.state.hotelName != "All") ? ['#', 'Category', "Designation"] : ['#', 'Hotel', 'Category', "Designation"],
            body: selected,
            hotelNameExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hotelNameExport : '',
            hoteladdressExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "designation" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }
    // Data source to set data in ag grid
    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {

                // Grid params for filter and sort
                const perPage = query.perPage ? query.perPage : this.state.perPage
                const page = Math.floor(params.startRow / perPage);
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                // Action call for inspection list
                this.props.AC_EMPTY_DESIGNATION()
                await this.props.AC_LIST_DESIGNATION({ perPage: perPage, page: page, hotel: this.state.searchhotel ? this.state.searchhotel : '', category: this.state.searchcategory ? this.state.searchcategory : '', filter: filter, sort: sortModel.length ? sortModel[0] : {}, ...query });
                const rowData = this.props.DesignationState.listDesignation
                const total = this.props.DesignationState.total;
                const { AccountState } = this.props;
                const previleges = AccountState.previleges;
                const Previlege = previleges.find(obj => { return obj.name == "Designation" });
                const deleteOption = AccountState.role == 'admin' ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : '';
                const editOption = Previlege?.edit ? '<button title="Edit" type="button" class="btn btn-icon" data-action-type="Edit" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' + deleteOption : '';

                rowData && rowData.length > 0 && rowData.forEach(object => {
                    object.actions = editOption;
                });
                const lbLastRowOnPageEl = document.querySelector(`[ref=\'lbLastRowOnPage\']`);
                if (lbLastRowOnPageEl) {
                    lbLastRowOnPageEl.innerHTML = (rowData) ? rowData.length + params.startRow : 0;
                }
                params.successCallback(rowData, total);
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.gridApi) {
            if (this.props.AccountState.previleges && prevState.gridApi !== this.state.gridApi && prevState.perPage == this.state.perPage) {
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
                this.UpdateColumnDefs();
            }
        }
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };
    UpdateColumnDefs = () => {
        const role = this.props.AccountState.role;
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role)) {
            let updatedColumnDefs = [...this.state.columnDefs];
            updatedColumnDefs = updatedColumnDefs.map((col) => {
                if (col.field === 'hotelName' || col.field === 'logo') {
                    return { ...col, hide: false };
                }
                return col;
            });
            this.gridApi.setColumnDefs(updatedColumnDefs);
        }
    };
    onPerPageChange = (newPerPage) => {
        this.setState({ perPage: newPerPage.value })
    };

    render() {
        const { DesignationState, AccountState, LandingState } = this.props;
        const data = DesignationState.designation;
        const rowData = DesignationState.listDesignation
        const account = AccountState.account;
        const spinner = DesignationState.spinner;
        const previleges = AccountState.previleges;
        const languageData = AccountState.account.SelectedlanguageData
        const Previlege = previleges.find(obj => { return obj.name == "Designation" });
        const listHotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[0] : [];;

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]

        var selectedHotel = '';
        const hotelOptions = [];
        if (account?.allhotels?.length > 0) {
            account.allhotels.map((item) => {
                if (account.hotel == item.hotelid) {
                    selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                }
                hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo });
            })
        } else {
            if (data.hotel?._id !== undefined) {
                listHotels.filter(filterItem => filterItem.status === true).map((item) => {
                    if (data.hotel?._id == item._id) {
                        selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                    }
                    hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
                })
            } else {
                listHotels.filter(filterItem => filterItem.status === true).map((item) => {
                    if (data.hotel == item._id) {
                        selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                    }
                    hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
                })
            }
        }
        var hotelOptionSearch = [];
        if (listHotels.length > 1) {
            hotelOptionSearch.push({ label: 'All', value: '', name: 'searchhotel' })
        }
        if (listHotels.length > 0) {
            listHotels.filter(filterItem => filterItem.status === true).map(item => {
                hotelOptionSearch.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'searchhotel' });
            })
        }
        const DepartmentData = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[7] : [];;
        const DepartmentArray = [];
        if (data.hotel) {
            DepartmentData.filter(filterItem => filterItem.status === true).map((item, i) => {
                // if (data.hotel == item.hotel) {
                DepartmentArray.push(
                    item
                )
                // }
            })
        }
        var selectDepartmentData = '';
        if (data.department !== undefined) {
            selectDepartmentData = DepartmentArray.find(e => e._id === data.department)
        }
        const listCategories = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[7] : [];;
        var newListCategories = [];
        if (listCategories) {
            listCategories.filter(filterItem => filterItem.status == true).map((item) => {
                newListCategories.push(item)
            })
        }
        const categoryOptions = [{ label: 'All', value: '', name: 'searchcategory' }];
        newListCategories.filter(filterItem => filterItem.status == true).map(item => {
            categoryOptions.push({ label: item.name, value: item._id, name: 'searchcategory' });
        })
        return (
            <>

                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-designation"><h6 className="highlights_breadcrump">{languageData && languageData.DESIGNATION ? languageData.DESIGNATION : "Designation"}{languageData && languageData.LIST ? languageData.LIST : ' List'}</h6></Link></p>
                        </div>
                        <div className="section-body pt-3 user_sec">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header tit_res">
                                                <div className='d-flex'>
                                                    <div className='rounded_icon'><i className="icon-globe-alt mr-2 "></i></div>
                                                    <div className='d-flex align-items-center'>
                                                        <h3 className="card-title"> {languageData && languageData.DESIGNATION ? languageData.DESIGNATION : "Designation"}{languageData && languageData.LIST ? languageData.LIST : ' List'}</h3>
                                                    </div>
                                                </div>

                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="header-action" >
                                                            {Previlege?.add ? <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" id='Add' onClick={(e) => this.changeModal(e)}><i className="fe fe-plus mr-2" id='Add' />Add</button> : ''}
                                                        </div>
                                                    </div>
                                                    {rowData && rowData.length > 0 ?
                                                        <>
                                                            <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                            <div className="dropdown-menu">
                                                                <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                            </div>
                                                        </>
                                                        : []}
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                        <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={this.onPerPageChange}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        account?.allhotels == undefined &&
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6">
                                                            <label className="form-label">{languageData && languageData.SEARCH ? languageData.SEARCH : "Search"}  {languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel"}</label>
                                                            <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                <Select
                                                                    value={this.state.searchhoteloption}
                                                                    onChange={this.searchUser}
                                                                    options={hotelOptionSearch}
                                                                    formatOptionLabel={(e) => (
                                                                        <div style={{ display: 'block', alignItems: 'center' }}>
                                                                            {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} />}
                                                                            <span className='all_search_text_size' >{e.label}</span>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xl-4">
                                                        <label className="form-label"> {languageData && languageData.USER ? languageData.USER : "User"} {languageData && languageData.CATEGORY ? languageData.CATEGORY : "Category"} </label>
                                                        <div className="form-group" style={{ marginBottom: '10px' }}>
                                                            <Select
                                                                value={this.state.searchcategoryoption ? { label: this.state.searchcategoryoption.label } : ''}
                                                                onChange={this.searchUser}
                                                                options={categoryOptions}
                                                                className="custom_select"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <div className={(rowData && rowData.length == 0) ? "ag-theme-alpine ag_grid_no_record_show" : "ag-theme-alpine"}>
                                                        <AgGridReact
                                                            key={this.state.perPage}
                                                            rowHeight={82}
                                                            paginationPageSize={this.state.perPage}
                                                            cacheBlockSize={this.state.perPage}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            onGridReady={this.onGridReady}
                                                            columnDefs={this.state.columnDefs}
                                                            rowDragManaged={true}
                                                            wrapText={true}
                                                            animateRows={true}
                                                            overlayNoRowsTemplate={"No rows to display"}
                                                            rowModelType={'infinite'}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                    {
                                                        (rowData && rowData.length == 0)
                                                        &&
                                                        <span className='ag_grid_no_record_show_span' >No rows to display</span>
                                                    }
                                                    <div id="overlay" style={{ display: spinner || this.state.spinner ? 'block' : 'none' }}></div>
                                                    {spinner || this.state.spinner ?
                                                        <div className='common_loader_ag_grid'>
                                                            <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                            </Spinner>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className='rounded_icon'><i className="fa fa-address-card-o mr-2"></i></div>
                                    <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} {languageData && languageData.DESIGNATION ? languageData.DESIGNATION : "Designation"}</h5>
                                    <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                            <img src='../../assets/images/cancel.png' />
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row clearfix">
                                        <div className="col-md-12">
                                            {ImportedURL.SUPERADMIN.includes(account.role) ?
                                                <div className='form-group'>
                                                    <div className="form-group">
                                                        <label className="form-label pt-3">Hotel<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            value={selectedHotel}
                                                            onChange={this.HotelChange}
                                                            options={hotelOptions}
                                                            formatOptionLabel={(e) => (
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                    <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                </div>
                                                            )}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.hotelError ? "block" : 'none' }}>Hotel is required</div>
                                                    </div>
                                                </div>
                                                : ''}
                                            <div className="form-group">
                                                <label className="form-label">Category<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <Select
                                                    value={selectDepartmentData ? { label: selectDepartmentData.name } : ''}
                                                    onChange={this.AddSelectHandleChange}
                                                    options={DepartmentArray.filter(filterItem => filterItem.status === true).map(item => {
                                                        return {
                                                            label: item?.name, value: item?._id, name: 'department',
                                                        }
                                                    })} />
                                                <div className="invalid-feedback" style={{ display: this.state.departmentError ? "block" : 'none' }}>Category is required</div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Designation<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='name' onChange={this.onChange} value={data.name} placeholder="Designation" />
                                                <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Designation is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.modalType == "Edit" ?
                                    <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="" >
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save " ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Update</button>
                                            }
                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                        </div>
                                    </div>
                                    : <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="" >
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save " ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                            }
                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
    LandingState: state.landing,
    DesignationState: state.designation,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ AC_EMPTY_DESIGNATION, AC_LIST_DESIGNATION, AC_VIEW_DESIGNATION, AC_HANDLE_DESIGNATION_CHANGE, AC_RESET_DESIGNATION, AC_EMPTY_DESIGNATION }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListDesignation);
import { combineReducers } from 'redux';
import settings from './settings';
import hotel from './hotel';
import account from './account';
import category from './category';
import user from './user';
import review from './review';
import commonissue from './commonissue';
import notification from './notification';
import group from './group';
import room from './room';
import country from './country';
import currency from './currency';
import roomtype from './roomtype';
import ticket from './ticket';
import socialmedia from './socialmedia';
import todolistitem from './todolistitem';
import timezone from './timezone';
import problemkeyword from './problemkeyword';
import dynamiccategory from './dynamiccategory';
import designation from './designation';
import phonenumberblocklist from './phonenumberblocklist';
import incidentticket from './incidentticket';
import roomareaamenity from './roomareaamenity';
import settingroomtype from './settingroomtype';
import settingroom from './settingroom';
import configuration from './configuration';
import language from './language';
import ticketworkflow from './ticketworkflow';
import dynamicmanagement from './dynamicmanagement';
import reviewflow from './reviewflow';
import ticketpriority from './ticketpriority';
import reminder from './reminder';
import landing from './landing';
import todolist from './todolist';
import pmsprivileges from './pmsprivileges';

export default combineReducers({
    settings,
    hotel,
    account,
    category,
    user,
    review,
    commonissue,
    notification,
    group,
    room,
    country,
    roomtype,
    ticket,
    socialmedia,
    currency,
    todolistitem,
    timezone,
    problemkeyword,
    designation,
    phonenumberblocklist,
    incidentticket,
    roomareaamenity,
    settingroomtype,
    settingroom,
    configuration,
    language,
    ticketworkflow,
    dynamiccategory,
    dynamicmanagement,
    reviewflow,
    ticketpriority,
    reminder,
    landing,
    todolist,
    pmsprivileges,
});
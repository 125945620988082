import axios from 'axios';
import ImportedURL from '../common/api';


export function AC_HANDLE_TICKET_PRIORITY_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_TICKET_PRIORITY_CHANGE", name: name, value: value })
    };
}
export function AC_VIEW_TICKET_PRIORITY() {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewTicketPriority)
            .then((res) => {
                dispatch({ type: 'VIEW_TICKET_PRIORITY', payload: res.data, spinner: false });
            })
    }
}
export function AC_DEFAULT_TICKET_PRIORITY() {
    return function (dispatch) {
        return axios.get(ImportedURL.API.defaultTicketPriority)
            .then((res) => {
                dispatch({ type: 'DEFAULT_TICKET_PRIORITY', payload: res.data });
            })
    }
}
export function AC_EMPTY_TICKET_PRIORITY() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_TICKET_PRIORITY" })
    };
}
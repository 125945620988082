import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { AC_HANDLE_LANGUAGE_CHANGE } from '../../actions/accountAction';
import ImportedURL from '../../common/api';
import axios from 'axios';
import { Error, Success } from '../../common/swal';
import { AC_LIST_USER } from '../../actions/userAction';
import flagList from '../Country/flag.json';
import Swal from 'sweetalert2';
import { bindActionCreators } from 'redux';
import myhotelai_presetation from '../../assets/pdf/myhotelai-presentaion.pdf';
import { onErrorImage } from '../../common/validate';
import ViewmodalHeader from '../Todolist/Allmytask/viewmodalheader';
import { AC_MODAL_SPINNER_TO_DO_LIST, AC_VIEW_TO_DO_LIST } from '../../actions/todolistAction';
import Spinner from 'react-bootstrap/esm/Spinner';
import { Calendar } from "react-multi-date-picker";
class Header extends Component {
	constructor(props) {
		super(props);
		this.state = {
			notifications: [],
			showModal: false
		}
	}
	signOut = (e) => {
		Swal.fire({
			title: 'Are you sure want to logout ?',
			showCancelButton: true,
			cancelButtonColor: '#d33',
			confirmButtonText: 'Ok',
			imageUrl: '../../assets/images/signout.png',
			customClass: {
				popup: 'swal_pop',
				title: 'swal_title',
				image: 'swal_image',
				actions: 'swal_action',
				confirmButton: 'swal_confirm',
				cancelButton: 'swal_close',
			}
		}).then((result) => {
			if (result.isConfirmed) {
				e.preventDefault();
				localStorage.removeItem('historyData');
				localStorage.removeItem('adminLandingData');
				localStorage.removeItem('lastDateToFetch');
				localStorage.removeItem('role');
				localStorage.removeItem('acwtoken');
				localStorage.removeItem('languageData');
				window.location.href = "/login";
			}
		})
	}

	getDate = (date) => {
		const account = this.props.AccountState.account;
		var dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat != '' ? account.timeformat : "hh:mm A")
		if (!date) return '';
		if (date) return moment(date).format(dtFormat);
	}

	changeHotel = (e) => {
		const { AccountState } = this.props;
		const languageData = AccountState.account.SelectedlanguageData
		const account = AccountState.account;
		if (account.hotel !== e.value) {
			axios.get(ImportedURL.API.changeHotelLogin + '/' + e.value)
				.then((res) => {
					Success('Hotel preference changed successfully');
					// Success(res.statusText);
					localStorage.setItem('acwtoken', res.data);
					localStorage.setItem('hotelaiuserchange', true);
					window.location.reload()
					window.location.href = `/`;
				}).catch(({ response }) => {
					if (response) {
						if (response.status == 400) {
							Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
						}
						else if (response.status == 500) {
							Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
						} else if (response.status == 502) {
							Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
						} else {
							Error(response.statusMessage)
						}
						// Error(response.statusText)
					}
				});
		}
	}

	changeLanguage = (e) => {
		var formData = {
			language: e.value,
			code: e.code
		}
		this.props.LanguageChange(e.code);
		axios.post(ImportedURL.API.selectedLanguage, formData)
			.then((res) => {
				// setTimeout(() => { window.location.href = '/'; }, 1000)
			}).catch(({ response }) => {
				if (response) {
					if (response.status == 400) {
						Error('Bad request')
					}
					else if (response.status == 500) {
						Error('Internal Server Error')
					} else if (response.status == 502) {
						Error('Bad Gateway')
					} else {
						Error(response.statusMessage)
					}
				}
			});
	}
	openTodoModal = (id, title) => {
		if (title == "todo") {
			this.setState({ todoid: id, date: "" });
			this.props.spinnerModalTodo();
			this.props.ViewTodo(id);
		}
		if (title == "mom") {
			this.setState({ momid: id, date: "" });
			// this.props.ModalEmptyMinutesOfMeeting();
			// this.props.ViewMinutesOfMeeting(id);
		}
	}
	toggleReadMore = () => {
		this.setState({ readMoreValue: !this.state.readMoreValue })
	}
	render() {
		const { fixNavbar, darkHeader, NotificationState, AccountState, LandingState } = this.props;
		const listnotification = NotificationState.listnotifications;
		const logo = AccountState.account.logo;
		const dataValue = AccountState.account;
		const account = AccountState.account;
		const role = AccountState.role;
		const data = typeof logo == 'string' ? logo : '';
		var resultArray = [];
		// const language = LanguageState.listLanguage  // old
		const language = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 5 ? LandingState.landingdata.data[5] : [];
		const languageData = language;
		const languageLength = language;
		var selectLanguage = '';
		if (dataValue.language !== undefined) {
			let status = languageData.find(e => e.code === dataValue.language)
			if (status) {
				selectLanguage = { label: `${status.name}`, value: status.name }
			}
		}
		if (listnotification.length > 0) {
			for (let index = 0; index < listnotification.length; index++) {
				let textMsg = "";
				let icon = '';
				let title = '';
				let link = '';
				if (listnotification[index].type === 'feedback') {
					title = <Link to={"/view-feedbacks/" + listnotification[index].feedbackid}>Feedback</Link>;
					link = "/view-feedbacks/" + listnotification[index].feedbackid;
					textMsg = 'Received new feedback';
					icon = 'icon-star';
				}
				else if (listnotification[index].type === 'ticket') {
					title = <Link to={"/view-ticket/" + listnotification[index].ticket}>Ticket</Link>;
					link = "/view-ticket/" + listnotification[index].ticket;
					textMsg = listnotification[index].notification;
					icon = 'fa fa-ticket';
				}
				else if (listnotification[index].type === 'incidentticket') {
					title = <Link to={"/view-incident-ticket/" + listnotification[index].incidentticket}>Incident Ticket</Link>;
					link = "/view-incident-ticket/" + listnotification[index].incidentticket;
					textMsg = listnotification[index].notification;
					icon = 'fa fa-ticket';
				}
				else if (listnotification[index].type === 'mom') {
					title = <a href=''>Meeting Minutes</a>;
					link = "";
					textMsg = listnotification[index].notification;
					icon = 'fa fa-calendar';
				}
				else if (listnotification[index].type === 'todo') {
					title = <a href=''>To-Do</a>;
					link = "";
					textMsg = listnotification[index].notification;
					icon = 'icon-clock';
				}
				else if (listnotification[index].type === 'review') {
					title = <Link to={"/social-reviews"}>Review</Link>;
					link = "/social-reviews";
					textMsg = 'New review';
					icon = 'icon-star';
				}
				else if (listnotification[index].type === 'pettycash') {
					title = <Link to={'/view-petty-cash-all/' + listnotification[index].hotelid + '/' + listnotification[index].userid}>Petty Cash</Link>;
					link = '/view-petty-cash-all/' + listnotification[index].hotelid + '/' + listnotification[index].userid
					textMsg = listnotification[index].notification;
					icon = 'icon-star';
				}
				else if (listnotification[index].type === 'pettytally') {
					title = <Link to={'/view-petty-cash-all/' + listnotification[index].hotelid + '/' + listnotification[index].userid}>Petty Cash Tally</Link>;
					link = '/view-petty-cash-all/' + listnotification[index].hotelid + '/' + listnotification[index].userid
					textMsg = listnotification[index].notification;
					icon = 'icon-star';
				}
				else if (listnotification[index].type === 'cashcount') {
					title = <Link to={'/view-cash-count-logsheet/' + listnotification[index].cashcountlogsheet}>Cash Count</Link>;
					link = '/view-cash-count-logsheet/' + listnotification[index].cashcountlogsheet
					textMsg = listnotification[index].notification;
					icon = 'icon-star';
				}
				else {
					title = <Link to={"/notifications"}>Notification</Link>;
					link = "/notifications";
					icon = 'fa fa-bell';
				}
				{
					listnotification[index].type == "todo" || listnotification[index].type == "mom"
						?
						resultArray.push(
							<li key={index} style={{ color: "#1C3078" }} data-toggle="modal" data-target={listnotification[index].type == "mom" ? "#viewminuteofmeetingheader" : "#exampleTodoModalHeader"} onClick={(e) => this.openTodoModal(listnotification[index].type == "mom" ? listnotification[index].meetingminutes : listnotification[index].todo, listnotification[index].type)}>
								<div className="feeds-left ">
									<i className={icon} />
								</div>
								<div className="feeds-body bell">
									<h4 className="title text-danger">
										{title}
										<small className="float-right">{this.getDate(listnotification[index].time)}</small> </h4>
									<small className='bell_notification' style={{ width: '100%' }}> {textMsg}</small>
								</div>
							</li>)
						:
						resultArray.push(<Link to={link}>
							<li key={index}>
								<div className="feeds-left ">
									<i className={icon} />
								</div>
								<div className="feeds-body bell">
									<h4 className="title text-danger">
										{title}
										<small className="float-right">{this.getDate(listnotification[index].time)}</small> </h4>
									<small className='bell_notification' style={{ width: '100%' }}> {textMsg}</small>
								</div>
							</li>
						</Link>)
				}
			}
		} else {
			resultArray.push(<li key='1'>No new notifications</li>)
		}
		let selectHotel = '';
		const hotelOptions = [];
		if (dataValue.allhotels?.length > 0) {
			dataValue.allhotels.map((item) => {
				if (dataValue.hotel == item.hotelid) {
					selectHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
				}
				hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo });
			})
		}
		var label = [];
		if (dataValue?.allhotels?.length == 1) {
			dataValue.allhotels.map((item, i) => {
				const countryVal = item.country != undefined ? item.country : ''
				const postcode = item.postcode != undefined ? item.postcode : ''
				label.push(
					<>
						<div className='logo'>
							<div className='img_size' style={{ width: '50px', height: '50px', marginRight: '10px' }}>
								<img style={{ width: '100%', height: '100%', objectFit: 'fil', borderRadius: '50%', border: '2px solid #1c3078 ' }} src={ImportedURL.FILEURL + item.logo} alt='not found' onError={onErrorImage} />
							</div>
						</div>
						<div className='address width_hoteldetails' style={{ color: '#000', fontWeight: '500', fontSize: '15px', width: '650px', wordWrap: 'break-word' }}>
							<p className='mb-0 h_name'>{item.name}</p>
							<p className='mb-0'>{item.address.charAt(0).toUpperCase() + item.address.slice(1) + ", " + item.city.charAt(0).toUpperCase() + item.city.slice(1) + ", " + item.state.charAt(0).toUpperCase() + item.state.slice(1) + ", " + postcode.charAt(0).toUpperCase() + postcode.slice(1) + ', ' + countryVal.charAt(0).toUpperCase() + countryVal.slice(1) + '.'}</p>
						</div>
					</>
				)
			})
		}
		var dtFormat = (dataValue.dateformate != '' ? dataValue.dateformate : 'MM/DD/YYYY') + ' ' + (dataValue.timeformat != '' ? dataValue.timeformat : "hh:mm A")
		var tzone = 'America/chicago'
		var timeval = ''
		if (account != undefined && account && account.tzone != undefined && account.tzone && account.tzone != '') {
			var tzone = account.tzone
			if (dataValue.time) {
				timeval = moment(dataValue.time).tz(tzone).format(dtFormat);
			} else {
				timeval = moment(new Date()).tz(tzone).format(dtFormat);
			}
		}


		let selectedCountry = ''
		if (dataValue.country) {
			selectedCountry = flagList.find(e => e.code == dataValue.country)
		}

		const dataTodo = this.props.TodoListState.todo;
		const modalspinner = this.props.TodoListState.modalspinner;

		const dataMom = []
		const spinnerMom = false
		var selectStart = ''
		var selectEnd = ''
		if (dataMom.selectdateFilter != undefined && dataMom.selectdateFilter.length > 0 && dataMom.selectdateFilter != null) {
			for (let i = 0; i < dataMom.selectdateFilter.length; i++) {
				selectStart = dataMom.selectdateFilter[0]
				selectEnd = dataMom.selectdateFilter[dataMom.selectdateFilter.length - 1]
			}
		}
		var dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY');
		var tFormat = (account.timeformat != '' ? account.timeformat : "hh:mm A");
		return (
			<div>
				<div
					id="page_top"
					className={`section-body ${fixNavbar ? "sticky-top" : ""} ${darkHeader ? "top_dark" : ""}`}
				>
					<div className="container-fluid">
						<div className="page-header">
							<div className="left">
								{ImportedURL.SUPERADMIN.includes(role) &&
									<>
										<div className='address' style={{ height: '50px', color: '#000', fontWeight: '500', fontSize: '15px', display: 'flex', alignItems: 'center' }}>
											<p className='mb-0 h_name mr-2' style={{ fontWeight: 'bold' }}><i className="fa fa-id-card mr-2 last_log_icon text-muted"></i>Last Login : </p>
											<p className='mb-0  mr-2'>{timeval}</p>
										</div>
									</>}
								{
									(!ImportedURL.SUPERADMIN.includes(AccountState.role) && dataValue.allhotels?.length > 1) &&
									<div className='height_fixed_select' style={{ width: '600px' }}>
										<Select
											value={selectHotel}
											placeholder="Select Hotel..."
											name="hotel"
											options={hotelOptions}
											onChange={this.changeHotel}
											className='header_main_hotel_select'
											formatOptionLabel={(e) => (
												<div style={{ display: 'flex', alignItems: 'center' }}>
													{e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
													<div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
												</div>
											)}
										/>
									</div>
								}
								{label}
							</div>

							<div className="right header_icon" >
								<div className="notification d-flex">
									{language && language.length > 0 &&
										<div className="left">
											<Select
												value={selectLanguage}
												onChange={this.changeLanguage}
												options={language && language.map(item => {
													return {
														label: item.name,
														code: item.code,
														value: item.code
													}
												})}
											/>
										</div>
									}
									<div className=" d-flex">
										<a
											href={myhotelai_presetation} rel="noopener noreferrer" target="_blank"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
										>
											<i className="fa fa-question-circle" title="Help" />
										</a>
									</div>
									<div className="dropdown d-flex">
										<a
											href="/#"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
											data-toggle="dropdown"
										>
											<i className="fa fa-bell" />
										</a>
										<div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow" style={{ width: '350px' }}>
											{
												resultArray.length > 0 ?
													<ul className="list-unstyled feeds_widget" style={resultArray.length > 5 ? { height: '300px' } : { height: 'auto' }}>
														{resultArray}
													</ul>
													:
													<ul className="list-unstyled feeds_widget" style={{ listStyleType: 'none' }}>
														{resultArray}
													</ul>
											}

											<div className="dropdown-divider" />
											{resultArray.length > 1 ?
												<Link to="/notifications"
													className="dropdown-item text-center text-muted-dark readall"
												>
													See all notifications
												</Link> : ''}

										</div>
									</div>
									<div className="dropdown d-flex">
										<a
											href="/#"
											className="nav-link icon d-none d-md-flex btn btn-default btn-icon ml-1"
											data-toggle="dropdown"
										>{dataValue.local ?
											<>
												{
													data ?
														<img className="avatar" src={ImportedURL.LIVEURL + 'uploads/' + data
														} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" onError={onErrorImage}></img>
														:
														<img className="avatar" src={"../assets/images/xs/avatar2.jpg"} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" onError={onErrorImage}></img>
												}
											</>
											:
											<>
												{
													data ?
														<img className="avatar" src={ImportedURL.FILEURL + data
														} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" onError={onErrorImage}></img>
														:
														<img className="avatar" src={"../assets/images/xs/avatar2.jpg"} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" onError={onErrorImage}></img>
												}
											</>
											}
										</a>
										<div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
											<div className="card-body">
												<div className="form-group d-flex">
													<i className="icon-user  mr-2" style={{ position: 'relative', top: '3px' }}></i>
													<label className="form-label">{dataValue.role == 'vendor' ? (dataValue.vendorname ? dataValue.vendorname : '') : (dataValue.name ? dataValue.name : '')}</label>
												</div>
												<div className="form-group d-flex">
													<i className="fe fe-mail  mr-2" style={{ position: 'relative', top: '3px' }}></i>
													<label className="form-label">{dataValue.email}</label>
												</div>
												<div className="form-group d-flex">
													<i className="fe fe-award  mr-2" style={{ position: 'relative', top: '3px' }}></i>
													<label className="form-label">{ImportedURL.SUPERADMIN.includes(dataValue.role) ? 'Admin' : dataValue.categoryType}</label>
												</div>
											</div>
											<div className="dropdown-item sign_out" style={{ cursor: 'pointer' }} onClick={this.signOut}>
												<i className="dropdown-icon fe fe-log-out " /> Sign out
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<ViewmodalHeader data={dataTodo} checkListStatus={true} spinner={modalspinner} id={this.state.todoid} startdate={this.state.viewStart ? moment(this.state.viewStart).format("MM-DD-YYYY") : ''} />
					{/* MOM Modal */}
					{/* <div className="modal fade" id="viewminuteofmeetingheader" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
						<div className="modal-dialog modal-xl latest_review_modal" role="document">
							<div className="modal-content">
								<div className="modal-header">
									<div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
									<h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{languageData && languageData.VIEW ? languageData.VIEW : "View"} {languageData && languageData.MINURES_OF_MEETING ? languageData.MINURES_OF_MEETING : "Minutes of Meeting"}</h5>
									<button type="button" className="close" data-dismiss="modal" aria-label="Close">
										<span aria-hidden="true" style={{ fontSize: "23px" }}>
											<img src='../../assets/images/cancel.png' />
										</span>
									</button>
								</div>
								<div className="modal-body">
									<div className='userView taskview_ribbon'>
										<div className='row'>
											<div className='col-lg-4 bor-right'>
												<div className='User_part_left '>
													<div className='ribbon'>
														<div className="ribbon-box orange" style={{ left: '-27px' }}>{dataMom.assignorName ? dataMom.assignorName : account.name}</div>
													</div>
													<div className='user_hotel_logo'>
														<img src={ImportedURL.FILEURL + dataMom.logo} alt="fake_url" />
													</div>
													<div className='User_part_right'>
														<p className='hotel_name'>{dataMom.hotelName}</p>
													</div>
												</div>
												<div className='User_part_right'>
												</div>
											</div>
											<div className='col-lg-4 px-4 calendarborder'>
												<div className='User_part_right'>
													<p className='mb-4 break_word'><label>Agenda</label><br></br><span className='ml-0'>{dataMom.agenda}</span></p>
													<p className='mb-4 break_word'><label>Participants Name</label><br></br><span className='ml-0'>{dataMom.usernameName}</span></p>
													<p className='mb-4'><label>Duration</label><br></br><span className='ml-0'>{dataMom.duration + " : " + ((dataMom.duration == 'Monthly' || dataMom.duration == 'Bi-Weekly' || dataMom.duration == 'Weekly' || dataMom.duration == "Yearly" || dataMom.duration == "Half-Yearly") ? 'Start ' + moment(dataMom.startdate).format(dFormat) : 'From ' + moment(selectStart).format(dFormat)) + ((dataMom.duration == 'Monthly' || dataMom.duration == 'Bi-Weekly' || dataMom.duration == 'Weekly' || dataMom.duration == "Yearly" || dataMom.duration == "Half-Yearly") ? '' : " to " + moment(selectEnd).format(dFormat)) + ' at ' + moment(dataMom.starttime).format(tFormat) + '-' + moment(dataMom.endtime).format(tFormat)}</span></p>
													{
														(dataMom.duration == 'Monthly' || dataMom.duration == 'Bi-Weekly' || dataMom.duration == 'Weekly' || dataMom.duration == "Yearly" || dataMom.duration == "Half-Yearly")
														&&
														<p className='mb-4'><label>Number Of Meeting</label><br></br><span className='ml-0'>{dataMom.noofperiod}</span></p>
													}
													{
														dataMom.editeduserName
															?
															<p className='mb-4'><label>Edited By  </label><br></br><span className='ml-0'>{dataMom.editeduserName ? dataMom.editeduserName : "---"}</span></p>
															: ""
													}
												</div>
												<div className='User_part_right'>
												</div>
											</div>
											<div className='col-lg-4'>
												<div className='User_part_left '>
													<div className='User_part_right'>
														<div className='d-flex' style={{ justifyContent: 'space-between' }}>
															<div>
																<p className='mb-4'><label>Preview Calendar</label></p>
															</div>
														</div>
														{dataMom.selectdate && dataMom.selectdate.length > 0 && <Calendar
															style={{}}
															mapDays={({ date }) => {
																if (!dataMom.weekendstatus) {
																	let isWeekend = [0, 6].includes(date.weekDay.index)
																	if (isWeekend) return {
																		disabled: true,
																		style: { color: "#ccc" },
																	}
																}
															}}
															multiple
															value={dataMom.selectdate}
															readOnly
														/>}
													</div>
												</div>
												<div className='User_part_right'>
												</div>
											</div>
											<div className='col-lg-12'>
												<div className='User_part_right'>
													<p className='mt-2 break_word'>
														<label style={{ fontWeight: "bolder" }}>Description</label>  <br />

														{
															(dataMom.description != undefined && dataMom.description)
															&&
															(dataMom.description.length < 256 ? <span>{dataMom.description}</span> : (
																<>
																	{
																		this.state.readMoreValue ?
																			<span>{dataMom.description}</span>
																			:
																			<span>{dataMom.description.slice(0, 250) + "..."}</span>
																	}
																	< span className='read_more' onClick={(e) => this.toggleReadMore()}>{this.state.readMoreValue ? <span> read less</span> : <span>read more</span>}</span>
																</>
															))
														}
													</p>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div id='overlay-model' style={{ display: spinnerMom ? 'block' : 'none' }}></div>
								{spinnerMom ?
									<div className='model_loader_poss'>
										<img className='loader_img_style_model' src='../../assets/images/load.png' />
										<Spinner className='spinner_load_model' animation="border" variant="info" >
										</Spinner>
									</div>
									: ''}
							</div>
						</div>
					</div> */}
				</div>
			</div >
		);
	}
}
const mapStateToProps = state => ({
	darkHeader: state.settings.isDarkHeader,
	NotificationState: state.notification,
	AccountState: state.account,
	LanguageState: state.language,
	LandingState: state.landing,
	TodoListState: state.todolist,
})

function mapDispatchToProps(dispatch) {
	return bindActionCreators({
		ListUser: AC_LIST_USER, LanguageChange: AC_HANDLE_LANGUAGE_CHANGE,
		ViewTodo: AC_VIEW_TO_DO_LIST,
		spinnerModalTodo: AC_MODAL_SPINNER_TO_DO_LIST,
	}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Header);
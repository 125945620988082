import React, { Component } from 'react';
import Menu from './Menu';
import { Container, Button, lightColors, darkColors } from 'react-floating-action-button';
import { Link, Redirect } from 'react-router-dom';
export default class Layout extends Component {
	render() {
		return (
			<div id="main_content">
				<Menu {...this.props} />
			</div>
		);
	}
}

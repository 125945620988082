import React, { Component } from 'react'
import ImportedURL from '../../common/api'
import { AC_ROOM_CHECKBOX_CHANGE, AC_HANDLE_ROOM_AMENITY_REMOVE } from '../../actions/settingroomAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { onErrorImage } from '../../common/validate';

class Amenitycontent extends Component {

  checkBoxTrue = (i, item) => {
    const data = this.props.RoomState.room.amenity;

    let status = (data != undefined && data.length > 0) ? data.find((e) => e.amenity == item) : false
    if (status) {
      this.props.AC_HANDLE_ROOM_AMENITY_REMOVE(item);
    } else {
      this.props.AC_ROOM_CHECKBOX_CHANGE({ amenity: item, status: true });
    }
  }
  CheckedStatus = (id) => {
    const data = this.props.RoomState.room.amenity;
    if (data != undefined && data.length > 0) {
      let status = data.find((e) => { return e.amenity == id })

      if (status) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }
  render() {
    let CheckedStatus = this.CheckedStatus(this.props.item1._id)
    const data = this.props.RoomState.room.amenity;
    return (
      <div className='col-lg-3 col-md-3 roomtype-setting-amenity mt-2'>
        <div>
          <label className="custom-control custom-checkbox" style={{ minHeight: '16px' }}>
            <input id={this.props.item1.amenity} type="checkbox" className="custom-control-input" name="example-checkbox1" checked={CheckedStatus} onChange={(event) => this.checkBoxTrue(this.props.i, this.props.item1._id)} />
            <span className="custom-control-label"></span>
          </label>
        </div>
        <div>
          <img for={this.props.item1.amenity} className="icon-stepform" src={ImportedURL.FILEURL + this.props.item1.icon} width="100px" alt='' onError={onErrorImage} />
        </div>
        <div style={{ textAlign: "center", fontSize: '14px', marginLeft: '12px' }}>
          <span for={this.props.item1.amenity}>{this.props.item1.amenity}</span>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  RoomState: state.settingroom,
  HotelState: state.hotel,
  AccountState: state.account,
  RoomtypeState: state.roomtype,
  RoomareaAmenityState: state.roomareaamenity,
});
function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    AC_ROOM_CHECKBOX_CHANGE, AC_HANDLE_ROOM_AMENITY_REMOVE
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Amenitycontent);

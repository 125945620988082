import React, { Component } from 'react'
import { Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AC_SETTING_ROOM_HANDLE_CHANGE, AC_VIEW_SETTING_ROOM, AC_EMPTY_ROOM } from '../../actions/settingroomAction';
import ImportedURL from '../../common/api';
import ViewAmenitycontent from './viewamenitycontent';
import { AC_LIST_CONFIGOPTIONS_BED_TYPE, AC_LIST_CONFIGOPTIONS_MATTRESS } from '../../actions/configurationAction';
import { AC_SPINNER_ROOMTYPE_ENQUIRY, AC_VIEW_ROOMTYPE_ENQUIRY } from '../../actions/settingRoomtypeAction';
import { formatPhoneNumber, onErrorImage } from '../../common/validate';

class ViewRoomtypeEnquiry extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewData: ''
        }
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.SpinnerRoomType();
        this.props.ViewRoomtypeEnquiry(id);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    handleback = e => {
        this.props.HandleInputChange('tabposition', 'rooms');
    }
    render() {
        const { AccountState, SettingRoomtypeState } = this.props;
        const account = AccountState.account;
        let data = SettingRoomtypeState.viewRoomtypeEnquiry;
        let dataSpinner = SettingRoomtypeState.spinner;
        const languageData = AccountState.account.SelectedlanguageData
        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span> <i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-setting-roomtype"><h6>{(languageData && languageData.ROOM_TYPE_ENQUIRY) ? languageData.ROOM_TYPE_ENQUIRY : "Room Type Enquiry"}</h6></Link> <span> <i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>{(languageData && languageData.ROOM_TYPE_ENQUIRY) ? "View " + languageData.ROOM_TYPE_ENQUIRY : "View Room Type Enquiry"}</h6></p>
                        </div>
                        <div className="section-body  pt-3">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div
                                        className="tab-pane fade show active"
                                        id="Departments-list"
                                        role="tabpanel"
                                    >
                                        <div className="card common_print_pageg">
                                            <div className="card-body">
                                                <div className="header-action">
                                                    <Link to={'/list-setting-roomtype'}><button type="button" className="btn btn-primary d-flex  align-items-center" id='Add' onClick={this.handleback}><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                                </div>
                                                <div className='card-title d-flex'>
                                                    <div className='rounded_icon'><i className="icon-home mr-2 "></i></div>
                                                    <h3 className="card-title " style={{ marginTop: '10px' }}>{(languageData && languageData.ROOM_TYPE_ENQUIRY) ? "View " + languageData.ROOM_TYPE_ENQUIRY : "View Room Type Enquiry"}</h3>
                                                </div>

                                                <div className="card-body border-0" id='print_page'>
                                                    <div className="alignment_fix_roomins">
                                                        {(data.hotel && ImportedURL.SUPERADMIN.includes(account.role)) ?
                                                            <div className="card c_grid c_yellow">
                                                                <div className="card-body text-center view_hotel_card">
                                                                    <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                                                        <div className='top_img_guestdetails'>
                                                                            <div className="img_size">
                                                                                <img src={ImportedURL.FILEURL + data.logo} alt="fake_url" className='contain_image' onError={onErrorImage} />
                                                                            </div>
                                                                            <div className='text-left'>
                                                                                <h6 className="mt-5 mb-0">{(data.hotelName !== undefined) || (data.hotelName != null) ? data.hotelName : ''}</h6>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            : ''}

                                                        <div className="view_hotel_sec card c_grid c_yellow ">
                                                            <div className='card-print'>
                                                                <Row>
                                                                    <div className="Over_all_table_style col-lg-12 col-md-12 mb-2">
                                                                        <table className="table card-table">
                                                                            <tbody>
                                                                                {data.name ? <tr>
                                                                                    <td><h5 className="table_text_left">Name</h5></td>
                                                                                    <td><h5 className="table_text_left">:</h5></td>
                                                                                    <td> <p className='table_text_right'>{data.name}</p></td>
                                                                                </tr> : ''}
                                                                                {data.email ? <tr>
                                                                                    <td><h5 className="table_text_left">Email</h5></td>
                                                                                    <td><h5 className="table_text_left">:</h5></td>
                                                                                    <td> <p className='table_text_right'>{data.email}</p></td>
                                                                                </tr> : ''}
                                                                                {data.phonenumber ? <tr>
                                                                                    <td><h5 className="table_text_left">Phone #</h5></td>
                                                                                    <td><h5 className="table_text_left">:</h5></td>
                                                                                    <td> <p className='table_text_right'>{(data.cc ? data.cc + ' ' : '') + formatPhoneNumber(data.phonenumber, data.ccCode)}</p></td>
                                                                                </tr> : ''}
                                                                                {data.roomtype ? <tr>
                                                                                    <td><h5 className="table_text_left">Room Type</h5></td>
                                                                                    <td><h5 className="table_text_left">:</h5></td>
                                                                                    <td> <p className='table_text_right'>{data.roomtype}</p></td>
                                                                                </tr> : ''}
                                                                                {data.room ? <tr>
                                                                                    <td><h5 className="table_text_left">Room #</h5></td>
                                                                                    <td><h5 className="table_text_left">:</h5></td>
                                                                                    <td> <p className='table_text_right'>{data.room}</p></td>
                                                                                </tr> : ''}
                                                                                {data.adult ? <tr>
                                                                                    <td><h5 className="table_text_left">Adult</h5></td>
                                                                                    <td><h5 className="table_text_left">:</h5></td>
                                                                                    <td> <p className='table_text_right'>{data.adult}</p></td>
                                                                                </tr> : ''}
                                                                                {data.children ? <tr>
                                                                                    <td><h5 className="table_text_left">Children</h5></td>
                                                                                    <td><h5 className="table_text_left">:</h5></td>
                                                                                    <td> <p className='table_text_right'>{data.children}</p></td>
                                                                                </tr> : ''}
                                                                                {data.checkindate ? <tr>
                                                                                    <td><h5 className="table_text_left">Check-In Date</h5></td>
                                                                                    <td><h5 className="table_text_left">:</h5></td>
                                                                                    <td> <p className='table_text_right'>{data.checkindate}</p></td>
                                                                                </tr> : ''}
                                                                                {data.checkoutdate ? <tr>
                                                                                    <td><h5 className="table_text_left">Check-Out Date</h5></td>
                                                                                    <td><h5 className="table_text_left">:</h5></td>
                                                                                    <td> <p className='table_text_right'>{data.checkoutdate}</p></td>
                                                                                </tr> : ''}
                                                                                {data.message ? <tr>
                                                                                    <td><h5 className="table_text_left">Message</h5></td>
                                                                                    <td><h5 className="table_text_left">:</h5></td>
                                                                                    <td> <p className='table_text_right'>{data.message}</p></td>
                                                                                </tr> : ''}
                                                                            </tbody>
                                                                        </table>
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                        <div className='common_footer_print'>
                                                            <p><a href=''>info@myhotelai.com</a> | <a href=''>www.myhotelai.com </a>|<a href=''>admin.myhotelai.com</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div id="overlay" style={{ display: (dataSpinner ? 'block' : 'none') }}></div>
                {(dataSpinner &&
                    <div className='common_loader'>
                        <img className='loader_img_style_common' src='../../assets/images/load.png' />
                        <Spinner className='spinner_load_common' animation="border" variant="info" >
                        </Spinner>
                    </div>
                )}
            </>
        )
    }
}

const mapStateToProps = state => ({
    SettingRoomtypeState: state.settingroomtype,
    HotelState: state.hotel,
    AccountState: state.account,
    ConfigurationState: state.configuration,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ViewRoomtypeEnquiry: AC_VIEW_ROOMTYPE_ENQUIRY,
        SpinnerRoomType: AC_SPINNER_ROOMTYPE_ENQUIRY,
        HandleInputChange: AC_SETTING_ROOM_HANDLE_CHANGE
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewRoomtypeEnquiry);
import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { onErrorImage } from '../../common/validate';

class AggridSpinnerComponent extends Component {
    render() {
        const { spinner, overlay } = this.props;
        return (
            <>
                {overlay === true ? <div id='overlay' style={{ display: spinner ? 'block' : 'none' }}></div> : ''}
                {
                    spinner ?
                        <div className='common_loader_ag_grid'>
                            <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' onError={onErrorImage} />
                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                            </Spinner>
                        </div>
                        : ''
                }
            </>
        );
    }
}

export default AggridSpinnerComponent;
import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { Link, NavLink, Redirect } from 'react-router-dom';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import jsPDF from "jspdf";
import Select from 'react-select';
import moment from 'moment';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import Spinner from 'react-bootstrap/esm/Spinner';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import { AC_LIST_CONFIGURATION, AC_RESET_CONFIGURATION, AC_EMPTY_CONFIGURATION } from '../../actions/configurationAction';
import ErrorResponse from '../../utilities/Error/errorstatus';
import showSwalDialog from '../../utilities/Modal/swal';


const initialState = {
    modalType: "Add",
    columnDefs: [
        { headerName: '#', valueGetter: "node.rowIndex+1", width: 50, hide: true, sortable: false, filter: false, cellStyle: { 'text-align': 'center' } },
        // {
        //     headerName: 'Logo', field: 'logo', sortable: false, filter: false, "resizable": false, width: 100, cellStyle: { 'text-align': 'center' },
        //     cellRenderer: function (params) {
        //         return `<img src=${ImportedURL.FILEURL + params.value} alt='' width='65px' height='65px' class="contain_image"/>`;
        //     }
        // },
        // {
        //     headerName: 'Contact Person', field: 'name', width: 100, cellStyle: { 'text-align': 'left' }, floatingFilter: true,
        //     valueGetter: function (params) {
        //         if (params.data.name) {
        //             return params.data.name
        //         }
        //     }
        // },
        {
            headerName: 'Name', field: 'name', width: 170, cellStyle: { 'text-align': 'left' }, floatingFilter: true,
        },
        {
            headerName: 'Slug', field: 'slug', width: 170, cellStyle: { 'text-align': 'left' }, floatingFilter: true,
            cellRenderer: function (params) {
                if (params.data && params.data.slug) {
                    return `<span type="button" class="config-redirect"  data-action-type="Edit">` + params.data.slug + `</span>`;
                }
            }
        },
        {
            headerName: 'Status', width: 100, field: 'status', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header',
            cellRenderer: function (params) {
                if (params.data && params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else if (params.value == 0) {
                    return '<span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
    },
    nameError: false,
    id: '',
    searchhoteloption: '',
    searchhotel: '',
    id: '',
    perPage: 25
}
class listConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.setState({ modalType: "Edit", id: event.data._id, nameError: false });
        }

        if (value === 'Delete') {
            showSwalDialog('Are you sure you want to delete?', '', 'Ok', 'Cancel', 'assets/images/delete.png').then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeleteConfiguration + '/' + event.data._id)
                        .then((res) => {
                            Success((languageData && languageData.CONFIGURATION ? languageData.CONFIGURATION : 'Configuration') + (languageData && languageData.DELETED_SUCCESSFULLY ? languageData.DELETED_SUCCESSFULLY : ' deleted successfully'));
                            this.setState({ searchhoteloption: '' })
                            this.props.AC_LIST_CONFIGURATION();
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
        if (value === 'Status') {
            showSwalDialog('Are you sure you want to change the status?', '', 'Ok', 'Cancel', 'assets/images/status.png').then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'configurations' })
                        .then((data) => {
                            this.props.AC_LIST_CONFIGURATION();
                            Success((languageData && languageData.STATUS ? languageData.STATUS : 'Status') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " updated successfully"))
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
    }

    componentDidMount() {
        this.props.ListHotel();
        this.props.AC_LIST_COUNTRY();
        this.props.AC_LIST_CONFIGURATION();
        this.props.AC_RESET_CONFIGURATION();
        const { AccountState } = this.props;
        this.setState({ hotelName: AccountState.account.hotelName })

        this.props.EmptyConfiguration()
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };


    exportToCSV = () => {
        const account = this.props.AccountState.account;
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: ['name', 'slug'],
            fileName: "configuration" + "_" + (this.state.hotelName && (this.state.hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }
    exportPDF = () => {
        const account = this.props.AccountState.account;
        const api = this.gridApi;
        const filteredRows = api.getModel().rowsToDisplay
        const exportDataTemp = filteredRows.map(rowNode => rowNode.data);
        const firstRow = api.getFirstDisplayedRow();
        const lastRow = api.getLastDisplayedRow();
        const exportData = exportDataTemp.slice(firstRow, lastRow + 1);
        const selectedProperties = ['name', 'slug'];
        const selected = exportData.map((item, i) => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    selectedData[property] = item[property] ? item[property] : '---';
                }
            });
            return selectedData;
        });
        let data = {
            title: " Configuration List",
            head: ['#', 'Name', 'Slug'],
            body: selected,
            hotelNameExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hotelNameExport : '',
            hoteladdressExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "configuration" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }


    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    };
    handleFilterChanged = () => {
        const api = this.gridApi;
        if (api && api.getDisplayedRowCount() === 0) {
            api.showNoRowsOverlay();
            this.setState({ hideExport: true })
        } else {
            api.hideOverlay();
            this.setState({ hideExport: false })
        }
    };
    render() {
        if (this.state.modalType === "View") return <Redirect to={'view-configuration/' + this.state.id} />
        if (this.state.modalType === "Edit") return <Redirect to={'edit-configuration/' + this.state.id} />
        if (this.state.modalType === "Message") return <Redirect to={'message-configuration/' + this.state.id} />

        const { AccountState, ConfigurationState, HotelState } = this.props;
        const listHotelData = HotelState.listhotels;
        const account = AccountState.account;
        const rowData = ConfigurationState.listConfiguration;
        const languageData = AccountState.account.SelectedlanguageData
        const spinner = ConfigurationState.spinner;
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Configuration" });
        const editOption = Previlege?.edit ? '<button type="button" class="btn btn-icon" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
        const deleteOption = (ImportedURL.SUPERADMIN.includes(AccountState.role)) ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : '';
        const template =
            editOption
            + deleteOption
            + '</div>'
        rowData.forEach(object => {
            object.actions = template;
        });

        var dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + "  " + (account.timeformat != '' ? account.timeformat : "hh:mm A")
        var dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY');
        rowData.forEach(object => {
            object.dtFormat = dtFormat;
        });

        rowData.forEach(object => {
            object.dFormat = dFormat;
        });


        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]






        var hotelOptionSearch = [];
        if (listHotelData.length > 1) {
            hotelOptionSearch.push({ label: 'All', value: '', name: 'searchhotel' })
        }
        if (listHotelData.length > 0) {
            listHotelData.filter(filterItem => filterItem.status === true).map(item => {
                hotelOptionSearch.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'searchhotel' });
            })
        }

        return (
            <div>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-configuration"><h6 className="highlights_breadcrump">{languageData && languageData.CONFIGURATION ? languageData.CONFIGURATION : "Configuration"}{languageData && languageData.LIST ? languageData.LIST : " List"} </h6></Link></p>
                    </div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header tit_res">
                                            <div className='d-flex'>
                                                <div className='rounded_icon'><i class="fa fa-cogs" aria-hidden="true"></i></div>
                                                <div className='d-flex align-items-center'>
                                                    <h3 className="card-title d-flex "> <span className='pt-2'> {languageData && languageData.CONFIGURATION ? languageData.CONFIGURATION : "Configuration "}{languageData && languageData.LIST ? languageData.LIST : " List"} </span> </h3>
                                                </div>
                                            </div>
                                            <div className="card-options add_card_options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab">
                                                    </ul>
                                                    <div className="header-action">
                                                        {Previlege?.add ? <Link to='/add-configuration'><button type="button" className="btn btn-primary" id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button></Link> : ''}
                                                    </div>
                                                </div>
                                                {rowData && rowData.length > 0 && !this.state.hideExport ? <>
                                                    <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                        <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                    </div>
                                                </> : []}
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-3 col-sm-2 col-xl-3">
                                                    <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                            className='limit_size'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        rowDragManaged={true}
                                                        animateRows={true}
                                                        onGridReady={this.onGridReady}
                                                        overlayNoRowsTemplate={"No rows to display"}
                                                        onFilterChanged={this.handleFilterChanged}
                                                    >
                                                    </AgGridReact>
                                                </div>
                                                {spinner ?
                                                    <div className='common_loader_ag_grid'>
                                                        <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                        </Spinner>
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    AccountState: state.account,
    ConfigurationState: state.configuration,
    HotelState: state.hotel,
    CountryState: state.country,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListHotel: AC_LIST_HOTEL,
        AC_RESET_CONFIGURATION,
        AC_LIST_COUNTRY,
        EmptyConfiguration: AC_EMPTY_CONFIGURATION,
        AC_LIST_CONFIGURATION,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(listConfiguration);
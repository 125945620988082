import React, { Component } from 'react'
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import ImportedURL from '../../../common/api';
import { AC_EMPTY_TICKET_COUNT, AC_GET_HOTEL_REPORT } from '../../../actions/ticketAction';
import Userreportchart from './userreportchart';
import Placewisechart from './placewisechart';
import Socialreviewschart from './socialreviewschart';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { onErrorImage } from '../../../common/validate';
class ListTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hotelsList: [],
            hotel: ''
        }
    }

    componentDidMount() {
        const account = this.props.AccountState.account;
        if (!ImportedURL.SUPERADMIN.includes(account.role)) {
            this.props.EmptyTicketCount();
            this.props.ListHotelReport();
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    handleHotelChange = (e) => {
        this.setState({ hotel: e.value });
        this.props.EmptyTicketCount();
        this.props.ListHotelReport({ hotel: e.value });
    };

    render() {
        const { LandingState, TicketState, AccountState } = this.props;
        const account = AccountState.account;
        const ticketStatusCount = TicketState;
        const spin = ticketStatusCount.spinner;
        const languageData = AccountState.account.SelectedlanguageData
        const listhotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[0] : [];
        const hotelOptions = [];
        // hotelOptions.push({ label: `All`, value: '', name: 'hotel' });

        listhotels.filter(filterItem => filterItem.status === true).map((item) => {
            hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
        })
        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/hotel-ticket-reports"><h6 className="highlights_breadcrump">{languageData && languageData.HOTEL_REPORTS ? languageData.HOTEL_REPORTS : "Hotels Reports"}</h6></Link></p>
                    </div>
                    <div className="section-body pt-3 user_sec ticket_roport">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header hd_bg_tic_rep">
                                            <div className='rounded_icon'><i className="fa fa-bar-chart mr-2 "></i></div>
                                            <h3 className="card-title">{languageData && languageData.HOTEL_REPORTS ? languageData.HOTEL_REPORTS : "Hotels Reports"}</h3>
                                            <div className="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="header-action" >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {ImportedURL.SUPERADMIN.includes(account.role) && <div className="col-sm-4 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">{languageData && languageData.SEARCH ? languageData.SEARCH : "Search"} {languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel"}</label>
                                                        <Select
                                                            placeholder="Select Hotel"
                                                            name="hotel"
                                                            options={hotelOptions}
                                                            onChange={this.handleHotelChange}
                                                            formatOptionLabel={(e) => (
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                    <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>}
                                            </div>
                                            {this.state.hotel || !ImportedURL.SUPERADMIN.includes(account.role) ?
                                                <>
                                                    <div className="row">
                                                        <Userreportchart />
                                                    </div>
                                                    <div className="row">

                                                        <Placewisechart /> :
                                                    </div>
                                                    <div className="row">
                                                        <Socialreviewschart />
                                                    </div>

                                                </>
                                                :
                                                <>
                                                    <div className="row">
                                                        <div className="card">
                                                            <div className="card-header"><h3 className="card-title">No. of tickets created by individuals</h3></div>
                                                            <div className="card-body no_records text-center d-flex align-items-center justify-content-center">
                                                                <div style={{ maxWidth: "340px" }}>
                                                                    <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid mb-4 mt-4" style={{ maxWidth: "272px" }} />
                                                                    <h5 className="mb-2">Choose a hotel to display records</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="card">
                                                            <div className="card-header"><h3 className="card-title">No. of tickets raised in a particular room / other place</h3></div>
                                                            <div className="card-body no_records text-center d-flex align-items-center justify-content-center">
                                                                <div style={{ maxWidth: "340px" }}>
                                                                    <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid mb-4 mt-4" style={{ maxWidth: "272px" }} />
                                                                    <h5 className="mb-2">Choose a hotel to display records</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="card">
                                                            <div className="card-header"><h3 className="card-title">Latest 100 Guest Reviews</h3></div>
                                                            <div className="card-body no_records text-center d-flex align-items-center justify-content-center">
                                                                <div style={{ maxWidth: "340px" }}>
                                                                    <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid mb-4 mt-4" style={{ maxWidth: "272px" }} />
                                                                    <h5 className="mb-2">Choose a hotel to display records</h5>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="overlay" style={{ display: spin ? 'block' : 'none' }}></div>
                {spin ?
                    <div className='common_loader'>
                        <img className='loader_img_style_common' src='../../assets/images/load.png' />
                        <Spinner className='spinner_load_common' animation="border" variant="info" >
                        </Spinner>
                    </div>
                    : ''
                }
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    TicketState: state.ticket,
    LandingState: state.landing,
})
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListHotelReport: AC_GET_HOTEL_REPORT,
        EmptyTicketCount: AC_EMPTY_TICKET_COUNT,
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ListTicket);
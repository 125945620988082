
const initialState = {
    room: {
        hotel: '',
        roomtype: '',
        roomname: '',
        maxguestcount: '',
        builtunit: '',
        allotment: '',
        description: '',
        roomimage: [],
        actype: 'AC',
        minnight: '',
        amenity: [],
        checkintime: '',
        checkouttime: '',
        extraamenity: '',
        actype: '',
        itemvalue: [{
            bedname: '',
            width: '',
            length: '',
            mattress: '',
            quantity: '',
        }],
        tabposition: 'roomtype',
    },
    listSettingRoomtype: [],
    listRoomtypeEnquiry: [],
    viewRoomtypeEnquiry: {},
    spinner: true,
    total: 0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'SETTING_ROOMTYPE_HANDLE_CHANGE':
            return Object.assign({}, state, {
                room: {
                    ...state.room,
                    [action.name]: action.value
                }
            })
        case 'HANDLE_AMENITY_REMOVE':
            return {
                ...state,
                room: {
                    ...state.room,
                    amenity: [...state.room.amenity.filter((e, i) => e.amenity !== action.id)]
                }
            }
        case 'HANDLE_FACILITY_REMOVE':
            return {
                ...state,
                room: {
                    ...state.room,
                    facility: [...state.room.facility.filter((e, i) => e.facility !== action.id)]
                }
            }
        case 'SETTING_ROOMTYPE_HANDLE_FORM_CHANGE':
            return Object.assign({}, state, {
                roominfo: {
                    ...state.roominfo,
                    [action.name]: action.value
                }
            })
        case 'HANDLE_MULTIROW_CLICK':
            return {
                ...state,
                room: {
                    ...state.room,
                    itemvalue:
                        [
                            ...state.room.itemvalue.slice(0, action.i),
                            {
                                ...state.room.itemvalue[action.i],
                                [action.name]: action.value
                            },
                            ...state.room.itemvalue.slice(action.i + 1)
                        ]
                }
            }
        case 'CHECKBOX_CHANGE':
            return {
                ...state,
                room: {
                    ...state.room,
                    amenity: [...state.room.amenity, action.value]
                }
            }
        case 'RESET_SETTING_ROOMTYPE':
            return Object.assign({}, state, {
                room: initialState.room
            })
        case 'LIST_SETTING_ROOMTYPE':
            return Object.assign({}, state, {
                listSettingRoomtype: action.payload,
                total: action.total,
                spinner: action.spinner
            })
        case 'VIEW_SETTING_ROOMTYPE':
            return Object.assign({}, state, {
                room: action.payload,
                spinner: false,
            })

        case 'HANDLE_ROOMTYPEBED_UPDATE':
            if (action.id === '') {
                return {
                    ...state,
                    room: {
                        ...state.room,
                        itemvalue: [...state.room.itemvalue, action.value]
                    }
                }
            }
            else {
                return {
                    ...state,
                    room: {
                        ...state.room,
                        itemvalue: [...state.room.itemvalue.filter((event, i) => i !== action.id)]
                    }
                }
            }
        case 'EMPTY_ROOMTYPE':
            return Object.assign({}, state, {
                spinner: true
            })
        case 'LIST_ROOMTYPE_ENQUIRY':
            return Object.assign({}, state, {
                listRoomtypeEnquiry: action.payload,
                total: action.total,
                spinner: false
            })
        case 'VIEW_ROOMTYPE_ENQUIRY':
            return Object.assign({}, state, {
                viewRoomtypeEnquiry: action.payload,
                spinner: false,
            })
        case 'SPINNER_ROOMTYPE_ENQUIRY':
            return Object.assign({}, state, {
                spinner: true
            })

        default:
            return state;
    }
}
import React, { Component } from 'react'
import { connect } from 'react-redux';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import axios from 'axios';
import URLImported from '../../common/api';
import 'react-loading-skeleton/dist/skeleton.css'
import ImportedURL from '../../common/api';
import { bindActionCreators } from 'redux';
import { GetManagementUserStatus } from '../../common/validate';
import HotelSelectComponent from '../../utilities/SelectOption/hotelselect';
import DashboardCount from './dashboardcount';
import BookingCountChart from './bookingcountchart';
import LatestReview from './latestreview';
import HotelCountChart from './hotelcountchart';
import ViewReviewModal from './viewreviewmodal';

class DashBoardIndex extends Component {
    constructor(props) {
        super(props);
        this.state = {
            uniqueUrl: [],
            uniqueCity: [],
            uniqueCountry: [],
            orignalData: [],
            historyData: [],
            options: { labels: [] },
            series: [],
            feedbacks: [],
            hotelEmployeeList: [],
            empOptions: {
                chart: {
                    height: "100%",
                    events: {
                        mouseLeave: function (event, chartContext, config) {
                            chartContext.resetSeries()
                        }
                    }
                },
                labels: [],
                dataLabels: {
                    formatter: function (val, opts) {
                        return opts.w.config.series[opts.seriesIndex]
                    },
                },
            },
            empSeries: [],
            ticketList: [],
            ticketOptions: {
                labels: [],
                dataLabels: {
                    formatter: function (val, opts) {
                        return opts.w.config.series[opts.seriesIndex]
                    },
                },
            },
            ticketSeries: [],
            spinner: true,
            secSpinner: true,
            viewMore: '',
            reviewTypeView: '',
            reviewType: '',
            hotel: '',
            viewMoreRedirect: false,
            countReview: [],
            tickets: [],
            viewReviewRedirect: false,
            viewReviewRedirectId: '',
            titleView: '',
            descriptionView: '',
            viewNotificationData: {},
            reviewSpin: true,
            ticketCount: 0,
            userCount: 0,
            hotelCount: 0,
        }
    }

    async componentDidMount() {
        const { AccountState } = this.props;
        if (ImportedURL.SUPERADMIN.includes(AccountState.role)) {
            await this.fetchAdminData();
        }
        await this.fetchDashboardData();
    }

    componentDidUpdate(prevProps) {
        if (this.props.LandingState !== prevProps.LandingState) {
            this.processHistoryData();
            // Data from Redux has updated, you can perform actions here
            // or simply let the component re-render
        }
    }

    fetchAdminData = async () => {
        try {
            const countsResponse = await axios.get(URLImported.API.getCounts);
            this.processCountsData(countsResponse.data);
            this.processHistoryData();
        } catch (err) {
            console.error("Error fetching admin data", err);
        }
    }

    fetchDashboardData = async (hotelId = "") => {
        try {
            const response = await axios.get(`${URLImported.API.getDashboardPageData}?hotel=${hotelId}`);
            const { employeeCount, ticketPriority, badReviews } = response.data;
            this.processEmployeeData(employeeCount);
            this.processTicketData(ticketPriority);
            this.setState({ feedbacks: badReviews, secSpinner: false });
        } catch (err) {
            console.error("Error fetching dashboard data", err);
        }
    }

    processCountsData = (data) => {
        this.setState({
            hotelCount: data.data[0],
            userCount: data.data[1],
            ticketCount: data.data[2],
            spinner: false,
        });
    }

    // Implement logic to process history data
    processHistoryData = () => {
        let data = this.props.LandingState ? this.props.LandingState.historydata : [];
        let uniqueUrlSet = new Set(this.state.uniqueUrl);
        let uniqueCitySet = new Set(this.state.uniqueCity);
        let uniqueCountrySet = new Set(this.state.uniqueCountry);

        const resultData = data.map(history => {
            let url = this.processUrl(history.url);
            history.url = url;
            uniqueUrlSet.add(url);
            uniqueCitySet.add(history.city);
            uniqueCountrySet.add(history.country);

            return history;
        });

        const { counts, labelArray, serieslArray } = this.calculateCounts(data);

        this.setState({
            spinner: false,
            series: serieslArray,
            options: { ...this.state.options, labels: labelArray },
            orignalData: resultData,
            historyData: resultData,
            uniqueUrl: [...uniqueUrlSet],
            uniqueCity: [...uniqueCitySet],
            uniqueCountry: [...uniqueCountrySet]
        });
    }

    processUrl = (url) => {
        if (url.includes('www')) {
            url = url.replace("http://", '');
        } else {
            url = url.replace("http://", 'www.').replace("https://", 'www.');
        }
        return url.replace("/store.php", '').split('/')[0];
    }

    calculateCounts = (data) => {
        let counts = data.reduce((accumulator, current) => {
            const name = current.url;
            accumulator[name] = (accumulator[name] || 0) + 1;
            return accumulator;
        }, {});

        let labelArray = [];
        let serieslArray = [];
        Object.entries(counts).sort().forEach(([key, value]) => {
            labelArray.push(key.replace('http://', '').replace('https://', '').replace('/store.php', '').replace('//www.', '').replace('www.', ''));
            serieslArray.push(value);
        });
        return { counts, labelArray, serieslArray };
    }


    // Implement logic to process employee data
    processEmployeeData = (employeeCount) => {
        let hotelEmployeeList = employeeCount;
        let employeeLabelArray = [];
        let employeeSeriesArray = [];
        for (const [key, value] of Object.entries(hotelEmployeeList)) {
            employeeLabelArray.push(key);
            employeeSeriesArray.push(value);
        }
        this.setState({ secSpinner: false, 'empSeries': employeeSeriesArray, empOptions: { ...this.state.empOptions, ...this.state.empOptions, labels: employeeLabelArray } });
    }

    // Implement logic to process ticket priority data
    processTicketData = (ticketPriority) => {
        let ticketLabelArray = [];
        let ticketSerieslArray = [];
        for (const [key, value] of Object.entries(ticketPriority)) {
            let label = "";
            if (key === "1") {
                label = "High";
            } else if (key === "2") {
                label = "Medium";
            } else {
                label = "Low";
            }
            ticketLabelArray.push(label)
            ticketSerieslArray.push(value)
        }
        this.setState({ 'ticketSeries': ticketSerieslArray, ticketOptions: { ...this.state.ticketOptions, ...this.state.ticketOptions, labels: ticketLabelArray } });
    }

    setTicketHotel = (e) => {
        const { value } = e;
        this.fetchDashboardData(value);
    }

    viewRedirectClick = (id) => {
        this.setState({ reviewSpin: true })
        axios.get(ImportedURL.API.getReviewById + '/' + id)
            .then((res) => {
                if (res.data)
                    this.setState({ viewNotificationData: res.data, spinner: false, reviewSpin: false })
            })
    }

    render() {
        const { AccountState, LandingState } = this.props;
        const listHotel = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 5 ? LandingState.landingdata.data[0] : [];
        const account = AccountState.account;
        const managementStatus = GetManagementUserStatus(account.defaultmanagementcategory, account.userId, account.categoryType, account.role);
        const role = AccountState.role;
        var listHotels = '';
        if (account?.allhotels != undefined) {
            listHotels = account?.allhotels;
        } else {
            listHotels = listHotel;
        }
        var hotelOptions = [];
        if (listHotels.length > 1) {
            hotelOptions.push({ label: 'All', value: '', name: 'hotel' })
        }
        if (listHotels.length > 0) {
            listHotels.map(item => {
                if (ImportedURL.SUPERADMIN.includes(role)) {
                    hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
                } else {
                    hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item.hotelid, logo: item.logo, name: 'hotel' });
                }
            })
        }
        return (
            <>
                <div className="section-body mt-3">
                    <div className="container-fluid">
                        <div className="row clearfix">
                        </div>
                        {ImportedURL.SUPERADMIN.includes(role) ? <>
                            <div className="row clearfix common_top_section">
                                <DashboardCount count={this.state.orignalData.length} to={"/booking-count"} icon={"icon-list dash_bar"} title={"Booking Count"} spinner={this.state.spinner} />
                                <DashboardCount count={this.state.hotelCount} to={"/list-hotel"} icon={"fa fa-building-o"} title={"Hotels"} spinner={this.state.spinner} />
                                <DashboardCount count={this.state.userCount} to={"/list-user"} icon={"icon-user"} title={"Users"} spinner={this.state.spinner} />
                                <DashboardCount count={this.state.ticketCount} to={"/tickets"} icon={"fa fa-ticket"} title={"Tickets"} spinner={this.state.spinner} />
                            </div>
                            <BookingCountChart state={this.state} managementStatus={managementStatus} />
                        </> : ''}

                        {managementStatus ?
                            <>
                                <div className="row clearfix">
                                    <div className="col-sm-12 col-md-12">
                                        {ImportedURL.SUPERADMIN.includes(role) &&
                                            <>
                                                <label className="form-label">Search Hotel</label>
                                                <div className="form-group">
                                                    <HotelSelectComponent onChange={this.setTicketHotel} options={hotelOptions} />
                                                </div>
                                            </>
                                        }
                                    </div>
                                    <HotelCountChart column={'col-lg-6 col-md-12 col-sm-6 col-xl-5 ticket_priority_count_dashboard'} title={"Ticket Priority Count"} series={this.state.ticketSeries} options={this.state.ticketOptions} width={"400"} />
                                    <HotelCountChart column={'col-lg-6 col-md-12 col-sm-6 col-xl-7'} className={'employee_count_dashboard'} title={"Employees Count"} series={this.state.empSeries} options={this.state.empOptions} />
                                </div>
                                <LatestReview {...this.props} state={this.state} viewRedirectClick={this.viewRedirectClick} />
                            </> :
                            (AccountState.previleges && AccountState.previleges.length) ?
                                <>
                                    <div className="card card-profile">
                                        <div className="card-body text-center">
                                            <img className="card-profile-img" src="../assets/images/logo.png" alt="fake_url" />
                                            <h4 className="mb-3">Welcome to MyHotel AI</h4>

                                            <p className="mb-4">Ensuring the flawless operation and maintenance of your hotel properties could be stressful. Hence, MyHotel AI comes in as your savior. It is the only AI-powered PMS application in the industry that is tailor-made to suit your organizational needs.</p>
                                        </div>
                                    </div>
                                </>
                                : ''
                        }
                    </div>
                </div>
                <ViewReviewModal {...this.props} state={this.state} />
            </>
        )
    }
}

const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    LandingState: state.landing
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoardIndex);
import React, { Component } from 'react'
import { connect } from 'react-redux';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { bindActionCreators } from 'redux';
import "jspdf-autotable";
import axios from 'axios';
import { AC_LIST_FEEDBACK } from '../../actions/reviewAction';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import ImportedURL from '../../common/api';
import moment from 'moment';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { AC_LIST_ROOM } from '../../actions/roomAction';


const initialState = {
    hotelsreport: [],
    // fromDate: '2021-01-01',
    fromDate: moment(new Date()).format("YYYY-MM-DD")
}

class ListFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    setDateReport = (e) => {
        const { name, value } = e.target;
        if (name == 'fromDate') {
            this.setState({ fromDate: value, toDate: '', month: '', hideReset: true });
            const formData = {
                fromDate: value,
                toDate: '',
            }
            this.getAllData(formData)
        }
        if (name == 'toDate') {
            this.setState({ toDate: value, month: '', hideReset: true });
            const formData = {
                toDate: value,
                fromDate: this.state.fromDate ? this.state.fromDate : ''
            }
            this.getAllData(formData)
        }
    }

    componentDidMount() {
        this.getAllData({ fromDate: this.state.fromDate, toDate: this.state.toDate })

    }


    getMonths = (mon) => {
        let date = new Date();
        date.setDate(1);
        date.setMonth(date.getMonth() - mon);
        let monthName = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        return `${monthName}`;
    }

    getMonthlyReport = e => {
        const { value } = e.target;
        const newval = value == 0 ? 0 : value == 1 ? 1 : 2;

        let date = new Date();
        let startDate = new Date(date.getFullYear(), date.getMonth() - newval, 1);
        let endDate = new Date(date.getFullYear(), date.getMonth() - newval + 1, 0);
        const formData = {
            toDate: moment(endDate).format('yyyy-MM-DD'),
            fromDate: moment(startDate).format('yyyy-MM-DD')
        }
        this.getAllData(formData);
        this.setState({ fromDate: moment(startDate).format('yyyy-MM-DD'), toDate: moment(endDate).format('yyyy-MM-DD'), month: value, hideReset: true });

    }
    filterReset = (e) => {
        const account = this.props.AccountState.account;

        var today = new Date()
        var todayDate = moment(today).format("YYYY-MM-DD");

        this.setState({
            status: '',
            toDate: '',
            fromDate: todayDate,
            month: '',
            hideReset: false,
            spinner: true,
        });

        const formData = {
            fromDate: todayDate,
            toDate: '',
        }
        this.getAllData(formData);
    }

    getAllData = (formData) => {
        this.setState({ spinner: true })

        axios.post(ImportedURL.API.overallreport, formData)
            .then((res) => {
                this.setState({ spinner: false, })

                if (res.data) {
                    this.setState({
                        hotelsreport: res.data
                    })

                }
            }).catch((err) => {
                this.setState({ spinner: false, })
                console.log(err);
            });

    }


    render() {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData

        return (
            <>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/reports"><h6 className="highlights_breadcrump"> {languageData && languageData.REPORTS ? languageData.REPORTS : "Reports"}{languageData && languageData.LIST ? languageData.LIST : ' List'}</h6></Link></p>
                </div>
                <div className="section-body pt-3">
                    {/* <div className='review_sec '> */}
                    <div className="container-fluid">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header top_title_icon">
                                        <div className='rounded_icon'><i className="fa fa-list-alt mr-2"></i></div>
                                        <h3 className="card-title top_title common_title_fs" style={{ fontWeight: 600 }}>Overall Reports</h3>
                                        <div class="card-options">
                                            {/* <div class="input-group"></div> */}
                                            <div className="header-action" style={{ marginTop: '5px' }}>
                                                <Link to='/reports'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2" id='Add' />Back</button></Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='card'>
                                    <div className="card-body">
                                        <div className="row">
                                            <>
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12" style={this.state.queueshow ? { display: 'none' } : {}}>
                                                    <div className="form-group">
                                                        <label className="form-label">From Date</label>
                                                        <div className='site_pass_calander'>
                                                            <input type='date' className="form-control" name="fromDate" value={this.state.fromDate} onChange={this.setDateReport}></input>
                                                            <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12" style={this.state.queueshow ? { display: 'none' } : {}}>
                                                    <div className="form-group">
                                                        <label className="form-label">To Date</label>
                                                        <div className='site_pass_calander'>
                                                            <input type='date' className="form-control" name="toDate" min={this.state.fromDate ? this.state.fromDate : ''} onChange={this.setDateReport} value={this.state.toDate}></input>
                                                            <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 " style={this.state.queueshow ? { display: 'none' } : {}}>
                                                    <div className="form-group">
                                                        <label className="form-label">Archived Reports</label>
                                                        <div className="selectgroup w-100">
                                                            <label className="selectgroup-item">
                                                                <input type="radio" name="month" className="selectgroup-input" value={0} onChange={this.getMonthlyReport} checked={this.state.month === '0'} />
                                                                <span className="selectgroup-button first_month">{this.getMonths(0)}</span>
                                                            </label>
                                                            <label className="selectgroup-item">
                                                                <input type="radio" name="month" className="selectgroup-input" value={1} onChange={this.getMonthlyReport} checked={this.state.month === '1'} />
                                                                <span className="selectgroup-button">{this.getMonths(1)}</span>
                                                            </label>
                                                            <label className="selectgroup-item">
                                                                <input type="radio" name="month" className="selectgroup-input" value={2} onChange={this.getMonthlyReport} checked={this.state.month === '2'} />
                                                                <span className="selectgroup-button last_month">{this.getMonths(2)}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            <div className='col-xl-3 col-lg-3'>
                                                <div className='row' style={{ marginTop: '27px' }}>
                                                    <div className={"col-xl-6 col-lg-6 col-md-3 col-sm-6 col-12 d-flex align-items-center pr-0"} >
                                                        <div >
                                                            {
                                                                this.state.hideReset ?
                                                                    <div>
                                                                        <button style={{ width: '110px', letterSpacing: '1px' }} className='btn social_rest_btn btn-primarys mt-0' type='button' onClick={this.filterReset}><i className="fa fa-refresh" aria-hidden="true" style={{ fontSize: '20px !importent', marginRight: '7px' }}></i> Reset</button>
                                                                    </div>
                                                                    : ''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="row clearfix">

                                    <>
                                        {this.state.hotelsreport.length ?
                                            <>
                                                {
                                                    this.state.hotelsreport.map((rows, i) => {
                                                        return (
                                                            <div className="col-lg-4 col-md-6" key={i}>

                                                                <div className="card" style={{ cursor: 'pointer' }}>

                                                                    <div className="card-body text-center">
                                                                        <img className="img-thumbnail rounded-circle avatar-xxl" src={(rows.hotel && rows.hotel.logo) ? ImportedURL.FILEURL + rows.hotel.logo : "../assets/images/xs/avatar2.jpg"} alt="" />
                                                                        <h6 className="pt-3">{rows.hotel ? `${rows.hotel.name} ${rows.hotel.city} ${rows.hotel.state}` : ''}</h6>
                                                                        <div className="text-center text-muted mb-3">{rows.email}</div>
                                                                    </div>

                                                                    <div className="card-body pb-2">
                                                                        <div className="list-group list-widget">
                                                                            <a className="list-group-item p-1">
                                                                                Total
                                                                                <span className='float-right'><strong>{rows.total}</strong></span>
                                                                            </a>
                                                                            <a className="list-group-item p-1">
                                                                                {/* <span className="badge badge-info">654</span> */}
                                                                                Sent
                                                                                <span className='float-right'><strong>{rows.sent}</strong></span>
                                                                            </a>
                                                                            <a className="list-group-item p-1">
                                                                                Opened
                                                                                <span className='float-right'><strong>{rows.seen}</strong></span>
                                                                            </a>
                                                                            <a className="list-group-item p-1">
                                                                                No phone number
                                                                                <span className='float-right'><strong>{rows.nophone}</strong></span>
                                                                            </a>
                                                                            <a className="list-group-item p-1">
                                                                                Invalid phone number
                                                                                <span className='float-right'><strong>{rows.invalidphone}</strong></span>
                                                                            </a>
                                                                            <a className="list-group-item p-1">
                                                                                Cancelled
                                                                                <span className='float-right'><strong>{rows.noroom}</strong></span>
                                                                            </a>
                                                                            <a className="list-group-item p-1">
                                                                                Repeated phone number
                                                                                <span className='float-right'><strong>{rows.repeated}</strong></span>
                                                                            </a>
                                                                            <a className="list-group-item p-1">
                                                                                Block list
                                                                                <span className='float-right'><strong>{rows.blocklist}</strong></span>
                                                                            </a>
                                                                            <a className="list-group-item p-1">
                                                                                Missing phone number
                                                                                <span className='float-right'><strong>{rows.missingphone}</strong></span>
                                                                            </a>
                                                                        </div>

                                                                    </div>
                                                                    <div className="card-body pt-2">
                                                                        <div className="list-group list-widget">
                                                                            <a className="list-group-item p-1">
                                                                                Completed with google
                                                                                <span className='float-right'><strong>{rows.google}</strong></span>
                                                                            </a>
                                                                            <a className="list-group-item p-1">
                                                                                Completed with tripadvisor
                                                                                <span className='float-right'><strong>{rows.tripadvisor}</strong></span>
                                                                            </a>
                                                                            <a className="list-group-item p-1">
                                                                                Negative feedback
                                                                                <span className='float-right'><strong>{rows.negativefeedbackstatus}</strong></span>
                                                                            </a>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>)
                                                    })
                                                }

                                            </>

                                            :
                                            <div className="card" style={{ height: '455px' }}>
                                                <div className="card-header pt-0">
                                                    <div className="card-body no_records text-center d-flex align-items-center justify-content-center pt-0">
                                                        <div style={{ maxWidth: "340px" }}>
                                                            <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid" style={{ maxWidth: "272px" }} />
                                                            <h5 className="mb-2">No records to display </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </>

                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </div> */}
                </div>
                <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div>
                {
                    this.state.spinner &&
                    <div className='common_loader'>
                        <img className='loader_img_style_common' src='../../assets/images/load.png' />
                        <Spinner className='spinner_load_common' animation="border" variant="info" >
                        </Spinner>
                    </div>
                }

            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    ReviewState: state.review,
    AccountState: state.account,
    HotelState: state.hotel,
    RoomState: state.room,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListHotel: AC_LIST_HOTEL,
        ListRoom: AC_LIST_ROOM,
        ListFeedback: AC_LIST_FEEDBACK
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ListFeedback);
import React, { Component } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import ChartComponent from '../../utilities/Chart/chart';
import { onErrorImage } from '../../common/validate';

class HotelCountChart extends Component {
    render() {
        const { column, className, title, series, options, width } = this.props;
        return (
            <>
                <div className={column}>
                    {series.length > 0 ?
                        <div className={`${className ? className : ''} card`} style={{ height: '340px' }}>
                            <div className="card-header">
                                <div className='rounded_icon'><i className="icon-user mr-2 "></i></div>
                                <h3 className="card-title">{title}</h3>
                            </div>
                            <ChartComponent options={options} series={series} type={"pie"} width={width} height={"350"} />
                        </div>
                        :
                        <div className="card" style={{ height: '340px' }}>
                            <div className="card-header">
                                <div className='rounded_icon'><i className="icon-user mr-2 "></i></div>
                                <h3 className="card-title">{title}</h3>
                            </div>
                            <div className="card-body no_records text-center d-flex align-items-center justify-content-center pt-0">
                                <div style={{ maxWidth: "200px", marginBottom: '60px' }}>
                                    <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid" style={{ maxWidth: "200px" }} onError={onErrorImage} />
                                    <h5 className="mb-2">No records to display </h5>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </>
        )
    }
}

export default HotelCountChart;
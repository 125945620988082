
const initialState = {
    pmsprevileges: {},
    pmsprevilegeslist: [],
    modalspinner: false,
    spinner: false,
    total: 0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_PMS_PREVILEGES_CHANGE':
            return Object.assign({}, state, {
                pmsprevileges: {
                    ...state.pmsprevileges,
                    [action.name]: action.value
                }
            })
        case 'LIST_PMS_PREVILEGES':
            return Object.assign({}, state, {
                pmsprevilegeslist: action.payload,
                total: action.total,
                spinner: action.spinner,
                modalspinner: action.spinner,
            })
        case 'VIEW_PMS_PREVILEGES':
            return Object.assign({}, state, {
                pmsprevileges: action.payload,
                modalspinner: action.spinner,
                spinner: action.spinner,
            })
        case 'EMPTY_PMS_PREVILEGES':
            return Object.assign({}, state, {
                pmsprevileges: {}
            })
        case 'SPINNER_PMS_PREVILEGES':
            return Object.assign({}, state, {
                spinner: true
            })
        case 'MODAL_SPINNER_PMS_PREVILEGES':
            return Object.assign({}, state, {
                modalspinner: true
            })

        default:
            return state;
    }
}
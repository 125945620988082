const jobData = {
	wifipassword: '',
	timezoneutc: '',
	name: '',
	hotelid: '',
	email: '',
	cc: '',
	ccowner: '',
	ccgm: '',
	ccperson: '',
	cctwilloData: '',
	cctwillo: '',
	radius: '',
	phonenumber: '',
	address: '',
	city: '',
	state: '',
	postcode: '',
	latitude: '',
	longitude: '',
	country: '',
	ownername: '',
	ownerphone: '',
	owneremail: '',
	gmname: '',
	gmphone: '',
	gmemail: '',
	contactperson: '',
	contactphone: '',
	communicationemail: [],
	website: '',
	bookingurl: '',
	facebook: '',
	twitter: '',
	pinterest: '',
	instagram: '',
	youtube: '',
	linkedin: '',
	tiktok: '',
	images: [],
	logo: '',
	card: '',
	googlereviewurl: '',
	tripsadvisorurl: '',
	elfsighttripsadvisorurl: '',
	elfsightgoogleurl: '',
	elfsightfacebookurl: '',
	elfsighthotelsurl: '',
	elfsightbookingurl: '',
	elfsightyelpurl: '',
	overallreviewcount: '',
	status: 1,
	location: [{
		lat: '',
		lon: '',
	}],
	socialreviews: [{
		socialmediaid: '',
		socialmediaurl: '',
		socialmediastatus: true
	}],
	socialmedias: [{
		socialmediaid: '',
		socialmediaurl: '',
		socialmediastatus: true
	}],
	shifttime: [{
		shiftintime: null,
		shiftouttime: null,
		shift: ''
	}],
	messaging: '',
	mailstatus: true,
	guesttouchid: '',
	totalStatus: 0,
	activecount: 0,
	inactivecount: 0,
	ownerlist: []
}
const initialState = {
	hotel: jobData,
	listhotels: [],
	totalhotels: 0,
	spinner: true
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'HANDLE_HOTEL_CHANGE':
			return Object.assign({}, state, {
				hotel: {
					...state.hotel,
					[action.name]: action.value
				}
			})
		case 'HANDLE_HOTEL_CHANGE_MULTIPLE':
			return {
				...state,
				hotel: {
					...state.hotel,
					location:
						[
							...state.hotel.location.slice(0, action.i),
							{ ...state.hotel.location[action.i], [action.name]: action.value },
							...state.hotel.location.slice(action.i + 1)
						]
				}
			}
		case 'HANDLE_HOTEL_MULTIPLE_UPDATE':
			if (action.id === '') {
				return {
					...state,
					hotel: {
						...state.hotel,
						location: [...state.hotel.location, action.value]
					}
				}
			}
			else if (action.name === true) {
				return {
					...state,
					hotel: {
						...state.hotel,
						location: [action.value]
					}
				}
			}
			else {
				return {
					...state,
					hotel: {
						...state.hotel,
						location: [...state.hotel.location.filter((event, i) => i !== action.id)]
					}
				}
			}

		case 'HANDLE_HOTEL_ELFSIGHTS_CHANGE':
			return {
				...state,
				hotel: {
					...state.hotel,
					socialreviews:
						[
							...state.hotel.socialreviews.slice(0, action.i),
							{ ...state.hotel.socialreviews[action.i], [action.name]: action.value },
							...state.hotel.socialreviews.slice(action.i + 1)
						]
				}
			}
		case 'HANDLE_HOTEL_SOCAILMEDIA_CHANGE':
			return {
				...state,
				hotel: {
					...state.hotel,
					socialmedias:
						[
							...state.hotel.socialmedias.slice(0, action.i),
							{ ...state.hotel.socialmedias[action.i], [action.name]: action.value },
							...state.hotel.socialmedias.slice(action.i + 1)
						]
				}
			}
		case 'HANDLE_HOTEL_ELFSIGHTS_MULTIPLE_UPDATE':
			if (action.id === '') {
				return {
					...state,
					hotel: {
						...state.hotel,
						socialreviews: [...state.hotel.socialreviews, action.value]
					}
				}
			}
			else if (action.name === true) {
				return {
					...state,
					hotel: {
						...state.hotel,
						socialreviews: [action.value]
					}
				}
			}
			else {
				return {
					...state,
					hotel: {
						...state.hotel,
						socialreviews: [...state.hotel.socialreviews.filter((event, i) => i !== action.id)]
					}
				}
			}
		case 'RESET_HOTEL':
			return Object.assign({}, state, {
				hotel: jobData
			})

		case 'LIST_HOTEL':
			return Object.assign({}, state, {
				listhotels: action.payload,
				totalhotels: action.result.total,
				totalStatus: action.result.totalStatus,
				activecount: action.result.active,
				inactivecount: action.result.inactive,
				spinner: action.spinner
			})
		case 'NEW_LIST_HOTEL':
			return Object.assign({}, state, {
				listhotels: action.payload
			})
		case 'VIEW_HOTEL':
			return Object.assign({}, state, {
				hotel: action.payload,
				spinner: false
			})
		case 'EMPTY_HOTEL':
			return Object.assign({}, state, {
				hotel: jobData,
				spinner: true
			})
		case 'EMPTY_HOTEL_LIST':
			return Object.assign({}, state, {
				hotel: jobData,
				listhotels: [],
				spinner: true
			})
		case 'LIST_USER_OWNER':
			return Object.assign({}, state, {
				ownerlist: action.payload,
			})
		default:
			return state;
	}
}
import React, { Component } from 'react';
import { Container, Form } from 'react-bootstrap';
import { ImSad } from "react-icons/im";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Banner from './banner.png';
import { AC_SEND_FEEDBACK } from '../../actions/reviewAction';
import '../../assets/css/job_apply_form.css';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { Success, Error } from '../../common/swal';
import { Link, Redirect } from 'react-router-dom';
import bad from '../../assets/images/smile-bad.png'

export class Feedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phone: '',
            review: '',
            hotelData: {},
            thanksRedirect: false,
            reviewOption: false,
            reviewsStatus: false,
            isStar1: false,
            isStar2: false,
            isStar3: false,
            starRes: '',
            sendonce: true,
        }
    }
    sendRating1 = (count) => {
        this.setState({ isStar1: true, isStar2: false, isStar3: false, starRes: count })
    }
    sendRating2 = (count) => {
        this.setState({ isStar2: true, isStar1: true, isStar3: false, starRes: count })
    }
    sendRating3 = (count) => {
        this.setState({ isStar3: true, isStar1: true, isStar2: true, starRes: count })
    }
    sendFeedback = (event) => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        let rating = this.state.starRes;
        let data = this.state.hotelData;
        let review = this.state.review;
        let valid = 1
        if (!this.state.starRes) {
            valid = 0
            Error(languageData && languageData.FEEDBACK_STAR_RATING_REQUIRED ? languageData.FEEDBACK_STAR_RATING_REQUIRED : 'Star rating is required')
        }
        if (valid) {
            let formData = {
                hotel: data.hotelid,
                review: review,
                phone: data.phone,
                cc: data.cc,
                name: data.name,
                email: data.email,
                checkoutdate: data.checkoutdate,
                // roomno: data.roomno,
                // roomnopms: data.roomno,
                reviewid: data._id,
                rating: parseInt(rating),
            }
            if (data && data.guestpmsroomno) {
                formData["roomnopms"] = data.roomno
            } else {
                formData["roomno"] = data.roomno
            }
            let feedbackId = this.props.match.params.id
            this.setState({ sendonce: false })
            for (let key in formData) {
                if (formData.hasOwnProperty(key) && typeof formData[key] === 'string') {
                    formData[key] = formData[key].trim();
                }
            }
            if (!formData.review) {
                this.setState({ review: '' })
            }
            axios.post(ImportedURL.API.sendFeedback, formData)
                .then((res) => {
                    this.setState({ sendonce: false })
                    axios.post(ImportedURL.API.reviewUpdate + "/" + feedbackId, { negativefeedbackstatus: true, feedbackpostedon: res.data ? res.data.createdAt : new Date() })
                        .then((res) => {
                            Success((languageData && languageData.FEEDBACK_SENT_SUCCESSFULLY_THANKS ? languageData.FEEDBACK_SENT_SUCCESSFULLY_THANKS : 'Feedback sent successfully. Thanks'));
                            // Success(res.statusText);
                            this.setState({ review: '', thanksRedirect: true })
                        }).catch((err) => { console.log(err); });
                }).catch(({ response }) => {
                    if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else {
                        Error(response.statusMessage)
                    }
                });
        }
    }
    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        if (name === 'review') {
            this.setState({ review: value })
        }
    };
    componentDidMount() {
        const { params } = this.props.match;
        axios.get(ImportedURL.API.viewReviews + "/" + params.id)
            .then((res) => {
                if (res.data != undefined) {
                    this.setState({ reviewsStatus: res.data.negativefeedbackstatus })
                }
            }).catch((err) => { console.log(err); });
        axios.get(ImportedURL.API.getReview + "/" + params.id)
            .then((res) => {
                this.setState({ hotelData: res.data })
            }).catch((err) => { console.log(err); });
    }
    feddBackClick = () => {
        this.setState({ reviewOption: true })
    }


    render() {
        const { isStar1, isStar2, isStar3 } = this.state;
        const { params } = this.props.match;
        if (this.state.thanksRedirect) {
            return <Redirect to={'/thanks'} />
        }
        if (this.state.reviewOption) {
            return <Redirect to={'/review/' + params.id} />
        }
        return (
            <div>
                {
                    this.state.reviewsStatus
                        ?
                        <>
                            <section className='review_sec'>
                                <Container>
                                    <div className='icemote'>
                                        <div className='img_size'>
                                            <img src={this.state.hotelData.hotelcard ? ImportedURL.FILEURL + this.state.hotelData.hotelcard : Banner} alt='not found' />
                                        </div>
                                        <div className='content'>
                                            <div className='icons_sm'>
                                                <div className="order_confirm_img">
                                                    <img src="../assets/images/negative-status.jpg" />
                                                </div>
                                            </div>
                                            <p className='help_t already_review'>This reviews are Already reviewed</p>
                                            {/* <p className='add'>{this.state.hotelData.hotelAddress}</p> */}
                                            {/* <p className='add'>1401 Martin Luther King Jr, Monroe, LA 71202</p> */}
                                            <p className='call'>Call Us @ <span className='dial_num' onClick={this.dialNumClick}>{this.state.hotelData.hotelPhone}</span></p>
                                            {/* <p className='call'>Call Us @ (318) 410-1005</p> */}
                                        </div>

                                    </div>
                                </Container>
                            </section>

                        </>
                        :
                        <section className='review_pro_sec feedback'>
                            <Container >
                                <div className='iprotex'>
                                    <div className='img_size'>
                                        <img src={this.state.hotelData.hotelcard ? ImportedURL.FILEURL + this.state.hotelData.hotelcard : Banner} alt='not found' />
                                    </div>
                                    <div className='content'>
                                        <p style={{ color: '#1c3078', fontWeight: '600', fontSize: '20px' }}>How was your stay with us ?</p>
                                        <div className='icons_smile'>
                                            <img className='img_rev_size_success mb-3' src={bad} name="sad" onClick={this.reviewPage} />
                                            {/* <ImSad className='sad' /> */}
                                        </div>
                                        <p className='feed_t'>Your feedback means a lot to us , please tell us what went wrong.</p>
                                        <div className='feed_area'>
                                            <div className='bad_review_star'>
                                                <i className="fa fa-star" style={{ color: (isStar1) ? '#e4bd51' : '#ccc' }} onClick={() => this.sendRating1(1)}></i>
                                                <i className="fa fa-star" style={{ color: (isStar2) ? '#e4bd51' : '#ccc' }} onClick={() => this.sendRating2(2)}></i>
                                                <i className="fa fa-star" style={{ color: (isStar3) ? '#e4bd51' : '#ccc' }} onClick={() => this.sendRating3(3)}></i>
                                            </div>
                                            <Form>
                                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                                    <Form.Control as="textarea" name="review" placeholder='Write your feedback....' rows={5} onChange={this.handleChange} value={this.state.review} />
                                                </Form.Group>
                                            </Form>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <div>
                                                <button type="button" onClick={this.feddBackClick} className="btn common_bc" id="Add"><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button>
                                            </div>
                                            <div className='submit_btn' style={this.state.sendonce ? { display: 'flex', alignItems: 'end' } : { display: 'none' }}>
                                                <button className='btn commor_save' type='button' onClick={this.sendFeedback}><i class="fe fe-save mr-2"></i>Submit</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Container>
                        </ section>
                }
            </div >
        )
    }
}

const mapStateToProps = state => ({
    ReviewState: state.review,
    AccountState: state.account
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ sendFeedback: AC_SEND_FEEDBACK }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Feedback);


const initialState = {
	socialmedia: {
		socialmediaimage: '',
		socialmedianame: '',
		id: '',
	},
	listSocialMedia: [],
	spinner: false,
	modalspinner: false,
	listfilter: [],
}

export default (state = initialState, action) => {
	switch (action.type) {

		case 'HANDLE_SOCIALMEDIA_CHANGE':
			return Object.assign({}, state, {
				socialmedia: {
					...state.socialmedia,
					[action.name]: action.value
				}
			})
		case 'RESET_SOCIALMEDIA':
			return Object.assign({}, state, {
				socialmedia: {
					socialmediaimage: '',
					socialmedianame: '',
					id: ''
				}
			})
		case 'LIST_SOCIALMEDIA':
			return Object.assign({}, state, {
				listSocialMedia: action.payload,
				listfilter: action.payload,
				spinner: action.spinner
			})
		case 'HANDLE_SOCIALMEDIA_SEARCH':
			return Object.assign({}, state, {
				listSocialMedia: action.value
			})
		case 'VIEW_SOCIALMEDIA':
			return Object.assign({}, state, {
				socialmedia: action.payload,
				modalspinner: false
			})
		case 'SPINNER_SOCIALMEDIA':
			return Object.assign({}, state, {
				spinner: true
			})
		case 'MODAL_SPINNER_SOCIALMEDIA':
			return Object.assign({}, state, {
				modalspinner: true
			})

		default:
			return state;
	}
}
import React, { Component } from "react";
import { connect } from "react-redux";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { bindActionCreators } from "redux";
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { AC_CHANGE_PREVILEGE, AC_DELETE_CATEGORY, AC_EMPTY_CATEGORY, AC_HANDLE_CATEGORY_CHANGE, AC_LIST_CATEGORY, AC_RESET_CATEGORY, AC_UPDATE_CATEGORY, AC_VIEW_CATEGORY } from '../../actions/categoryAction';
import CONSTANTS from "../../common/constants";
import ImportedURL from "../../common/api";
import { Error, Success } from "../../common/swal";
import Spinner from 'react-bootstrap/Spinner';
import { ListLandingUpdateData } from "../../common/validate";
import { AC_PUSH_DATA_LANDING } from "../../actions/landing";
import Pmsprivilegeuser from "../User/pmsprivilegeuser";
import { AC_LIST_PMS_PREVILEGES } from "../../actions/pmsprivilegesAction";
import { AC_MULTIPLE_LIST_CONFIGRATIONS } from "../../actions/configurationAction";

class AddCategory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: "Add",
      id: "",
      previlegeupdated: false,
      changePmsPrivileges: false,
      saving: false,
      spinner: false,
      oldprevileges: '',
      initialchange: true,
      fetch: true,
      previlegesLabel: [
        {
          name: 'Tickets',
          value: ['Incident_Ticket', 'All_Reports', 'Ticket_Report', 'Ticket', 'Ticket_Analytics', 'Ticket_Report']
        },
        {
          name: 'Hotels',
          value: ['Hotel']
        },
        {
          name: 'Users',
          value: ['User']
        },
        {
          name: 'To-Do List',
          value: ['Todo_List', 'Todo_Report', 'Todo_Tag', 'Todo_My_List', 'Todo_List_Item']
        },
        {
          name: 'Notification',
          value: ['Notification']
        },
        {
          name: 'Setup',
          value: ['Problem_Keyword', 'Designation', 'Room_Amenity']
        },
        {
          name: 'Master',
          value: ['Category', 'Currency', 'Country', 'Group', 'Time_Zone', 'Language', 'Configuration', 'Phone_Number_Block_List']
        },
        {
          name: 'Setting',
          value: ['Room_Type_And_Rooms']
        },
        {
          name: 'Reviews',
          value: ['Review', 'Feedback', 'Social_Review', 'Weekly_Report']
        }
      ]
    };
  }

  onChange = e => {
    const languageData = this.props.AccountState.account.SelectedlanguageData
    this.setState({ spinner: false })
    const { name, value } = e.target;
    const Error1 = name + "Error";
    this.setState({ [Error1]: false });
    if (value.length <= 50) {
      this.props.HandleInputChange(name, value);
    } else {
      Error(languageData && languageData.MAXIMUM_CHAR_LIMIT_REACHED ? languageData.MAXIMUM_CHAR_LIMIT_REACHED : "Maximum character limit reached")
    }
  }
  changePrevilege = (index, key) => {
    this.setState({ spinner: false })
    this.props.ChangePrevilege(index, key);
    this.setState({ previlegeupdated: true })

    if (this.state.initialchange && this.state.modalType == 'Edit') {
      const { CategoryState } = this.props;
      this.setState({ oldprevileges: CategoryState.category.previleges, initialchange: false });
    }
  }

  setAll = e => {
    this.setState({ spinner: false })
    const { checked } = e.target;
    this.setState({ setChecked: checked })
    let previleges = checked ? CONSTANTS.allprevileges : CONSTANTS.previleges;
    const updatedData = previleges.map(obj => {
      if (obj.name === 'Hotel') {
        delete obj.add;
        delete obj.edit;
      }
      return obj;
    });

    this.props.HandleInputChange('previleges', updatedData);
    this.setState({ previlegeupdated: true })


    if (this.state.initialchange && this.state.modalType == 'Edit') {
      const { CategoryState } = this.props;
      this.setState({ oldprevileges: CategoryState.category.previleges, initialchange: false });
    }

  }

  fetchPermissions = async () => {
    this.setState({ spinner: false, fetch: false })
    const { CategoryState } = this.props;
    let tempCategoryPrevileges = CategoryState.category && CategoryState.category.previleges ? [...CategoryState.category.previleges] : [];
    let previleges = CONSTANTS.previleges;
    let categoryprevileges = []
    for (let i = 0; i < previleges.length; i++) {
      let Previlege = tempCategoryPrevileges.find(obj => { return obj.name == previleges[i].name });
      if (Previlege) {
        categoryprevileges.push({ ...Previlege, number: previleges[i].number })
      } else {
        categoryprevileges.push(previleges[i])
      }
    }
    this.setState({ previlegeupdated: true })
    await this.props.HandleInputChange('previleges', categoryprevileges);
    await this.checkArrayOfObjIsTrue()
  }
  submit = () => {
    const { AccountState } = this.props;
    const languageData = AccountState.account.SelectedlanguageData
    const { history } = this.props;
    const data = this.props.CategoryState.category;
    data['name'] = data.name.trim()
    if (!data.name) {
      if (!data.name) {
        this.setState({ nameError: true });
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth"
      });
    }
    else {
      const formData = {
        previleges: JSON.stringify(data.previleges),
        pmsprivileges: data.pmsprivileges ? data.pmsprivileges : [],
        id: data.id,
        name: data.name,
        status: data.status,
        previlegeupdated: this.state.previlegeupdated,
        changePmsPrivileges: this.state.changePmsPrivileges,
      }
      if (this.state.modalType === "Add") {
        this.setState({ saving: true })
        axios.post(ImportedURL.API.addCategory, formData)
          .then((res) => {
            // let list = ListLandingUpdateData(res.data, null, 7);
            // this.props.LandingData(list);
            this.setState({ saving: false })
            Success((languageData && languageData.CATEGORY ? languageData.CATEGORY : 'Category') + (languageData && languageData.CREATED_SUCCESSFULLY ? languageData.CREATED_SUCCESSFULLY : " Created Successfully"));
            this.props.ResetCategory();
            history.push("/list-category");
          }).catch(({ response }) => {
            if (response) {
              this.setState({ saving: false })
              if (response.status == 409) {
                Error((languageData && languageData.CATEGORY ? languageData.CATEGORY : 'Category') + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : ' Already Exist'))
              } else if (response.status == 400) {
                Error((languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request'))
              } else if (response.status == 500) {
                Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
              } else if (response.status == 502) {
                Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
              } else if (response.status == 408) {
                Error((languageData && languageData.SORT_ORDER ? languageData.SORT_ORDER : ' Sort order') + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : ' Already Exist'))
              } else {
                Error(response.statusMessage)
              }
            }
          });
      } else {
        this.setState({ saving: true })
        axios.post(ImportedURL.API.updateCategory + "/" + this.state.id, formData)
          .then((res) => {
            // let list = ListLandingUpdateData(res.data, this.state.id, 7);
            // this.props.LandingData(list);
            this.setState({ saving: false })
            Success((languageData && languageData.CATEGORY ? languageData.CATEGORY : 'Category') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"));
            history.push("/list-category");
            this.setState({ previlegeupdated: false, changePmsPrivileges: false })
          }).catch(({ response }) => {
            if (response) {
              if (response.status == 500) {
                Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
              } else if (response.status == 502) {
                Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
              } else if (response.status == 408) {
                Error((languageData && languageData.SORT_ORDER ? languageData.SORT_ORDER : ' Sort order') + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : ' Already Exist'))
              } else if (response.status == 409) {
                Error((languageData && languageData.CATEGORY ? languageData.CATEGORY : 'Category') + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : ' Already Exist'))
              } else {
                Error(response.statusMessage)
              }
              this.setState({ saving: false })
            }
          });
      }
    }
  }

  setAllPMSStatus = (pmsprivileges) => {
    const pmsprivilegeslist = this.props.PMSPrivilegesState.pmsprevilegeslist;
    if (pmsprivileges && pmsprivileges.length > 0 && pmsprivilegeslist && pmsprivilegeslist.length > 0) {
      let status = true;
      for (let i = 0; i < pmsprivilegeslist.length; i++) {
        let find = pmsprivileges.find((e) => e.pmsprivilegesid == pmsprivilegeslist[i]._id)
        if (find) {
          for (let key in find.privileges) {
            if (key != 'name' || key != 'subprivileges') {
              if ((pmsprivilegeslist[i].typesOption).includes(key)) {
                if (find.privileges[key] === false) {
                  status = false;
                  break;
                }
              }
            }
          }
          if (find.privileges && find.privileges.subprivileges && find.privileges.subprivileges.length > 0 && status) {
            for (let j = 0; j < find.privileges.subprivileges.length; j++) {
              for (let key in find.privileges.subprivileges[j]) {
                if (key != 'name') {
                  if ((pmsprivilegeslist[i].typesOption).includes(key)) {
                    if (find.privileges.subprivileges[j][key] === false) {
                      status = false;
                      break;
                    }
                  }
                }
              }
            }
          }
        } else {
          status = false;
          break;
        }
      }
      this.props.HandleInputChange('setChecked', status);
    }
  }

  async componentDidMount() {
    this.props.EmptyCategory();
    this.props.ListConfiguration({ parentslug: ['pms-privileges-type', 'pms-privileges-sub-name'] });
    this.props.ListPMSPrivileges();

    const { params, path } = this.props.match;
    if (params.id) {
      this.setState({ modalType: path === "/view-category/:id" ? "View" : "Edit", id: params.id, spinner: true })
      await this.props.ViewCategory(params.id).then((data) => {
        this.setAllPMSStatus(data && data.pmsprivileges ? data.pmsprivileges : []);
      });
      await this.checkArrayOfObjIsTrue()
    } else {
      this.props.ResetCategory();
    }
    //default scroll top the page
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
  resetOldPermission = async () => {
    this.setState({ initialchange: true, oldprevileges: '' })
    await this.props.HandleInputChange('previleges', this.state.oldprevileges);
    await this.checkArrayOfObjIsTrue()
  }

  checkArrayOfObjIsTrue() {
    let array = this.props.CategoryState.category.previleges;
    let status = true
    for (let obj of array) {
      if (obj.hasOwnProperty('add')) {
        if (obj.add != true) {
          status = false
        }
      }
      if (obj.hasOwnProperty('edit')) {
        if (obj.edit != true) {
          status = false
        }
      }
      if (obj.hasOwnProperty('view')) {
        if (obj.view != true) {
          status = false
        }
      }
      if (obj.hasOwnProperty('invite')) {
        if (obj.invite != true) {
          status = false
        }
      }
      if (obj.hasOwnProperty('share')) {
        if (obj.share != true) {
          status = false
        }
      }
    }
    this.setState({ setChecked: status })
  }
  render() {


    const { CategoryState, AccountState } = this.props;
    const languageData = AccountState.account.SelectedlanguageData
    const data = CategoryState.category;
    const tempVendor = data.name
    const dataSpinner = CategoryState.spinner;
    const previleges = data.previleges ? data.previleges : CONSTANTS.previleges;
    previleges.sort((a, b) => a.number - b.number);
    var repeated = []
    return (
      <>
        <div>
          <div>
            <div className="breadcrump">
              <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-category"><h6>{languageData && languageData.CATEGORIES ? languageData.CATEGORIES : "Categories "}{languageData && languageData.LIST ? languageData.LIST : " List"}</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>{this.state.modalType} {languageData && languageData.CATEGORY ? languageData.CATEGORY : "Category"}</h6></p>
            </div>
            <div className="section-body pt-3">
              <div className="container-fluid">
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="Departments-list"
                    role="tabpanel"
                  >
                    <div className="card">
                      <div className="card-body border-0">
                        <div className='title_mt'>
                          <div className='d-flex'>
                            <div className='rounded_icon'><i className="icon-bar-chart mr-2 "></i></div>
                            <div className='d-flex align-items-center'>
                              <h3 className="card-title">{this.state.modalType} {languageData && languageData.CATEGORY ? languageData.CATEGORY : "Category"}</h3>
                            </div>
                          </div>
                          <div className="header-action" style={{ marginTop: '5px', marginLeft: 'auto' }}>
                            <Link to='/list-category'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button></Link>
                          </div>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="row">
                          <div className="col-sm-6 col-md-6">
                            <div className="form-group" style={this.state.modalType == "View" ? { display: "inline-flex", fontSize: "18px" } : {}}>
                              <label className="label_black" style={this.state.modalType ? { fontSize: "18px" } : {}}>Category Name<span className="ml-1" style={this.state.modalType == "View" ? { padding: "0px 20px", } : {}}>{this.state.modalType == "View" ? <span>:</span> : <span style={{ color: "red" }}>*</span>}</span></label>
                              {this.state.modalType == "View" ? <label>{data.name}</label> : <input type="text" className="form-control" placeholder="Category Name" name="name" disabled={this.state.modalType == "View"} onChange={this.onChange} value={data.name} />}
                              <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Category Name is required</div>
                            </div>
                          </div>
                        </div>

                        {this.state.modalType == "View" ?
                          <div className="card set_permission">
                            <div className="cord-body py-4">
                              <div className="category_previleges">
                                <h6> Set Permissons  </h6>
                              </div>
                              {
                                previleges.map((data, index) => {
                                  if (data.sidebarname) {
                                    if (data.add || data.edit || data.view || data.invite || data.share) {
                                      return (
                                        <>
                                          <div className="category_prev_list pt-3">
                                            <div className="row">
                                              <div className="col-lg-3">
                                                <div className="mainten_name">
                                                  <label className="label_black">{data.sidebarname}</label>
                                                </div>
                                              </div>
                                              <div className="col-lg-1">
                                                <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                              </div>
                                              <div className="col-lg-8">
                                                <div className="category_access">
                                                  <ul>
                                                    {data.add ? <li><span className="tag tag-blue mb-3">ADD</span></li> : ''}
                                                    {data.view ? <li><span className="tag tag-pink mb-3">VIEW</span></li> : ''}
                                                    {data.edit ? <li><span className="tag mb-3" style={{ background: "#5a5278", color: "white" }}>EDIT</span></li> : ''}
                                                    {data.share ? <li><span className="tag tag-gray mb-3" style={{ background: "#e4bd51", color: "white" }}>SHARE</span></li> : ''}
                                                    {data.invite ? <li><span className="tag mb-3" style={{ background: "#cedd7a", color: "white" }}>INVITE</span></li> : ''}
                                                  </ul>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )
                                    }
                                  }
                                })
                              }
                            </div>
                          </div>
                          :
                          <div className="col-lg-12 category_pad">
                            <div className="card add_category">
                              <div className="card-header set_permission_res">
                                <h3 className="card-title">Set Permissons</h3>
                                <div className="card-options">
                                  <div className="d-flex justify-content-between align-items-center">
                                    <ul className="nav nav-tabs page-header-tab"></ul>
                                    <div className="header-action">
                                      <div className="input-group set_permission_input_res">
                                        {ImportedURL.SUPERADMIN.includes(AccountState.role) && <> {this.state.oldprevileges && <span className="input-group-btn mr-2">
                                          <button className="btn btn-icon btn-sm show-fetch" type="submit" onClick={this.resetOldPermission} >
                                            <span className="fa fa-refresh mr-2"></span>Reset old permissions
                                          </button>
                                        </span>}
                                          {data.name != "VENDOR" && this.state.fetch ?
                                            <span className="input-group-btn mr-2">
                                              <button className="btn btn-icon btn-sm fetch_btn" type="submit" onClick={this.fetchPermissions} >
                                                <span className="fa fa-refresh mr-2" style={{ position: 'relative', top: '3px' }}></span>Fetch Permissons
                                              </button>
                                            </span> : ''}
                                        </>}
                                        {data.name != "VENDOR" ? <label className="custom-switch">
                                          <input
                                            checked={this.state.setChecked}
                                            type="checkbox"
                                            name="custom-switch-checkbox"
                                            className="custom-switch-input"
                                            onChange={this.setAll}
                                          />
                                          <span className="custom-switch-indicator"></span>
                                          <span className="custom-switch-description">
                                            Set All
                                          </span>
                                        </label> : ''}
                                      </div>
                                      <div className="input-group">

                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="card-body card-footer" style={{ border: "none" }}>
                                <div className={tempVendor == "VENDOR" ? "Over_all_table_style table-responsive" : "Over_all_table_style table-responsive edit_user_table"}>
                                  <table className="table table-striped mb-0 ">
                                    <thead>
                                      <tr>
                                        <th style={{ textAlign: 'center' }}>Management</th>
                                        <th style={{ textAlign: 'center' }}>View</th>
                                        <th style={{ textAlign: 'center' }}>Add</th>
                                        <th style={{ textAlign: 'center' }}>Edit</th>
                                        <th style={{ textAlign: 'center' }}>Share</th>
                                        <th style={{ textAlign: 'center' }}>Invite</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {previleges.map((data, index) => {
                                        let titleLabel = this.state.previlegesLabel.find(e => e.value.includes(data.name))
                                        let status = ''
                                        if (titleLabel) {
                                          status = repeated.find(e => e == titleLabel.name)
                                          if (!status) {
                                            repeated.push(titleLabel.name)
                                          }
                                        }
                                        if (tempVendor == "VENDOR") {
                                          if (data.name == "Booked_Product") {
                                            return (
                                              <tr key={index}>
                                                <td style={{ position: 'relative' }}>
                                                  <p className="user_permission">
                                                    {(!status) ? (titleLabel ? titleLabel.name : "") : ''}
                                                  </p>
                                                  <p style={{ marginTop: "revert", textAlign: 'center' }}>
                                                    {data.sidebarname} {data.mobile ? <i class="fa fa-mobile ml-2 mobile_permission_icon" aria-hidden="true"></i> : ''}
                                                  </p>
                                                </td>
                                                <td className="user_category_previledge">
                                                  {
                                                    data.view != undefined ?
                                                      <label className="custom_control_user_previleges custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.view} onChange={(event) => this.changePrevilege(index, 'view')} />
                                                        <span className="custom-control-label"></span>
                                                      </label>
                                                      : "---"
                                                  }
                                                </td>
                                                <td className="user_category_previledge">
                                                  {data.name !== "History" ?
                                                    (
                                                      data.add != undefined ?
                                                        <label className="custom_control_user_previleges custom-checkbox">
                                                          <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.add} onChange={(event) => this.changePrevilege(index, 'add')} />
                                                          <span className="custom-control-label"></span>
                                                        </label>
                                                        : '---'
                                                    )
                                                    : '---'
                                                  }
                                                </td>
                                                <td className="user_category_previledge">
                                                  {data.name !== "History" && data.name !== "Setting" ?
                                                    (
                                                      data.edit != undefined ?
                                                        <label className="custom_control_user_previleges custom-checkbox">
                                                          <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.edit} onChange={(event) => this.changePrevilege(index, 'edit')} />
                                                          <span className="custom-control-label"></span>
                                                        </label>
                                                        : '---'
                                                    )
                                                    : '---'}
                                                </td>
                                                <td className="user_category_previledge">
                                                  {data.name == "Job" ?
                                                    (
                                                      data.share != undefined ?
                                                        <label className="custom_control_user_previleges custom-checkbox">
                                                          <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.share} onChange={(event) => this.changePrevilege(index, 'share')} />
                                                          <span className="custom-control-label"></span>
                                                        </label>
                                                        : '---'
                                                    )
                                                    : '---'}
                                                </td>
                                                <td className="user_category_previledge">
                                                  {data.name == "User" ?
                                                    (
                                                      data.invite != undefined ?
                                                        <label className="custom_control_user_previleges custom-checkbox">
                                                          <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.invite} onChange={(event) => this.changePrevilege(index, 'invite')} />
                                                          <span className="custom-control-label"></span>
                                                        </label>
                                                        : '---'
                                                    )
                                                    : '---'}
                                                </td>

                                              </tr>
                                            )
                                          }
                                        } else {
                                          if (data.sidebarname) {
                                            return (
                                              <tr key={index}>
                                                <td style={{ position: 'relative' }}>
                                                  <p className="user_permission">
                                                    {(!status) ? (titleLabel ? titleLabel.name : "") : ''}
                                                  </p>
                                                  <p style={{ marginTop: "revert", textAlign: 'center' }}>
                                                    {data.sidebarname} {data.mobile ? <i class="fa fa-mobile ml-2 mobile_permission_icon" aria-hidden="true"></i> : ''}
                                                  </p>
                                                </td>
                                                <td className="user_category_previledge">
                                                  {
                                                    data.view != undefined ?
                                                      <label className="custom_control_user_previleges custom-checkbox">
                                                        <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.view} onChange={(event) => this.changePrevilege(index, 'view')} />
                                                        <span className="custom-control-label"></span>
                                                      </label>
                                                      : "---"
                                                  }
                                                </td>
                                                <td className="user_category_previledge">
                                                  {data.name !== "History" ?
                                                    (
                                                      data.add != undefined ?
                                                        <label className="custom_control_user_previleges custom-checkbox">
                                                          <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.add} onChange={(event) => this.changePrevilege(index, 'add')} />
                                                          <span className="custom-control-label"></span>
                                                        </label>
                                                        : '---'
                                                    )
                                                    : '---'
                                                  }
                                                </td>
                                                <td className="user_category_previledge">
                                                  {data.name !== "History" && data.name !== "Setting" ?
                                                    (
                                                      data.edit != undefined ?
                                                        <label className="custom_control_user_previleges custom-checkbox">
                                                          <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.edit} onChange={(event) => this.changePrevilege(index, 'edit')} />
                                                          <span className="custom-control-label"></span>
                                                        </label>
                                                        : '---'
                                                    )
                                                    : '---'}
                                                </td>
                                                <td className="user_category_previledge">
                                                  {data.name == "Job" ?
                                                    (
                                                      data.share != undefined ?
                                                        <label className="custom_control_user_previleges custom-checkbox">
                                                          <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.share} onChange={(event) => this.changePrevilege(index, 'share')} />
                                                          <span className="custom-control-label"></span>
                                                        </label>
                                                        : '---'
                                                    )
                                                    : '---'}
                                                </td>
                                                <td className="user_category_previledge">
                                                  {data.name == "User" ?
                                                    (
                                                      data.invite != undefined ?
                                                        <label className="custom_control_user_previleges custom-checkbox">
                                                          <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.invite} onChange={(event) => this.changePrevilege(index, 'invite')} />
                                                          <span className="custom-control-label"></span>
                                                        </label>
                                                        : '---'
                                                    )
                                                    : '---'}
                                                </td>

                                              </tr>
                                            )
                                          }
                                        }
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        }
                        {
                          (this.state.modalType == "View" && data.pmsprivileges && data.pmsprivileges.length > 0) ?
                            <div className="card set_permission">
                              <div className="cord-body py-4">
                                <div className="category_previleges">
                                  <h6> Set PMS Permissons  </h6>
                                </div>
                                {
                                  data.pmsprivileges.map((data, index) => {
                                    const { name } = data.privileges
                                    return (
                                      <>
                                        <div className="category_prev_list pt-3" key={index}>
                                          <div className="row">
                                            <div className="col-lg-3">
                                              <div className="mainten_name">
                                                <label className="label_black">{name}</label>
                                              </div>
                                            </div>
                                            <div className="col-lg-1">
                                              <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                            </div>
                                            <div className="col-lg-8">
                                              <div className="category_access">
                                                <ul>
                                                  {Object.keys(data.privileges).map((key, i) => {
                                                    if (key !== 'name' && data.privileges[key]) {
                                                      return (
                                                        <li> <span className="tag tag-blue mb-3" style={{ textTransform: "uppercase" }} >{key}</span>  </li>
                                                      );
                                                    }
                                                  })}
                                                </ul>
                                              </div>
                                            </div>
                                          </div>
                                        </div >
                                      </>
                                    )
                                  })
                                }
                              </div>
                            </div>
                            : ""
                        }
                      </div>
                      {/* {
                        this.state.modalType != "View" ?
                          <>
                            <div className="catgeory_top">
                              < Pmsprivilegeuser {...this.props} type={"category"} setAllPMSStatus={this.setAllPMSStatus} changePmsPrivileges={(e) => this.setState({ changePmsPrivileges: e })} />
                            </div>
                          </> : ''
                      } */}
                      {
                        this.state.modalType == 'View' ?
                          ""
                          :
                          (this.state.modalType == 'Edit' ?
                            <div className="card-footer text-right mandatory">
                              <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                              <div className="text-right">
                                {
                                  this.state.saving ?
                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                    :
                                    <button type="submit" className="btn commor_save" onClick={this.submit}>
                                      <i className="fe fe-save mr-2"></i>Update
                                    </button>
                                }
                              </div>
                            </div>
                            :
                            <div className="card-footer text-right mandatory">
                              <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                              <div className="text-right">
                                {
                                  this.state.saving ?
                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                    :
                                    <button type="submit" className="btn commor_save" onClick={this.submit}>
                                      <i className="fe fe-save mr-2"></i>Save
                                    </button>
                                }
                              </div>
                            </div>)
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div >
        <div id="overlay" style={{ display: this.state.spinner ? (dataSpinner ? 'block' : 'none') : 'none' }}></div>
        {
          this.state.spinner && (dataSpinner &&
            <div className="common_loader">
              <img className='loader_img_style_common' src='../../assets/images/load.png' />
              <Spinner className='spinner_load_common' animation="border" variant="info" >
              </Spinner>
            </div>)
        }
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  SubadminState: state.subadmin,
  CategoryState: state.category,
  AccountState: state.account,
  PMSPrivilegesState: state.pmsprivileges,
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators(
    {
      EmptyCategory: AC_EMPTY_CATEGORY,
      HandleInputChange: AC_HANDLE_CATEGORY_CHANGE,
      ViewCategory: AC_VIEW_CATEGORY,
      UpdateCategory: AC_UPDATE_CATEGORY,
      ResetCategory: AC_RESET_CATEGORY,
      ChangePrevilege: AC_CHANGE_PREVILEGE,
      LandingData: AC_PUSH_DATA_LANDING,
      PushData: AC_PUSH_DATA_LANDING,
      ListConfiguration: AC_MULTIPLE_LIST_CONFIGRATIONS,
      ListPMSPrivileges: AC_LIST_PMS_PREVILEGES,
    },
    dispatch
  );
}
export default connect(mapStateToProps, mapDispatchToProps)(AddCategory);

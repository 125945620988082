import React, { Component } from "react";
import { connect } from "react-redux";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { bindActionCreators } from "redux";
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import { AC_SETTING_ROOM_HANDLE_CHANGE, AC_RESET_SETTING_ROOM, AC_EMPTY_ROOM, AC_HANDLE_ROOM_UPDATE, AC_VIEW_SETTING_ROOM, AC_HANDLE_MULTIROW_CLICK, AC_EMPTY_VIEWSETTING_ROOM, AC_LIST_SETTING_ROOM, AC_PASS_VIEW_SETTING_ROOM_DATA } from '../../actions/settingroomAction';
import ImportedURL from "../../common/api";
import { Error, Success } from "../../common/swal";
import Ckeditor from "react-ckeditor-component/lib/ckeditor";
import { Offcanvas, Row, Spinner } from 'react-bootstrap';
import Amenitycontent from './roomamenitycontent';
import { AC_LIST_SETTING_ROOMAREA_AMENITY, AC_HANDLE_KEY_SEARCH } from '../../actions/roomareaAmenityAction';
import { CapitalizeFirstLetter, Textareavalidation, onErrorImage } from "../../common/validate";
import { AC_LIST_SETTING_ROOMTYPE, AC_SETTING_ROOMTYPE_HANDLE_CHANGE } from '../../actions/settingRoomtypeAction';
import { AC_LIST_CONFIGURATION, AC_LIST_CONFIGOPTIONS, AC_LIST_CONFIGOPTIONS_BED_TYPE, AC_LIST_CONFIGOPTIONS_MATTRESS } from '../../actions/configurationAction';
import showSwalDialog from "../../utilities/Modal/swal";
import QRCode from 'qrcode.react';

class AddRoom extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modalType: "Add",
      id: "",
      from: "",
      to: "",
      roomError: false,
      floorError: false,
      bedtypeError: false,
      hotelError: false,
      roomStartError: false,
      initial: true,
      saving: false,
      validToError: false,
      existValue: '',
      seqChechecked: false,
      alreadyExistsSrror: false,
      form: 1,
      progress: 50,
      bednameArrayError: [],
      mattressnameArrayError: [],
      quantityArrayError: [],
      qrCodeValue: '',
    };
    this.hotelInputRef = React.createRef();
    this.roomnoInputRef = React.createRef();
    this.roomtypeInputRef = React.createRef();
    this.floorInputRef = React.createRef();
    this.bedroomInputRef = React.createRef();
    this.mattressInputRef = React.createRef();
    this.quantityInputRef = React.createRef();
  }
  componentWillUpdate(nextProps) {
    const newaccount = nextProps.AccountState.account;

    if (this.state.initial) {
      if (newaccount) {
        this.props.HandleInputChange('hotel', newaccount.hotel)
        this.props.ListRoom({ hotel: newaccount.hotel, isAllDetailsShows: true });
      }
      this.setState({ initial: false })
    }
  }
  onHandleChange = e => {
    const data = this.props.RoomState.room;
    const { name, value } = e.target;
    const Error = name + "Error";
    this.setState({ [Error]: false })
    if (name == 'description') {
      var textCount = e.target.value;
      textCount.trim()
      var textareaLimit = textCount.substring(0, 255);
      let resultObj = Textareavalidation(textareaLimit);
      this.props.HandleInputChange(name, resultObj.value);
      this.setState({ descriptionError: resultObj.notextErr, textarealimitError: resultObj.textlimitErr })
    } else if (name == 'area' || name == 'floor' || name == 'maxquantity' || name == 'roomno') {
      var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
      if (val) {
        this.setState({ [Error]: false });
        this.props.HandleInputChange(name, val);
        if (name == "roomno") {
          let qrCode = ImportedURL.LOCALURL + 'view-setting-room-qr/' + data.hotel + "/" + val + Math.floor(100000 + Math.random() * 900000);
          this.setState({ qrCodeValue: qrCode })
        }
      } else {
        this.props.HandleInputChange(name, val);
      }
      if (name == 'roomno') {
        const rowData = this.props.RoomState.listSettingRoom;

        let findValue = val.trim()
        if (rowData && rowData.length > 0) {
          let error = false
          rowData.map((item) => {
            if (error == false)
              if ((item.roomno == findValue) && (item.hotel == data.hotel)) {
                if (this.state.id) {
                  if (item._id != this.state.id) {
                    error = true
                  }
                } else {
                  error = true
                }
              }
          })
          if (error) {
            this.setState({ alreadyExistsSrror: true })
          } else {
            this.setState({ alreadyExistsSrror: false })
          }
        }
      }
    } else {
      this.props.HandleInputChange(name, value);
    }
  }
  onChangeFloor = e => {
    const { name, value } = e;
    this.setState({ floorError: false })
    this.props.HandleInputChange(name, value);
  }
  HotelChange = e => {
    const { name, value, label } = e;
    const Error = name + "Error";
    this.setState({ [Error]: false });
    if (name == 'hotel') {
      this.props.HandleInputChange(name, value);
      this.props.HandleInputChange("roomtype", '');
      this.props.HandleInputChange("area", '');
      this.props.HandleInputChange("maxquantity", '');
      this.props.HandleInputChange("description", '');
      this.props.HandleInputChange("itemvalue", '');
      this.props.HandleInputChange("amenity", []);
      const rowData = this.props.RoomState.listSettingRoom;
      const data = this.props.RoomState.room
      if (rowData && rowData.length > 0) {
        let error = false
        rowData.map((item) => {
          if (error == false)
            if ((item.roomno == data.roomno) && (item.hotel == value)) {
              if (this.state.id) {
                if (item._id != this.state.id) {
                  error = true
                }
              } else {
                error = true
              }
            }
        })
        if (error) {
          this.setState({ alreadyExistsSrror: true })
        } else {
          this.setState({ alreadyExistsSrror: false })
        }
      }
    }
    if (name == 'roomtype') {
      this.setState({ bednameArrayError: [], quantityArrayError: [], mattressnameArrayError: [] })
      this.props.HandleInputChange(name, value);
      axios.get(ImportedURL.API.viewSettingRoomtype + "/" + value)
        .then((res) => {
          if (res.data) {
            this.props.HandleInputChange("area", res.data.builtunit);
            this.props.HandleInputChange("maxquantity", res.data.maxoccupancy);
            this.props.HandleInputChange("description", res.data.description);
            this.props.HandleInputChange("itemvalue", res.data.itemvalue);
            var amenityTemp = []
            res.data.viewamenity.map((item, i) => {
              amenityTemp.push(
                {
                  amenity: item.amenity._id,
                  status: item.status
                }
              )
            })
            this.props.HandleInputChange("amenity", amenityTemp);
          }
        }).catch((err) => { console.log(err); });

    }
    if (name == 'door') {
      this.props.HandleInputChange(name, value);
    }
  }
  submit = async e => {
    const { AccountState } = this.props;
    const languageData = AccountState.account.SelectedlanguageData
    const data = this.props.RoomState.room;
    for (let key in data) {
      if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
        data[key] = data[key].trim();
      }
    }
    let isValid = true;
    const { bednameArrayError, mattressnameArrayError, quantityArrayError } = this.state
    if (data.itemvalue && data.itemvalue.length > 0) {
      let bednameArray = []
      let mattressnameArray = []
      let quantityArray = []
      data.itemvalue.map((item, i) => {
        if (!item.bedname) {
          bednameArray.push(i)
          isValid = false;
          this.bedroomInputRef.current.focus();
        }
        if (!item.mattressname) {
          mattressnameArray.push(i)
          isValid = false;
          this.mattressInputRef.current.focus();
        }
        if (!item.quantity) {
          quantityArray.push(i)
          isValid = false;
          this.quantityInputRef.current.focus();
        }
      })
      this.setState({ bednameArrayError: bednameArray, mattressnameArrayError: mattressnameArray, quantityArrayError: quantityArray })
    }

    if (!data.floor) {
      this.setState({ floorError: true })
      isValid = false;
      this.floorInputRef.current.focus();
    }
    if (!data.roomtype) {
      this.setState({ roomtypeError: true })
      isValid = false;
      this.roomtypeInputRef.current.focus();
    }
    if (this.state.alreadyExistsSrror) {
      isValid = false;
      this.roomnoInputRef.current.focus();
    }
    if (!data.roomno) {
      this.setState({ roomnoError: true })
      isValid = false;
      this.roomnoInputRef.current.focus();
    }
    if (!data.hotel) {
      this.setState({ hotelError: true })
      isValid = false;
      this.hotelInputRef.current.focus();
    }

    const formData = new FormData();
    let Qrcode = ""
    if (data.hotel && !data.uniqueqrcode) {
      const canvas = document.getElementById("HpQrcode");
      Qrcode = canvas
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");

      const blob = await fetch(Qrcode).then((res) => res.blob());
      formData.append("qrcode", blob);
      formData.append("qrcodevalue", this.state.qrCodeValue.split('/')[5]);
    }
    for (let key in data) {
      if (key === 'itemvalue') formData.append(key, JSON.stringify(data[key]));
      else if (key === 'amenity') formData.append(key, JSON.stringify(data[key]));
      else formData.append(key, data[key])
    }
    if (isValid) {
      if (this.state.modalType == "Add") {
        this.setState({ saving: true })
        axios.post(ImportedURL.API.addSettingRoom, formData)
          .then((res) => {
            this.setState({ saving: false })
            this.props.HandleTabChange('tabposition', 'rooms');
            Success((languageData && languageData.ROOM ? languageData.ROOM_SETTINGS + " " : 'Room ') + (languageData && languageData.CREATED_SUCCESSFULLY ? (languageData.CREATED_SUCCESSFULLY) : "created successfully"));
            this.setState({ listRedirect: true })
            this.props.ListRoom();
          }).catch(({ response }) => {
            this.setState({ saving: false })
            this.ErrorResponse(response)
          });
      } else {
        this.setState({ saving: true })
        axios.post(ImportedURL.API.updateSettingRoom + '/' + this.state.id, formData)
          .then((res) => {
            this.setState({ saving: false })
            this.props.HandleTabChange('tabposition', 'rooms');
            Success((languageData && languageData.SETTINGROOM ? languageData.ROOM_SETTINGS + " " : " Room ") + (languageData && languageData.UPDATED_SUCCESSFULLY ? (languageData.UPDATED_SUCCESSFULLY) : " updated successfully"));
            this.setState({ listRedirect: true })
            this.props.ListRoom();
          }).catch(({ response }) => {
            this.setState({ saving: false })
            this.ErrorResponse(response)
          });
      }
    }
  }
  ErrorResponse = (response) => {
    const languageData = this.props.AccountState.account.SelectedlanguageData
    if (response.status == 500) {
      Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
    } else if (response.status == 400) {
      Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
    } else if (response.status == 409) {
      Error((languageData && languageData.ROOM_NUMBER ? CapitalizeFirstLetter(languageData.ROOM_SETTINGS) + " " : 'Room ') + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : ' already exist'))
    } else if (response.status == 502) {
      Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
    } else {
      Error(response.statusMessage)
    }
  }
  // ------------------------------------------------dynamic row
  addFormFields(e) {
    const data = this.props.RoomState.room;
    let isValid = true;
    if (data.itemvalue && data.itemvalue.length > 0) {
      let bednameArray = []
      let mattressnameArray = []
      let quantityArray = []
      data.itemvalue.map((item, i) => {
        if (!item.bedname) {
          bednameArray.push(i)
          isValid = false;
        }
        if (!item.mattressname) {
          mattressnameArray.push(i)
          isValid = false;
        }
        if (!item.quantity) {
          quantityArray.push(i)
          isValid = false;
        }
      })
      this.setState({ bednameArrayError: bednameArray, mattressnameArrayError: mattressnameArray, quantityArrayError: quantityArray })
    }
    if (isValid) {
      this.props.AC_HANDLE_ROOM_UPDATE('itemvalue', { 'bedname': '', 'width': '', 'length': '', 'matrass': '', 'quantity': '', }, '');
    }
  }
  removeFormFields(i) {
    this.setState({ spinner: false })
    const DataValue = this.props.RoomState.room;
    if (DataValue.length === 1 && i === 0) {
      this.props.AC_HANDLE_ROOM_UPDATE(true, { 'bedname': '' }, i);
    } else {
      this.props.AC_HANDLE_ROOM_UPDATE('itemvalue', '', i);
    }
    const { bednameArrayError, mattressnameArrayError, quantityArrayError } = this.state
    let bedname = bednameArrayError.filter((value) => value !== i)
    let mattress = mattressnameArrayError.filter((value) => value !== i)
    let quantity = quantityArrayError.filter((value) => value !== i)
    this.setState({ bednameArrayError: bedname, mattressnameArrayError: mattress, quantityArrayError: quantity })
  }
  // ------------------------------------------------------------------------------------------
  onChangeEditor = e => {
    const data = this.props.RoomState.room;
    let description = window.$(e.editor.getData()).text()
    data.descriptionCount = Math.floor(description.length % 159);
    data.count = Math.floor(description.length / 159);
    this.props.HandleInputChange("description", e.editor.getData());
  }
  // ------------------------------------------------------------------------------------------------
  onChangeItem(i, e) {
    const { name, value } = e.target;
    this.setState({ spinner: false, itemsError: false })
    var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
    this.props.AC_HANDLE_MULTIROW_CLICK(name, val, i);
    const { quantityArrayError } = this.state
    if (name == 'quantity') {
      let arr = quantityArrayError.filter((value) => value !== i)
      this.setState({ quantityArrayError: arr })
    }
  }
  onChangeSelectItem(i, e) {
    this.setState({ spinner: false, itemsError: false })
    const { name, value } = e;
    if (value) {
      this.props.AC_HANDLE_MULTIROW_CLICK(name, value, i);
    } else {
      if (name == 'mattressname') {
        this.setState({ MattressCanvas: true, mattressnameError: false })
      }
      if (name == 'bedname') {
        this.setState({ showcanvas: true, bednameError: false })
      }
    }
    const { bednameArrayError, mattressnameArrayError } = this.state
    if (name == 'mattressname') {
      let arr = mattressnameArrayError.filter((value) => value !== i)
      this.setState({ mattressnameArrayError: arr })
    }
    if (name == 'bedname') {
      let arr = bednameArrayError.filter((value) => value !== i)
      this.setState({ bednameArrayError: arr })
    }
  }
  handleSearch = e => {
    const { name, value } = e.target
    const rowData = this.props.RoomareaAmenityState.listsettingamenities;
    if (value) {
      if (rowData != undefined && rowData.length > 0) {
        const data = rowData.filter((item) =>
          (item.amenity ? item.amenity.toLowerCase().includes(value.toLowerCase()) : ''))
        this.props.handlekeySearch(data)
      }
    }
    else {
      this.props.AC_LIST_SETTING_ROOMAREA_AMENITY();
    }
  }
  componentDidMount() {
    this.props.AC_LIST_SETTING_ROOMAREA_AMENITY();
    this.props.AC_LIST_SETTING_ROOMTYPE();
    this.props.EmptyRoom();
    this.props.AC_LIST_CONFIGURATION();
    this.props.ListRoom({ isAllDetailsShows: true });
    this.props.ListConfigOption();
    this.props.ListBedTypeConfigOption({ parentslug: 'room-type-beds' });
    this.props.ListMattressConfigOption({ parentslug: 'mattress' });
    const { params, path } = this.props.match;
    if (params.id) {
      // this.props.ViewRoom(params.id)
      axios.get(ImportedURL.API.viewSettingRoom + "/" + params.id)
        .then((res) => {
          this.props.PassViewData(res.data)
          if (res.data && !res.data.uniqueqrcode) {
            let qrCode = ImportedURL.LOCALURL + 'view-setting-room-qr/' + res.data.hotel + "/" + res.data.roomno + Math.floor(100000 + Math.random() * 900000);
            this.setState({ qrCodeValue: qrCode })
          }
        }).catch((err) => { console.log(err); });
      this.setState({ modalType: path === "/view-setting-room/:id" ? "View" : "Edit", id: params.id, contentCategory: true, contentUser: true, spinner: true })
    } else {
      this.props.ResetRoom();
    }
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
  handleback = e => {
    this.props.HandleTabChange('tabposition', 'rooms');
  }
  onChangeBedtype = e => {
    const { name, value } = e.target;
    const Error = name + 'Error';
    this.setState({ [Error]: false });
    this.props.HandleInputChange(name, value)
  }
  submitBedRoom = e => {
    const data = this.props.RoomState.room;
    for (let key in data) {
      if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
        data[key] = data[key].trim();
      }
    }
    const languageData = this.props.AccountState.account.SelectedlanguageData
    let valid = 1;

    if (!data.bedname) {
      valid = 0;
      this.setState({ bednameError: true });
    }

    if (valid) {
      var formData = {
        name: data.bedname,
      }
      this.setState({ saving: true })
      axios.post(ImportedURL.API.addConfigurationBedroomtype, formData)
        .then((res) => {
          this.props.HandleInputChange('bedname', '')
          this.setState({ saving: false, showcanvas: false })
          Success("Bed type added successfully");
          this.props.ListBedTypeConfigOption({ parentslug: 'room-type-beds' });
        }).catch(({ response }) => {
          this.setState({ saving: false })
          if (response.status == 500) {
            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
          } else if (response.status == 400) {
            Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
          } else if (response.status == 502) {
            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
          } else if (response.status == 409) {
            Error('Bed type already exist')
          } else {
            Error(response.statusMessage)
          }
        });
    }
  }
  submitMattress = e => {
    const data = this.props.RoomState.room;
    const languageData = this.props.AccountState.account.SelectedlanguageData
    for (let key in data) {
      if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
        data[key] = data[key].trim();
      }
    }
    let valid = 1;

    if (!data.mattressname) {
      valid = 0;
      this.setState({ mattressnameError: true });
    }

    if (valid) {
      var formData = {
        name: data.mattressname,
      }
      this.setState({ saving: true })
      axios.post(ImportedURL.API.addConfigurationMattress, formData)
        .then((res) => {
          this.setState({ saving: false, MattressCanvas: false })
          Success("Mattress added successfully");
          this.props.HandleInputChange('mattressname', '')
          this.props.ListMattressConfigOption({ parentslug: 'mattress' });
        }).catch(({ response }) => {
          this.setState({ saving: false })
          if (response.status == 500) {
            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
          } else if (response.status == 400) {
            Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
          } else if (response.status == 502) {
            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
          } else if (response.status == 409) {
            Error('Mattress already exist')
          } else {
            Error(response.statusMessage)
          }
        });
    }
  }
  BedRoomError = (index) => {
    const { bednameArrayError, } = this.state
    if (bednameArrayError && bednameArrayError.length > 0) {
      if (bednameArrayError.includes(index)) {
        return 1
      } else {
        return 0
      }
    } else {
      return 0
    }
  }
  MattressError = (index) => {
    const { mattressnameArrayError } = this.state
    if (mattressnameArrayError && mattressnameArrayError.length > 0) {
      if (mattressnameArrayError.includes(index)) {
        return 1
      } else {
        return 0
      }
    } else {
      return 0
    }
  }
  QuantityError = (index) => {
    const { quantityArrayError } = this.state
    if (quantityArrayError && quantityArrayError.length > 0) {
      if (quantityArrayError.includes(index)) {
        return 1
      } else {
        return 0
      }
    } else {
      return 0
    }
  }
  render() {
    const { AccountState, HotelState, RoomState, RoomareaAmenityState, SettingRoomtypeState, ConfigurationState } = this.props;
    const account = AccountState.account;
    const languageData = AccountState.account.SelectedlanguageData
    const listHotels = HotelState.listhotels;
    const data = this.props.RoomState.room;
    const tabposition = RoomState.room.tabposition;
    const spinner = RoomState.spinner
    if (this.state.listRedirect) {
      return <Redirect to={'/list-setting-roomtype'} />
    }
    var selectedHotel = '';
    const hotelOptions = [];
    if (listHotels && listHotels.length > 0) {
      listHotels.filter(e => e.status === true).map((item) => {
        if (data.hotel == item._id) {
          selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
        }
        hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
      })
    }

    var optionsDoor = [
      { value: '1', label: '1', name: 'door' },
      { value: '2', label: '2', name: 'door' },
      { value: '3', label: '3', name: 'door' },
      { value: '4', label: '4', name: 'door' },
      { value: '5', label: '5', name: 'door' },
      { value: '6', label: '6', name: 'door' },
      { value: '7', label: '7', name: 'door' },
      { value: '8', label: '8', name: 'door' },
      { value: '9', label: '9', name: 'door' },
      { value: '10', label: '10', name: 'door' },
    ]

    var optionsMat = [
      { value: 'Inner Spring', label: 'Inner Spring', name: 'mattress' },
      { value: 'Pillow Top', label: 'Pillow Top', name: 'mattress' },
      { value: 'Hybrid', label: 'Hybrid', name: 'mattress' },
    ]



    var ConfigurationData = ConfigurationState.listConfiguration;
    var ConfigOptionData = ConfigurationState.listConfigOptions;
    var BedTypeConfigOptions = ConfigurationState.listBedTypeConfigOptions;
    var MattressConfigOptions = ConfigurationState.listMattressConfigOptions;


    var itemArray = [];
    if (data.itemvalue && data.itemvalue.length > 0) {
      data.itemvalue.map((item, i) => {

        var selectedRoomtypeBeds = ''
        var selectedRoomMattress = ''
        var bedArray = []
        var mattressArray = []
        if (BedTypeConfigOptions && BedTypeConfigOptions.length > 0) {
          BedTypeConfigOptions.filter((e) => e.status == true).map((e) => {
            if (item.bedname == e._id) {
              selectedRoomtypeBeds = { label: `${e.name}`, value: e._id, name: 'bedname' }
            }
            bedArray.push({ label: `${e.name}`, value: e._id, name: 'bedname' })
          })
        }
        bedArray.push({ label: 'Add', value: '', name: 'bedname' })
        if (MattressConfigOptions && MattressConfigOptions.length > 0) {
          MattressConfigOptions.filter((e) => e.status == true).map((e) => {
            if (item.mattressname == e._id) {
              selectedRoomMattress = { label: `${e.name}`, value: e._id, name: 'mattressname' }
            }
            mattressArray.push({ label: `${e.name}`, value: e._id, name: 'mattressname' })
          })
        }
        mattressArray.push({ label: 'Add', value: '', name: 'mattressname' })

        itemArray.push(
          <tr key={i} className="required_tables_pms">
            <td style={{ width: '210px' }}>
              <div className="form-group" style={{ marginBottom: '0px' }}>
                <Select
                  value={selectedRoomtypeBeds ? { label: selectedRoomtypeBeds.label } : ''}
                  onChange={e => this.onChangeSelectItem(i, e)}
                  options={bedArray}
                  ref={this.bedroomInputRef}
                />
                <div className="invalid-feedback" style={{ display: this.BedRoomError(i) ? "block" : 'none' }}>Bed Name is required</div>
              </div>
            </td>
            <td>
              <div className="form-group" style={{ marginBottom: '0px' }}>
                <p style={{ marginBottom: '0px' }}>
                  <input type="text" className='form-control' name="width" value={item.width} onChange={e => this.onChangeItem(i, e)} placeholder={"Width (cm)"} />
                </p>
              </div>
            </td>
            <td>
              <div className="form-group" style={{ marginBottom: '0px' }}>
                <p style={{ marginBottom: '0px' }}>
                  <input type="text" className='form-control' name="length" value={item.length} onChange={e => this.onChangeItem(i, e)} placeholder={"Length (cm)"} />
                </p>
              </div>
            </td>
            <td style={{ width: '210px' }}>
              <div className="form-group" style={{ marginBottom: '0px' }}>
                <p style={{ marginBottom: '0px' }}>
                  <Select
                    value={selectedRoomMattress}
                    onChange={e => this.onChangeSelectItem(i, e)}
                    options={mattressArray}
                    ref={this.mattressInputRef}
                  />
                  <div className="invalid-feedback" style={{ display: this.MattressError(i) ? "block" : 'none' }}>Mattress is required</div>
                </p>
              </div>
            </td>
            <td>
              <div className="form-group" style={{ marginBottom: '0px' }}>
                <p style={{ marginBottom: '0px' }}>
                  <input type="text" ref={this.quantityInputRef} className='form-control' name="quantity" value={item.quantity} onChange={e => this.onChangeItem(i, e)} placeholder={"Quantity"} />
                  <div className="invalid-feedback" style={{ display: this.QuantityError(i) ? "block" : 'none' }}>Quantity is required</div>
                </p>
              </div>
            </td>
            <td>
              <div className="col-sm-6 col-md-3">
                <div className='todo_increment' style={{ marginTop: '0px' }}>
                  {data.itemvalue.length > 1 ?
                    < span className='plus_minus' > <i className="fa fa-minus-circle" aria-hidden="true" onClick={() => this.removeFormFields(i)}></i></span>
                    : ''}
                </div>
              </div>
            </td>
          </tr>
        )
      })
    }

    // --------------------------------------------------------------------------
    const amenityData = RoomareaAmenityState.listsettingamenities;

    const contentRoomType = []
    const contentView = []
    const contentBathroom = []
    const contentBedroom = []
    const contentKitchen = []
    const contentTVInternetVideo = []
    const contentElectrical = []
    const contentAccessibility = []
    const contentFloor = []
    const contentOutdoors = []
    if (data.hotel) {
      amenityData && amenityData.filter(filterItem => filterItem.status === false).map((item1, i) => {
        if (item1.hotel == data.hotel) {
          if (item1.type == 'Room Type') {
            contentRoomType.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'View') {
            contentView.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Bathroom') {
            contentBathroom.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Bedroom') {
            contentBedroom.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Kitchen') {
            contentKitchen.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Tv,Internet,Video') {
            contentTVInternetVideo.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Electrical Appliances') {
            contentElectrical.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Accessibility') {
            contentAccessibility.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Floor') {
            contentFloor.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Outdoors') {
            contentOutdoors.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          }
        }
        if (item1.hotel == 'All') {
          if (item1.type == 'Room Type') {
            contentRoomType.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'View') {
            contentView.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Bathroom') {
            contentBathroom.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Bedroom') {
            contentBedroom.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Kitchen') {
            contentKitchen.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Tv,Internet,Video') {
            contentTVInternetVideo.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Electrical Appliances') {
            contentElectrical.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Accessibility') {
            contentAccessibility.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Floor') {
            contentFloor.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          } else if (item1.type == 'Outdoors') {
            contentOutdoors.push(
              <>
                <Amenitycontent item1={item1} data={data} i={i} />
              </>
            )
          }
        }
      })
    }
    // --------------------------------------------------------------------------
    var RoomtypeArray = []
    const Roomtype = this.props.SettingRoomtypeState.listSettingRoomtype;

    if (data.hotel) {
      Roomtype.filter((e) => e.status == true).map((item) => {
        if (item.hotel == data.hotel) {
          RoomtypeArray.push(item)
        }
      })
    }
    var selectroomData = '';
    if (data.roomtype != undefined && data.roomtype) {
      selectroomData = Roomtype.find(e => e._id === data.roomtype)
    }
    // --------------------------------------------------------------------------
    const limitOptions = []
    for (let i = 1; i <= 100; i++) {
      limitOptions.push({
        value: i, label: i, name: 'floor'
      })
    }
    return (
      <>
        <div>
          <div>
            <div className="breadcrump">
              <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-setting-roomtype"><h6 className="highlights_breadcrump">{tabposition == 'roomtype' ? ((languageData && languageData.ROOM_TYPE_SETTINGS) ? languageData.ROOM_TYPE_SETTINGS + " List" : "Room Type List") : tabposition == 'rooms' ? ((languageData && languageData.ROOM_SETTINGS) ? languageData.ROOM_SETTINGS + " List" : "Room List") : tabposition == 'service' ? ((languageData && languageData.OUT_OF_SERVICE_SETTINGS) ? languageData.OUT_OF_SERVICE_SETTINGS + " List" : "Out Of Service List") : ''}</h6></Link><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>{this.state.modalType} {(languageData && languageData.ROOM_SETTINGS) ? languageData.ROOM_SETTINGS : "Room"}</h6></p>
            </div>
            <div className="section-body pt-3">
              <div className="container-fluid">
                <div className="tab-content">
                  <div
                    className="tab-pane fade show active"
                    id="Departments-list"
                    role="tabpanel"
                  >
                    <div className="card">

                      <div className="card-header">
                        <div className="card-options">
                          <div className="d-flex justify-content-between align-items-center">
                            <ul className="nav nav-tabs page-header-tab"></ul>

                            <div className="header-action">
                              <Link to="/list-setting-roomtype"><button type="button" className="btn btn-primary" onClick={this.handleback}><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                            </div>
                          </div>

                        </div>
                        <div className='rounded_icon'><i className="icon-home mr-2 "></i></div>
                        <h3 className="card-title">{this.state.modalType} {(languageData && languageData.ROOM_SETTINGS) ? languageData.ROOM_SETTINGS : "Room"}</h3>
                      </div>
                      <div className="card-body">
                        <Row>
                          <div className="col-lg-12 col-md-12">
                            <Row>
                              {ImportedURL.SUPERADMIN.includes(account.role) &&
                                <>
                                  <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                      <label className="form-label">Hotel<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                      <Select
                                        value={selectedHotel}
                                        onChange={this.HotelChange}
                                        options={hotelOptions}
                                        formatOptionLabel={(e) => (
                                          <div style={{ display: 'flex', alignItems: 'center' }}>
                                            {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                            <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                          </div>
                                        )}
                                        isDisabled={this.state.modalType == "View"}
                                        ref={this.hotelInputRef}
                                      />
                                      <div className="invalid-feedback" style={{ display: this.state.hotelError ? "block" : 'none' }}>Hotel is required</div>
                                    </div>
                                  </div>
                                </>
                              }
                              <div style={{ display: 'none' }} className="HpQrcode">
                                <QRCode id="HpQrcode" value={this.state.qrCodeValue} width="100px" height="100px" />
                              </div>
                              <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                  <label className="form-label">Room #<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                  <input type="text" ref={this.roomnoInputRef} className="form-control" name='roomno' onChange={this.onHandleChange} value={data.roomno} placeholder="Room #" />
                                  <div className="invalid-feedback" style={{ display: this.state.roomnoError ? "block" : 'none' }}>Room # is required</div>
                                  <div className="invalid-feedback" style={{ display: this.state.alreadyExistsSrror ? "block" : 'none' }}>Room number already allocated in this hotel</div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                  <label className="form-label">Room Type<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                  <Select
                                    value={selectroomData ? { label: selectroomData.roomname } : ''}
                                    onChange={this.HotelChange}
                                    options={RoomtypeArray && RoomtypeArray.filter(e => (e.status === true)).map(item => {
                                      return {
                                        label: item.roomname, value: item._id, name: 'roomtype',
                                      }
                                    })}
                                    ref={this.roomtypeInputRef}
                                  />
                                  <div className="invalid-feedback" style={{ display: this.state.roomtypeError ? "block" : 'none' }}>Room Type is required</div>

                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                  <label className="form-label">Floor<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                  <Select
                                    value={data.floor ? { label: Number(data.floor) } : ''}
                                    onChange={this.onChangeFloor}
                                    options={limitOptions}
                                    isDisabled={this.state.modalType == "View"}
                                    ref={this.floorInputRef}
                                  />
                                  <div className="invalid-feedback" style={{ display: this.state.floorError ? "block" : 'none' }}>Floor is required</div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                  <label className="form-label">Area ( Sq. Feet )</label>
                                  <input type="text" className="form-control" name='area' onChange={this.onHandleChange} value={data.area} placeholder="Area" />
                                  <div className="invalid-feedback" style={{ display: this.state.areaError ? "block" : 'none' }}>Area is required</div>
                                </div>
                              </div>
                              <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                  <label className="form-label">Guest Max Quantity</label>
                                  <input type="text" className="form-control" name='maxquantity' onChange={this.onHandleChange} value={data.maxquantity} placeholder="Guest Max Quantity" />
                                  <div className="invalid-feedback" style={{ display: this.state.maxquantityError ? "block" : 'none' }}>Guest Max Quantity is required</div>
                                </div>
                              </div>

                              <div className="col-lg-4 col-md-4">
                                <div className="form-group">
                                  <label className="form-label">Connecting Door</label>
                                  <Select
                                    value={data.door ? { label: data.door } : ''}
                                    onChange={this.HotelChange}
                                    options={optionsDoor}
                                  />
                                  <div className="invalid-feedback" style={{ display: this.state.doorError ? "block" : 'none' }}>Connecting Door is required</div>
                                </div>
                              </div>
                            </Row>
                            <div className="col-md-12 pl-0 pr-0">
                              <div className="form-group">
                                <div className='d-flex' style={{ justifyContent: "space-between" }}>
                                  <label className="form-label" >Description</label>
                                </div>
                                <Ckeditor
                                  activeclassName="p10"
                                  content={data.description}
                                  events={{
                                    "change": this.onChangeEditor
                                  }}
                                />

                              </div>
                            </div>
                            <div className="Over_all_table_style col-md-12 pl-0 pr-0">
                              <table class="table table-borderless">
                                <thead>
                                  <tr style={{ textAlign: "center" }}>
                                    <th>Bed Name<span className="ml-1" style={{ color: 'red' }}>*</span></th>
                                    <th>Width</th>
                                    <th>Length</th>
                                    <th>Mattress<span className="ml-1" style={{ color: 'red' }}>*</span></th>
                                    <th>Quantity<span className="ml-1" style={{ color: 'red' }}>*</span></th>
                                    <th>
                                      <div className='todo_increment' style={{ marginTop: '0px' }}>
                                        <span className='plus_minus'><i className="fa fa-plus-circle" aria-hidden="true" onClick={() => this.addFormFields()}></i></span>
                                      </div>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {itemArray}
                                </tbody>
                              </table>
                            </div>
                            {data.hotel ?
                              <>
                                <div className="row ml-0 mr-0 pl-0">
                                  <h3 className="card-title ml-0 mr-0 mb-3">Facilities</h3>
                                  <div style={{ position: 'relative' }}>
                                    <input type="text" className="form-control" style={{ position: 'relative', paddingLeft: '32px' }} name='builtunit' onChange={this.handleSearch} placeholder="Search Facilities..." />
                                    <i class="fa fa-search mr-2" style={{ position: 'absolute', top: "12px", left: '25px' }} aria-hidden="true"></i>
                                  </div>
                                </div>
                                {contentRoomType && contentRoomType.length > 0 ?
                                  <>
                                    <div className="row ml-0 mr-0 pl-0 mt-4">
                                      <h3 className="card-title ml-0 mr-0 mb-1">Room Type</h3>
                                      {contentRoomType ? contentRoomType : ''}
                                    </div>
                                  </> : ''}
                                {contentView && contentView.length > 0 ?
                                  <>
                                    <div className="row ml-0 mr-0 pl-0 mt-5">
                                      <h3 className="card-title ml-0 mr-0 mb-1">View</h3>
                                      {contentView ? contentView : ''}
                                    </div>
                                  </> : ''}
                                {contentBathroom && contentBathroom.length > 0 ?
                                  <>
                                    <div className="row ml-0 mr-0 pl-0 mt-5">
                                      <h3 className="card-title ml-0 mr-0 mb-1">Bathroom</h3>
                                      {contentBathroom ? contentBathroom : ''}
                                    </div>
                                  </> : ''}
                                {contentBedroom && contentBedroom.length > 0 ?
                                  <>
                                    <div className="row ml-0 mr-0 pl-0 mt-5">
                                      <h3 className="card-title ml-0 mr-0 mb-1">Bedroom</h3>
                                      {contentBedroom ? contentBedroom : ''}
                                    </div>
                                  </> : ''}
                                {contentKitchen && contentKitchen.length > 0 ?
                                  <>
                                    <div className="row ml-0 mr-0 pl-0 mt-5">
                                      <h3 className="card-title ml-0 mr-0 mb-1">Kitchen</h3>
                                      {contentKitchen ? contentKitchen : ''}
                                    </div>
                                  </> : ''}
                                {contentTVInternetVideo && contentTVInternetVideo.length > 0 ?
                                  <>
                                    <div className="row ml-0 mr-0 pl-0 mt-5">
                                      <h3 className="card-title ml-0 mr-0 mb-1">TV,Internet,Video</h3>
                                      {contentTVInternetVideo ? contentTVInternetVideo : ''}
                                    </div>
                                  </> : ''}
                                {contentElectrical && contentElectrical.length > 0 ?
                                  <>
                                    <div className="row ml-0 mr-0 pl-0 mt-5">
                                      <h3 className="card-title ml-0 mr-0 mb-1">Electrical Appliances</h3>
                                      {contentElectrical ? contentElectrical : ''}
                                    </div>
                                  </> : ''}
                                {contentAccessibility && contentAccessibility.length > 0 ?
                                  <>
                                    <div className="row ml-0 mr-0 pl-0 mt-5">
                                      <h3 className="card-title ml-0 mr-0 mb-1">Accessibility</h3>
                                      {contentAccessibility ? contentAccessibility : ''}
                                    </div>
                                  </> : ''}
                                {contentFloor && contentFloor.length > 0 ?
                                  <>
                                    <div className="row ml-0 mr-0 pl-0 mt-5">
                                      <h3 className="card-title ml-0 mr-0 mb-1">Floor</h3>
                                      {contentFloor ? contentFloor : ''}
                                    </div>
                                  </> : ''}
                                <div className="row ml-0 mr-0 pl-0 mt-5">
                                  {contentOutdoors && contentOutdoors.length > 0 ? <h3 className="card-title ml-0 mr-0 mb-1">Outdoors</h3> : ''}
                                  {contentOutdoors ? contentOutdoors : ''}
                                </div>
                                {(contentRoomType && contentRoomType.length > 0 || contentView && contentView.length > 0 || contentBathroom && contentBathroom.length > 0 || contentBedroom && contentBedroom.length > 0 || contentKitchen && contentKitchen.length > 0 || contentTVInternetVideo && contentTVInternetVideo.length > 0 || contentElectrical && contentElectrical.length > 0 || contentAccessibility && contentAccessibility.length > 0 || contentFloor && contentFloor.length > 0 || contentOutdoors && contentOutdoors.length > 0)
                                  ? '' :
                                  <div className="card-body no_records text-center d-flex align-items-center justify-content-center pt-0">
                                    <div style={{ maxWidth: "340px" }}>
                                      <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid" style={{ maxWidth: "272px" }} />
                                      <h5 className="mb-2">No records to display ... Add Room Amenity..</h5>
                                    </div>
                                  </div>
                                }
                              </>
                              : ''}

                            {this.state.modalType == 'Edit' ?
                              <div className="card-footer text-right mandatory">
                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                <div className="text-right">
                                  {
                                    this.state.saving ?
                                      <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                      :
                                      <button type="submit" className="btn commor_save" onClick={this.submit}>
                                        <i className="fe fe-save mr-2"></i>Update
                                      </button>
                                  }
                                </div>
                              </div> :
                              <div className="card-footer text-right mandatory">
                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                <div className="text-right">
                                  {
                                    this.state.saving ?
                                      <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                      :
                                      <button type="submit" className="btn commor_save" onClick={this.submit}>
                                        <i className="fe fe-save mr-2"></i>Save
                                      </button>
                                  }
                                </div>
                              </div>
                            }
                          </div>
                        </Row>

                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="overlay" style={{ display: this.state.modalType == "Edit" && spinner ? 'block' : 'none' }}></div>
        {this.state.modalType == "Edit" && spinner &&
          <div className="common_loader">
            <img className='loader_img_style_common' src='../../assets/images/load.png' />
            <Spinner className='spinner_load_common' animation="border" variant="info" >
            </Spinner>
          </div>
        }
        <Offcanvas className="offcanvas_width_changes" show={this.state.showcanvas} onHide={(e) => this.setState({ showcanvas: false })} placement={'end'} style={{ width: '35%' }}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title><h4>Add Bed Type</h4></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="row">
              <div className="form-group">
                <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                <input type='text' style={{ width: "100%" }} className="form-control" name="bedname" placeholder="Name" onChange={this.onChangeBedtype} value={data.bedname} />
                <div className="invalid-feedback" style={{ display: this.state.bednameError ? "block" : 'none' }}>Name is required</div>
              </div>
            </div>
            <div className='position_fixed_cnavas_small_contant'>
              <div className="card-footer" style={{ padding: '20px 0px' }}>
                <div className="canvas_footer_button">
                  <div className='mr-5'>
                    <button type="button" className="btn btn-secondary" onClick={(e) => this.setState({ showcanvas: false })} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Cancel</button>
                  </div>
                  <div>
                    {
                      this.state.saving ?
                        <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>save</button>
                        :
                        <button type="submit" className="btn commor_save" onClick={this.submitBedRoom}>
                          <i className="fe fe-save mr-2"></i>Save
                        </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
        <Offcanvas className="offcanvas_width_changes" show={this.state.MattressCanvas} onHide={(e) => this.setState({ MattressCanvas: false })} placement={'end'} style={{ width: '35%' }}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title><h4>Add Mattress</h4></Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <div className="row">
              <div className="form-group">
                <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                <input type='text' style={{ width: "100%" }} className="form-control" name="mattressname" placeholder="Name" onChange={this.onChangeBedtype} value={data.mattressname} />
                <div className="invalid-feedback" style={{ display: this.state.mattressnameError ? "block" : 'none' }}>Name is required</div>
              </div>
            </div>
            <div className='position_fixed_cnavas_small_contant'>
              <div className="card-footer" style={{ padding: '20px 0px' }}>
                <div className="canvas_footer_button">
                  <div className='mr-5'>
                    <button type="button" className="btn btn-secondary" onClick={(e) => this.setState({ MattressCanvas: false })} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Cancel</button>
                  </div>
                  <div>
                    {
                      this.state.saving ?
                        <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>save</button>
                        :
                        <button type="submit" className="btn commor_save" onClick={this.submitMattress}>
                          <i className="fe fe-save mr-2"></i>Save
                        </button>
                    }
                  </div>
                </div>
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </>
    );
  }
}
const mapStateToProps = (state) => ({
  HotelState: state.hotel,
  AccountState: state.account,
  RoomState: state.settingroom,
  RoomareaAmenityState: state.roomareaamenity,
  SettingRoomtypeState: state.settingroomtype,
  ConfigurationState: state.configuration,

});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({
    ListRoom: AC_LIST_SETTING_ROOM,
    HandleInputChange: AC_SETTING_ROOM_HANDLE_CHANGE,
    HandleTabChange: AC_SETTING_ROOMTYPE_HANDLE_CHANGE,
    ResetRoom: AC_RESET_SETTING_ROOM,
    AC_HANDLE_ROOM_UPDATE,
    AC_LIST_SETTING_ROOMAREA_AMENITY,
    AC_LIST_SETTING_ROOMTYPE,
    AC_HANDLE_MULTIROW_CLICK,
    ViewRoom: AC_VIEW_SETTING_ROOM,
    handlekeySearch: AC_HANDLE_KEY_SEARCH,

    AC_LIST_CONFIGURATION,
    EmptyRoom: AC_EMPTY_ROOM,
    ListConfigOption: AC_LIST_CONFIGOPTIONS,
    ListBedTypeConfigOption: AC_LIST_CONFIGOPTIONS_BED_TYPE,
    ListMattressConfigOption: AC_LIST_CONFIGOPTIONS_MATTRESS,
    PassViewData: AC_PASS_VIEW_SETTING_ROOM_DATA
  }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(AddRoom);

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import AgGridComponent from '../../../utilities/AgGridReact/aggrid';
import moment from 'moment';
import { AC_MODAL_SPINNER_TO_DO_LIST, AC_SPINNER_TODO_MY_LIST, AC_VIEW_TODO_MY_LIST, AC_VIEW_TO_DO_LIST } from '../../../actions/todolistAction';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import ViewModal from '../Allmytask/viewmodal';
import axios from 'axios';
import ImportedURL from '../../../common/api';

class ViewMylist extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                { headerName: 'Title', field: 'title', width: 200, floatingFilter: true },
                {
                    headerName: 'Description', field: 'description', width: 200, floatingFilter: true,
                    valueGetter: function (params) {
                        if (params.data)
                            if (params.data.description) {
                                return params.data.description
                            } else {
                                return "---"
                            }
                    }
                },
                {
                    headerName: 'Start Date', field: 'startdate', width: 200, floatingFilter: true,
                    valueGetter: function (params) {
                        if (params.data)
                            if (params.data.startdate) {
                                return moment(params.data.startdate).format(params.data.dFormat)
                            } else {
                                return "---"
                            }
                    }
                },
                {
                    headerName: 'Actions', width: 150, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
                    cellRenderer: function (params) {
                        if (params.data)
                            if (params.value) {
                                return params.value;
                            } else {
                                return "---"
                            }
                    }
                },
            ],
            defaultColumDef: {
                editable: false,
                sortable: true,
                resizable: true,
                filter: true,
                flex: 1,
                minWidth: 100,
            },
            perPage: 25,
            date: ''
        }
    }
    componentDidMount() {
        const account = this.props.AccountState.account;
        this.setState({ hotelName: account.hotelName ? account.hotelName : '' });
        this.props.SpinnerTodo();
        const { params } = this.props.match;
        if (params.id) {
            this.props.ViewTodoList(params.id);
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };
    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value == 'View') {
            this.setState({ date: event.data.start });
            this.props.spinnerModalTodo();
            this.props.ViewTodo(event.data._id);
        }
    }
    handleFilterChanged = () => {
        const api = this.gridApi;
        if (api && api.getDisplayedRowCount() === 0) {
            api.showNoRowsOverlay();
            this.setState({ hideExport: true })
        } else {
            api.hideOverlay();
            this.setState({ hideExport: false })
        }
    };
    exportToCSV = () => {
        const account = this.props.AccountState.account;
        const dFormat = (account.dateformate != '' ? account.dateformate : 'MM-DD-YYYY');
        this.gridApi.exportDataAsCsv({
            columnKeys: ['title', 'description', 'startdate'],
            fileName: "todomylist" + "_" + (this.state.hotelName && (this.state.hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".csv",
            processCellCallback: function (params) {
                if (params && params.column && params.column.colId === 'startdate') {
                    return moment(params.value).format(dFormat);
                } else {
                    return params.value;
                }
            }
        })
    }
    handleExportPDF = () => {
        const account = this.props.AccountState.account;
        const exportData = this.props.TodoListState.todomylist.todolist
        const dFormat = (account.dateformate != '' ? account.dateformate : 'MM-DD-YYYY');
        const selected = exportData.map(item => {
            const selectedData = {};
            const selectedProperties = ['title', 'description', 'startdate']
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    if (property == "startdate") {
                        selectedData[property] = item[property] ? moment(item[property]).format(dFormat) : '---';
                    } else if (property == "description") {
                        selectedData[property] = item[property] ? item[property] : '---';
                    } else {
                        selectedData[property] = item[property] ? item[property] : '---';
                    }
                }
            });
            return selectedData;
        });
        let data = {
            title: this.state.hotelName + " - To-Do My List",
            head: ['#', "Title", 'Descriptiom', 'Start Date'],
            body: selected,
            hotelNameExport: this.state.hotelName ? account.hotelNameExport : '',
            hoteladdressExport: this.state.hotelName ? account.hoteladdressExport : '',
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "todomylist" + "_" + (this.state.hotelName).replace(/ /g, '_') + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }
    render() {
        const { AccountState, TodoListState } = this.props;
        const modalspinner = TodoListState.modalspinner;
        const data = TodoListState.todo;
        const viewTodo = TodoListState.todomylist;
        const spinner = TodoListState.spinner;
        const languageData = AccountState.account.SelectedlanguageData;
        const account = AccountState.account;
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]

        var rowData = viewTodo.todolist ? viewTodo.todolist : []
        const template = '<div><button title="View" type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#exampleTodoModal"><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"></i></button></div>'
        var dFormat = (account.dateformate != '' ? account.dateformate : 'MM-DD-YYYY');
        if (rowData && rowData.length > 0) {
            rowData.forEach(object => {
                object.actions = template;
                object.dFormat = dFormat;
            });
        }

        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-country"><h6 className="highlights_breadcrump">{languageData && languageData.TO_DO ? languageData.TO_DO : "TO-DO"} {languageData && languageData.TODO_MY_LIST ? languageData.TODO_MY_LIST : "My List"}</h6></Link></p>
                        </div>
                        <div className="section-body pt-3 user_sec">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="fa fa-folder-o mr-2"></i></div>
                                                <h3 className="card-title">{languageData && languageData.TO_DO ? languageData.TO_DO : "TO-DO"} {languageData && languageData.TODO_MY_LIST ? languageData.TODO_MY_LIST : "My List"}</h3>
                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="header-action" >
                                                            <Link to={"/todo-my-list"}><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                                        </div>
                                                        {
                                                            (rowData && rowData.length > 0) ?
                                                                <div className="card-options">
                                                                    <a className="btn btn-primary btn-sm nav-link dropdown-toggle ticket_export" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                                    <div className="dropdown-menu">
                                                                        <a className="dropdown-item" type='button' onClick={() => this.exportToCSV()}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                        <a className="dropdown-item" type='button' onClick={() => this.handleExportPDF()}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                                    </div>
                                                                </div>
                                                                : ""
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                        <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <AgGridComponent spinner={spinner} rowData={rowData} state={this.state} onRowClicked={(e) => this.onRowClicked(e)} onGridReady={(e) => this.onGridReady(e)} handleFilterChanged={(e) => this.handleFilterChanged(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ViewModal {...this.props} data={data} spinner={modalspinner} startdate={this.state.date ? moment(this.state.date).format("MM-DD-YYYY") : ''} />
            </>
        )
    }
}

const mapStateToProps = state => ({
    AccountState: state.account,
    TodoListState: state.todolist,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ViewTodoList: AC_VIEW_TODO_MY_LIST,
        SpinnerTodo: AC_SPINNER_TODO_MY_LIST,
        ViewTodo: AC_VIEW_TO_DO_LIST,
        spinnerModalTodo: AC_MODAL_SPINNER_TO_DO_LIST,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewMylist);


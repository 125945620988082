const initialState = {
    listConfiguration: [],
    listConfigurationSignup: [],
    configration: {},
    viewConfiguration: {
        name: '',
        slug: '',
        options: [{
            type: '',
            status: true,
        }],
    },
    // --------------------------------------
    viewConfigOption: {
        options: [{
            type: '',
            status: true,
        }],
    },
    listConfigOptions: [],
    listConfigOptionsSignup: [],
    listBedTypeConfigOptions: [],
    listDocumentConfigOptions: [],
    listMattressConfigOptions: [],
    listTypeConfigOptions: [],
    listBrandConfigOptions: [],
    listPriorityConfigOptions: [],
    listDurationConfigOptions: [],
    listNumberOfIntervalConfigOptions: [],
    listtodooccurrenceConfigOptions: [],
    listTodoReminderConfigOptions: [],
    listTodoStatus: [],
    spinner: true,
    // ------------------------------------
    listReserveBooking: [],
    listReserveBussiness: [],
    listReserveUpload: [],
    listReservationtype: [],
    listReserveOffertype: [],
    listReserveAdults: [],
    listReserveAdults: [],
    listReserveChildren: [],
    listReserveNoofrooms: [],
    listReserveIdproof: [],
    listReserveCash: []
    // listReserveAdults:[],
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_CONFIGURATION_CHANGE':
            return Object.assign({}, state, {
                viewConfiguration: {
                    ...state.viewConfiguration,
                    [action.name]: action.value
                }
            })
        case 'HANDLE_POSITION_CHANGE':
            return Object.assign({}, state, {
                [action.name]: action.value
            })
        case 'RESET_CONFIGURATION':
            return Object.assign({}, state, {
                viewConfiguration: initialState.viewConfiguration,
            })
        case 'LIST_CONFIGURATION':
            return {
                ...state,
                listConfiguration: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGURATION_SIGNUP':
            return {
                ...state,
                listConfigurationSignup: action.payload,
                spinner: false
            }
        case 'VIEW_CONFIGURATION':
            return Object.assign({}, state, {
                viewConfiguration: action.payload,
                spinner: false,
            })
        // -------------------------------------------------

        case 'VIEW_CONFIGOPTIONS':
            return Object.assign({}, state, {
                viewConfigOption: {
                    options: action.payload && action.payload.length ? action.payload : [{
                        type: '',
                        status: true,
                    }],
                },
                spinner: false,
            })
        case 'HANDLE_CONFIGOPTIONS_CHANGE':
            return Object.assign({}, state, {
                viewConfigOption: {
                    ...state.viewConfigOption,
                    [action.name]: action.value
                }
            })
        case 'LIST_CONFIGOPTIONS':
            return {
                ...state,
                listConfigOptions: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_BED_TYPE':
            return {
                ...state,
                listBedTypeConfigOptions: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_DOCUMENT':
            return {
                ...state,
                listDocumentConfigOptions: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_BRAND':
            return {
                ...state,
                listBrandConfigOptions: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_VENDORTYPE':
            return {
                ...state,
                listTypeConfigOptions: action.payload,
                spinner: false
            }

        case 'LIST_CONFIGOPTIONS_MATTRESS':
            return {
                ...state,
                listMattressConfigOptions: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONSSIGNUP':
            return {
                ...state,
                listConfigOptionsSignup: action.payload,
                spinner: false
            }
        // -------------------------------------------------

        case 'EMPTY_CONFIGURATION':
            return Object.assign({}, state, {
                viewConfiguration: initialState.viewConfiguration,
                spinner: true
            })
        // ------------------------------new-------------------
        case 'LIST_CONFIGOPTIONS_BOOKING':
            return {
                ...state,
                listReserveBooking: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_BUSSINESS':
            return {
                ...state,
                listReserveBussiness: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_UPLOAD':
            return {
                ...state,
                listReserveUpload: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_TYPE':
            return {
                ...state,
                listReservationtype: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_COUPON':
            return {
                ...state,
                listReserveOffertype: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_ADULTS':
            return {
                ...state,
                listReserveAdults: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_CHILDREN':
            return {
                ...state,
                listReserveChildren: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_NOOFROOMS':
            return {
                ...state,
                listReserveNoofrooms: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_GOVERNMENT':
            return {
                ...state,
                listReserveIdproof: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_CASH':
            return {
                ...state,
                listReserveCash: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_PRIORITY':
            return {
                ...state,
                listPriorityConfigOptions: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_DURATION':
            return {
                ...state,
                listDurationConfigOptions: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_TODO_NUMBER_OF_INTERVALS':
            return {
                ...state,
                listNumberOfIntervalConfigOptions: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_TODO_OCCURENCE':
            return {
                ...state,
                listtodooccurrenceConfigOptions: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_TODO_REMINDER':
            return {
                ...state,
                listTodoReminderConfigOptions: action.payload,
                spinner: false
            }
        case 'LIST_CONFIGOPTIONS_TODO_STATUS':
            return {
                ...state,
                listTodoStatus: action.payload,
                spinner: false
            }
        case 'MULTIPLE_LIST_CONFIGRATIONS':
            return Object.assign({}, state, {
                configration: action.payload,
                spinner: action.spinner
            })
        default:
            return state;
    }
}
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import { AC_GET_REVIEW_BY_ID, AC_REVIEW_KEYWORD } from '../../actions/reviewAction';
import ImportedURL from '../../common/api';

class SentimentAnalysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [],
            redirect: false,
            viewredirect: false,
            id: '',
            initial: true
        }
    }
    handleClickMore = () => {
        this.setState({ viewredirect: true, fromdate: this.props.datereport.fromdate, todate: this.props.datereport.todate })
    }
    componentDidMount() {
        const account = this.props.AccountState.account;
        const { ReviewState, hotel } = this.props;
        if (!ImportedURL.SUPERADMIN.includes(account.role)) {
            this.props.ReviewByKeyword({ hotel: account.hotel })
            this.setState({ hotel: account.hotel })
        } else {
            this.setState({ hotel: hotel })
        }

    }

    componentDidUpdate(prevprops) {
        const account = this.props.AccountState.account;
        const { ReviewState, hotel } = this.props;

    }
    render() {
        const { ReviewState, hotel, AccountState } = this.props;
        const { toDateReview, fromDateReview } = this.props && this.props.datereport;
        let decodetoDateReview = toDateReview;
        let decodefromDateReview = fromDateReview;

        if (this.state.viewredirect && toDateReview && fromDateReview) {
            return <Redirect to={'/sentimental-viewreview/' + (hotel ? hotel : this.state.hotel) + '/' + '&from=' + decodefromDateReview + '/' + '&to=' + decodetoDateReview} />
        }
        if (this.state.viewredirect && !toDateReview && !fromDateReview) {
            return <Redirect to={'/sentimental-viewreview/' + (hotel ? hotel : this.state.hotel)} />
        }
        // if (this.state.viewredirect) {
        //     return <Redirect to={'/sentimental-viewreview/' + (hotel ? hotel : this.state.hotel) + `?fromdate=${this.state.fromdate}&todate=${this.state.todate}`} />
        // }
        if (this.state.viewredirect && !fromDateReview && toDateReview) {
            return <Redirect to={'/sentimental-viewreview/' + (hotel ? hotel : this.state.hotel) + '/' + '&to=' + decodetoDateReview} />
        }
        if (this.state.viewredirect && fromDateReview && !toDateReview) {
            return <Redirect to={'/sentimental-viewreview/' + (hotel ? hotel : this.state.hotel) + '/' + '&from=' + decodefromDateReview} />
        }
        const reviewClientKeyword = Object.entries(ReviewState.reviewClientKeyword);
        const languageData = AccountState.account.SelectedlanguageData

        const seriesArray = [];
        const categories = [];
        if (reviewClientKeyword && reviewClientKeyword.length > 0)
            for (let i = 0; i < reviewClientKeyword.length; i++) {
                categories.push(reviewClientKeyword[i][0])
                seriesArray.push({
                    name: reviewClientKeyword[i][0], type: "bar", data: [
                        {
                            x: reviewClientKeyword[i][0],
                            y: reviewClientKeyword[i][1]
                        },
                    ]
                })
            }
        const options = {
            chart: {
                type: 'bar',
                id: "basic-bar",
                events: {
                    mouseLeave: function (event, chartContext, config) {
                        chartContext.resetSeries()
                    }
                }
            },
            xaxis: {
                labels: {
                    show: false,
                },
            },
            yaxis: {
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: true,
                },
                labels: {
                    show: true,
                    formatter: function (val) {
                        return val;
                    }
                }
            },
            tooltip: {
                x: {
                    show: false
                }
            }
        }
        return (
            <>
                <div>
                    <div className="Over_all_table_style">
                        <div className="card-header" style={{ display: "flex", justifyContent: "space-between" }}>
                            <h3 className="card-title"> {languageData && languageData.INSTANT_NEGATIVE_REVIEWS ? languageData.INSTANT_NEGATIVE_REVIEWS : "Instant Negative Review"}</h3>
                            {reviewClientKeyword && reviewClientKeyword.length > 0 ? <button type="button" className="btn btn-primary sentimental_seemore" id='Add' onClick={this.handleClickMore}><i className="fa fa-th viewmore_icon" style={{ color: "white !important", padding: "0px 10px 0px 0px" }}></i>See More In Detail</button> : ''}
                        </div>
                        <table className="table card-table">
                            {/* <tbody>
                                {countData}
                            </tbody> */}
                        </table>
                    </div>
                </div>
                {
                    reviewClientKeyword && reviewClientKeyword.length ?
                        <div className="card">
                            <div className="card-body">
                                <div id="chart">
                                    <Chart
                                        options={options}
                                        series={seriesArray}
                                        type="bar"
                                        height={350} />
                                </div>
                            </div>
                        </div>

                        :

                        <div className="card">
                            <div className="card-body no_records text-center d-flex align-items-center justify-content-center">
                                <div style={{ maxWidth: "340px" }}>
                                    <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid mb-4 mt-4" style={{ maxWidth: "272px" }} />
                                    <h5 className="mb-2">{hotel ? "No records to display" : "Select a hotel to display records"}</h5>
                                </div>
                            </div>
                        </div>

                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    ReviewState: state.review,
    AccountState: state.account,
})
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ReviewByClient: AC_GET_REVIEW_BY_ID, ReviewByKeyword: AC_REVIEW_KEYWORD
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(SentimentAnalysis);



const initialState = {
    roomtype: {
        hotel: '',
        roomtype: '',
        colorcode: {
            r: '225',
            g: '155',
            b: '99',
            a: '2',
        },
        status: 1,
        id: ''
    },
    listroomtype: [],
    total: '',
    spinner: true
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_ROOMTYPE_CHANGE':
            return Object.assign({}, state, {
                roomtype: {
                    ...state.roomtype,
                    [action.name]: action.value
                },
                spinner: false
            })
        case 'RESET_ROOMTYPE':
            return Object.assign({}, state, {
                roomtype: initialState.roomtype
            })
        case 'LIST_ROOMTYPE':
            return Object.assign({}, state, {
                listroomtype: action.payload,
                total: action.total,
                spinner: action.spinner
            })
        case 'VIEW_ROOMTYPE':
            return Object.assign({}, state, {
                roomtype: action.payload,
            })
        case 'EMPTY_ROOMTYPE':
            return Object.assign({}, state, {
                roomtype: {
                    hotel: '',
                    roomtype: '',
                    colorcode: {
                        r: '225',
                        g: '155',
                        b: '99',
                        a: '2',
                    },
                    id: ''
                },
                spinner: true
            })
        default:
            return state;
    }
}
import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Swal from 'sweetalert2';
import Select from 'react-select';
import jsPDF from "jspdf";
import ImportedUrl from '../../common/api';
import moment from 'moment';
import ImportedURL from '../../common/api';
import { bindActionCreators } from 'redux';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import { AC_LIST_USER } from '../../actions/userAction';
import { AC_LIST_CATEGORY } from '../../actions/categoryAction';
import { Error } from '../../common/swal';
import Spinner from 'react-bootstrap/Spinner';
import { imageCellRendererList, objectToQueryString, onErrorImage } from '../../common/validate';

class UserTickets extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                { headerName: '#', valueGetter: "node.rowIndex+1", width: 70, filter: false },
                { headerName: 'Ticket No.', field: 'ticketid', width: 150, floatingFilter: true },
                { headerName: 'Title', field: 'title', width: 150, floatingFilter: true },
                // { headerName: 'Description', field: 'description', width: 150 },
                {
                    headerName: 'Description', field: 'description', width: 200, floatingFilter: true,
                    valueGetter: function (params) {
                        if (params.data.description) {
                            return params.data.description
                        } else {
                            return "---"
                        }
                    }
                },
                {
                    headerName: 'Logo', width: 100, field: 'hotellogo', hide: true, sortable: false, filter: false, "resizable": false,
                    cellRenderer: imageCellRendererList,
                },
                { headerName: 'Hotel', field: 'hotel', width: 130, hide: true, },
                { headerName: 'Reporter', field: 'reporter', width: 150 },
                {
                    headerName: 'Assignee', field: 'assignee', width: 150,
                    valueGetter: function (params) {
                        if (params.data.assignee) {
                            return params.data.assignee
                        } else {
                            return "---"
                        }
                    }
                },
                {
                    headerName: 'Status', width: 250, field: 'status', cellStyle: { 'text-align': 'center' }, suppressMenu: true,
                    cellRenderer: function (params) {
                        if (params.value == 'open') {
                            return `<span class="tag ticket_open">Open</span>`;
                        } else if (params.value === 'inprogress') {
                            return '<span class="tag ticket_inprogress">Inprogress</span>';
                        } else if (params.value === 'reopened') {
                            return '<span class="tag ticket_reopened">Reopened</span>';
                        } else if (params.value === 'assigned') {
                            return '<span class="tag ticket_assigned">Assigned</span>';
                        } else if (params.value === 'inreview') {
                            return '<span class="tag ticket_inreview">Inreview</span>';
                        } else if (params.value === 'closed') {
                            return '<span class="tag ticket_closed">Closed</span>';
                        } else if (params.value === 'completed') {
                            return '<span class="tag ticket_completed">Completed</span>';
                        } else if (params.value === 'Deleted') {
                            return '<span class="tag ticket_deleted">Deleted</span>';
                        } else {
                            return `<span class="tag default">${params.value}</span>`;
                        }
                    }
                },
                // {
                //     headerName: 'Actions', width: 150, sortable: false, filter: false, cellStyle: { 'text-align': 'center' },
                //     template:
                //         `<div><button type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-eye" style='color: #1DC9B7 !important' data-action-type="View"></i></button></div>`,
                // },
            ],
            defaultColumDef: {
                // "width": 200,
                // "filter": true,
                // "sortable": true,
                // "resizable": true,
                editable: false,
                sortable: true,
                resizable: true,
                filter: true,
            },
            tickets: [],
            originalData: [],
            userTickets: [],
            openCount: 0,
            assignedCount: 0,
            inprogressCount: 0,
            inreviewCount: 0,
            completedCount: 0,
            reopenedCount: 0,
            reassignedCount: 0,
            totalCount: 0,
            ticketid: '',
            hotel: '',
            showTicket: true,
            allOption: true,
            id: '',
            type: '',
            todateValue: '',
            fromdateValue: '',
            isFilterShow: false,
            columnreset: true,
            totalStatus: true,
            typeTwo: 'dfd',
            perPage: 25
        }
    }

    getServiceRequest = (id, type, query = {}) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData;
        this.setState({ type: type, id: id, spinner: true });
        const userID = id ? id : this.state.id;
        const userType = type ? type : this.state.type;
        axios.get(ImportedUrl.API.userTicket + "/" + userID + "/" + userType, { params: query })
            .then((res) => {
                if (res.data) {
                    const { data, open, assigned, inprogress, inreview, completed, reopened, reassigned, isDeleted, totalstatus } = res.data
                    this.setState({
                        tickets: data,
                        hotel: data && data[0] ? data[0].hotelId : '',
                        originalData: data,
                        openCount: open,
                        assignedCount: assigned,
                        inprogressCount: inprogress,
                        inreviewCount: inreview,
                        completedCount: completed,
                        reopenedCount: reopened,
                        reassignedCount: reassigned,
                        isDeletedCount: isDeleted,
                        totalCount: totalstatus,
                        spinner: false,
                    });
                }
            }).catch(({ response }) => {
                if (response.status == 500) {
                    Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                } else if (response.status == 502) {
                    Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                } else {
                    Error(response.statusMessage)
                }
            })
    }

    handleFilterChange = (e) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const { history } = this.props;
        const { path, url } = this.props.match;
        let urlVal = url.split('/');
        let pathFirst = urlVal[1];
        let ids = urlVal[2];
        history.push(`/${pathFirst}/${ids}/${e.value}`);
        const { id } = this.props.match.params;
        this.getServiceRequest(id, e.value);
        this.resetstatuscheck()
    }

    resetstatuscheck = () => {
        this.setState({ openStatus: false, totalStatus: true, assignedStatus: false, inprogressStatus: false, inreviewStatus: false, completedStatus: false, isDeletedStatus: false, reopenedStatus: false, reassignedStatus: false })
    }

    componentDidMount() {
        const account = this.props.AccountState.account;
        const { id, type } = this.props.match.params;
        // this.props.ListUser();
        this.getServiceRequest(id, type);
        this.setState({ hotelName: account.hotelName ? account.hotelName : '' });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }
    onRowClicked = event => {

        this.setState({ modalType: "View", ticketid: event.data.ticketid });
    }
    exportToCSV = () => {
        const account = this.props.AccountState.account;
        const dtFormat = (account.dateformate !== '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat !== '' ? account.timeformat : 'hh:mm A');
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        let hotelName = ''
        if (this.state.tickets.length > 0 && this.state.tickets) {
            this.state.tickets.map((item) => {
                hotelName = item.hotel
            })
        }
        this.gridApi.exportDataAsCsv({
            columnKeys: ['ticketid', 'title', 'description', 'hotel', 'reporter', 'status', 'time'],
            fileName: "Userwisereports" + "_" + (hotelName && (hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                // return true if index is less than first row or more than last row
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                if (params && params.column && params.column.colId === 'time') {
                    return moment(params.value).format(dtFormat);
                } else {
                    return params.value;
                }
            }
        })
    }

    setDateReport = (e) => {
        this.setState({ isFilterShow: true });
        const { id, type } = this.state;
        const { name, value } = e.target;
        this.resetstatuscheck()
        if (name == 'fromdate') {
            this.setState({ fromdate: value, fromdateValue: value, todate: "", todateValue: "", month: "" })
            this.getServiceRequest(id, type, { hotel: this.state.hotel, fromdate: value + "T00:00:00", todate: "" });
        }
        if (name == 'todate') {
            this.setState({ todate: value, todateValue: value, month: "" })
            this.getServiceRequest(id, type, { hotel: this.state.hotel, fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '', todate: value ? value + "T23:59:59" : '' });
        }
        if (!value) {
            if (!this.state.fromdate && name == 'todate') {
                this.setState({ isFilterShow: false });
            }
            if (name == 'fromdate') {
                this.setState({ isFilterShow: false });
            }
        } else {
            this.setState({ isFilterShow: true });
        }
    }

    getMonthlyReport = e => {
        this.setState({ isFilterShow: true });
        const { id, type } = this.state;
        const { value } = e.target;
        const newval = value == 0 ? 0 : value == 1 ? 1 : 2;
        let date = new Date();
        let startDate = new Date(date.getFullYear(), date.getMonth() - newval, 1);
        let endDate = new Date(date.getFullYear(), date.getMonth() - newval + 1, 0);
        this.setState({ fromdate: moment(startDate).format('YYYY-MM-DD'), todate: moment(endDate).format('YYYY-MM-DD'), month: value, fromdateValue: moment(startDate).format("YYYY-MM-DD"), todateValue: moment(endDate).format("YYYY-MM-DD") });
        this.getServiceRequest(id, type, { hotel: this.state.hotel, fromdate: moment(startDate).format('YYYY-MM-DD') + "T00:00:00", todate: moment(endDate).format('YYYY-MM-DD') + "T23:59:59" });
        this.resetstatuscheck()
    }

    getMonths = (mon) => {
        let date = new Date();
        date.setDate(1);
        date.setMonth(date.getMonth() - mon);
        let monthName = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        return `${monthName}`;
    }

    exportPDF = () => {
        const account = this.props.AccountState.account;
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        const selectedProperties = this.state.hotelName ? ['ticketid', 'title', 'description', 'reporter', 'status'] : ['ticketid', 'hotel', 'title', 'description', 'reporter', 'status'];
        const api = this.gridApi;
        const filteredRows = api.getModel().rowsToDisplay
        const ticketsData = filteredRows.map(rowNode => rowNode.data);
        let temp = ticketsData.slice(firstRow, lastRow + 1)
        const selected = temp.map(item => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    if (property == "description") {
                        selectedData[property] = item[property] ? item[property] : "---"
                    } else {
                        selectedData[property] = item[property];
                    }
                }
            });
            return selectedData;
        });
        let data = {
            title: (this.state.hotelName) ? (this.state.hotelName != "All" ? this.state.hotelName + " - User Wise Reports" : "User Wise Reports") : "User Wise Reports",
            head: this.state.hotelName ? ['#', 'Ticket #', "Title", "Description", 'Reported By', 'Status'] : ['#', 'Hotel', 'Ticket #', "Title", "Description", 'Reported By', 'Status'],
            body: selected,
            hotelNameExport: this.state.hotelName ? account.hotelNameExport : '',
            hoteladdressExport: this.state.hotelName ? account.hoteladdressExport : '',
            limit: this.state.perPage,
            ticketmanagement: true,
            hotelWidth: true
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "Userwisereports" + "_" + (this.state.hotelName && (this.state.hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }

    total = e => {
        const { id, type } = this.state;
        this.setState({ openStatus: false, totalStatus: true, assignedStatus: false, spinner: true, inprogressStatus: false, inreviewStatus: false, completedStatus: false, isDeletedStatus: false, reopenedStatus: false, reassignedStatus: false })
        this.getServiceRequest(id, type, { ticketid: this.state.ticketid, hotel: this.state.hotel, fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '', todate: this.state.todate ? this.state.todate + "T23:59:59" : '' });
    }
    open = e => {
        const { id, type } = this.state;
        this.setState({ openStatus: true, status: 'open', totalStatus: false, spinner: true, assignedStatus: false, inprogressStatus: false, inreviewStatus: false, completedStatus: false, isDeletedStatus: false, reopenedStatus: false, reassignedStatus: false })
        this.getServiceRequest(id, type, { ticketid: this.state.ticketid, hotel: this.state.hotel, status: 'open', fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '', todate: this.state.todate ? this.state.todate + "T23:59:59" : '' });
    }
    assigned = e => {
        const { id, type } = this.state;
        this.setState({ assignedStatus: true, totalStatus: false, openStatus: false, spinner: true, inprogressStatus: false, inreviewStatus: false, completedStatus: false, status: 'assigned', isDeletedStatus: false, reopenedStatus: false, reassignedStatus: false })
        this.getServiceRequest(id, type, { ticketid: this.state.ticketid, hotel: this.state.hotel, status: 'assigned', fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '', todate: this.state.todate ? this.state.todate + "T23:59:59" : '' });
    }
    inprogress = e => {
        const { id, type } = this.state;
        this.setState({ inprogressStatus: true, totalStatus: false, openStatus: false, spinner: true, assignedStatus: false, inreviewStatus: false, completedStatus: false, status: 'inprogress', isDeletedStatus: false, reopenedStatus: false, reassignedStatus: false })
        this.getServiceRequest(id, type, { ticketid: this.state.ticketid, hotel: this.state.hotel, status: 'inprogress', fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '', todate: this.state.todate ? this.state.todate + "T23:59:59" : '' });
    }
    inreview = e => {
        const { id, type } = this.state;
        this.setState({ inreviewStatus: true, openStatus: false, totalStatus: false, spinner: true, assignedStatus: false, inprogressStatus: false, completedStatus: false, status: 'inreview', isDeletedStatus: false, reopenedStatus: false, reassignedStatus: false })
        this.getServiceRequest(id, type, { ticketid: this.state.ticketid, hotel: this.state.hotel, status: 'inreview', fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '', todate: this.state.todate ? this.state.todate + "T23:59:59" : '' });
    }
    completed = e => {
        const { id, type } = this.state;
        this.setState({ completedStatus: true, openStatus: false, totalStatus: false, spinner: true, assignedStatus: false, inprogressStatus: false, inreviewStatus: false, status: 'completed', isDeletedStatus: false, reopenedStatus: false, reassignedStatus: false })
        this.getServiceRequest(id, type, { ticketid: this.state.ticketid, hotel: this.state.hotel, status: 'completed', fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '', todate: this.state.todate ? this.state.todate + "T23:59:59" : '' });
    }
    reopened = e => {
        const { id, type } = this.state;
        this.setState({ reopenedStatus: true, openStatus: false, spinner: true, totalStatus: false, assignedStatus: false, inprogressStatus: false, inreviewStatus: false, status: 'reopened', isDeletedStatus: false, completedStatus: false, reassignedStatus: false })
        this.getServiceRequest(id, type, { ticketid: this.state.ticketid, hotel: this.state.hotel, status: 'reopened', fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '', todate: this.state.todate ? this.state.todate + "T23:59:59" : '' });
    }
    reassigned = e => {
        const { id, type } = this.state;
        this.setState({ spinner: true, reassignedStatus: true, openStatus: false, totalStatus: false, assignedStatus: false, inprogressStatus: false, isDeletedStatus: false, status: 'reassigned', inreviewStatus: false, completedStatus: false, reopenedStatus: false })
        this.getServiceRequest(id, type, { ticketid: this.state.ticketid, hotel: this.state.hotel, status: 'reassigned', fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '', todate: this.state.todate ? this.state.todate + "T23:59:59" : '' });
    }
    isDeleted = e => {
        const { id, type } = this.state;
        this.setState({ spinner: true, reassignedStatus: false, openStatus: false, totalStatus: false, assignedStatus: false, inprogressStatus: false, isDeletedStatus: true, status: 'isDeleted', inreviewStatus: false, completedStatus: false, reopenedStatus: false })
        this.getServiceRequest(id, type, { ticketid: this.state.ticketid, hotel: this.state.hotel, status: 'isDeleted', fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '', todate: this.state.todate ? this.state.todate + "T23:59:59" : '' });
    }
    filterReset = (e) => {
        this.setState({
            fromdate: '',
            todate: '',
            month: '',
            fromdateValue: '',
            todateValue: '',
            isFilterShow: false,
            status: "",
            inprogressStatus: false, totalStatus: true, openStatus: false, assignedStatus: false, inreviewStatus: false, completedStatus: false, isDeletedStatus: false, reopenedStatus: false, reassignedStatus: false
        });
        const { id, type } = this.props.match.params;
        this.getServiceRequest(id, type);
    }
    handleFilterChanged = () => {
        const api = this.gridApi;
        if (api && api.getDisplayedRowCount() === 0) {
            api.showNoRowsOverlay();
            this.setState({ hideExport: true })
        } else {
            api.hideOverlay();
            this.setState({ hideExport: false })
        }
    };
    render() {
        const { AccountState, UserState, LandingState } = this.props;
        const listCategory = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[7] : [];
        const listHotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[0] : [];
        const listUserData = UserState.listusers;
        const account = AccountState?.account;
        const languageData = AccountState.account.SelectedlanguageData
        if (this.state.modalType === 'View') {
            const myObject = { status: this.state.status, fromdate: this.state.fromdate ? this.state.fromdate + "T00:00:00" : '', todate: this.state.todate ? this.state.todate + "T23:59:59" : '' };
            const objectString = objectToQueryString(myObject)
            return <Redirect to={'/view-user-ticket/' + this.state.id + "/" + this.state.type + "/" + this.state.ticketid + objectString} />
        }
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        var hotelOptions = [];
        var userHotel = [];
        if (listUserData.length > 0) {
            listUserData.map((item) => {
                if (this.props.match.params.id == item._id) {
                    userHotel.push(item.hotelid)
                }
            })
        }
        if (userHotel.length > 1) {
            hotelOptions.push({ label: 'All', value: '', name: 'hotel' })
        }
        if (listHotels.length > 0) {
            listHotels.map(item => {
                userHotel.map((item1) => {
                    if (item._id == item1) {
                        if (ImportedURL.SUPERADMIN.includes(account.role)) {
                            hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
                        } else {
                            hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
                        }
                    }
                })
            })
        }
        let hotelName = ''
        let hotelLogo = ''
        let name = ''
        let category = ''
        let categoryName = ''
        if (this.state.tickets.length > 0 && this.state.tickets) {
            this.state.tickets.map((item) => {
                hotelName = item.hotel
                hotelLogo = item.logo
                name = item.name
                category = item.category
            })
        }
        if (listCategory.length > 0) {
            listCategory.map((item) => {
                if (category != undefined && category != null)
                    if (category == item._id) {
                        categoryName = item.name
                    }
            })
        }

        const filterOptions = [
            { value: 'assignee', label: 'Assigned' },
            { value: 'reporter', label: 'Created' },
        ]
        var selectedFilterValue = ''
        filterOptions.map((item) => {
            if (item.value == this.state.type) {
                selectedFilterValue = { label: item.label, value: item.value }
            }
        })
        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/ticket-report"><h6> Users Reports</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>User-wise Tickets List</h6></p>
                        </div>
                        <div className="section-body pt-3 list_ticket">
                            <div className="container-fluid">
                                <div className="row clearfix">
                                    <div className="col-6 col-md-6 col-xl-3">
                                        <div className="card" >
                                            <div className="card-body ribbon" style={{ backgroundColor: '#e9c1e4', boxShadow: this.state.totalStatus ? '0 0 5px #0a0a0a' : '' }}>
                                                <div className="ribbon-box orange">{this.state.totalCount ? this.state.totalCount : 0}</div>
                                                <a className="my_sort_cut" href="#" onClick={this.total} >
                                                    <span className='dash_img'>
                                                        <img src='../../assets/images/totaltickets.png' />
                                                    </span>
                                                    <span className='dash_text'>Total Tickets</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 col-xl-3">
                                        <div className="card">
                                            <div className="card-body ribbon " style={{ backgroundColor: '#a8e1ff', borderRadius: this.state.openStatus ? '10px' : '', boxShadow: this.state.openStatus ? '0 0 5px #0a0a0a' : '' }}>
                                                <div className="ribbon-box orange">{this.state.openCount ? this.state.openCount : 0}</div>
                                                <a className="my_sort_cut text-muted" href="#" onClick={this.open}>
                                                    <span className='dash_img'>
                                                        <img src='../../assets/images/opentickets.png' />
                                                    </span>
                                                    <span style={{ color: '#444444' }} className='dash_text'>Open Tickets</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 col-xl-3">
                                        <div className="card">
                                            <div className="card-body ribbon" style={{ backgroundColor: '#28e3ff', borderRadius: this.state.assignedStatus ? '10px' : '', boxShadow: this.state.assignedStatus ? '0 0 5px #0a0a0a' : '' }}>
                                                <div className="ribbon-box orange">{this.state.assignedCount ? this.state.assignedCount : 0}</div>
                                                <a className="my_sort_cut text-muted" href="#" onClick={this.assigned}>
                                                    <span className='dash_img'>
                                                        <img src='../../assets/images/assignedtickets.png' />
                                                    </span>
                                                    <span style={{ color: '#444444' }} className='dash_text'>Assigned Tickets</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 col-xl-3">
                                        <div className="card">
                                            <div className="card-body ribbon" style={{ backgroundColor: '#eeeaaf', borderRadius: this.state.inprogressStatus ? '10px' : '', boxShadow: this.state.inprogressStatus ? '0 0 5px #0a0a0a' : '' }}>
                                                <div className="ribbon-box orange">{this.state.inprogressCount ? this.state.inprogressCount : 0}</div>
                                                <a className="my_sort_cut text-muted" href="#" onClick={this.inprogress}>
                                                    <span className='dash_img'>
                                                        <img src='../../assets/images/inprogresstickets.png' />
                                                    </span>
                                                    <span style={{ color: '#444444' }} className='dash_text'>In Progress Tickets</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row clearfix">
                                    <div className="col-6 col-md-6 col-xl-3">
                                        <div className="card">
                                            <div className="card-body ribbon" style={{ backgroundColor: '#f5d7bb', borderRadius: this.state.inreviewStatus ? '10px' : '', boxShadow: this.state.inreviewStatus ? '0 0 5px #0a0a0a' : '' }}>
                                                <div className="ribbon-box orange">{this.state.inreviewCount ? this.state.inreviewCount : 0}</div>
                                                <a className="my_sort_cut text-muted" href="#" onClick={this.inreview}>
                                                    <span className='dash_img'>
                                                        <img src='../../assets/images/inreviewtickets.png' />
                                                    </span>
                                                    <span style={{ color: '#444444' }} className='dash_text'>In Review Tickets</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6 col-md-6 col-xl-3">
                                        <div className="card">
                                            <div className="card-body ribbon" style={{ backgroundColor: '#c4e4a5', borderRadius: this.state.completedStatus ? '10px' : '', boxShadow: this.state.completedStatus ? '0 0 5px #0a0a0a' : '' }}>
                                                <div className="ribbon-box orange">{this.state.completedCount ? this.state.completedCount : 0}</div>
                                                <a className="my_sort_cut text-muted" href="#" onClick={this.completed}>
                                                    <span className='dash_img'>
                                                        <img src='../../assets/images/completedtickets.png' />
                                                    </span>
                                                    <span style={{ color: '#444444' }} className='dash_text'>Completed Tickets</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className="col-6 col-md-6 col-xl-3" >
                                        <div className="card">
                                            <div className="card-body ribbon" style={{ backgroundColor: '#a9e8c1', borderRadius: this.state.reopenedStatus ? '10px' : '', boxShadow: this.state.reopenedStatus ? '0 0 5px #0a0a0a' : '' }}>
                                                <div className="ribbon-box orange">{this.state.reopenedCount ? this.state.reopenedCount : 0}</div>
                                                <a className="my_sort_cut text-muted" href="#" onClick={this.reopened}>
                                                    <span className='dash_img'>
                                                        <img src='../../assets/images/reopenedtickets.png' />
                                                    </span>
                                                    <span style={{ color: '#444444' }} className='dash_text'>Reopended Tickets</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-6 col-md-6 col-xl-3">
                                        <div className="card">
                                            <div className="card-body ribbon" style={{ backgroundColor: '#cec6c0', borderRadius: this.state.reassignedStatus ? '10px' : '', boxShadow: this.state.reassignedStatus ? '0 0 5px #0a0a0a' : '' }}>
                                                <div className="ribbon-box orange">{this.state.reassignedCount ? this.state.reassignedCount : 0}</div>
                                                <a className="my_sort_cut text-muted" href="#" onClick={this.reassigned}>
                                                    <span className='dash_img'>
                                                        <img src='../../assets/images/reassignedtickets.png' />
                                                    </span>
                                                    <span style={{ color: '#444444' }} className='dash_text'>Rejected Tickets</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div> */}
                                    <div className="col-6 col-md-6 col-xl-3">
                                        <div className="card">
                                            <div className="card-body ribbon" style={{ backgroundColor: '#D59191', borderRadius: this.state.isDeletedStatus ? '10px' : '', boxShadow: this.state.isDeletedStatus ? '0 0 5px #0a0a0a' : '' }}>
                                                <div className="ribbon-box orange">{this.state.isDeletedCount ? this.state.isDeletedCount : 0}</div>
                                                <a className="my_sort_cut text-muted" href="#" onClick={this.isDeleted}>
                                                    <span className='dash_img'>
                                                        <img src='../../assets/images/deleteblack.png' />
                                                    </span>
                                                    <span style={{ color: '#444444' }} className='dash_text'>Deleted Tickets</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-body pt-3">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header res_tit_tick">
                                                <div className='d-flex'>
                                                    <div className='rounded_icon'><i className="icon-layers mr-2 "></i></div>
                                                    <div className='d-flex align-items-center'>
                                                        <h3 className="card-title">User-Wise Tickets List </h3>
                                                    </div>
                                                </div>
                                                <div className="card-options">
                                                    {this.state.isFilterShow &&
                                                        <button className='btn topsocial_rest_btn btn-primarys ' type='button' onClick={this.filterReset} style={{ height: '45px !important', width: '115pxpx !important' }}><i className="fa fa-refresh" aria-hidden="true" style={{ fontSize: '20px !importent', marginRight: '7px' }}></i> Reset</button>
                                                    }
                                                    {/* <a href="/#" className="btn btn-primary btn-sm">Action 1</a> */}
                                                    {(this.state.tickets).length > 0 && !this.state.hideExport ?
                                                        <>
                                                            <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                            <div className="dropdown-menu">
                                                                <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                            </div>
                                                        </> : ''}
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    {
                                                        (this.state.tickets && this.state.tickets.length > 0)
                                                        &&
                                                        <>
                                                            {ImportedURL.SUPERADMIN.includes(account.role) &&
                                                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3">
                                                                    <>
                                                                        <img className="rounded-circle img-thumbnail w100" src={ImportedURL.FILEURL + hotelLogo} onError={onErrorImage} alt="fake_url" /> <label className="mt-3 mb-0">{hotelName + " - " + name + "(" + categoryName + ")"}</label>
                                                                    </>
                                                                </div>
                                                            }
                                                        </>

                                                    }
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3 ">
                                                        <label className="form-label">Limit</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={(e) => { this.gridApi.paginationSetPageSize(Number(e.value)); this.setState({ perPage: e.value }) }}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-6 col-xl-3">
                                                        <label className='form-label'>From Date</label>
                                                        <div className='site_pass_calander'>
                                                            <input type='date' className="form-control" name="fromdate" value={this.state.fromdateValue} onChange={this.setDateReport}></input>
                                                            <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xl-3">
                                                        <label className='form-label'>To Date</label>
                                                        <div className='site_pass_calander'>
                                                            <input type='date' className="form-control" name="todate" min={this.state.fromdateValue ? this.state.fromdateValue : ''} onChange={this.setDateReport} value={this.state.todateValue}></input>
                                                            <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                        </div>
                                                    </div>

                                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xl-3">
                                                        <div className="form-group">
                                                            <label className='form-label'>Archived Reports</label>
                                                            <div className="selectgroup w-100">
                                                                <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                    <input type="radio" name="month" className="selectgroup-input" value={0} onChange={this.getMonthlyReport} checked={this.state.month === '0'} />
                                                                    <span className="selectgroup-button first_month">{this.getMonths(0)}</span>
                                                                </label>
                                                                <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                    <input type="radio" name="month" className="selectgroup-input" value={1} onChange={this.getMonthlyReport} checked={this.state.month === '1'} />
                                                                    <span className="selectgroup-button">{this.getMonths(1)}</span>
                                                                </label>
                                                                <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                    <input type="radio" name="month" className="selectgroup-input" value={2} onChange={this.getMonthlyReport} checked={this.state.month === '2'} />
                                                                    <span className="selectgroup-button last_month">{this.getMonths(2)}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3 mb-3">
                                                        <label className="form-label">Created or Assigned</label>
                                                        <div className="form-group">
                                                            <Select
                                                                value={selectedFilterValue ? selectedFilterValue : ''}
                                                                onChange={this.handleFilterChange}
                                                                options={filterOptions}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="table-responsive">
                                                    <div className="ag-theme-alpine ticket_aggridrow">
                                                        <AgGridReact
                                                            rowHeight={80}
                                                            paginationPageSize={25}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            rowData={this.state.tickets}
                                                            columnDefs={this.state.columnDefs}
                                                            onGridReady={this.onGridReady}
                                                            overlayNoRowsTemplate={"No rows to display"}
                                                            onFilterChanged={this.handleFilterChanged}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.spinner ?
                                                        <div className='common_loader'>
                                                            <img className='loader_img_style_common' src='../../assets/images/load.png' />
                                                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                                                            </Spinner>
                                                        </div> : ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    UserState: state.user,
    AccountState: state.account,
    LandingState: state.landing,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListHotel: AC_LIST_HOTEL,
        ListCategory: AC_LIST_CATEGORY,
        ListUser: AC_LIST_USER,
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(UserTickets);
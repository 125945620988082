import React, { Component } from 'react'
import { connect } from 'react-redux';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import ImportedURL from '../../../common/api';
import { AC_EMPTY_TICKET_COUNT, AC_GET_TICKET_REPORT } from '../../../actions/ticketAction';
import CurrentStatus from './currentstatus';
import CurrentStatusChart from './piechart';
import CurrentPriority from './currentpriority';
import CurrentPriorityChart from './barchart';
import Categorywisechart from './categorywisechart';
import SentimentAnalysis from '../Tickets/sentimentalanalysis';
import { Link } from 'react-router-dom';
import Spinner from 'react-bootstrap/Spinner';
import { onErrorImage } from '../../../common/validate';
class ListTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hotelsList: [],
            hotel: '',
            viewredirect: false,
        }
    }

    componentDidMount() {
        const account = this.props.AccountState.account;
        if (account.hotel != undefined && account.hotel) {
            this.setState({ hotel: account.hotel })
        }
        this.props.EmptyTicketCount();
        this.props.GetTicketReport({});
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }

    handleHotelChange = (e) => {
        this.setState({ hotel: e.value })
        this.props.EmptyTicketCount();
        this.props.GetTicketReport({ hotel: e.value });
    };

    handleClick = () => {
        this.setState({ viewredirect: true })
    }

    render() {
        const { LandingState, TicketState, AccountState } = this.props;
        const account = AccountState.account;
        const languageData = AccountState.account.SelectedlanguageData
        const listhotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[0] : [];
        const ticketStatusCount = TicketState;
        const ticketListCount = TicketState.userTickets;
        const spin = ticketStatusCount.spinner;
        const hotelOptions = [];
        hotelOptions.push({ label: `All`, value: '', name: 'hotel' });
        listhotels.filter(filterItem => filterItem.status === true).map((item) => {
            hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
        })

        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/ticket-reports"><h6 className="highlights_breadcrump"> {languageData && languageData.TICKET_REPORTS ? languageData.TICKET_REPORTS : "Tickets Reports"}</h6></Link></p>
                    </div>
                    <div className="section-body pt-3 user_sec ticket_roport">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card " style={{ backgroundColor: '#fff !importent' }}>
                                        <div className="card-header hd_bg_tic_rep">
                                            <div className='rounded_icon'><i className="fa fa-bar-chart mr-2 "></i></div>
                                            <h3 className="card-title"> {languageData && languageData.TICKET_REPORTS ? languageData.TICKET_REPORTS : "Tickets Reports"}</h3>
                                            <div className="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="header-action" >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                {ImportedURL.SUPERADMIN.includes(account.role) && <div className="col-sm-4 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">{languageData && languageData.SEARCH ? languageData.SEARCH : "Search"} {languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel"}</label>
                                                        <Select
                                                            defaultValue={{ label: "All", value: 0 }}
                                                            name="hotel"
                                                            options={hotelOptions}
                                                            onChange={this.handleHotelChange}
                                                            formatOptionLabel={(e) => (
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                    <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                </div>
                                                            )}
                                                        />
                                                    </div>
                                                </div>}
                                                <CurrentStatus ticketStatusCount={ticketStatusCount} />
                                                <CurrentStatusChart ticketStatusCount={ticketStatusCount} />
                                            </div>
                                            <div className="row">
                                                <SentimentAnalysis hotel={this.state.hotel} />

                                            </div>
                                            <div className="row">
                                                <CurrentPriority ticketStatusCount={ticketStatusCount} />
                                                <CurrentPriorityChart ticketStatusCount={ticketStatusCount} />
                                            </div>

                                            <div className="row">
                                                <Categorywisechart status='open' hotel={this.state.hotel} />
                                            </div>
                                            <div className="row">
                                                <Categorywisechart status='completed' hotel={this.state.hotel} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="overlay" style={{ display: spin ? 'block' : 'none' }}></div>
                    {spin ?
                        <div className='common_loader'>
                            <img className='loader_img_style_common' src='../../assets/images/load.png' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                        </div>
                        : ''
                    }
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    TicketState: state.ticket,
    LandingState: state.landing,
})
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        EmptyTicketCount: AC_EMPTY_TICKET_COUNT,
        GetTicketReport: AC_GET_TICKET_REPORT,
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ListTicket);
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
class CurrentStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    render() {
        const { HotelState, AccountState, TicketState } = this.props;
        const ticketStatusCount = TicketState;
        return (
            <>
                <div className="col-xl-5 col-lg-5 col-md-5">
                    <div className="card">
                        <div className="Over_all_table_style card-body">
                            <div className="card-header"><h3 className="card-title">Current Status Of Tickets</h3></div>
                            <table className="table card-table">
                                <tbody>
                                    <tr>
                                        <td>
                                            <strong className='table_text_left1'>{ticketStatusCount.openCount}</strong>
                                        </td>
                                        <td className="text-right">
                                            <small className="text-muted table_text_left1" style={{ fontSize: "14px", fontWeight: "bold" }}>Open</small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong className='table_text_left1'>{ticketStatusCount.assignedCount}</strong>
                                        </td>
                                        <td className="text-right">
                                            <small className="text-muted table_text_left1" style={{ fontSize: "14px", fontWeight: "bold" }}>Assigned</small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong className='table_text_left1'>{ticketStatusCount.inprogressCount}</strong>
                                        </td>
                                        <td className="text-right">
                                            <small className="text-muted table_text_left1" style={{ fontSize: "14px", fontWeight: "bold" }}>In Progress</small>
                                        </td>
                                    </tr>
                                    {/* <tr>
                                        <td>
                                            <strong className='table_text_left1'>{ticketStatusCount.reopenedCount}</strong>
                                        </td>
                                        <td className="text-right">
                                            <small className="text-muted table_text_left1" style={{ fontSize: "14px", fontWeight: "bold" }}>Re-Opended</small>
                                        </td>
                                    </tr> */}
                                    <tr>
                                        <td>
                                            <strong className='table_text_left1'>{ticketStatusCount.inreviewCount}</strong>
                                        </td>
                                        <td className="text-right">
                                            <small className="text-muted table_text_left1" style={{ fontSize: "14px", fontWeight: "bold" }}>In Review</small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong className='table_text_left1'>{ticketStatusCount.completedCount}</strong>
                                        </td>
                                        <td className="text-right">
                                            <small className="text-muted table_text_left1" style={{ fontSize: "14px", fontWeight: "bold" }}>Completed</small>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <strong className='table_text_left1'>{ticketStatusCount.deletedCount}</strong>
                                        </td>
                                        <td className="text-right">
                                            <small className="text-muted table_text_left1" style={{ fontSize: "14px", fontWeight: "bold" }}>Deleted</small>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    HotelState: state.hotel,
    AccountState: state.account,
    TicketState: state.ticket,
})
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(CurrentStatus);
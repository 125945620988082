import { Error } from '../../common/swal';

const ErrorResponseComponent = (languageData, response, existData) => {
    if (response) {
        if (response.status == 401) {
            Error('Something wrong, Retry again!')
        } else if (response.status == 502) {
            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
        } else if (response.status == 500) {
            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
        } else if (response.status == 400) {
            Error(response.status + languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
        } else if (response.status == 409) {
            Error(existData ? existData + ' already exist' : "Already exists")
        } else {
            Error('Bad request')
        }
    } else {
        Error('Bad request')
    }
}

export default ErrorResponseComponent

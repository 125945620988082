import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_HANDLE_TAB_CHANGE_REVIEW_FLOLW(value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_TAB_CHANGE_REVIEW_FLOLW", value: value })
    };
}


export function AC_HANDLE_REVIEW_FLOLW_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_REVIEW_FLOLW_CHANGE", name: name, value: value })
    };
}

export function AC_EMPTY_REVIEW_FLOLW() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_REVIEW_FLOLW" })
    };
}

export function AC_VIEW_REVIEW_FLOLW(params = {}) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewReviewFlow, { params: params })
            .then((res) => {
                dispatch({ type: 'VIEW_REVIEW_FLOLW', payload: res.data, spinner: false });
            })
    }
}
import axios from 'axios';
import React, { Component } from 'react'
import { Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { AC_HANDLE_TODO_LIST_CHANGE, AC_HANDLE_TODO_LIST_ITEM_CHANGE, AC_HANDLE_TODO_LIST_ITEM_UPDATE, AC_VIEW_TODO_LIST_ITEM, AC_EMPTY_LIST_TODO_ITEM } from '../../actions/todolistAction';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import { AC_LIST_CATEGORY } from '../../actions/categoryAction';
import { onErrorImage } from '../../common/validate';


class AddTodoListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: 1,
            itemvalue: [],
            duration: '',
            itemsError: [],
            durationError: false,
            hotelError: false,
            removeItem: -1,
            modalType: "Add",
            listRedirect: false,
            saving: false,
            initial: true,
            starttimeValidateError: false,
            endtimeValidateError: false,
            editSpi: false,
            allOption: true,
        }
    }

    componentWillUpdate(nextProps) {
        const newaccount = nextProps.AccountState.account;
        if (this.state.initial) {
            if (!ImportedURL.SUPERADMIN.includes(nextProps.AccountState.role)) {
                this.props.handleChange('hotel', newaccount.hotel)
                this.setState({ owner: true });
            }
            this.setState({ initial: false })
        }
    }

    componentDidMount() {
        this.props.emptystate();
        const { params, path } = this.props.match;
        if (params.id) {
            this.props.viewtodolistitemdata(params.id, params.hotelid);
            this.setState({ modalType: path === "/view-to-do-list-item/:id" ? "View" : "Edit", id: params.id, editSpi: true })
        }
    }
    addTodoitem = (e) => {
        this.setState({ items: this.state.items + 1 })
    }
    removeTodoitem = (e, i) => {
        this.setState({ itemvalue: this.state.itemvalue.splice(i, 1) })
    }
    onChange = (e) => {
        const { name, value } = e.target;
        if (value.length) {
            // this.setState({ itemvalue:[...this.state.itemvalue,value], itemsError: false })
            this.props.todolistitemChange('items', { ...this.state.itemvalue, name: [value] })
            this.setState({ itemsError: false })
        }
        else {
            this.setState({ itemsError: true })
        }
    }
    handleChange = (e) => {
        const { name, value } = e;
        if (value.length) {
            this.props.handleChange('duration', value);
            this.setState({ durationError: false })
        }
        else {
            this.setState({ durationError: true })
        }
    }
    handleChangeHotel = (e) => {
        const { name, value } = e;
        if (value.length) {
            this.props.handleChange('hotel', value)
            this.setState({ hotelError: false })
        }
        else {
            this.setState({ hotelError: true })
        }
    }
    handleChangeMultiSelect = (e) => {
        const { AccountState, TodolistitemState, LandingState } = this.props;
        const listCategory = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 0 ? LandingState.landingdata.data[7] : [];
        const Error = 'category' + "Error";
        this.setState({ [Error]: false });
        let allData = false
        let multivalueArray = [];
        if (e && e.length > 0) {
            e && e.map((data) => {
                if (data.value == "All") {
                    allData = true
                    this.setState({ allOption: false })
                } else {
                    allData = false
                    multivalueArray.push(data.value)
                    this.setState({ allOption: true })
                }
            })
        } else {
            this.setState({ allOption: true })
        }
        if (listCategory.length == e.length) {
            this.setState({ allOption: false })
        }
        let dataArray = []
        if (allData) {
            listCategory.filter(filterItem => filterItem.status === true).map(item => {
                dataArray.push(item._id)
            })
        }
        this.setState({ multiplecategoryError: false });
        this.props.handleChange('category', allData ? dataArray : multivalueArray);


        // let pushedArr = []
        // if (e && e.length > 0) {
        //     e.map((item) => {
        //         const { value } = item;
        //         pushedArr.push(value)
        //     })
        // }
        // this.props.handleChange('category', pushedArr);
    }
    onSubmit = (e) => {
        const { AccountState } = this.props;
        const data = this.props.TodolistitemState.viewtodolistitem;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        let hotel = data.hotel;
        let itemvalue = data.itemvalue;
        let duration = data.duration;
        let lastreturnid = data.lastreturnid;
        let isValid = true;
        if (data.itemvalue && data.itemvalue.length > 0) {
            let arr = []
            data.itemvalue.map((item, i) => {
                item['name'] = item.name.trim()
                if (!item.name) {
                    arr.push(i)
                }
            })
            if (arr.length > 0) {
                this.setState({ itemsError: arr })
                isValid = false;
            }
        } else {
            isValid = false;
        }
        if (!data.category || data.category.length == 0) {
            this.setState({ categoryError: true })
            isValid = false;
        }
        // if (!data.duration) {
        //     this.setState({ durationError: true })
        //     isValid = false;
        // }
        // if (data.duration == "Daily") {
        //     if (!data.starttime) {
        //         this.setState({ starttimeError: true })
        //         isValid = false;
        //     }
        //     if (!data.endtime) {
        //         this.setState({ endtimeError: true })
        //         isValid = false;
        //     }
        // }
        if (!data.hotel) {
            this.setState({ hotelError: true })
            isValid = false;
        }
        if (isValid) {
            if (this.state.modalType == "Add") {
                if (itemvalue && hotel) {
                    this.setState({ saving: true })
                    axios.post(ImportedURL.API.addTodolistitem, data)
                        .then((res) => {
                            Success((languageData && languageData.CHECK_LIST ? languageData.CHECK_LIST : "Check List listitem") + (languageData && languageData.CREATED_SUCCESSFULLY ? languageData.CREATED_SUCCESSFULLY : " created successfully"));
                            this.setState({ saving: false })
                            this.setState({ listRedirect: true })
                        }).catch(({ response }) => {
                            if (response.status == 500) {
                                Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                            } else if (response.status == 502) {
                                Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                            } else if (response.status == 400) {
                                Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                            } else {
                                Error(response.statusMessage)
                            }
                            this.setState({ saving: false })
                        });
                }
            } else {
                this.setState({ saving: true })
                this.setState({ editSpi: true })
                axios.post(ImportedURL.API.updatetodolistitem + '/' + data._id, data)
                    .then((res) => {
                        Success((languageData && languageData.CHECK_LIST ? languageData.CHECK_LIST : "Check List listitem") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"));
                        this.setState({ saving: false })
                        this.setState({ listRedirect: true })
                    }).catch(({ response }) => {
                        if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else if (response.status == 400) {
                            Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                        this.setState({ saving: false })
                    });
            }
        }

    }
    addFormFields(e) {
        this.setState({ spinner: false })
        this.props.todolistitemChangeUpdate('items', { 'name': '', togglestatus: "true" }, '');
        this.setState({ itemsError: [] })
    }
    removeFormFields(i) {
        this.setState({ spinner: false })
        this.setState({ itemsError: [] })
        const DataValue = this.props.TodolistitemState.viewtodolistitem;
        if (DataValue.length === 1 && i === 0) {
            this.props.todolistitemChangeUpdate(true, { 'name': '' }, i);
        } else {
            this.props.todolistitemChangeUpdate('items', '', i);
        }
    }

    onChangeItem(i, e) {
        const { name, value } = e.target;
        this.props.todolistitemChange("name", value, i);
        let arr = this.state.itemsError ? this.state.itemsError.filter((e) => i !== e) : []
        this.setState({ itemsError: arr })
    }

    ErrorStatus = (index) => {
        if (this.state.itemsError.length > 0) {
            if (this.state.itemsError.includes(index)) {
                return true
            } else {
                return false
            }
        } else {
            return false
        }
    }
    onChangetoggle(i, e) {
        const { name, value, checked } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        const data = this.props.TodolistitemState.viewtodolistitem.itemvalue;
        var temp = [
            ...data.slice(0, i),
            { ...data[i], [name]: name == "togglestatus" ? checked : value },
            ...data.slice(i + 1)
        ]
        this.props.handleChange('itemvalue', temp);
    }
    render() {
        const { AccountState, TodolistitemState, LandingState } = this.props;
        if (this.state.listRedirect) return <Redirect to={'/to-do-list-item'} />
        const { ListCategoryFilter, ListUsernameFilter } = this.state;
        const languageData = AccountState.account.SelectedlanguageData
        const durationOption = [
            { value: 'Yearly', label: 'Yearly', name: 'duration' },
            { value: 'Half-Yearly', label: 'Half-Yearly', name: 'duration' },
            { value: 'Monthly', label: 'Monthly', name: 'duration' },
            { value: 'Bi-Weekly', label: 'Bi-Weekly', name: 'duration' },
            { value: 'Weekly', label: 'Weekly', name: 'duration' },
            { value: 'Daily', label: 'Daily', name: 'duration' },
            { value: 'Custom', label: 'Custom', name: 'duration' }
        ]
        const account = AccountState.account;
        const data = TodolistitemState.viewtodolistitem;
        const dataSpinner = TodolistitemState.spinner;
        const listHotelData = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[0] : [];

        var selectedHotel = '';
        const hotelOptions = [];
        if (account?.allhotels?.length > 0) {
            account.allhotels.map((item) => {
                if (account.hotel == item.hotelid) {
                    selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                }
                hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo });
            })
        } else {
            listHotelData.filter(e => e.status === true).map((item) => {
                if (data?.hotel == item._id) {
                    selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                }
                hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
            })
        }
        var itemArray = [];
        if (data.itemvalue && data.itemvalue.length > 0) {
            data.itemvalue.map((item, i) => {
                itemArray.push(
                    <div className='row' key={i}>
                        <div className="col-sm-8 col-lg-8">
                            <div className="form-group">
                                <label className="form-label">Item<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                <div className=''>
                                    <p>
                                        <input type="text" className='form-control' name="item" value={item.name} onChange={e => this.onChangeItem(i, e)} placeholder={"Item"} />
                                        <div className="invalid-feedback" style={{ display: this.ErrorStatus(i) ? 'block' : 'none' }}>Item is required</div>
                                    </p>
                                </div>

                            </div>
                        </div>
                        <div className="col-sm-2 col-lg-2">
                            <label className="custom-control custom-switch mr-3">
                                <input type="checkbox" className="custom-control-input" name="togglestatus"
                                    checked={item.togglestatus}
                                    onChange={e => this.onChangetoggle(i, e)}
                                />
                                <span className="custom-control-label" style={{ cursor: 'pointer', marginLeft: '30px', marginTop: "32px" }}>Status</span>
                            </label>
                        </div>
                        <div className="col-sm-2 col-lg-2">
                            <div className='todo_increment d-flex'>
                                {data.itemvalue.length > 1 ?
                                    < span className='plus_minus' > <i className="fa fa-minus-circle" aria-hidden="true" onClick={() => this.removeFormFields(i)}></i></span>
                                    : ''}
                                <span className='plus_minus'><i className="fa fa-plus-circle" aria-hidden="true" onClick={() => this.addFormFields(item, i)}></i></span>
                            </div>
                        </div>

                    </div >
                )
            })
        }
        const categorySelect = []
        const categoryOption = []
        const listCategory = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 0 ? LandingState.landingdata.data[7] : [];
        if (listCategory && listCategory.length > 0) {
            if (this.state.allOption) categoryOption.push({ label: "All", value: 'All', name: 'category' })
            listCategory.filter((e) => e.status == true).map((temp) => {
                let status = (data.category && Array.isArray(data.category) && data.category.length > 0) ? data.category.find(e => e == temp._id) : (data.category ? data.category == temp._id : "")
                if (status) {
                    categorySelect.push({ label: `${temp.name}`, value: temp._id, name: 'category' })
                }
                categoryOption.push({ label: `${temp.name}`, value: temp._id, name: 'category' })
            })
        }
        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/to-do-list-item"><h6>{languageData && languageData.CHECK_LIST ? languageData.CHECK_LIST : "Check List"}</h6></Link><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>{this.state.modalType} {languageData && languageData.CHECK_LIST ? languageData.CHECK_LIST : "Check List"}</h6> </p>
                    </div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade show active"
                                    id="Departments-list"
                                    role="tabpanel"
                                >
                                    <div className="card">
                                        <div className="card-body border-0">
                                            <div className='title_mt'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                                    <div className="d-flex align-items-center ">
                                                        <h3 className="card-title">{this.state.modalType} {languageData && languageData.CHECK_LIST ? languageData.CHECK_LIST : "TO-DO"}</h3>
                                                    </div>
                                                </div>
                                                <div className="header-action" style={{ marginTop: '5px' }}>
                                                    <Link to='/to-do-list-item'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            <form id='form_reset'>

                                                <Row>
                                                    {ImportedURL.SUPERADMIN.includes(account.role) ?
                                                        <div className='col-lg-12 col-md-12'>
                                                            <div className="form-group">
                                                                <label className="form-label"> Hotel<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                                <Select
                                                                    value={selectedHotel}
                                                                    onChange={this.handleChangeHotel}
                                                                    options={hotelOptions}
                                                                    formatOptionLabel={(e) => (
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                            <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                        </div>
                                                                    )}
                                                                    isDisabled={this.state.modalType == "View"}
                                                                />
                                                                {ImportedURL.SUPERADMIN.includes(account.role) ? <div className="invalid-feedback" style={{ display: this.state.hotelError ? "block" : 'none' }}>Hotel is required</div> : ''}
                                                            </div>
                                                        </div>
                                                        : ''}
                                                    <div className="col-lg-4 col-md-12">
                                                        <label className="form-label">User Categories<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                        <div className="form-group">
                                                            <Select
                                                                isMulti={true}
                                                                value={categorySelect}
                                                                onChange={this.handleChangeMultiSelect}
                                                                options={categoryOption}
                                                            />
                                                            <div className="invalid-feedback" style={{ display: this.state.categoryError ? "block" : 'none' }}>User Categories is required</div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-sm-4 col-md-4">
                                                        <label className="form-label">Duration<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                        <div className="form-group">
                                                            <Select
                                                                value={data.duration && { label: data.duration }}
                                                                name='duration'
                                                                options={durationOption}
                                                                isDisabled={this.state.modalType == "View"}
                                                                onChange={this.handleChange}
                                                            />
                                                            <div className="invalid-feedback" style={{ display: this.state.durationError ? "block" : 'none' }}>Duration  is required</div>
                                                        </div>
                                                    </div> */}
                                                    {/* {data.duration == "Daily" ?
                                                        <>
                                                            <div className='col-sm-4 col-md-4'>
                                                                <div className="form-group">
                                                                    <label className="form-label">Start Time<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                                    <input type="time" pattern="hh:mm" className='form-control' disabled={this.state.modalType == "View"} name="starttime" onChange={this.onChangeTime} value={TimeFormat24(data.starttime)} placeholder={"Start Time"}></input>
                                                                    <div className="invalid-feedback" style={{ display: this.state.starttimeValidateError ? 'block' : 'none' }}>Choose less than end time</div>
                                                                    <div className="invalid-feedback" style={{ display: this.state.starttimeError ? 'block' : 'none' }}>Start Time is required</div>
                                                                </div>
                                                            </div>
                                                            <div className='col-sm-4 col-md-4'>
                                                                <div className="form-group">
                                                                    <label className="form-label">End Time<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                                    <input type="time" className='form-control' required disabled={this.state.modalType == "View"} name="endtime" onChange={this.onChangeTime} value={TimeFormat24(data.endtime)} placeholder={"Start Time"}></input>
                                                                    <div className="invalid-feedback" style={{ display: this.state.endtimeValidateError ? 'block' : 'none' }}>Choose greater than start time</div>
                                                                    <div className="invalid-feedback" style={{ display: this.state.endtimeError ? 'block' : 'none' }}>End Time is required</div>
                                                                </div>
                                                            </div>
                                                        </>
                                                        : ''} */}
                                                    <div className="col-lg-8 col-md-12">
                                                        {itemArray.reverse()}
                                                    </div>
                                                    {this.state.modalType == "Edit" ? <div className="card-footer text-right mandatory mt-4 px-2">
                                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                        <div className="text-right">
                                                            {
                                                                this.state.saving ?
                                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                                    :
                                                                    <button type="button" className="btn commor_save" onClick={this.onSubmit}>
                                                                        <i className="fe fe-save mr-2"></i>Update
                                                                    </button>
                                                            }
                                                            {/* <button type="button" className="btn commor_save" onClick={this.onSubmit}>
                                                                <i className="fe fe-save mr-2"></i>Save
                                                            </button> */}
                                                        </div>
                                                    </div> : <div className="card-footer text-right mandatory mt-4 px-2">
                                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                        <div className="text-right">
                                                            {
                                                                this.state.saving ?
                                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                                    :
                                                                    <button type="button" className="btn commor_save" onClick={this.onSubmit}>
                                                                        <i className="fe fe-save mr-2"></i>Save
                                                                    </button>
                                                            }
                                                            {/* <button type="button" className="btn commor_save" onClick={this.onSubmit}>
                                                                <i className="fe fe-save mr-2"></i>Save
                                                            </button> */}
                                                        </div>
                                                    </div>}

                                                </Row>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="overlay" style={{ display: this.state.editSpi ? (dataSpinner ? 'block' : 'none') : 'none' }}></div>
                    {
                        (this.state.modalType != "Add") && this.state.editSpi && (dataSpinner &&
                            <div className='common_loader'>
                                <img className='loader_img_style_common' src='../../assets/images/load.png' />
                                <Spinner className='spinner_load_common' animation="border" variant="info" >
                                </Spinner>
                            </div>
                        )
                    }
                </div >

            </>
        )
    }
}
const mapStateToProps = state => ({
    TodolistitemState: state.todolistitem,
    AccountState: state.account,
    LandingState: state.landing,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        todolistitemChange: AC_HANDLE_TODO_LIST_ITEM_CHANGE,
        todolistitemChangeUpdate: AC_HANDLE_TODO_LIST_ITEM_UPDATE,
        viewtodolistitemdata: AC_VIEW_TODO_LIST_ITEM,
        handleChange: AC_HANDLE_TODO_LIST_CHANGE,
        emptystate: AC_EMPTY_LIST_TODO_ITEM,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTodoListItem);
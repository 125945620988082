import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Chart from 'react-apexcharts';
import moment from 'moment';
import { Link, Redirect } from 'react-router-dom';
import { AC_GET_DESCRIPTION_WISE_TICKET_BY_ID } from '../../../actions/ticketAction';
import { GenerateUniqueColorCodeArray } from '../../../common/validate';

class SentimentAnalysis extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [],
            redirect: false,
            viewredirect: false,
            id: ''
        }
    }
    handleClick = (id) => {
        window.open('/view-ticket/' + id, '_blank').focus();
    }
    handleClickMore = () => {
        this.setState({ viewredirect: true })
    }

    render() {
        const { TicketState, hotel, AccountState } = this.props;
        if (this.state.viewredirect) {
            return <Redirect to={'/sentimental-viewdescription/' + hotel} />
        }
        const account = AccountState.account;
        const descriptionWiseTicketCount = Object.entries(TicketState.descriptionWiseTickets);
        const descriptionByTicket = TicketState.descriptionByTickets;
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
        }
        var ticketHighligtArray = [];
        for (let index = 0; index < descriptionByTicket.length; index++) {
            var priority = 'Low';
            var highlightRe = '/<span class="highlight" >(.*?)<\/span>/g';
            var highlightHtml = '<span class="highlight" style="background:none;border:0;color:red;font-size:15px;font-weight:700;line-height:1.2;">$1</span>';
            let replaceTitle = descriptionByTicket[index].title;
            var highlightTitle = replaceTitle.replace(highlightRe, '$1');
            if (descriptionByTicket[index].keyword !== '') {
                highlightTitle = highlightTitle.replace(new RegExp('(' + descriptionByTicket[index].keyword + ')', 'gi'), highlightHtml);
            }

            let replaceDesc = descriptionByTicket[index].description;
            var highlightDesc = replaceDesc.replace(highlightRe, '$1');
            if (descriptionByTicket[index].keyword !== '') {
                highlightDesc = highlightDesc.replace(new RegExp('(' + descriptionByTicket[index].keyword + ')', 'gi'), highlightHtml);
            }

            if (descriptionByTicket[index].priority == 1) {
                priority = 'High'
            } else if (descriptionByTicket[index].priority == 2) {
                priority = 'Medium'
            }
            ticketHighligtArray.push(<>
                <div class="col-lg-4 col-md-12">
                    <div class="card sent_card">
                        <div class="card-header">
                            <h3 class="card-title">
                                <p dangerouslySetInnerHTML={{ __html: highlightTitle }}></p>
                            </h3>
                        </div>
                        <div class="card-body">
                            <span class="tag tag-blue mb-3 sent_tickets" onClick={(event) => this.handleClick(descriptionByTicket[index].ticketid)}>{descriptionByTicket[index].ticketid}</span>
                            <p dangerouslySetInnerHTML={{ __html: highlightDesc }}></p>
                            <div class="row">
                                <div class="col-5 py-1">
                                    <strong>Created:</strong>
                                </div>
                                <div class="col-7 py-1">{moment(descriptionByTicket[index].createdAt).tz(tzone).format("MM/DD/YYYY ddd hh:mm A")}</div>
                                <div class="col-5 py-1">
                                    <strong>Creator:</strong>
                                </div>
                                <div class="col-7 py-1">{descriptionByTicket[index].reporter}</div>
                                <div class="col-5 py-1">
                                    <strong>Room No.:</strong>
                                </div>
                                <div class="col-7 py-1">{descriptionByTicket[index].roomno}</div>
                                <div class="col-5 py-1">
                                    <strong>Priority:</strong>
                                </div>
                                <div class="col-7 py-1">{priority}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </>)
        }
        const seriesArray = [];
        const categories = [];
        for (let i = 0; i < descriptionWiseTicketCount.length; i++) {
            categories.push(descriptionWiseTicketCount[i][0])
            seriesArray.push({
                name: descriptionWiseTicketCount[i][0],
                type: "bar",
                data: [
                    {
                        x: descriptionWiseTicketCount[i][0],
                        y: descriptionWiseTicketCount[i][1]
                    },
                ]
            })
        }
        const options = {
            chart: {
                type: 'bar',
                events: {
                    mouseLeave: function (event, chartContext, config) {
                        chartContext.resetSeries()
                    }
                },
                id: "basic-bar",
            },
            xaxis: {
                // categories: categories,
                // position: 'bottom',
                // labels: {
                //     show: false,
                //     formatter: function (val) {
                //         return "";
                //     }
                // },
                labels: {
                    show: false,
                },
            },
            yaxis: {
                axisBorder: {
                    show: true
                },
                axisTicks: {
                    show: true,
                },
                labels: {
                    show: true,
                    formatter: function (val) {
                        return val;
                    }
                }
            },
            colors: GenerateUniqueColorCodeArray(seriesArray.length),
            tooltip: {
                x: {
                    show: false
                }
            }
        }
        return (
            <>
                <div className="card">
                    <div className="Over_all_table_style card-body">
                        <div className="card-header" style={{ display: "flex", justifyContent: "space-between" }}>
                            <h3 className="card-title">Frequently Raised Tickets</h3>
                            {descriptionWiseTicketCount && descriptionWiseTicketCount.length > 0 ? <button type="button" className="btn btn-primary sentimental_seemore" id='Add' onClick={this.handleClickMore}><i className="fa fa-th viewmore_icon" style={{ color: "white !important", padding: "0px 10px 0px 0px" }}></i>View More Details</button> : ''}
                        </div>
                        <table className="table card-table">
                            {/* <tbody>
                                {countData}
                            </tbody> */}
                        </table>
                    </div>
                </div>
                {
                    descriptionWiseTicketCount && descriptionWiseTicketCount.length ?
                        <div className="card ticket_reports">
                            <div className="card-body">
                                <div id="chart">
                                    <Chart
                                        options={options}
                                        series={seriesArray}
                                        type="bar"
                                        height={500}
                                    />
                                </div>
                            </div>
                        </div>

                        :

                        <div className="card no_records">
                            <div className="card-body no_records text-center d-flex align-items-center justify-content-center">
                                <div style={{ maxWidth: "340px" }}>
                                    <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid mb-4 mt-4" style={{ maxWidth: "272px" }} />
                                    <h5 className="mb-2">{hotel ? "No records to display" : "Select a hotel to display records"}</h5>
                                </div>
                            </div>
                        </div>

                }
                {/* {descriptionByTicket.length > 0 ?
                    <div class="row">
                        {ticketHighligtArray}
                    </div>
                    : ""
                } */}

            </>
        )
    }
}

const mapStateToProps = state => ({
    TicketState: state.ticket,
    AccountState: state.account,
})
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        TicketByDescription: AC_GET_DESCRIPTION_WISE_TICKET_BY_ID,
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(SentimentAnalysis);


import React, { Component } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import moment from 'moment';
import { onErrorImage } from '../../common/validate';
import { Redirect } from 'react-router-dom';

class LatestReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewMoreRedirect: false,
        }
    }
    getRatings = (rating) => {
        let data = []
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) data.push(<i className="fa fa-star text-orange" key={i}></i>)
            else data.push(<i className="fa fa-star" key={i}></i>)
        }
        return data;
    }
    Viewmore = e => {
        this.setState({ viewMoreRedirect: true })
    }
    render() {
        if (this.state.viewMoreRedirect) {
            return <Redirect to={'social-reviews'} />
        }
        const { viewRedirectClick, state, AccountState } = this.props;
        const { feedbacks } = state;
        const account = AccountState.account;
        var dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY');
        return (
            <>
                <div className="card">
                    <div className="card-header tit_res">
                        <div className='d-flex'>
                            <div className='rounded_icon'><i className="icon-star mr-2 "></i></div>
                            <div className='d-flex align-items-center'>
                                <h3 className="card-title">Latest 10 Guest Reviews</h3>
                            </div>
                        </div>
                        <div className="card-options">
                            <form>
                                {feedbacks.length > 0 ?
                                    <div className="vertical-center">
                                        <button type="button" className="btn " onClick={this.Viewmore}><i className="fa fa-th viewmore_icon" style={{ color: "white !important", padding: "0px 10px 0px 0px" }}></i>View More</button>
                                    </div>
                                    : ''
                                }
                            </form>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="Over_all_table_style table-responsive">
                            <table className="table table-striped  table-vcenter text-nowrap mb-0">
                                <thead className='dashboard_top_reviews'>
                                    <tr>
                                        <th>profile</th>
                                        <th>GUEST NAME</th>
                                        <th>HOTEL NAME</th>
                                        <th>Ratings</th>
                                        <th>Posted on </th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {feedbacks.length > 0 ?
                                        feedbacks.map((data, i) => {
                                            var hotelName = data.hotel ? data.hotel.name + ' ' + data.hotel.city + ' ' + data.hotel.state : '';
                                            var supplierValue = ""
                                            if (data.supplier == "hotels" || data.supplier == "booking") {
                                                supplierValue = data.supplier + ".com"
                                            } else if (data.supplier == "trip-advisor") {
                                                supplierValue = "tripadvisor"
                                            } else {
                                                supplierValue = data.supplier
                                            }
                                            var imageSet = ''
                                            if (data.supplier == "hotels") {
                                                imageSet = '../../assets/images/hotels.png'
                                            } else if (data.supplier == "trip-advisor") {
                                                imageSet = '../../assets/images/tripadviser.png'
                                            } else if (data.supplier == "booking") {
                                                imageSet = '../../assets/images/booking.png'
                                            } else if (data.supplier == "google") {
                                                imageSet = '../../assets/images/google.webp'
                                            } else if (data.supplier == "facebook") {
                                                imageSet = '../../assets/images/fb.svg'
                                            } else if (data.supplier == "yelp") {
                                                imageSet = '../../assets/images/yelp.png'
                                            } else if (data.supplier == "expedia") {
                                                imageSet = '../../assets/images/ex.png'
                                            }
                                            return <tr>
                                                <td>
                                                    <img className="avatar avatar-xl" src={data.reviewer_picture_url ?
                                                        data.reviewer_picture_url : 'assets/images/defaultuser.png'}
                                                        onError={(e) => { if (e.target.src !== "assets/images/defaultuser.png") { e.target.onerror = null; e.target.src = "assets/images/defaultuser.png"; } }
                                                        }
                                                        alt="" />
                                                </td>
                                                <td>{data.reviewer_name}</td>
                                                <td><p style={{ whitePpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '200px' }}>{hotelName}</p></td>
                                                <td>{this.getRatings(data.rating)}</td>
                                                <td>
                                                    <div style={{ display: 'inline-grid', position: "relative" }}  >
                                                        <span className='view_logo' >
                                                            <a href={data.url} target='_blank'>
                                                                <img src={imageSet} onError={onErrorImage} />
                                                            </a>
                                                        </span>
                                                        <span style={{ paddingLeft: "40px" }}> <a href={data.url} style={{ color: '#1c3078' }} target='_blank'>{supplierValue.charAt(0).toUpperCase() + supplierValue.substr(1).toLowerCase()}</a></span>
                                                        <p className="float-right" style={{ paddingLeft: "40px" }}>{moment(data.published_at).format(dFormat)}</p>
                                                    </div>
                                                </td>
                                                <td>
                                                    <button type="button" className="btn btn-icon js-sweetalert hover-text" data-type="confirm" data-bs-toggle="tooltip">
                                                        <i className="fa fa-eye" onClick={(e) => viewRedirectClick(data._id)} data-toggle="modal" data-target="#latest_view_reviews"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        }) :
                                        <tr style={{ textAlign: 'center' }}>
                                            <h3 className="card-title" style={{ position: 'relative', left: '430px', top: '10px', margin: '50px 0px' }}>No Reviews Found</h3>
                                        </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default LatestReview;

const initialState = {
    listticketworkflows: [],
    ticketworkflow: {
        type: [],
        individualcategory: '',
        ticketuser: [],
        status: '',
        custom: '',
        multiplecategory: [],
        id: '',
    },
    tabposition: 'create',
    spinner: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_TICKET_WORK_FLOW_CHANGE':
            return Object.assign({}, state, {
                ticketworkflow: {
                    ...state.ticketworkflow,
                    [action.name]: action.value
                }
            })
        case 'HANDLE_TAB_CHANGE_TICKET_WORKFLOLW':
            return Object.assign({}, state, {
                tabposition: action.value
            })
        case 'LIST_TICKET_WORK_FLOW':
            return Object.assign({}, state, {
                listticketworkflows: action.payload,
                total: action.total,

            })
        case 'VIEW_TICKET_WORK_FLOW':
            return Object.assign({}, state, {
                ticketworkflow: action.payload,
                spinner: false,
            })
        case 'EMPTY_TICKET_WORK_FLOW':
            return Object.assign({}, state, {
                ticketworkflow: {
                    type: [],
                    individualcategory: '',
                    ticketuser: [],
                    status: '',
                    multiplecategory: [],
                    id: '',
                },
                spinner: true,
            })
            case 'RESET_TICKET_WORK_FLOW':
            return Object.assign({}, state, {
                spinner: false,
            })


        default:
            return state;
    }
}
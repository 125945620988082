import React, { Component } from 'react'
import axios from 'axios';
import { connect } from 'react-redux';
import ImportedUrl from '../../common/api';
import { bindActionCreators } from 'redux';
import ImportedURL from '../../common/api';
import { Success, Error } from '../../common/swal';
import { AC_HANDLE_INPUT_CHANGE, AC_ADMIN_UPDATE } from '../../actions/accountAction';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import Select from 'react-select';
import { Form } from 'react-bootstrap';
import Sitesettings from './sitesettings';
import { AC_LIST_USER } from '../../actions/userAction';
import { Imagevalidation } from '../../common/validate';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';

const initialState = {
    tabposition: 'tab1',
    ticket: {},
    mom: {},
    todo: {},
    reminder: {},
}
class AdminSettings extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    onChangeTicket = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({ ticket: { ...this.state.ticket, [name]: value } })
    }

    onChangeMOM = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({ mom: { ...this.state.mom, [name]: value } })
    }

    onChangeTodo = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({ todo: { ...this.state.todo, [name]: value } })
    }
    onChangeReminder = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        this.setState({ reminder: { ...this.state.reminder, [name]: value } })
    }
    componentDidMount() {
        axios.get(ImportedUrl.API.getPushmessage)
            .then((res) => {
                if (res.data) {
                    this.setState({ ticket: res.data.ticket, mom: res.data.mom, todo: res.data.todo, reminder: res.data.reminder })
                }
            })
    }
    submit = () => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        this.setState({ saving: true })

        let formData = {
            ticket: this.state.ticket ? this.state.ticket : {},
            mom: this.state.mom ? this.state.mom : {},
            todo: this.state.todo ? this.state.todo : {},
            reminder: this.state.reminder ? this.state.reminder : {},
        }
        axios.post(ImportedUrl.API.updatePushmessage, formData)
            .then((res) => {
                this.setState({ saving: false })
                Success(languageData && languageData.MESSAGE ? languageData.MESSAGE : 'Message' + languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : "updated successfully");
            }).catch(({ response }) => {
                if (response) {
                    this.setState({ saving: false })
                    if (response.status == 400) {
                        Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                    }
                }
            });
    }
    render() {
        // const { AccountState, CountryState, UserState } = this.props;
        let tabposition = this.state.tabposition;
        let ticket = this.state.ticket ? this.state.ticket : {};
        let mom = this.state.mom ? this.state.mom : {};
        let todo = this.state.todo ? this.state.todo : {};
        let reminder = this.state.reminder ? this.state.reminder : {};
        const languageData = this.props.AccountState.account.SelectedlanguageData
        return (
            <>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/push-message"><h6 className="highlights_breadcrump">Push Message</h6></Link></p>
                </div>
                <div className="section-body pt-3">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='rounded_icon'><i className="fa fa-envelope mr-2 "></i></div>
                                        <h3 className="card-title">Push Message</h3>
                                    </div>
                                    <div className='card-body dashboard_mom'>
                                        <div className='upcommcomp_btn'>
                                            <div className='mr-2' style={{ position: 'relative' }}>
                                                <button type='button' className={`${tabposition == 'tab1' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={() => this.setState({ tabposition: 'tab1' })} style={{ background: tabposition == 'upcoming' ? 'after_click' : 'before_click' }}>Ticket</button>
                                            </div>
                                            {/* <div className='mr-2' style={{ position: 'relative' }}>
                                                <button type='button' className={`${tabposition == 'tab2' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={() => this.setState({ tabposition: 'tab2' })}>MoM</button>
                                            </div> */}
                                            <div className='mr-2' style={{ position: 'relative' }}>
                                                <button type='button' className={`${tabposition == 'tab3' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={() => this.setState({ tabposition: 'tab3' })}>To-do</button>
                                            </div>
                                            <div className='mr-2' style={{ position: 'relative' }}>
                                                <button type='button' className={`${tabposition == 'tab4' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={() => this.setState({ tabposition: 'tab4' })}>Reminder</button>
                                            </div>
                                        </div>
                                    </div>
                                    {tabposition == 'tab1' ? <div className="card-body">
                                        <form autoComplete='off'>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Create
                                                            {/* <span className="ml-1" style={{ color: 'red' }}>*</span> */}
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'create'} onChange={this.onChangeTicket} value={ticket.create} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">In-progress
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'inprogress'} onChange={this.onChangeTicket} value={ticket.inprogress} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Assigned
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'assigned'} onChange={this.onChangeTicket} value={ticket.assigned} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">In-review
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'inreview'} onChange={this.onChangeTicket} value={ticket.inreview} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Completed
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'completed'} onChange={this.onChangeTicket} value={ticket.completed} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Reopened
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'reopened'} onChange={this.onChangeTicket} value={ticket.reopened} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Reassigned
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'reassigned'} onChange={this.onChangeTicket} value={ticket.reassigned} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Accepted
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'accepted'} onChange={this.onChangeTicket} value={ticket.accepted} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Rejected
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'rejected'} onChange={this.onChangeTicket} value={ticket.rejected} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Edited
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'update'} onChange={this.onChangeTicket} value={ticket.update} />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div> : ''}
                                    {tabposition == 'tab2' ? <div className="card-body">
                                        <form autoComplete='off'>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Create
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'create'} onChange={this.onChangeMOM} value={mom.create} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Inprogress
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'inprogress'} onChange={this.onChangeMOM} value={mom.inprogress} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Sent
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'sent'} onChange={this.onChangeMOM} value={mom.sent} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Received
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'receive'} onChange={this.onChangeMOM} value={mom.receive} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Waiting for comments
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'waitingforcomment'} onChange={this.onChangeMOM} value={mom.waitingforcomment} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Updated
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'update'} onChange={this.onChangeMOM} value={mom.update} />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div> : ''}
                                    {tabposition == 'tab3' ? <div className="card-body">
                                        <form autoComplete='off'>
                                            <div className="row">
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Create
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'create'} onChange={this.onChangeTodo} value={todo.create} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Inprogress
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'inprogress'} onChange={this.onChangeTodo} value={todo.inprogress} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Sent
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'sent'} onChange={this.onChangeTodo} value={todo.sent} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Received
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'receive'} onChange={this.onChangeTodo} value={todo.receive} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Waiting for comments
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'waitingforcomment'} onChange={this.onChangeTodo} value={todo.waitingforcomment} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Updated
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'update'} onChange={this.onChangeTodo} value={todo.update} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Pending
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'pending'} onChange={this.onChangeTodo} value={todo.pending} />
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div> : ''}
                                    {tabposition == 'tab4' ? <div className="card-body">
                                        <form autoComplete='off'>
                                            <div className="row">
                                                {/* <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Create
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'create'} onChange={this.onChangeReminder} value={reminder.create} />
                                                    </div>
                                                </div> */}
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Open ticket
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'openticket'} onChange={this.onChangeReminder} value={reminder.openticket} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">High priority
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'highpriority'} onChange={this.onChangeReminder} value={reminder.highpriority} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Medium priority
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'mediumpriority'} onChange={this.onChangeReminder} value={reminder.mediumpriority} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Low priority
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'lowpriority'} onChange={this.onChangeReminder} value={reminder.lowpriority} />
                                                    </div>
                                                </div>
                                                {/*<div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Reopened
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'reopened'} onChange={this.onChangeReminder} value={reminder.reopened} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Reassigned
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'reassigned'} onChange={this.onChangeReminder} value={reminder.reassigned} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Accepted
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'accepted'} onChange={this.onChangeReminder} value={reminder.accepted} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Rejected
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'rejected'} onChange={this.onChangeReminder} value={reminder.rejected} />
                                                    </div>
                                                </div>
                                                <div className="col-sm-12 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Edited
                                                        </label>
                                                        <input type='text' className="form-control" placeholder="Message" name={'update'} onChange={this.onChangeReminder} value={reminder.update} />
                                                    </div>
                                                </div> */}
                                            </div>
                                        </form>
                                    </div> : ''}
                                    < div className="card-footer text-right">
                                        {
                                            this.state.saving ?
                                                <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                :
                                                <button type="submit" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    AccountState: state.account,
    UserState: state.user,
    CountryState: state.country,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ListUser: AC_LIST_USER, ListCountry: AC_LIST_COUNTRY, HandleInputChange: AC_HANDLE_INPUT_CHANGE, AC_ADMIN_UPDATE }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminSettings);
import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_TAB_CHANGE_TICKET_WORKFLOLW(value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_TAB_CHANGE_TICKET_WORKFLOLW", value: value })
    };
}

export function AC_HANDLE_TICKET_WORK_FLOW_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_TICKET_WORK_FLOW_CHANGE", name: name, value: value })
    };
}
export function AC_LIST_TICKET_WORK_FLOW(data = {}) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.listTicketWorkFlow, data)
            .then((res) => {
                if (res.data)
                    dispatch({ type: "LIST_TICKET_WORK_FLOW", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}

export function AC_EMPTY_TICKET_WORK_FLOW() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_TICKET_WORK_FLOW" })
    };
}
export function AC_RESET_TICKET_WORK_FLOW() {
    return function (dispatch) {
        dispatch({ type: "RESET_TICKET_WORK_FLOW" })
    };
}

export function AC_VIEW_TICKET_WORK_FLOW(params = {}) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewTicketWorkFlow, { params: params })
            .then((res) => {
                dispatch({ type: 'VIEW_TICKET_WORK_FLOW', payload: res.data });
            })
    }
}
import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';
import { ReplaceArrayAtIndex } from '../common/validate';

export function AC_HANDLE_HOTEL_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_HOTEL_CHANGE", name: name, value: value })
	};
}

export function AC_HANDLE_HOTEL_CHANGE_MULTIPLE(name, value, i) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_HOTEL_CHANGE_MULTIPLE", name: name, value: value, i: i })
	};
}
export function AC_HANDLE_HOTEL_MULTIPLE_UPDATE(name, value, id) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_HOTEL_MULTIPLE_UPDATE", name: name, value: value, id: id })
	};
}

export function AC_HANDLE_HOTEL_ELFSIGHTS_CHANGE(name, value, i) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_HOTEL_ELFSIGHTS_CHANGE", name: name, value: value, i: i })
	};
}
export function AC_HANDLE_HOTEL_SOCAILMEDIA_CHANGE(name, value, i) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_HOTEL_SOCAILMEDIA_CHANGE", name: name, value: value, i: i })
	};
}
export function AC_HANDLE_HOTEL_ELFSIGHTS_MULTIPLE_UPDATE(name, value, id) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_HOTEL_ELFSIGHTS_MULTIPLE_UPDATE", name: name, value: value, id: id })
	};
}

export function AC_RESET_HOTEL() {
	return function (dispatch) {
		dispatch({ type: "RESET_HOTEL" })
	};
}

export function AC_ADD_HOTEL(formData) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.addHotel, formData)
			.then((res) => {
				Success('Hotel created successfully');
				// Success(res.statusText);
				// dispatch({ type: "LIST_HOTEL", payload: res.data })
				dispatch({ type: "RESET_HOTEL" })
			}).catch(({ response }) => {
				if (response.status == 409) {
					Error('Email already exist')
				} else if (response.status == 400) {
					Error('Bad request')
				}
			});
	};
}
export function AC_LIST_HOTEL(data = {}) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.listHotel, data)
			.then((res) => {
				if (res.data) {
					let list = ReplaceArrayAtIndex(res.data.allData, 0);
					dispatch({ type: "PUSH_DATA_LANDING", payload: list })
					dispatch({ type: "LIST_HOTEL", payload: res.data.data, total: res.data.total, result: res.data, spinner: false })
				}
			}).catch((err) => { console.log(err); });
	};
}
export function AC_VIEW_HOTEL(id) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewHotel + "/" + id)
			.then((res) => {
				dispatch({ type: "VIEW_HOTEL", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_UPDATE_HOTEL(formData, id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.updateHotel + "/" + id, formData)
			.then((res) => {
				// Success(res.statusText);
				Success('Hotel updated successfully');
				// dispatch({ type: "LIST_HOTEL", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_DELETE_HOTEL(id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.deleteHotel + "/" + id)
			.then((res) => {
				Success(res.statusText);
				dispatch({ type: "LIST_HOTEL", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}


export function AC_NEW_LIST_HOTEL(list) {
	return function (dispatch) {
		dispatch({ type: "NEW_LIST_HOTEL", payload: list })
	};
}

export function AC_EMPTY_HOTEL() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_HOTEL" })
	};
}

export function AC_EMPTY_HOTEL_LIST() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_HOTEL_LIST" })
	};
}

export function AC_LIST_USER_OWNER() {
	return function (dispatch) {
		return axios.post(ImportedURL.API.listUserOwner)
			.then((res) => {
				if (res.data)
					dispatch({ type: "LIST_USER_OWNER", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

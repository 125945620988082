import React, { Component } from 'react'
import SpinnerComponent from '../../../utilities/Spinner/spinner';
import moment from 'moment';
import { FaRegEye } from "react-icons/fa";
import { FiEdit } from "react-icons/fi";

class GridList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeView: 'list',
        }
    }
    onChangeAction = (id, type, start) => {
        this.props.onChangeAction(id, type, start);
    }
    render() {
        const { rowData, spinner, AccountState, calendarType } = this.props;
        const today = (rowData && rowData.length > 0) ? rowData.find((e) => e.date == "today") : {};
        const tomorrow = (rowData && rowData.length > 0) ? rowData.find((e) => e.date == "tomorrow") : {};
        const upcoming = (rowData && rowData.length > 0) ? rowData.find((e) => e.date == "upcoming") : {};
        const account = AccountState.account;
        const dFormat = (account.dateformate != '' ? account.dateformate : 'MM-DD-YYYY');
        return (
            <>
                <div className="row clearfix d-flex justify-content-between view_list">
                    <div className={calendarType == 'days' ? "col-lg-12 col-md-12" : "col-lg-4 col-md-12"}>
                        <div className="Over_all_table_style card">
                            <div className="card-header">
                                <h3 className="card-title">Today</h3>
                            </div>
                            {
                                (today && today.data && today.data.length > 0)
                                    ?
                                    <>
                                        {
                                            today.data.map((item, i) => {
                                                return (
                                                    <div className="card-body" key={i}>
                                                        <div className="dropdown float-end">
                                                            <a href="#" className="dropdown-toggle arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <i className="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                                                            </a>
                                                            <div className="dropdown-menu dropdown-menu-end">
                                                                {item.iseditable ? <a className="dropdown-item edittask-details" onClick={() => this.onChangeAction(item.id, 'Edit')} ><FiEdit className="todo_icon_edit" />Edit</a> : ""}
                                                                <a className="dropdown-item edittask-details" data-toggle="modal" data-target="#exampleTodoModal" onClick={() => this.onChangeAction(item.id, 'View', item.start)}><FaRegEye className="todo_icon" />View</a>
                                                            </div>
                                                        </div>
                                                        <div>
                                                            <h6 className="font-size-5">{item.title ? item.title : '---'}</h6>
                                                            <p className="text-muted mb-2">{item.start ? moment(item.start).format(dFormat) : '---'}</p>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </> :
                                    <>
                                        <div className="card-body no_recor_to_show">
                                            <h5>No record to display</h5>
                                        </div>
                                    </>
                            }
                        </div>
                    </div>
                    {
                        calendarType != 'days' ?
                            <>
                                <div className="col-lg-4 col-md-12">
                                    <div className="Over_all_table_style card">
                                        <div className="card-header">
                                            <h3 className="card-title">Tomorrow</h3>
                                        </div>
                                        {
                                            (tomorrow && tomorrow.data && tomorrow.data.length > 0)
                                                ?
                                                <>
                                                    {
                                                        tomorrow.data.map((item, i) => {
                                                            return (
                                                                <div className="card-body" key={i}>
                                                                    <div className="dropdown float-end">
                                                                        <a href="#" className="dropdown-toggle arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                                                                        </a>
                                                                        <div className="dropdown-menu dropdown-menu-end">
                                                                            {item.iseditable ? <a className="dropdown-item edittask-details" onClick={() => this.onChangeAction(item.id, 'Edit')} ><FiEdit className="todo_icon_edit" />Edit</a> : ""}
                                                                            <a className="dropdown-item edittask-details" data-toggle="modal" data-target="#exampleTodoModal" onClick={() => this.onChangeAction(item.id, 'View', item.start)}><FaRegEye className="todo_icon" />View</a>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <h6 className="font-size-5">{item.title ? item.title : '---'}</h6>
                                                                        <p className="text-muted mb-2">{item.start ? moment(item.start).format(dFormat) : '---'}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </> :
                                                <>
                                                    <div className="card-body no_recor_to_show">
                                                        <h5>No record to display</h5>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12">
                                    <div className="Over_all_table_style card">
                                        <div className="card-header">
                                            <h3 className="card-title">Upcoming</h3>
                                        </div>
                                        {
                                            (upcoming && upcoming.data && upcoming.data.length > 0)
                                                ?
                                                <>
                                                    {
                                                        upcoming.data.map((item, i) => {
                                                            return (
                                                                <div className="card-body" key={i}>
                                                                    <div className="dropdown float-end">
                                                                        <a href="#" className="dropdown-toggle arrow-none" data-bs-toggle="dropdown" aria-expanded="false">
                                                                            <i className="mdi mdi-dots-vertical m-0 text-muted h5"></i>
                                                                        </a>
                                                                        <div className="dropdown-menu dropdown-menu-end">
                                                                            {item.iseditable ? <a className="dropdown-item edittask-details" onClick={() => this.onChangeAction(item.id, 'Edit')} ><FiEdit className="todo_icon_edit" />Edit</a> : ""}
                                                                            <a className="dropdown-item edittask-details" data-toggle="modal" data-target="#exampleTodoModal" onClick={() => this.onChangeAction(item.id, 'View', item.start)}><FaRegEye className="todo_icon" />View</a>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <h6 className="font-size-5">{item.title ? item.title : '---'}</h6>
                                                                        <p className="text-muted mb-2">{item.start ? moment(item.start).format(dFormat) : '---'}</p>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </> :
                                                <>
                                                    <div className="card-body no_recor_to_show">
                                                        <h5>No record to display</h5>
                                                    </div>
                                                </>
                                        }
                                    </div>
                                </div>
                            </> : ''
                    }
                </div >
                <SpinnerComponent spinner={spinner} />
            </>
        )
    }
}

export default GridList;

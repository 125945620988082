import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_VIEW_HOTEL } from '../../actions/hotelAction';
import axios from 'axios';
import ImportedURL from '../../common/api';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';
import { Container, Row } from 'react-bootstrap';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import { onErrorImage } from '../../common/validate';

const initialState = {
    hotelData: '',
    loader: false,
    employeeData: ''
}
class ViewEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    async componentDidMount() {
        this.props.ListCountry();
        let id = this.props.match.params.id;
        await axios.get(ImportedURL.API.viewHotelData + '/' + id)
            .then((res) => {
                this.setState({ hotelData: res.data });
                this.setState({ loader: true })
            });
        //default scroll top the page
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        await axios.get(ImportedURL.API.viewHotelDynamicCatgory + '/' + id)
            .then((data) => {
                this.setState({ employeeData: data.data })
            })
    }
    render() {
        const { AccountState, CountryState } = this.props;
        const phoneCode = CountryState.listcountry;
        const account = AccountState.account;
        const previleges = AccountState.previleges;
        const hotelPrevilege = previleges.find(obj => { return obj.name == "Hotel" });
        var hotelId = this.props.match.params.id;
        var employeeListData = this.state.employeeData;
        const languageData = AccountState.account.SelectedlanguageData
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-employee"><h6>  Employee List</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/view-employee/:id"><h6 className="highlights_breadcrump">View Employee</h6></Link></p>
                </div>
                <div className="section-body  view_hotel_sec mt-3 ">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12 col-md-12">
                                <div className="card">
                                    <div className="card-body border-0">
                                        <div className='title_mt'>
                                            <div className='d-flex align-items-center'>
                                                <div className='rounded_icon'><i className="fa fa-users mr-2"></i></div>
                                                <div className='d-flex align-items-center'>
                                                    <h3 className="card-title ">View Employee</h3>
                                                </div>
                                            </div>
                                            <div className="header-action" style={{ marginTop: '5px', marginLeft: 'auto' }}>
                                                <Link to={'/list-employee'}><button type="button" className="btn btn-primary d-flex  align-items-center mr-4 mt-4 mb-3" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                                {/* <button type='button' className='btn  commor_save  mr-3' onClick={this.printFun}><i className="icon-printer mr-2" aria-hidden="true"></i>Print</button> */}
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <div className='card-title d-flex ml-4 mt-4 mb-3' >
                                        </div>
                                    </div>
                                    <div className='add_dynaminc_category'>
                                        {/* <div className='top_bott_border'> */}
                                        <div className='img_text_top px-4 my-4 mx-4 ' style={{ backgroundColor: '#d7eaff ' }}>
                                            <div className='h_logo'>
                                                <img src={ImportedURL.FILEURL + this.state.hotelData.logo} onError={onErrorImage} />
                                            </div>
                                            <div className='barnd_name'>
                                                <h5>MyHotel AI</h5>
                                                <span>property maintenance system</span>
                                            </div>
                                            <div className='b_logo'>
                                                <img src='../assets/images/reviewslogo.png' />
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                    <div className='Over_all_table_style list_of_category'>
                                        {employeeListData.length > 0 ?
                                            <table className="table table-striped mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>category</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Phone Number</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {(employeeListData.length > 0 && employeeListData) &&
                                                        employeeListData.map((listData) => {
                                                            var phonecode = '';
                                                            {
                                                                phoneCode && phoneCode.map(item => {
                                                                    if (item.code == listData.cc) {
                                                                        phonecode = item.phonecode
                                                                    }
                                                                })
                                                            }
                                                            return (
                                                                <tr>
                                                                    <td>{listData.categoryname}</td>
                                                                    <td>{listData.name ? listData.name : '---'}</td>
                                                                    <td>{listData.email ? listData.email : '---'}</td>
                                                                    <td>{listData.phonenumber ? phonecode + ' ' + listData.phonenumber : '---'}</td>
                                                                </tr>
                                                            )
                                                        })}
                                                </tbody>
                                            </table>
                                            :
                                            <>
                                                <div className="card" style={{ height: '455px' }}>
                                                    {/* <div className="card-header">
                                                        <div className='rounded_icon'><i className="fa fa-ticket mr-2 mr-2 "></i></div>
                                                        <h3 className="card-title">Ticket Priority Count</h3></div> */}
                                                    <div className="">
                                                        <div className="card-body no_records text-center d-flex align-items-center justify-content-center pt-0">
                                                            <div style={{ maxWidth: "340px" }}>
                                                                <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid" style={{ maxWidth: "272px" }} />
                                                                <h5 className="mb-2">No records to display </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    HotelState: state.hotel,
    AccountState: state.account,
    GroupState: state.group,
    CountryState: state.country,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ViewHotel: AC_VIEW_HOTEL, ListCountry: AC_LIST_COUNTRY }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewEmployee);

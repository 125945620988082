
const initialState = {
	group: {
		name: '',
		phonenumber: '',
		cc: '',
	},
	listgroups: [],
	spinner: true,
	total: 0,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'HANDLE_GROUP_CHANGE':
			return Object.assign({}, state, {
				group: {
					...state.group,
					[action.name]: action.value
				}
			})
		case 'RESET_GROUP':
			return Object.assign({}, state, {
				group: initialState.group
			})
		case 'LIST_GROUP':
			return Object.assign({}, state, {
				listgroups: action.payload,
				total: action.total,
				spinner: false
			})
		case 'VIEW_GROUP':
			return Object.assign({}, state, {
				group: action.payload
			})
		case 'EMPTY_GROUP':
			return Object.assign({}, state, {
				spinner: initialState.spinner
			})
		default:
			return state;
	}
}
import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_ROOMAREA_AMENITY_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_ROOMAREA_AMENITY_CHANGE", name: name, value: value })
	};
}

export function AC_RESET_ROOMAREA_AMENITY() {
	return function (dispatch) {
		dispatch({ type: "RESET_ROOMAREA_AMENITY" })
	};
}


export function AC_LIST_ROOMAREA_AMENITY(params = {}) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.listRoomareaAmenity, params)
			.then((res) => {
				dispatch({ type: "LIST_ROOMAREA_AMENITY", payload: res.data.data, total: res.data.total, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_LIST_SETTING_ROOMAREA_AMENITY(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listSettingRoomAmenity, { params: params })
			.then((res) => {
				dispatch({ type: "LIST_SETTING_ROOMAREA_AMENITY", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_VIEW_ROOMAREA_AMENITY(data) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewRoomareaAmenity + "/" + data)
			.then((res) => {
				dispatch({ type: "VIEW_ROOMAREA_AMENITY", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_UPDATE_ROOMAREA_AMENITY(formData, id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.updateRoomareaAmenity + "/" + id, formData)
			.then((res) => {
				Success(res.statusText);
				dispatch({ type: "LIST_ROOMAREA_AMENITY", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_EMPTY_ROOMAREA_AMENITY() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_ROOMAREA_AMENITY" })
	}
}

// / --------------------settingroom--------

export function AC_HANDLE_KEY_SEARCH(value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_KEY_SEARCH", value: value })
	};
}


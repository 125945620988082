import React, { Component } from 'react'
import axios from 'axios';
import ImportedURL from '../../common/api';
import moment from 'moment';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';

class viewFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = {
            spinner: true,
            hotelName: '',
            feedbackData: {}
        }
    }
    componentDidMount() {
        this.setState({ spinner: true })
        const account = this.props.AccountState.account;
        this.setState({ hotelName: account.hotelName ? account.hotelName : '' });
        let id = this.props.match.params.id;
        axios.get(ImportedURL.API.viewFeedback + '/' + id)
            .then((res) => {
                this.setState({ feedbackData: res.data, spinner: false });
            }).catch((err) => {
                this.setState({ spinner: false });
            });
    }
    
    componentDidUpdate(prevProps) {
        const currentTicketId = this.props.match.params.id;
        const prevTicketId = prevProps.match.params.id;
 
        // Fetch new data if the id has changed
        if (currentTicketId !== prevTicketId) {
            this.fetchData(currentTicketId);
        }
    }
    fetchData(id) {
        this.setState({ spinner: true })
        const account = this.props.AccountState.account;
        this.setState({ hotelName: account.hotelName ? account.hotelName : '' });
        axios.get(ImportedURL.API.viewFeedback + '/' + id)
            .then((res) => {
                this.setState({ feedbackData: res.data, spinner: false });
            }).catch((err) => {
                this.setState({ spinner: false });
            });
    }
    render() {
        const { AccountState } = this.props;
        const { feedbackData } = this.state
        const account = this.props.AccountState.account;
        const languageData = AccountState.account.SelectedlanguageData
        var dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + "  " + (account.timeformat != '' ? account.timeformat : "hh:mm A")
        let ratingArr = []
        if (feedbackData && feedbackData.rating != undefined) {
            for (let i = 1; i <= 3; i++) {
                if (i <= feedbackData.rating) ratingArr.push(<i className="fa fa-star text-orange  mr-1" key={i}></i>)
                else ratingArr.push(<i className="fa fa-star mr-1" key={i}></i>)
            }
        }
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link> <span><i class="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>View Feedback </h6></p>
                </div>
                <div className="section-body pt-3 view_reservation">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <div className="card">
                                <div className='card-header'>
                                    <div className='card-title d-flex'>
                                        <div className='rounded_icon'><i className="fa fa-users mr-2"></i></div>
                                        <h3 className="card-title " style={{ marginTop: '10px' }}>VIEW FEEDBACK</h3>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className='reserve_details' style={{ padding: '10px' }}>
                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <label className='label_black'>Name</label>
                                                <p className='table_text_right p-0'>
                                                    {feedbackData.name ? feedbackData.name : '---'}
                                                </p>
                                            </div>
                                            <div className='col-lg-4'>
                                                <label className='label_black'>Email</label>
                                                <p>{feedbackData.email ? feedbackData.email : '---'}</p>
                                            </div>
                                            <div className='col-lg-4'>
                                                <label className='label_black'>Phone Number</label>
                                                <p>{feedbackData.phone ? feedbackData.phone : '---'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='reserve_details' style={{ padding: '10px' }}>
                                        <div className='row'>
                                            <div className='col-lg-4'>
                                                <label className='label_black'>Room Number</label>
                                                <p>{feedbackData.roomno ? feedbackData.roomno : '---'}</p>
                                            </div>
                                            <div className='col-lg-4'>
                                                <label className='label_black'>Rating</label>
                                                <p>{(ratingArr && ratingArr.length > 0) ? ratingArr : (<><i className="fa fa-star mr-1" /><i className="fa fa-star mr-1" /><i className="fa fa-star mr-1" /></>)}</p>
                                            </div>
                                            <div className='col-lg-4'>
                                                <label className='label_black'>Received At</label>
                                                <p>{feedbackData.createdAt ? moment(feedbackData.createdAt).format(dtFormat) : '---'}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='reserve_details' style={{ padding: '10px' }}>
                                        <div className='row'>
                                            <div className='col-lg-12'>
                                                <label className='label_black'>Feedback</label>
                                                <p>{feedbackData.feedback ? feedbackData.feedback : '---'}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div>
                {
                    this.state.spinner &&
                    <div className='common_loader'>
                        <img className='loader_img_style_common' src='../../assets/images/load.png' />
                        <Spinner className='spinner_load_common' animation="border" variant="info" >
                        </Spinner>
                    </div>
                }
            </div>
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(viewFeedback);

import axios from 'axios';
import { AgGridReact } from 'ag-grid-react';
import { Row, Spinner } from 'react-bootstrap';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import { AC_LIST_TODO_LIST_ITEM, AC_VIEW_TODO_LIST_ITEM, AC_EMPTY_LIST_TODO_ITEM } from '../../actions/todolistAction';
import jsPDF from "jspdf";
import Swal from 'sweetalert2';
import Select from 'react-select';
import ImportedURL from '../../common/api';
import { Success, Error } from '../../common/swal';
import { Textareavalidation, TimeFormat, imageCellRendererList, onErrorImage } from '../../common/validate';
import showSwalDialog from '../../utilities/Modal/swal';
import ErrorResponse from '../../utilities/Error/errorstatus';


const initialState = {
    redirect: false,
    columnDefs: [
        {
            headerName: 'Logo', field: 'logo', hide: true, sortable: false, filter: false, "resizable": false, width: 100, cellStyle: { 'text-align': 'center' },
            cellRenderer: imageCellRendererList,
        },
        { headerName: 'Hotel', field: 'hotelName', hide: true, width: 200, cellStyle: { 'text-align': 'left' } },
        {
            headerName: 'User Category', field: 'categoryName', width: 200, cellStyle: { 'text-align': 'left' },

        },
        { headerName: 'Item', field: 'itemvalue', width: 200 },
        // {
        //     headerName: 'Status', field: 'status', width: 170, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true,
        //     cellRenderer: function (params) {
        //         if (params.data && params.data.status) {
        //             return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
        //         } else {
        //             return '<span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span>';
        //         }
        //     }
        // },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
        searchhotel: '',
        searchhoteloption: '',
        hotelName: '',
    },
    searchhoteloption: { label: 'All', value: '', name: 'searchhotel' },
    editSpi: false,
    perPage: 25,
    columnreset: true,
    todoitemdata: {}
}
class ListtodoListItem extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    componentDidMount() {
        const account = this.props.AccountState.account;
        const { params, path } = this.props.match;
        this.setState({ hotelName: account.hotelName ? account.hotelName : '' });
        this.props.emptytodolist();
        this.props.listtodolistitem();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };


    exportToCSV = () => {
        const account = this.props.AccountState.account;
        this.gridApi.exportDataAsCsv({
            columnKeys: ImportedURL.SUPERADMIN.includes(account.role) ? ((this.state.hotelName && this.state.hotelName != "All") ? ['categoryName', 'itemvalue'] : ['hotelName', 'categoryName', 'itemvalue']) : ['categoryName', 'itemvalue'],
            fileName: 'checklist' + "_" + (this.state.hotelName && (this.state.hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".csv",
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const account = this.props.AccountState.account;
        const api = this.gridApi;
        const filteredRows = api.getModel().rowsToDisplay
        const exportData = filteredRows.map(rowNode => rowNode.data);
        const selectedProperties = (this.state.hotelName && this.state.hotelName != "All") ? ['categoryName', 'itemvalue'] : ['hotelName', 'categoryName', 'itemvalue'];
        const selected = exportData.map((item, i) => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    selectedData[property] = item[property] ? item[property] : '---';
                }
            });
            return selectedData;
        });
        let data = {
            title: (this.state.hotelName) ? (this.state.hotelName != "All" ? this.state.hotelName + " - Check List" : "Check List") : "Check List",
            head: (this.state.hotelName && this.state.hotelName != "All") ? ['#', 'User Category', 'Item'] : ['#', 'Hotel', 'User Category', 'Item'],
            body: selected,
            hotelNameExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hotelNameExport : '',
            hoteladdressExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "checklist" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }
    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (value === 'View') {
            // this.props.emptytodolist();
            // this.props.viewtodolistitem(event.data._id, true);
            this.setState({ id: event.data._id, todoitemdata: event.data }, () => {

            });
        }
        if (value === 'Edit') {
            this.setState({ modalType: "Edit", id: event.data._id });
            this.props.history.push('/edit-to-do-list-item/' + event.data.category + '/' + event.data.hotel)
            // if (this.state.modalType === "Edit") return <Redirect to={'edit-to-do-list-item/' + this.state.id} />

        }
        if (value === 'Delete') {
            showSwalDialog('Are you sure you want to delete?', '', 'Ok', 'Cancel', 'assets/images/delete.png').then((result) => {
                if (result.isConfirmed) {
                    this.setState({ searchhoteloption: '', searchhotel: '' })
                    axios.get(ImportedURL.API.softDeletetodolistitem + "/" + event.data._id)
                        .then((res) => {
                            Success((languageData && languageData.CHECK_LIST ? languageData.CHECK_LIST : "Check List listitem") + (languageData && languageData.DELETED_SUCCESSFULLY ? languageData.DELETED_SUCCESSFULLY : " Deleted successfully"));
                            this.props.listtodolistitem();
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData, "todo items")
                        });
                }
            })
        }
        if (value === 'Status') {
            showSwalDialog('Are you sure you want to change the status?', '', 'Ok', 'Cancel', (ImportedURL.LOCALIMAGEURL + 'assets/images/status.png')).then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { itemparentid: event.data._id, status: !event.data.status, model: 'todolistitems' })
                        .then((data) => {
                            this.setState({ searchhoteloption: '', searchhotel: '' })
                            this.props.listtodolistitem();
                            Success((languageData && languageData.STATUS ? languageData.STATUS + " " : "Status") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"))
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
    }

    searchUser = e => {
        const { name, value, label } = e;
        this.props.listtodolistitem({ hotel: value });
        this.setState({ searchhoteloption: e, searchhotel: value, hotelName: label })
    }
    searchDuration = e => {
        const { name, value, label } = e;
        this.props.listtodolistitem({ duration: value });
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };
    componentDidUpdate(prevProps, prevState) {
        const role = this.props.AccountState.role;
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role) && this.state.columnreset) {
            let updatedColumnDefs = [...this.state.columnDefs];
            updatedColumnDefs = updatedColumnDefs.map((col) => {
                if (col.field === 'hotelName' || col.field === 'logo') {
                    return { ...col, hide: false };
                }
                return col;
            });
            this.gridApi.setColumnDefs(updatedColumnDefs);
            this.setState({ columnreset: false })
        }
    }
    handleFilterChanged = () => {
        const api = this.gridApi;
        if (api && api.getDisplayedRowCount() === 0) {
            api.showNoRowsOverlay();
            this.setState({ hideExport: true })
        } else {
            api.hideOverlay();
            this.setState({ hideExport: false })
        }
    };
    render() {
        const { AccountState, LandingState } = this.props;
        const rowData = this.props.TodolistitemState.listtodolistitem;
        const ViewData = this.props.TodolistitemState.viewtodolistitem;
        const account = this.props.AccountState.account;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const listHotelData = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 0 ? LandingState.landingdata.data[0] : [];
        const spinner = this.props.TodolistitemState.spinner;
        var hotelName = ViewData.hotelName;
        var logo = ViewData.logo;
        var duration = ViewData.duration;
        var itemvalue = ViewData.itemvalue;
        var categoryName = ViewData.categoryName;
        var time = ' at ' + TimeFormat(ViewData.starttime) + ' - ' + TimeFormat(ViewData.endtime);




        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Todo_List_Item" });
        const editOption = Previlege?.edit ? '<button type="button" class="btn btn-icon" title="Edit" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
        const deleteOption = ImportedURL.SUPERADMIN.includes(AccountState.role) ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : ''
        const template = '<div><button title="View" type="button" class="btn btn-icon" data-action-type="View"><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"  data-toggle="modal" data-target="#viewtodoitem"></i></button>'
            + editOption
            // + deleteOption
            + '</div>'
        if (rowData) {
            rowData.forEach(object => {
                object.actions = template;
            });
        }
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        const durationOptions = [
            { value: 'Monthly', label: 'Monthly', name: 'duration' },
            { value: 'Bi-Weekly', label: 'Bi-Weekly', name: 'duration' },
            { value: 'Weekly', label: 'Weekly', name: 'duration' },
            { value: 'Daily', label: 'Daily', name: 'duration' },
            { value: 'Custom', label: 'Custom', name: 'duration' }
        ]

        var hotelOptionSearch = [];
        if (listHotelData.length > 1) {
            hotelOptionSearch.push({ label: 'All', value: '', name: 'searchhotel' })
        }
        if (listHotelData.length > 0) {
            listHotelData.filter(filterItem => filterItem.status === true).map(item => {
                hotelOptionSearch.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'searchhotel' });
            })
        }

        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/to-do-list-item"><h6 className="highlights_breadcrump"> {languageData && languageData.CHECK_LIST ? languageData.CHECK_LIST : "Check List"}</h6></Link></p>
                    </div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header tit_res">
                                            <div className='d-flex'>
                                                <div className='rounded_icon'><i className="fa fa-calendar mr-2 "></i></div>
                                                <div className='d-flex align-items-center'>
                                                    <h3 className="card-title d-flex "> <span className=''>{languageData && languageData.CHECK_LIST ? languageData.CHECK_LIST : "Check List"}</span> </h3>
                                                </div>
                                            </div>

                                            <div className="card-options add_card_options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab">
                                                    </ul>
                                                    <div className="header-action">
                                                        {Previlege?.add ? <Link to='/add-to-do-list-item'><button type="button" className="btn btn-primary" id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button></Link> : ''}
                                                    </div>
                                                </div>
                                                {rowData && rowData.length > 0 && !this.state.hideExport ? <>
                                                    <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                        <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                    </div>
                                                </> : ''}
                                            </div>
                                        </div>

                                        <div className="card-body ">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                    <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                            className='limit_size'
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    account?.allhotels == undefined &&
                                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xl-6">
                                                        <label className="form-label">{languageData && languageData.SEARCH ? languageData.SEARCH : "Search"}  {languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel"}</label>
                                                        <div className="form-group" style={{ marginBottom: '10px' }}>
                                                            <Select
                                                                value={this.state.searchhoteloption}
                                                                onChange={this.searchUser}
                                                                options={hotelOptionSearch}
                                                                formatOptionLabel={(e) => (
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                        <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                    </div>
                                                                )}
                                                            />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        rowDragManaged={true}
                                                        animateRows={true}
                                                        columnDefs={this.state.columnDefs}
                                                        onGridReady={this.onGridReady}
                                                        overlayNoRowsTemplate={"No rows to display"}
                                                        onFilterChanged={this.handleFilterChanged}
                                                    >
                                                    </AgGridReact>
                                                </div>
                                                {(spinner || this.state.spinner) ?
                                                    <div className='common_loader_ag_grid'>
                                                        <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                        </Spinner>
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="overlay" style={{ display: spinner ? 'block' : 'none' }}></div>
                    </div>
                </div>
                <div className="modal fade" id="viewtodoitem" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{languageData && languageData.VIEW ? languageData.VIEW : "View"} {languageData && languageData.CHECK_LIST ? languageData.CHECK_LIST : "Check List"}</h5>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }} onClick={this.closeModal}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body ribbon px-0">
                                {/* <div className='ribbon'>
                                    <div className="ribbon-box orange " style={{ zIndex: '1' }}>{duration}</div>
                                </div> */}
                                <div className="card">
                                    <div className="card-body text-center ribbon">
                                        {ImportedURL.SUPERADMIN.includes(account.role) ?
                                            <><img className="rounded-circle img-thumbnail w100 h100" style={{ objectFit: "contain" }} src={ImportedURL.FILEURL + this.state.todoitemdata.logo} alt="fake_url" onError={onErrorImage} />
                                                <h6 className="mt-3">{this.state.todoitemdata.hotelName}</h6>
                                            </>
                                            : ''}
                                        <div className="row text-left" style={{ marginTop: ImportedURL.SUPERADMIN.includes(account.role) ? '50px' : '' }}>
                                            {
                                                // categoryName && categoryName.length > 0
                                                // &&
                                                <>
                                                    <div className="col-lg-12  col-xl-12">
                                                        <label className="mb-2 mt-1" style={{ fontWeight: "bolder" }}>User Category </label>
                                                        <h4 className={categoryName && categoryName.length > 3 ? "duration_scroll" : "font-18 "}>
                                                            <p style={{ display: 'flex' }}><span className='mx-2' style={{ fontSize: "13px" }}><i className="fa fa-play" aria-hidden="true"></i></span>{this.state.todoitemdata.categoryName}</p>
                                                            {/* {
                                                                categoryName?.map((item, i) => {
                                                                    return (
                                                                        <>
                                                                            <p key={i} style={{ display: 'flex' }}><span className='mx-2' style={{ fontSize: "13px", marginTop: '4px' }}><i className="fa fa-play" aria-hidden="true"></i></span>{item}</p></>
                                                                    )
                                                                })} */}
                                                        </h4>
                                                    </div>
                                                </>
                                            }
                                            <div className="col-lg-12  col-xl-12">
                                                <label className="mb-2 mt-1" style={{ fontWeight: "bolder" }}>Item</label>
                                                <h4 className="font-18 duration_scroll">
                                                    {
                                                        this.state.todoitemdata.itemvalue?.map((item, i) => {
                                                            return (
                                                                <>
                                                                    <p key={i} style={{ display: 'flex' }}><span className='mx-2' style={{ fontSize: "13px" }}><i className="fa fa-play" aria-hidden="true"></i></span>{item}</p></>
                                                            )
                                                        })}
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div id='overlay-model' style={{ display: (this.state.editSpi && Object.keys(ViewData.hotel && ViewData.itemvalue).length <= 0) ? 'block' : 'none' }}></div>
                            {(this.state.editSpi && Object.keys(ViewData.hotel && ViewData.itemvalue).length <= 0) ?
                                <div className='model_loader_poss'>
                                    <img className='loader_img_style_model' src='../../assets/images/load.png' />
                                    <Spinner className='spinner_load_model' animation="border" variant="info" >
                                    </Spinner>
                                </div>
                                : ''} */}
                        </div>
                    </div>
                </div >
            </>
        )
    }
}
const mapStateToProps = state => ({
    TodolistitemState: state.todolistitem,
    AccountState: state.account,
    LandingState: state.landing,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        listtodolistitem: AC_LIST_TODO_LIST_ITEM,
        viewtodolistitem: AC_VIEW_TODO_LIST_ITEM,
        emptytodolist: AC_EMPTY_LIST_TODO_ITEM,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListtodoListItem);
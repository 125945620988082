import React, { Component } from 'react'
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { Success } from '../../common/swal';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import moment from 'moment';
import showSwalDialog from '../../utilities/Modal/swal';
import ErrorResponse from '../../utilities/Error/errorstatus';

const initialState = {
    modalType: "Add",
    columnApi: '',
    gridApi: '',
    columnDefs: [
        { headerName: '#', valueGetter: "node.rowIndex+1", width: 50, hide: true, sortable: false, filter: false, cellStyle: { 'text-align': 'center' } },
        { headerName: 'Name', field: 'name', width: 120, cellStyle: { 'text-align': 'center' }, floatingFilter: true },
        {
            headerName: 'Code', field: 'code', width: 120, cellStyle: { 'text-align': 'center' }, floatingFilter: true,
        },
        {
            headerName: 'Auto Deleted', field: 'updatedAt', width: 130, cellStyle: { 'text-align': 'center' },
            cellRenderer: function (params) {
                var date1 = new Date(moment(params.data.updatedAt).tz(params.data.tzone).format(params.data.dFormat))
                var date2 = new Date(moment(new Date()).tz(params.data.tzone).format(params.data.dFormat))
                var difference = date2.getTime() - date1.getTime();
                var TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
                var value = 30 - TotalDays
                if (value > 5) {
                    return value + ' days'
                } else {
                    if (value <= 0) {
                        return `<div style="color:red">` + 'Today' + `</div>`
                    } else {
                        if (value == 1) {
                            return `<div style="color:red">` + value + ' day' + `</div>`
                        } else {
                            return `<div style="color:red">` + value + ' days' + `</div>`
                        }
                    }
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                return '<div class="status_style"><span type="button" class="tag tag-danger" data-action-type="Restore">Restore</span></div>';
            }
        },

    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true,
    },
    rowData: [],
    spinner: true

}

class LanguageTrash extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    onRowClicked = event => {
        const { AccountState } = this.props;
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (value === 'Restore') {
            showSwalDialog('Do you want to recover data?', '', 'Yes', 'No', 'assets/images/restore.png').then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeleteLanguage + '/' + event.data._id)
                        .then((res) => {
                            Success((languageData && languageData.LANGUAGE ? languageData.LANGUAGE : "Language") + (languageData && languageData.RECOVERD_SUCCESSFULLY ? languageData.RECOVERD_SUCCESSFULLY : " recoverd successfully"));
                            axios.get(ImportedURL.API.listTrashLanguage)
                                .then((res) => {
                                    this.setState({ rowData: res.data })
                                })
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
    }
    componentDidMount() {
        axios.get(ImportedURL.API.listTrashLanguage)
            .then((res) => {
                this.setState({ rowData: res.data, spinner: false })
            })
    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }
    render() {
        const account = this.props.AccountState.account;
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        const languageData = this.props.AccountState.account.SelectedlanguageData
        var dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY');
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
        }
        if (this.state.rowData) {
            this.state.rowData.forEach(object => {
                object.dFormat = dFormat;
                object.tzone = tzone;
            });
        }
        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/group-trash"><h6 className="highlights_breadcrump">{languageData && languageData.LANGUAGE ? languageData.LANGUAGE : "Language"} {languageData && languageData.TRASH ? languageData.TRASH : " Trash"} {languageData && languageData.LIST ? languageData.LIST : ' List'}</h6></Link></p>
                    </div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-trash-o mr-2"></i></div>
                                            <h3 className="card-title">{languageData && languageData.LANGUAGE ? languageData.LANGUAGE : "Food"} {languageData && languageData.TRASH ? languageData.TRASH : " Language"} {languageData && languageData.LIST ? languageData.LIST : ' List'}</h3>
                                            <div className="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab">
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                    <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                            className='limit_size'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine">
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={this.state.rowData}
                                                        columnDefs={this.state.columnDefs}
                                                        rowDragManaged={true}
                                                        animateRows={true}
                                                        overlayNoRowsTemplate={"No rows to display"}
                                                        onGridReady={this.onGridReady}
                                                    >
                                                    </AgGridReact>
                                                </div>
                                                {/* <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div> */}
                                                {this.state.spinner ?
                                                    <div className='common_loader_ag_grid'>
                                                        <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                        </Spinner>
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    CountryState: state.country,
    AccountState: state.account,
    HotelState: state.hotel,
    InventoryProductState: state.inventoryproduct
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageTrash);
import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_LIST_PMS_PREVILEGES(params = {}) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.listPMSPrivileges, params)
            .then((res) => {
                dispatch({ type: "LIST_PMS_PREVILEGES", payload: res.data, total: 0, spinner: false })
            }).catch((err) => {
                dispatch({ type: "LIST_PMS_PREVILEGES", payload: [], total: 0, spinner: false })
            });
    }
}

export function AC_VIEW_PMS_PREVILEGES(id = '') {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewPMSPrivileges + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_PMS_PREVILEGES", payload: res.data, spinner: false })
            }).catch((err) => {
                dispatch({ type: "VIEW_PMS_PREVILEGES", payload: {}, spinner: false })
            });
    };
}

export function AC_HANDLE_PMS_PREVILEGES_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_PMS_PREVILEGES_CHANGE", name: name, value: value })
    };
}

export function AC_EMPTY_PMS_PREVILEGES() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_PMS_PREVILEGES" })
    };
}

export function AC_SPINNER_PMS_PREVILEGES() {
    return function (dispatch) {
        dispatch({ type: "SPINNER_PMS_PREVILEGES" })
    };
}

export function AC_MODAL_SPINNER_PMS_PREVILEGES() {
    return function (dispatch) {
        dispatch({ type: "MODAL_SPINNER_PMS_PREVILEGES" })
    };
}

const initialState = {
    designation: {
        name: '',
        hotel: '',
        department: '',
    },
    listDesignation: [],
    total: 0,
    spinner: true
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_DESIGNATION':
            return {
                ...state,
                designation: action.payload
            }
        case 'LIST_DESIGNATIONS':
            return Object.assign({}, state, {
                listDesignation: action.payload,
                total: action.total,
                spinner: false
            })
        case 'VIEW_DESIGNATION':
            return {
                ...state,
                designation: action.payload,
                spinner: false,
            }
        case 'RESET_DESIGNATION':
            return Object.assign({}, state, {
                designation: initialState.designation
            })
        case 'EMPTY_DESIGNATION':
            return Object.assign({}, state, {
                spinner: true
            })
        case 'DELETE_DESIGNATION':
            return {
                ...state,
                deleteDesignation: action.payload
            }
        case 'HANDLE_DESIGNATION_CHANGE':
            return Object.assign({}, state, {
                designation: {
                    ...state.designation,
                    [action.name]: action.value
                }
            })
        default:
            return state;
    }
}


import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
// import { AC_GET_DESCRIPTION_WISE_TICKET_BY_ID, AC_DESCRIPTION_WISE_TICKET, AC_LIST_TICKET_PRIORITY_COUNT, AC_HANDLE_TICKET_KEY_SEARCH } from '../../../actions/ticketAction';
import moment from 'moment';
import { AC_REVIEW_KEYWORD, AC_GET_REVIEW_BY_ID, AC_HANDLE_REVIEW_KEY_SEARCH, AC_EMPTY_SPIN } from '../../actions/reviewAction';
import { Row, Spinner } from 'react-bootstrap';
import Carousel from 'react-multi-carousel';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};


class sentimentalviewdescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hotel: "",
            ticketId: this.props.match.params.id,
            serachValue: "",
            viewredirect: false,
            viewTicketId: '',
            initial: true,
            readMoreValue: false,
            indexValue: 0,
            length: 0,
            popupdata: {},
            descriptionKeys: {},
            seemore: false,
            typingTimeout: 0
        }
    }
    async componentDidMount() {
        var hotel = this.props.match.params.id;
        this.props.EmptySpin();
        const fromDateReview = new URLSearchParams(this.props.location.search).get('from');
        const toDateReview = new URLSearchParams(this.props.location.search).get('to');
        axios.get(ImportedURL.API.reviewKeyword, { params: { hotel: hotel, fromDateReview: fromDateReview, toDateReview: toDateReview } })
            .then((res) => {
                const descriptionKeys = Object.keys(res.data)
                const formData = {
                    keyword: descriptionKeys,
                    hotel: this.props.match.params.id,
                    fromDateReview: fromDateReview ? fromDateReview : '',
                    toDateReview: toDateReview ? toDateReview : ''
                    // fromDate: fromdate, toDate: todate
                }
                this.setState({ descriptionKeys: res.data })
                if (descriptionKeys && descriptionKeys.length > 5) {
                    this.setState({ seemore: true })
                } else {
                    this.setState({ seemore: false })
                }

                this.props.ReviewByClient(formData, 'initial');
                // axios.post(ImportedURL.API.reviewByClient, formData)
                //     .then((res) => {
                //         this.setState({ length: res && res.data ? res.data.length : 0 })
                //     })
            }).catch((err) => { console.log(err); });
    }

    handleBackClick = () => {
        const account = this.props.AccountState.account;
        if (ImportedURL.SUPERADMIN.includes(account.role)) {
            this.props.ReviewByKeyword({ hotel: "" });
        }
    }
    desctiptionClick = (e, i) => {
        var hotel = this.props.match.params.id;
        const fromDateReview = new URLSearchParams(this.props.location.search).get('from');
        const toDateReview = new URLSearchParams(this.props.location.search).get('to');

        // const reviewClientKeyword = this.props.ReviewState.reviewClientKeyword;
        const descriptionKeys = Object.keys(this.state.descriptionKeys)
        let value = []
        if (e == "") {
            value = descriptionKeys
        } else {
            value.push(e)
        }
        const formData = {
            keyword: value,
            hotel: hotel,
            fromDateReview: fromDateReview ? fromDateReview : '',
            toDateReview: toDateReview ? toDateReview : ''
        }
        this.setState({ indexValue: i, keyvalue: e, serachValue: '' })
        this.props.EmptySpin();
        this.props.ReviewByClient(formData);
    }
    handleSearch = (e) => {
        const { ReviewState } = this.props;
        var value = e.target.value;
        const rowData = ReviewState.listfilter;
        this.setState({ serachValue: value })
        var hotel = this.props.match.params.id;

        const fromDateReview = new URLSearchParams(this.props.location.search).get('from');
        const toDateReview = new URLSearchParams(this.props.location.search).get('to');

        if (value) {
            if (value.length > 2) {
                // Clear the existing timer
                clearTimeout(this.searchTimer);

                // Set up a new timer to trigger the search after a delay (e.g., 500 milliseconds)
                this.searchTimer = setTimeout(() => {
                    this.props.EmptySpin();
                    const descriptionKeys = Object.keys(this.state.descriptionKeys)
                    const formData = {
                        keyword: this.state.keyvalue ? [this.state.keyvalue] : descriptionKeys,
                        hotel: this.props.match.params.id,
                        fromDateReview: fromDateReview ? fromDateReview : '',
                        toDateReview: toDateReview ? toDateReview : '',
                        searchtext: this.state.serachValue
                    }

                    this.props.ReviewByClient(formData);
                }, 1000);

            }
        }
        else {
            this.props.EmptySpin();
            const descriptionKeys = Object.keys(this.state.descriptionKeys)
            const formData = {
                keyword: descriptionKeys,
                hotel: hotel,
                fromDateReview: fromDateReview ? fromDateReview : '',
                toDateReview: toDateReview ? toDateReview : ''
            }
            this.props.ReviewByClient(formData);
            // this.props.ReviewByKeyword({ hotel: hotel, fromDateReview: fromDateReview, toDateReview: toDateReview });
        }
    }
    getDate = (date) => {
        const account = this.props.AccountState.account;
        var dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY');
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
        }
        if (!date) return '';
        // const newDate = new Date(date * 1000);
        return moment(date).format(dFormat);
    }
    toggleReadMore = (e, i) => {
        if (i == this.state.readMoreValue) {
            if (this.state.isReadMore) {
                this.setState({ isReadMore: false })
            } else {
                this.setState({ isReadMore: true })
            }
        } else {
            this.setState({ isReadMore: true })
        }
        this.setState({ readMoreValue: i })
    };


    index = (i) => {
        if (this.state.indexValue == i) {
            return true
        } else {
            return false
        }
    }
    popup = (data) => {
        this.setState({ popupdata: data })
    }
    render() {
        const { ReviewState, AccountState } = this.props;
        const account = AccountState.account;
        // const reviewClientKeyword = ReviewState.reviewClientKeyword;
        const dataSpinner = ReviewState.spin
        const languageData = AccountState.account.SelectedlanguageData

        const descriptionByTicket = ReviewState.reviewByClient;


        let length = 0
        if (descriptionByTicket && descriptionByTicket.length > 0) {
            length = descriptionByTicket.length
        }
        const TicketKeys = [
            <li class="nav-item mb-3 pl-0" role="presentation">
                <button class={this.index(0) ? "nav-link active senti_btn_view onchange_sentimental_value" : "nav-link active senti_btn_view"} id="pills-home-tab" data-toggle="pill" data-target="#pills-home" type="button" role="tab" aria-controls="pills-home" onClick={e => this.desctiptionClick('', 0)} aria-selected="true">
                    All <span class="badge badge-light sentiment_badge_count" >{ReviewState.lengthofsentimentdata}</span>
                </button>
            </li>
        ];
        if (this.state.descriptionKeys) {
            for (let [index, [key, value]] of Object.entries(Object.entries(this.state.descriptionKeys))) {
                TicketKeys.push(
                    <li class="nav-item mb-3 review_sentimental_analysis pl-0" role="presentation">
                        <button class={this.index(index + 1) ? "nav-link active senti_btn_view onchange_sentimental_value" : "nav-link active senti_btn_view"} id="pills-home-tab" data-toggle="pill" data-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={e => this.desctiptionClick(key, index + 1)}>
                            {key}<span class="badge badge-light sentiment_badge_count" >{value}</span>
                        </button>
                    </li>
                )
            }

        }

        var ticketHighligtArray = [];
        for (let index = 0; index < descriptionByTicket.length; index++) {
            var priority = 'Low';
            var highlightRe = '/<span class="highlight" >(.*?)<\/span>/g';
            var highlightHtml = '<span class="highlight" style="background:none;border:0;color:red;font-size:15px;font-weight:700;line-height:1.2;">$1</span>';
            let replaceTitle = descriptionByTicket[index].title ? descriptionByTicket[index].title : 'No Title';
            var highlightTitle = replaceTitle.replace(highlightRe, '$1');
            if (descriptionByTicket[index].keyword !== '') {
                highlightTitle = highlightTitle.replace(new RegExp('(' + descriptionByTicket[index].keyword + ')', 'gi'), highlightHtml);
            }

            let replaceDesc = descriptionByTicket[index].text;
            var highlightDesc = replaceDesc.replace(highlightRe, '$1');
            if (descriptionByTicket[index].keyword !== '') {
                highlightDesc = highlightDesc.replace(new RegExp('(' + descriptionByTicket[index].keyword + ')', 'gi'), highlightHtml);
            }

            // if (descriptionByTicket[index].priority == 1) {
            //     priority = 'High'
            // } else if (descriptionByTicket[index].priority == 2) {
            //     priority = 'Medium'
            // }

            var supplierValue = ""
            if (descriptionByTicket[index].supplier == "hotels" || descriptionByTicket[index].supplier == "booking") {
                supplierValue = descriptionByTicket[index].supplier + ".com"
            } else if (descriptionByTicket[index].supplier == "trip-advisor") {
                supplierValue = "tripadvisor"
            } else {
                supplierValue = descriptionByTicket[index].supplier
            }
            var imageSet = ''
            if (descriptionByTicket[index].supplier == "hotels") {
                imageSet = '../../assets/images/hotels.png'
            } else if (descriptionByTicket[index].supplier == "trip-advisor") {
                imageSet = '../../assets/images/tripadviser.png'
            } else if (descriptionByTicket[index].supplier == "booking") {
                imageSet = '../../assets/images/booking.png'
            } else if (descriptionByTicket[index].supplier == "google") {
                imageSet = '../../assets/images/google.webp'
            } else if (descriptionByTicket[index].supplier == "facebook") {
                imageSet = '../../assets/images/fb.svg'
            } else if (descriptionByTicket[index].supplier == "yelp") {
                imageSet = '../../assets/images/yelp.png'
            } else if (descriptionByTicket[index].supplier == "expedia") {
                imageSet = '../../assets/images/ex.png'
            }
            if (highlightDesc) {
                highlightDesc.replace(/<[^>]*>/g, '')
            }
            ticketHighligtArray.push(<>
                <div class="col-lg-6 col-md-12" data-toggle="modal" data-target="#exampleModal" onClick={() => this.popup(descriptionByTicket[index])}>
                    <div class="card sent_card design__card">
                        <div class="card-header view_card_design">
                            <h3 class="card-title">
                                <p dangerouslySetInnerHTML={{ __html: highlightTitle }}></p>
                            </h3>
                        </div>
                        <div class="card-body">
                            {
                                (highlightDesc != undefined && highlightDesc)
                                &&
                                (highlightDesc.length < 250 ? <span className='read_more_read_less'>{<p dangerouslySetInnerHTML={{ __html: highlightDesc }}></p>}</span> : (
                                    <>
                                        {
                                            this.state.readMoreValue == index ?
                                                <span className='read_more_read_less' dangerouslySetInnerHTML={{ __html: this.state.isReadMore ? highlightDesc : (highlightDesc).slice(0, 250) + '...' }}></span>
                                                :
                                                <span className='read_more_read_less' dangerouslySetInnerHTML={{ __html: (highlightDesc).slice(0, 250) + '...' }}></span>
                                        }
                                        {/* < span className='read_more' onClick={(e) => this.toggleReadMore(e, index)}>{this.state.readMoreValue == index ? (this.state.isReadMore && (highlightDesc).slice(0, 150) ? ' Read less' : 'Read more') : ' Read more'}</span> */}
                                    </>
                                ))
                            }
                            <div class="row">
                                {ImportedURL.SUPERADMIN.includes(account.role) ?
                                    <>
                                        <div class="col-3 py-1">
                                            <label className='label_black'>Hotel</label>
                                        </div>
                                        <div class="col-2 py-1">:</div>
                                        <label class="col-7 py-1 hotel_name_ creator_senti">{descriptionByTicket[index].hotelName}</label>
                                    </>
                                    : ""}
                                <div class="col-3 py-1">
                                    <label className='label_black'>Name</label>
                                </div>
                                <div class="col-2 py-1">:</div>
                                <label class="col-7 py-1 hotel_name_1 creator_senti">{descriptionByTicket[index].reviewer_name}</label>

                                <div class="col-3 py-1">
                                    <label className='label_black'>OTA's Type</label>
                                </div>
                                <div class="col-2 py-1">:</div>
                                <label class="col-7 py-1">
                                    <a href={descriptionByTicket[index].url} target='_blank' >
                                        <small className='pr-2'>
                                            <span className='review_Logo'>
                                                <img src={imageSet} referrerpolicy="no-referrer" />
                                            </span>
                                        </small>
                                    </a>
                                    <a href={descriptionByTicket[index].url} style={{ color: '#007bff' }} target='_blank'>{supplierValue.charAt(0).toUpperCase() + supplierValue.substr(1).toLowerCase()}</a>
                                </label>
                                <div class="col-3 py-1">
                                    <label className='label_black'>Rating</label>
                                </div>
                                <div class="col-2 py-1">:</div>
                                <label class="col-7 py-1">{descriptionByTicket[index].rating}</label>
                                <div class="col-3 py-1">
                                    <label className='label_black'>Published at</label>
                                </div>
                                <div class="col-2 py-1">:</div>
                                <label class="col-7 py-1">{this.getDate(descriptionByTicket[index].published_at)}</label>
                            </div>
                        </div>
                    </div>


                </div>
            </>)
        }
        var popupimageSet = ''
        var popupsupplierValue = ""
        if (this.state.popupdata && this.state.popupdata.supplier) {
            var popuphighlightRe = '/<span class="highlight" >(.*?)<\/span>/g';
            var popuphighlightHtml = '<span class="highlight" style="background:none;border:0;color:red;font-size:15px;font-weight:700;line-height:1.2;">$1</span>';
            let popupreplaceTitle = this.state.popupdata.title ? this.state.popupdata.title : '';
            var popuphighlightTitle = popupreplaceTitle.replace(popuphighlightRe, '$1');
            if (this.state.popupdata.keyword !== '') {
                popuphighlightTitle = popuphighlightTitle.replace(new RegExp('(' + this.state.popupdata.keyword + ')', 'gi'), popuphighlightHtml);
            }

            let popupreplaceDesc = this.state.popupdata.text;
            var popuphighlightDesc = popupreplaceDesc.replace(popuphighlightRe, '$1');
            if (this.state.popupdata.keyword !== '') {
                popuphighlightDesc = popuphighlightDesc.replace(new RegExp('(' + this.state.popupdata.keyword + ')', 'gi'), popuphighlightHtml);
            }

            if (this.state.popupdata.supplier == "hotels" || this.state.popupdata.supplier == "booking") {
                popupsupplierValue = this.state.popupdata.supplier + ".com"
            } else if (this.state.popupdata.supplier == "trip-advisor") {
                popupsupplierValue = "tripadvisor"
            } else {
                popupsupplierValue = this.state.popupdata.supplier
            }
            if (this.state.popupdata.supplier == "hotels") {
                popupimageSet = '../../assets/images/hotels.png'
            } else if (this.state.popupdata.supplier == "trip-advisor") {
                popupimageSet = '../../assets/images/tripadviser.png'
            } else if (this.state.popupdata.supplier == "booking") {
                popupimageSet = '../../assets/images/booking.png'
            } else if (this.state.popupdata.supplier == "google") {
                popupimageSet = '../../assets/images/google.webp'
            } else if (this.state.popupdata.supplier == "facebook") {
                popupimageSet = '../../assets/images/fb.svg'
            } else if (this.state.popupdata.supplier == "yelp") {
                popupimageSet = '../../assets/images/yelp.png'
            } else if (this.state.popupdata.supplier == "expedia") {
                popupimageSet = '../../assets/images/ex.png'
            }
            if (popuphighlightDesc) {
                popuphighlightDesc.replace(/<[^>]*>/g, '')
            }

        }

        let highCountKeys = []
        let allCountKeys = TicketKeys
        if (TicketKeys && TicketKeys.length > 5) {
            for (let index = 0; index < 5; index++) {
                highCountKeys.push(TicketKeys[index])
            }
        }
        return (
            <div>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/social-reviews"><h6 className="highlights_breadcrump">Latest OTA's Reviews</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>Review-Sentimental Analysis Details</h6></p>
                    </div>
                    <div className="section-body pt-3 view_ticket">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header" style={{ padding: "0px", background: 'white' }}>
                                    <div className='rounded_icon'><i className="icon-star mr-2"></i></div>
                                    <h3 className="card-title " style={{ marginTop: '5px' }}>Review-Sentimental Analysis Details</h3>
                                    <div className="header-action" style={{ marginTop: '5px', marginLeft: 'auto' }}>
                                        <Link to='/social-reviews'><button type="button" className="btn btn-primary" id='Add' onClick={this.handleBackClick}><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button></Link>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className="row clearfix">
                                        <div className='col-lg-12'>
                                            <div class="form-group mt-2 senti_search">
                                                <label className='form-label'>Search</label>
                                                <input type="text" class="form-control " id="formGroupExampleInput" placeholder="Search" onChange={this.handleSearch} value={this.state.serachValue} disabled={dataSpinner} />
                                                <i class="fa fa-search senti_serch_icon" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className={TicketKeys.length > 5 ? 'col-lg-10 justify-content-md-center' : 'col-lg-12 justify-content-md-center'}>
                                            {/* {
                                                (TicketKeys != undefined && TicketKeys && TicketKeys.length > 5)
                                                    ?
                                                    <div className='d-flex flex-wrap reviews_sentimental_buttons'>
                                                        {TicketKeys}
                                                    </div>
                                                    :
                                                    <div className='d-flex flex-wrap'>
                                                        {TicketKeys}
                                                    </div>
                                            } */}
                                            {this.state.seemore ?
                                                <div className='d-flex flex-wrap align-items-center'>
                                                    {highCountKeys}
                                                </div> :
                                                <div className='d-flex flex-wrap align-items-center reviews_sentimental_buttons'>
                                                    {allCountKeys}
                                                </div>}
                                        </div>
                                        {TicketKeys.length > 5 ? <div className='col-lg-2'>
                                            <button className='nav-link active see_more_senti' onClick={() => this.setState({ seemore: !this.state.seemore })}><i className={this.state.seemore ? "fa fa-chevron-down mr-2" : "fa fa-chevron-up mr-2"}></i>{this.state.seemore ? "See More" : "See Less"}</button>
                                        </div> : ''}
                                        <div className='col-lg-12 mt-3'>
                                            {descriptionByTicket.length > 0 ?
                                                <div class="row">
                                                    {ticketHighligtArray}
                                                </div>
                                                : ""
                                            }
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade review_modal__" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog modal-lg" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title card-title" id="exampleModalLabel"> {this.state.popupdata.title ? this.state.popupdata.title : 'No Title'}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><img src="../../assets/images/cancel.png" /></span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <p className='review_modal_para'>{this.state.popupdata ? this.state.popupdata.text : ''}</p>
                                    <div className='review_table__'>
                                        <div class="row">
                                            {ImportedURL.SUPERADMIN.includes(account.role) ?
                                                <>
                                                    <div class="col-2 py-1">
                                                        <label className='label_black'>Hotel</label>
                                                    </div>
                                                    <div class="col-1 py-1">:</div>
                                                    <label class="col-9 py-1">{this.state.popupdata ? this.state.popupdata.hotelName : ''}</label>
                                                </>
                                                : ""}
                                            <div class="col-2 py-1">
                                                <label className='label_black'>Name</label>
                                            </div>
                                            <div class="col-1 py-1">:</div>
                                            <label class="col-9 py-1">{this.state.popupdata ? this.state.popupdata.reviewer_name : ''}</label>

                                            <div class="col-2 py-1">
                                                <label className='label_black'>OTA's Type</label>
                                            </div>
                                            <div class="col-1 py-1">:</div>
                                            <label class="col-9 py-1">
                                                <a href={this.state.popupdata ? this.state.popupdata.url : ''} target='_blank' >
                                                    <small className='pr-2'>
                                                        <span className='review_Logo'>
                                                            <img src={this.state.popupdata ? popupimageSet : ''} referrerpolicy="no-referrer" />
                                                        </span>
                                                    </small>
                                                </a>
                                                <a href={this.state.popupdata ? this.state.popupdata.url : ''} style={{ color: '#007bff' }} target='_blank'>{popupsupplierValue ? popupsupplierValue.charAt(0).toUpperCase() + popupsupplierValue.substr(1).toLowerCase() : ''}</a>
                                            </label>
                                            <div class="col-2 py-1">
                                                <label className='label_black'>Rating</label>
                                            </div>
                                            <div class="col-1 py-1">:</div>
                                            <label class="col-9 py-1">{this.state.popupdata ? this.state.popupdata.rating : ''}</label>
                                            <div class="col-2 py-1">
                                                <label className='label_black'>Published at</label>
                                            </div>
                                            <div class="col-1 py-1">:</div>
                                            <label class="col-9 py-1">{this.state.popupdata ? this.getDate(this.state.popupdata.published_at) : ''}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div id="overlay" style={{ display: (dataSpinner ? 'block' : 'none') }}></div>
                {
                    (dataSpinner &&
                        <div className='common_loader'>
                            <img className='loader_img_style_common' src='../../assets/images/load.png' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                        </div>
                    )
                }
            </div>
        )
    }
}
const mapStateToProps = state => ({
    HotelState: state.hotel,
    ReviewState: state.review,
    AccountState: state.account,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ReviewByKeyword: AC_REVIEW_KEYWORD,
        ReviewByClient: AC_GET_REVIEW_BY_ID,
        handlekeySearch: AC_HANDLE_REVIEW_KEY_SEARCH,
        EmptySpin: AC_EMPTY_SPIN
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(sentimentalviewdescription);

import { Error } from "../../common/swal";

const ErrorResponse = (response, languageData, taggedManagement, alreadyExistField, recoverAlert) => {
    if (response && response.status) {
        if (response.status == 500) {
            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
        } else if (response.status == 400) {
            Error((languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request'))
        } else if (response.status == 502) {
            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
        } else if (response.status == 408) {
            Error((languageData && languageData.CANNOT_ABLE_TO_SEND_MESSAGE_FOR_INDIAN_NUMBER ? languageData.CANNOT_ABLE_TO_SEND_MESSAGE_FOR_INDIAN_NUMBER : 'Cannot able to send message for indian number'))
        } else if (response.status == 402) {
            Error(response.data && response.data.message ? response.data.message : (languageData && languageData.INVALID_PHONENUMBER ? languageData.INVALID_PHONENUMBER : 'Invalid phone number'))
        } else if (response.status == 409) {
            Error((alreadyExistField ? alreadyExistField : '') + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : ' already exist'))
        } else if (response.status == 422) {
            Error(("Could not delete this record as it is associated with ") + (taggedManagement ? taggedManagement : ''))
        } else if (response.status == 410) {
            Error(recoverAlert)
        } else {
            Error(response.statusMessage)
        }
    } else {
        Error("Bad request")
    }
};

export default ErrorResponse;

import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { bindActionCreators } from 'redux';
import jsPDF from "jspdf";
import { AC_ADD_HOTEL, AC_DELETE_HOTEL, AC_HANDLE_HOTEL_CHANGE, AC_LIST_HOTEL, AC_RESET_HOTEL, AC_UPDATE_HOTEL, AC_VIEW_HOTEL, AC_EMPTY_HOTEL, AC_EMPTY_HOTEL_LIST } from '../../actions/hotelAction';
import { AC_LIST_GROUP } from '../../actions/groupAction';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import Spinner from 'react-bootstrap/Spinner';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import { AC_LIST_HOTEL_DYNAMIC_CATEGORY } from '../../actions/dynamiccategoryAction';
import { imageCellRendererList } from '../../common/validate';
import ErrorResponse from '../../utilities/Error/errorstatus';
import showSwalDialog from '../../utilities/Modal/swal';

class ListEmployee extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalType: "Add",
            columnDefs: [
                { headerName: 'Group', field: 'group.name', width: 80, floatingFilter: true },
                {
                    headerName: 'Logo', field: 'logo', sortable: false, filter: false, "resizable": false, width: 50,
                    cellRenderer: imageCellRendererList,
                },
                {
                    headerName: 'Name', field: 'name', width: 300, floatingFilter: true,
                    cellRenderer: function (params) {
                        return `<div class='redirect_color'><a href='#' data-action-type="categoryPage">${params.data.name + ' ' + params.data.city + ' ' + params.data.state}</a></div>`;
                    }
                },
                // {
                //     headerName: 'Status', width: 110, field: 'status', filter: false, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true,
                //     cellRenderer: function (params) {
                //         if (params.data.status == "inactive") {
                //             return `<span type="button" class="tag tag-green" >Active</span>`;
                //         } else if (params.data.status == true) {
                //             return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                //         } else if (params.data.status == false) {
                //             return '<span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span>';
                //         }
                //     }
                // },
                {
                    headerName: 'Actions', width: 180, field: 'actions', pinned: 'right', resizable: 'false', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
                    cellRenderer: function (params) {
                        return params.value;
                    }
                },
            ],
            defaultColumDef: {
                // "width": 200,
                // "filter": true,
                // "sortable": true,
                // "resizable": true,
                editable: false,
                sortable: true,
                resizable: true,
                filter: true,
                flex: 1,
                minWidth: 100,
            },
            nameError: false,
            locationError: false,
            emailError: false,
            hotelidError: false,
            id: '',
            activeClick: false,
            inactiveClick: false,
            groupoption: '',
            groupName: '',
            group: ''
        }
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const { AccountState } = this.props;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'categoryPage') {
            window.open('/add-dynamic-category/' + event.data._id, '_blank').focus();
        }
        if (value === 'Edit') {
            window.open('/edit-dynamic-category/' + event.data._id, '_blank').focus();
        }
        if (value === 'View') {
            this.setState({ modalType: "View", page: this.gridApi.paginationGetCurrentPage(), id: event.data._id });
            this.props.ResetHotel();
        }
        if (value === 'Status') {
            showSwalDialog('Are you sure you want to change the status?', '', 'Ok', 'Cancel', 'assets/images/status.png').then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusDynamicCategory, { id: event.data._id, status: !event.data.status, model: 'hotels' })
                        .then((data) => {
                            this.props.ListHotel({ status: this.state.status, group: this.state.group });
                            Success((languageData && languageData.STATUS ? languageData.STATUS : ' Status') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " updated successfully"))
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
    }

    componentDidMount() {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        this.props.EmptyHotel()
        this.props.ListHotel();
        this.props.ListCountry();
        this.props.ListGroup();
    }

    componentDidUpdate() {
        const { fixNavbar, HotelState, AccountState, GroupState } = this.props;
        const rowData = HotelState.listhotels;
        const pageno = new URLSearchParams(this.props.location.search).get('page');
        if (rowData.length && this.gridApi && pageno) {
            this.gridApi.paginationGoToPage(pageno);
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
    };

    setGroup = e => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const { name, value, label } = e;
        this.props.ListHotel({ group: value, status: this.state.status, });
        this.setState({ [name + 'option']: e, groupName: label, group: value })
    }

    total = e => {
        this.props.RestHotelList()
        this.setState({ activeClick: false, inactiveClick: false, status: '' })
        this.props.ListHotel({ group: this.state.group });
    }
    active = e => {
        this.props.RestHotelList()
        this.setState({ activeClick: !this.state.activeClick, inactiveClick: false, status: true })
        this.props.ListHotel({ status: true, group: this.state.group });
    }
    inactive = e => {
        this.props.RestHotelList()
        this.setState({ inactiveClick: !this.state.inactiveClick, activeClick: false, status: false })
        this.props.ListHotel({ status: false, group: this.state.group });
    }
    render() {
        if (this.state.modalType === "View") return <Redirect to={'view-employee/' + this.state.id} />
        if (this.state.modalType === "Edit") return <Redirect to={'edit-hotel/' + this.state.id + '?page=' + this.state.page} />
        const { fixNavbar, HotelState, AccountState, GroupState, CountryState, DynamicCategoryState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const phoneCode = CountryState.listcountry;
        var rowData = []
        if (phoneCode && phoneCode.length > 0) {
            rowData = DynamicCategoryState.listHotel;
        }
        const spinner = DynamicCategoryState.spinner;
        const data = HotelState.hotel;
        const previleges = AccountState.previleges;
        const hotelPrevilege = previleges.find(obj => { return obj.name == "Hotel" });
        const editOption = hotelPrevilege.edit ? '<button type="button" class="btn btn-icon" title="Edit" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
        const deleteOption = ImportedURL.SUPERADMIN.includes(AccountState.role) ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : '';
        const template = '<div><button title="View" type="button" class="btn btn-icon" data-action-type="View"><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
            // + editOption
            // + deleteOption
            + '</div>'

        rowData.forEach(object => {
            object.actions = template;
        });
        rowData.forEach(object => {
            object.phoneCode = phoneCode;
        });
        const listGroup = GroupState.listgroups;
        const hotelOptions = [{ label: 'All', value: '', name: 'group' }];
        listGroup && listGroup.filter(filterItem => filterItem.status === true).map(item => {
            hotelOptions.push({ label: item.name, value: item._id, name: 'group' });
        })
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i class="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-employee"><h6 className="highlights_breadcrump">{languageData && languageData.EMPLOYEE_LIST ? languageData.EMPLOYEE_LIST : "Employees List"} </h6></Link></p>
                    </div>
                    <div className="section-body pt-3 hotel_sec">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-building-o mr-2 "></i></div>
                                            <h3 className="card-title">{languageData && languageData.EMPLOYEE_LIST ? languageData.EMPLOYEE_LIST : "Employees"} </h3>
                                            <div class="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab">
                                                    </ul>
                                                    {/* <div className="header-action">
                                                        {hotelPrevilege.add ? <Link to='add-hotel'><button type="button" className="btn btn-primary" id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button></Link> : ''}
                                                    </div> */}
                                                </div>
                                                {/* <a href="/#" class="btn btn-primary btn-sm">Action 1</a> */}
                                                {/* {rowData.length > 0 ? <>
                                                    <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i class="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                        <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                    </div>
                                                </> : []} */}
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                    <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                        />
                                                    </div>
                                                </div>
                                                {/* <div className="col-lg-2 col-md-4 col-sm-6 col-xl-5">
                                                    <label className="form-label">Group</label>
                                                    <div className="form-group">
                                                        <Select
                                                            value={this.state.groupoption ? { label: this.state.groupoption.label } : ''}
                                                            onChange={this.setGroup}
                                                            options={hotelOptions}
                                                        /> 
                                                    </div>
                                                </div> */}
                                            </div>
                                            {/* <button onClick={() => this.onBtPageFive()}>To Page 5</button> */}

                                            <div className="table-responsive">
                                                <div className="ag-theme-alpine" style={{ textAlign: 'left' }}>
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={25}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        rowData={rowData}
                                                        onGridReady={this.onGridReady}
                                                        columnDefs={this.state.columnDefs}
                                                        rowDragManaged={true}
                                                        animateRows={true}
                                                        overlayNoRowsTemplate={"No rows to display"}
                                                    >
                                                    </AgGridReact>
                                                </div>
                                                {/* <div id="overlay" style={{ display: spinner ? 'block' : 'none' }}></div> */}
                                                {spinner ?
                                                    <div className='common_loader_ag_grid'>
                                                        <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                        </Spinner>
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    HotelState: state.hotel,
    AccountState: state.account,
    GroupState: state.group,
    CountryState: state.country,
    DynamicCategoryState: state.dynamiccategory,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ListCountry: AC_LIST_COUNTRY, RestHotelList: AC_EMPTY_HOTEL_LIST, HandleInputChange: AC_HANDLE_HOTEL_CHANGE, ListGroup: AC_LIST_GROUP, AddHotel: AC_ADD_HOTEL, ListHotel: AC_LIST_HOTEL_DYNAMIC_CATEGORY, ViewHotel: AC_VIEW_HOTEL, ResetHotel: AC_RESET_HOTEL, UpdateHotel: AC_UPDATE_HOTEL, DeleteHotel: AC_DELETE_HOTEL, EmptyHotel: AC_EMPTY_HOTEL }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ListEmployee);

const initialState = {
	listIncidentTicket: [],
	listfilter: [],
	spinner: true,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'HANDLE_TICKET_KEY_SEARCH':
			return Object.assign({}, state, {
				listIncidentTicket: initialState.listIncidentTicket
			})
		default:
			return state;
	}
}

import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_GET_LANDING_DATA() {
    return function (dispatch) {
        return axios.get(ImportedURL.API.getLandingData)
            .then((res) => {
                dispatch({ type: "GET_LANDING_DATA", payload: res.data })
                localStorage.setItem('adminLandingData', JSON.stringify(res.data));
            }).catch((err) => { console.log(err); });
    };
}

export function AC_GET_HISTORY_DATA(date) {
    let newdate = date ? date : '';
    return function (dispatch) {
        return axios.get(ImportedURL.API.getHistory + "?lastFetchedDate=" + newdate)
            .then((res) => {
                if (res.data.lastDateToFetch && res.data.uniqueData.length > 0) {
                    localStorage.setItem('lastDateToFetch', res.data.lastDateToFetch);
                }
                const currentData = JSON.parse(localStorage.getItem('historyData')) || [];
                const newData = res.data.uniqueData;
                // Compare newData with currentData and filter out the new records
                const updatedData = newData.filter(item =>
                    !currentData.some(currentItem => currentItem.id === item.id)
                );
                // If there are new records, update local storage and Redux store
                if (updatedData.length > 0) {
                    const combinedData = [...currentData, ...updatedData];
                    localStorage.setItem('historyData', JSON.stringify(combinedData));
                    dispatch({ type: "GET_HISTORY_DATA", payload: combinedData });
                } else {
                    // If no new records, just dispatch the existing data
                    dispatch({ type: "GET_HISTORY_DATA", payload: currentData });
                }
            }).catch((err) => { console.log(err); });
    };
}

export function AC_GET_LANGUAGE_DATA() {
    return function (dispatch) {
        return axios.get(ImportedURL.API.getLanguageData)
            .then((res) => {
                dispatch({ type: "GET_LANGUAGE_DATA", payload: res.data })
                localStorage.setItem('languageData', JSON.stringify(res.data));
            }).catch((err) => { console.log(err); });
    };
}


export function AC_PUSH_DATA_LANDING(data) {
    return function (dispatch) {
        dispatch({ type: "PUSH_DATA_LANDING", payload: data })
    };
}



import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { AC_EMPTY_PROBLEM_KEY_WORD, AC_HANDLE_PROBLEM_KEY_WORD_CHANGE, AC_LIST_PROBLEM_KEY_WORD, AC_RESET_PROBLEM_KEY_WORD, AC_VIEW_PROBLEM_KEY_WORD, } from '../../actions/problemKeyWordAction';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import Select from 'react-select';
import jsPDF from 'jspdf';
import { AC_LIST_CATEGORY } from '../../actions/categoryAction';
import Spinner from 'react-bootstrap/Spinner';
import { Link, Redirect } from 'react-router-dom';
import { imageCellRendererList, onErrorImage } from '../../common/validate';
import ErrorResponse from '../../utilities/Error/errorstatus';
import showSwalDialog from '../../utilities/Modal/swal';



const initialState = {
    modalType: "Add",
    columnApi: '',
    gridApi: '',
    columnDefs: [
        {
            headerName: 'Logo', width: 100, field: 'logo', hide: true, sortable: false, filter: false, "resizable": false, cellStyle: { 'text-align': 'center' },
            cellRenderer: imageCellRendererList,
        },
        {
            headerName: 'Hotel', field: 'hotelName', hide: true, width: 130, floatingFilter: true, cellStyle: { 'text-align': 'left' },
            cellRenderer: function (params) {
                if (params.data.hotelName) {
                    return params.data.hotelName;
                } else {
                    return "All"
                }
            }
        },
        { headerName: 'Key Word Type', field: 'type', width: 130, floatingFilter: true },
        { headerName: 'Problem Keyword', field: 'problemkeyword', width: 200, floatingFilter: true },
        // {
        //     headerName: 'Status', field: 'status', width: 170, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true,
        //     cellRenderer: function (params) {
        //         if (params.data.status) {
        //             return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
        //         } else {
        //             return '<span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span>';
        //         }
        //     }
        // },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                if (params.value) {
                    return params.value;
                } else {
                    return "---"
                }
            }
        },

    ],
    defaultColumDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
    },
    amenityError: false,
    roomnameError: false,
    id: '',
    initial: true,
    saving: false,
    editSpi: false,
    searchhotel: '',
    searchhoteloption: '',
    hotelName: '',
    hideReset: false,
    columnreset: true

}

class ListProblemKeyWord extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    onRowClicked = event => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        // this.props.EmptyProblemKeyWord();
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'View') {
            this.props.ViewProblemKeyWord(event.data._id);
        }
        if (value === 'Edit') {
            this.setState({ modalType: "Edit", id: event.data._id });
        }
        if (value === 'Delete') {
            showSwalDialog('Are you sure you want to delete?', '', 'Ok', 'Cancel', 'assets/images/delete.png').then((result) => {
                if (result.isConfirmed) {
                    this.setState({ searchhoteloption:'', searchhotel: '' })
                    axios.get(ImportedURL.API.softDeleteProblemKeyWord + '/' + event.data._id)
                        .then((res) => {
                            Success((languageData && languageData.PROBLEM_KEYWORD ? languageData.PROBLEM_KEYWORD : 'Problem Keyword') + (languageData && languageData.DELETED_SUCCESSFULLY ? languageData.DELETED_SUCCESSFULLY : ' deleted successfully'));
                            this.props.ListProblemKeyWord();
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
        if (value === 'Status') {
            showSwalDialog('Are you sure you want to change the status?', '', 'Ok', 'Cancel', 'assets/images/status.png').then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'problemkeywords' })
                        .then((data) => {
                            this.setState({ searchhoteloption: '', searchhotel: '' })
                            this.props.ListProblemKeyWord();
                            Success((languageData && languageData.STATUS ? languageData.STATUS : ' Status') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : "updated successfully"))
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const role = this.props.AccountState.role;
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role) && this.state.columnreset) {
            let updatedColumnDefs = [...this.state.columnDefs];
            updatedColumnDefs = updatedColumnDefs.map((col) => {
                if (col.field === 'hotelName' || col.field === 'logo') {
                    return { ...col, hide: false };
                }
                return col;
            });
            this.gridApi.setColumnDefs(updatedColumnDefs);
            this.setState({ columnreset: false })
        }
    }
    componentDidMount() {
        this.props.ListProblemKeyWord();
        this.props.EmptyProblemKeyWord();
        const account = this.props.AccountState.account;
        this.setState({ hotelName: account.hotelName ? account.hotelName : '' });
    }

    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }
    exportToCSV = () => {
        const account = this.props.AccountState.account;
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: ['hotelName', 'type', 'problemkeyword'],
            fileName: 'problemkeyword' + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                return params.value ? params.value : "---";
            }
        })
    }
    exportPDF = () => {
        const account = this.props.AccountState.account;
        const api = this.gridApi;
        const filteredRows = api.getModel().rowsToDisplay
        const exportData = filteredRows.map(rowNode => rowNode.data);
        const selectedProperties = (this.state.hotelName && this.state.hotelName != "All") ? ['type', 'problemkeyword'] : ['hotelName', 'type', 'problemkeyword'];
        const selected = exportData.map(item => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    selectedData[property] = item[property] ? item[property] : "---";
                }
            });
            return selectedData;
        });
        let data = {
            title: (this.state.hotelName) ? (this.state.hotelName != "All" ? this.state.hotelName + " - Message Templates List" : "Message Templates List") : "Message Templates List",
            head: (this.state.hotelName && this.state.hotelName != "All") ? ['#', 'Keyword Type', 'Problem Keyword'] : ['#', 'Hotel', 'Keyword Type', 'Problem Keyword'],
            body: selected,
            hotelNameExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hotelNameExport : '',
            hoteladdressExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "problemkeyword" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }

    searchUser = e => {
        const { name, value, label } = e;
        this.props.ListProblemKeyWord({ hotel: value });
        this.setState({ searchhoteloption: e, searchhotel: value, hotelName: label, hideReset: true })
    }
    filterReset = e => {
        this.setState({ selectedOption: '', searchhoteloption: '', hotel: '', hotelName: '' });
        this.props.ListProblemKeyWord();
        this.setState({ hideReset: false })
    }
    handleFilterChanged = () => {
        const api = this.gridApi;
        if (api && api.getDisplayedRowCount() === 0) {
            api.showNoRowsOverlay();
            this.setState({ hideExport: true })
        } else {
            api.hideOverlay();
            this.setState({ hideExport: false })
        }
    };
    render() {

        const { ProblemKeyWordState, AccountState, LandingState } = this.props;
        const listHotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[0] : [];;
        const account = AccountState.account;
        const languageData = AccountState.account.SelectedlanguageData
        const rowData = ProblemKeyWordState.listproblemkeyword;
        const ViewData = ProblemKeyWordState.areaamenity;
        const spinner = ProblemKeyWordState.spinner;
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Problem_Keyword" });
        const deleteOption = ImportedURL.SUPERADMIN.includes(AccountState.role) ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : '';
        const editOption = Previlege?.edit ? '<button title="Edit" type="button" class="btn btn-icon" data-action-type="Edit" ><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';


        if (rowData) {
            rowData.forEach(object => {
                object.actions = editOption + deleteOption;
            });
        }

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]

        var hotelOptionSearch = [];
        if (listHotels.length > 1) {
            hotelOptionSearch.push({ label: 'All', value: 'All', name: 'searchhotel' })
        }
        if (listHotels.length > 0) {
            listHotels.filter(filterItem => filterItem.status === true).map(item => {
                hotelOptionSearch.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'searchhotel' });
            })
        }

        if (this.state.modalType === "Edit") return <Redirect to={'/edit-problem-keyword/' + this.state.id} />

        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-problem-keyword"><h6 className="highlights_breadcrump"> {languageData && languageData.PROBLEM_KEYWORD ? languageData.PROBLEM_KEYWORD : "Problem Keyword"}{languageData && languageData.LIST ? languageData.LIST : ' List'}</h6></Link></p>
                        </div>
                        <div className="section-body pt-3 user_sec">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header tit_res">
                                                <div className='d-flex'>
                                                    <div className='rounded_icon'><i className="fa fa-puzzle-piece mr-2 "></i></div>
                                                    <div className='d-flex align-items-center'>
                                                        <h3 className="card-title">{languageData && languageData.PROBLEM_KEYWORD ? languageData.PROBLEM_KEYWORD : "Problem Keyword"}{languageData && languageData.LIST ? languageData.LIST : ' List'}</h3>
                                                    </div>
                                                </div>

                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="header-action" >
                                                            {Previlege?.add ? <Link to='/add-problem-keyword'><button type="button" className="btn btn-primary" id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button></Link> : ''}
                                                        </div>
                                                    </div>
                                                    {rowData && rowData.length > 0 && !this.state.hideExport ?
                                                        <>
                                                            <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                            <div className="dropdown-menu">
                                                                <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                            </div>
                                                        </> : ''}
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                        <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        account?.allhotels == undefined &&
                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-xl-6">
                                                            <label className="form-label">{languageData && languageData.SEARCH ? languageData.SEARCH : "Search"}  {languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel"}</label>
                                                            <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                <Select
                                                                    value={this.state.searchhoteloption}
                                                                    onChange={this.searchUser}
                                                                    options={hotelOptionSearch}
                                                                    formatOptionLabel={(e) => (
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                            <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.hideReset ?
                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-xl-3 d-flex" style={{ marginTop: '24px' }}>
                                                            <button className='btn social_rest_btn btn-primarys' type='button' onClick={this.filterReset}><i className="fa fa-refresh" aria-hidden="true" style={{ fontSize: '20px !importent', marginRight: '7px' }}></i> Reset</button>
                                                        </div> : ''}
                                                </div>
                                                <div className="table-responsive">
                                                    <div className="ag-theme-alpine">
                                                        <AgGridReact
                                                            rowHeight={82}
                                                            paginationPageSize={25}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            rowData={rowData}
                                                            columnDefs={this.state.columnDefs}
                                                            rowDragManaged={true}
                                                            animateRows={true}
                                                            onGridReady={this.onGridReady}
                                                            overlayNoRowsTemplate={"No rows to display"}
                                                            onFilterChanged={this.handleFilterChanged}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                    {spinner ?
                                                        <div className='common_loader_ag_grid'>
                                                            <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                            </Spinner>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    RoomAreaState: state.roomarea,
    ProblemKeyWordState: state.problemkeyword,
    AccountState: state.account,
    LandingState: state.landing,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleInputChange: AC_HANDLE_PROBLEM_KEY_WORD_CHANGE, ListProblemKeyWord: AC_LIST_PROBLEM_KEY_WORD, ViewProblemKeyWord: AC_VIEW_PROBLEM_KEY_WORD, ResetProblemKeyWord: AC_RESET_PROBLEM_KEY_WORD
        , ListCategory: AC_LIST_CATEGORY, EmptyProblemKeyWord: AC_EMPTY_PROBLEM_KEY_WORD,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListProblemKeyWord);
import axios from 'axios';
import React, { Component } from 'react'
import { Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { AC_VIEW_ROOMAREA_AMENITY, AC_EMPTY_ROOMAREA_AMENITY, AC_LIST_ROOMAREA_AMENITY, AC_HANDLE_ROOMAREA_AMENITY_CHANGE } from '../../actions/roomareaAmenityAction';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import { Imagevalidation, onErrorImage } from '../../common/validate';




class AddRoomareaAmenity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: 1,
            amenity: [],
            itemsError: false,
            hotelError: false,
            removeItem: -1,
            modalType: "Add",
            listRedirect: false,
            saving: false,
            initial: true,
            editSpi: false,
            allOptionUser: true,


            icon: '',
            iconSrc: '',
        }
    }

    componentWillUpdate(nextProps) {
        const newaccount = nextProps.AccountState.account;
        if (this.state.initial) {
            if (!ImportedURL.SUPERADMIN.includes(nextProps.AccountState.role)) {
                this.props.HandleChange('hotel', newaccount.hotel)
                this.setState({ owner: true });
            } else {
                this.props.HandleChange('hotel', 'All')
            }
            this.setState({ initial: false })
        }
    }

    componentDidMount() {
        this.props.ListRoomareaAmenity();
        this.props.ListHotel();
        this.props.EmptyRoomareaAmenity();
        const { params, path } = this.props.match;
        if (params.id) {
            this.props.ViewRoomareaAmenity(params.id);
            this.setState({ modalType: path === "/view-roomarea-amenity/:id" ? "View" : "Edit", id: params.id, editSpi: true })
        }
    }

    onChange = (e) => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false })
        this.props.HandleChange(name, value);
    }

    HandleChangeHotel = (e) => {
        const { name, value } = e;
        this.props.HandleChange('hotel', value)
        this.setState({ hotelError: false })

    }
    onSubmit = (e) => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const data = this.props.RoomareaAmenityState.roomareaamenity;
        let isValid = true;
        if (!data.hotel) {
            this.setState({ hotelError: true })
            isValid = false;
        }
        data['amenity'] = data.amenity.trim()
        if (!data.amenity) {
            this.setState({ amenityError: true })
            isValid = false;
        }
        if (!data.icon) {
            this.setState({ iconError: true });
            isValid = false;
        }
        if (this.state.invalidFileError) {
            isValid = false;
        }
        if (!data.type) {
            this.setState({ typeError: true })
            isValid = false;
        }
        var formData = new FormData();
        formData.append("hotel", data.hotel)
        formData.append("amenity", data.amenity)
        formData.append("icon", data.icon)
        formData.append("type", data.type)
        if (isValid) {
            if (this.state.modalType == "Add") {
                this.setState({ saving: true })
                axios.post(ImportedURL.API.addRoomareaAmenity, formData)
                    .then((res) => {
                        this.props.ListRoomareaAmenity();
                        this.props.EmptyRoomareaAmenity();
                        Success((languageData && languageData.ROOM_AMENITY ? languageData.ROOM_AMENITY : "Room Amenity") + (languageData && languageData.CREATED_SUCCESSFULLY ? languageData.CREATED_SUCCESSFULLY : "created successfully"));
                        this.setState({ saving: false })
                        this.setState({ listRedirect: true })
                    }).catch(({ response }) => {
                        if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else if (response.status == 400) {
                            Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                        this.setState({ saving: false })
                    });
            } else {
                this.setState({ saving: true })
                this.setState({ editSpi: true })
                axios.post(ImportedURL.API.updateRoomareaAmenity + '/' + data._id, formData)
                    .then((res) => {
                        this.props.ListRoomareaAmenity();
                        this.props.EmptyRoomareaAmenity();
                        Success((languageData && languageData.ROOM_AMENITY ? languageData.ROOM_AMENITY : "Room Amenity") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : "updated successfully"));
                        this.setState({ saving: false })
                        this.setState({ listRedirect: true })
                    }).catch(({ response }) => {
                        if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else if (response.status == 400) {
                            Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                        this.setState({ saving: false })
                    });
            }
        }

    }


    onChangeIcon = (e) => {
        this.setState({ iconSrc: '' })
        const imgvalidate = Imagevalidation(e.target.files[0]);
        if (imgvalidate) {
            this.setState({ iconError: false, invalidFileError: false })
            this.setState({ icon: e.target.files[0], validImages: false });
            var reader = new FileReader();
            var url = reader.readAsDataURL(e.target.files[0]);
            reader.onloadend = function (e) {
                this.setState({
                    iconSrc: [reader.result]
                })
            }.bind(this);
            this.props.HandleChange('icon', e.target.files[0]);
        } else {
            this.setState({ invalidFileError: true, iconError: false, validImages: true })
            this.props.HandleChange('icon', '');
        }
    }
    // HandleChangeLogo = (e) => {
    //     const { name, value } = e.target;
    //     if (name == 'icon') {
    //         this.setState({ logoSrc: '' })
    //         const imgvalidate = Imagevalidation(e.target.files[0]);
    //         if (imgvalidate) {
    //             this.setState({ iconError: false, invalidFileError: false })
    //             this.setState({ icon: e.target.files[0], validImages: false });
    //             var reader = new FileReader();
    //             var url = reader.readAsDataURL(e.target.files[0]);
    //             reader.onloadend = function (e) {
    //                 this.setState({
    //                     logoSrc: [reader.result]
    //                 })
    //             }.bind(this);
    //             this.props.HandleChange('icon', e.target.files[0]);
    //         } else {
    //             this.setState({ invalidFileError: true, iconError: false, validImages: true })
    //             this.props.HandleChange('icon', '');
    //         }
    //     }
    // }
    AddSelectHandleChange = e => {
        const { name, value, label } = e;
        const Error = name + "Error";
        if (name == 'type') {
            this.setState({ [Error]: false })
            this.props.HandleChange(name, value);
        }
    }

    render() {
        const { AccountState, RoomareaAmenityState, HotelState } = this.props;
        if (this.state.listRedirect) return <Redirect to={'/list-roomarea-amenity'} />
        const languageData = AccountState.account.SelectedlanguageData
        const account = AccountState.account;
        const data = RoomareaAmenityState.roomareaamenity;
        const dataSpinner = RoomareaAmenityState.spinner;
        const listHotelData = HotelState.listhotels;

        var selectedHotel = '';
        const hotelOptions = [];
        if (this.state.allOptionUser) {
            hotelOptions.push({ label: "All", value: 'All', name: 'hotel' })
        }
        if (account?.allhotels?.length > 0) {
            account.allhotels.map((item) => {
                if (account.hotel == item.hotelid) {
                    selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                }
                hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo });
            })
        } else {
            listHotelData.filter(e => e.status === true).map((item) => {
                if (data?.hotel == item._id) {
                    selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                }
                hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
            })
        }

        const Type = [
            { value: 'Room Type', label: 'Room Type', name: 'type' },
            { value: 'View', label: 'View', name: 'type' },
            { value: 'Bathroom', label: 'Bathroom', name: 'type' },
            { value: 'Bedroom', label: 'Bedroom', name: 'type' },
            { value: 'Kitchen', label: 'Kitchen', name: 'type' },
            { value: 'Tv,Internet,Video', label: 'Tv,Internet,Video', name: 'type' },
            { value: 'Electrical Appliances', label: 'Electrical Appliances', name: 'type' },
            { value: 'Floor', label: 'Floor', name: 'type' },
            { value: 'Accessibility', label: 'Accessibility', name: 'type' },
            { value: 'Outdoors', label: 'Outdoors', name: 'type' },
        ]
        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-roomarea-amenity"><h6>{languageData && languageData.ROOM_AMENITIES ? languageData.ROOM_AMENITIES : "Room Amenities"}{languageData && languageData.LIST ? languageData.LIST : ' List'} </h6></Link><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>{this.state.modalType} {languageData && languageData.ROOM_AMENITY ? languageData.ROOM_AMENITY : "Room Amenity"}</h6> </p>
                    </div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade show active"
                                    id="Departments-list"
                                    role="tabpanel"
                                >
                                    <div className="card">
                                        <div className="card-body border-0">
                                            <div className='title_mt'>
                                                <div className='d-flex'>
                                                    <div className='rounded_icon'><i className="fa fa-modx mr-2 "></i></div>
                                                    <div className="d-flex align-items-center ">
                                                        <h3 className="card-title">{this.state.modalType} {languageData && languageData.ROOM_AMENITY ? languageData.ROOM_AMENITY : "Room Amenity"}</h3>
                                                    </div>
                                                </div>
                                                <div className="header-action">
                                                    <Link to='/list-roomarea-amenity'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body" style={{ padding: "20px 20px" }}>
                                            <form id='form_reset'>
                                                <Row>
                                                    {ImportedURL.SUPERADMIN.includes(account.role) ?
                                                        <div className='col-sm-4 col-md-12'>
                                                            <div className="form-group">
                                                                <label className="form-label">Hotel<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                                <Select
                                                                    value={selectedHotel != '' ? selectedHotel : { label: 'All' }}
                                                                    onChange={this.HandleChangeHotel}
                                                                    options={hotelOptions}
                                                                    formatOptionLabel={(e) => (
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                            <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                        </div>
                                                                    )}
                                                                    isDisabled={this.state.modalType == "View"}
                                                                />
                                                                {ImportedURL.SUPERADMIN.includes(account.role) ? <div className="invalid-feedback" style={{ display: this.state.hotelError ? "block" : 'none' }}>Hotel is required</div> : ''}
                                                            </div>
                                                        </div>
                                                        : ''}
                                                    <div className='col-sm-4 col-md-6'>
                                                        <div className="form-group">
                                                            <label className="form-label">Type<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <Select
                                                                value={data.type && { label: data.type }}
                                                                name='type'
                                                                onChange={this.AddSelectHandleChange}
                                                                options={Type} />
                                                            <div className="invalid-feedback" style={{ display: this.state.typeError ? "block" : 'none' }}>Type is required</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <label className="form-label">Room Amenity<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                        <input type="text" className='form-control' name="amenity" value={data.amenity} onChange={this.onChange} placeholder={"Room Amenity"} />
                                                        <div className="invalid-feedback" style={{ display: this.state.amenityError ? 'block' : 'none' }}>Room Amenity is required</div>
                                                    </div>
                                                    <div className="col-sm-6 col-md-6">
                                                        <label className="form-label">Amenity Icon<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                        <input type="file" accept="image/*" className="form-control" placeholder="Icon" name='icon' onChange={this.onChangeIcon} id="imageSet" />
                                                        <div className="invalid-feedback" style={{ display: this.state.iconError ? "block" : 'none' }}>Amenity Icon is required</div>
                                                        <div className="invalid-feedback" style={{ display: this.state.invalidFileError ? "block" : 'none' }}>Invalid File</div>
                                                        {this.state.iconSrc != '' || data.icon ?
                                                            <div className="form-group mt-2">
                                                                {this.state.iconSrc ? <img src={this.state.iconSrc} width="100px" alt='' onError={onErrorImage} /> : <img src={data.icon ? ImportedURL.FILEURL + data.icon : ''} width="100px" alt='' onError={onErrorImage} />}
                                                            </div>
                                                            : ''}
                                                    </div>
                                                    {this.state.modalType == "Edit" ? <div className="card-footer text-right mandatory mt-4 px-2">
                                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                        <div className="text-right">
                                                            {
                                                                this.state.saving ?
                                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                                    :
                                                                    <button type="button" className="btn commor_save" onClick={this.onSubmit}>
                                                                        <i className="fe fe-save mr-2"></i>Update
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div> : <div className="card-footer text-right mandatory mt-4 px-2">
                                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                        <div className="text-right">
                                                            {
                                                                this.state.saving ?
                                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                                    :
                                                                    <button type="button" className="btn commor_save" onClick={this.onSubmit}>
                                                                        <i className="fe fe-save mr-2"></i>Save
                                                                    </button>
                                                            }
                                                        </div>
                                                    </div>}

                                                </Row>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="overlay" style={{ display: this.state.modalType != "Add" && dataSpinner ? 'block' : 'none' }}></div>
                    {
                        (this.state.modalType != "Add") && (dataSpinner &&
                            <div className='common_loader'>
                                <img className='loader_img_style_common' src='../../assets/images/load.png' />
                                <Spinner className='spinner_load_common' animation="border" variant="info" >
                                </Spinner>
                            </div>
                        )
                    }
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    RoomareaAmenityState: state.roomareaamenity,
    AccountState: state.account,
    HotelState: state.hotel,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleChange: AC_HANDLE_ROOMAREA_AMENITY_CHANGE,
        ViewRoomareaAmenity: AC_VIEW_ROOMAREA_AMENITY,
        EmptyRoomareaAmenity: AC_EMPTY_ROOMAREA_AMENITY,
        ListHotel: AC_LIST_HOTEL,
        ListRoomareaAmenity: AC_LIST_ROOMAREA_AMENITY,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRoomareaAmenity);
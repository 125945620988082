const initialState = {
	status: false,
	feedbacks: [],
	spinner: true,
	reviewByClient: [],
	listfilter: [],
	reviewClientKeyword: [],
	spin: true,
	lengthofsentimentdata: 0,
	tabposition: 'noncomment',
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'SEND_REVIEW':
			return Object.assign({}, state, {
				status: action.payload
			})
		case 'LIST_REVIEW':
			return Object.assign({}, state, {
				feedbacks: action.payload,
				spinner: false,
			})
		case 'RESET_ROOMTYPE':
			return Object.assign({}, state, {
				spinner: true
			})
		case 'GET_REVIEW_BY_ID': {
			let val = {
				reviewByClient: action.payload,
				listfilter: action.payload,
				spin: false,
			}
			if (action.gettype == 'initial')
				val['lengthofsentimentdata'] = action.payload.length
			return Object.assign({}, state, val)
		}
		case 'REVIEW_KEYWORD':
			return Object.assign({}, state, {
				reviewClientKeyword: action.payload,
				listfilter: action.payload,
				spin: false,
			})
		case 'REVIEW_KEYWORD_EMPTY':
			return Object.assign({}, state, {
				reviewClientKeyword: [],
				listfilter: [],
				spin: true,
			})
		case 'EMPTY_SPIN':
			return Object.assign({}, state, {
				spin: true,
			})
		case 'HANDLE_REVIEW_KEY_SEARCH':
			return Object.assign({}, state, {
				reviewByClient: action.value
			})
		case 'LIST_FEEDBACK_TAB_CHANGES':
			return Object.assign({}, state, {
				tabposition: action.value
			})
		default:
			return state;
	}
}
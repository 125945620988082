import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_SETTING_ROOMTYPE_HANDLE_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "SETTING_ROOMTYPE_HANDLE_CHANGE", name: name, value: value })
	};
}
export function AC_SETTING_ROOMTYPE_HANDLE_FORM_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "SETTING_ROOMTYPE_HANDLE_FORM_CHANGE", name: name, value: value })
	};
}

export function AC_RESET_SETTING_ROOMTYPE() {
	return function (dispatch) {
		dispatch({ type: "RESET_SETTING_ROOMTYPE" })
	};
}


export function AC_LIST_SETTING_ROOMTYPE(params = {}) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.listSettingRoomtype, params)
			.then((res) => {
				dispatch({ type: "LIST_SETTING_ROOMTYPE", payload: res.data.data, total: res.data.total, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_VIEW_SETTING_ROOMTYPE(data) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewSettingRoomtype + "/" + data)
			.then((res) => {
				dispatch({ type: "VIEW_SETTING_ROOMTYPE", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_HANDLE_AMENITY_REMOVE(id) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_AMENITY_REMOVE", id: id })
	};
}
export function AC_HANDLE_FACILITY_REMOVE(id) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_FACILITY_REMOVE", id: id })
	};
}

export function AC_HANDLE_AMENITY_CLICK(item, i, status) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_AMENITY_CLICK", name: item, i: i, status: status })
	};
}
export function AC_HANDLE_MULTIROW_CLICK(name, value, i) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_MULTIROW_CLICK", name: name, value: value, i: i })
	};
}




export function AC_HANDLE_ROOMTYPEBED_UPDATE(name, value, id) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_ROOMTYPEBED_UPDATE", name: name, value: value, id: id })
	};
}

export function AC_EMPTY_ROOMTYPE() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_ROOMTYPE" })
	}
}

// --------------------------------checkbox
export function AC_CHECKBOX_CHANGE(value) {
	return function (dispatch) {
		dispatch({ type: "CHECKBOX_CHANGE", value: value });
	};
}


export function AC_SPINNER_ROOMTYPE_ENQUIRY() {
	return function (dispatch) {
		dispatch({ type: "SPINNER_ROOMTYPE_ENQUIRY" })
	};
}


export function AC_LIST_ROOMTYPE_ENQUIRY(params = {}) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.listRoomTypeEnquiry, params)
			.then((res) => {
				dispatch({ type: "LIST_ROOMTYPE_ENQUIRY", payload: res.data.data, total: res.data.total, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_VIEW_ROOMTYPE_ENQUIRY(data) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewRoomTypeEnquiry + "/" + data)
			.then((res) => {
				dispatch({ type: "VIEW_ROOMTYPE_ENQUIRY", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

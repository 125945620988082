import axios from 'axios';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import ImportedUrl from '../../../common/api';
import { Error, Success } from '../../../common/swal';
import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { onErrorImage } from '../../../common/validate';

class ViewIncidentTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ticket: {},
            listusers: [],
            listComments: [],
            selectAssignee: false,
            comment: '',
            history: {},
            spinner: true,
            nxpvspinner: false,
            indexOfImagesWsa: 0,
            showModalWsa: false,

            indexOfImagesWca: 0,
            showModalWca: false,

            indexOfVideosWca: 0,
            showVideosModalWca: false
        }
    }

    setAssignee = (e) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (e.target.value) {
            if (this.state.ticket && e.target.value == this.state.ticket.assigneeid) return;
            const ticketid = this.props.match.params.ticketid;


            const formData = {
                status: 'assigned',
                ticketId: ticketid,
                assignee: e.target.value,
            }
            axios.post(ImportedUrl.API.assignTaskerToTicket, formData)
                .then((res) => {
                    this.setState({ comment: '' });
                    Success((languageData && languageData.COMMENT_ADDED_SUCCESSFULLY ? languageData.COMMENT_ADDED_SUCCESSFULLY : " Comment added successfully"))
                    axios.get(ImportedUrl.API.listComments + "/" + ticketid)
                        .then((res) => {
                            Success((languageData && languageData.TICKET_ASSIGNED_TO_USER ? languageData.TICKET_ASSIGNED_TO_USER : " Ticket assigned to user"));
                            axios.get(ImportedUrl.API.viewTicket + "/" + ticketid)
                                .then((res) => {
                                    this.setState({ ticket: res.data, selectAssignee: false })
                                }).catch(({ response }) => {
                                    if (response.status == 500) {
                                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                                    } else if (response.status == 502) {
                                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                                    } else {
                                        Error(response.statusMessage)
                                    }
                                });
                            // this.setState({ listComments: res.data })
                        }).catch(({ response }) => { Error(response.statusText) });
                }).catch(({ response }) => { Error(response.statusText) });
        } else {
            Error((languageData && languageData.SELECT_AN_USER ? languageData.SELECT_AN_USER : " Select an user"));
        }
    }

    submitComment = () => {
        const ticketid = this.props.match.params.ticketid;
        const { AccountState } = this.props;
        const formData = {
            comment: this.state.comment,
            ticket: this.state.ticket ? this.state.ticket._id : '',
        }
        const languageData = this.props.AccountState.account.SelectedlanguageData
        axios.post(ImportedUrl.API.addComment, formData)
            .then((res) => {
                this.setState({ comment: '' });
                Success((languageData && languageData.COMMENT_ADDED_SUCCESSFULLY ? languageData.COMMENT_ADDED_SUCCESSFULLY : " Comment added successfully"))
                axios.get(ImportedUrl.API.listComments + "/" + ticketid)
                    .then((res) => {
                        this.setState({ listComments: res.data })
                    }).catch(({ response }) => { Error(response.statusText) });
            }).catch(({ response }) => {
                if (response.status == 400) {
                    Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                } else if (response.status == 500) {
                    Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                } else if (response.status == 502) {
                    Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                } else {
                    Error(response.statusMessage)
                }
            });
    }

    onPreviousTicket = (id) => {
        const searchParams = new URLSearchParams(window.location.search.substring(1));
        let params = this.searchUrlParams()

        const ticketid = id;

        this.setState({
            nxpvspinner: true
        })
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (searchParams) {
            this.props.history.push('/view-incident-ticket/' + ticketid + "?" + searchParams);
        } else {
            this.props.history.push('/view-incident-ticket/' + ticketid)
        }
        axios.get(ImportedUrl.API.viewIncidentTicket + "/" + ticketid, { params: params })
            .then((res) => {
                this.setState({
                    ticket: res.data ? res.data : {},
                    nxpvspinner: false

                });

            }).catch(({ response }) => {
                if (response.status == 500) {
                    Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                } else if (response.status == 502) {
                    Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                } else {
                    Error(response.statusMessage)
                }
            });
        //this.setState({ modalType: "View" })

    }
    onNextTicket = (id) => {
        const searchParams = new URLSearchParams(window.location.search.substring(1));
        let params = this.searchUrlParams()
        const ticketid = id;
        this.setState({
            nxpvspinner: true
        })
        if (searchParams) {
            this.props.history.push('/view-incident-ticket/' + ticketid + "?" + searchParams);
        } else {
            this.props.history.push('/view-incident-ticket/' + ticketid)
        }
        axios.get(ImportedUrl.API.viewIncidentTicket + "/" + ticketid, { params: params })
            .then((res) => {
                this.setState({
                    ticket: res.data ? res.data : {},
                    nxpvspinner: false
                });
            }).catch(({ response }) => { Error(response.statusText) });
    }
    openModalAndSetIndexWsa = (index) => {
        this.setState({ indexOfImagesWsa: index, showModalWsa: true })
    }
    openModalAndSetIndexWca = (index) => {
        this.setState({ indexOfImagesWca: index, showModalWca: true })
    }
    openVedioModelWsa = (index) => {
        this.setState({ indexOfVideosWca: index, showVideosModalWca: true })
    }
    searchUrlParams = () => {
        const searchParams = new URLSearchParams(window.location.search.substring(1));
        const fromdate = searchParams.get('fromdate') ? decodeURIComponent(searchParams.get('fromdate')) : '';
        const todate = searchParams.get('todate') ? decodeURIComponent(searchParams.get('todate')) : '';
        const hotel = searchParams.get('hotel') ? decodeURIComponent(searchParams.get('hotel')) : '';
        const group = searchParams.get('group') ? decodeURIComponent(searchParams.get('group')) : '';
        let params = {}
        if (fromdate) params["fromdate"] = fromdate
        if (hotel) params["hotel"] = [hotel]
        if (todate) params["todate"] = todate
        if (group) params["group"] = group
        return params;
    }
    componentDidMount() {
        let params = this.searchUrlParams()
        const ticketid = this.props.match.params.ticketid;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (ticketid) {
            axios.get(ImportedUrl.API.viewIncidentTicket + "/" + ticketid, { params: params })
                .then((res) => {
                    this.setState({
                        ticket: res.data ? res.data : {},
                    });
                    this.setState({ spinner: false })
                }).catch(({ response }) => {
                    if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else {
                        Error(response.statusMessage)
                    }
                });
        }
        //default scroll top the page
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    componentDidUpdate(prevProps) {
        const currentTicketId = this.props.match.params.ticketid;
        const prevTicketId = prevProps.match.params.ticketid;
        if (currentTicketId !== prevTicketId && !this.state.prevnext) {
            this.onNextTicket(currentTicketId);
        }
    }
    render() {

        //light box -------- >
        const { indexOfImagesWsa, showModalWsa, indexOfImagesWca, showModalWca, indexOfVideosWca, showVideosModalWca } = this.state;

        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const role = AccountState.account ? AccountState.account.role : '';
        const { ticket, listusers, listComments, history } = this.state;
        var historyData = history.history
        const logo = AccountState.account.logo;
        const data = typeof logo == 'string' ? logo : '';
        let nextTicket = ticket.next;
        let previousTicket = ticket.previous;

        const photos = ticket.images ? ticket.images.map((image, index) =>
            <div className="col-4" key={index}>
                <div style={{ height: '200px', cursor: 'pointer' }} onClick={() => this.openModalAndSetIndexWsa(index)}>
                    <img className="d-block img-fluid rounded w-100" src={ImportedUrl.FILEURL + image} alt="image" width='100%' style={{ objectFit: 'cover', height: '100%' }} onError={onErrorImage} />
                    <span className="link-overlay-bg rounded"><i className="fa fa-search" /></span>
                </div>
                {showModalWsa && (
                    <div className='react-image-video-lightbox'>
                        <ReactImageVideoLightbox
                            data={ticket.images.map((item) => {
                                return ({ url: ImportedUrl.FILEURL + item, type: "photo", altTag: "No image to display" })
                            })}
                            startIndex={indexOfImagesWsa}
                            showResourceCount={true}
                            onCloseCallback={() => this.setState({ showModalWsa: false })}
                        />
                    </div>
                )}
            </div>
        ) : [];

        const audios = ticket.audios ? ticket.audios.map((audio, item) => <audio
            id="audio"
            src={ImportedUrl.FILEURL + audio}
            type="video/mp4"
            controls
            key={item}
        ></audio>) : [];

        const vedioWsa = [];
        const videos = ticket.videos ? ticket.videos.map((video, index) => {
            vedioWsa.push({
                index: index,
                url: ImportedUrl.FILEURL + video,
                type: "video",
                title: "some video",
            })
            return (
                <>
                    <div className="col-4">
                        <div className='vedio_bg' style={{ height: '200px', width: '100%', position: 'relative' }}>
                            <video
                                muted
                                id="video"
                                src={ImportedUrl.FILEURL + video}
                                type="video/mp4"
                                width='100%'
                                key={index}
                                height='100%'
                            ></video>
                            <div className='vedio_overlay' onClick={() => this.openVedioModelWsa(index)}></div>
                            <div className='play_is'>
                                <span className='vedio_play_icon'><i className="fa fa-play" data-toggle="tooltip" title="fa fa-play-circle"></i></span>
                            </div>
                        </div>
                        <div className='lightBoxModal'>
                            {showVideosModalWca && (
                                <div className='react-image-video-lightbox'>
                                    <ReactImageVideoLightbox
                                        data={vedioWsa}
                                        startIndex={indexOfVideosWca}
                                        showResourceCount={true}
                                        onCloseCallback={() => this.setState({ showVideosModalWca: false })}
                                        onNavigationCallback={(indexOfVideosWca) =>
                                            console.log(`Current index: ${indexOfVideosWca}`)
                                        }
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                </>
            )
        }) : [];

        const workCompletedPhotos = ticket.reviewimages ? ticket.reviewimages.map((image, index) => <div className="col-4" key={index}>
            {/* <a href={ImportedUrl.FILEURL + image} className="d-block link-overlay" target='_blank'>
                <img className="d-block img-fluid rounded" src={ImportedUrl.FILEURL + image} alt="image" width='100%' style={{ objectFit: 'cover', height: '200px' }} />
                <span className="link-overlay-bg rounded"><i className="fa fa-search" /></span>
            </a> */}
            <div style={{ height: '200px', width: '100%', cursor: 'pointer' }} onClick={() => this.openModalAndSetIndexWca(index)}>
                <img className="d-block img-fluid rounded w-100" src={ImportedUrl.FILEURL + image} alt="image" width='100%' style={{ objectFit: 'cover', height: '100%' }} onError={onErrorImage} />
                <span className="link-overlay-bg rounded"><i className="fa fa-search" /></span>
            </div>
            {showModalWca && (
                <div className='react-image-video-lightbox'>
                    <ReactImageVideoLightbox
                        data={ticket.reviewimages.map((item) => {
                            return ({ url: ImportedUrl.FILEURL + item, type: "photo", altTag: "No image to display" })
                        })}
                        startIndex={indexOfImagesWca}
                        showResourceCount={true}
                        onCloseCallback={() => this.setState({ showModalWca: false })}
                    />
                </div>
            )}
        </div>) : [];

        const workCompletedAudios = ticket.reviewaudios ? ticket.reviewaudios.map((audio, item) => <audio
            id="audio"
            src={ImportedUrl.FILEURL + audio}
            type="video/mp4"
            controls
            key={item}
        ></audio>) : [];

        const workCompletedVideos = ticket.reviewvideos ? ticket.reviewvideos.map((video, item) =>
            <video
                id="video"
                src={ImportedUrl.FILEURL + video}
                type="video/mp4"
                controls
                width='300px'
                key={item}
                height='200px'
            ></video>
        ) : [];

        const account = AccountState.account;
        var dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat != '' ? account.timeformat : "hh:mm A")
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
        }
        const dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY')
        const tFormat = (account.timeformat != '' ? account.timeformat : 'hh:mm A')

        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/incident-ticket"><h6>Incident Tickets List</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>View Incident Ticket</h6></p>
                    </div>
                    <div className="section-body pt-3 view_ticket">
                        <div id="overlay" style={{ display: this.state.nxpvspinner ? 'block' : 'none' }}></div>
                        <div className="container-fluid">
                            <div className="card">
                                <div className='card-body'>
                                    <div className="card-header" style={{ padding: '7px 0px 20px 0px', background: 'white' }}>
                                        <div className='rounded_icon'><i className="fa fa-ticket mr-2 "></i></div>
                                        <h3 className="card-title " style={{ marginTop: '5px' }}>View Incident Ticket</h3>
                                        <div className="header-action" style={{ marginTop: '5px', marginLeft: 'auto' }}>
                                            <Link to='/incident-ticket'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button></Link>
                                        </div>
                                    </div>

                                    <div className="row clearfix">
                                        <div className="col-xl-8 col-lg-4 col-md-12">
                                        </div>
                                        <div className="col-xl-8 col-lg-4 col-md-12">
                                            <div className="card" style={{ display: ticket.next == 0 && ticket.previous == 0 ? 'none' : 'block' }}>
                                                <div className='d-flex justify-content-end'>
                                                    {nextTicket != 0 ? <div onClick={(e) => this.onPreviousTicket(nextTicket)} className='left_ticket' ><i class="fa fa-arrow-circle-left mr-2" aria-hidden="true"></i> Previous</div> : <div></div>}
                                                    {previousTicket != 0 ? <div onClick={(e) => this.onNextTicket(previousTicket)} className='right_ticket'><i class="fa fa-arrow-circle-right mr-2" aria-hidden="true"></i>Next </div> : <div></div>}
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-header">
                                                    <h3 className="h6">{ticket.incidentticketid}</h3>
                                                </div>
                                            </div>
                                            {ticket.notes !== '' ?
                                                <div className="card">
                                                    <div className="card-body view_ticket_dec">
                                                        <div className="h6">Description</div>
                                                        <p>{ticket.notes}</p>
                                                    </div>
                                                </div>
                                                : ''}
                                            {ticket.reason ?
                                                <div className="card">
                                                    <div className="card-body view_ticket_dec">
                                                        <div className="h6">Remarks</div>
                                                        <p>{ticket.reason}</p>
                                                    </div>
                                                </div>
                                                : ''}
                                            {photos.length ?
                                                <div className="card">
                                                    <div className="card-header">
                                                        <h3 className="card-title">Image Attachments</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <div>
                                                            <div className="row img-gallery">
                                                                {photos}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> : ''}
                                            {videos.length ? <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">Video</h3>
                                                </div>
                                                <div className="card-body">
                                                    <div>
                                                        <div className="row">
                                                            {videos}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''}
                                            {audios.length ? <div className="card">
                                                <div className="card-header">
                                                    <h3 className="card-title">Audio</h3>
                                                </div>
                                                <div className="card-body">
                                                    <div>
                                                        <div className="row img-gallery">
                                                            <div className="col-8">
                                                                {audios}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> : ''}
                                        </div>
                                        <div className="col-lg-4 col-md-12 ticket_table_style">
                                            <div className="Over_all_table_style card">
                                                <div className="card-header">
                                                    <h3 className="card-title">Details</h3>
                                                </div>
                                                <table className="table card-table">
                                                    <tbody>
                                                        <tr>
                                                            <td><p className='detail_table'>Reporter</p></td>
                                                            <td className="text-right"><span className="text-muted ">{ticket.reporter ? ticket.reporter : "---"}</span></td>
                                                        </tr>
                                                        {ticket.roomno ?
                                                            <tr>
                                                                <td><p className='detail_table'>Room</p></td>
                                                                <td className="text-right"><span className="text-muted ">{ticket.roomno}</span></td>
                                                            </tr> :
                                                            <>{
                                                                ticket.placefor ?
                                                                    <tr>
                                                                        <td><p className='detail_table'>Place</p></td>
                                                                        <td className="text-right"><span className="text-muted">{ticket.placefor ? ticket.placefor : ''}</span></td>
                                                                    </tr>

                                                                    : ""
                                                            }</>
                                                        }
                                                        <tr>
                                                            <td><p className='detail_table'>Property</p></td>
                                                            <td className="text-right"><span className="text-muted">{ticket.hotel}</span></td>
                                                        </tr>
                                                        <tr>
                                                            <td><p className='detail_table'>Status</p></td>
                                                            <td className="text-right"><span className="text-muted">
                                                                {
                                                                    ticket.reasonstatus
                                                                        ? <span class="tag ticket_completed" style={{ width: "85px" }}>Completed</span>
                                                                        : <span class="tag ticket_inprogress" style={{ width: "85px" }}>Inprogress</span>
                                                                }</span></td>
                                                        </tr>

                                                        <tr>
                                                            <td><p className='detail_table'>Incident Date & Time</p></td>
                                                            <td className="text-right"><span className="text-muted">{ticket.date ? moment(ticket.date).format(dFormat) + " " + (ticket.time ? moment(ticket.time).format(tFormat) : "") : "---"}</span></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div>
                {
                    this.state.spinner ?
                        <div className='common_loader'>
                            <img className='loader_img_style_common' src='../../assets/images/load.png' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                        </div>
                        : ""
                }
                {
                    this.state.nxpvspinner ?
                        <div className='common_loader'>
                            <img className='loader_img_style_common' src='../../assets/images/load.png' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                        </div>
                        : ''
                }
            </>
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewIncidentTicket);
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { CSVLink } from "react-csv";
import "jspdf-autotable";
import axios from 'axios';
import moment from 'moment';
import Select from 'react-select';
import Chart from 'react-apexcharts'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { TailSpin } from 'react-loader-spinner';
import Pagination from 'react-bootstrap/Pagination';
import { AC_LIST_FEEDBACK, AC_REVIEW_KEYWORD_EMPTY, AC_SEND_SENTIMENTAL_ANALYSIS_DATA } from '../../actions/reviewAction';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import ImportedURL from '../../common/api';
// import { ReactComponent as Star } from "./star.png";
import { ReactComponent } from 'ag-grid-react/lib/reactComponent';
import jsPDF from "jspdf";
import Spinner from 'react-bootstrap/Spinner';
import { Link, Redirect } from 'react-router-dom';
import { ElfsightWidget } from 'react-elfsight-widget';
import { Capitalize, onErrorImage } from '../../common/validate';
import SentimentAnalysis from '../Reviews/sentimentalanalysis';
import { AC_GET_REVIEW_BY_ID } from '../../actions/reviewAction';
import { AC_LIST_SOCIALMEDIA } from '../../actions/socialmediaAction';



const initialState = {
    feedbacks: [],
    orignalData: [],
    hotel: '',
    typeReview: '',
    currentPage: 1,
    todosPerPage: 25,
    upperPageBound: 3,
    lowerPageBound: 0,
    isPrevBtnActive: 'disabled',
    isNextBtnActive: '',
    pageBound: 3,
    reviewType: '',
    ratings: [1, 2, 3],
    hotel: '',
    gridApi: '',
    spinner: true,
    ViewHotelId: '',
    ViewHotelType: '',
    viewData: false,
    viewDataType: false,
    searchValue: 'All',
    viewReviewRedirectId: '',
    viewReviewRedirect: false,
    isReadMore: false,
    readMoreValue: '',
    fromDateReview: '',
    toDateReview: '',
    elfsightData: '',
    reviewTypeColor: '',
    csvHeaders: [
        { label: "Hotel", key: "hotel" },
        { label: "Name", key: "reviewer_name" },
        { label: "Review title", key: "title" },
        { label: "Comments", key: "text" },
        { label: "Supplier", key: "supplier" },
        { label: "Number of Rating", key: "rating" },
        { label: "Published at", key: "published_at" }
    ],
    options: [
        {
            value: '', label: 'All'
        },
        {
            value: 5, label: <span dangerouslySetInnerHTML={{
                __html: `<img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;">`
            }} />
        },
        {
            value: 4, label: <span dangerouslySetInnerHTML={{
                __html: `<img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;">`
            }} />
        },
        {
            value: 3, label: <span dangerouslySetInnerHTML={{
                __html: `<img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;">`
            }} />
        },
        {
            value: 2, label: <span dangerouslySetInnerHTML={{
                __html: `<img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;">`
            }} />
        },
        {
            value: 1, label: <span dangerouslySetInnerHTML={{
                __html: `<img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;">`
            }} />
        },
    ],
    optionsStar: [
        {
            value: 5, label: <span dangerouslySetInnerHTML={{
                __html: `<img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;">`
            }} />
        },
        {
            value: 4, label: <span dangerouslySetInnerHTML={{
                __html: `<img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;">`
            }} />
        },
        {
            value: 3, label: <span dangerouslySetInnerHTML={{
                __html: `<img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;">`
            }} />
        },
        {
            value: 2, label: <span dangerouslySetInnerHTML={{
                __html: `<img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;"><img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;">`
            }} />
        },
        {
            value: 1, label: <span dangerouslySetInnerHTML={{
                __html: `<img srcset="../assets/images/starticon.png" alt="icon" loading="lazy" style="width: 20px; height: 20px;">`
            }} />
        },
    ],
    starOptionStatus: true,
    starOptions: [3, 2, 1],
    hotelName: '',
    topNav: false,
    hotelFeedback: true,
    onclickloader: false,
    gridView: false,
    gridSpinner: false,
    hotelOptions: '',
    hotelNameMatch: '',
    epmtyHotel: false,
    hotelValue: { label: 'All', value: '' },
    hideReset: false,
    elfsights: [],
    reviewSeries: [],
    barChartSeries: [],
    reviewOptions: {
        chart: {
            height: "100%",
        },
        labels: [],
        dataLabels: {
            formatter: function (val, opts) {
                return opts.w.config.series[opts.seriesIndex]
            },
        },
    },
    ratingOptions: {
        chart: {
            type: 'bar',
            id: "basic-bar",
            events: {
                mouseLeave: function (event, chartContext, config) {
                    chartContext.resetSeries()
                }
            },
            height: 380,

        },
        plotOptions: { bar: { horizontal: false, columnWidth: '55%', endingShape: 'rounded', distributed: true, } },
        fill: { colors: ['#2E93fA', '#66DA26', '#546E7A', '#E91E63', '#FF9800'] },
        xaxis: {
            labels: { show: false, }
        },
        stroke: {
            show: true, width: 2,
            colors: ['transparent']
        },
        fill: { opacity: 1 },
    },
    ratingSerious: [
        {
            name: "Priority",
            data: []
        }
    ],
    perPage: 25,


}

const loaderStyle = {
    textAlign: 'center',
    position: 'absolute',
    zIndex: 1,
    top: '150%',
    left: '45%'
};
class ListSocialReviews extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    handleClick = (event) => {
        let listid = Number(event.target.id);
        this.setState({
            currentPage: listid
        });
        // $("ul li.active").removeClass('active');
        // $('ul li#' + listid).addClass('active');
        this.setPrevAndNextBtnClass(listid);
    }
    setPrevAndNextBtnClass = (listid) => {
        let totalPage = Math.ceil(this.state.feedbacks.length / this.state.todosPerPage);
        this.setState({ isNextBtnActive: 'disabled' });
        this.setState({ isPrevBtnActive: 'disabled' });
        if (totalPage === listid && totalPage > 1) {
            this.setState({ isPrevBtnActive: '' });
        }
        else if (listid === 1 && totalPage > 1) {
            this.setState({ isNextBtnActive: '' });
        }
        else if (totalPage > 1) {
            this.setState({ isNextBtnActive: '' });
            this.setState({ isPrevBtnActive: '' });
        }
    }

    btnIncrementClick = () => {
        this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
        this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
        let listid = this.state.upperPageBound + 1;
        this.setState({ currentPage: listid });
        this.setPrevAndNextBtnClass(listid);
    }
    btnDecrementClick = () => {
        this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
        this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
        let listid = this.state.upperPageBound - this.state.pageBound;
        this.setState({ currentPage: listid });
        this.setPrevAndNextBtnClass(listid);
    }

    btnPrevClick = () => {
        if ((this.state.currentPage - 1) % this.state.pageBound === 0) {
            this.setState({ upperPageBound: this.state.upperPageBound - this.state.pageBound });
            this.setState({ lowerPageBound: this.state.lowerPageBound - this.state.pageBound });
        }
        let listid = this.state.currentPage - 1;
        this.setState({ currentPage: listid });
        this.setPrevAndNextBtnClass(listid);
    }
    btnNextClick = () => {
        if ((this.state.currentPage + 1) > this.state.upperPageBound) {
            this.setState({ upperPageBound: this.state.upperPageBound + this.state.pageBound });
            this.setState({ lowerPageBound: this.state.lowerPageBound + this.state.pageBound });
        }
        let listid = this.state.currentPage + 1;
        this.setState({ currentPage: listid });
        this.setPrevAndNextBtnClass(listid);
    }
    getDate = (date) => {
        const account = this.props.AccountState.account;
        var dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY');
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
        }
        if (!date) return '';
        const newDate = new Date(date * 1000);
        return moment(newDate).format(dFormat);
    }
    viewRedirectClick = (id) => {
        this.setState({ viewReviewRedirect: true, viewReviewRedirectId: id })
    }

    getSocialReviewData = (type, rating, hotel, fromdate, todate) => {
        this.setState({ spinner: true });
        let formData = {
            type: type !== null ? type : '',
            rating: rating !== null ? rating : this.state.ratings,
            hotel: hotel !== null ? hotel : this.state.hotel,
            fromdate: fromdate !== null ? fromdate : (this.state.fromDateReview ? this.state.fromDateReview + "T00:00:00" : ''),
            todate: todate !== null ? todate : (this.state.toDateReview ? this.state.toDateReview + "T23:59:59" : ''),
        }
        axios.post(ImportedURL.API.getSocialReview, formData)
            .then((res) => {
                if (res.data) {
                    let pieChart = res.data.getPieChart
                    let reviewLabelArray = [];
                    let reviewSerieslArray = [];
                    if (pieChart && pieChart.length > 0)
                        for (let i = 0; i < pieChart.length; i++) {
                            reviewLabelArray.push(pieChart[i].name)
                            reviewSerieslArray.push(pieChart[i].counter)
                        }
                    const { overallreviewcount, socialreviews } = res?.data.viewHotel
                    if (overallreviewcount != undefined && overallreviewcount != null && overallreviewcount) {
                        this.setState({ elfsightData: overallreviewcount });
                    } else {
                        this.setState({ elfsightData: '' });
                    }
                    if (socialreviews != undefined && socialreviews.length > 0) {
                        this.setState({ elfsights: socialreviews });
                    } else {
                        this.setState({ elfsights: [] });
                    }
                    this.props.SendSentimentalAnalysisData(res.data.reviewKeyword);
                    this.setState({
                        barChartSeries: res.data.getStarCount,
                        reviewSeries: reviewSerieslArray,
                        reviewOptions: { ...this.state.reviewOptions, ...this.state.reviewOptions, labels: reviewLabelArray },
                        feedbacks: res.data.getSocialReviews,
                        spinner: false,
                        topNav: true
                    });
                }
            }).catch((err) => { console.log(err); });
    }
    componentDidMount() {
        const { AccountState } = this.props
        const account = AccountState.account;
        this.props.ListSocialMedia();
        this.props.ReviewEmptyKeyword()
        // this.props.ReviewByClient({}, "");
        if (account.hotelName != undefined && account.hotelName != null && account.hotelName) {
            if (account.hotelName.replaceAll(" ", "").toLowerCase() == 'beachsideresortgulfshoresalabama') {
                this.setState({ hotelNameMatch: "beachsideresortgulfshoresalabama" })
            }
        }
        this.setState({ hotelName: account.hotelName ? account.hotelName : '' });
        const { params } = this.props.match
        var ViewIdParams = '';
        var ViewTypeParams = '';
        if (params !== undefined) {
            const { id, reviewType } = params
            if (id) ViewIdParams = id;
            if (reviewType) ViewTypeParams = reviewType;
            this.setState({
                hotel: ViewIdParams,
                currentPage: 1,
                upperPageBound: 3,
                lowerPageBound: 0,
                isPrevBtnActive: 'disabled',
                isNextBtnActive: '',
                pageBound: 3,
                ViewHotelId: ViewIdParams,
                ViewHotelType: ViewTypeParams,
                viewData: true,
                viewDataType: true,
            })
        }
        this.getSocialReviewData(ViewTypeParams, [1, 2, 3], ViewIdParams, null, null);
    }

    getRatings = (rating) => {
        let data = []
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) data.push(<i className="fa fa-star text-orange" key={i}></i>)
            else data.push(<i className="fa fa-star" key={i}></i>)
        }
        return data;
    }

    getReview = (type) => {
        var socialtype = ''
        if (type == 'tripadvisor') {
            socialtype = 'trip-advisor'
        } else if (type == 'booking.com') {
            socialtype = 'booking'
        } else if (type == 'hotels.com') {
            socialtype = 'hotels'
        } else {
            socialtype = type
        }
        this.setState({ viewDataType: false, typeReview: socialtype, onclickloader: true })
        this.getSocialReviewData(socialtype, null, null, null, null);
        this.setState({
            reviewType: socialtype, currentPage: 1,
            // todosPerPage: 25,
            upperPageBound: 3,
            lowerPageBound: 0,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            pageBound: 3,
            reviewTypeColor: type,
        })
    }

    setRatings = e => {
        var socialtype = ''
        if (this.state.reviewType == 'tripadvisor') {
            socialtype = 'trip-advisor'
        } else if (this.state.reviewType == 'booking.com') {
            socialtype = 'booking'
        } else if (this.state.reviewType == 'hotels.com') {
            socialtype = 'hotels'
        } else {
            socialtype = this.state.reviewType
        }

        var query = []
        let allstatus = e.find((e) => e.label == "All")
        if (!allstatus && e.length) {
            e.map((item) => {
                query.push(item.value)
                this.setState({ starOptionStatus: true })
            })
        } else {
            this.setState({ starOptionStatus: true })
        }
        if (allstatus) {
            query = [5, 4, 3, 2, 1]
            this.setState({ starOptionStatus: false })
        }
        if (e.length == 5) this.setState({ starOptionStatus: false })
        this.setState({ starOptions: query, spinner: true, viewData: false, hideReset: true })
        this.getSocialReviewData(socialtype, query, null, null, null);
        this.setState({
            ratings: query,
            currentPage: 1,
            // todosPerPage: 25,
            upperPageBound: 3,
            lowerPageBound: 0,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            pageBound: 3,
        })
    }


    setHotel = e => {
        const { name, value, label } = e;
        this.setState({ ViewHotelId: '', searchValue: e.label, hotelNameMatch: label.replaceAll(" ", "").toLowerCase(), hotelValue: e })
        this.getSocialReviewData(null, null, value, null, null);
        this.setState({
            hotel: value,
            currentPage: 1,
            // todosPerPage: 25,
            upperPageBound: 3,
            lowerPageBound: 0,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            pageBound: 3,
            hotelOptions: e,
            hideReset: true,
            reviewType: '',
            reviewTypeColor: '',
        })
    }
    setLimit = e => {
        this.setState({ todosPerPage: e })
    }

    exportPDF = () => {
        const account = this.props.AccountState.account;
        const dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY')

        const selectedProperties = this.state.hotelName ? ['#', "reviewer_name", "title", 'text', 'supplier', 'rating', 'published_at'] : ['#', "hotel", "reviewer_name", "title", 'text', 'supplier', 'rating', 'published_at']

        const { feedbacks, currentPage, todosPerPage } = this.state;
        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const currentfeedbacks = feedbacks.slice(indexOfFirstTodo, indexOfLastTodo);

        const selected = currentfeedbacks.map(item => {
            const withEmojis = /\p{Extended_Pictographic}/ug;
            let reviewEmoji = item.text.replace(/\p{Emoji}/gu, '');
            let newReviewEmoji = reviewEmoji.replaceAll(withEmojis, '*');
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    if (property == 'published_at') {
                        selectedData[property] = moment(item[property]).format(dFormat)
                    } else if (property == "supplier") {
                        selectedData[property] = (item[property] == "hotels" || item[property] == "booking") ? item[property] + '.com' : item[property]
                    } else if (property == "comments") {
                        selectedData[property] = String(reviewEmoji)
                    } else {
                        selectedData[property] = item[property];
                    }
                }
            });
            return selectedData;
        });
        let data = {
            title: (this.state.hotelName) ? (this.state.hotelName != "All" ? this.state.hotelName + " - Social Review" : "Social Review") : "Social Review",
            head: this.state.hotelName ? ['#', 'Name', 'Review Title', 'Comments', 'Supplier', 'Number of Rating', 'Published at'] : ['#', 'Hotel', 'Name', 'Review Title', 'Comments', 'Supplier', 'Number of Rating', 'Published at'],
            body: selected,
            hotelNameExport: this.state.hotelName ? account.hotelNameExport : '',
            hoteladdressExport: this.state.hotelName ? account.hoteladdressExport : '',
            limit: this.state.perPage,
            hotelWidth: true
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "socialreview" + "_" + (this.state.hotelName && (this.state.hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }

    toggleReadMore = (e, i) => {
        if (i == this.state.readMoreValue) {
            if (this.state.isReadMore) {
                this.setState({ isReadMore: false })
            } else {
                this.setState({ isReadMore: true })
            }
        } else {
            this.setState({ isReadMore: true })
        }
        this.setState({ readMoreValue: i })
    };

    getMonths = (mon) => {
        let date = new Date();
        date.setDate(1);
        date.setMonth(date.getMonth() - mon);
        let monthName = new Intl.DateTimeFormat('en', { month: 'short' }).format(date);
        return `${monthName}`;
    }


    setDateReport = (e) => {
        var socialtype = ''
        if (this.state.reviewType == 'tripadvisor') {
            socialtype = 'trip-advisor'
        } else if (this.state.reviewType == 'booking.com') {
            socialtype = 'booking'
        } else if (this.state.reviewType == 'hotels.com') {
            socialtype = 'hotels'
        } else {
            socialtype = this.state.reviewType
        }
        this.setState({ spinner: true })
        const { name, value } = e.target;
        let hotelId = this.state.hotel;
        if (name == 'fromDateReview') {
            this.setState({ fromDateReview: value, toDateReview: "", month: '', hideReset: true });
            let startDate = value + "T00:00:00"
            let endDate = ''
            this.getSocialReviewData(socialtype, null, hotelId, startDate, endDate);
            this.setState({
                currentPage: 1,
                upperPageBound: 3,
                lowerPageBound: 0,
                isPrevBtnActive: 'disabled',
                isNextBtnActive: '',
                pageBound: 3,
            })
        }
        if (name == 'toDateReview') {
            this.setState({ toDateReview: value, month: '', hideReset: true });
            let endDate = value ? value + "T23:59:59" : ''
            let startDate = this.state.fromDateReview ? this.state.fromDateReview + "T00:00:00" : ''
            this.getSocialReviewData(socialtype, null, hotelId, startDate, endDate);
            this.setState({
                currentPage: 1,
                upperPageBound: 3,
                lowerPageBound: 0,
                isPrevBtnActive: 'disabled',
                isNextBtnActive: '',
                pageBound: 3,
            })
        }
        if (!this.state.hotel && !value) {
            if (!this.state.fromDateReview && name == 'toDateReview') {
                this.setState({ hideReset: false });
            }
            if (name == 'fromDateReview') {
                this.setState({ hideReset: false });
            }
        } else {
            this.setState({ hideReset: true });
        }
    }

    getMonthlyReport = e => {
        var socialtype = ''
        if (this.state.reviewType == 'tripadvisor') {
            socialtype = 'trip-advisor'
        } else if (this.state.reviewType == 'booking.com') {
            socialtype = 'booking'
        } else if (this.state.reviewType == 'hotels.com') {
            socialtype = 'hotels'
        } else {
            socialtype = this.state.reviewType
        }
        this.setState({ spinner: true })
        const { value } = e.target;
        let hotelId = this.state.hotel;
        const newval = value == 0 ? 0 : value == 1 ? 1 : 2;
        let date = new Date();
        let startDate = new Date(date.getFullYear(), date.getMonth() - newval, 1);
        let endDate = new Date(date.getFullYear(), date.getMonth() - newval + 1, 0);
        let startD = moment(startDate).format('YYYY-MM-DD') + "T00:00:00"
        let endD = moment(endDate).format('YYYY-MM-DD') + "T23:59:59"
        this.setState({ reportspin: true })

        this.getSocialReviewData(socialtype, null, hotelId, startD, endD);
        this.setState({
            currentPage: 1,
            upperPageBound: 3,
            lowerPageBound: 0,
            isPrevBtnActive: 'disabled',
            isNextBtnActive: '',
            pageBound: 3,
        })
    }
    filterReset = () => {
        this.setState({ starOptions: [3, 2, 1], searchValue: '', toDateReview: '', fromDateReview: '', month: '', hotelName: '', hotel: '', hotelValue: { label: 'All', value: '' } });
        var ViewIdParams = '';
        var ViewTypeParams = '';
        const account = this.props.AccountState.account;
        if (account.hotelName != undefined && account.hotelName != null && account.hotelName) {
            if (account.hotelName.replaceAll(" ", "").toLowerCase() == 'beachsideresortgulfshoresalabama') {
                this.setState({ hotelNameMatch: "beachsideresortgulfshoresalabama" })
            }
        } else {
            this.setState({ hotelNameMatch: "" })
        }
        this.setState({ hotelName: account.hotelName ? account.hotelName : '', hideReset: false });

        const { params } = this.props.match
        var ViewIdParams = '';
        var ViewTypeParams = '';
        if (params !== undefined) {
            const { id, reviewType } = params
            if (id) ViewIdParams = id;
            if (reviewType) ViewTypeParams = reviewType;
            this.setState({
                hotel: ViewIdParams,
                currentPage: 1,
                upperPageBound: 3,
                lowerPageBound: 0,
                isPrevBtnActive: 'disabled',
                isNextBtnActive: '',
                pageBound: 3,
                ViewHotelId: ViewIdParams,
                ViewHotelType: ViewTypeParams,
                viewData: true,
                viewDataType: true,
                reviewType: '',
                reviewTypeColor: '',
            })
        }
        this.getSocialReviewData(ViewTypeParams, [1, 2, 3], ViewIdParams, null, null);
    }
    render() {
        const { AccountState, SocialMediaState, LandingState } = this.props;
        const { feedbacks, viewDataType, ViewHotelId, viewData, ViewHotelType, currentPage, todosPerPage, upperPageBound, lowerPageBound, isPrevBtnActive, isNextBtnActive } = this.state;
        const role = AccountState.role;
        const account = AccountState.account;
        const languageData = AccountState.account.SelectedlanguageData
        const rowData = this.state.feedbacks;
        let socialMediaList = SocialMediaState.listSocialMedia;
        // var google = rowData.filter(function (data) { return data.supplier == "google" });
        // var tripadvisor = rowData.filter(function (data) { return data.supplier == "trip-advisor" });
        // var hotels = rowData.filter(function (data) { return data.supplier == "hotels" });
        // var booking = rowData.filter(function (data) { return data.supplier == "booking" });
        // var expedia = rowData.filter(function (data) { return data.supplier == "expedia" });
        // var yelp = rowData.filter(function (data) { return data.supplier == "yelp" });
        // var facebook = rowData.filter(function (data) { return data.supplier == "facebook" });

        const listHotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[0] : [];
        const indexOfLastTodo = currentPage * todosPerPage;
        const indexOfFirstTodo = indexOfLastTodo - todosPerPage;
        const currentfeedbacks = feedbacks.slice(indexOfFirstTodo, indexOfLastTodo);

        var selectedHotel = '';
        const hotelOptions = [];
        if (listHotels && listHotels.length > 0) {
            hotelOptions.push({ label: 'All', value: '' })
        }
        if (account?.allhotels?.length > 0) {
            account.allhotels.map((item) => {
                if (account.hotel == item.hotelid) {
                    selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                }
                hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo });
            })
        } else {
            listHotels.filter(filterItem => filterItem.status === true).map((item) => {
                hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
            })
        }
        var searchHotelView = '';
        var reviewIconView = '';
        if (viewData) {
            if (ViewHotelId) {
                listHotels.map(item => {
                    if (item._id === ViewHotelId) {
                        searchHotelView = item.name + " " + item.city + " " + item.state
                    }
                })
            }
        }
        if (viewDataType) {
            if (ViewHotelType) {
                reviewIconView = ViewHotelType
            } else {
                reviewIconView = 'All'
            }
        }

        const pageNumbers = [];
        for (let i = 1; i <= Math.ceil(feedbacks.length / todosPerPage); i++) {
            pageNumbers.push(i);
        }
        const renderPageNumbers = pageNumbers.map(number => {
            if (currentPage === number) {
                return (
                    <li key={number} className='page-item active' id={number}><a className="page-link" role='button' id={number} onClick={this.handleClick}>{number}</a></li>
                )
            }
            else if ((number < upperPageBound + 1) && number > lowerPageBound) {
                return (
                    <li key={number} id={number} className='page-item'><a className="page-link" role='button' id={number} onClick={this.handleClick}>{number}</a></li>
                )
            }
        });
        let pageIncrementBtn = null;
        if (pageNumbers.length > upperPageBound) {
            pageIncrementBtn = <li className="page-item"><a className="page-link" role='button' onClick={this.btnIncrementClick}>&hellip;</a></li>
        }
        let pageDecrementBtn = null;
        if (lowerPageBound >= 1) {
            pageDecrementBtn = <li className="page-item"><a className="page-link" role='button' onClick={this.btnDecrementClick}>&hellip;</a></li>
        }
        let renderPrevBtn = null;
        if (isPrevBtnActive === 'disabled') {
            renderPrevBtn = <li className="page-item"><a className="page-link" role='button' style={{ cursor: "default", backgroundColor: 'lightgrey' }}>Prev</a></li>
        }
        else {
            renderPrevBtn = <li className="page-item"><span className="page-link" role='button' onClick={this.btnPrevClick}>Prev</span></li>
        }
        let renderNextBtn = null;
        if (isNextBtnActive === 'disabled' || renderPageNumbers.length == 1) {
            renderNextBtn = <li className="page-item"><a className="page-link" role='button' style={{ cursor: "default", backgroundColor: 'lightgrey' }}>Next</a></li>
        }
        else {
            renderNextBtn = <li className="page-item"><span className="page-link" role='button' onClick={this.btnNextClick}>Next</span></li>
        }

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]

        var dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY');
        const csvexport = currentfeedbacks.map((elt) => {
            return {
                hotel: elt.hotel,
                reviewer_name: elt.reviewer_name,
                title: elt.title,
                text: elt.text,
                supplier: elt.supplier,
                rating: elt.rating,
                published_at: moment(elt.published_at).format(dFormat)
            }
        });
        var gridSpin = []
        if (this.state.gridSpinner) {
            setTimeout(() => {
                gridSpin.push(
                    <div>
                        <div id="overlay" style={{ display: 'block' }}></div>
                        <div className="common_loader">
                            <img className='loader_img_style_common' src='../../assets/images/load.png' referrerpolicy="no-referrer" />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                        </div>
                    </div>
                )
            }, 3000)
        }


        // ---------------------- pie code start--------------------- 
        const chartDataGoogle = [];
        const chartDataTripAdvisor = [];
        const chartDataHotels = [];
        const chartDataExpedia = [];
        const chartDataBooking = [];
        const chartDataYelp = [];
        const chartDataFacebook = [];
        const chartLable1 = [];
        const chartLable2 = [];
        const chartLable3 = [];
        const chartLable4 = [];
        const chartLable5 = [];
        const chartLable6 = [];
        const chartLable7 = [];
        var star1 = '';
        var star2 = '';
        var star3 = '';
        var star4 = '';
        var star5 = '';
        if (rowData) {
            for (let i = 0; i < rowData.length; i++) {
                if (rowData[i].supplier == 'google') {
                    chartDataGoogle.push(rowData[i]);
                    chartLable1.push(rowData[i].supplier)
                } else {
                    chartLable1.push('google')
                }
                if (rowData[i].supplier == 'trip-advisor') {
                    chartDataTripAdvisor.push(rowData[i])
                    chartLable2.push(rowData[i].supplier)
                } else {
                    chartLable2.push('trip-advisor')
                }
                if (rowData[i].supplier == 'hotels') {
                    chartDataHotels.push(rowData[i])
                    chartLable3.push(rowData[i].supplier + '.com')
                } else {
                    chartLable3.push('hotels.com')
                }
                if (rowData[i].supplier == 'expedia') {
                    chartDataExpedia.push(rowData[i])
                    chartLable4.push(rowData[i].supplier)
                } else {
                    chartLable4.push('expedia')
                }
                if (rowData[i].supplier == 'booking') {
                    chartDataBooking.push(rowData[i])
                    chartLable5.push(rowData[i].supplier + '.com')
                } else {
                    chartLable5.push('booking.com')
                }
                if (rowData[i].supplier == 'yelp') {
                    chartDataYelp.push(rowData[i])
                    chartLable6.push(rowData[i].supplier)
                } else {
                    chartLable6.push('yelp')
                }
                if (rowData[i].supplier == 'facebook') {
                    chartDataFacebook.push(rowData[i])
                    chartLable7.push(rowData[i].supplier)
                } else {
                    chartLable7.push('facebook')
                }
                { (rowData[i].rating == 1) ? star1 = '1 Star' : star1 = '1 Star'; }
                { (rowData[i].rating == 2) ? star2 = '2 Star' : star2 = '2 Star' }
                { (rowData[i].rating == 3) ? star3 = '3 Star' : star3 = '3 Star' }
                { (rowData[i].rating == 4) ? star4 = '4 Star' : star4 = '4 Star' }
                { (rowData[i].rating == 5) ? star5 = '5 Star' : star5 = '5 Star' }
            }
            var starPush = [star1, star2, star3, star4, star5];
            const mergeCount = [chartDataGoogle.length, chartDataTripAdvisor.length, chartDataHotels.length, chartDataExpedia.length, chartDataBooking.length, chartDataYelp.length, chartDataFacebook.length];
            var mergeLable = []
            if (this.state.hotelName && this.state.hotelName != 'All') {
                if (this.state.elfsights.length > 0 && this.state.elfsights) {
                    this.state.elfsights.map((item) => {
                        if (item.socialmediaid != null) {
                            let value = ''
                            if (item.socialmediaid.socialmedianame == 'google') {
                                value = "google"
                            }
                            if (item.socialmediaid.socialmedianame == 'booking.com') {
                                value = "booking.com"
                            }
                            if (item.socialmediaid.socialmedianame == 'hotels.com') {
                                value = "hotels.com"
                            }
                            if (item.socialmediaid.socialmedianame == 'tripadvisor') {
                                value = "trip-advisor"
                            }
                            if (item.socialmediaid.socialmedianame == 'expedia') {
                                value = "expedia"
                            }
                            if (item.socialmediaid.socialmedianame == 'yelp') {
                                value = "yelp"
                            }
                            if (item.socialmediaid.socialmedianame == 'Facebook') {
                                value = "facebook"
                            }
                            mergeLable.push(value)
                        }
                    })
                } else {
                    mergeLable = [chartLable1[0], chartLable2[0], chartLable3[0], chartLable4[0], chartLable5[0], chartLable6[0], chartLable7[0]]
                }
            } else {
                mergeLable = [chartLable1[0], chartLable2[0], chartLable3[0], chartLable4[0], chartLable5[0], chartLable6[0], chartLable7[0]]
            }
            let countValue = []
            if (this.state.hotelName && this.state.hotelName != 'All') {

                mergeCount.map((item) => {
                    if (this.state.elfsights.length > 0 && this.state.elfsights) {
                        if (item != 0) {
                            countValue.push(item)
                        }
                    } else {
                        countValue.push(item)
                    }
                })
            } else {
                mergeCount.map((item) => {
                    countValue.push(item)
                })
            }
            var series = countValue;
            var chartOptions = {
                chart: {
                    width: 380,
                    type: 'pie',
                    toolbar: {
                        show: true,
                        offsetX: 0,
                        offsetY: 0,
                        // tools: {
                        //   download: true,
                        //   selection: true,
                        //   zoom: true,
                        //   zoomin: true,
                        //   zoomout: true,
                        //   pan: true,
                        //   reset: true | '<img src="/static/icons/reset.png" width="20">',
                        //   customIcons: []
                        // },
                        export: {
                            csv: {
                                filename: undefined,
                                columnDelimiter: ',',
                                headerCategory: 'category',
                                headerValue: 'value',
                                dateFormatter(timestamp) {
                                    return new Date(timestamp).toDateString()
                                }
                            },
                            svg: {
                                filename: undefined,
                            },
                            png: {
                                filename: undefined,
                            }
                        },
                        autoSelected: 'zoom'
                    },
                },
                labels: mergeLable,
                dataLabels: {
                    formatter: function (val, opts) {
                        return opts.w.config.series[opts.seriesIndex]
                    },
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: 200
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            }
        }
        // ----------------------  code end ---------------------
        // -----------------------bar chart ---------------------

        const starRatingvalue = {
            chart: {
                type: 'bar',
                id: "basic-bar",
                events: {
                    mouseLeave: function (event, chartContext, config) {
                        chartContext.resetSeries()
                    }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            fill: {
                opacity: 1
            },
            xaxis: {
                categories: [''],
            }
        }
        let oneStars = 0, twoStars = 0, threeStars = 0, fourStars = 0, fiveStars = 0;
        rowData.map((item) => { switch (item.rating) { case 1: oneStars += 1; break; case 2: twoStars += 1; break; case 3: threeStars += 1; break; case 4: fourStars += 1; break; default: fiveStars += 1; break }return item });
        var finalStarsArr = [{
            name: 'star', data: [oneStars, twoStars, threeStars, fourStars, fiveStars]
        }]


        var elfurl = true;
        if (this.state.elfsights) {
            var dummy = this.state.elfsights.sort((a, b) => Number(a.socialmediaid?.id) - Number(b.socialmediaid?.id));
            this.state.elfsights && this.state.elfsights.map((data) => {
                if (data.socialmediaurl) {
                    elfurl = true
                } else {
                    elfurl = false
                }
            })
        }

        var hotelSocialMedia = []
        if (this.state.hotelName && this.state.hotelName != 'All' && this.state.elfsights.length > 0 && this.state.elfsights && elfurl) {
            hotelSocialMedia.push(
                <div className="chips">
                    {
                        (reviewIconView == "All" || reviewIconView == '') && this.state.reviewType == "" ?
                            <a className="chip" role='button' onClick={() => this.getReview('')} style={reviewIconView == 'All' ? { backgroundColor: 'rgb(165, 197, 255)' } : this.state.reviewType == '' ? { backgroundColor: 'rgb(165, 197, 255)' } : {}}><span className="avatar" style={{ backgroundImage: 'url(../assets/images/myhotel.png)' }}></span> All Reviews
                            </a>
                            :
                            <a className="chip" role='button' onClick={() => this.getReview('')} ><span className="avatar" style={{ backgroundImage: 'url(../assets/images/myhotel.png)' }}></span> All Reviews</a>
                    }
                    {
                        dummy && dummy.map((data) => {
                            if (data.socialmediaid != null) {
                                let find = socialMediaList.find(e => e.socialmedianame == data.socialmediaid.socialmedianame)
                                if (find && find.status)
                                    return (
                                        <>
                                            <a role='button' className="chip" style={this.state.reviewTypeColor == data.socialmediaid.socialmedianame ? { backgroundColor: 'rgb(165, 197, 255)' } : {}} onClick={() => this.getReview(data.socialmediaid.socialmedianame)}>
                                                <span className="avatar bg-light">
                                                    <img style={{ borderRadius: "50%" }} src={ImportedURL.FILEURL + data.socialmediaid.socialmediaimage} onError={onErrorImage} />
                                                </span>
                                                {data.socialmediaid.socialmedianame}
                                            </a>
                                        </>
                                    )
                            }
                        })
                    }
                </div>
            )
        } else {
            var socialMediaArray = []
            if (socialMediaList && socialMediaList.length > 0 && (this.state.hotelName == 'All' || this.state.hotelName == '')) {
                socialMediaArray.push(
                    <div className="chips">
                        {
                            (reviewIconView == "All" || reviewIconView == '') && this.state.reviewType == "" ?
                                <a className="chip" role='button' onClick={() => this.getReview('')} style={reviewIconView == 'All' ? { backgroundColor: 'rgb(165, 197, 255)' } : this.state.reviewType == '' ? { backgroundColor: 'rgb(165, 197, 255)' } : {}}><span className="avatar" style={{ backgroundImage: 'url(../assets/images/myhotel.png)' }}></span> All Reviews
                                </a>
                                :
                                <a className="chip" role='button' onClick={() => this.getReview('')} ><span className="avatar" style={{ backgroundImage: 'url(../assets/images/myhotel.png)' }}></span> All Reviews</a>
                        }
                        {
                            socialMediaList && socialMediaList.filter(e => e.status === true).map((item, j) => {
                                return (
                                    <>
                                        <a role='button' className="chip" style={this.state.reviewTypeColor == item.socialmedianame ? { backgroundColor: 'rgb(165, 197, 255)' } : {}} onClick={() => this.getReview(item.socialmedianame)}>
                                            <span className="avatar bg-light" style={{ borderRadius: "50%" }}>
                                                <img style={{ borderRadius: "50%" }} src={ImportedURL.FILEURL + item.socialmediaimage} onError={onErrorImage} />
                                            </span>
                                            {item.socialmedianame}
                                        </a>
                                    </>
                                )
                            })
                        }
                    </div>
                )
            }
        }

        var options = ''
        if (this.state.starOptionStatus) {
            options = this.state.options
        } else {
            options = this.state.optionsStar
        }

        var selectrating = options ? options.filter(e => this.state.starOptions.some(e1 => (e1 == e.value))) : []

        return (
            <>
                <div className="breadcrump">
                    <p> <h6><Link to="/">Dashboard</Link></h6> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/social-reviews"><h6 className="highlights_breadcrump">{languageData && languageData.LATEST_OTA_REVIEW ? languageData.LATEST_OTA_REVIEW : "Latest OTA Reviews"}</h6></Link></p>
                </div>
                <div className="section-body pt-3">
                    <div className="container-fluid">
                        <div className="tab-content">
                            <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='rounded_icon'><i className="icon-star mr-2"></i></div>
                                        <h3 className="card-title common_title_fs"> {languageData && languageData.LATEST_OTA_REVIEW ? languageData.LATEST_OTA_REVIEW : "Latest OTA Reviews"}</h3>
                                        <div className="card-options social_reviews_option">
                                            {/* <Link to={'/'}><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button></Link> */}
                                            {
                                                (currentfeedbacks.length > 0 && this.state.gridView === false)
                                                &&
                                                <>
                                                    <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                    <div className="dropdown-menu">
                                                        <CSVLink headers={this.state.csvHeaders} data={csvexport} filename={'socialreview' + "_" + (this.state.hotelName && (this.state.hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".csv"}><a className="dropdown-item" type='button'><i className="dropdown-icon fa fa-file-excel-o"></i>Excel</a></CSVLink>
                                                        <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                    </div>
                                                </>
                                            }
                                            {
                                                (this.state.elfsightData && this.state.elfsightData != undefined)
                                                &&
                                                <span className="input-group-btn ml-3">
                                                    <button className="btn btn-sm btn-default review_count" style={{ height: '50px' }} onClick={() => { this.setState({ gridView: !this.state.gridView, gridSpinner: !this.state.gridSpinner }) }}>
                                                        {this.state.gridView ? <span className="fe fe-grid" style={{ fontSize: '20px' }}></span> : <span className="fa fa-bar-chart" style={{ fontSize: '20px' }}></span>}
                                                        {this.state.gridView ? <span style={{ fontSize: '17px', paddingLeft: '7px', fontWeight: 500 }}>Reviews Report</span> : <span style={{ fontSize: '17px', paddingLeft: '7px', fontWeight: 500 }}>Reviews Count</span>}
                                                    </button>
                                                </span>
                                            }
                                            {
                                                (this.state.elfsightData && this.state.elfsightData != undefined)
                                                &&
                                                <div className='click_gif_images' onClick={() => { this.setState({ gridView: !this.state.gridView, gridSpinner: !this.state.gridSpinner }) }}>
                                                    <img src='../assets/images/clickhere.gif' referrerpolicy="no-referrer" />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        ((this.state.topNav && this.state.hotelFeedback && this.state.gridView === false))
                                        &&
                                        <div className="card-body">
                                            {
                                                (this.state.hotelName && this.state.hotelName != 'All' && this.state.elfsights.length > 0 && this.state.elfsights)
                                                    ?
                                                    <>
                                                        {hotelSocialMedia}

                                                    </>
                                                    :
                                                    <>
                                                        {socialMediaArray}
                                                    </>
                                            }
                                        </div>
                                    }

                                </div>

                                {
                                    this.state.gridView === true
                                    &&
                                    <>
                                        {
                                            (this.state.elfsightData && this.state.elfsightData != undefined)
                                            &&
                                            <div className="card">
                                                <div className="card-body">
                                                    <ElfsightWidget widgetID={this.state.elfsightData} />
                                                </div>
                                            </div>
                                        }
                                    </>
                                }

                                {
                                    this.state.gridView === false
                                    &&
                                    <>
                                        <div className="card">
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-xl-2 col-lg-2 col-md-4 col-sm-2 col-12">
                                                        <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                        <div className="form-group">
                                                            <Select
                                                                //  value={limitValue ? { label: limitValue.label, value: limitValue.value } : ''}
                                                                onChange={(e) => { this.setLimit(Number(e.value)); this.setState({ perPage: e.value }) }}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-4 col-lg-4 col-md-8 col-sm-6 col-12">
                                                        <label className="form-label">{languageData && languageData.STAR ? languageData.STAR : "Star"} {languageData && languageData.RATINGS ? languageData.RATINGS : "Ratings"}</label>
                                                        <div className="form-group">
                                                            <Select
                                                                isMulti={true}
                                                                value={selectrating}
                                                                onChange={this.setRatings}
                                                                options={this.state.starOptionStatus ? this.state.options : this.state.optionsStar}
                                                            />
                                                        </div>
                                                    </div>


                                                    {
                                                        account.allhotels?.length > 0 ?
                                                            ''
                                                            :
                                                            <>
                                                                <div className="col-xl-6 col-lg-6 col-md-4 col-sm-6 col-12">
                                                                    <label className="form-label">{languageData && languageData.SEARCH ? languageData.SEARCH : "Search"}  {languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel"}</label>
                                                                    <div className="form-group">
                                                                        <Select
                                                                            value={this.state.hotelValue}
                                                                            onChange={this.setHotel}
                                                                            options={hotelOptions}
                                                                            formatOptionLabel={(e) => (
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                                    <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                                </div>
                                                                            )}
                                                                            isDisabled={this.state.modalType == "View"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </>
                                                    }
                                                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-label">From Date</label>
                                                            <div className='site_pass_calander'>
                                                                <input type='date' className="form-control" name="fromDateReview" value={this.state.fromDateReview} onChange={this.setDateReport}></input>
                                                                <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className=" col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="form-group">
                                                            <label className="form-label">To Date</label>
                                                            <div className='site_pass_calander'>
                                                                <input type='date' className="form-control" name="toDateReview" min={this.state.fromDateReview ? this.state.fromDateReview : ''} onChange={this.setDateReport} value={this.state.toDateReview}></input>
                                                                <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-3 col-lg-3 col-md-4 col-sm-6 col-12">
                                                        <div className="form-group" style={ImportedURL.SUPERADMIN.includes(account.role) ? {} : { margin: "10px 0px 35px" }}>
                                                            <label className="form-label">Archived Reports</label>
                                                            <div className="selectgroup w-100">
                                                                <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                    <input type="radio" name="month" className="selectgroup-input" value={0} onChange={this.getMonthlyReport} checked={this.state.month === '0'} />
                                                                    <span className="selectgroup-button first_month">{this.getMonths(0)}</span>
                                                                </label>
                                                                <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                    <input type="radio" name="month" className="selectgroup-input" value={1} onChange={this.getMonthlyReport} checked={this.state.month === '1'} />
                                                                    <span className="selectgroup-button">{this.getMonths(1)}</span>
                                                                </label>
                                                                <label className="selectgroup-item" style={{ zIndex: 0 }}>
                                                                    <input type="radio" name="month" className="selectgroup-input" value={2} onChange={this.getMonthlyReport} checked={this.state.month === '2'} />
                                                                    <span className="selectgroup-button last_month">{this.getMonths(2)}</span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {this.state.hideReset ?
                                                        <div className=" col-xl-2 col-lg-2 col-md-4 col-sm-6 d-flex align-items-center" style={{ marginBottom: ImportedURL.SUPERADMIN.includes(account.role) ? '3px' : "13px" }}>
                                                            <button className='btn social_rest_btn btn-primarys' type='button' onClick={this.filterReset}><i className="fa fa-refresh" aria-hidden="true" style={{ fontSize: '20px !importent', marginRight: '7px' }}></i> Reset</button>
                                                        </div> : ''}
                                                </div>
                                            </div>
                                        </div>
                                        {rowData && rowData.length > 0 ?
                                            <>
                                                <div className="card">
                                                    <div className="card-body">
                                                        <div className="row">
                                                            {/* <SentimentAnalysis hotel={this.state.hotel} toDateReview={this.state.toDateReview} fromDateReview={this.state.fromDateReview} /> */}
                                                            <SentimentAnalysis hotel={this.state.hotel} datereport={{ fromDateReview: this.state.fromDateReview ? this.state.fromDateReview + "T00:00:00" : '', toDateReview: this.state.toDateReview ? this.state.toDateReview + "T23:59:59" : '' }} />
                                                        </div>
                                                        <div className='chart_collaps'>
                                                            <div id="accordion">
                                                                <div className="card">
                                                                    <div className="" id="headingOne">
                                                                        <h5 className="mb-0">
                                                                            <button className="btn" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                                Reviews - Graphical Representation<i className="fa fa-angle-down ml-2" aria-hidden="true"></i>
                                                                            </button>
                                                                        </h5>
                                                                    </div>

                                                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
                                                                        <div className="card-body">
                                                                            <div className='row'>
                                                                                <div className='col-xl-6 col-lg-6 col-md-12 col-12 border_right'>
                                                                                    {rowData && rowData.length > 0 ?
                                                                                        <div className="card" style={{ height: '350px' }}><div className="card-header">
                                                                                            <div className='rounded_icon'><i className="icon-star mr-2"></i></div>
                                                                                            <h3 className="card-title common_title_fs">{languageData && languageData.OTAS_WISE_PIE_CHART ? languageData.OTAS_WISE_PIE_CHART : "OTA-wise Pie Chart"}</h3></div>
                                                                                            <Chart options={this.state.reviewOptions} series={this.state.reviewSeries} type="pie" height="300" />
                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                            <div className="card" style={{ height: '350px' }}>
                                                                                                <div className="card-header">
                                                                                                    <div className='rounded_icon'><i className="icon-star mr-2 mr-2 "></i></div>
                                                                                                    <h3 className="card-title common_title_fs">{languageData && languageData.OTAS_WISE_PIE_CHART ? languageData.OTAS_WISE_PIE_CHART : "OTA-wise Pie Chart"}</h3>
                                                                                                </div>
                                                                                                <div className="card-header pt-0">
                                                                                                    <div className="card-body no_records text-center d-flex align-items-center justify-content-center pt-0">
                                                                                                        <div style={{ maxWidth: "340px" }}>
                                                                                                            <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid no_data_display_img" style={{ maxWidth: "272px" }} referrerpolicy="no-referrer" />
                                                                                                            <h5 className="mb-2">No records to display </h5>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                                <div className='col-xl-6 col-lg-6 col-md-12 col-12 '>
                                                                                    {rowData && rowData.length > 0 ?
                                                                                        <div className="card bar_chart_style" style={{ height: '350px' }}><div className="card-header">
                                                                                            <div className='rounded_icon'><i className="icon-star mr-2"></i></div>
                                                                                            <h3 className="card-title common_title_fs">{languageData && languageData.RATING_WISE_BAR_CHART ? languageData.RATING_WISE_BAR_CHART : "Rating-wise Bar Chart"}</h3></div>
                                                                                            <Chart options={starRatingvalue} series={this.state.barChartSeries} type="bar" height={250} />
                                                                                        </div>
                                                                                        :
                                                                                        <>
                                                                                            <div className="card" style={{ height: '350px' }}>
                                                                                                <div className="card-header">
                                                                                                    <div className='rounded_icon'><i className="icon-star mr-2 mr-2 "></i></div>
                                                                                                    <h3 className="card-title common_title_fs">{languageData && languageData.RATING_WISE_BAR_CHART ? languageData.RATING_WISE_BAR_CHART : "Rating-wise Bar Chart"}</h3>
                                                                                                </div>
                                                                                                <div className="card-header pt-0">
                                                                                                    <div className="card-body no_records text-center d-flex align-items-center justify-content-center pt-0">
                                                                                                        <div style={{ maxWidth: "340px" }}>
                                                                                                            <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid no_data_display_img" style={{ maxWidth: "272px" }} referrerpolicy="no-referrer" />
                                                                                                            <h5 className="mb-2">No records to display </h5>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className=''>
                                                    {
                                                        currentfeedbacks.map((data, i) => {
                                                            var supplierValue = ""
                                                            if (data.supplier == "hotels" || data.supplier == "booking") {
                                                                supplierValue = data.supplier + ".com"
                                                            } else if (data.supplier == "trip-advisor") {
                                                                supplierValue = "tripadvisor"
                                                            } else {
                                                                supplierValue = data.supplier
                                                            }
                                                            var imageSet = ''
                                                            if (data.supplier == "hotels") {
                                                                imageSet = '../../assets/images/hotels.png'
                                                            } else if (data.supplier == "trip-advisor") {
                                                                imageSet = '../../assets/images/tripadviser.png'
                                                            } else if (data.supplier == "booking") {
                                                                imageSet = '../../assets/images/booking.png'
                                                            } else if (data.supplier == "google") {
                                                                imageSet = '../../assets/images/google.webp'
                                                            } else if (data.supplier == "facebook") {
                                                                imageSet = '../../assets/images/fb.svg'
                                                            } else if (data.supplier == "yelp") {
                                                                imageSet = '../../assets/images/yelp.png'
                                                            } else if (data.supplier == "expedia") {
                                                                imageSet = '../../assets/images/ex.png'
                                                            }
                                                            return <div onClick={(e) => this.viewRedirectClick(data._id)} className="card" key={i}>
                                                                <div className="card-body socail_reviews_body">
                                                                    <article className="media">
                                                                        <div className="mr-3">
                                                                            <img className="avatar avatar-xl" referrerpolicy="no-referrer" src={data.reviewer_picture_url ?
                                                                                data.reviewer_picture_url : 'assets/images/defaultuser.png'}
                                                                                onError={(e) => { if (e.target.src !== "assets/images/defaultuser.png") { e.target.onerror = null; e.target.src = "assets/images/defaultuser.png"; } }
                                                                                }
                                                                                alt="" />
                                                                        </div>
                                                                        <div className="media-body">
                                                                            <div className="content">
                                                                                <div className=" revie_tit_v">
                                                                                    <div>
                                                                                        <span className='pr-2 h5 socail_reviews'>{data.reviewer_name}</span>
                                                                                        <span>
                                                                                            {this.getRatings(data.rating)}
                                                                                        </span>
                                                                                    </div>
                                                                                    <p className="">{moment(data.published_at).format(dFormat)}</p>
                                                                                </div>
                                                                                <p>
                                                                                    <a href={data.url} target='_blank' >
                                                                                        <small className='pr-2'>
                                                                                            <span className='review_Logo'>
                                                                                                <img src={imageSet} referrerpolicy="no-referrer" />
                                                                                            </span>
                                                                                            <a href={data.url} style={{ color: '#1c3078' }} target='_blank'>{supplierValue.charAt(0).toUpperCase() + supplierValue.substr(1).toLowerCase()}</a></small>
                                                                                        <p className="float-right">{data.hotel}</p>
                                                                                    </a>
                                                                                </p>
                                                                                <p className='label_black'>{data.title}</p>
                                                                                {data.text.length < 250 ? <span className='text' dangerouslySetInnerHTML={{ __html: data.text }}></span> : (
                                                                                    <>
                                                                                        {
                                                                                            this.state.readMoreValue == i ?
                                                                                                <span className='text' dangerouslySetInnerHTML={{ __html: this.state.isReadMore ? data.text : (data.text).slice(0, 250) + '...' }}></span>
                                                                                                :
                                                                                                <span className='text' dangerouslySetInnerHTML={{ __html: (data.text).slice(0, 250) + '...' }}></span>
                                                                                        }
                                                                                        < span className='read_more' onClick={(e) => this.toggleReadMore(e, i)}>{this.state.readMoreValue == i ? (this.state.isReadMore && (data.text).slice(0, 150) ? 'read less' : 'read more') : ' read more'}</span>
                                                                                    </>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    </article>
                                                                </div>
                                                            </div>
                                                        }
                                                        )
                                                    }

                                                    {
                                                        rowData.length > 0 ?
                                                            <>
                                                                {
                                                                    pageNumbers.length > 1
                                                                        ?
                                                                        <ul className="pagination mt-2 mb-2">
                                                                            {renderPrevBtn}
                                                                            {pageDecrementBtn}
                                                                            {renderPageNumbers}
                                                                            {pageIncrementBtn}
                                                                            {renderNextBtn}
                                                                        </ul>
                                                                        : <ul className="pagination mt-2 mb-2"></ul>
                                                                }
                                                            </>
                                                            : this.state.spinner ?
                                                                <div className="common_loader">
                                                                    <img className='loader_img_style_common' src='../../assets/images/load.png' referrerpolicy="no-referrer" />
                                                                    <Spinner className='spinner_load_common' animation="border" variant="info" >
                                                                    </Spinner>
                                                                </div>
                                                                :
                                                                <div className="card-body text-center d-flex align-items-center justify-content-center">
                                                                    <div style={{ maxWidth: "340px" }}>
                                                                        <img src="../assets/images/nothing-here.png" referrerpolicy="no-referrer" alt="..." className="img-fluid mb-4 mt-4 no_data_display_img" style={{ maxWidth: "272px" }} />
                                                                        <h5 className="mb-2">No Reviews Found</h5>
                                                                    </div>
                                                                </div>
                                                    }
                                                </div>
                                            </> :
                                            <div className="card" style={{ height: '350px' }}>
                                                <div className="card-header pt-0">
                                                    <div className="card-body no_records text-center d-flex align-items-center justify-content-center pt-0">
                                                        <div style={{ maxWidth: "340px" }}>
                                                            <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid no_data_display_img" style={{ maxWidth: "272px" }} referrerpolicy="no-referrer" />
                                                            <h5 className="mb-2">No records to display </h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        <div>
                                            {/* <Chart options={chartOptions} series={series} type="pie" width={500} height={320} /> */}
                                            {/* <Chart options={this.state.chartOptions} series={this.state.series} type="pie" width={500} height={320} /> */}
                                            {/* <Chart options={this.state.options} series={this.state.series} type="pie" width="800" height="500" /> */}

                                        </div>
                                    </>
                                }
                                {this.state.spinner &&
                                    <div>
                                        <div className='common_loader'>
                                            <img className='loader_img_style_common' src='../../assets/images/load.png' alt='' />
                                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                                            </Spinner>
                                        </div>
                                    </div>
                                }
                            </div >
                        </div >
                    </div >
                </div >
                <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div>
                {/* {this.state.onclickloader ?
                    <div className='common_loader'>
                        <img className='loader_img_style_common' src='../../assets/images/load.png' />
                        <Spinner className='spinner_load_common' animation="border" variant="info" >
                        </Spinner>
                    </div>
                    : ''} */}
            </>
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
    SocialMediaState: state.socialmedia,
    LandingState: state.landing,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListHotel: AC_LIST_HOTEL, ListSocialMedia: AC_LIST_SOCIALMEDIA,
        ListFeedback: AC_LIST_FEEDBACK, ReviewByClient: AC_GET_REVIEW_BY_ID,
        SendSentimentalAnalysisData: AC_SEND_SENTIMENTAL_ANALYSIS_DATA,
        ReviewEmptyKeyword: AC_REVIEW_KEYWORD_EMPTY
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ListSocialReviews);
import axios from 'axios';
import ImportedURL from '../common/api';

export function AC_HANDLE_TAB_CHANGE_REMINDER(value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_TAB_CHANGE_REMINDER", value: value })
    };
}

// ----------------------------- MOM Reminder ---------------------

export function AC_HANDLE_MOM_REMINDER_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_MOM_REMINDER_CHANGE", name: name, value: value })
    };
}

export function AC_VIEW_MOM_REMINDER(params) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.viewReminder, params)
            .then((res) => {
                dispatch({ type: 'VIEW_MOM_REMINDER', payload: res.data, spinner: false });
            })
    }
}

export function AC_EMPTY_MOM_REMINDER() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_MOM_REMINDER" })
    };
}

export function AC_EMPTY_SPINNER() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_SPINNER" })
    };
}
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

class DashboardCount extends Component {
    render() {
        const { spinner, count, to, icon, title } = this.props;
        return (
            <>
                <div className="col-6 col-md-6 col-xl-3">
                    <div className="card">
                        {
                            spinner
                                ?
                                <Skeleton className="skeleton-loader-box" />
                                :
                                <div className="card-body ribbon">
                                    <div className="ribbon-box orange">{count}</div>
                                    <Link className="my_sort_cut text-muted" to={to}>
                                        <span className='dashbord_icons'>  <i className={icon}></i> </span>
                                        <span className='dash_total'>{title}</span>
                                    </Link>
                                </div>
                        }
                    </div>
                </div>
            </>
        )
    }
}

export default DashboardCount;
const initialState = {
    reviewflow: {
        type: [],
        individualcategory: '',
        reviewuser: [],
        status: '',
        multiplecategory: [],
        id: '',
    },
    tabposition: 'socialreviews',
    spinner: true,
}



export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_TAB_CHANGE_REVIEW_FLOLW':
            return Object.assign({}, state, {
                tabposition: action.value
            })
        case 'HANDLE_REVIEW_FLOLW_CHANGE':
            return Object.assign({}, state, {
                reviewflow: {
                    ...state.reviewflow,
                    [action.name]: action.value
                }
            })
        case 'VIEW_REVIEW_FLOLW':
            return Object.assign({}, state, {
                reviewflow: action.payload,
                spinner: false,
            })
        case 'EMPTY_REVIEW_FLOLW':
            return Object.assign({}, state, {
                reviewflow: {
                    type: [],
                    individualcategory: '',
                    reviewuser: [],
                    status: '',
                    multiplecategory: [],
                    id: '',
                },
                spinner: true,
            })

        default:
            return state;
    }
}
import React, { Component } from 'react';
import Chart from 'react-apexcharts';

class ChartComponent extends Component {
    render() {
        const { options, series, type, width, height } = this.props;

        return (
            <Chart
                options={options}
                series={series}
                type={type}
                width={width}
                height={height}
            />
        );
    }
}

export default ChartComponent;
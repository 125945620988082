import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_PROBLEM_KEY_WORD_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_PROBLEM_KEY_WORD_CHANGE", name: name, value: value })
	};
}

export function AC_RESET_PROBLEM_KEY_WORD() {
	return function (dispatch) {
		dispatch({ type: "RESET_PROBLEM_KEY_WORD" })
	};
}


export function AC_LIST_PROBLEM_KEY_WORD(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listProblemKeyWord, { params: params })
			.then((res) => {
				dispatch({ type: "LIST_PROBLEM_KEY_WORD", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_VIEW_PROBLEM_KEY_WORD(data) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewProblemKeyWord + "/" + data)
			.then((res) => {
		dispatch({ type: "VIEW_PROBLEM_KEY_WORD", payload: res.data })
		}).catch((err) => { console.log(err); });
	};
}


export function AC_EMPTY_PROBLEM_KEY_WORD() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_PROBLEM_KEY_WORD" })
	}
}
export function AC_EMPTY_ARRAY_PROBLEM_KEY_WORD() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_ARRAY_PROBLEM_KEY_WORD" })
	}
}

export function AC_HANDLE_AMENITY_CHANGE(name, value, i) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_AMENITY_CHANGE", name: name, value: value, i: i })
    };
}

export function AC_HANDLE_PROBLEM_KEY_WORD_PLUS(name, value, id) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_PROBLEM_KEY_WORD_PLUS", name: name, value: value, id: id })
    };
}

import React, { Component } from 'react'

class ModalStatus extends Component {
    handleClick = (modalname) => {
        this.props[modalname]();
    }
    render() {
        const { modalname, image, content, buttonok, buttoncancel } = this.props;
        return (
            <div className="modal fade modal_status" id={modalname} tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-body">


                            <div className='logo'>
                                {image ? <img src={`../../assets/images/${image}`} onError={(e) => { e.target.style.display = 'none' }} /> : ''}
                            </div>
                            <div className='swal_content'>
                                <div className='swal_content_text'>
                                    <p>
                                        {content}
                                    </p>
                                </div>
                                <div className='modal_btn'>
                                    <button type="button" className="btn ok_btn" data-dismiss="modal" onClick={() => this.handleClick(modalname)}>{buttonok}</button>
                                    <button type="button" className="btn cancel_btn" data-dismiss="modal">{buttoncancel}</button>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div >
        )
    }
}
export default ModalStatus;
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../common/api';
import Select from 'react-select';
import Spinner from 'react-bootstrap/Spinner';
import { Link, Redirect } from 'react-router-dom';
import { Capitalize } from '../../common/validate';

const initialState = {
    modalType: "Add",
    columnApi: '',
    gridApi: '',
    columnDefs: [
        {
            headerName: 'Name', field: 'name', width: 200, cellStyle: { 'text-align': 'left' }, floatingFilter: true,
        },
        {
            headerName: 'Accommodation', field: 'hotelaccommodation', hide: true, floatingFilter: true, cellStyle: { 'text-align': 'left' },
            valueGetter: function (params) {
                if (params.data.hotelaccommodation == "yes") {
                    return params.data.fromdateandtodate ? params.data.fromdateandtodate : "---"
                } else {
                    return "---"
                }
            }
        },
        {
            headerName: 'Number of Guest', field: 'noofguest', width: 200, cellStyle: { 'text-align': 'center' }, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data.noofguest) {
                    return params.data.noofguest
                } else {
                    return "---"
                }
            }
        },
        {
            headerName: 'Flight Detail', field: 'venue', floatingFilter: true,
            valueGetter: function (params) {
                return params.data.venue + " " + params.data.flightlandingdate + " " + params.data.flightlandingtime
            },
            cellRenderer: function (params) {
                if (params.data) {
                    var venue = params.data.venue
                    var flightlandingdate = params.data.flightlandingdate
                    var flightlandingtime = params.data.flightlandingtime
                    return `
                <span style="color:#1c3078; position:absolute;bottom:7px">`+ venue + `</span> 
                <span style="position:absolute;top:11px ;" data-action-type="Copyemail">` + flightlandingdate + " " + flightlandingtime + `</span>
                `
                }
            }
        },
        {
            headerName: 'Food', field: 'food', width: 150, floatingFilter: true, hide: true,
            valueGetter: function (params) {
                if (params.data.food) {
                    return params.data.food
                } else {
                    return "---"
                }
            }
        },
        {
            headerName: 'Actions', field: 'actions', width: 85, cellStyle: { 'text-align': 'center' }, pinned: 'right', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    defaultColumDef: {
        "flex": 1,
        "filter": true,
        "sortable": true,
        "resizable": true,
    },
    spinner: false,
    rowData: [],
    viewData: {},
    perPage: 25,
    veg: 0,
    withoutonion: 0,
    nonveg: 0,
    vegClick: false,
    nonvegClick: false,
    withoutonionClick: false,
    totalClick: true
}
class ListDeveshGuest extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }


    componentDidMount() {
        this.onFetchData()
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    }
    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'View') {
            this.setState({ viewData: event.data });
        }
    }
    onFetchData = (query) => {
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.listDeveshGuest, query)
            .then((res) => {
                this.setState({
                    rowData: res.data.rowdata,
                    totalcount: res.data.totalcount,
                    withoutonion: res.data.withoutonion,
                    veg: res.data.veg,
                    nonveg: res.data.nonveg,
                    spinner: false
                })
            }).catch((err) => {
                this.setState({ spinner: false })
            })
    }
    total = e => {
        this.setState({ nonvegClick: false, withoutonionClick: false, vegClick: false, totalClick: true })
        this.onFetchData()
    }
    veg = e => {
        this.setState({ nonvegClick: false, withoutonionClick: false, vegClick: true, totalClick: false })
        this.onFetchData({ food: 'Regular' })
    }
    nonveg = e => {
        this.setState({ nonvegClick: true, withoutonionClick: false, vegClick: false, totalClick: false })
        this.onFetchData({ food: "Non Veg" })
    }
    withoutonion = e => {
        this.setState({ withoutonionClick: true, nonvegClick: false, vegClick: false, totalClick: false })
        this.onFetchData({ food: "Without onion & garlic" })
    }
    exportToCSV = () => {
        const account = this.props.AccountState.account;
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: ['name', 'noofguest', 'venue', 'food', 'hotelaccommodation'],
            fileName: "Devesh_Guest_List" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }
    exportPDF = () => {
        const account = this.props.AccountState.account;
        const api = this.gridApi;
        const firstRow = api.getFirstDisplayedRow();
        const lastRow = api.getLastDisplayedRow();

        const filteredRows = api.getModel().rowsToDisplay;
        const exportData = filteredRows
            .filter(rowNode => rowNode.rowIndex >= firstRow && rowNode.rowIndex <= lastRow)
            .map(rowNode => rowNode.data);

        const selectedProperties = ['name', 'noofguest', 'venue', 'food', 'hotelaccommodation']
        const selected = exportData.map((item, i) => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    if (property == "hotelaccommodation") {
                        let temp = item["fromdateandtodate"] ? item["fromdateandtodate"] : ''
                        selectedData[property] = (temp && item[property] == "yes") ? temp : "No"
                    } else if (property == "venue") {
                        var venue = item["venue"]
                        var flightlandingdate = item["flightlandingdate"] ? item["flightlandingdate"] : ''
                        var flightlandingtime = item["flightlandingtime"] ? item["flightlandingtime"] : ''
                        selectedData[property] = item[property] ? venue + " " + flightlandingdate + " " + flightlandingtime : "---"
                    } else {
                        selectedData[property] = item[property] ? item[property] : '---';
                    }
                }
            });
            return selectedData;
        });
        let data = {
            title: "Devesh Guest List",
            head: ['#', 'Name', "Number Of Guest", "Venue", "Food", "Accommodation"],
            body: selected,
            hotelNameExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hotelNameExport : '',
            hoteladdressExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "devesh_guest_list" + "_" + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }

    render() {
        if (this.state.modalType === "View") return <Redirect to={'/view-roomtype-enquiry/' + this.state.id} />
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const template = '<div class="userlist_view"><button  title="View" type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-eye"  data-action-type="View"></i></button>'
            + '</div>';

        if (this.state.rowData && this.state.rowData.length > 0) {
            this.state.rowData.forEach(object => {
                object.actions = template;
            });
        }
        let guestdetails = (this.state.viewData && this.state.viewData.guestdetails && this.state.viewData.guestdetails.length > 0) ? this.state.viewData.guestdetails : ''
        let viewData = this.state.viewData
        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span><h6 className="highlights_breadcrump">{languageData && languageData.DEVESH_GUEST ? languageData.DEVESH_GUEST : "Devesh Guest"} </h6></p>
                    </div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="row clearfix common_top_section">
                                                <div className="col-12 col-md-12 col-xl-3">
                                                    <div className="card">
                                                        <div className="card-body ribbon" style={{ backgroundColor: this.state.totalClick ? '#1c3078' : '', borderRadius: this.state.totalClick ? '10px' : '', border: this.state.totalClick ? "2px solid grey" : '', boxShadow: this.state.totalClick ? '0 0 5px #555' : '' }} >
                                                            <div className={`${!this.state.totalClick ? "ribbon-box orange" : "ribbon-box text-color white"} `}>{this.state.totalcount}</div>
                                                            <a className="my_sort_cut text-muted" href="#" onClick={this.total}>
                                                                <span className="dashbord_icons">
                                                                    <i className="fa fa-search" style={{ color: this.state.totalClick ? '#1c3078' : '#1c3078' }} id='total'></i>
                                                                </span>
                                                                <span className='dash_total' style={{ color: this.state.totalClick ? 'white' : '' }}>Total Guest</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-xl-3">
                                                    <div className="card">
                                                        <div className="card-body ribbon" style={{ backgroundColor: this.state.vegClick ? '#1c3078' : '', borderRadius: this.state.vegClick ? '10px' : '', border: this.state.vegClick ? "2px solid grey" : '', boxShadow: this.state.vegClick ? '0 0 5px #555' : '' }} >
                                                            <div className={`${!this.state.vegClick ? "ribbon-box orange" : "ribbon-box text-color white"} `}>{this.state.veg}</div>
                                                            <a className="my_sort_cut text-muted" href="#" onClick={this.veg}>
                                                                <span className="dashbord_icons">
                                                                    <i className="fa fa-circle" style={{ color: this.state.vegClick ? '#1c3078' : '#1c3078' }} id='total'></i>
                                                                </span>
                                                                <span className='dash_total' style={{ color: this.state.vegClick ? 'white' : '' }}>Veg</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-xl-3">
                                                    <div className="card">
                                                        <div className="card-body ribbon" style={{ backgroundColor: this.state.nonvegClick ? '#1c3078' : '', borderRadius: this.state.nonvegClick ? '10px' : '', border: this.state.nonvegClick ? "2px solid grey" : '', boxShadow: this.state.nonvegClick ? '0 0 5px #555' : '' }}>
                                                            <div className={`${!this.state.nonvegClick ? "ribbon-box orange" : "ribbon-box text-color white"} `}>{this.state.nonveg}</div>
                                                            <a className="my_sort_cut text-muted" href="#" onClick={this.nonveg}>
                                                                <span className="dashbord_icons">
                                                                    <i className="fa fa-stop-circle-o" style={{ color: this.state.nonvegClick ? '#1c3078' : '#1c3078' }}></i>
                                                                </span>
                                                                <span className='dash_total' style={{ color: this.state.nonvegClick ? 'white' : '' }}>Non-Veg</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-md-12 col-xl-3">
                                                    <div className="card">
                                                        <div className="card-body ribbon" style={{ backgroundColor: this.state.withoutonionClick ? '#1c3078' : '', borderRadius: this.state.withoutonionClick ? '10px' : '', border: this.state.withoutonionClick ? "2px solid grey" : '', boxShadow: this.state.withoutonionClick ? '0 0 5px #555' : '' }}>
                                                            <div className={`${!this.state.withoutonionClick ? "ribbon-box orange" : "ribbon-box text-color white"} `}>{this.state.withoutonion}</div>
                                                            <a className="my_sort_cut text-muted" href="#" onClick={this.withoutonion}>
                                                                <span className="dashbord_icons dashboard_icons_1">
                                                                    <i className="fa fa-exclamation-circle" style={{ color: this.state.withoutonionClick ? '#1c3078' : '#1c3078' }}></i>
                                                                </span>
                                                                <span className='dash_total' style={{ color: this.state.withoutonionClick ? 'white' : '' }}>Without Onion & Garlic</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-body history pt-3">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-street-view mr-2 "></i></div>
                                            <h3 className="card-title">{languageData && languageData.DEVESH_GUEST ? languageData.DEVESH_GUEST : "Devesh Guest"}</h3>
                                            <div className="card-options">
                                                {this.state.rowData && this.state.rowData.length > 0 ?
                                                    <>
                                                        <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                        <div className="dropdown-menu">
                                                            <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                            <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                        </div>
                                                    </>
                                                    : []}
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                    <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                            className='limit_size'
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="table-responsive">
                                                <div className={(this.state.rowData && this.state.rowData.length == 0) ? "ag-theme-alpine ag_grid_no_record_show" : "ag-theme-alpine"}>
                                                    <AgGridReact
                                                        rowHeight={82}
                                                        paginationPageSize={this.state.perPage}
                                                        cacheBlockSize={this.state.perPage}
                                                        pagination={true}
                                                        domLayout={"autoHeight"}
                                                        onRowClicked={this.onRowClicked}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        onGridReady={this.onGridReady}
                                                        columnDefs={this.state.columnDefs}
                                                        rowDragManaged={true}
                                                        rowData={this.state.rowData}
                                                        wrapText={true}
                                                        animateRows={true}
                                                        overlayNoRowsTemplate={"No rows to display"}
                                                    >
                                                    </AgGridReact>
                                                </div>
                                                <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div>
                                                {this.state.spinner ?
                                                    <div className='common_loader_ag_grid'>
                                                        <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                        </Spinner>
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal fade" id="viewmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog view_user_modal" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{languageData && languageData.VIEW ? languageData.VIEW : "View"} {languageData && languageData.DEVESH_GUEST ? languageData.DEVESH_GUEST : "Devesh Guest"}</h5>
                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='row'>
                                    <div className='col-lg-12 px-4'>
                                        <div className={guestdetails && guestdetails.length > 2 ? "Over_all_table_style table-responsive devesh_guest_view" : "Over_all_table_style table-responsive devesh_guest_view"}>
                                            <table className='table table-striped'>
                                                <thead>
                                                    <tr>
                                                        <th scope="col">First Name</th>
                                                        <th scope="col">Last Name</th>
                                                        <th scope="col">Phone Number</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {guestdetails && guestdetails.map((item, i) => {
                                                        return (
                                                            <tr>
                                                                <td>{item.firstname ? item.firstname : "---"}</td>
                                                                <td>{item.lastname ? item.lastname : '---'}</td>
                                                                <td>{item.phonenumber ? item.phonenumber : "---"}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                            {viewData.noofguest
                                                &&
                                                <p className='mt-4'>
                                                    <label className='label_black'>Number Of Guest</label>  <br />
                                                    <span>{viewData.noofguest}</span>
                                                </p>
                                            }
                                            {viewData.hotelaccommodation == "yes"
                                                &&
                                                <p className='mt-4'>
                                                    <label className='label_black'>Accommodation</label>  <br />
                                                    <span>{viewData.fromdateandtodate}</span>
                                                </p>
                                            }
                                            {viewData.venue
                                                &&
                                                <p className='mt-4'>
                                                    <label className='label_black'>Venue</label>  <br />
                                                    <span>{viewData.venue}</span>
                                                </p>
                                            }
                                            {viewData.flightlandingdate
                                                &&
                                                <p className='mt-4'>
                                                    <label className='label_black'>Flight Landing Date & Time</label>  <br />
                                                    <span>{viewData.flightlandingdate + " " + viewData.flightlandingtime}</span>
                                                </p>
                                            }
                                            {viewData.food
                                                &&
                                                <p className='mt-4'>
                                                    <label className='label_black'>Food</label>  <br />
                                                    <span>{viewData.food}</span>
                                                </p>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListDeveshGuest);
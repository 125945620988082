import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_USER_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_USER_CHANGE", name: name, value: value })
	};
}

export function AC_HANDLE_CHANGE_PMS_PRIVILEGES(name, value, type) {
	return function (dispatch) {
		if (type == 'user') {
			dispatch({ type: "HANDLE_USER_CHANGE", name: name, value: value })
		} else {
			dispatch({ type: "HANDLE_CATEGORY_CHANGE", name: name, value: value })
		}
	};
}

export function AC_RESET_USER() {
	return function (dispatch) {
		dispatch({ type: "RESET_USER" })
	};
}

export function AC_ADD_USER(formData) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.addUser, formData)
			.then((res) => {
				Success("User created successfully");
				dispatch({ type: "LIST_USER", payload: res.data })
				dispatch({ type: "RESET_USER" })
			}).catch(({ response }) => {
				if (response.status == 409) {
					Error('Email already exist for this hotel with same category')
				} else if (response.status == 400) {
					Error('Bad request')
				}
			});
	};
}

export function AC_LIST_USER(data = {}) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.listUser, data)
			.then((res) => {
				if (res.data)
					dispatch({ type: "LIST_USER", payload: res.data.data, total: res.data.total, result: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_LIST_USER_SEND_DATA(list = []) {
	return function (dispatch) {
		dispatch({ type: "LIST_USER", payload: list, total: 0, result: {}, spinner: false })
	};
}

export function AC_VIEW_USER(id) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewUser + "/" + id)
			.then((res) => {
				dispatch({ type: "VIEW_USER", payload: res.data })
				return res.data
			}).catch((err) => { console.log(err); });
	};
}

export function AC_UPDATE_USER(formData, id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.updateUser + "/" + id, formData)
			.then((res) => {
				Success(res.statusText);
				dispatch({ type: "LIST_USER", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_DELETE_USER(id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.deleteUser + "/" + id)
			.then((res) => {
				// Success(res.statusText);
				Success("User deleted successfully");
				dispatch({ type: "LIST_USER", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_SET_ALL_PREVILEGES(data) {
	return function (dispatch) {
		dispatch({ type: "SET_ALL_PREVILEGE", payload: data });
	};
}

// export function AC_RESET_SUBADMIN() {
// 	return function (dispatch) {
// 		dispatch({ type: "RESET_SUBADMIN" });
// 	};
// }

export function AC_CHANGE_PREVILEGE(index, key) {
	return function (dispatch) {
		dispatch({ type: "CHANGE_PREVILEGE", index, key });
	};
}

export function AC_LIST_USER_COUNT() {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listUserCount)
			.then((res) => {
				dispatch({ type: "LIST_USER", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_EMPTY_USER() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_USER" })
	};
}

export function AC_EMPTY_USER_LIST() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_USER_LIST" })
	};
}
import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Papa from "papaparse";
import { AC_SEND_REVIEW } from '../../actions/reviewAction';
import axios from 'axios';
import { Error, Success } from '../../common/swal';
import ImportedURL from '../../common/api';
import { Emailvalidate, Phonenumber, onErrorImage } from '../../common/validate';
import { Link } from 'react-router-dom';
import { Button, Modal, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { AiOutlineZoomIn } from "react-icons/ai";
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import { AC_HANDLE_INPUT_CHANGE } from '../../actions/accountAction';
import validator from 'validator';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import showSwalDialog from '../../utilities/Modal/swal';
import ErrorResponse from '../../utilities/Error/errorstatus';
export class Review extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hotelsList: [],
            selectedHotel: '',
            phone: '',
            name: '',
            roomno: '',
            email: '',
            cc: '',
            bulkfile: '',
            columns: '',
            nameError: false,
            phoneError: false,
            phoneValidError: false,
            roomnoError: false,
            defaultCC: '',
            initial: true,
            saving: false,
            emailError: false,
            spinner: false,
            hotelName: '',
            data: [],
            // State to store parsed data
            parsedData: '',
            //State to store table Column name
            tableRows: '',
            //State to store the values
            values: '',
            //model show 
            show: false,
            hotelOverAll: '',
            bulk: false,
            includeguesttouch: false,
        }
    }

    componentWillUpdate(nextProps) {
        const newaccount = nextProps.AccountState.account;
        const phonecode = nextProps.AccountState.account.phonecode;
        if (this.state.initial) {
            if (!ImportedURL.SUPERADMIN.includes(nextProps.AccountState.role)) {
                this.setState({ selectedHotel: newaccount.hotel, hotelName: newaccount.hotelName, hoteldata: { lastreviewupdated: newaccount.lastreviewupdated } })
            }
            if (phonecode) {
                this.setState({ cc: phonecode })
            }
            this.setState({ initial: false })
        }
    }

    handleClose = () => {
        this.setState({ show: false })
    }
    handleShow = () => {
        this.setState({ show: true })
    }
    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        const Error = name + 'Error';
        const ValidError = name + 'ValidError';
        this.setState({ [Error]: false })
        if (name === 'name') {
            this.setState({ name: value });
            var guestName = value;
            if (guestName) {
                this.setState({ [Error]: false })
            } else {
                this.setState({ [Error]: false })
            }
        }
        else if (name === 'phone') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var s3 = s2.slice(0, 10);
            var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            this.setState({ phone: val });
            if (val) {
                this.setState({ [Error]: false });
                if (Phonenumber(val)) {
                    this.setState({ [ValidError]: false })
                } else {
                    this.setState({ [ValidError]: true })
                }
            } else {
                this.setState({ [Error]: false, [ValidError]: false })
            }
        }
        else if (name === 'email') {
            this.setState({ email: value });
            let emailError = false;
            if (value) {
                if (!validator.isEmail(value)) {
                    emailError = true;
                } else {
                    const tld = value.split('.').pop();
                    if (tld.length < 2 || tld.length > 3) {
                        emailError = true;
                    }
                }
            }
            this.setState({ emailError: emailError })
        }
        else {
            this.setState({ [name]: value })
        }
    };
    handleHotelChange = (e) => {
        const { name, id, value } = e
        this.setState({ selectedHotel: value, hotelName: e.label, hotelOverAll: e, roomno: '', hotellogo: ImportedURL.FILEURL + e.logo, bulkfile: '', hoteldata: e.id });
        if (this.state.bulk) {
            document.getElementById('fileSet').value = '';
        }
        if (name == 'hotel') {
            this.props.HandleInputChangeAccount('showpositivefeedback', id.showpositivefeedback ? id.showpositivefeedback : false);
            this.props.HandleInputChangeAccount('reviewsurlexpdays', id.reviewsurlexpdays ? id.reviewsurlexpdays : 0);
            this.props.HandleInputChangeAccount('defaultreviewexpstatus', id.defaultreviewexpstatus ? id.defaultreviewexpstatus : false);
        }
    };
    handleRoomChange = (e) => {
        const { name, value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        if (name == 'roomno') {
            this.setState({ roomno: value })
        }
    }
    handleFileChange = (e) => {
        this.setState({ bulk: true })
        const hotelName = (this.state.hotelName).toLowerCase().replaceAll(' ', '_');
        e.stopPropagation();
        let superThis = this;
        superThis.setState({ show: true })
        // openFileDialog(e)
        // Check if user has entered the file
        if (e.target.files[0]) {
            const inputFile = e.target.files[0];
            if (hotelName !== inputFile.name.toLowerCase().split('.')[0]) {
                document.getElementById('fileSet').value = '';
            }

            const allowedExtensions = ["csv"];

            // Check the file extensions, if it not
            // included in the allowed extensions
            // we show the error
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                // Error("Input a csv file");
                return;
            }

            const reader = new FileReader();

            // Event listener on reader when the file
            // loads, we parse it and set the data.
            reader.onload = async ({ target }) => {
                const csv = Papa.parse(target.result, {
                    header: true,
                    skipEmptyLines: true,
                    complete: function (results) {
                        const rowsArray = [];
                        const valuesArray = [];
                        let csvData = results.data
                        // Iterating data to get column name and their values
                        for (let i = 0; i < csvData.length; i++) {
                            if (csvData[i]) {
                                rowsArray.push(Object.keys(csvData[i]));
                                valuesArray.push(Object.values(csvData[i]));
                            }
                        }
                        // Parsed Data Response in array format
                        superThis.setState({ parsedData: results.data })
                        // Filtered Column Names
                        superThis.setState({ tableRows: rowsArray[0] });

                        // Filtered Values
                        superThis.setState({ values: valuesArray });
                    },
                });
            };
            reader.readAsText(inputFile);

            // If input type is correct set the state
            this.setState({ bulkfile: inputFile });
        } else {
            const file = new File(['Dummy File Content'], hotelName + '.csv', { type: 'text/plain' });
            const fileList = new DataTransfer();
            fileList.items.add(file);
            const fileInput = document.getElementById('fileSet');
            fileInput.files = fileList.files;
        }
    };


    sendReview = () => {
        const { LandingState, AccountState } = this.props;
        let hotel = this.state.selectedHotel;
        const phoneCode = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[1] : [];;
        const languageData = AccountState.account.SelectedlanguageData

        // let phone = this.state.cc + this.state.phone;
        if (!hotel) return Error((languageData && languageData.SELECT_A_HOTEL ? languageData.SELECT_A_HOTEL : "Select a hotel"));

        let number = this.state.phone;
        let cc = this.state.cc ? this.state.cc : this.state.defaultCC;
        let roomno = this.state.roomno;
        let name = this.state.name.trim();
        let valid = 1
        if (!roomno) {
            valid = false
            this.setState({ roomnoError: true });
        }
        if (!name) {
            valid = 0
            this.setState({ nameError: true });
        }
        if (!number) {
            valid = 0
            this.setState({ phoneError: true });
        }
        if (this.state.emailError) {
            valid = 0
        }
        if (this.state.phoneValidError) {
            valid = 0
        }
        let ccValue = '';
        if (phoneCode) {
            phoneCode.map(item => {
                if (this.state.cc) {
                    if (item.phonecode == this.state.cc) {
                        ccValue = item.phonecode
                    }
                    if (item.code == this.state.cc) {
                        ccValue = item.phonecode
                    }
                }
            })
        }

        let formData = {
            hotel: hotel,
            phone: number,
            cc: ccValue,
            name: name,
            roomno: this.state.roomno,
            email: this.state.email.trim(),
            name: name,
            logo: this.state.hotellogo,
            custommessage: this.state.custommessage && this.state.custommessage.trim() ? this.state.custommessage : '',
            checkoutdate: this.state.checkoutdate ? this.state.checkoutdate : new Date()
        }

        if (valid) {
            showSwalDialog('Are you sure want to send the message?', '', 'Send', 'Cancel', '../../assets/images/status.png').then((result) => {
                if (result.isConfirmed) {
                    this.setState({ saving: true, spinner: true })
                    axios.post(ImportedURL.API.sendReview, formData)
                        .then((res) => {
                            this.setState({ saving: false })
                            Success((languageData && languageData.MESSAGE_SENT_SUCCESSFULLY ? languageData.MESSAGE_SENT_SUCCESSFULLY : "Message sent successfully"));
                            this.setState({ name: '', phone: '', roomno: '', email: '', checkoutdate: '', spinner: false, custommessage: '' })
                        }).catch(({ response }) => {
                            this.setState({ saving: false, spinner: false })
                            ErrorResponse(response, languageData)
                        })
                }
            });
        }

    };

    SubmitForm = () => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        let hotel = this.state.selectedHotel;
        if (!hotel) return Error("Selct a hotel");
        if (!this.state.bulkfile) return Error("Upload valid file");
        // axios.post(ImportedURL.API.sendBulkReview, { data: this.state.bulkData, hotel: hotel, logo: this.state.hotellogo, })
        //     .then((res) => {
        //         Success("Message sent successfully")
        //         this.setState({ bulkfile: '' });
        //         document.getElementById('fileSet').value = '';
        //     }).catch((err) => { console.log(err); });

        let bulkfileconfirmation = this.state.bulkfile?.name && this.state.bulkfile?.name.split('.')[0];
        let hotelName = (this.state.hotelName).replaceAll(' ', '_');
        if (bulkfileconfirmation.toLowerCase() == hotelName.toLowerCase()) {
            showSwalDialog('Are you sure you are sending the message?', '', 'Send', 'Cancel', '../../assets/images/invitenew.png').then((result) => {
                if (result.isConfirmed) {
                    this.setState({ spinner: true })
                    axios.post(ImportedURL.API.sendBulkReview, { data: this.state.parsedData, hotel: hotel, logo: this.state.hotellogo })
                        .then((res) => {
                            this.props.ListHotel();
                            this.setState({ bulk: false })
                            Success((languageData && languageData.MESSAGE_SENT_SUCCESSFULLY ? languageData.MESSAGE_SENT_SUCCESSFULLY : "Message sent successfully"))
                            this.setState({ bulkfile: '', spinner: false });
                            document.getElementById('fileSet').value = '';
                            this.setState({ hotelOverAll: '', hotelName: '' })
                        }).catch((err) => {
                            this.setState({ spinner: false });
                            console.log(err);
                        });
                }
            })
        } else {
            showSwalDialog('Sorry, Could not send your bulk message because hotel name & filename did not match', '', 'Ok', 'Cancel', 'assets/images/status.png').then((result) => {
                if (result.isConfirmed) {
                }
            })
        }

    }
    handlePhoneCode = e => {
        this.setState({ cc: e.value, defaultCC: '' })
    }
    componentDidMount() {
        var phonecode = localStorage.getItem("phonecode");
        this.setState({ defaultCC: phonecode })
    }
    copyHotelName = () => {
        navigator.clipboard.writeText((this.state.hotelName).toLowerCase().replaceAll(' ', '_'));
        Success('Copied')
        let btn = document.getElementById("closeModal");
        btn.click();
        this.setState({ show: false })
    }


    render() {
        const { AccountState, LandingState } = this.props;
        const account = AccountState.account;
        const listhotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[0] : [];
        const phoneCode = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[1] : [];

        const languageData = AccountState.account.SelectedlanguageData
        var selectedHotel = '';
        const hotelOptions = [];

        if (account?.allhotels?.length > 0) {
            account.allhotels.map((item) => {
                if (account.hotel == item.hotelid) {
                    selectedHotel = { label: `${item.name} ${item.city} ${item.state}`, value: item.hotelid, logo: item.logo, id: item }
                }
                hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item.hotelid, logo: item.logo, id: item });
            })
        } else {
            if (this.state.includeguesttouch)
                listhotels.filter(filterItem => filterItem.status === true && filterItem.tripsadvisorurl && filterItem.googlereviewurl).map((item) => {
                    hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'hotel', id: item });
                })
            else
                listhotels.filter(filterItem => filterItem.status === true && filterItem.tripsadvisorurl && filterItem.googlereviewurl && !filterItem.guesttouchid).map((item) => {
                    hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'hotel', id: item });
                })
        }

        let ccData = '';
        if (phoneCode) {
            phoneCode.map(item => {
                if (this.state.cc) {
                    if (item.phonecode == this.state.cc) {
                        ccData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                    if (item.code == this.state.cc) {
                        ccData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                }
                else {
                    if (item.phonecode === account.phonecode) {
                        ccData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                    if (item.code === account.phonecode) {
                        ccData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                }
            })
        }
        const bulkfileconfirmation = this.state.bulkfile?.name && this.state.bulkfile?.name.toLowerCase().split('.')[0];
        const formate = this.state.bulkfile?.name && this.state.bulkfile?.name.toLowerCase().split('.')[1];
        const hotelName = (this.state.hotelName).toLowerCase().replaceAll(' ', '_');

        const roomData = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[8] : [];;
        const RoomArray = [];
        var selectedRoom = "";
        if (roomData && roomData.length > 0) {
            roomData.filter(filterItem => filterItem.status == true).map((item, i) => {
                if (account?.allhotels?.length > 0) {
                    if (account?.hotel === item.hotel) {
                        RoomArray.push(item)
                    }
                } else {
                    if (this.state?.selectedHotel === item.hotel) {
                        RoomArray.push(item)
                    }
                }
                if (this.state.roomno) {
                    if (this.state.roomno == (item.room && item.room.roomno)) {
                        selectedRoom = { label: `${item.room.roomno}`, id: item._id }
                    }
                }
            })
        }
        RoomArray?.sort((a, b) => Number(a.room) - Number(b.room));
        return (
            <>
                <div className="section-body ">
                    <div className='review_sec'>
                        <div className="container-fluid pt-3">
                            <div className="breadcrump send-review-bread">
                                <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/reviews"><h6 className="highlights_breadcrump">{languageData && languageData.SEND_REVIEW ? languageData.SEND_REVIEW : "Send Reviews"}</h6></Link></p>
                            </div>
                        </div>
                        <div className="container-fluid pt-3">
                            <div className="card mb-0">
                                <div className="card-header">
                                    <div className='rounded_icon'><i className="icon-star mr-2 "></i></div>
                                    <h3 className="card-title common_title_fs">{languageData && languageData.SEND_REVIEW ? languageData.SEND_REVIEW : "Send Reviews"}</h3>
                                    {
                                        this.state.selectedHotel
                                            ?
                                            <>
                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <ul className="nav nav-tabs page-header-tab">
                                                        </ul>
                                                        <div className="header-action send_reviews">
                                                            <Link to="/site-setting#form"><img className='gif-css' src='../../assets/images/timergif.gif' onClick={() => this.props.HandleSiteChange('reviewcard', true)} />
                                                                <label className="form-label blinking-text">{"Temporal validity for " + account.reviewsurlexpdays + ((account.reviewsurlexpdays && account.reviewsurlexpdays == 1) ? ' day' : ' days')}</label></Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                            : ''
                                    }
                                </div>
                                {ImportedURL.SUPERADMIN.includes(account.role) ?
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group" style={ImportedURL.SUPERADMIN.includes(account.role) ? {} : { fontSize: "18px" }}>
                                                    {
                                                        account.allhotels?.length > 0 ?
                                                            <>
                                                                <div className="text-center ribbon">
                                                                    <img className="rounded-circle img-thumbnail w100 h100 " src={ImportedURL.FILEURL + selectedHotel.logo} alt="fake_url" />
                                                                    <h6 className="mt-3 mb-0">{selectedHotel.label}</h6>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <Select
                                                                    value={this.state.hotelOverAll ? this.state.hotelOverAll : ''}
                                                                    placeholder="Select Hotel"
                                                                    name="hotel"
                                                                    options={hotelOptions}
                                                                    onChange={this.handleHotelChange}
                                                                    formatOptionLabel={(e) => (
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                            <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                        </div>
                                                                    )}
                                                                    isDisabled={this.state.modalType == "View"}
                                                                />
                                                                <label style={{ marginTop: '10px', display: 'inline-block' }}>Note : Select hotel to send a text message or bulk message</label>
                                                                <span class="form-label-small" style={{ marginTop: '10px' }}><i class={this.state.includeguesttouch ? "fa fa-toggle-on pr-1" : "fa fa-toggle-off pr-1"} onClick={() => { this.setState({ includeguesttouch: !this.state.includeguesttouch }) }} data-toggle="tooltip" title="fa fa-toggle-on" style={{ fontSize: "24px", cursor: 'pointer' }}></i>
                                                                    <span style={{ position: 'relative', bottom: '4px' }}> Include guesttouch</span></span>

                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                {this.state.hotelName != '' || selectedHotel ?
                                    <div style={{ backgroundColor: '#fff' }}>
                                        <div className="d-flex justify-content-between align-items-center ">
                                            <ul className="nav nav-tabs page-header-tab bulk_tab">
                                                <li className="nav-item"><a className="nav-link active" id="user-tab" data-toggle="tab" href="#user-add">Bulk Message</a></li>
                                                <li className="nav-item"><a className="nav-link " id="user-tab" data-toggle="tab" href="#user-list">Text Message</a></li>
                                            </ul>
                                        </div>
                                        <div className="tab-content pt-3">
                                            <div className="tab-pane fade " id="user-list" role="tabpanel">
                                                <div className="col-lg-12 px-0">
                                                    <div className="card">
                                                        <div className="card-header px-0">
                                                            <div className='rounded_icon'><i className="fa fa-comments-o mr-2 "></i></div>
                                                            <h3 className="card-title common_title_fs">Text message</h3>
                                                        </div>
                                                        <div className="card-body  px-0">
                                                            <div className="row">
                                                                <div className="col-4">
                                                                    <div className="form-group">
                                                                        <label className="form-label" >Guest Room No.<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                        <Select
                                                                            value={selectedRoom}
                                                                            onChange={this.handleRoomChange}
                                                                            options={RoomArray && RoomArray.filter(filterItem => filterItem.status === true).map((item, i) => {
                                                                                return {

                                                                                    label: item.room.roomno, value: item.room.roomno, name: 'roomno',
                                                                                }
                                                                            })} />
                                                                        <div className="invalid-feedback" style={{ display: this.state.roomnoError ? "block" : 'none' }}> Guest Room is required </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 col-md-4">
                                                                    <div className="form-group">
                                                                        <label className="form-label" >Guest Email</label>
                                                                        <input type="email" className="form-control" placeholder="Guest Email" value={this.state.email} onChange={this.handleChange} name="email" />
                                                                        <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Enter valid guest email</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 col-md-4">
                                                                    <div className="form-group">
                                                                        <label className="form-label" >Guest Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                        <input type="text" className="form-control" placeholder="Guest Name" value={this.state.name} onChange={this.handleChange} name="name" />
                                                                        <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Guest Name is required</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-4 col-md-4">
                                                                    <div className="row">
                                                                        <label className="form-label" >Guest phone #<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                        <div className="col-sm-5 col-md-5 pr-0">
                                                                            <div className="form-group country_code">
                                                                                <Select
                                                                                    isDisabled={this.state.modalType == "View"}
                                                                                    value={ccData ? ccData : ''}
                                                                                    onChange={this.handlePhoneCode}
                                                                                    options={phoneCode && phoneCode.filter(filterItem => filterItem.status === true).map(item => {
                                                                                        return {
                                                                                            label: item.phonecode,
                                                                                            flag: item.flag,
                                                                                            value: item.code,
                                                                                            name: 'cc'
                                                                                        }
                                                                                    })}
                                                                                    formatOptionLabel={(item) => {
                                                                                        return (
                                                                                            <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.label }} />
                                                                                        )
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-7 col-md-7 pl-0">
                                                                            <div className="form-group">
                                                                                <input type="text" className="form-control ml-1" placeholder="Guest phone #" name="phone" value={this.state.phone} onChange={this.handleChange} />
                                                                                <div className="invalid-feedback" style={{ display: this.state.phoneError ? "block" : 'none' }}>Guest phone # is required</div>
                                                                                <div className="invalid-feedback" style={{ display: this.state.phoneValidError ? "block" : 'none' }}>Guest phone # should contain 10 digits</div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div className="form-group">
                                                                        <label className="form-label">Check Out Date</label>
                                                                        <div className='site_pass_calander'>
                                                                            <input type="date" className="form-control" max={new Date().toISOString().split('T')[0]} name="checkoutdate" value={this.state.checkoutdate} onChange={this.handleChange} />
                                                                            <i class="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4 col-md-4">
                                                                    <div className="form-group">
                                                                        <label className="form-label">Custom message</label>
                                                                        <div className='site_pass_calander'>
                                                                            <textarea type="text" placeholder='Custom message' className="form-control" name="custommessage" value={this.state.custommessage} onChange={this.handleChange} />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="card-footer text-right" style={{ padding: "20px 0px" }}>
                                                                {this.state.name && <p style={{ margin: 'auto', width: '350px', textAlign: 'justify' }}>Hi {this.state.name}, {this.state.custommessage ? this.state.custommessage : `Thank you for staying with us. Can you take 30 seconds to leave us your feedback? The link below makes it super easy. ${ImportedURL.LOCALURL}`}</p>}
                                                                <button type="submit" className="btn btn-primary" onClick={this.sendReview}>
                                                                    <i className="fa fa-paper-plane-o mr-2" aria-hidden="true"></i>Send
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade active show" id="user-add" role="tabpanel">

                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-body-bulk-message px-0">
                                                            <div className='csv_main'>
                                                                <div className="card-header bulk_message_header">
                                                                    <div className='rounded_icon'><i className="fa fa-comments-o mr-2 "></i></div>
                                                                    <h3 className="card-title common_title_fs">Bulk message</h3>
                                                                </div>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    {/* <ul className="nav nav-tabs page-header-tab">
                                                                    </ul> */}
                                                                    <div className="page-header" style={{ padding: "0px" }}>
                                                                        <div className="right">
                                                                            {hotelName != '' &&
                                                                                <ul className="nav nav-pills">
                                                                                    <li className="nav-item dropdown" style={{ background: '#be7b08', borderRadius: "8px" }}>
                                                                                        <a href="assets/review-sample.csv" download={hotelName + ".csv"} style={{ color: '#fff' }} className="nav-link" aria-haspopup="true"><i className='fa fa-download px-2'></i> Download Sample CSV</a>
                                                                                    </li>
                                                                                </ul>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-6 col-md-12">
                                                                    <div className="form-group">
                                                                        <label className="form-label">Upload CSV file<span className="ml-1" style={{ color: 'red' }}>*</span> <span class="form-label-small">{this.state.hoteldata && this.state.hoteldata.lastreviewupdated ? `Last updated: ${moment(this.state.hoteldata.lastreviewupdated).format('MM/DD/YYYY hh:mm A')}` : ''}</span></label>
                                                                        <input type="file" accept=".csv" className="form-control" id='fileSet' onChange={this.handleFileChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.state.bulkfile != '' ?
                                                                <div className='csv_log_setup' style={{ display: this.state.hotelName == '' ? 'none' : 'block' }}>
                                                                    <div className='img_icon' >
                                                                        <div className='img_size'>
                                                                            <img src='../assets/images/csv-viewer.png' alt='not found' />
                                                                        </div>
                                                                        <div className='csv_overlay' onClick={this.handleShow}></div>
                                                                        <span onClick={this.handleShow}><AiOutlineZoomIn className='zoom_i' /></span>
                                                                    </div>
                                                                </div>
                                                                : ''}

                                                            {/* --------------- csv data shhowin model --------------------- */}

                                                            <div className='csv_model'>
                                                                <Modal className={(bulkfileconfirmation == hotelName) ? 'info-modal' : ''} show={this.state.show} onHide={this.handleClose} size={(bulkfileconfirmation == hotelName) ? "lg" : ""} >
                                                                    {/* <div className="modal-content" style={(bulkfileconfirmation == hotelName) ? { height: '500px', overflow: 'auto' } : {}}> */}
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>{formate === 'csv' ? "BULK MESSAGE PREVIEW" : "Invalid file format"}</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body style={(bulkfileconfirmation == hotelName) ? { height: '500px', overflow: 'auto' } : {}}>
                                                                        {(bulkfileconfirmation == hotelName) ?
                                                                            <>
                                                                                <div className='Over_all_table_style table_head_top'>
                                                                                    <table className='w-100 bulk_msg_table'>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                {this.state.tableRows && this.state.tableRows.map((rows, index) => {
                                                                                                    return <th key={index} style={{ padding: '10px !important' }}>{rows}</th>;
                                                                                                })}
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {this.state.values.length > 0 && this.state.values.map((value, index) => {
                                                                                                return (
                                                                                                    <tr key={index} className='px-2'>
                                                                                                        {value.map((val, i) => {
                                                                                                            return <td key={i} style={{ padding: '10px !important' }}>{val ? val : "---"}</td>;
                                                                                                        })}
                                                                                                    </tr>
                                                                                                );
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {formate === 'csv' ?
                                                                                    <>
                                                                                        <label className='wrong_file_text'>
                                                                                            <p style={{ color: '#d9534f' }}>Upload file name should be same as the hotel name.
                                                                                            </p>
                                                                                            <p>Change file name as <label onClick={this.copyHotelName} id="closeModal" aria-label="Close">{hotelName} <i className="fa fa-clone ml-2" ></i></label></p>
                                                                                        </label>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <label className='wrong_file_text'>
                                                                                            <p style={{ color: '#d9534f' }}>Invalid file formate.
                                                                                                Upload only (csv) file.
                                                                                            </p>
                                                                                        </label>
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </Modal.Body>
                                                                    {/* </div> */}
                                                                </Modal>
                                                            </div>
                                                            <div className="card-footer text-right" style={{ padding: "20px 0px" }}>
                                                                {
                                                                    this.state.saving ?
                                                                        <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>sending</button>
                                                                        :
                                                                        <button type="submit" className="btn commor_save" onClick={this.SubmitForm}>
                                                                            <i className="fa fa-paper-plane-o mr-2" aria-hidden="true"></i> Send
                                                                        </button>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div>
                {this.state.spinner &&
                    <div className="common_loader">
                        <img className='loader_img_style_common' src='../../assets/images/load.png' />
                        <Spinner className='spinner_load_common' animation="border" variant="info" >
                        </Spinner>
                    </div>
                }

            </>
        );
    }
}

const mapStateToProps = state => ({
    AccountState: state.account,
    LandingState: state.landing,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        sendReview: AC_SEND_REVIEW,
        HandleInputChangeAccount: AC_HANDLE_INPUT_CHANGE,
        ListHotel: AC_LIST_HOTEL,
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Review);



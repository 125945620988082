import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom';
import ImportedURL from '../../../common/api';
import { HiOutlineChevronRight, HiOutlineChevronLeft } from "react-icons/hi";
import Select from 'react-select';
import moment from 'moment';
import { AC_EMPTY_TO_DO_LIST, AC_LIST_TO_DO_LIST_REPORT, AC_MODAL_SPINNER_TO_DO_LIST, AC_SPINNER_TO_DO_LIST, AC_VIEW_TO_DO_LIST } from '../../../actions/todolistAction';
import axios from 'axios';
import AgGridComponent from '../../../utilities/AgGridReact/aggrid';
import Viewmodal from '../Allmytask/viewmodal';
import HotelSelectComponent from '../../../utilities/SelectOption/hotelselect';
import SelectComponent from '../../../utilities/SelectOption/select';
import { AC_LIST_USER } from '../../../actions/userAction';
import { AC_LIST_CONFIGOPTIONS_TODO_STATUS } from '../../../actions/configurationAction';
import { imageCellRendererList } from '../../../common/validate';
import Spinner from '../../../utilities/Spinner/spinner';

class TodoReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            columnDefs: [
                {
                    headerName: 'Logo', field: 'logo', sortable: false, hide: true, filter: false, "resizable": false, width: 100, cellStyle: { 'text-align': 'center' },
                    cellRenderer: imageCellRendererList,
                },
                { headerName: 'Hotel', field: 'hotel', hide: true, width: 200 },
                { headerName: 'Title', field: 'title', width: 200, floatingFilter: true },
                {
                    headerName: 'Description', field: 'description', width: 200, floatingFilter: true,
                    valueGetter: function (params) {
                        if (params.data)
                            if (params.data.description) {
                                return params.data.description
                            } else {
                                return "---"
                            }
                    }
                },
                { headerName: 'User Category', field: 'todousercategory', width: 200, floatingFilter: true },
                { headerName: 'User Name', field: 'todouser', width: 200, floatingFilter: true },
                {
                    headerName: 'Start Date', field: 'start', width: 200, floatingFilter: true,
                    valueGetter: function (params) {
                        if (params.data)
                            if (params.data.start) {
                                return moment(params.data.start).format(params.data.dFormat)
                            } else {
                                return "---"
                            }
                    }
                },
                {
                    headerName: 'Status', width: 250, field: 'todostatus', cellStyle: { 'text-align': 'center' }, suppressMenu: true,
                    cellRenderer: function (params) {
                        if (params.value === 'inprogress') {
                            return '<span class="tag ticket_inprogress">Inprogress</span>';
                        } else if (params.value === 'completed') {
                            return '<span class="tag ticket_completed">Completed</span>';
                        } else if (params.value == 'upcoming') {
                            return `<span class="tag ticket_open">Upcoming</span>`;
                        } else if (params.value == 'todo') {
                            return `<span class="tag ticket_open">Todo</span>`;
                        } else if (params.value == 'pending') {
                            return `<span class="tag ticket_inreview">Pending</span>`;
                        }
                    }
                },
                {
                    headerName: 'Actions', width: 100, field: 'actions', headerClass: 'ag-center-header', cellStyle: { 'text-align': 'center' }, suppressMenu: true, sortable: false, filter: false,
                    cellRenderer: function (params) {
                        if (params.value) {
                            return params.value;
                        } else {
                            return "---"
                        }
                    }
                },
            ],
            defaultColumDef: {
                editable: false,
                sortable: true,
                resizable: true,
                filter: true,
                flex: 1,
                minWidth: 100,
            },
            perPage: 25,
            searchhoteloption: { label: 'All', value: '', name: 'searchhotel' },
            searchcategoryoption: { label: 'All', value: '', name: 'searchcategory' },
            searchuseroption: { label: 'All', value: '', name: 'searchuser' },
            editStatus: false,
            isFilterShow: false,
            id: '',
            calendarType: 'months',
            startdate: '',
            enddate: '',
            pagecount: 0,
            date: '',
            columnreset: true,
            totalStatus: true,
            todoStatus: false,
            inprogressStatus: false,
            completedStatus: false,
            pendingStatus: false,
        }
    }
    componentDidMount() {
        const account = this.props.AccountState.account;
        this.props.ListUser({ status: true });
        this.props.ListTodoStatus({ parentslug: 'todo-status' });
        let date = moment(new Date()).format('MM-DD-YYYY hh:mm A')
        this.setState({ hotelName: account.hotelName ? account.hotelName : '', hotel: account.hotel ? account.hotel : '', startdate: date });
        this.getDates(0, this.state.calendarType);
    }
    componentDidUpdate(prevProps, prevState) {
        const { AccountState } = this.props;
        const role = AccountState.role;
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role) && this.state.columnreset) {
            let updatedColumnDefs = [...this.state.columnDefs];
            updatedColumnDefs = updatedColumnDefs.map((col) => {
                if (col.field === 'hotel' || col.field === 'logo') {
                    return { ...col, hide: false };
                }
                return col;
            });
            this.gridApi.setColumnDefs(updatedColumnDefs);
            this.setState({ columnreset: false })
        }
    }
    apiCall = (query = {}) => {
        this.props.spinnerTodo();
        this.props.ListTodoReport({ startdate: this.state.startdate, enddate: this.state.enddate, hotel: this.state.hotel, category: this.state.category, user: this.state.user, todostatus: this.state.todostatus, ...query })
    }
    viewTodo = (id, date = '') => {
        this.setState({ id: id, date: date });
        this.props.spinnerModalTodo();
        this.props.ViewTodo(id);
    }

    onChangePage = (type) => {
        let count = this.state.pagecount;
        if (type === 'next') {
            count++;
        } else if (type === 'previous') {
            count--;
        } else if (type === 'today') {
            count = 0;
        }
        this.setState({ pagecount: count });
        this.getDates(count, this.state.calendarType);
    };

    onChangeCalendar = (type) => {
        this.setState({ calendarType: type, pagecount: 0 }, () => {
            this.getDates(0, type);
        });
    };

    getDates = (count, calendarType) => {
        const { tzone } = this.props.AccountState.account;
        let currentDate = moment().tz(tzone);
        let name = calendarType ? calendarType.slice(0, -1) : '';
        let startOfDate = currentDate.clone().add(count, calendarType).startOf(name);
        let endOfDate = currentDate.clone().add(count, calendarType).endOf(name);
        let start = moment(startOfDate).format("YYYY-MM-DD");
        let end = moment(endOfDate).format("YYYY-MM-DD");
        this.setState({ startdate: start, enddate: end });
        this.apiCall({ startdate: start, enddate: end });
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };
    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'View') {
            this.setState({ id: event.data.id, date: event.data.start });
            this.props.EmptyTodoList();
            this.props.spinnerModalTodo();
            this.props.ViewTodo(event.data.id);
        }
    }
    handleFilterChanged = () => {
        const api = this.gridApi;
        if (api && api.getDisplayedRowCount() === 0) {
            api.showNoRowsOverlay();
            this.setState({ hideExport: true })
        } else {
            api.hideOverlay();
            this.setState({ hideExport: false })
        }
    };
    getDasbBoardName = (name) => {
        const listTodoStatusArray = this.props.ConfigurationState.listTodoStatus;
        let find = listTodoStatusArray.find((e) => e.suboption == name)
        return find ? find.name : ''
    }
    searchSelect = e => {
        const account = this.props.AccountState.account;
        const { name, value, label } = e;
        let apiName = name ? name.replace(/search/g, "") : '';
        this.setState({ [name + 'option']: e, [name]: value, [apiName]: value })
        if (name == 'searchhotel') {
            this.apiCall({ [apiName]: value, category: '', user: '' });
            this.setState({ hotelName: label, searchcategory: '', searchuser: '', category: '', user: '', searchcategoryoption: { label: 'All', value: '', name: 'searchcategory' }, searchuseroption: { label: 'All', value: '', name: 'searchuser' } });
            if (label == "All") this.setState({ isFilterShow: false }); else this.setState({ isFilterShow: true })
        } else if (name == 'searchcategory') {
            this.apiCall({ [apiName]: value, user: '' });
            this.setState({ searchuser: '', user: '', searchuseroption: { label: 'All', value: '', name: 'searchuser' } });
            if (label == "All" && !ImportedURL.SUPERADMIN.includes(account.role)) this.setState({ isFilterShow: false }); else this.setState({ isFilterShow: true })
        } else {
            this.apiCall({ [apiName]: value });
        }
    }
    exportToCSV = () => {
        const account = this.props.AccountState.account;
        const dFormat = (account.dateformate != '' ? account.dateformate : 'MM-DD-YYYY');
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: (this.state.hotelName && this.state.hotelName != "All") ? ['title', 'description', 'todousercategory', 'todouser', 'start'] : ['hotel', 'title', 'description', 'todousercategory', 'todouser', 'start'],
            fileName: "todoreport" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                if (params && params.column && params.column.colId === 'start') {
                    return moment(params.value).format(dFormat);
                } else {
                    return params.value;
                }
            }
        })
    }
    exportPDF = () => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const account = this.props.AccountState.account;
        const dFormat = (account.dateformate != '' ? account.dateformate : 'MM-DD-YYYY');
        const api = this.gridApi;
        const currentPage = api.paginationGetCurrentPage(); // Get the current page number
        const pageSize = api.paginationGetPageSize(); // Get the page size (number of rows per page)
        const startRow = currentPage * pageSize; // Calculate the starting row index
        const endRow = (currentPage + 1) * pageSize; // Calculate the ending row index
        const filteredRows = api.getModel().rowsToDisplay.slice(startRow, endRow); // Get the rows on the current page
        const exportData = filteredRows.map(rowNode => rowNode.data);

        const selectedProperties = (this.state.hotelName && this.state.hotelName != "All") ? ['title', 'description', 'todousercategory', 'todouser', 'start'] : ['hotel', 'title', 'description', 'todousercategory', 'todouser', 'start'];
        const selected = exportData.map((item, i) => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    if (property == 'start') {
                        selectedData[property] = item[property] ? moment(item[property]).format(dFormat) : '---';
                    } else {
                        selectedData[property] = item[property] ? item[property] : '---';
                    }
                }
            });
            return selectedData;
        });
        let data = {
            title: ((this.state.hotelName && this.state.hotelName != "All") ? this.state.hotelName + ' - ' : '') + (languageData && languageData.TODO_REPORT ? (languageData.TODO_REPORT) : 'To-Do Report'),
            head: (this.state.hotelName && this.state.hotelName != "All") ? ['#', 'Title', "Description", 'User Category', 'User Name', "Start Date"] : ['#', 'Hotel', "Title", "Description", 'User Category', 'User Name', "Start Date"],
            body: selected,
            hotelNameExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hotelNameExport : '',
            hoteladdressExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "todoreport" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }
    total = e => {
        this.setState({ todostatus: '', totalStatus: true, todoStatus: false, inprogressStatus: false, completedStatus: false, pendingStatus: false })
        this.apiCall({ todostatus: '' });
    }
    todo = e => {
        this.setState({ todostatus: 'todo', totalStatus: false, todoStatus: true, inprogressStatus: false, completedStatus: false, pendingStatus: false })
        this.apiCall({ todostatus: 'todo' });
    }
    inprogress = e => {
        this.setState({ todostatus: 'inprogress', totalStatus: false, todoStatus: false, inprogressStatus: true, completedStatus: false, pendingStatus: false })
        this.apiCall({ todostatus: 'inprogress' });
    }
    completed = e => {
        this.setState({ todostatus: 'completed', totalStatus: false, todoStatus: false, inprogressStatus: false, completedStatus: true, pendingStatus: false })
        this.apiCall({ todostatus: 'completed' });
    }
    pending = e => {
        this.setState({ todostatus: 'pending', totalStatus: false, todoStatus: false, inprogressStatus: false, completedStatus: false, pendingStatus: true })
        this.apiCall({ todostatus: 'pending' });
    }
    filterReset = (e) => {
        const role = this.props.AccountState.role;
        if (role && ImportedURL.SUPERADMIN.includes(role)) {
            this.setState({ searchhoteloption: { label: 'All', value: '', name: 'searchhotel' }, searchhotel: '', hotel: '', })
        }
        this.setState({
            isFilterShow: false, todostatus: '', totalStatus: true, todoStatus: false, inprogressStatus: false, completedStatus: false, pendingStatus: false,
            searchcategoryoption: { label: 'All', value: '', name: 'searchcategory' },
            searchuseroption: { label: 'All', value: '', name: 'searchuser' },
            searchcategory: '', searchuser: '', category: '', user: '',
        });
        this.apiCall({ hotel: '', category: '', user: '', todostatus: '' });
    }
    render() {
        if (this.state.editStatus) return <Redirect to={'edit-todo-list/' + this.state.id} />

        const { AccountState, TodoListState, LandingState, UserState } = this.props;
        const account = AccountState.account;
        const languageData = AccountState.account.languageData
        const listHotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 0 ? LandingState.landingdata.data[0] : [];
        const listCategory = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 0 ? LandingState.landingdata.data[7] : [];
        const listUser = UserState.listusers;
        const rowData = TodoListState.todolistreport;
        const count = TodoListState.count;
        const spinner = TodoListState.spinner;
        const modalspinner = TodoListState.modalspinner;
        const data = TodoListState.todo;

        const dFormat = (account.dateformate != '' ? account.dateformate : 'MM-DD-YYYY');
        const template = '<div><button title="View" type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#exampleTodoModal" ><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"></i></button></div>'
        if (rowData) {
            rowData.forEach(object => {
                object.actions = template;
                object.dFormat = dFormat;
            });
        }

        let displayDate = moment(this.state.startdate).format("MMMM YYYY");
        if (this.state.calendarType == 'weeks') {
            let sameMonth = moment(this.state.startdate).format("MMMM") == moment(this.state.enddate).format("MMMM")
            displayDate = sameMonth ? (moment(this.state.startdate).format("MMM DD") + " - " + moment(this.state.enddate).format("DD, YYYY")) : (moment(this.state.startdate).format("MMM DD") + " - " + moment(this.state.enddate).format("MMM DD, YYYY"))
        }
        if (this.state.calendarType == 'days') {
            displayDate = (moment(this.state.startdate).format("MMM DD, YYYY"))
        }

        var hotelOptionSearch = [];
        if (listHotels.filter(filterItem => filterItem.status === true).length > 1) {
            hotelOptionSearch.push({ label: 'All', value: '', name: 'searchhotel' })
        }
        if (listHotels.length > 0) {
            listHotels.filter(filterItem => filterItem.status === true).map(item => {
                hotelOptionSearch.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'searchhotel' });
            })
        }
        var categoryOptionSearch = [];
        if (listCategory.length > 0) {
            let list = listCategory.filter(filterItem => filterItem.status === true)
            list.map(item => {
                let userIncludes = listUser.some((e) => (e.categoryid == item._id && this.state.hotel == e.hotelid))
                if (userIncludes)
                    categoryOptionSearch.push({ label: `${item.name}`, value: item._id, name: 'searchcategory' });
            })
        }
        if (categoryOptionSearch.length > 1) {
            categoryOptionSearch.unshift({ label: "All", value: '', name: 'searchcategory' })
        }

        var userOptionSearch = [];
        if (listUser.length > 0) {
            let list = listUser.filter(filterItem => filterItem.status === true)
            list.map(item => {
                if (this.state.hotel == item.hotelid && this.state.searchcategory == item.categoryid) {
                    userOptionSearch.push({ label: `${item.name}`, value: item._id, name: 'searchuser' });
                }
            })
        }
        if (userOptionSearch.length > 1) {
            userOptionSearch.unshift({ label: "All", value: '', name: 'searchuser' })
        }

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        return (
            <div>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <h6 className="highlights_breadcrump">{languageData && languageData.TODO_REPORT ? languageData.TODO_REPORT : "To-Do Report"}</h6></p>
                    </div>
                    <div className="section-body pt-3 list_ticket">
                        <div className="container-fluid">
                            <div className="row clearfix">
                                <div className="col">
                                    <div className="card" >
                                        <div className="card-body ribbon" style={{ backgroundColor: '#e9c1e4', borderRadius: this.state.totalStatus ? '10px' : '', boxShadow: this.state.totalStatus ? '0 0 5px #0a0a0a' : '' }}>
                                            <div className="ribbon-box orange">{(count && count.total) ? count.total : 0}</div>
                                            <a className="my_sort_cut" href="#" onClick={this.total} >
                                                <span className='dash_img'>
                                                    <img src='../../assets/images/totaltickets.png' />
                                                </span>
                                                <span className='dash_text'>Total Todo</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="card-body ribbon " style={{ backgroundColor: '#a8e1ff', borderRadius: this.state.todoStatus ? '10px' : '', boxShadow: this.state.todoStatus ? '0 0 5px #0a0a0a' : '' }}>
                                            <div className="ribbon-box orange">{(count && count.todo) ? count.todo : 0}</div>
                                            <a className="my_sort_cut text-muted" href="#" onClick={this.todo}>
                                                <span className='dash_img'>
                                                    <img src='../../assets/images/opentickets.png' />
                                                </span>
                                                <span style={{ color: '#444444' }} className='dash_text'>{this.getDasbBoardName('todo') ? this.getDasbBoardName('todo') : 'Todo'}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="card-body ribbon" style={{ backgroundColor: '#eeeaaf', borderRadius: this.state.inprogressStatus ? '10px' : '', boxShadow: this.state.inprogressStatus ? '0 0 5px #0a0a0a' : '' }}>
                                            <div className="ribbon-box orange">{(count && count.inprogress) ? count.inprogress : 0}</div>
                                            <a className="my_sort_cut text-muted" href="#" onClick={this.inprogress}>
                                                <span className='dash_img'>
                                                    <img src='../../assets/images/inprogresstickets.png' />
                                                </span>
                                                <span style={{ color: '#444444' }} className='dash_text'>{this.getDasbBoardName('inprogress') ? this.getDasbBoardName('inprogress') : 'Inprogress'}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="card-body ribbon" style={{ backgroundColor: '#c4e4a5', borderRadius: this.state.completedStatus ? '10px' : '', boxShadow: this.state.completedStatus ? '0 0 5px #0a0a0a' : '' }}>
                                            <div className="ribbon-box orange">{(count && count.completed) ? count.completed : 0}</div>
                                            <a className="my_sort_cut text-muted" href="#" onClick={this.completed}>
                                                <span className='dash_img'>
                                                    <img src='../../assets/images/completedtickets.png' />
                                                </span>
                                                <span style={{ color: '#444444' }} className='dash_text'>{this.getDasbBoardName('completed') ? this.getDasbBoardName('completed') : 'Completed'}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="card">
                                        <div className="card-body ribbon" style={{ backgroundColor: '#f5d7bb', borderRadius: this.state.pendingStatus ? '10px' : '', boxShadow: this.state.pendingStatus ? '0 0 5px #0a0a0a' : '' }}>
                                            <div className="ribbon-box orange">{(count && count.pending) ? count.pending : 0}</div>
                                            <a className="my_sort_cut text-muted" href="#" onClick={this.pending}>
                                                <span className='dash_img'>
                                                    <img src='../../assets/images/inprogresstickets.png' />
                                                </span>
                                                <span style={{ color: '#444444' }} className='dash_text'>Pending</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-body pt-3 user_sec todo_all_task">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i class="icon-clock" aria-hidden="true"></i></div>
                                            <h3 className="card-title">{languageData && languageData.TODO_REPORT ? languageData.TODO_REPORT : "To-Do Report"}
                                            </h3>
                                            <div className="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    {this.state.isFilterShow &&
                                                        <button className='btn topsocial_rest_btn btn-primarys ' type='button' onClick={this.filterReset} style={{ height: '45px !important', width: '115pxpx !important' }}><i className="fa fa-refresh" aria-hidden="true" style={{ fontSize: '20px !importent', marginRight: '7px' }}></i> Reset</button>
                                                    }
                                                    {
                                                        (rowData && rowData.length > 0) ?
                                                            <div className="card-options">
                                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle ticket_export" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                                <div className="dropdown-menu">
                                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                                </div>
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="calendar_filter">
                                                <div className="selectgroup">
                                                    <label className="selectgroup-item">
                                                        <span className="selectgroup-button first_month" onClick={() => this.onChangePage('previous')} ><HiOutlineChevronLeft /></span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <span className="selectgroup-button" onClick={() => this.onChangePage('today')}>{"Today"}</span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <span className="selectgroup-button last_month" onClick={() => this.onChangePage('next')}><HiOutlineChevronRight /></span>
                                                    </label>
                                                </div>
                                                <div>
                                                    <span className='todo_center_name'>{displayDate}</span>
                                                </div>
                                                <div className="selectgroup">
                                                    <label className="selectgroup-item">
                                                        <span className={`${this.state.calendarType == 'months' ? 'active ' : ''} selectgroup-button first_month`} onClick={() => this.onChangeCalendar('months')}>{"Month"}</span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <span className={`${this.state.calendarType == 'weeks' ? 'active ' : ''} selectgroup-button`} onClick={() => this.onChangeCalendar('weeks')}>{"Week"}</span>
                                                    </label>
                                                    <label className="selectgroup-item">
                                                        <span className={`${this.state.calendarType == 'days' ? 'active ' : ''} selectgroup-button last_month`} onClick={() => this.onChangeCalendar('days')}>{"Day"}</span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-2">
                                                    <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={(e) => { this.gridApi.paginationSetPageSize(Number(e.value)); this.setState({ perPage: Number(e.value) }); }}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                            className='limit_size'
                                                        />
                                                    </div>
                                                </div>
                                                {
                                                    (ImportedURL.SUPERADMIN.includes(account.role)) ?
                                                        <>
                                                            <div className="col-4">
                                                                <label className="form-label">{languageData && languageData.SEARCH ? languageData.SEARCH : "Search"} {languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel"}</label>
                                                                <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                    <HotelSelectComponent options={hotelOptionSearch} onChange={this.searchSelect} value={this.state.searchhoteloption} />
                                                                </div>
                                                            </div>

                                                        </> : ''
                                                }
                                                {
                                                    (this.state.searchhotel || !ImportedURL.SUPERADMIN.includes(account.role))
                                                        ?
                                                        <div className="col-3">
                                                            <label className="form-label">User Category</label>
                                                            <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                <SelectComponent value={this.state.searchcategoryoption} onChange={this.searchSelect} options={categoryOptionSearch} />
                                                            </div>
                                                        </div>
                                                        : ''
                                                }
                                                {
                                                    this.state.searchcategory ?
                                                        <div className="col-3">
                                                            <label className="form-label">User Name</label>
                                                            <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                <SelectComponent value={this.state.searchuseroption} onChange={this.searchSelect} options={userOptionSearch} />
                                                            </div>
                                                        </div>
                                                        : ''
                                                }
                                            </div>
                                            <div>
                                                <AgGridComponent rowData={rowData} state={this.state} onRowClicked={(e) => this.onRowClicked(e)} onGridReady={(e) => this.onGridReady(e)} handleFilterChanged={(e) => this.handleFilterChanged(e)} overlay={true} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div >
                    </div >
                    <Spinner spinner={spinner || this.state.spinner}></Spinner>
                </div >
                <Viewmodal {...this.props} data={data} spinner={modalspinner} startdate={this.state.date ? moment(this.state.date).format("MM-DD-YYYY") : ''} />
            </div >
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
    TodoListState: state.todolist,
    LandingState: state.landing,
    ConfigurationState: state.configuration,
    UserState: state.user,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListTodoStatus: AC_LIST_CONFIGOPTIONS_TODO_STATUS,
        ListTodoReport: AC_LIST_TO_DO_LIST_REPORT,
        ViewTodo: AC_VIEW_TO_DO_LIST,
        spinnerTodo: AC_SPINNER_TO_DO_LIST,
        spinnerModalTodo: AC_MODAL_SPINNER_TO_DO_LIST,
        EmptyTodoList: AC_EMPTY_TO_DO_LIST,
        ListUser: AC_LIST_USER,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoReport);

import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import CKEditor from "react-ckeditor-component";
import { bindActionCreators } from 'redux';
import { AC_HANDLE_SOCIALMEDIA_CHANGE, AC_LIST_SOCIALMEDIA, AC_RESET_SOCIALMEDIA, AC_VIEW_SOCIALMEDIA, AC_LIST__SOCIALMEDIA_DRAG_DROP, AC_HANDLE_SOCIALMEDIA_SEARCH, AC_SPINNER_SOCIALMEDIA, AC_MODAL_SPINNER_SOCIALMEDIA } from '../../actions/socialmediaAction';
import axios from 'axios';
import { AC_ADD_ROOMTYPE, AC_LIST_ROOMTYPE, AC_UPDATE_ROOMTYPE } from '../../actions/roomtypeAction';
import { AC_LIST_CATEGORY } from '../../actions/categoryAction';
import { Link, Redirect } from 'react-router-dom';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import Select from 'react-select';
import { Imagevalidation, imageCellRendererList, onErrorImage } from '../../common/validate';
import jsPDF from 'jspdf';
import Spinner from 'react-bootstrap/Spinner';
import RLDD from 'react-list-drag-and-drop/lib/RLDD';
import showSwalDialog from '../../utilities/Modal/swal';

const initialState = {
    modalType: "Add",
    gridApi: '',
    columnDefs: [
        { headerName: '#', valueGetter: "node.rowIndex+1", width: 100, sortable: false, filter: false, headerClass: 'ag-center-header', cellStyle: { 'text-align': 'center' }, hide: 'true' },
        {
            headerName: 'Image', field: 'socialmediaimage', width: 300, suppressMenu: true, sortable: false, floatingFilter: false, headerClass: 'ag-center-header', rowDrag: true,
            cellRenderer: imageCellRendererList,
        },
        { headerName: 'Name', field: 'socialmedianame', suppressMenu: true, width: 300, floatingFilter: false, sortable: false, headerClass: 'ag-center-header', rowDrag: true, },
        {
            headerName: 'Status', width: 200, field: 'status', headerClass: 'ag-center-header', sortable: false, suppressMenu: true,
            cellRenderer: function (params) {
                if (params.data.status) {
                    return `<div class="status_style"><span type="button" class="tag tag-green" data-action-type="Status">Active</span></div>`;
                } else {
                    return '<div class="status_style"><span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span></div>';
                }
            }
        },
        {
            headerName: 'Actions', width: 300, field: 'actions', sortable: 'false', headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false, cellStyle: { 'text-align': 'center' },
            cellRenderer: function (params) {
                return params.value;
            }
        },
    ],
    modalType: "Add",
    columnApi: '',
    gridApi: '',
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true,
    },
    socialmediaimageError: false,
    socialmedianameError: false,
    socialmediaimageSrc: '',
    socialmediaimage: '',
    id: '',
    invalidFileError: false,
    validImages: false,
    saving: false,
    viewButton: false,
    editSpi: false,
    serachValue: '',
    pagination: 1,
    paginationLimit: 25,
    startLimit: 1,
    endLimit: 25,
}
class SocialMediaType extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    changeModal = (e) => {
        this.setState({ modalType: e.target.id, socialmediaimageError: false, socialmedianameError: false, invalidFileError: false, socialmediaimageSrc: '', socialmediaimage: '', validImages: false, editSpi: false });
        document.getElementById('imageSet').value = '';
        this.props.ResetSocialMedia();
        const { AccountState } = this.props;
        const account = AccountState.account;
        if (!ImportedURL.SUPERADMIN.includes(AccountState.role)) {
            this.props.HandleInputChange('hotel', account.hotel);
        }
    }
    onClick = () => {
        this.setState({
            showPicker: !this.state.showPicker
        })
    };

    onClose = () => {
        this.setState({
            showPicker: false
        })
    };
    onRowClicked = event => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        this.setState({ spinner: false })
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'View') {
            this.setState({ modalType: "View", nameError: false, id: event.data._id, });
            // window.open('/view-category/' + event.data._id).focus();
            // this.props.ViewCategory(event.data._id);
        }
        if (value === 'Edit') {
            document.getElementById('imageSet').value = '';
            this.props.ModalSpinnerSocialMedia();
            this.props.ResetSocialMedia();
            this.setState({ socialmediaimageSrc: '', socialmediaimage: '' })
            this.setState({ modalType: "Edit", id: event.data._id, socialmediaimage: event.data.socialmediaimage, socialmedianame: event.data.socialmedianame, socialmediaimageError: false, socialmedianameError: false, validImages: false, editSpi: true });
            this.props.ViewSocialMedia(event.data._id);

        }
        if (value === 'Delete') {
            showSwalDialog('Are you sure you want to delete?', '', 'Ok', 'Cancel', 'assets/images/delete.png').then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.deleteSocialMedia + '/' + event.data._id)
                        .then((res) => {
                            Success((languageData && languageData.SOCIAL_MEDIA ? languageData.SOCIAL_MEDIA + " " : " Social media") + (languageData && languageData.DELETED_SUCCESSFULLY ? languageData.DELETED_SUCCESSFULLY : ' deleted successfully'));
                            this.props.ListSocialMedia();
                        }).catch(({ response }) => {
                            this.ErrorResponse(response);
                        });
                }
            })
        }
        if (value === 'Status') {
            showSwalDialog('Are you sure you want to change the status?', '', 'Ok', 'Cancel', 'assets/images/status.png').then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'socialmedias' })
                        .then((data) => {
                            this.props.ListSocialMedia();
                            Success((languageData && languageData.STATUS ? languageData.STATUS + " " : ' Status') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " updated successfully"))
                        }).catch(({ response }) => {
                            this.ErrorResponse(response);
                        });
                }
            })
        }
    }

    onChange = e => {
        const { SocialMediaState } = this.props;
        const { name, value } = e.target;
        const Error = name + "Error";
        if (name === 'socialmedianame') {
            if (value) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
            this.props.HandleInputChange("socialmedianame", value);
        }
        if (name == 'socialmediaimage') {
            this.setState({ socialmediaimageSrc: '' })
            const imgvalidate = Imagevalidation(e.target.files[0]);
            if (imgvalidate) {
                this.setState({ socialmediaimageError: false, invalidFileError: false })
                this.setState({ socialmediaimage: e.target.files[0], validImages: false });
                this.props.HandleInputChange('socialmediaimage', e.target.files[0]);
            } else {
                this.setState({ invalidFileError: true, socialmediaimageError: false, validImages: true })
                this.props.HandleInputChange('socialmediaimage', '');
                document.getElementById('imageSet').value = '';
                // Error('Invalid file extension');
            }
        }
        if (name === 'id') {
            if (value) {
                this.setState({ [Error]: false });
            } else {
                this.setState({ [Error]: true })
            }
            this.props.HandleInputChange("id", value);
        }
        this.setState({ editSpi: false });
    }

    handleRLDDChange = (e) => {
        if (e && e.length > 0) {
            const formData = {
                socialmedia: e
            }
            this.props.CategoryDragDrop(formData)
        }
    }
    submit = (e) => {
        const { SocialMediaState } = this.props;
        const data = SocialMediaState.socialmedia;
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        data['socialmedianame'] = data.socialmedianame.trim()

        let valid = 1;
        if (!data.socialmediaimage && !this.state.validImages) {
            this.setState({ socialmediaimageError: true, invalidFileError: false });
            valid = 0;
        }
        if (this.state.validImages) {
            this.setState({ invalidFileError: true });
            valid = 0;
        }
        if (!data.socialmedianame) {
            this.setState({ socialmedianameError: true });
            valid = 0;
        }
        // if (!data.id) {
        //     this.setState({ idError: true });
        //     valid = 0;
        // }
        var formData = new FormData();
        formData.append("socialmedianame", data.socialmedianame)
        formData.append("socialmediaimage", this.state.socialmediaimage)
        // formData.append("id", data.id)
        if (valid) {
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                this.setState({ viewButton: true })
                axios.post(ImportedURL.API.addSocialMedia, formData)
                    .then((res) => {
                        this.setState({ saving: false, viewButton: false })
                        Success((languageData && languageData.OTA_REVIEWS ? languageData.OTA_REVIEWS : "OTA 's Reviews ") + (languageData && languageData.CREATED_SUCCESSFULLY ? languageData.CREATED_SUCCESSFULLY : " created successfully"));
                        // Success(res.statusText);
                        this.props.ListSocialMedia();
                        this.props.ResetSocialMedia();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                        document.getElementById('imageSet').value = '';
                        this.setState({ socialmediaimageSrc: '', socialmediaimage: '' })
                        // data.socialmediaimage('');
                    }).catch(({ response }) => {
                        this.ErrorResponse(response);
                    });
            } else {
                this.setState({ viewButton: true })
                axios.post(ImportedURL.API.updateSocialMedia + '/' + this.state.id, formData)
                    .then((res) => {
                        this.setState({ saving: false, viewButton: false })
                        Success((languageData && languageData.OTA_REVIEWS ? languageData.OTA_REVIEWS : "OTA 's Reviews ") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " updated successfully"));
                        // Success(res.statusText);
                        this.props.ListSocialMedia();
                        this.props.ResetSocialMedia();

                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.ErrorResponse(response);
                    });
            }
        }
    }

    ErrorResponse = (response) => {
        this.setState({ saving: false, viewButton: false })
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (response != undefined) {
            if (response.status == 400) {
                Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : ' Bad request')
            } else if (response.status == 500) {
                Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
            } else if (response.status == 502) {
                Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
            } else if (response.status == 409) {
                Error((languageData && languageData.OTA_REVIEWS ? languageData.OTA_REVIEWS : "OTA 's Reviews ") + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : 'already exist'))
            } else {
                Error(response.statusMessage)
            }
        } else {
            Error("Bad Gateway")
        }
    }

    componentDidMount() {
        this.props.SpinnerSocialMedia();
        this.props.ListSocialMedia();
        const account = this.props.AccountState.account;
        this.setState({ hotelName: account.hotelName ? account.hotelName : '' });
        const { AccountState } = this.props;
        // this.setState({ hotelName: AccountState.account.hotelName })
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };



    exportToCSV = () => {
        const account = this.props.AccountState.account;
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: ['socialmedianame'],
            fileName: 'socialmedia' + "_" + (this.state.hotelName && (this.state.hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const account = this.props.AccountState.account;
        const api = this.gridApi;
        const filteredRows = api.getModel().rowsToDisplay
        const exportDataTemp = filteredRows.map(rowNode => rowNode.data);
        const firstRow = api.getFirstDisplayedRow();
        const lastRow = api.getLastDisplayedRow();
        const exportData = exportDataTemp.slice(firstRow, lastRow + 1);
        const selectedProperties = ['socialmedianame'];
        const selected = exportData.map((item, i) => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    selectedData[property] = item[property] ? item[property] : '---';
                }
            });
            return selectedData;
        });
        let data = {
            title: "OTA 's Reviews List",
            head: ['#', 'Name'],
            body: selected,
            hotelNameExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hotelNameExport : '',
            hoteladdressExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "socialmedia" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }
    changeStatus = (id, value) => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        showSwalDialog('Are you sure you want to change the status?', '', 'Ok', 'Cancel', 'assets/images/status.png').then((result) => {
            if (result.isConfirmed) {
                axios.post(ImportedURL.API.statusChange, { id: id, status: value, model: 'socialmedias' })
                    .then((data) => {
                        this.props.ListSocialMedia();
                        Success((languageData && languageData.STATUS ? languageData.STATUS + " " : ' Status') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " updated successfully"))
                    }).catch(({ response }) => {
                        if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else {
                            Error(response.statusMessage)
                        }
                    });
            }
        })
    }
    changeAction = (id, value) => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData

        if (value === 'Edit') {
            this.props.ModalSpinnerSocialMedia();
            this.props.ResetSocialMedia();
            this.setState({ socialmediaimageSrc: '', socialmediaimage: '' })
            this.setState({ modalType: "Edit", id: id._id, socialmediaimage: id.socialmediaimage, socialmedianame: id.socialmedianame, socialmediaimageError: false, socialmedianameError: false, validImages: false, editSpi: true });
            this.props.ViewSocialMedia(id._id);

        }
        if (value === 'Delete') {
            showSwalDialog('Are you sure you want to delete?', '', 'Ok', 'Cancel', 'assets/images/delete.png').then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.deleteSocialMedia + '/' + id)
                        .then((res) => {
                            Success((languageData && languageData.SOCIAL_MEDIA ? languageData.SOCIAL_MEDIA + " " : " Social media") + (languageData && languageData.DELETED_SUCCESSFULLY ? languageData.DELETED_SUCCESSFULLY : ' deleted successfully'));
                            this.props.ListSocialMedia();
                        }).catch(({ response }) => {
                            if (response) {
                                if (response.status == 400) {
                                    Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : ' Bad request')
                                }
                                else if (response.status == 500) {
                                    Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                                } else if (response.status == 502) {
                                    Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                                } else {
                                    Error(response.statusMessage)
                                }
                            }
                        });
                }
            })
        }
    }

    handleSearch = (e) => {
        var value = e.target.value
        const rowData = this.props.SocialMediaState.listfilter;
        this.setState({ serachValue: value })
        // this.props.ListCategory();
        if (value) {
            if (rowData != undefined && rowData.length > 0) {
                const data = rowData.filter((item) =>
                    item.socialmedianame.toLowerCase().includes(value.toLowerCase())
                )
                this.props.searchSocialMediaList(data)
            } else {
                this.props.searchSocialMediaList([])
            }
        } else {
            this.props.ListSocialMedia();
        }
    }

    handleLimit = (e) => {
        this.setState({ paginationLimit: e, startLimit: 1, endLimit: e })
    }
    previousPage = (e) => {
        const rowData = this.props.CategoryState.listcategories;
        const { pagination, paginationLimit, startLimit, endLimit } = this.state
        if (1 < pagination) {
            this.setState({ startLimit: startLimit - paginationLimit, endLimit: endLimit - paginationLimit, pagination: pagination - 1 })
        }
    }
    nextPage = (e) => {
        const rowData = this.props.CategoryState.listcategories;
        const { pagination, paginationLimit, startLimit, endLimit } = this.state
        if (endLimit < rowData.length) {
            this.setState({ startLimit: endLimit + 1, endLimit: endLimit + paginationLimit, pagination: pagination + 1 })
        }
    }
    firstPage = (e) => {
        const rowData = this.props.CategoryState.listcategories;
        const { pagination, paginationLimit, startLimit, endLimit } = this.state
        if (1 < pagination) {
            this.setState({ startLimit: 1, endLimit: paginationLimit, pagination: pagination - 1 })
        }
    }
    lastPage = (e) => {
        const rowData = this.props.CategoryState.listcategories;
        const { pagination, paginationLimit, startLimit, endLimit } = this.state
        var totalNumber = Math.ceil(rowData.length / paginationLimit)
        if (endLimit < rowData.length) {
            this.setState({ startLimit: (totalNumber * paginationLimit) - paginationLimit, endLimit: (totalNumber * paginationLimit), pagination: totalNumber })
        }
    }
    onRowDragEnd = (e) => {
        let arr = []
        this.gridApi.forEachNode((rowNode, index) => {
            arr.push({ id: rowNode.data._id, sort: index })
        });

        axios.post(ImportedURL.API.sortSocialMedia, { data: arr, model: 'socialmedias' })
            .then((res) => {
                this.props.ListCategory();
            }).catch(({ response }) => {
            });
    };
    render() {
        const { AccountState, RoomtypeState, HotelState, SocialMediaState } = this.props;
        const topHotelName = AccountState.account.hotelName;
        const socialmedia = SocialMediaState.socialmedia;
        let socialMediaList = SocialMediaState.listSocialMedia;
        const spinner = SocialMediaState.spinner;
        const modalspinner = SocialMediaState.modalspinner;
        var rowData = [];
        socialMediaList.map((data) => {
            rowData.push(data);
        })
        const previleges = AccountState.previleges;
        // const Previlege = previleges.find(obj => { return obj.name == "Room_type" });
        const Previlege = previleges.find(obj => { return obj.name == "Category" });
        const editOption = Previlege?.edit ? '<button type="button" class="btn btn-icon" data-toggle="modal" data-target="#exampleModal" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
        const deleteOption = ImportedURL.SUPERADMIN.includes(AccountState.role) ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : '';
        // '<div class="userlist_view"><button type="button" class="btn btn-icon" data-action-type="View" ><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
        const template = editOption
            + deleteOption
            + '</div>'
        rowData.forEach(object => {
            object.actions = template;
        });
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        const { pagination, paginationLimit, startLimit, endLimit } = this.state

        var startNumber = ''
        var endNumber = ''
        var categoryData = []
        let endvalue = ''
        if (endLimit <= rowData.length) {
            endvalue = endLimit
        } else {
            endvalue = rowData.length
        }
        if (rowData.length > 0) {
            for (let i = startLimit; i <= Number(endvalue); i++) {
                categoryData.push(rowData[i - 1])
            }
        }

        var totalNumber = Math.ceil(rowData.length / paginationLimit)

        const imageArr = [];
        rowData?.map((item) => {
            imageArr.push(
                <div>
                    <img src={ImportedURL.FILEURL + item.socialmediaimage} onError={onErrorImage} />
                </div>
            )
        })
        const languageData = AccountState.account.SelectedlanguageData
        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-category"><h6 className="highlights_breadcrump">{languageData && languageData.OTA_REVIEWS ? languageData.OTA_REVIEWS : "OTA 's Reviews "}{languageData && languageData.LIST ? languageData.LIST : " List"}  </h6></Link></p>
                        </div>
                        <div className="section-body pt-3">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header tit_res">
                                                <div className='d-flex'>
                                                    <div className='rounded_icon'><i className="fa fa-dropbox mr-2 "></i></div>
                                                    <div className='d-flex align-items-center'>
                                                        <h3 className="card-title"> {languageData && languageData.OTA_REVIEWS ? languageData.OTA_REVIEWS + " Type" : "OTA 's Reviews Type"}</h3>
                                                    </div>
                                                </div>

                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="header-action">
                                                            {Previlege?.add ? <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" id='Add' onClick={(e) => this.changeModal(e)}><i className="fe fe-plus mr-2" id='Add' />Add</button> : ''}
                                                        </div>
                                                    </div>
                                                    {socialMediaList && socialMediaList.length > 0 && !this.state.hideExport ? <>
                                                        <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                        <div className="dropdown-menu">
                                                            {/* <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a> */}
                                                            <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                        </div>
                                                    </> : []}
                                                </div>
                                            </div>
                                            <div className="card-body" style={{ padding: "20px 20px 0px 20px" }}>
                                                <div className="row">
                                                    {/* <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                        <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                        <div className="form-group mb-0">
                                                            <Select
                                                                // onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                                onChange={(e) => this.handleLimit(Number(e.value))}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div> */}
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                        <label className="form-label">{languageData && languageData.OTA ? languageData.OTA : "OTA's"} {languageData && languageData.REVIEW ? languageData.REVIEW : " Review"}  {languageData && languageData.SEARCH ? languageData.SEARCH : "Search"}</label>
                                                        <div className="form-group mb-0">
                                                            <input type="text" className="form-control" placeholder="OTA 's Review Search" name="categorysearch" disabled={this.state.modalType == "View"} onChange={this.handleSearch} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='card-body border-0'>
                                                <div className="table-responsive">
                                                    <div className={(rowData && rowData.length == 0) ? "ag-theme-alpine ag_grid_no_record_show" : "ag-theme-alpine"}>
                                                        <AgGridReact
                                                            rowHeight={82}
                                                            paginationPageSize={25}
                                                            rowData={rowData}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            onGridReady={this.onGridReady}
                                                            columnDefs={this.state.columnDefs}
                                                            onRowDragEnd={this.onRowDragEnd.bind(this)}
                                                            rowDragManaged={true}
                                                            suppressMoveWhenRowDragging={true}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                </div>
                                            </div>
                                            {spinner ?
                                                <div className='common_loader_ag_grid'>
                                                    <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                    <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                    </Spinner>
                                                </div>
                                                : ""}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* =====soc */}
                    <div className="modal fade" id="exampleModal" autoComplete="off" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className='rounded_icon'><i className="fa fa-dropbox mr-2 "></i></div>
                                    <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} {languageData && languageData.OTA_REVIEWS ? languageData.OTA_REVIEWS + " Type" : "OTA 's Reviews Type"}</h5>
                                    <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" style={{ fontSize: "23px" }} id="closeModal" onClick={this.clear}>
                                            <img src='../../assets/images/cancel.png' />
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row clearfix">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">
                                                    OTA 's Reviews Image<span className='ml-1' style={{ color: 'red' }}>*</span>
                                                </label>
                                                <input type="file" accept="image/*" className="form-control" placeholder="OTA 's Reviews Image" name='socialmediaimage' onChange={this.onChange} id="imageSet" />
                                                <div className="invalid-feedback" style={{ display: this.state.socialmediaimageError ? "block" : 'none' }}>OTA 's Reviews Image is required</div>
                                                <div className="invalid-feedback" style={{ display: this.state.invalidFileError ? "block" : 'none' }}>Invalid file extension</div>
                                            </div>
                                            {socialmedia.socialmediaimage ?
                                                <div className="form-group">
                                                    <img src={(typeof socialmedia.socialmediaimage == 'string') ? ImportedURL.FILEURL + socialmedia.socialmediaimage : (window.URL.createObjectURL(socialmedia.socialmediaimage))} width="100px" alt='' onError={onErrorImage} />
                                                </div>
                                                : ''}
                                        </div>
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">OTA 's Reviews Name<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                <input type="text" className="form-control placevalue" disabled={this.state.modalType == "View"} name='socialmedianame' onChange={this.onChange} value={socialmedia.socialmedianame
                                                } placeholder="OTA 's Reviews Name" />
                                                <div className="invalid-feedback" style={{ display: this.state.socialmedianameError ? "block" : 'none' }}>OTA 's Reviews Name is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.modalType == "Edit" ? <div className="card-footer text-right mandatory">
                                    <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                    <div className="" >
                                        {
                                            this.state.saving ?
                                                <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                :
                                                <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Update</button>
                                        }
                                        <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal" ><i className="fa fa-times mr-2"></i>Close</button>
                                    </div>
                                </div> : <div className="card-footer text-right mandatory">
                                    <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                    <div className="" >
                                        {
                                            this.state.saving ?
                                                <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                :
                                                <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                        }
                                        <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal" ><i className="fa fa-times mr-2"></i>Close</button>
                                    </div>
                                </div>}

                                <div id='overlay-model' style={{ display: modalspinner ? 'block' : 'none' }}></div>
                                {modalspinner ?
                                    <div className='model_loader_poss'>
                                        <img className='loader_img_style_model' src='../../assets/images/load.png' />
                                        <Spinner className='spinner_load_model' animation="border" variant="info" >
                                        </Spinner>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div >
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    UserState: state.user,
    HotelState: state.hotel,
    CategoryState: state.category,
    AccountState: state.account,
    RoomtypeState: state.roomtype,
    SocialMediaState: state.socialmedia

})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleInputChange: AC_HANDLE_SOCIALMEDIA_CHANGE,
        ListCategory: AC_LIST_CATEGORY,
        ListSocialMedia: AC_LIST_SOCIALMEDIA,
        AddRoomtype: AC_ADD_ROOMTYPE,
        ListRoomtype: AC_LIST_ROOMTYPE,
        ViewSocialMedia: AC_VIEW_SOCIALMEDIA,
        ResetSocialMedia: AC_RESET_SOCIALMEDIA,
        UpdateRoomtype: AC_UPDATE_ROOMTYPE,
        SpinnerSocialMedia: AC_SPINNER_SOCIALMEDIA,
        ModalSpinnerSocialMedia: AC_MODAL_SPINNER_SOCIALMEDIA,
        CategoryDragDrop: AC_LIST__SOCIALMEDIA_DRAG_DROP,
        searchSocialMediaList: AC_HANDLE_SOCIALMEDIA_SEARCH
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(SocialMediaType);
import CONSTANTS from "../common/constants";

const initialState = {

	account: {
		name: '',
		hotelid: '',
		email: '',
		location: '',
		role: '',
		status: '',
		ticket: '',
		phonecode: '',
		currencycode: '',
		currencyname: '',
		currencysymbol: '',
		logo: '',
		time: '',
		dateformate: '',
		timeformat: '',
		usercategory: '',
		report: '',
		assignuser: '',
		namechange: false,
		countrychange: false,
		imagechange: false,
		multiplechange: false,
		defaultmanagementcategory: {
			individualcategory: '',
			multiplecategory: [],
			individualuser: [],
			type: [],
		},
		sendreportto: '',
		hotel: '',
		owneremail: '',
		otheremail: '',
		reports: [
			{ "name": "Tickets", "daily": false, "weekly": false, "byweekly": false, "monthly": false, "quarterly": false, "yearly": false },
			{ "name": "MOM", "daily": false, "weekly": false, "byweekly": false, "monthly": false, "quarterly": false, "yearly": false },
			{ "name": "To-Do", "daily": false, "weekly": false, "byweekly": false, "monthly": false, "quarterly": false, "yearly": false }
		],
	},
	role: '',
	loginHistory: [],
	logHistory: [],
	total: '',
	spinner: true,
	sendgridkey: '',
	twillioauthkey: '',
	twilliotoken: '',
	feedbackmailto: [],
	defaultlanguage: '',
	statusList: [],
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'ACCOUNT_DETAILS':
			return Object.assign({}, state, {
				account: action.payload,
				role: action.payload?.role,
				previleges: action.payload ? action.payload.previleges : CONSTANTS.previleges,
			})
		case 'PREVILEGE_DETAILS':
			return Object.assign({}, state, {
				previleges: action.payload.previleges
			})
		case 'ADMIN_DATA':
			return {
				...state,
				account: { ...state.account, ...action.payload },
			}
		case 'UPDATE_ADMIN_DATA':
			return Object.assign({}, state, {
				account: {
					...state.account,
					[action.name]: action.value
				}
			})
		case 'LOGIN_HISTORY':
			return Object.assign({}, state, {
				loginHistory: action.payload,
				spinner: action.spinner
			})
		case 'LOG_HISTORY':
			return Object.assign({}, state, {
				logHistory: action.payload,
				total: action.total,
				spinner: action.spinner
			})
		case "PERMISSION_CHANGE":
			var _i = action.index;
			return {
				...state,
				account: {
					...state.account,
					reports: [
						...state.account.reports.slice(0, _i),
						{
							...state.account.reports[_i],
							[action.key]: !state.account.reports[_i][action.key]
						},
						...state.account.reports.slice(_i + 1),
					]
				}
			}
		case 'UPDATE_REPORT_DATA':
			return Object.assign({}, state, {
				account: {
					...state.account,
					[action.name]: action.value
				}
			})
		case 'GET_STATUS':
			return Object.assign({}, state, {
				statusList: action.payload
			})
		case 'UPDATE_LANGUAGE_DATA':
			return Object.assign({}, state, {
				account: {
					...state.account,
					'SelectedlanguageData': action.updatedLanguageData,
					'language': action.lang
				}
			})
		case 'UPDATE_LANGUAGE_JSON':
			return Object.assign({}, state, {
				account: {
					...state.account,
					'SelectedlanguageData': action.updatedLanguageData,
					'language': action.lang
				}
			})
		default:
			return state;
	}
}
import React, { Component } from 'react';
import { Container } from 'react-bootstrap';
import { BsEmojiSmile } from "react-icons/bs";
import { ImSad } from "react-icons/im";
import { Link, Redirect } from 'react-router-dom';
import Banner from './banner.png';
import '../../assets/css/job_apply_form.css';
import ImportedURL from '../../common/api';
import axios from 'axios';
import img from '../../assets/images/thums.png';
import bad from '../../assets/images/smile-bad.png'
import good from '../../assets/images/smile-good.png'
import Spinner from 'react-bootstrap/Spinner';
import { PulseLoader } from 'react-spinners';

export default class Review extends Component {
    constructor(props) {
        super(props);
        this.state = {
            mode: '',
            review: '',
            hotelData: {},
            reviewsStatus: '',
            redirectPage: true,
        };
    }
    reviewPage = (e) => {
        let value = e.target.attributes.name ? e.target.attributes.name.value : '';
        if (value) {
            this.setState({ mode: value })
        }
    };
    componentDidMount() {
        const queryparams = new URLSearchParams(window.location.search);
        const hotel = queryparams.get('hotel');
        const { params } = this.props.match;
        this.setState({ review: params.id, redirectPage: true })

        axios.get(ImportedURL.API.getReview + "/" + params.id)
            .then((res) => {
                this.setState({ hotelData: res.data, urlExpired: res.data.urlExpired })
            }).catch((err) => { console.log(err); });

        axios.post(ImportedURL.API.reviewSeen + "/" + params.id, { seen: true })
            .then((res) => {
            }).catch((err) => { console.log(err); });

        axios.get(ImportedURL.API.viewReviews + "/" + params.id)
            .then((res) => {
                if (res.data != undefined) {
                    this.setState({ reviewsStatus: res.data.negativefeedbackstatus, })
                    if (res.data.negativefeedbackstatus) {
                        this.setState({ mode: 'smile', })
                    }
                } else {
                    this.setState({ reviewsStatus: false })
                }
                this.setState({ redirectPage: false });
            }).catch((err) => {
                this.setState({ redirectPage: false });
                console.log(err);
            });
    }
    dialNumClick = () => {
        window.location.href = 'tel:' + this.state.hotelData.hotelPhone;
    }
    render() {
        // const queryparams = new URLSearchParams(window.location.search);
        // const hotel = queryparams.get('hotel');
        if (this.state.mode === "smile") return <Redirect to={'/review-success' + "?review=" + this.state.review} />
        if (this.state.mode === "sad") return <Redirect to={'/review-failure/' + this.state.review} />
        if (this.state.redirectPage) {
            return (
                <div className='feedback_guest'>
                    <section className='review_sec'>
                        <Container>
                            <div className='error-page'>
                                <p className='oops-para'>Redirecting <PulseLoader color="#1c3078" size={5} /></p>
                            </div>
                        </Container>
                    </section>
                </div>
            )
        } else {
            return (
                <div>
                    <section className='review_sec'>
                        <Container>
                            <div className='icemote'>
                                <div className='img_size'>
                                    <img src={this.state.hotelData.hotelcard ? ImportedURL.FILEURL + this.state.hotelData.hotelcard : Banner} alt='not found' />
                                </div>
                                <div className='content'>
                                    {/* {
                                        this.state.reviewsStatus
                                        &&
                                        <>
                                            <div className='icons_sm'>
                                                <div className="order_confirm_img">
                                                    <img src="../assets/images/negative-status.jpg" />
                                                </div>
                                            </div>
                                            <p className='help_t already_review'>This review has already reviewed</p>
                                        </>
                                    } */}
                                    {(!this.state.reviewsStatus && !this.state.urlExpired)
                                        ?
                                        <>
                                            <p style={{ color: '#1c3078', fontWeight: '600', fontSize: '20px' }}>How was your stay with us ?</p>
                                            <div className='icons_sm'>
                                                <img className='img_rev_size mr-4' name="smile" src={good} onClick={this.reviewPage} />
                                                <img className='img_rev_size' src={bad} name="sad" onClick={this.reviewPage} />
                                                {/* <BsEmojiSmile className='smile' onClick={this.reviewPage} name="smile" style={{ cursor: 'pointer' }} /> */}
                                                {/* <ImSad className='sad' onClick={this.reviewPage} name="sad" style={{ cursor: 'pointer' }} /> */}
                                            </div>
                                            <p className='help_t'>We are always here to help</p>
                                        </> : ''
                                    }
                                    {(!this.state.reviewsStatus && this.state.urlExpired)
                                        ?
                                        <>
                                            <div className="form-group">
                                                <p style={{ color: '#1c3078', fontWeight: '600', fontSize: '20px' }}>Link Expired.. Contact Hotel Admin..</p>
                                                <div className='thumcsup'>
                                                    <div style={{ width: "150px", height: 'auto', margin: ' 0 auto' }}>
                                                        <img src='../../assets/images/sad.png' style={{ width: '60%', height: '60%', objectFit: 'fill' }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <p className='help_t'>We are always here to help</p>
                                        </> : ''
                                    }
                                    {/* <p className='add'>{this.state.hotelData.hotelAddress}</p> */}
                                    {/* <p className='add'>1401 Martin Luther King Jr, Monroe, LA 71202</p> */}
                                    <p className='call'>Call Us @ <span className='dial_num' onClick={this.dialNumClick}>{this.state.hotelData.hotelPhone}</span></p>
                                    {/* <p className='call'>Call Us @ (318) 410-1005</p> */}
                                </div>

                            </div>
                        </Container>
                    </section>
                </div >
            )
        }
    }
}



import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_CONFIGURATION_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_CONFIGURATION_CHANGE", name: name, value: value })
	};
}

export function AC_RESET_CONFIGURATION() {
	return function (dispatch) {
		dispatch({ type: "RESET_CONFIGURATION" })
	};
}


export function AC_LIST_CONFIGURATION(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listConfiguration, { params: params })
			.then((res) => {
				dispatch({ type: "LIST_CONFIGURATION", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_LIST_CONFIGURATION_SIGNUP(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listConfigurationSignup, { params: params })
			.then((res) => {
				dispatch({ type: "LIST_CONFIGURATION_SIGNUP", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}


export function AC_VIEW_CONFIGURATION(data) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewConfiguration + "/" + data)
			.then((res) => {
				dispatch({ type: "VIEW_CONFIGURATION", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

// ---------------------------------------------------------------
export function AC_VIEW_CONFIGOPTIONS(data) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewConfigOption + "/" + data)
			.then((res) => {
				dispatch({ type: "VIEW_CONFIGOPTIONS", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}
export function AC_HANDLE_CONFIGOPTIONS_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_CONFIGOPTIONS_CHANGE", name: name, value: value })
	};
}

export function AC_LIST_CONFIGOPTIONS(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listConfigOption, { params: params })
			.then((res) => {
				dispatch({ type: "LIST_CONFIGOPTIONS", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_LIST_CONFIGOPTIONS_BED_TYPE(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listConfigOptionSlug, { params: params })
			.then((res) => {
				dispatch({ type: "LIST_CONFIGOPTIONS_BED_TYPE", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}
export function AC_LIST_CONFIGOPTIONS_BRAND(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listConfigOptionSlug, { params: params })
			.then((res) => {
				dispatch({ type: "LIST_CONFIGOPTIONS_BRAND", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}
export function AC_LIST_CONFIGOPTIONS_TYPE(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listConfigOptionSlug, { params: params })
			.then((res) => {
				dispatch({ type: "LIST_CONFIGOPTIONS_VENDORTYPE", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_LIST_CONFIGOPTIONS_DOCUMENT(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listConfigOptionSlug, { params: params })
			.then((res) => {
				dispatch({ type: "LIST_CONFIGOPTIONS_DOCUMENT", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_LIST_CONFIGOPTIONS_MATTRESS(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listConfigOptionSlug, { params: params })
			.then((res) => {
				dispatch({ type: "LIST_CONFIGOPTIONS_MATTRESS", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_LIST_CONFIGOPTIONSSIGNUP(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listConfigOptionSignup, { params: params })
			.then((res) => {
				dispatch({ type: "LIST_CONFIGOPTIONSSIGNUP", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_EMPTY_CONFIGURATION() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_CONFIGURATION" })
	}
}


export function AC_LIST_CONFIGOPTIONS_RESERVE(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listConfigOptionSlug, { params: params })
			.then((res) => {
				dispatch({
					type: params.type, payload: res.data, spinner: false
				})
			}).catch((err) => { console.log(err); });
	};
}

export function AC_LIST_CONFIGOPTIONS_TODO_STATUS(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listConfigOptionSlug, { params: params })
			.then((res) => {
				dispatch({ type: "LIST_CONFIGOPTIONS_TODO_STATUS", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_MULTIPLE_LIST_CONFIGRATIONS(formData = {}) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.configurationArray, formData)
			.then((res) => {
				dispatch({ type: "MULTIPLE_LIST_CONFIGRATIONS", payload: res.data, spinner: false })
			}).catch((err) => { dispatch({ type: "MULTIPLE_LIST_CONFIGRATIONS", payload: {}, spinner: false }) });
	};
}

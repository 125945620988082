import Swal from 'sweetalert2';

const showSwalDialog = (title, text, confirmButtonText, cancelButtonText, imageUrl, denyButtonText) => {
    return Swal.fire({
        title: title || 'Are you sure?',
        text: text || '',
        showCancelButton: true,
        showDenyButton: denyButtonText ? true : false,
        confirmButtonText: confirmButtonText || 'Confirm',
        cancelButtonText: cancelButtonText || 'Cancel',
        denyButtonText: denyButtonText || 'No',
        imageUrl: imageUrl || '',
        customClass: {
            popup: 'swal_pop_new',
            title: 'swal_title_new',
            image: 'swal_image_new',
            content: 'swal_content_new', // Custom class for modal body content
            actions: 'swal_action_new',
            confirmButton: 'swal_confirm_new',
            cancelButton: 'swal_close_new',
            denyButton: 'swal_deny_new',
        }
    });
};

export default showSwalDialog;

const initialState = {
    listLanguage: [],
    language: {
        // languagestatus: '',
        name: '',
        value: '',
        code: '',
        reload: false,
        languageData: {},
        tempLanguageData: {},
    },
    tabposition: 'Website',
    spinner: true,
}
export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_LANGUAGE_CHANGE':
            return Object.assign({}, state, {
                language: {
                    ...state.language,
                    [action.name]: action.value
                }
            })
        case 'RESET_LANGUAGE':
            return Object.assign({}, state, {
                language: initialState.language,
            })
        case 'LIST_LANGUAGE':
            return {
                ...state,
                listLanguage: action.payload,
                spinner: false
            }
        case 'HANDLE_LANGUAGE_TABPOSITION_CHANGE':
            return Object.assign({}, state, {
                [action.name]: action.value
            })
        case 'VIEW_LANGUAGE':
            return Object.assign({}, state, {
                language: action.payload,
                spinner: false,
            })

        case 'EMPTY_LANGUAGE':
            return Object.assign({}, state, {
                language: {
                    name: '',
                    code: '',
                },
                spinner: true
            })
        case 'HANDLE_CHANGE':
            let updatedData = Object.assign(state.language.tempLanguageData, action.payload)
            return {
                ...state,
                language: {
                    ...state.language,
                    tempLanguageData: updatedData
                }
            }
        default:
            return state;
    }
}
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import "jspdf-autotable";
import Select from 'react-select';
import { AC_LIST_FEEDBACK, AC_LIST_FEEDBACK_TAB_CHANGES, AC_RESET_FEEDBACK } from '../../actions/reviewAction';
import ImportedURL from '../../common/api';
import axios from 'axios';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Error, Success } from '../../common/swal';
import Spinner from 'react-bootstrap/Spinner';
import { Row } from 'react-bootstrap';
import { imageCellRendererList, onErrorImage } from '../../common/validate';

const initialState = {
    columnDefs: [
        // { headerName: '#', valueGetter: "node.rowIndex+1", width: 100, sortable: false, filter: false, cellStyle: { 'text-align': 'center' } },
        {
            headerName: 'Logo', width: 100, field: 'logo', hide: false, sortable: false, filter: false, "resizable": false,
            cellRenderer: imageCellRendererList,
        },
        {
            headerName: 'Hotel ', field: 'hotelName', hide: false, width: 150, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data.hotelName)
                    return `${params.data.hotelName}`;
                else return '---'
            }
        },
        {
            headerName: 'Name', field: 'name', width: 150, cellStyle: { 'text-align': 'center' }, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data.name) {
                    return params.data.name
                } else {
                    return '---'
                }
            }
        },
        {
            headerName: 'Room #', field: 'roomno', width: 100, cellStyle: { 'text-align': 'center' }, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data.roomno) {
                    return params.data.roomno
                } else {
                    return '---'
                }
            }
        },
        {
            headerName: 'Phone #', field: 'phone', width: 150, cellStyle: { 'text-align': 'center' }, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data.phone || params.data.cc) {
                    return (params.data.cc ? params.data.cc + " " : '') + (params.data.phone ? params.data.phone : '');
                } else {
                    return '---'
                }
            }
        },
        {
            headerName: 'Feedback', field: 'feedback', width: 150, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data.feedback) {
                    return (params.data.feedback);
                } else {
                    return '---'
                }
            }
        },
        {
            headerName: 'Received At', field: 'createdAt', width: 150, cellStyle: { 'text-align': 'center' },
            valueGetter: function (params) {
                return moment(params.data.createdAt).format(params.data.dtFormat);
            }
        },
        {
            headerName: 'Action', width: 150, sortable: false, filter: false, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            template:
                `<i class="fa fa-eye" type="button" style='color: #1DC9B7 !important' data-action-type="View" data-toggle="modal" data-target="#listreportmodel"></i>`,
        },
    ],
    columnDefs1: [
        // { headerName: '#', valueGetter: "node.rowIndex+1", width: 100, sortable: false, filter: false, cellStyle: { 'text-align': 'center' } },
        {
            headerName: 'Logo', width: 100, field: 'logo', hide: true, sortable: false, filter: false, "resizable": false,
            cellRenderer: imageCellRendererList,
        },
        {
            headerName: 'Hotel ', field: 'hotelName', hide: true, width: 150, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data.hotelName)
                    return `${params.data.hotelName}`;
                else return '---'
            }
        },
        {
            headerName: 'Name', field: 'name', width: 150, cellStyle: { 'text-align': 'center' }, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data.name) {
                    return params.data.name
                } else {
                    return '---'
                }
            }
        },
        {
            headerName: 'Room #', field: 'roomno', width: 100, cellStyle: { 'text-align': 'center' }, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data.roomno) {
                    return params.data.roomno
                } else {
                    return '---'
                }
            }
        },
        {
            headerName: 'Phone #', field: 'phone', width: 150, cellStyle: { 'text-align': 'center' }, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data.phone || params.data.cc) {
                    return (params.data.cc ? params.data.cc + " " : '') + (params.data.phone ? params.data.phone : '');
                } else {
                    return '---'
                }
            }
        },
        {
            headerName: 'Feedback', field: 'feedback', width: 150, floatingFilter: true,
            valueGetter: function (params) {
                if (params.data.feedback) {
                    return (params.data.feedback);
                } else {
                    return '---'
                }
            }
        },
        {
            headerName: 'Received At', field: 'createdAt', width: 150, cellStyle: { 'text-align': 'center' },
            valueGetter: function (params) {
                return moment(params.data.createdAt).format(params.data.dtFormat);
            }
        },
        {
            headerName: 'Action', width: 150, sortable: false, filter: false, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            template:
                `<i class="fa fa-eye" type="button" style='color: #1DC9B7 !important' data-action-type="View" data-toggle="modal" data-target="#listreportmodel"></i>`,
        },
    ],
    defaultColumDef: {
        // "width": 200,
        // "filter": true,
        // "sortable": true,
        // "resizable": true,
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
    },
    feedbacks: [],
    orignalData: [],
    hotel: '',
    currentPage: 1,
    todosPerPage: 25,
    upperPageBound: 3,
    lowerPageBound: 0,
    isPrevBtnActive: 'disabled',
    isNextBtnActive: '',
    pageBound: 3,
    feedbackData: {},
    gridView: false,
    hideReset: false,
    replyid: '',
    hotelName: '',
    hotelLabel: '',
    spinner: false,
    issuestatus: false,
    columnreset: true,
    totalfeedback: 0,
    hideExport: false
}

class ListFeedback extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    // Grid funtions
    onRowClicked = event => {
        this.setState({ feedbackData: event.data })
    }

    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    generateColumnsForExcel() {
        const keys = this.gridColumnApi
            .getAllDisplayedColumns()
            .map(column => column.getColId())
        return keys;
    }

    exportToCSV = () => {
        const account = this.props.AccountState.account;
        const dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat != '' ? account.timeformat : "hh:mm A")
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: ['hotelName', 'roomno', 'name', 'phone', 'feedback', 'createdAt'],
            fileName: "feedbacks" + "_" + (this.state.hotelName && (this.state.hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                if (params && params.column && params.column.colId === 'createdAt') {
                    return moment(params.value).format(dtFormat);
                } else {
                    return params.value;
                }
            }
        })
    }

    // Export function

    exportPDF = () => {
        const account = this.props.AccountState.account;
        const dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat != '' ? account.timeformat : "hh:mm A")

        const selectedProperties = this.state.hotelName ? ['#', "roomno", "name", 'phone', 'feedback', 'createdAt'] : ['#', "hotelName", "roomno", "name", 'phone', 'feedback', 'createdAt']
        const api = this.gridApi;
        const filteredRows = api.getModel().rowsToDisplay
        const feedbacks = filteredRows.map(rowNode => rowNode.data);
        const selected = feedbacks.map(item => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    if (property == 'createdAt') {
                        selectedData[property] = moment(item[property]).format(dtFormat)
                    } else if (property == "roomno" || property == "phone") {
                        selectedData[property] = item[property] ? item[property] : "---"
                    } else {
                        selectedData[property] = item[property];
                    }
                }
            });
            return selectedData;
        });
        let data = {
            title: (this.state.hotelName) ? (this.state.hotelName != "All" ? this.state.hotelName + " - Report List" : "Report List") : "Report List",
            head: this.state.hotelName ? ['#', "Room #", "Name", "Phone #", 'Feedback', 'Received At'] : ['#', "Hotel", "Room #", "Name", "Phone #", 'Feedback', 'Received At'],
            body: selected,
            hotelNameExport: this.state.hotelName ? account.hotelNameExport : '',
            hoteladdressExport: this.state.hotelName ? account.hoteladdressExport : '',
            limit: this.state.todosPerPage,
            hotelWidth: true
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "feedbacks" + "_" + (this.state.hotelName && (this.state.hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }
    // Filter functions

    handleChange = e => {
        const { name, value, label } = e;
        this.getFeedbackData({ page: 1, hotel: value });

        this.setState({ hotel: value, hotelName: label, hideReset: true, hotelLabel: e, currentPage: 1 });
    }

    filterReset = (e) => {
        this.getFeedbackData({ page: 1, hotel: '', from: '', to: '' });
        const account = this.props.AccountState.account;

        if (ImportedURL.SUPERADMIN.includes(account.role)) {
            this.setState({ hotel: '', hotelName: '', fromDate: '', toDate: '', hideReset: false, hotelLabel: "", currentPage: 1 });
        } else {
            this.setState({ fromDate: '', toDate: '', hideReset: false, currentPage: 1 });
        }
    }

    setDate = (e) => {
        const { name, value } = e.target;
        let newtime = new Date(e.target.value);
        const account = this.props.AccountState.account;
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
        }

        this.setState({ hideReset: true })
        if (name == 'fromDate') {
            this.setState({ spinner: true })
            this.getFeedbackData({ page: 1, from: value ? value + "T00:00:00" : '', to: '' });
            this.setState({ fromDate: value, fromTime: newtime, toDate: '', currentPage: 1 });
        }

        if (name == 'toDate') {
            this.setState({ toDate: value, toTime: newtime, spinner: true, currentPage: 1 });
            this.getFeedbackData({ page: 1, to: value ? value + "T23:59:59" : '' });
        }
        if (!this.state.hotel && !value) {
            if (!this.state.fromDate && name == 'toDate') {
                this.setState({ hideReset: false });
            }
            if (name == 'fromDate') {
                this.setState({ hideReset: false });
            }
        } else {
            this.setState({ hideReset: true });
        }
    }

    //Get data format
    getDate = (date) => {
        const account = this.props.AccountState.account;
        var dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + "  " + (account.timeformat != '' ? account.timeformat : "hh:mm A")
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
        }
        if (!date) return '';
        return moment(date).format(dtFormat);
    }

    componentDidMount() {
        this.setState({ spinner: true })
        this.props.ResetFeedback();
        this.getFeedbackData();
        const account = this.props.AccountState.account;
        this.setState({ hotelName: account.hotelName ? account.hotelName : '' });
    }

    getFeedbackData = (query = {}) => {
        this.setState({ spinner: true })
        const tabposition = this.props.ReviewState.tabposition;
        let ParamsQuery = { page: this.state.currentPage, perPage: this.state.todosPerPage, from: this.state.fromDate ? this.state.fromDate + "T00:00:00" : '', to: this.state.toDate ? this.state.toDate + "T23:59:59" : '', hotel: this.state.hotel, tabposition: !this.state.gridView ? tabposition : "", ...query }
        // if (!this.state.gridView) {
        //     ParamsQuery = { ...ParamsQuery, tabposition: '' }
        // }
        axios.get(ImportedURL.API.getFeedbackCount, { params: ParamsQuery })
            .then((res) => {
                if (res.data) {
                    this.setState({ comment: res.data.comment ? res.data.comment : 0, noncomment: res.data.noncomment ? res.data.noncomment : 0, feedbacks: (res.data.feedback && res.data.feedback.data) ? res.data.feedback.data : [], totalfeedback: (res.data.feedback && res.data.feedback.total) ? res.data.feedback.total : 0 })
                }
                this.setState({ spinner: false })
            }).catch((err) => {
                this.setState({ spinner: false })
                console.log(err);
            });
        // axios.get(ImportedURL.API.getFeedback, { params: ParamsQuery })
        //     .then((res) => {
        //         if (res.data)
        //             this.setState({ feedbacks: res.data.data ? res.data.data : [], totalfeedback: res.data.total ? res.data.total : 0 })
        //         this.setState({ spinner: false })

        //     }).catch((err) => {
        //         this.setState({ spinner: false })
        //         console.log(err);
        //     });
    }

    // componentDidUpdate() {
    //     const { AccountState } = this.props;
    //     const role = AccountState.role;
    //     if (this.gridApi && role && !ImportedURL.SUPERADMIN.includes(role) && this.state.columnreset) {
    //         let updatedColumnDefs = [...this.state.columnDefs];
    //         updatedColumnDefs = updatedColumnDefs.map((col) => {
    //             if (col.field === 'hotelName' || col.field === 'logo') {
    //                 return { ...col, hide: true };
    //             }
    //             return col;
    //         });
    //         this.gridApi.setColumnDefs(updatedColumnDefs);
    //         this.setState({ columnreset: false })
    //     }
    // }


    sendReply = () => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        var replymessage = this.state.replymessage.trim()
        this.setState({ replymessage: replymessage })
        if (!replymessage) return Error((languageData && languageData.TYPE_A_REPLY_POST ? languageData.TYPE_A_REPLY_POST : "Type any discription to update"));
        axios.post(ImportedURL.API.sendReply, { id: this.state.replyid, reply: replymessage, issuestatus: this.state.issuestatus, postedOn: new Date() })
            .then((res) => {
                this.setState({ replymessage: '', issuestatus: false, })
                Success((languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : "Updated sucessfully"))
                this.getFeedbackData();
            }).catch((err) => { Error((languageData && languageData.TRY_AGAIN ? languageData.TRY_AGAIN : "Try again")) });
    }

    changepage = (type) => {
        const totalPages = Math.ceil(this.state.totalfeedback / this.state.todosPerPage);
        this.setState({ spinner: true })
        if (type == 'prev') {
            this.getFeedbackData({ page: Math.max(this.state.currentPage - 1, 1), });
            this.setState({ currentPage: Math.max(this.state.currentPage - 1, 1) })
        } else if (type == 'next') {
            this.getFeedbackData({ page: Math.min(this.state.currentPage + 1, totalPages), });
            this.setState({ currentPage: Math.min(this.state.currentPage + 1, totalPages) })
        } else if (type == 'back') {
            this.getFeedbackData({ page: 1, });
            this.setState({ currentPage: 1 })
        } else if (type == 'forward') {
            this.getFeedbackData({ page: totalPages });
            this.setState({ currentPage: totalPages })
        }
    }
    tapChange = (e) => {
        this.props.HandleTabChangePosition(e);
        this.setState({ currentPage: 1, todosPerPage: 25 })
        this.getFeedbackData({ tabposition: e, page: 1, perPage: 25 });
    }
    ChangeGrid = () => {
        const tabposition = this.props.ReviewState.tabposition;
        this.setState({ gridView: !this.state.gridView, currentPage: 1, todosPerPage: 25, hideExport: false })
        if (!this.state.gridView) {
            this.getFeedbackData({ tabposition: '', page: 1, perPage: 25 });
        } else {
            this.getFeedbackData({ tabposition: tabposition, page: 1, perPage: 25 });
        }
    }
    handleFilterChanged = () => {
        const api = this.gridApi;
        if (api && api.getDisplayedRowCount() === 0) {
            api.showNoRowsOverlay();
            this.setState({ hideExport: true })
        } else {
            api.hideOverlay();
            this.setState({ hideExport: false })
        }
    };
    render() {
        const { AccountState, ReviewState, LandingState } = this.props;
        const rowData = this.state.feedbacks;
        const spinner = this.state.spinner;
        const listHotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[0] : [];;
        const languageData = AccountState.account.SelectedlanguageData
        const tabposition = ReviewState.tabposition;
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        const account = AccountState.account;
        var hotelOptionsSearch = [];
        if (listHotels.length > 1) {
            hotelOptionsSearch.push({ label: 'All', value: '', name: 'hotel' })
        }
        if (listHotels.length > 0) {
            listHotels.filter(filterItem => filterItem.status === true && filterItem.tripsadvisorurl && filterItem.googlereviewurl).map(item => {
                if (ImportedURL.SUPERADMIN.includes(account.role)) {
                    hotelOptionsSearch.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
                }
            })
        }

        const viewfeedbackData = this.state.feedbackData;
        var dFormat = account.dateformate ? account.dateformate : 'MM/DD/YYYY'
        var dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat != '' ? account.timeformat : "hh:mm A")
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
        }
        if (rowData) {
            rowData.forEach(object => {
                object.dtFormat = dtFormat;
                object.tzone = tzone;
            });
        }
        const totalPages = Math.ceil(this.state.totalfeedback / this.state.todosPerPage); // Assuming pageSize is 10
        return (
            <>
                <div>
                    <div >
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/feedbacks"><h6 className="highlights_breadcrump"> {languageData && languageData.FEEDBACKS ? languageData.FEEDBACKS : "Feedback"}</h6></Link></p>
                        </div>
                        <div className="section-body pt-3">
                            <div className='review_sec'>
                                <div className="container-fluid">
                                    <div className="tab-content">
                                        <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="card-header top_title_icon">
                                                        <div className='rounded_icon'><i className="fa fa-comments mr-2"></i></div>
                                                        <h3 className="card-title top_title common_title_fs"> {languageData && languageData.FEEDBACKS ? languageData.FEEDBACKS : "Feedback"}</h3>
                                                        <div className="card-options ">
                                                            {
                                                                this.state.gridView
                                                                &&
                                                                <>
                                                                    {rowData && rowData.length > 0 && !this.state.hideExport ?
                                                                        <div>
                                                                            <div className="d-flex justify-content-between align-items-center">
                                                                                <ul className="nav nav-tabs page-header-tab">
                                                                                </ul>
                                                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle export_btn_grid" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2 " aria-hidden="true"></i>Export</a>
                                                                                <div className="dropdown-menu">
                                                                                    <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                                    <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                                                </div>
                                                                            </div>
                                                                        </div> : ''}
                                                                </>
                                                            }
                                                            <div>
                                                                <span className="input-group-btn ml-2">
                                                                    <button className="btn btn-sm btn-default fdb_doggulor" style={{ fontSize: "25px" }} onClick={this.ChangeGrid}>
                                                                        {this.state.gridView ? <span className="fe fe-list"></span> : <span className="fe fe-grid"></span>}
                                                                    </button>
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card">
                                                <div className="card-body">
                                                    <div className="row">
                                                        <div className="col-xl-2 col-lg-2 col-md-6 col-sm-2  col-12">
                                                            <label className="form-label">Limit</label>
                                                            <div className="form-group">
                                                                <Select
                                                                    onChange={(e) => { this.gridApi.paginationSetPageSize(Number(e.value)); this.setState({ todosPerPage: Number(e.value), currentPage: 1, perPage: Number(e.value) }); this.getFeedbackData({ perPage: Number(e.value), page: 1 }); }}
                                                                    value={this.state.todosPerPage ? limitOptions.find((e) => e.value == this.state.todosPerPage) : ""}
                                                                    options={limitOptions}
                                                                    defaultValue={limitOptions[0]}
                                                                    className='limit_size'
                                                                />
                                                            </div>
                                                        </div>
                                                        {
                                                            ImportedURL.SUPERADMIN.includes(account.role) &&
                                                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                                                <label className="form-label">Hotel</label>
                                                                <div className="form-group">
                                                                    <Select
                                                                        onChange={this.handleChange}
                                                                        options={hotelOptionsSearch}
                                                                        value={this.state.hotelLabel ? this.state.hotelLabel : ""}
                                                                        formatOptionLabel={(e) => (
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                                <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                            </div>
                                                                        )}
                                                                    />
                                                                </div>
                                                            </div>
                                                        }

                                                        <div className={ImportedURL.SUPERADMIN.includes(account.role) ? "col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12" : "col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"}>
                                                            <div className="form-group">
                                                                <label className="form-label">From Date</label>
                                                                <div className='site_pass_calander'>
                                                                    <input type='date' className="form-control" name="fromDate" value={this.state.fromDate} onChange={this.setDate}></input>
                                                                    <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className={ImportedURL.SUPERADMIN.includes(account.role) ? "col-xl-2 col-lg-2 col-md-6 col-sm-6 col-12" : "col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"}>
                                                            <div className="form-group">
                                                                <label className="form-label">To Date</label>
                                                                <div className='site_pass_calander'>
                                                                    <input type='date' className="form-control" name="toDate" min={this.state.fromDate ? this.state.fromDate : ''} onChange={this.setDate} value={this.state.toDate}></input>
                                                                    <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            (this.state.gridView)
                                                                ?
                                                                <>
                                                                    {this.state.hideReset ?
                                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-xl-3 d-flex align-items-center">
                                                                            <button className='btn social_rest_btn btn-primarys' type='button' onClick={this.filterReset}><i className="fa fa-refresh" aria-hidden="true" style={{ fontSize: '20px !importent', marginRight: '7px' }}></i> Reset</button>
                                                                        </div> : ''}
                                                                </>
                                                                : ''
                                                        }
                                                    </div>
                                                    {
                                                        (!this.state.gridView)
                                                            ?
                                                            <div className='upcommcomp_btn review_feedback_tab'>
                                                                <div className='mr-2' style={{ position: 'relative', margin: "8px 0px" }} >
                                                                    <button type='button' className={`${tabposition == 'noncomment' ? 'btn btn-primery after_click button_feedback_tab' : 'btn btn-primery before_click button_feedback_tab'}`} onClick={(e) => this.tapChange("noncomment")}>Non Comment</button><span className="badge badge-light" style={{ backgroundColor: 'rgb(194 187 45)' }}>{this.state.noncomment ? this.state.noncomment : 0}</span>
                                                                </div>
                                                                <div className='mr-2' style={{ position: 'relative', margin: "8px 0px" }}>
                                                                    <button type='button' className={`${tabposition == 'comment' ? 'btn btn-primery after_click button_feedback_tab' : 'btn btn-primery before_click button_feedback_tab'}`} onClick={(e) => this.tapChange("comment")} >Comment</button><span className="badge badge-light" style={{ backgroundColor: '#17a2b8' }}>{this.state.comment ? this.state.comment : 0}</span>
                                                                </div>
                                                                {this.state.hideReset ?
                                                                    <div className="col-lg-2 col-md-4 col-sm-6 col-xl-3 d-flex align-items-center">
                                                                        <button className='btn social_rest_btn btn-primarys' type='button' onClick={this.filterReset}><i className="fa fa-refresh" aria-hidden="true" style={{ fontSize: '20px !importent', marginRight: '7px' }}></i> Reset</button>
                                                                    </div> : ''}
                                                            </div>
                                                            : ""
                                                    }
                                                </div>
                                            </div>

                                            <div style={this.state.gridView ? { display: 'none' } : {}}>
                                                {this.state.feedbacks.map((data, i) => {
                                                    let ratingArr = []
                                                    if (data.rating != undefined) {
                                                        for (let i = 1; i <= 3; i++) {
                                                            if (i <= data.rating) ratingArr.push(<i className="fa fa-star text-orange  mr-1" key={i}></i>)
                                                            else ratingArr.push(<i className="fa fa-star mr-1" key={i}></i>)
                                                        }
                                                    }
                                                    return <div className="card" key={i}>
                                                        <div className="card-body fback_body">
                                                            <div className='image_size_fd'>
                                                                <Row>
                                                                    {ImportedURL.SUPERADMIN.includes(account.role) &&
                                                                        <div className='col-xl-1 col-lg-1 col-md-2 col-12 col-12'>
                                                                            <div className='img_size'>
                                                                                <img className="avatar avatar-xl mr-3" style={{ background: 'none' }} src={data.hotel && data.logo ?
                                                                                    ImportedURL.FILEURL + data.logo : 'assets/images/defaultuser.png'}
                                                                                    onError={(e) => { if (e.target.src !== "assets/images/defaultuser.png") { e.target.onerror = null; e.target.src = "assets/images/defaultuser.png"; } }
                                                                                    }
                                                                                    alt="" />
                                                                            </div>
                                                                        </div>}
                                                                    <div className='col-xl-8 col-lg-8 col-md-8 col-8 col-8'>
                                                                        <div className='rating' style={!ImportedURL.SUPERADMIN.includes(account.role) ? { marginLeft: "15px" } : {}}>
                                                                            <p className='mb-0'>
                                                                                {data.roomno ? <span className="h6">Room # : {data.roomno} </span> : ''}<span style={{ marginLeft: '10px' }}>{ratingArr}</span>
                                                                                {ImportedURL.SUPERADMIN.includes(account.role) && <p style={{ fontSize: '13px', fontWeight: '600', marginBottom: '0px' }}>{data.hotelName ? `${data.hotelName}` : ''}</p>}
                                                                                <p className='mb-0' style={{ fontSize: '14px' }}> {data.namedetails}</p>
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    {data.checkoutdate ? <div className={ImportedURL.SUPERADMIN.includes(account.role) ? 'col-xl-3 col-lg-3 col-md-3 col-3' : 'col-xl-4 col-lg-4 col-md-4 col-4'}>
                                                                        <p className='mb-0' style={{ fontSize: '14px', fontWeight: '600', float: "right" }}>Checkout : {moment(data.checkoutdate).format(dFormat)}</p>
                                                                    </div> :
                                                                        <div className={ImportedURL.SUPERADMIN.includes(account.role) ? 'col-xl-3 col-lg-3 col-md-3 col-3' : 'col-xl-4 col-lg-4 col-md-4 col-4'}>
                                                                            <p className='mb-0' style={{ fontSize: '14px', fontWeight: '600', float: "right" }}>Checkout : {moment(data.createdAt).format(dFormat)}</p>
                                                                        </div>}
                                                                </Row>
                                                            </div>
                                                            <article className="media">
                                                                <div className="media-body">
                                                                    <div className="content">
                                                                        {data.feedback ?
                                                                            <div className="chat_windows">
                                                                                <ul className="mb-0 pl-0">
                                                                                    <li className="my-message">
                                                                                        <div className="message">
                                                                                            <div className='col-sm-12 col-md-12 mt-3'>
                                                                                                <div style={{ display: 'flex', justifyContent: 'start' }}>
                                                                                                    <div style={{ position: 'relative' }}>
                                                                                                        <p className="bg-light-gray received">{data.feedback}</p>
                                                                                                        <span className="time received-time">{this.getDate(data.createdAt)}</span>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </li>
                                                                                </ul>
                                                                            </div>
                                                                            : ''}
                                                                        {data.replies ? <>
                                                                            {data.replies && data.replies.map((item, j) => {
                                                                                return <div className="chat_windows">
                                                                                    <ul className="mb-0 pl-0">
                                                                                        <li className="my-message" key={j}>
                                                                                            <div className="message">
                                                                                                <div className='col-sm-12 col-md-12 mt-3'>
                                                                                                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                                                                                                        <div style={{ position: 'relative' }}>
                                                                                                            {data.feedback ? <p className="review-response">{item.username} - Response Time : {item.responsetime}</p> : ''}
                                                                                                            <p className="bg-light-gray">{item.reply}</p>
                                                                                                            <span className="time">{moment(item.postedOn).format(dtFormat)}</span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            })}
                                                                        </>
                                                                            : ''}



                                                                        {/* {data.replies && data.replies.length < 1 ?
                                                                            <> */}
                                                                        {!data.issuestatus &&
                                                                            <>
                                                                                <div className='reply_icon'>
                                                                                    <div className='d-flex align-items-center mt-1'>
                                                                                        <a type='button' style={{ fontSize: '23px' }} onClick={() => { this.setState({ replyid: data._id, replymessage: '' }) }}>
                                                                                            <i className="icon-bubbles"></i>
                                                                                        </a>
                                                                                        <label className="custom-control custom-switch mb-0 review_toggle" onClick={() => { this.setState({ replyid: data._id, replymessage: '' }) }}>
                                                                                            <input type="checkbox" className="custom-control-input" name="issuestatus" checked={this.state.issuestatus == data._id} onChange={(e) => { if (e.target.checked) { this.setState({ issuestatus: data._id }) } else this.setState({ issuestatus: '' }) }} />
                                                                                            <span className="custom-control-label" style={{ cursor: 'pointer', }}>{this.state.issuestatus == data._id ? "Issue Resolved" : "Issue Not Resolved"}</span>
                                                                                        </label>
                                                                                    </div>
                                                                                </div>

                                                                                {
                                                                                    this.state.replyid == data._id &&
                                                                                    <>
                                                                                        <div className="form-group"><textarea rows="4" className="form-control no-resize" value={this.state.replymessage} placeholder="Give brief discription..." onChange={(e) => { this.setState({ replymessage: e.target.value }) }}></textarea></div>
                                                                                        <button type="button" className="btn commor_save" onClick={this.sendReply}><i className="fe fe-save mr-2"></i>Update</button>
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </article>
                                                        </div>
                                                    </div>
                                                }
                                                )}
                                            </div>
                                            {rowData.length > 0 && account.role ? <div className="card" style={!this.state.gridView ? { display: 'none' } : {}}>
                                                <div className="card-body">
                                                    <div className="table-responsive">
                                                        {
                                                            ImportedURL.SUPERADMIN.includes(account.role) ?
                                                                <div className="ag-theme-alpine">
                                                                    <AgGridReact
                                                                        rowHeight={82}
                                                                        paginationPageSize={25}
                                                                        pagination={false}
                                                                        onRowClicked={this.onRowClicked}
                                                                        domLayout={"autoHeight"}
                                                                        defaultColDef={this.state.defaultColumDef}
                                                                        onGridReady={this.onGridReady}
                                                                        rowData={rowData}
                                                                        columnDefs={this.state.columnDefs}
                                                                        onFilterChanged={this.handleFilterChanged}
                                                                        overlayNoRowsTemplate={"No rows to display"}
                                                                    >
                                                                    </AgGridReact>
                                                                </div> :
                                                                <div className="ag-theme-alpine">
                                                                    <AgGridReact
                                                                        rowHeight={82}
                                                                        paginationPageSize={25}
                                                                        pagination={false}
                                                                        onRowClicked={this.onRowClicked}
                                                                        domLayout={"autoHeight"}
                                                                        defaultColDef={this.state.defaultColumDef}
                                                                        onGridReady={this.onGridReady}
                                                                        rowData={rowData}
                                                                        columnDefs={this.state.columnDefs1}
                                                                        onFilterChanged={this.handleFilterChanged}
                                                                        overlayNoRowsTemplate={"No rows to display"}
                                                                    >
                                                                    </AgGridReact>
                                                                </div>
                                                        }


                                                    </div>
                                                </div>
                                            </div> : ''}
                                            {(rowData.length > 0) ?
                                                <>
                                                    {
                                                        (totalPages && totalPages > 1)
                                                            ?
                                                            <>
                                                                <div className="card-body pl-0 pt-2 pb-4">
                                                                    <ul className="pagination mt-2">
                                                                        <li className="page-item"><button className="page-link" style={this.state.currentPage === 1 ? { background: 'lightgrey', cursor: "default" } : {}} role='button' onClick={() => this.changepage('back')} disabled={this.state.currentPage === 1}><i class="fa fa-step-backward" ></i></button></li>
                                                                        <li className="page-item"><button type='button' style={this.state.currentPage === 1 ? { background: 'lightgrey', cursor: "default" } : {}} className="page-link" role='button' onClick={() => this.changepage('prev')} disabled={this.state.currentPage === 1}><i className="fa fa-chevron-left" ></i></button></li>
                                                                        <li className='page-item active' ><a className="page-link" role='button'>Page {this.state.currentPage} of {totalPages}</a></li>
                                                                        <li className="page-item"><button type='button' className="page-link" role='button' onClick={() => this.changepage('next')} disabled={this.state.currentPage === totalPages} style={this.state.currentPage === totalPages ? { background: 'lightgrey', cursor: "default" } : {}}><i className="fa fa-chevron-right" ></i></button></li>
                                                                        <li className="page-item"><button className="page-link" role='button' onClick={() => this.changepage('forward')} disabled={this.state.currentPage === totalPages} style={this.state.currentPage === totalPages ? { background: 'lightgrey', cursor: "default" } : {}}><i class="fa fa-step-forward" ></i></button></li>
                                                                    </ul>
                                                                </div>
                                                            </>
                                                            : <div className="pagination mt-2 mb-2"></div>
                                                    }
                                                </>
                                                :
                                                spinner ?
                                                    ""
                                                    :
                                                    <div className="card-body text-center d-flex align-items-center justify-content-center">
                                                        <div style={{ maxWidth: "340px" }}>
                                                            <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid mb-4 mt-4" style={{ maxWidth: "272px" }} />
                                                            <h5 className="mb-2">No records to display </h5>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="overlay" style={{ display: spinner ? 'block' : 'none' }}></div>
                        <div className="section-body">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="modal fade" id="listreportmodel" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                                        <div className="modal-dialog latest_review_modal" role="document">
                                            <div className="modal-content">
                                                <div className="modal-header">
                                                    <div className='rounded_icon'><i className="fa fa-comments mr-2"></i></div>
                                                    <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}> {languageData && languageData.VIEW ? languageData.VIEW + ' ' : "View "}{languageData && languageData.FEEDBACK ? languageData.FEEDBACK : "Feedback"}</h5>
                                                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                                        <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                                            <img src='../../assets/images/cancel.png' />
                                                        </span>
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="modal-body">
                                                        <article className="media">
                                                            <div className="mr-3">
                                                                {ImportedURL.SUPERADMIN.includes(account.role) && <img className="avatar avatar-xl" src={viewfeedbackData && viewfeedbackData.logo ?
                                                                    ImportedURL.FILEURL + viewfeedbackData.logo : 'assets/images/defaultuser.png'}
                                                                    onError={(e) => { if (e.target.src !== "assets/images/defaultuser.png") { e.target.onerror = null; e.target.src = ImportedURL.LOCALURL + "assets/images/defaultuser.png"; } }} alt=""
                                                                />}
                                                            </div>
                                                            <div className="media-body">
                                                                <div className="content">
                                                                    <p className="h5"><span className='pr-2'>{viewfeedbackData.name}</span>
                                                                        <small className="float-right text-muted">{viewfeedbackData.createdAt ? moment(viewfeedbackData.createdAt).format(dtFormat) : ''}</small>
                                                                    </p>
                                                                    <p>
                                                                        <small className='pt-2'>{viewfeedbackData.phone}</small>
                                                                        {ImportedURL.SUPERADMIN.includes(account.role) && <small className="float-right text-muted">{viewfeedbackData.hotelName ? `${viewfeedbackData.hotelName}` : ''}</small>}
                                                                    </p>
                                                                    <p dangerouslySetInnerHTML={{ __html: viewfeedbackData.feedback }} className="mt-3" style={{ overflowWrap: "anywhere" }}></p>
                                                                </div>
                                                            </div>
                                                        </article>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                    <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div>
                    {
                        this.state.spinner &&
                        <div className='common_loader'>
                            <img className='loader_img_style_common' src='../../assets/images/load.png' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                        </div>
                    }
                </div >

            </>
        )
    }
}
const mapStateToProps = state => ({
    ReviewState: state.review,
    AccountState: state.account,
    LandingState: state.landing,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListFeedback: AC_LIST_FEEDBACK,
        ResetFeedback: AC_RESET_FEEDBACK,
        HandleTabChangePosition: AC_LIST_FEEDBACK_TAB_CHANGES,
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ListFeedback);

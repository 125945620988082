import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_VIEW_HOTEL } from '../../actions/hotelAction';
import axios from 'axios';
import ImportedURL from '../../common/api';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import Spinner from 'react-bootstrap/Spinner';
import Swal from 'sweetalert2';
import { AC_LIST_COUNTRY, AC_LIST_COUNTRY_CATEGORY } from '../../actions/countryAction';
import { Container, Row } from 'react-bootstrap';
import { AC_LIST_CATEGORY } from '../../actions/categoryAction';
import { AC_HANDLE_DYNAMIC_CATEGORY_MULTIPLE, AC_HANDLE_DYNAMIC_CATEGORY_MULTIPLE_UPDATE, AC_LIST_DYNAMIC_CATEGORY, AC_LIST_DYNAMIC_CATEGORY_STATUS, AC_VIEW_DYNAMIC_CATEGORY } from '../../actions/dynamiccategoryAction';
import { CapitalizeFirstLetter, Emailvalidate, Phonenumber, onErrorImage } from '../../common/validate';
import { Error, Success } from '../../common/swal';
import Select from 'react-select';

const initialState = {
    hotelData: '',
    modalType: 'Add',
    id: '',
    loader: false,
    saving: false,
    emailValidError: false,
    phoneNoPatternError: false,
    phoneIndex: [],
    emailIndex: [],
}
class AddDynamicCategory extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }
    async componentDidMount() {
        this.props.ListCountry();
        let id = this.props.match.params.id;
        this.props.ListStatus(id);
        this.props.ListDynamicCategory(id);
        this.setState({ id: id })
        await axios.get(ImportedURL.API.viewHotelData + '/' + id)
            .then((res) => {
                this.setState({ hotelData: res.data });
                this.setState({ loader: true })
            });
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    onChangeDynamic(i, e) {
        this.setState({ spinner: false })
        var { name, value } = e.target;
        if (name == 'phonenumber') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var s3 = s2.slice(0, 10);
            var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            this.props.HandleChangeDynamic(name, val, i);
            if (val) {
                if (Phonenumber(val)) {
                    let arr = this.state.phoneIndex.filter((item, index) => item !== i + 1)
                    this.setState({ phoneIndex: arr })
                } else {
                    let repeatedMaintance = this.state.phoneIndex.find(obj => { return obj == i + 1 })
                    if (!repeatedMaintance) {
                        this.setState({ phoneIndex: [...this.state.phoneIndex, i + 1] })
                    }
                }
            } else {
                let arr = this.state.phoneIndex.filter((item, index) => item !== i + 1)
                this.setState({ phoneIndex: arr })
            }
        } else if (name == 'email') {
            this.props.HandleChangeDynamic(name, value, i);
            var email = value;
            if (email) {
                if (Emailvalidate(email)) {
                    let arr = this.state.emailIndex.filter((item, index) => item !== i + 1)
                    this.setState({ emailIndex: arr })
                } else {
                    let repeatedMaintance = this.state.emailIndex.find(obj => { return obj == i + 1 })
                    if (!repeatedMaintance) {
                        this.setState({ emailIndex: [...this.state.emailIndex, i + 1] })
                    }
                }
            } else {
                let arr = this.state.emailIndex.filter((item, index) => item !== i + 1)
                this.setState({ emailIndex: arr })
            }
        } else {
            this.props.HandleChangeDynamic(name, value, i);
        }
    }
    onChangeCC(i, e) {
        this.setState({ spinner: false })
        var { name, value } = e;
        this.props.HandleChangeDynamic(name, value, i);
    }
    addForm(e, i) {
        if (this.state.emailIndex.length > 0) {
            let arr = []
            this.state.emailIndex.map((item) => {
                arr.push(item + 1)
            })
            this.setState({ emailIndex: arr })
        }
        if (this.state.phoneIndex.length > 0) {
            let arr = []
            this.state.phoneIndex.map((item) => {
                arr.push(item + 1)
            })
            this.setState({ phoneIndex: arr })
        }
        this.setState({ spinner: false })
        this.props.HandleUpadteForm('Add', { categoryname: e.categoryname, namestatus: false, _id: e._id, previleges: e.previleges, name: '', email: '', phonenumber: '', }, i);
    }
    removeForm(i) {
        if (this.state.emailIndex.length > 0) {
            let arr = this.state.emailIndex.filter((item, index) => item !== i + 1)
            this.setState({ emailIndex: arr })
        }
        if (this.state.phoneIndex.length > 0) {
            let arr = this.state.phoneIndex.filter((item, index) => item !== i + 1)
            this.setState({ phoneIndex: arr })
        }
        this.setState({ spinner: false })
        this.props.HandleUpadteForm('Remove', '', i);
    }
    emailChange = (i) => {
        let email = this.state.emailIndex
        if (email) {
            if (email.includes(i)) {
                return 1
            } else {
                return 0
            }
        } else {
            return 0
        }
    }
    phoneChange = (i) => {
        let phone = this.state.phoneIndex
        if (phone) {
            if (phone.includes(i)) {
                return 1
            } else {
                return 0
            }
        } else {
            return 0
        }
    }
    submit = () => {
        let data = this.props.DynamicCategoryState.dynamicCategory.category
        let isValid = true;
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        if (data.length > 0) {
            var err = true
            for (let i = 0; i < data.length; i++) {
                if (data[i].name || data[i].email || data[i].phonenumber) {
                    err = false
                    break;
                }
            }
            if (err) {
                isValid = false;
                Error((languageData && languageData.FILL_THE_FORM ? languageData.FILL_THE_FORM : "Fill the from"))
                window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
            }
        }
        if (this.state.phoneIndex.length > 0) {
            isValid = false;
            Error((languageData && languageData.FILL_THE_REQUIED_FIELD ? languageData.FILL_THE_REQUIED_FIELD : "Fill the required field"))
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
        if (this.state.phoneIndex.length > 0) {
            isValid = false;
            Error((languageData && languageData.FILL_THE_REQUIED_FIELD ? languageData.FILL_THE_REQUIED_FIELD : "Fill the required field"))
            window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        }
        if (isValid) {
            this.setState({ saving: true })
            let fromData = {
                hotel: this.state.hotelData._id,
                hotelname: this.state.hotelData.name,
                category: data
            }
            axios.post(ImportedURL.API.addDynamicCategory, fromData)
                .then((res) => {
                    this.setState({ saving: false })
                    Success((languageData && languageData.DYNAMIC ? languageData.DYNAMIC : 'Dynamic ') + (languageData && languageData.CATEGORY ? languageData.CATEGORY : 'Category') + (languageData && languageData.CREATED_SUCCESSFULLY ? languageData.CREATED_SUCCESSFULLY : "created successfully"));
                    this.props.ListDynamicCategory(this.state.id);
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }).catch(({ response }) => {
                    this.setState({ saving: false })
                    if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 400) {
                        Error((languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request'))
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else {
                        Error(response.statusMessage)
                    }
                });
        }
    }
    render() {
        const { DynamicCategoryState, CountryState, AccountState } = this.props;
        const account = AccountState.account.phonecode;
        const languageData = AccountState.account.SelectedlanguageData
        const listCategoryData = DynamicCategoryState.dynamicCategory.category;
        const status = DynamicCategoryState.status;
        const spinner = DynamicCategoryState.spinner;
        // listCategoryData.sort((a, b) => a.categoryname.localeCompare(b.categoryname))
        const phoneCode = CountryState.listcountry;
        var year = new Date().getFullYear();
        var categoryName = [];
        return (
            <>
                <div>
                    <div className='add_dynaminc_category'>
                        <div className='top_bott_border'>
                            <Container>
                                <div className='img_text_top'>
                                    <div className='h_logo'>
                                        <img src={ImportedURL.FILEURL + this.state.hotelData.logo} onError={onErrorImage} />
                                    </div>
                                    <div className='barnd_name'>
                                        <h5>MyHotel AI</h5>
                                        <span>property maintenance system</span>
                                    </div>
                                    <div className='b_logo'>
                                        <img src='../assets/images/reviewslogo.png' />
                                    </div>
                                </div>
                            </Container>
                        </div>
                        <div className='h_address text-center'>
                            <p className='mb-1'>{this.state.hotelData.name}</p>
                            <p>{this.state.hotelData.address !== undefined ? this.state.hotelData.address + ', ' + (this.state.hotelData.city != undefined ? this.state.hotelData.city + ', ' : '') + (this.state.hotelData.state != undefined ? this.state.hotelData.state + ' ' : '') + (this.state.hotelData.postcode != undefined ? this.state.hotelData.postcode + ', ' : '') + (this.state.hotelData.country != undefined ? this.state.hotelData.country + '.' : '') : ''}</p>
                        </div>
                        {
                            status
                                ?
                                <>
                                    <Container>
                                        {(listCategoryData.length > 0 && listCategoryData != undefined) &&
                                            listCategoryData.map((data, index) => {
                                                let statusName = false
                                                let repeatedMaintance = categoryName.find(obj => { return obj == data._id })
                                                if (!repeatedMaintance) {
                                                    categoryName.push(data._id)
                                                    statusName = true
                                                }
                                                let ccData = '';
                                                if (phoneCode) {
                                                    phoneCode.map(item => {
                                                        if (item.code == data.cc) {
                                                            ccData = { label: item.phonecode, value: item.code, flag: item.flag }
                                                        }
                                                    })
                                                    if (!ccData) {
                                                        phoneCode.map(item => {
                                                            if (item.code == account) {
                                                                ccData = { label: item.phonecode, value: item.code, flag: item.flag }
                                                            }
                                                        })
                                                    }
                                                }
                                                return (
                                                    <div className='dynamic_row_category mb-4'>
                                                        <div>
                                                            {
                                                                statusName
                                                                &&
                                                                <h4><i class="fa fa-user mr-2" aria-hidden="true"></i>{data.categoryname}</h4>
                                                            }
                                                            <Row>
                                                                <div className='col-xl-3 col-lg-3 '>
                                                                    <div class="form-group">
                                                                        <input type="text" class="form-control" name="name" onChange={e => this.onChangeDynamic(index, e)} value={data.name} placeholder={CapitalizeFirstLetter(data.categoryname) + " Name"} />
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-3 col-lg-3 '>
                                                                    <div class="form-group">
                                                                        <input type="text" class="form-control" name="email" onChange={e => this.onChangeDynamic(index, e)} value={data.email} placeholder={CapitalizeFirstLetter(data.categoryname) + " Email"} />
                                                                        <div className="invalid-feedback" style={{ display: this.emailChange(index + 1) ? "block" : 'none' }}>Enter valid email</div>
                                                                    </div>
                                                                </div>
                                                                <div className='col-xl-4 col-lg-4 '>
                                                                    <Row>
                                                                        <div className="col-md-5 padi_right">
                                                                            <div className="form-group country_code">
                                                                                <Select
                                                                                    isDisabled={this.state.modalType == "View"}
                                                                                    value={ccData ? ccData : ''}
                                                                                    onChange={e => this.onChangeCC(index, e)}
                                                                                    options={phoneCode && phoneCode.filter(filterItem => filterItem.status === true).map(item => {
                                                                                        return {
                                                                                            label: item.phonecode,
                                                                                            flag: item.flag,
                                                                                            value: item.code,
                                                                                            name: 'cc'
                                                                                        }
                                                                                    })}
                                                                                    formatOptionLabel={(item) => {
                                                                                        return (
                                                                                            <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.label }} />
                                                                                        )
                                                                                    }}
                                                                                />
                                                                                <div className="invalid-feedback" style={{ display: this.state.ccError ? "block" : 'none' }}>Country Code is required</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-md-7 padd_lef">
                                                                            <div class="form-group">
                                                                                <input type="text" class="form-control" name="phonenumber" onChange={e => this.onChangeDynamic(index, e)} maxLength={10} value={data.phonenumber} placeholder={CapitalizeFirstLetter(data.categoryname) + " Phonenumber"} />
                                                                                <div class="invalid-feedback" style={{ display: 'none' }}>Owner Phonenumber is required</div>
                                                                                <div className="invalid-feedback" style={{ display: this.phoneChange(index + 1) ? "block" : 'none' }}>Phone number should contain 10 digits</div>
                                                                            </div>
                                                                        </div>
                                                                    </Row>
                                                                </div>
                                                                <div className='col-xl-2 col-lg-2 '>
                                                                    <div className='pluse_minus'>
                                                                        <button type='button' className='btn mr-4 dynamic-button' onClick={() => this.addForm(data, index)}><i class="fa fa-plus" aria-hidden="true" ></i></button>
                                                                        {
                                                                            (!statusName)
                                                                            &&
                                                                            <button type='button' className='btn' onClick={() => this.removeForm(index)}><i class="fa fa-minus" aria-hidden="true" ></i></button>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Row>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            )
                                        }
                                    </Container >
                                    <div className='d-flex justify-content-center'>
                                        {
                                            this.state.saving ?
                                                <button type="button" className="btn dy_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                :
                                                <button type="submit" className="btn dy_save" onClick={this.submit}>
                                                    <i className="fe fe-save mr-2"></i>Save
                                                </button>
                                        }
                                    </div>
                                </>
                                :
                                <div className='admin_false py-5'>
                                    <p className='cross_icon'>
                                        <img src='../assets/images/cross.png' />
                                    </p>
                                    <h2 className='mt-3 text-center'>
                                        {/* Conatct Your Admin */}
                                        Access denied
                                    </h2>
                                    <h5 className='text-center'>Conatct Your Admin</h5>

                                </div>
                        }

                        <div className='footer_div'>
                            <span>Copyright © {year} MyHotel AI - All Rights Reserved</span>
                        </div>
                    </div >
                </div >
                <div id="overlay" style={{ display: spinner ? 'block' : 'none' }}></div>
                {spinner &&
                    <div className="common_loader">
                        <img className='loader_img_style_common' src='../../assets/images/load.png' />
                        <Spinner className='spinner_load_common' animation="border" variant="info" >
                        </Spinner>
                    </div>
                }
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    HotelState: state.hotel,
    AccountState: state.account,
    GroupState: state.group,
    CountryState: state.country,
    CategoryState: state.category,
    DynamicCategoryState: state.dynamiccategory,
    CountryState: state.country,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ViewHotel: AC_VIEW_HOTEL, ListCountry: AC_LIST_COUNTRY_CATEGORY, ListDynamicCategory: AC_LIST_DYNAMIC_CATEGORY, HandleChangeDynamic: AC_HANDLE_DYNAMIC_CATEGORY_MULTIPLE, HandleUpadteForm: AC_HANDLE_DYNAMIC_CATEGORY_MULTIPLE_UPDATE, ViewDynamicCategory: AC_VIEW_DYNAMIC_CATEGORY, ListStatus: AC_LIST_DYNAMIC_CATEGORY_STATUS }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AddDynamicCategory);
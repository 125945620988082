import React, { Component } from 'react'
import 'react-loading-skeleton/dist/skeleton.css'
import ChartComponent from '../../utilities/Chart/chart';
import SpinnerComponent from '../../utilities/Spinner/spinner';

class BookingCountChart extends Component {
    render() {
        const { state, managementStatus } = this.props;
        const { options, series, spinner, secSpinner } = state;
        return (
            <>
                <div className="row clearfix">
                    <div className="col-12 col-md-12 col-xl-12">
                        <div className="card">
                            <div className="card-header">
                                <div className='rounded_icon'><i className="fa fa-pie-chart mr-2 "></i></div>
                                <h3 className="card-title">Click Ratio</h3>
                            </div>
                            <ChartComponent options={options} series={series} type="pie" width="800" height="500" />
                        </div>
                        <SpinnerComponent spinner={(managementStatus && (spinner || secSpinner))} overlay={false} />
                    </div>
                </div>
            </>
        )
    }
}

export default BookingCountChart;
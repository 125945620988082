import React, { Component } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AC_LIST_USER } from '../../actions/userAction';
import moment from 'moment';
import ImportedURL from '../../common/api';
import Spinner from 'react-bootstrap/Spinner';
import { onErrorImage } from '../../common/validate';

class ViewNotification extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewNotificationData: {},
            spinner: true,
        }
    }
    getRatings = (rating) => {
        let data = []
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) data.push(<i className="fa fa-star text-orange" key={i}></i>)
            else data.push(<i className="fa fa-star" key={i}></i>)
        }
        return data;
    }
    getDate = (date) => {
        const account = this.props.AccountState.account;
        var dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY');
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
        }
        if (!date) return '';
        const newDate = new Date(date * 1000);
        return moment(date).tz(tzone).format(dFormat);
    }
    UNSAFE_componentWillReceiveProps() {
        let id = this.props.match.params.id;
        axios.get(ImportedURL.API.getNotificationById + '/' + id)
            .then((res) => {
                if (res.data)
                    this.setState({ viewNotificationData: res.data, spinner: false })
            })
    }
    render() {
        const { HotelState } = this.props;
        const listHotels = HotelState.listhotels;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        let { viewNotificationData } = this.state;
        var hotelName = viewNotificationData.hotel;

        var reviewer_name = viewNotificationData.reviewer_name
        var title = viewNotificationData.title;
        var text = viewNotificationData.text;
        var reviewer_picture_url = viewNotificationData.reviewer_picture_url;
        var rating = viewNotificationData.rating;
        var published_at = viewNotificationData.published_at;
        var url = viewNotificationData.url;
        var supplier = viewNotificationData.supplier;
        var supplierValue = ""
        if (viewNotificationData.supplier == "hotels" || viewNotificationData.supplier == "booking") {
            supplierValue = viewNotificationData.supplier + ".com"
        } else if (viewNotificationData.supplier == "trip-advisor") {
            supplierValue = "tripadvisor"
        } else {
            supplierValue = viewNotificationData.supplier
        }
        var imageSet = ''
        if (viewNotificationData.supplier == "hotels") {
            imageSet = '../../assets/images/hotels.png'
        } else if (viewNotificationData.supplier == "trip-advisor") {
            imageSet = '../../assets/images/tripadviser.png'
        } else if (viewNotificationData.supplier == "booking") {
            imageSet = '../../assets/images/booking.png'
        } else if (viewNotificationData.supplier == "google") {
            imageSet = '../../assets/images/google.webp'
        } else if (viewNotificationData.supplier == "facebook") {
            imageSet = '../../assets/images/fb.svg'
        } else if (viewNotificationData.supplier == "yelp") {
            imageSet = '../../assets/images/yelp.png'
        } else if (viewNotificationData.supplier == "expedia") {
            imageSet = '../../assets/images/ex.png'
        }
        return (
            <div>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <Link to="/notifications"><h6> Notification List</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}> View Notification</h6></p>
                </div>
                <div className="section-body pt-3">
                    <div className="container-fluid">
                        <div className="card">
                            <div className="card-header" style={{ padding: '20px' }}>
                                <div className='rounded_icon'><i className="icon-bell mr-2 "></i></div>
                                <h3 className="card-title">View Notification</h3>
                                <div className="header-action" style={{ marginTop: '5px', marginLeft: 'auto' }}>
                                    <Link to='/notifications'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button></Link>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <article className="media">
                                    <div className="mr-3"><img className="avatar avatar-xl" src={reviewer_picture_url ?
                                        reviewer_picture_url : ImportedURL.LOCALURL + 'assets/images/defaultuser.png'}
                                        onError={(e) => { if (e.target.src !== ImportedURL.LOCALURL + "assets/images/defaultuser.png") { e.target.onerror = null; e.target.src = ImportedURL.LOCALURL + "assets/images/defaultuser.png"; } }
                                        }
                                        alt="" />
                                    </div>
                                    <div className="media-body">
                                        <div className="content">
                                            <p className="h5"><span className='pr-2'>{reviewer_name}</span>
                                                {this.getRatings(rating)}
                                                <small className="float-right text-muted">{this.getDate(published_at)}</small>
                                            </p>
                                            <div style={{ position: "relative" }}  >
                                                <span className='view_logo' style={{ position: "absolute", top: "0px" }}>
                                                    <a href={viewNotificationData.url} target='_blank'>
                                                        <img src={imageSet} onError={onErrorImage} />
                                                    </a>
                                                </span>
                                                <span style={{ paddingLeft: "40px" }}>  <a href={url} style={{ color: '#007bff' }} target='_blank'>{supplierValue?.charAt(0).toUpperCase() + supplierValue?.substr(1).toLowerCase()}</a></span>
                                                <small className="float-right text-muted">{hotelName}</small>
                                            </div>
                                            <p className='mt-3'><strong>{title}</strong></p>
                                            <p dangerouslySetInnerHTML={{ __html: text }}></p>
                                        </div>
                                    </div>
                                </article>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div>
                {this.state.spinner ?
                    <div className='common_loader'>
                        <img className='loader_img_style_common' src='../../assets/images/load.png' />
                        <Spinner className='spinner_load_common' animation="border" variant="info" >
                        </Spinner>
                    </div>
                    : ""}
            </div>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    HotelState: state.hotel,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ListUser: AC_LIST_USER }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewNotification);
import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_SETTING_ROOM_HANDLE_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "SETTING_ROOM_HANDLE_CHANGE", name: name, value: value })
	};
}

export function AC_SETTING_ROOM_HANDLE_FORM_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "SETTING_ROOM_HANDLE_FORM_CHANGE", name: name, value: value })
	};
}

export function AC_RESET_SETTING_ROOM() {
	return function (dispatch) {
		dispatch({ type: "RESET_SETTING_ROOM" })
	};
}


export function AC_LIST_SETTING_ROOM(params = {}) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.listSettingRoom, params)
			.then((res) => {
				dispatch({ type: "LIST_SETTING_ROOM", payload: res.data.data, total: res.data.total, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_LIST_SETTING_ROOM_ALL_DETAILS(params = {}) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.listSettingRoomAllDetails, params)
			.then((res) => {
				dispatch({ type: "LIST_SETTING_ROOM", payload: res.data, total: 0, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_VIEW_SETTING_ROOM(data) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewSettingRoom + "/" + data)
			.then((res) => {
				dispatch({ type: "VIEW_SETTING_ROOM", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_PASS_VIEW_SETTING_ROOM_DATA(data) {
	return function (dispatch) {
		dispatch({ type: "PASS_VIEW_SETTING_ROOM_DATA", payload: data })
	};
}


export function AC_VIEW_SETTING_ROOM_QRDATA(data) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewSettingRoomQrData + "/" + data)
			.then((res) => {
				dispatch({ type: "VIEW_SETTING_ROOM_QRDATA", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_HANDLE_ROOM_AMENITY_REMOVE(id) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_ROOM_AMENITY_REMOVE", id: id })
	};
}

export function AC_HANDLE_AMENITY_CLICK(item, i, status) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_AMENITY_CLICK", name: item, i: i, status: status })
	};
}
export function AC_HANDLE_MULTIROW_CLICK(name, value, i) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_MULTIROW_CLICK", name: name, value: value, i: i })
	};
}

export function AC_EMPTY_ROOM() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_ROOM" })
	}
}



export function AC_HANDLE_ROOM_UPDATE(name, value, id) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_ROOMBED_UPDATE", name: name, value: value, id: id })
	};
}

// --------------------------------checkbox
export function AC_ROOM_CHECKBOX_CHANGE(value) {
	return function (dispatch) {
		dispatch({ type: "ROOM_CHECKBOX_CHANGE", value: value });
	};
}

// ------------------------------------------------multiple
export function AC_HANDLE_ROOM_INPUT_CHANGE(name, value, index) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_ROOM_INPUT_CHANGE", name: name, value: value, index: index })
	};
}

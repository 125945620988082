
const initialState = {
	openCount: 0,
	assignedCount: 0,
	inprogressCount: 0,
	inreviewCount: 0,
	completedCount: 0,
	reopenedCount: 0,
	reassignedCount: 0,
	deletedCount: 0,
	highCount: 0,
	mediumCount: 0,
	lowCount: 0,
	openedticketcategory: {},
	closedticketcategory: {},
	userTickets: [],
	placeCount: [],
	socialreviewsCount: [],
	spinner: false,
	descriptionWiseTickets: {},
	descriptionByTickets: [],
	listfilter: [],
	spin: true,
	lengthofsentimentdata: 0,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'LIST_TICKET_COUNT':
			return Object.assign({}, state, {
				openCount: action.payload.open,
				assignedCount: action.payload.assigned,
				inprogressCount: action.payload.inprogress,
				inreviewCount: action.payload.inreview,
				completedCount: action.payload.completed,
				reopenedCount: action.payload.reopened,
				reassignedCount: action.payload.reassigned,
				deletedCount: action.payload.isDeleted,
				spinner: false
			})
		case 'LIST_TICKET_PRIORITY_COUNT':
			return Object.assign({}, state, {
				highCount: action.payload.highCount,
				mediumCount: action.payload.mediumCount,
				lowCount: action.payload.lowCount,
				// spinner: false
			})
		case 'LIST_CATEGORY_WISE_COUNT':
			if (action.status == 'open')
				return Object.assign({}, state, {
					openedticketcategory: action.payload,
					// spinner: false
				})
		// else
		// 	return Object.assign({}, state, {
		// 		closedticketcategory: action.payload,
		// 		// spinner: false
		// 	})
		case 'LIST_CATEGORY_WISE_COUNT_COMPLETED':
			return Object.assign({}, state, {
				closedticketcategory: action.payload,
				// spinner: false
			})
		case 'LIST_USER_TICKETS_COUNT':
			return Object.assign({}, state, {
				userTickets: action.payload,
				// spinner: false
			})
		case 'LIST_PLACE_TICKETS_COUNT':
			return Object.assign({}, state, {
				placeCount: action.payload,
				// spinner: false
			})
		case 'LIST_SOCIAL_REVIEWS_COUNT':
			return Object.assign({}, state, {
				socialreviewsCount: action.payload,
				spinner: false
			})
		case 'EMPTY_TICKET_COUNT':
			return Object.assign({}, state, {
				spinner: true,
				spin: true,
			})
		case 'DESCRIPTION_WISE_TICKET':
			return Object.assign({}, state, {
				descriptionWiseTickets: action.payload,
			})
		case 'DESCRIPTION_BY_TICKET': {
			let val = {
				descriptionByTickets: action.payload,
				listfilter: action.payload,
				spin: false
			}
			if (action.gettype == 'initial')
				val['lengthofsentimentdata'] = action.payload && action.payload.length > 0 ? [...new Map(action.payload.map(obj => [obj.id, obj])).values()].length : 0
			return Object.assign({}, state, val)
		}
		case 'HANDLE_TICKET_KEY_SEARCH':
			return Object.assign({}, state, {
				descriptionByTickets: action.value
			})
		default:
			return state;
	}
}
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom';
import ImportedURL from '../../../common/api';
import BigCalendar from './bigcalendar';
import { HiOutlineChevronRight, HiOutlineChevronLeft } from "react-icons/hi";
import moment from 'moment';
import GridList from './gridlist';
import { AC_EMPTY_TO_DO_LIST, AC_HANDLE_CHANGE_TODO_BACK_TAB, AC_LIST_PENDING_TO_DO_LIST, AC_LIST_TO_DO_LIST, AC_MODAL_SPINNER_TO_DO_LIST, AC_SPINNER_TO_DO_LIST, AC_VIEW_TO_DO_LIST } from '../../../actions/todolistAction';
import { AC_LIST_CONFIGOPTIONS_TODO_STATUS } from '../../../actions/configurationAction';
import TodoList from './todolist';
import ViewModal from './viewmodal';
import PendingTodo from './pendingtodo';
import axios from 'axios';
import { Error, Success } from '../../../common/swal';
import ErrorResponse from '../../../utilities/Error/errorstatus';
import Spinner from '../../../utilities/Spinner/spinner';

class Allmytask extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeView: 'grid',
            editStatus: false,
            id: '',
            calendarType: 'months',
            startdate: '',
            enddate: '',
            pagecount: 0,
            fullpagecount: 0,
            date: '',
            dashboard: true,
            checkliststatus: false
        }
        this.fullCalendarRef = React.createRef();
        this.childRef = React.createRef();
        this.childRefPending = React.createRef();
    }
    handleClick = (name) => {
        if (name == "pending") {
            this.childRefPending.current.childRefPending(this.state.hotelName);
        } else {
            this.childRef.current.childFunction(this.state.hotelName);
        }
    };
    componentDidMount() {
        const account = this.props.AccountState.account;
        this.setState({ hotelName: account.hotelName ? account.hotelName : '' });
        let date = moment(new Date()).format('MM-DD-YYYY hh:mm A')
        this.setState({ startdate: date })
        this.getDates(0, this.state.calendarType);
        this.props.PendingTodoList()
    }
    apiCall = (query = {}) => {
        this.props.spinnerTodo();
        this.props.ListTodoList({ type: this.state.activeView, ...query })
    }
    onClickAdd = () => {
        this.props.HandleChangeBackTab('/to-do-allmytask')
    }
    tabChanges = (type) => {
        this.setState({ activeView: type }, () => {
            this.getDates(0, this.state.calendarType);
        })

    }
    onChangeAction = (id, type, date = '') => {
        this.setState({ id: id, date: date });
        if (type == 'View') {
            this.setState({ checkliststatus: true })
            this.props.EmptyTodoList();
            this.props.spinnerModalTodo();
            this.props.ViewTodo(id);
        } else if (type == 'Edit') {
            this.props.spinnerTodo();
            this.onClickAdd();
            this.setState({ editStatus: true });
        }
    }
    viewTodo = (id, date = '') => {
        this.setState({ id: id, date: date });
        this.props.spinnerModalTodo();
        this.props.ViewTodo(id);
    }

    onChangePage = (type) => {
        let count = this.state.pagecount;
        if (type === 'next') {
            count++;
        } else if (type === 'previous') {
            count--;
        } else if (type === 'today') {
            count = 0;
        }
        this.setState({ pagecount: count });
        this.getDates(count, this.state.calendarType);
    };

    handleExportPDF = () => {
        const account = this.props.AccountState.account;
        const dFormat = (account.dateformate != '' ? account.dateformate : 'MM-DD-YYYY');
        const api = this.state.dashboard ? this.childRef.current?.gridApi : this.childRefPending.current?.gridApi;
        const currentPage = api.paginationGetCurrentPage(); // Get the current page number
        const pageSize = api.paginationGetPageSize(); // Get the page size (number of rows per page)
        const startRow = currentPage * pageSize; // Calculate the starting row index
        const endRow = (currentPage + 1) * pageSize; // Calculate the ending row index
        const filteredRows = api.getModel().rowsToDisplay.slice(startRow, endRow);
        const exportData = filteredRows.map(rowNode => rowNode.data);

        let pdfHead = ['#', "Title", 'Descriptiom', this.state.dashboard ? 'Start Date' : 'Work Date']
        const selected = exportData.map(item => {
            const selectedData = {};
            const selectedProperties = ['title', 'description', 'start']
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    if (property == "description") {
                        selectedData[property] = item[property] ? item[property] : '---';
                    } else if (property == "start") {
                        selectedData[property] = item[property] ? moment(item[property]).format(dFormat) : '---';
                    } else {
                        selectedData[property] = item[property] ? item[property] : '---';
                    }
                }
            });
            return selectedData;
        });
        let data = {
            title: this.state.hotelName + (this.state.dashboard ? " - All My Task" : " - Overdue"),
            head: pdfHead,
            body: selected,
            hotelNameExport: this.state.hotelName ? account.hotelNameExport : '',
            hoteladdressExport: this.state.hotelName ? account.hoteladdressExport : '',
            limit: 25
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = (this.state.dashboard ? "allmytask" : "overdue") + "_" + (this.state.hotelName).replace(/ /g, '_') + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }
    onChangeCalendar = (type) => {
        this.setState({ calendarType: type, pagecount: 0 }, () => {
            this.getDates(0, type);
        });
    };

    getDates = (count, calendarType) => {
        const { tzone } = this.props.AccountState.account;
        let currentDate = moment().tz(tzone);
        let name = calendarType ? calendarType.slice(0, -1) : '';
        let startOfDate = (this.state.activeView === 'datewise' && count === 0) ? currentDate : currentDate.clone().add(count, calendarType).startOf(name);
        let endOfDate = currentDate.clone().add(count, calendarType).endOf(name);
        let start = moment(startOfDate).format("YYYY-MM-DD");
        let end = moment(endOfDate).format("YYYY-MM-DD");
        this.setState({ startdate: start, enddate: end });
        this.apiCall({ startdate: start, enddate: end });
        this.getStartEndOfBigCalender(start, end, calendarType); // Pass calendarType
    };

    getStartEndOfBigCalender = (start, end, calendarType) => {
        let parsedDate;
        let displayDate = moment(start).format("MMMM YYYY");
        let data = calendarType === 'months' ? 'dayGridMonth' : calendarType === 'weeks' ? 'timeGridWeek' : calendarType === 'days' ? 'timeGridDay' : '';
        if (this.fullCalendarRef && this.fullCalendarRef.current) {
            this.fullCalendarRef.current.getApi().changeView(data);
        }
        if (calendarType === 'weeks' || calendarType === 'days') {
            if (this.fullCalendarRef && this.fullCalendarRef.current) {
                const startDate = moment(start).toDate();
                this.fullCalendarRef.current.getApi().gotoDate(startDate);
            }
        } else {
            parsedDate = moment(displayDate, 'MMM DD, YYYY').startOf('month').toDate();
            if (this.fullCalendarRef && this.fullCalendarRef.current) {
                this.fullCalendarRef.current.getApi().gotoDate(parsedDate);
            }
        }
    };
    triggerPendingApi = () => {
        this.props.PendingTodoList();
    }
    onChangeTab = () => {
        this.setState({ dashboard: !this.state.dashboard });
        // this.props.ListTodoStatus({ parentslug: 'todo-status' });
    }
    handleSubmitPending = (todostatus, id) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (todostatus) {
            axios.post(ImportedURL.API.updateTodoStatus + "/" + id, { todostatus: todostatus })
                .then((res) => {
                    Success((languageData && languageData.STATUS ? languageData.STATUS : 'Status') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : "updated successfully"))
                    this.props.PendingTodoList();
                    this.getDates(0, this.state.calendarType);
                }).catch((err) => {
                    ErrorResponse(err, languageData)
                });
        } else {
            Error("Select Todo Status")
        }
    }
    render() {
        if (this.state.editStatus) return <Redirect to={'edit-todo-list/' + this.state.id} />

        const { AccountState, TodoListState } = this.props;
        const { activeView } = this.state
        const account = AccountState.account;
        const languageData = AccountState.account.languageData
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Todo_List" });
        const rowData = TodoListState.todolist;
        const pendingRowData = TodoListState.pendinglist;
        const spinner = TodoListState.spinner;
        const modalspinner = TodoListState.modalspinner;
        const data = TodoListState.todo;
        let displayDate = moment(this.state.startdate).format("MMMM YYYY");
        if (this.state.calendarType == 'weeks') {
            let sameMonth = moment(this.state.startdate).format("MMMM") == moment(this.state.enddate).format("MMMM")
            displayDate = sameMonth ? (moment(this.state.startdate).format("MMM DD") + " - " + moment(this.state.enddate).format("DD, YYYY")) : (moment(this.state.startdate).format("MMM DD") + " - " + moment(this.state.enddate).format("MMM DD, YYYY"))
        }
        if (this.state.calendarType == 'days') {
            displayDate = (moment(this.state.startdate).format("MMM DD, YYYY"))
        }
        return (
            <div>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <h6 className="highlights_breadcrump">{this.state.dashboard ? languageData && languageData.ALL_MY_TASK ? languageData.ALL_MY_TASK : "All My Task" : languageData && languageData.OVERDUE ? languageData.OVERDUE : "Overdue"}</h6></p>
                    </div>
                    <div className="section-body pt-3 user_sec todo_all_task">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i class="icon-clock" aria-hidden="true"></i></div>
                                            <h3 className="card-title">{this.state.dashboard ? languageData && languageData.ALL_MY_TASK ? languageData.ALL_MY_TASK : "All My Task" : languageData && languageData.OVERDUE ? languageData.OVERDUE : "Overdue"}
                                                {
                                                    this.state.dashboard
                                                        ?
                                                        <div className="dropdown">
                                                            <a className="nav-link nav-dot" style={{ padding: "8.5px" }} id="navbarScrollingDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                                ...
                                                            </a>
                                                            <div className="dropdown-menu" aria-labelledby="navbarScrollingDropdown">
                                                                <div className="row m-2 exclude-hover">
                                                                    <ul className="list-inline d-flex threedot">
                                                                        <li className="list-inline-item" onClick={() => this.tabChanges('grid')}><i className="icon-clock" style={activeView == 'grid' ? { color: '#1C3078' } : { color: '#96b3d5' }}></i></li>
                                                                        <li className="list-inline-item" onClick={() => this.tabChanges('datewise')}><i className="fa fa-th-large" style={activeView == 'datewise' ? { color: '#1C3078' } : { color: '#96b3d5' }}></i></li>
                                                                        <li className="list-inline-item" onClick={() => this.tabChanges('calendar')}><i className="fa fa-calendar" style={activeView == 'calendar' ? { color: '#1C3078' } : { color: '#96b3d5' }}></i></li>
                                                                    </ul>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ""
                                                }
                                            </h3>
                                            <div className="card-options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <div className="header-action">
                                                        {
                                                            Previlege.add && !ImportedURL.SUPERADMIN.includes(account.role) ?
                                                                <Link to='/add-todo-list'>
                                                                    <button type="button" className="btn btn-primary" id='Add' onClick={this.onClickAdd}><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                                </Link>
                                                                : ''
                                                        }
                                                    </div>
                                                    {this.state.dashboard ?
                                                        <>
                                                            {
                                                                (rowData && rowData.length > 0 && activeView == 'grid') ?
                                                                    <div className="card-options">
                                                                        <a className="btn btn-primary btn-sm nav-link dropdown-toggle ticket_export" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                                        <div className="dropdown-menu">
                                                                            <a className="dropdown-item" type='button' onClick={() => this.handleClick(this.state.dashboard ? "" : "pending")}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                            <a className="dropdown-item" type='button' onClick={this.handleExportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                                        </div>
                                                                    </div>
                                                                    : ""
                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {
                                                                (pendingRowData && pendingRowData.length > 0 && activeView == 'grid') ?
                                                                    <div className="card-options">
                                                                        <a className="btn btn-primary btn-sm nav-link dropdown-toggle ticket_export" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                                        <div className="dropdown-menu">
                                                                            <a className="dropdown-item" type='button' onClick={() => this.handleClick(this.state.dashboard ? "" : "pending")}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                            <a className="dropdown-item" type='button' onClick={this.handleExportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                                        </div>
                                                                    </div>
                                                                    : ""
                                                            }
                                                        </>
                                                    }
                                                    <div className="card-options">
                                                        <a className="btn btn-primary btn-sm nav-link dropdown-toggle ticket_export" onClick={this.onChangeTab}><i class={this.state.dashboard ? "fa fa-list-alt mr-2" : "fa fa-tasks mr-2"} aria-hidden="true"></i>{this.state.dashboard ? languageData && languageData.OVERDUE ? languageData.OVERDUE : "Overdue" : languageData && languageData.ALL_MY_TASK ? languageData.ALL_MY_TASK : "All My Task"}</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            this.state.dashboard
                                                ?
                                                <div className="card-body">
                                                    <div className="calendar_filter">
                                                        <div className="selectgroup">
                                                            <label className="selectgroup-item">
                                                                <span className="selectgroup-button first_month" onClick={() => this.onChangePage('previous')} ><HiOutlineChevronLeft /></span>
                                                            </label>
                                                            <label className="selectgroup-item">
                                                                <span className="selectgroup-button" onClick={() => this.onChangePage('today')}>{"Today"}</span>
                                                            </label>
                                                            <label className="selectgroup-item">
                                                                <span className="selectgroup-button last_month" onClick={() => this.onChangePage('next')}><HiOutlineChevronRight /></span>
                                                            </label>
                                                        </div>
                                                        <div>
                                                            <span className='todo_center_name'>{displayDate}</span>
                                                        </div>
                                                        <div className="selectgroup">
                                                            <label className="selectgroup-item">
                                                                <span className={`${this.state.calendarType == 'months' ? 'active ' : ''} selectgroup-button first_month`} onClick={() => this.onChangeCalendar('months')}>{"Month"}</span>
                                                            </label>
                                                            <label className="selectgroup-item">
                                                                <span className={`${this.state.calendarType == 'weeks' ? 'active ' : ''} selectgroup-button`} onClick={() => this.onChangeCalendar('weeks')}>{"Week"}</span>
                                                            </label>
                                                            <label className="selectgroup-item">
                                                                <span className={`${this.state.calendarType == 'days' ? 'active ' : ''} selectgroup-button last_month`} onClick={() => this.onChangeCalendar('days')}>{"Day"}</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {activeView == 'grid' ?
                                                            <>
                                                                <TodoList {...this.props} rowData={rowData} onChangeAction={this.onChangeAction} ref={this.childRef} />
                                                            </>
                                                            : ''}
                                                        {activeView == 'datewise' ?
                                                            <>
                                                                <GridList  {...this.props} rowData={rowData} calendarType={this.state.calendarType} onChangeAction={this.onChangeAction} />
                                                            </>
                                                            : ''}
                                                        {activeView == 'calendar' ?
                                                            <>
                                                                <BigCalendar {...this.props} rowData={rowData} viewTodo={this.viewTodo} fullCalendarRef={this.fullCalendarRef} currentMonth={displayDate} calendarType={this.state.calendarType} />
                                                            </>
                                                            : ''}
                                                    </div>
                                                </div>
                                                :
                                                <>
                                                    <div className="card-body">
                                                        <PendingTodo {...this.props} rowData={pendingRowData} onChangeAction={this.onChangeAction} triggerPendingApi={this.triggerPendingApi} handleSubmitPending={(e, id) => this.handleSubmitPending(e, id)} ref={this.childRefPending} />
                                                    </div >
                                                </>
                                        }
                                    </div>
                                </div >
                            </div >
                        </div >
                    </div >
                    <Spinner spinner={spinner || this.state.spinner}></Spinner>
                </div >
                <ViewModal {...this.props} checkListStatus={!this.state.dashboard} data={data} spinner={modalspinner} startdate={this.state.date ? moment(this.state.date).format("MM-DD-YYYY") : ''} />
            </div >
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
    TodoListState: state.todolist,
    ConfigurationState: state.configuration,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleChangeBackTab: AC_HANDLE_CHANGE_TODO_BACK_TAB,
        ListTodoList: AC_LIST_TO_DO_LIST,
        ViewTodo: AC_VIEW_TO_DO_LIST,
        spinnerTodo: AC_SPINNER_TO_DO_LIST,
        spinnerModalTodo: AC_MODAL_SPINNER_TO_DO_LIST,
        PendingTodoList: AC_LIST_PENDING_TO_DO_LIST,
        EmptyTodoList: AC_EMPTY_TO_DO_LIST,
        ListTodoStatus: AC_LIST_CONFIGOPTIONS_TODO_STATUS,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(Allmytask);

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { Emailvalidate } from '../../common/validate';
import { Success, Error } from '../../common/swal';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { AC_HANDLE_INPUT_CHANGE } from '../../actions/accountAction';
import { AC_LIST_LANGUAGE } from '../../actions/languageAction';
import Select from 'react-select';

const initialState = {
    saving: false,

}


class livekeysettings extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;

    }
    handleKeyDown = e => {
        this.setState({ spinner: false })
        const { value } = e.target;
        const { AccountState } = this.props;
        const data = AccountState.account;



        if (e.key === 'Enter' && this.state.feedbackmailto !== '' && !this.state.feedmailError) {
            let newData = [...data.feedbackmailto];
            if (newData.includes(value)) {
                Error(' Already Exist')
            } else {
                newData.push(value);
                this.props.OnchangeKeySettings("feedbackmailto", newData);
                this.setState({ feedbackmailto: '', feedbackmailtoError: false });
            }
        }
    }
    removeEmail = (i) => {
        this.setState({ spinner: false })
        const { AccountState } = this.props;
        const data = AccountState.account.feedbackmailto;
        data.splice(i, 1);
        this.props.
            OnchangeKeySettings("feedbackmailto", data);

    }
    onChangeFeedBack = e => {
        const { name, value } = e.target;
        const Error = name + 'Error';
        this.setState({ [Error]: false });
        if (name == 'feedbackmailto') {
            if (value) {
                if (Emailvalidate(value)) {
                    this.setState({ feedmailError: false })
                } else {
                    this.setState({ feedmailError: true })
                }
                this.setState({ feedbackmailto: value });
            } else {
                this.setState({ feedbackmailto: value });
                this.setState({ [Error]: false });
            }
        } else {
            this.props.OnchangeKeySettings(name, value);
        }
    }
    submit = () => {
        const { AccountState, } = this.props;
        const data = AccountState.account;
        let valid = 1;
        // if (this.state.feedmailError) {
        //     this.setState({ feedbackmailtoError: false })
        //     valid = 0;
        // }
        if (!this.state.feedmailError) {
            if (data.feedbackmailto.length == 0) {
                this.setState({ feedbackmailtoError: true });
                valid = 0;
            }
        }
        if (this.state.feedmailError) {
            valid = 0;
        }
        if (!data.sendgridkey) {
            valid = 0;
            this.setState({ sendgridkeyError: true });
        }
        if (!data.twillioauthkey) {
            valid = 0;
            this.setState({ twillioauthkeyError: true });
        }
        if (!data.twilliotoken) {
            valid = 0;
            this.setState({ twilliotokenError: true });
        }
        if (!data.defaultlanguage) {
            valid = 0;
            this.setState({ defaultlanguageError: true });
        }

        var formdata = {
            feedbackmailto: data.feedbackmailto,
            sendgridkey: data.sendgridkey,
            twillioauthkey: data.twillioauthkey,
            twilliotoken: data.twilliotoken,
            defaultlanguage: data.defaultlanguage
        }


        if (valid) {
            this.setState({ saving: true })
            axios.post(ImportedURL.API.livekeysetting, formdata)
                .then((res) => {
                    this.setState({ saving: false })
                    Success('Updated successfully');
                    this.props.Emptylivekey();
                }).catch(({ response }) => {
                    this.setState({ saving: false })
                    if (response.status == 400) {
                        Error('Bad request')
                    }
                    else if (response.status == 500) {
                        Error(response.status + ' Internal Server Error')
                    } else if (response.status == 502) {
                        Error(response.status + ' Bad Gateway')
                    } else {
                        Error(response.statusMessage)
                    }
                });
        }
    }

    defaultLanguage = (e) => {
        const { name, value } = e;
        const Error = name + 'Error';
        this.setState({ [Error]: false });
        this.props.OnchangeKeySettings(name, value);
    }


    componentDidMount() {
        this.props.ListLanguage({ languagestatus: "Website" });
    }
    render() {
        const { AccountState, LanguageState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData;
        const data = AccountState.account;
        const listlanguage = LanguageState.listLanguage;
        const dataValue = AccountState.account



        var selectLanguage = '';
        if (dataValue.defaultlanguage !== undefined) {
            let status = listlanguage.find(e => e.name === dataValue.defaultlanguage)
            if (status) {
                selectLanguage = { label: `${status.name}`, value: status.name }
            }
        }


        return (
            <>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/site-setting"><h6 className="highlights_breadcrump">{languageData && languageData.LIVE ? languageData.LIVE : " Live"} {languageData && languageData.KEY ? languageData.KEY : " Key"} {languageData && languageData.SETTINGS ? languageData.SETTINGS : " Settings"}</h6></Link></p>
                </div>
                <div className="section-body pt-3">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="card">
                                    <div className="card-header">
                                        <div className='rounded_icon'><i className="fa fa-gear mr-2 "></i></div>
                                        <h3 className="card-title">{languageData && languageData.LIVE ? languageData.LIVE : " Live"} {languageData && languageData.KEY ? languageData.KEY : " Key"} {languageData && languageData.SETTINGS ? languageData.SETTINGS : " Settings"}</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="col-sm-6 col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">Feedback Mail To <span className="ml-1" style={{ color: 'red' }}> * <span style={{ color: 'black' }}>(</span>Type and press enter to add multiple mail<span style={{ color: 'black' }}>)</span></span></label>
                                                <input type='text' className="form-control" name="feedbackmailto" placeholder="Feedback Mail To" onKeyPress={this.handleKeyDown} onChange={this.onChangeFeedBack} value={this.state.feedbackmailto} />
                                                <div className="invalid-feedback" style={{ display: this.state.feedbackmailtoError ? "block" : 'none' }}>Feedback Mail is required</div>
                                                <div className="invalid-feedback" style={{ display: this.state.feedmailError ? "block" : 'none' }}>Enter Valid Email</div>
                                            </div>
                                            {
                                                data.feedbackmailto && data.feedbackmailto.length > 0
                                                    ?
                                                    <div className="col-sm-6 col-md-12">
                                                        <div className="form-group">
                                                            <div className="selectgroup selectgroup-pills">
                                                                {
                                                                    data.feedbackmailto && data.feedbackmailto.map((data, i) => {
                                                                        return <label className="selectgroup-item">
                                                                            <input type="checkbox" name="value" className="selectgroup-input" value="CSS" checked={true} disabled />
                                                                            <span className="selectgroup-button">{data} {this.state.modalType !== 'View' && <button type="button" className="close" style={{ outline: 'none' }} onClick={() => this.removeEmail(i)}></button>}</span>
                                                                        </label>
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                    : ''}
                                            <div className="form-group">
                                                <label className="form-label">Send Grid Key<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <input type='text' className="form-control" name="sendgridkey" placeholder="Send Grid Key" onChange={this.onChangeFeedBack} value={data.sendgridkey} />
                                                <div className="invalid-feedback" style={{ display: this.state.sendgridkeyError ? "block" : 'none' }}>Send Grid Key is required</div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Twillio Auth Key<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <textarea className="form-control" name="twillioauthkey" placeholder="Twillio Mail To" rows="3" cols="20" onChange={this.onChangeFeedBack} value={data.twillioauthkey} />
                                                <div className="invalid-feedback" style={{ display: this.state.twillioauthkeyError ? "block" : 'none' }}>Twillio Auth Key is required</div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Twillio Token<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <textarea className="form-control" name="twilliotoken" placeholder="Twillio Token" rows="3" cols="20" onChange={this.onChangeFeedBack} value={data.twilliotoken} />
                                                <div className="invalid-feedback" style={{ display: this.state.twilliotokenError ? "block" : 'none' }}>Twillio Token is required</div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Default Language<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <Select
                                                    value={selectLanguage ? { label: selectLanguage.label } : ''}
                                                    isDisabled={this.state.modalType == "View"}
                                                    onChange={this.defaultLanguage}
                                                    options={listlanguage && listlanguage.filter(filterItem => filterItem.status == true).map(item => {
                                                        return {
                                                            label: item.name, value: item.name, name: 'defaultlanguage',
                                                        }
                                                    })}
                                                />
                                                <div className="invalid-feedback" style={{ display: this.state.defaultlanguageError ? "block" : 'none' }}>Default language is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    < div className="card-footer text-right">
                                        {
                                            this.state.saving ?
                                                <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                :
                                                <button type="submit" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div >
                </div >
            </>

        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
    LanguageState: state.language,

})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        OnchangeKeySettings: AC_HANDLE_INPUT_CHANGE, ListLanguage: AC_LIST_LANGUAGE
    }, dispatch)
}


export default connect(mapStateToProps, mapDispatchToProps)(livekeysettings);

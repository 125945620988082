import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../../common/api';
import jsPDF from 'jspdf';
import Spinner from 'react-bootstrap/Spinner';
import { Error, Success } from '../../../common/swal';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { AddInaList, UpdateInaList, imageCellRendererList, onErrorImage } from '../../../common/validate';
import { AC_ADD_UPDATE_TODO_TAGS, AC_EMPTY_TODO_TAGS, AC_HANDLE_TODO_TAGS_CHANGE, AC_LIST_TODO_TAGS, AC_LIST_TODO_TAGS_SEND, AC_MODAL_SPINNER_TODO_TAGS, AC_SPINNER_TODO_TAGS, AC_VIEW_TODO_TAGS } from '../../../actions/todolistAction';
import { AC_LIST_USER } from '../../../actions/userAction';
import showSwalDialog from '../../../utilities/Modal/swal';

const initialState = {
    modalType: "Add",
    columnApi: '',
    gridApi: '',
    columnDefs: [
        {
            headerName: 'Logo', field: 'logo', sortable: false, hide: true, filter: false, "resizable": false, width: 100, cellStyle: { 'text-align': 'center' },
            cellRenderer: imageCellRendererList,
        },
        { headerName: 'Hotel', field: 'hotel', hide: true, width: 200 },
        { headerName: 'User Category', field: 'category', hide: true, width: 200, cellStyle: { 'text-align': 'center' }, floatingFilter: true, },
        { headerName: 'User Name', field: 'username', hide: true, width: 200, cellStyle: { 'text-align': 'center' }, floatingFilter: true, },
        { headerName: 'Tag', field: 'tag', width: 200, cellStyle: { 'text-align': 'center' }, floatingFilter: true, },
        {
            headerName: 'Status', width: 170, field: 'status', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true,
            cellRenderer: function (params) {
                if (params.data) {
                    if (params.data.status) {
                        return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                    } else if (!params.data.status) {
                        return '<span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span>';
                    }
                }
            }
        },
        {
            headerName: 'Actions', width: 150, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                if (params.data)
                    if (params.value) {
                        return params.value;
                    } else {
                        return "---"
                    }
            }
        },
    ],
    defaultColumDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
    },
    perPage: 25,
    searchhoteloption: { label: 'All', value: '', name: 'searchhotel' },
    taxlist: [],
    taxData: { type: 'inclusive', name: '', percentage: '', hotel: '', startdate: '', enddate: '' },
    nameError: false,
    percentageError: false,
    columnreset: true,
}
class TodoTag extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
        this.hotelInputRef = React.createRef();
        this.categoryInputRef = React.createRef();
        this.useridInputRef = React.createRef();
        this.tagInputRef = React.createRef();
    }
    componentDidMount() {
        const { AccountState } = this.props;
        this.setState({ hotelName: AccountState.account.hotelName })
        this.props.SpinnerTodoTag();
        this.props.ResetTodoTag();
        this.props.ListUser({ status: true });
    }
    changeModal = (e) => {
        this.setState({ modalType: "Add", hotelError: false, nameError: false, tagError: false, useridError: false, categoryError: false });
        this.props.ResetTodoTag();
        const { AccountState } = this.props;
        const account = AccountState.account;
        if (!ImportedURL.SUPERADMIN.includes(AccountState.role)) {
            this.props.HandleInputChange('hotel', account.hotel);
            this.props.HandleInputChange('userid', account.userId);
            this.props.HandleInputChange('category', account.categoryId);
        }
    }
    handleChange = e => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const { name, value, } = e.target;
        const ErrorName = name + "Error";
        this.setState({ [ErrorName]: false });
        if (value.length <= 50) {
            this.props.HandleInputChange(name, value);
        } else {
            Error(languageData && languageData.MAXIMUM_CHAR_LIMIT_REACHED ? languageData.MAXIMUM_CHAR_LIMIT_REACHED : "Maximum character limit reached")
        }
    }
    handleChangeSelect = e => {
        this.setState({ editSpi: false })
        const { name, value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.HandleInputChange(name, value);
        if (name == "hotel") {
            this.props.HandleInputChange("category", "");
            this.props.HandleInputChange("userid", "");
        }
        if (name == "category") {
            this.props.HandleInputChange("userid", "");
        }
    }
    onRowClicked = event => {
        const { TodoListState } = this.props
        const { listtodotags, total } = TodoListState;
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (value === 'Edit') {
            this.props.ModalSpinnerTodoTag();
            this.setState({ modalType: "Edit", id: event.data._id, hotelError: false, nameError: false, tagError: false, useridError: false, categoryError: false });
            this.props.ViewTodoTag(event.data._id);
        }
        if (value == 'Status') {
            if (!event.data.isDisable)
                showSwalDialog('Are you sure you want to change the status?', '', 'Ok', 'Cancel', 'assets/images/status.png').then((result) => {
                    if (result.isConfirmed) {
                        axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'todotags' })
                            .then((data) => {
                                const dataSource = this.getDataSource();
                                this.gridApi.setDatasource(dataSource);
                                Success((languageData && languageData.STATUS ? languageData.STATUS : "Status") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"))
                            }).catch(({ response }) => {
                                this.ErrorResponse(response)
                            });
                    }
                })
        }
    }
    submit = () => {
        const { TodoListState, AccountState } = this.props
        const data = TodoListState.todotags;
        const { listtodotags, total } = TodoListState;
        const account = AccountState.account;
        const languageData = AccountState.account.SelectedlanguageData;
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        }

        let valid = 1;
        if (!data.tag) {
            this.setState({ tagError: true });
            this.tagInputRef.current.focus();
            valid = 0;
        }
        if (ImportedURL.SUPERADMIN.includes(account.role)) {
            if (!data.userid) {
                this.setState({ useridError: true });
                this.useridInputRef.current.focus();
                valid = 0;
            }
            if (!data.category) {
                this.setState({ categoryError: true });
                this.categoryInputRef.current.focus();
                valid = 0;
            }
            if (!data.hotel) {
                this.setState({ hotelError: true });
                this.hotelInputRef.current.focus();
                valid = 0;
            }
        }
        if (valid) {
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addTodoTag, data)
                    .then((res) => {
                        this.setState({ saving: false })
                        const dataSource = this.getDataSource();
                        this.gridApi.setDatasource(dataSource);
                        Success((languageData && languageData.TODO_TAGS ? languageData.TODO_TAGS : "To-Do Tags") + (languageData && languageData.CREATED_SUCCESSFULLY ? languageData.CREATED_SUCCESSFULLY : " created Successfully"));
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.ErrorResponse(response)
                    });
            } else {
                axios.post(ImportedURL.API.updateTodoTag + '/' + this.state.id, data)
                    .then((res) => {
                        this.setState({ saving: false })
                        const dataSource = this.getDataSource();
                        this.gridApi.setDatasource(dataSource);
                        Success((languageData && languageData.TODO_TAGS ? languageData.TODO_TAGS : "To-Do Tags") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " updated Successfully"));
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.ErrorResponse(response)
                    });
            }
        }
    }
    ErrorResponse = (response) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData;
        this.setState({ saving: false })
        if (response)
            if (response.status == 500) {
                Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
            } else if (response.status == 400) {
                Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
            } else if (response.status == 502) {
                Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
            } else if (response.status == 409) {
                Error((languageData && languageData.TODO_TAGS ? languageData.TODO_TAGS : "To-Do Tags") + (languageData && languageData.ALREADY_EXIT ? languageData.ALREADY_EXIT : " Already exist"))
            } else {
                Error(response.status + " " + response.statusMessage)
            }
    }
    searchUser = e => {
        const { name, value, label } = e;
        const dataSource = this.getDataSource({ hotel: value })
        this.gridApi.setDatasource(dataSource);
        this.setState({ searchhoteloption: e, searchhotel: value, hotelName: label })
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };

    refreshGridDataSource = () => {
        this.gridApi.purgeInfiniteCache();
        const newDataSource = this.getDataSource();
        if (this.gridApi) {
            this.gridApi.setDatasource(newDataSource);
        }
        this.UpdateColumnDefs();
    };
    onPerPageChange = e => {
        const { value } = e;
        this.setState({ perPage: value }, () => {
            if (this.gridApi) {
                this.gridApi.paginationSetPageSize(parseInt(value, 10));
                this.gridApi.purgeInfiniteCache();
            }
        });
    };
    UpdateColumnDefs = () => {
        const role = this.props.AccountState.role;
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role)) {
            let updatedColumnDefs = [...this.state.columnDefs];
            updatedColumnDefs = updatedColumnDefs.map((col) => {
                if (col.field === 'hotel' || col.field === 'logo' || col.field === 'username' || col.field === 'category') {
                    return { ...col, hide: false };
                }
                return col;
            });
            this.gridApi.setColumnDefs(updatedColumnDefs);
        }
    }
    exportToCSV = () => {
        const account = this.props.AccountState.account;
        this.gridApi.exportDataAsCsv({
            columnKeys: ImportedURL.SUPERADMIN.includes(account.role) ? (this.state.hotelName && this.state.hotelName != "All") ? ['category', 'username', 'tag'] : ['hotel', 'category', 'username', 'tag'] : ['tag'],
            fileName: 'todotag' + "_" + (this.state.hotelName && (this.state.hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".csv",
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }
    exportPDF = () => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const account = this.props.AccountState.account;
        const exportData = this.props.TodoListState.listtodotags;
        const selectedProperties = (this.state.hotelName && this.state.hotelName != "All") ? (ImportedURL.SUPERADMIN.includes(account.role) ? ['category', 'username', 'tag'] : ['tag']) : ['hotel', 'category', 'username', 'tag'];
        const selected = exportData.map((item, i) => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    selectedData[property] = item[property] ? item[property] : '---';
                }
            });
            return selectedData;
        });
        let data = {
            title: ((this.state.hotelName && this.state.hotelName != "All") ? this.state.hotelName + ' - ' : '') + (languageData && languageData.TODO_TAGS ? (languageData.TODO_TAGS) : 'To-Do Tags') + (languageData && languageData.LIST ? languageData.LIST : " List"),
            head: (this.state.hotelName && this.state.hotelName != "All") ? (ImportedURL.SUPERADMIN.includes(account.role) ? ['#', 'User Category', "User Name", "Tag"] : ['#', 'Tag']) : ['#', 'Hotel', "User Category", "User Name", "Tag"],
            body: selected,
            hotelNameExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hotelNameExport : '',
            hoteladdressExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "todotag" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }
    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {
                const page = Math.floor(params.startRow / this.state.perPage);
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                this.props.SpinnerTodoTag();
                await this.props.ListTodoTag({ hotel: this.state.searchhotel, perPage: this.state.perPage, page: page, filter: filter, sort: sortModel.length ? sortModel[0] : {}, ...query });
                const rowData = this.props.TodoListState.listtodotags;
                const total = this.props.TodoListState.total;
                const { AccountState } = this.props;
                const account = AccountState.account;
                const previleges = AccountState.previleges;
                const Previlege = previleges.find(obj => { return obj.name == "Todo_Tag" });
                const editOption = Previlege?.edit ? '<button type="button" class="btn btn-icon" data-toggle="modal" data-target="#exampleModal" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-toggle="modal" data-action-type="Edit"></i></button>' : '';
                var dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY');
                if (rowData && rowData.length > 0) {
                    rowData.forEach(object => {
                        object.actions = editOption;
                        object.dFormat = dFormat;
                        object.account = account;
                    });
                }
                const lbLastRowOnPageEl = document.querySelector(`[ref=\'lbLastRowOnPage\']`);
                if (lbLastRowOnPageEl) {
                    lbLastRowOnPageEl.innerHTML = (rowData && rowData.length > 0) ? rowData.length + params.startRow : 0;
                }
                this.UpdateColumnDefs();
                params.successCallback(rowData, total);
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const { AccountState } = this.props;
        const role = AccountState.role;
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role) && this.state.columnreset) {
            this.UpdateColumnDefs();
            this.setState({ columnreset: false })
        }
        if (this.gridApi) {
            if (this.props.AccountState.previleges && prevState.gridApi !== this.state.gridApi) {
                const dataSource = this.getDataSource();
                this.gridApi.setDatasource(dataSource);
            }
        }
    }

    render() {
        const { AccountState, TodoListState, LandingState, UserState } = this.props;
        const listHotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 0 ? LandingState.landingdata.data[0] : [];
        const listCategory = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 0 ? LandingState.landingdata.data[7] : [];
        const listUser = UserState.listusers;
        const rowData = TodoListState.listtodotags;
        const data = TodoListState.todotags;
        const spinner = TodoListState.spinner;
        const modalspinner = TodoListState.modalspinner;
        const account = AccountState.account;
        const languageData = AccountState.account.SelectedlanguageData
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Todo_Tag" });
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]

        var selectedHotel = '';
        const hotelOptions = [];
        if (listHotels && listHotels.length > 0) {
            listHotels.filter(filterItem => filterItem.status === true).map((item) => {
                if (data.hotel == item._id) {
                    selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' }
                }
                hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
            })
        }
        var selectedCategory = '';
        const categoryOptions = [];
        if (listCategory && listCategory.length > 0) {
            listCategory.filter(filterItem => filterItem.status === true).map((item) => {
                if (data.category == item._id) {
                    selectedCategory = { label: `${item.name}`, value: item._id, name: 'category' }
                }
                let include = listUser.some((e) => (e.categoryid == item._id && data.hotel === e.hotelid));
                if (include && data.hotel)
                    categoryOptions.push({ label: `${item.name}`, value: item._id, name: 'category' });
            })
        }
        var selectedUser = '';
        const userOptions = [];
        if (listUser && listUser.length > 0) {
            listUser.filter(filterItem => filterItem.status === true).map((item) => {
                if (data.userid == item._id) {
                    selectedUser = { label: `${item.name}`, value: item._id, name: 'userid' }
                }
                if (selectedCategory && data.category === item.categoryid && data.hotel === item.hotelid) {
                    userOptions.push({ label: `${item.name}`, value: item._id, name: 'userid' });
                }
            })
        }

        var hotelOptionSearch = [];
        if (listHotels.filter(filterItem => filterItem.status === true).length > 1) {
            hotelOptionSearch.push({ label: 'All', value: '', name: 'searchhotel' })
        }
        if (listHotels.length > 0) {
            listHotels.filter(filterItem => filterItem.status === true).map(item => {
                hotelOptionSearch.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'searchhotel' });
            })
        }
        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/todo-tag"><h6 className="highlights_breadcrump">{languageData && languageData.TODO_TAGS ? languageData.TODO_TAGS : "To-Do Tags"}{languageData && languageData.LIST ? languageData.LIST : " List"}</h6></Link></p>
                        </div>
                        <div className="section-body pt-3 user_sec">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="fa fa-tags mr-2"></i></div>
                                                <h3 className="card-title">{languageData && languageData.TODO_TAGS ? languageData.TODO_TAGS : "To-Do Tags"}{languageData && languageData.LIST ? languageData.LIST : " List"}</h3>
                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="header-action" >
                                                            {Previlege?.add ? <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" id='Add' onClick={(e) => this.changeModal(e)}><i className="fe fe-plus mr-2" id='Add' />Add</button> : ''}
                                                        </div>
                                                    </div>
                                                    {rowData && rowData.length > 0 ?
                                                        <>
                                                            <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                            <div className="dropdown-menu">
                                                                <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                            </div>
                                                        </>
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                        <label className="form-label">Limit</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={this.onPerPageChange}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        ImportedURL.SUPERADMIN.includes(account.role) &&
                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-xl-6">
                                                            <label className="form-label">{languageData && languageData.SEARCH ? languageData.SEARCH : "Search"} {languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel"}</label>
                                                            <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                <Select
                                                                    value={this.state.searchhoteloption}
                                                                    onChange={this.searchUser}
                                                                    options={hotelOptionSearch}
                                                                    formatOptionLabel={(e) => (
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                            <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="table-responsive">
                                                    <div className={(rowData && rowData.length == 0) ? "ag-theme-alpine ag_grid_no_record_show" : "ag-theme-alpine"}>
                                                        <AgGridReact
                                                            key={this.state.perPage}
                                                            rowHeight={82}
                                                            paginationPageSize={this.state.perPage}
                                                            cacheBlockSize={this.state.perPage}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            onGridReady={this.onGridReady}
                                                            columnDefs={this.state.columnDefs}
                                                            rowDragManaged={true}
                                                            wrapText={true}
                                                            animateRows={true}
                                                            overlayNoRowsTemplate={"No rows to display"}
                                                            rowModelType={'infinite'}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                    {
                                                        (rowData && rowData.length == 0)
                                                        &&
                                                        <span className='ag_grid_no_record_show_span' >No rows to display</span>
                                                    }
                                                    {(spinner || this.state.spinner) ?
                                                        <div className='common_loader_ag_grid'>
                                                            <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                            </Spinner>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modal */}

                    <div className="modal fade country_model" id="exampleModal" autoComplete="off" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className='rounded_icon'><i className="fa fa-tags mr-2 "></i></div>
                                    <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} {(languageData && languageData.TODO_TAGS ? languageData.TODO_TAGS : "To-Do Tags")}</h5>
                                    <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                            <img src='../../assets/images/cancel.png' />
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        {ImportedURL.SUPERADMIN.includes(account.role) ?
                                            <>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Hotel<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            value={selectedHotel}
                                                            onChange={this.handleChangeSelect}
                                                            options={hotelOptions}
                                                            formatOptionLabel={(e) => (
                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                    {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                    <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                </div>
                                                            )}
                                                            isDisabled={this.state.modalType == "View"}
                                                            ref={this.hotelInputRef}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.hotelError ? "block" : 'none' }}>Hotel is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">User Category<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            ref={this.categoryInputRef}
                                                            value={selectedCategory}
                                                            onChange={this.handleChangeSelect}
                                                            options={categoryOptions}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.categoryError ? "block" : 'none' }}>User Category is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">User Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            ref={this.useridInputRef}
                                                            value={selectedUser}
                                                            onChange={this.handleChangeSelect}
                                                            options={userOptions}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.useridError ? "block" : 'none' }}>User Name is required</div>
                                                    </div>
                                                </div>
                                            </>
                                            : ''}
                                        <div className="col-sm-12 col-md-12">
                                            <div className="form-group">
                                                <label className="form-label" >Tag<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <input type="text" ref={this.tagInputRef} className="form-control" placeholder="Tag" value={data.tag ? data.tag : ''} name="tag" onChange={this.handleChange} />
                                                <div className="invalid-feedback" style={{ display: this.state.tagError ? "block" : 'none' }}>Tag is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-footer text-right mandatory">
                                    <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                    <div className="" >
                                        {
                                            this.state.saving ?
                                                <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>{this.state.modalType == "Edit" ? "Updating" : "Saving"}</button>
                                                :
                                                <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>{this.state.modalType == "Edit" ? "Update" : "Save"}</button>
                                        }
                                        <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                    </div>
                                </div>
                                <div id='overlay-model' style={{ display: modalspinner ? 'block' : 'none' }}></div>
                                {modalspinner ?
                                    <div className='model_loader_poss'>
                                        <img className='loader_img_style_model' src='../../assets/images/load.png' />
                                        <Spinner className='spinner_load_model' animation="border" variant="info" >
                                        </Spinner>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>


                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
    LandingState: state.landing,
    TodoListState: state.todolist,
    UserState: state.user,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ResetTodoTag: AC_EMPTY_TODO_TAGS,
        HandleInputChange: AC_HANDLE_TODO_TAGS_CHANGE,
        ListTodoTag: AC_LIST_TODO_TAGS,
        ListTodoTagSend: AC_LIST_TODO_TAGS_SEND,
        ViewTodoTag: AC_VIEW_TODO_TAGS,
        SpinnerTodoTag: AC_SPINNER_TODO_TAGS,
        ModalSpinnerTodoTag: AC_MODAL_SPINNER_TODO_TAGS,
        ListUser: AC_LIST_USER,
        AddUpdateTodotag: AC_ADD_UPDATE_TODO_TAGS,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TodoTag);
const roomData = {
	room: [],
	bedtype: '',
	floor: '',
	hotel: '',
	hk: '',
}
const initialState = {
	rooms: [],
	listrooms: [],
	listhousekeeper: [],
	spinner: true,
	viewRoom: {
		room: {}
	},
	allocation: {
		hotel: '',
		hk: '',
		allocationdate: '',
		rooms: [],
	},
	viewallocation: {
		hotel: '',
		hk: '',
		rooms: [],
	},
	listallocations: [],
	total: '',
	spinner: false,
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'HANDLE_ROOM_CHANGE':
			return Object.assign({}, state, {
				...state.roominfo,
				[action.name]: action.value
			})
		case 'HANDLE_ROOM_INPUT_CHANGE':
			return {
				...state,
				rooms:
					[
						...state.rooms.slice(0, action.index),
						{ ...state.rooms[action.index], [action.name]: action.value },
						...state.rooms.slice(action.index + 1)
					]
			}
		case 'HANDLE_ROOM_UPDATE_CHANGE':
			return Object.assign({}, state, {
				viewRoom: {
					...state.viewRoom,
					[action.name]: action.value
				}
			})
		case 'RESET_ROOM':
			return Object.assign({}, state, {
				rooms: [],
				listrooms: [],
				listhousekeeper: [],
				spinner: true,
				viewRoom: {
					room: {}
				},
				allocation: {
					hotel: '',
					hk: '',
					allocationdate: '',
					rooms: [],
				},
				viewallocation: {
					hotel: '',
					hk: '',
					rooms: [],
				},
				listallocations: [],
			})
		case 'LIST_ROOM':
			return Object.assign({}, state, {
				listrooms: action.payload,
				total: action.total,
				spinner: false
			})
		case 'UPDATE_ROOMLIST':
			return Object.assign({}, state, {
				listrooms: action.payload
			})
		case 'VIEW_ROOM':
			return Object.assign({}, state, {
				viewRoom: action.payload,
				spinner: action.spinner
			})
		case 'LIST_ROOMS_BY_HOTEL':
			return Object.assign({}, state, {
				listrooms: action.payload
			})
		case 'LIST_HK_BY_HOTEL':
			return Object.assign({}, state, {
				listhousekeeper: action.payload,
			})
		case 'HANDLE_ALLOCATION_CHANGE':
			if (action.valtype == 'edit') {
				return Object.assign({}, state, {
					viewallocation: {
						...state.viewallocation,
						[action.name]: action.value,
					}
				})
			} else
				return Object.assign({}, state, {
					allocation: {
						...state.allocation,
						[action.name]: action.value
					}
				})
		case 'LIST_ALLOCATION':
			return Object.assign({}, state, {
				listallocations: action.payload,
				spinner: action.spinner,
			})
		case 'VIEW_ALLOCATION':
			return Object.assign({}, state, {
				viewallocation: action.payload,
				spinner: action.spinner,
			})
		case 'RESET_ALLOCATION':
			return Object.assign({}, state, {
				allocation: initialState.allocation,
				rooms: [],
			})
		case 'EMPTY_ROOM':
			return Object.assign({}, state, {
				viewRoom: {
					room: {}
				},
				spinner: true
			})
		case 'EMPTY_VIEWROOM_ALLOCATION':
			return Object.assign({}, state, {
				viewallocation: {
					hotel: '',
					hk: '',
					rooms: [],
				},
				spinner: true
			})
		default:
			return state;
	}
}
import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import jsPDF from "jspdf";
import { AC_ADD_COUNTRY, AC_LIST_COUNTRY, AC_DELETE_COUNTRY, AC_VIEW_COUNTRY, AC_RESET_COUNTRY, AC_HANDLE_COUNTRY_CHANGE, AC_UPDATE_COUNTRY } from '../../actions/countryAction';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import { AC_LIST_CATEGORY } from '../../actions/categoryAction';
import { Emailvalidate } from '../../common/validate'
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import { Row } from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';
import { AC_HANDLE_CURRENCY_CHANGE, AC_LIST_CURRENCY, AC_RESET_CURRENCY, AC_VIEW_CURRENCY } from '../../actions/currencyAction';
import showSwalDialog from '../../utilities/Modal/swal';
import ErrorResponse from '../../utilities/Error/errorstatus';
const currencies = require('currencies.json');

const initialState = {
    modalType: "Add",
    columnDefs: [
        { headerName: '#', valueGetter: "node.rowIndex+1", width: 50, filter: false, cellStyle: { 'text-align': 'center' }, sort: { direction: 'asc', priority: 0 }, hide: 'true' },
        { headerName: 'Currency', field: 'name', width: 200, floatingFilter: true, sort: 'asc', sortIndex: 0 },
        { headerName: 'ISO Code', field: 'code', width: 150, floatingFilter: true, cellStyle: { 'text-align': 'center' } },
        // { headerName: 'Country Code', field: 'phonecode', width: 150,cellStyle: {'text-align': 'center'} },
        {
            headerName: 'Currency Symbol', field: 'symbol', width: 130, filter: false, cellStyle: { 'text-align': 'center' },
        },
        {
            headerName: 'Status', width: 85, field: 'status', filter: false, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header',
            cellRenderer: function (params) {
                if (params.data && params.data.status) {
                    return `<div class=""><span type="button" class="tag tag-green" data-action-type="Status">Active</span></div>`;
                } else if (params.value == 0) {
                    return '<div class=""><span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span></div>';
                }
            }
        },
        {
            headerName: 'Actions', width: 150, field: 'actions', headerClass: 'ag-center-header', cellStyle: { 'text-align': 'center' }, suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                if (params.value) {
                    return params.value;
                }
            }
        },
    ],
    viewFlag: false,
    nameError: false,
    codeError: false,
    phonecodeError: false,
    flagError: false,
    columnApi: '',
    gridApi: '',
    defaultColumDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 150,
        enableSorting: true,
        animateRows: true,
        sortingOrder: ['desc', 'asc']
    },
    // sortingOrder: ['asc', 'desc', null],
    hotelError: false,
    categoryError: false,
    id: '',
    cc: '+1',
    saving: false,
    editSpi: false,
    perPage: 25
}
class ListCurrency extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    changeModal = (e) => {
        this.setState({ modalType: e.target.id, existingowner: false, isowner: false, editSpi: false, nameError: false, });
        this.props.ResetCountry();
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        // if (value === 'View') {
        //     this.props.ResetCountry();
        //     this.setState({ modalType: "View", nameError: false, codeError: false, flagError: false, phonecodeError: false, isowner: false, phonenumberError: false });
        //     this.props.ViewCurrency(event.data._id);
        // }
        if (value === 'Edit') {
            this.props.ResetCountry();
            this.setState({ modalType: "Edit", nameError: false, id: event.data._id, codeError: false, flagError: false, phonecodeError: false, isowner: false, phonenumberError: false, editSpi: true });
            this.props.ViewCurrency(event.data._id);
        }
        if (value === 'Delete') {
            showSwalDialog('Are you sure you want to delete?', '', 'Ok', 'Cancel', 'assets/images/delete.png').then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeleteCurrency + '/' + event.data._id)
                        .then((res) => {
                            Success((languageData && languageData.CURRENCY ? languageData.CURRENCY : ' Currency') + (languageData && languageData.DELETED_SUCCESSFULLY ? languageData.DELETED_SUCCESSFULLY : ' deleted successfully'));
                            const dataSource = this.getDataSource();
                            this.gridApi.setDatasource(dataSource);
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
        if (value === 'Status') {
            showSwalDialog('Are you sure you want to change the status?', '', 'Ok', 'Cancel', 'assets/images/status.png').then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'currencies' })
                        .then((data) => {
                            const dataSource = this.getDataSource();
                            this.gridApi.setDatasource(dataSource);
                            Success((languageData && languageData.STATUS ? languageData.STATUS : ' Status') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : "updated successfully"))
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
    }
    handleChange = e => {
        const { code, name, symbol, symbolnative } = e;
        this.setState({ nameError: false });
        if (e) {
            this.props.HandleInputChange("code", code);
            this.props.HandleInputChange("name", name);
            this.props.HandleInputChange("symbol", symbol);
            this.props.HandleInputChange("symbolnative", symbolnative);
        }
    }
    submit = () => {
        const { CurrencyState } = this.props;
        const data = CurrencyState.ViewCurrency;
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        let valid = 1;
        if (!data.name) {
            this.setState({ nameError: true });
            valid = 0;
        }
        if (valid) {
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addCurrency, data)
                    .then((res) => {
                        this.setState({ saving: false })
                        Success((languageData && languageData.CURRENCY ? languageData.CURRENCY : ' Currency') + (languageData && languageData.CREATED_SUCCESSFULLY ? languageData.CREATED_SUCCESSFULLY : "created successfully"));
                        const dataSource = this.getDataSource();
                        this.gridApi.setDatasource(dataSource);
                        this.props.ResetCountry();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        let alreadyExistField = languageData && languageData.THE_CURRENCY_CODE ? languageData.THE_CURRENCY_CODE : 'This currency code'
                        ErrorResponse(response, languageData, '', alreadyExistField)
                    });
            } else {
                axios.post(ImportedURL.API.updateCurrency + '/' + this.state.id, data)
                    .then((res) => {
                        this.setState({ saving: false })
                        Success((languageData && languageData.CURRENCY ? languageData.CURRENCY : ' Currency') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : "updated successfully"));
                        const dataSource = this.getDataSource();
                        this.gridApi.setDatasource(dataSource);
                        this.props.ResetCountry();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        ErrorResponse(response, languageData)
                    });
            }
        }
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    exportToCSV = () => {
        const account = this.props.AccountState.account;
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: ['name', 'code', 'symbol'],
            fileName: 'currencies' + "_" + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }
    exportPDF = () => {
        const account = this.props.AccountState.account;
        const exportData = this.props.CurrencyState.ListCurrency;
        const selectedProperties = ['name', 'code', 'symbol'];
        const selected = exportData.map((item, i) => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    selectedData[property] = item[property] ? item[property] : '---';
                }
            });
            return selectedData;
        });
        let data = {
            title: "Currency List",
            head: ['#', 'Currency', 'ISO Code', 'Currency Symbol'],
            body: selected,
            hotelNameExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hotelNameExport : '',
            hoteladdressExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "currencies" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }

    // Data source to set data in ag grid
    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {

                // Grid params for filter and sort
                const page = Math.floor(params.startRow / this.state.perPage);
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                // Action call for inspection list
                await this.props.ListCurrency({ perPage: this.state.perPage, page: page, filter: filter, sort: sortModel.length ? sortModel[0] : {}, ...query });
                const rowData = this.props.CurrencyState.ListCurrency;
                const total = this.props.CurrencyState.total;
                const { AccountState } = this.props;

                const previleges = AccountState.previleges;
                const Previlege = previleges.find(obj => { return obj.name == "Currency" });
                const deleteOption = ImportedURL.SUPERADMIN.includes(AccountState.role) ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : '';
                const editOption = Previlege?.edit ? '<div class="userlist_view"><button title="Edit" type="button" class="btn btn-icon" data-action-type="Edit" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' + deleteOption + `</div>` : ''
                rowData.forEach(object => {
                    object.actions = editOption;
                });
                const lbLastRowOnPageEl = document.querySelector(`[ref=\'lbLastRowOnPage\']`);
                if (lbLastRowOnPageEl) {
                    lbLastRowOnPageEl.innerHTML = (rowData) ? rowData.length + params.startRow : 0;
                }
                params.successCallback(rowData, total);

            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.gridApi) {
            if (this.props.AccountState.previleges && prevState.gridApi !== this.state.gridApi) {
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
            }
        }
        if (prevState.perPage !== this.state.perPage) {
            this.refreshGridDataSource();
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
        this.refreshGridDataSource();
    };

    refreshGridDataSource = (params) => {
        const newDataSource = this.getDataSource(params);
        if (this.gridApi) {
            this.gridApi.setDatasource(newDataSource);
        }
    };

    onPerPageChange = (newPerPage) => {
        this.setState({ perPage: newPerPage })
        this.refreshGridDataSource({ perPage: newPerPage });
    };

    render() {
        const { fixNavbar, AccountState, CurrencyState } = this.props;
        const rowData = CurrencyState.ListCurrency;
        const data = CurrencyState.ViewCurrency;
        const spinner = CurrencyState.spinner;
        const languageData = AccountState.account.SelectedlanguageData
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Currency" });

        let selectedCurrency = '';
        if (currencies.currencies.length > 0) {
            selectedCurrency = currencies.currencies.find(e => e.code === data.code)
        }
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-currency"><h6 className="highlights_breadcrump"> {languageData && languageData.CURRENCIES ? languageData.CURRENCIES : "Currencies "}{languageData && languageData.LIST ? languageData.LIST : " List"} </h6></Link></p>
                        </div>
                        <div className="section-body pt-3 user_sec">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header tit_res">
                                                <div className='d-flex'>
                                                    <div className='rounded_icon'><i className="fa fa-dollar mr-2 "></i></div>
                                                    <div className='d-flex align-items-center'>
                                                        <h3 className="card-title">{languageData && languageData.CURRENCIES ? languageData.CURRENCIES : "Currencies "} {languageData && languageData.LIST ? languageData.LIST : " List"}</h3>
                                                    </div>
                                                </div>

                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="header-action">
                                                            {Previlege?.add ? <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" id='Add' onClick={(e) => this.changeModal(e)}><i className="fe fe-plus mr-2" id='Add' />Add</button> : ''}
                                                        </div>
                                                    </div>
                                                    {rowData.length > 0 ?
                                                        <>
                                                            <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                            <div className="dropdown-menu">
                                                                <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                            </div>
                                                        </>
                                                        : ""}
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                        <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={(e) => this.onPerPageChange(Number(e.value))}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <div className={(rowData && rowData.length == 0) ? "ag-theme-alpine ag_grid_no_record_show" : "ag-theme-alpine"}>
                                                        <AgGridReact
                                                            key={this.state.perPage}
                                                            rowHeight={82}
                                                            paginationPageSize={this.state.perPage}
                                                            cacheBlockSize={this.state.perPage}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            // rowData={rowData}
                                                            onGridReady={this.onGridReady}
                                                            columnDefs={this.state.columnDefs}
                                                            rowDragManaged={true}
                                                            wrapText={true}
                                                            animateRows={true}
                                                            overlayNoRowsTemplate={"No rows to display"}
                                                            rowModelType={'infinite'}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                    {
                                                        (rowData && rowData.length == 0)
                                                        &&
                                                        <span className='ag_grid_no_record_show_span' >No rows to display</span>
                                                    }
                                                    {/* <div id="overlay" style={{ display: spinner ? 'block' : 'none' }}></div> */}
                                                    {spinner ?
                                                        <div className='common_loader_ag_grid'>
                                                            <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                            </Spinner>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*==========ADD==========*/}
                    {/* {this.state.editSpi && Object.keys(data.name && data.code && data.phonecode && data.flag).length <= 0 ?
                            <div>
                                <img className='loader_img_style_dsh' src='../../assets/images/load.png' />
                                <Spinner className='spinner_load_dash' animation="border" variant="info" >
                                </Spinner>
                            </div>
                            : */}
                    <div className="modal fade country_model" id="exampleModal" autoComplete="off" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className='rounded_icon'><i className="fa fa-dollar mr-2 "></i></div>
                                    <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} {languageData && languageData.CURRENCY ? languageData.CURRENCY : "Currency"}</h5>
                                    <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                            <img src='../../assets/images/cancel.png' />
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row clearfix">
                                        <div className="col-xl-10 col-lg-10 col-md-10 col-sm-12 col-12 mb-2">
                                            <div className="form-group">
                                                <label className="form-label">Currency Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <Select
                                                    className="react-select"
                                                    styles={{ border: '1px solid #868686' }}
                                                    isDisabled={this.state.modalType == "View"}
                                                    value={selectedCurrency ? { label: selectedCurrency.name.toUpperCase(), value: selectedCurrency.code } : ''}
                                                    placeholder='Select ...'
                                                    onChange={this.handleChange}
                                                    options={currencies.currencies.length > 0 && currencies.currencies.map(item => {
                                                        return { label: `${item.name}`, code: item.code, value: item.code, name: item.name, symbol: item.symbol, symbolnative: item.symbolNative }
                                                    })}
                                                />
                                                <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Currency Name is required</div>
                                            </div>
                                        </div>
                                        {
                                            data.symbol &&
                                            <div className="col-xl-2 col-lg-2 col-md-2 col-sm-12 col-12">
                                                <div className="form-group" style={{ textAlign: 'center' }}>
                                                    <label className="form-label">Symbol</label>
                                                    <label style={{ fontSize: '21px', marginTop: '4px' }}>{data.symbol}</label>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                                {this.state.modalType == "Edit" ?
                                    <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="" >
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Update</button>
                                            }
                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                        </div>
                                    </div> : <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="" >
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                            }
                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                        </div>
                                    </div>}
                                <div id='overlay-model' style={{ display: this.state.editSpi && Object.keys(data.name).length <= 0 ? 'block' : 'none' }}></div>
                                {this.state.editSpi && Object.keys(data.name).length <= 0 ?
                                    <div className='model_loader_poss'>
                                        <img className='loader_img_style_model' src='../../assets/images/load.png' />
                                        <Spinner className='spinner_load_model' animation="border" variant="info" >
                                        </Spinner>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    CurrencyState: state.currency,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleInputChange: AC_HANDLE_CURRENCY_CHANGE,
        ListCurrency: AC_LIST_CURRENCY,
        ViewCurrency: AC_VIEW_CURRENCY,
        ResetCountry: AC_RESET_CURRENCY,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListCurrency);

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Chart from 'react-apexcharts';

class Barchart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                chart: {
                    id: "basic-bar",
                    events: {
                        mouseLeave: function (event, chartContext, config) {
                            chartContext.resetSeries()
                        }
                    }
                },
                fill: {
                    colors: ['#da7474', '#033f5a', '#ffa500']
                },
                xaxis: {
                    categories: [""]
                },
            },
            series: [
                {
                    name: "Priority",
                    data: [30, 40, 45]
                }
            ]
        };
    }
    componentDidMount() {

    }
    render() {
        const { TicketState } = this.props;
        const ticketStatusCount = TicketState;
        let isShow = true;
        if (ticketStatusCount.highCount == 0 && ticketStatusCount.mediumCount == 0 &&
            ticketStatusCount.lowCount == 0) {
            isShow = false;
        }
        const seriesArray = [
            {
                name: "High",
                color: "#da7474",
                data: [ticketStatusCount.highCount,]
            },
            {
                name: "Medium",
                color: "#033f5a",
                data: [ticketStatusCount.mediumCount,]
            },
            {
                name: "Low",
                color: "#ffa500",
                data: [ticketStatusCount.lowCount,]
            }
        ];
        return (
            <> {
                isShow ? <div className="col-xl-7 col-lg-7 col-md-7 ticket_reports" >
                    <div className="card">
                        <div className="card-body">
                            <div>
                                <Chart
                                    options={this.state.options}
                                    series={seriesArray}
                                    type="bar"
                                    width="100%"
                                    height="350"
                                />
                            </div>
                        </div>
                    </div>
                </div> :
                    <div className="col-xl-7 col-lg-7 col-md-7">
                        <div className="card">
                            <div className="card-body text-center d-flex align-items-center justify-content-center">
                                <div style={{ maxWidth: "340px" }}>
                                    <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid mb-4 mt-4" style={{ maxWidth: "272px" }} />
                                    <h5 className="mb-2">Tickets not yet added in this hotel</h5>
                                    <p className="text-muted">Once after successfull ticket creation you will get your report by chart</p>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            </>
        );
    }
}

const mapStateToProps = state => ({
    TicketState: state.ticket,
})
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Barchart);


import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_LANGUAGE_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_LANGUAGE_CHANGE", name: name, value: value })
	};
}

export function AC_RESET_LANGUAGE() {
	return function (dispatch) {
		dispatch({ type: "RESET_LANGUAGE" })
	};
}

export function AC_LIST_LANGUAGE(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listLanguage, { params: params })
			.then((res) => {
				dispatch({ type: "LIST_LANGUAGE", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}
export function AC_HANDLE_LANGUAGE_TABPOSITION_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_LANGUAGE_TABPOSITION_CHANGE", name: name, value: value })
	};
}

export function AC_VIEW_LANGUAGE(data) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewLanguage + "/" + data)
			.then((res) => {
				dispatch({ type: "VIEW_LANGUAGE", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_EMPTY_LANGUAGE() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_LANGUAGE" })
	}
}

export function AC_HANDLE_LANGUAGE(data) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_CHANGE", payload: data })
	}
}
import React, { Component } from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Papa from "papaparse";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import axios from 'axios';
import moment from 'moment';
import { Error, Success } from '../../../common/swal';
import ImportedURL from '../../../common/api';
import { AC_LIST_HOTEL, AC_NEW_LIST_HOTEL } from '../../../actions/hotelAction';
import { Emailvalidate, Phonenumber } from '../../../common/validate';
import { AC_LIST_COUNTRY } from '../../../actions/countryAction';
import { Link } from 'react-router-dom';
import { Modal, Row } from 'react-bootstrap';
import Swal from 'sweetalert2';
import { AiOutlineZoomIn } from "react-icons/ai";
import { AC_LIST_ROOM } from '../../../actions/roomAction';
import Spinner from 'react-bootstrap/Spinner';
import { CapitalizeFirstLetter } from '../../../common/validate';

export class Review extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hotelsList: [],
            selectedHotel: '',
            phone: '',
            name: '',
            roomno: '',
            email: '',
            cc: '',
            bulkfile: '',
            columns: '',
            nameError: false,
            phoneError: false,
            phoneValidError: false,
            roomnoError: false,
            defaultCC: '',
            initial: true,
            saving: false,
            emailError: false,
            spinner: false,
            hotelName: '',
            data: [],
            // State to store parsed data
            parsedData: '',
            //State to store table Column name
            tableRows: '',
            //State to store the values
            values: '',
            //model show 
            show: false,
            hotelOverAll: '',
            bulk: false,
            columnDefs: [
                {
                    headerName: 'Room', field: 'roomno', width: 100, floatingFilter: true,
                    valueGetter: function (params) {
                        if (params.data.roomno) {
                            let value = ''
                            if (params.data.roomData && params.data.roomData.length) {
                                params.data.roomData.map((item) => {
                                    if (item._id == params.data.roomno) {
                                        value = item.room
                                    }
                                })
                            }
                            if (value) {
                                return value
                            } else {
                                return params.data.roomno
                            }
                        } else {
                            return "---"
                        }
                    }
                },
                { headerName: 'Name', field: 'name', width: 200, floatingFilter: true },
                {
                    headerName: 'Phone No.', field: 'contact', width: 200, cellStyle: { 'text-align': 'center' }, floatingFilter: true,
                },
                {
                    headerName: 'Feedback', field: 'feedback_text', width: 450, floatingFilter: true,
                },
                {
                    headerName: 'Sent on', width: 200, field: 'date_text_sent', sortable: true, cellStyle: { 'text-align': 'center' }, filter: true,
                    valueGetter: function (params) {
                        if (params.data.date_text_sent) {
                            return moment(params.data.date_text_sent).format('YYYY-MM-DD')
                        } else {
                            return '---'
                        }
                    }
                },
            ],
            defaultColumDef: {
                "width": 200,
                "filter": true,
                "sortable": true,
                "resizable": true
            },
            messageData: [],
            fromDate: moment(new Date().setDate(new Date().getDate() - 3)).format('YYYY-MM-DD'),
            toDate: moment(new Date()).format('YYYY-MM-DD'),
        }
    }

    componentWillUpdate(nextProps) {
        const newaccount = nextProps.AccountState.account;
        const phonecode = nextProps.AccountState.account.phonecode;
        if (this.state.initial) {
            if (nextProps.AccountState.role !== 'admin') {
                this.setState({ selectedHotel: newaccount.hotel, hotelName: newaccount.hotelName })
            }
            if (phonecode) {
                this.setState({ cc: phonecode })
            }
            this.setState({ initial: false })
        }
    }

    handleClose = () => {
        this.setState({ show: false })
    }

    handleShow = () => {
        this.setState({ show: true })
    }

    handleChange = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        const Error = name + 'Error';
        const ValidError = name + 'ValidError';
        this.setState({ [Error]: false })
        if (name === 'name') {
            this.setState({ name: value });
            var guestName = value;
            if (guestName) {
                this.setState({ [Error]: false })
            } else {
                this.setState({ [Error]: false })
            }
        }
        else if (name === 'phone') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var s3 = s2.slice(0, 10);
            var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            this.setState({ phone: val });
            if (val) {
                this.setState({ [Error]: false });
                if (Phonenumber(val)) {
                    this.setState({ [ValidError]: false })
                } else {
                    this.setState({ [ValidError]: true })
                }
            } else {
                this.setState({ [Error]: false, [ValidError]: false })
            }
        }
        else if (name === 'email') {
            this.setState({ email: value });
            if (value) {
                if (Emailvalidate(value)) {
                    this.setState({ emailError: false })
                } else {
                    this.setState({ emailError: true })
                }
            } else {
                this.setState({ emailError: false })
            }
        }
        else {
            this.setState({ [name]: value })
        }
    };

    handleHotelChange = (e) => {
        this.setState({ selectedHotel: e.value, hotelName: e.label, hotelOverAll: e, roomno: '', hotellogo: ImportedURL.FILEURL + e.logo, bulkfile: '', hoteldata: e.data });
        if (this.state.bulk) {
            document.getElementById('fileSet').value = '';
        }
    };

    handleRoomChange = (e) => {
        const { name, value, roomno } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        if (name == 'roomno') {
            this.setState({ roomno: roomno })
        }
    }

    handleFileChange = (e) => {
        this.setState({ bulk: true })
        const hotelName = (this.state.hotelName).toLowerCase().replaceAll(' ', '_');
        e.stopPropagation();
        let superThis = this;
        superThis.setState({ show: true })
        // openFileDialog(e)

        // Check if user has entered the file
        if (e.target.files.length) {
            const inputFile = e.target.files[0];
            // if (hotelName == '') {
            //     Error("Selct a hotel");
            // }
            if (hotelName !== inputFile.name.toLowerCase().split('.')[0]) {
                document.getElementById('fileSet').value = '';
            }

            const allowedExtensions = ["csv"];

            // Check the file extensions, if it not
            // included in the allowed extensions
            // we show the error
            const fileExtension = inputFile?.type.split("/")[1];
            if (!allowedExtensions.includes(fileExtension)) {
                // Error("Input a csv file");
                return;
            }

            const reader = new FileReader();

            // Event listener on reader when the file
            // loads, we parse it and set the data.
            reader.onload = async ({ target }) => {
                const csv = Papa.parse(target.result, {
                    header: true,
                    skipEmptyLines: true,
                    complete: function (results) {
                        const rowsArray = [];
                        const valuesArray = [];
                        let csvData = results.data
                        // Iterating data to get column name and their values
                        for (let i = 0; i < csvData.length; i++) {
                            if (csvData[i] && csvData[i].phone) {
                                rowsArray.push(Object.keys(csvData[i]));
                                valuesArray.push(Object.values(csvData[i]));
                            }
                        }
                        // Parsed Data Response in array format
                        superThis.setState({ parsedData: results.data })
                        // Filtered Column Names
                        superThis.setState({ tableRows: rowsArray[0] });

                        // Filtered Values
                        superThis.setState({ values: valuesArray });
                    },
                });
            };
            reader.readAsText(inputFile);

            // If input type is correct set the state
            this.setState({ bulkfile: inputFile });
        }
    };

    sendReview = () => {
        let hotel = this.state.selectedHotel;
        const phoneCode = this.props.CountryState.listcountry;
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData

        if (!hotel) return Error((languageData && languageData.SELECT_A_HOTEL ? languageData.SELECT_A_HOTEL : "Select a hotel"));

        let number = this.state.phone;
        let roomno = this.state.roomno;
        let name = this.state.name;
        if (!roomno) this.setState({ roomnoError: true });
        if (!name) this.setState({ nameError: true });
        if (!number) this.setState({ phoneError: true });
        let ccValue = '';
        if (phoneCode) {
            phoneCode.map(item => {
                if (this.state.cc) {
                    if (item.phonecode == this.state.cc) {
                        ccValue = item.phonecode
                    }
                    if (item.code == this.state.cc) {
                        ccValue = item.phonecode
                    }
                }
            })
        }

        let formData = {
            hotel: hotel,
            phone: number,
            cc: ccValue,
            name: name,
            roomno: this.state.roomno,
            email: this.state.email,
            name: name,
            logo: this.state.hotellogo,
        }
        if (name && number && !this.state.phoneValidError && !this.state.emailError) {
            Swal.fire({
                title: 'Are you sure you are sending the message?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Send',
                imageUrl: '../../assets/images/invite.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title_surebulk',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({ saving: true, spinner: true })
                    axios.post(ImportedURL.API.sendGuestReview, formData)
                        .then((res) => {
                            this.setState({ saving: false })
                            Success((languageData && languageData.MESSAGE_SENT_SUCCESSFULLY ? languageData.MESSAGE_SENT_SUCCESSFULLY : "Message sent successfully"));
                            this.setState({ name: '', phone: '', roomno: '', email: '', spinner: false })
                        }).catch(({ response }) => {
                            this.setState({ saving: false, spinner: false })
                            if (response.status == 500) {
                                Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                            } else if (response.status == 502) {
                                Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                            } else if (response.status == 408) {
                                Error((languageData && languageData.CANNOT_ABLE_TO_SEND_MESSAGE_FOR_INDIAN_NUMBER ? languageData.CANNOT_ABLE_TO_SEND_MESSAGE_FOR_INDIAN_NUMBER : 'Cannot able to send message for indian number'))
                            } else {
                                if (response.data) Error(response.data);
                                else Error("Something went wrong!")
                            }
                        })
                }
            })
        }
    };

    SubmitForm = () => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        let hotel = this.state.selectedHotel;
        if (!hotel) return Error("Select a hotel");
        if (!this.state.bulkfile) return Error("Upload valid file");
        let bulkfileconfirmation = this.state.bulkfile?.name && this.state.bulkfile?.name.split('.')[0];
        let hotelName = (this.state.hotelName).replaceAll(' ', '_');
        if (bulkfileconfirmation.toLowerCase() == hotelName.toLowerCase()) {
            Swal.fire({
                title: 'Are you sure you are sending the message?',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Send',
                imageUrl: '../../assets/images/invite.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title_surebulk',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.setState({ spinner: true })
                    axios.post(ImportedURL.API.sendBulkGuestReview, { data: this.state.parsedData, hotel: hotel, logo: this.state.hotellogo })
                        .then((res) => {
                            this.props.ListHotel();

                            this.setState({ bulk: false })
                            Success((languageData && languageData.MESSAGE_SENT_SUCCESSFULLY ? languageData.MESSAGE_SENT_SUCCESSFULLY : "Message sent successfully"))
                            this.setState({ bulkfile: '', spinner: false });
                            document.getElementById('fileSet').value = '';
                            this.setState({ hotelOverAll: '', hotelName: '' })
                        }).catch(({ response }) => {
                            this.setState({ spinner: false });
                            if (response.status == 500) {
                                Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                            } else if (response.status == 502) {
                                Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                            } else if (response.status == 408) {
                                Error((languageData && languageData.CANNOT_ABLE_TO_SEND_MESSAGE_FOR_INDIAN_NUMBER ? languageData.CANNOT_ABLE_TO_SEND_MESSAGE_FOR_INDIAN_NUMBER : 'Cannot able to send message for indian number'))
                            } else {
                                if (response.data) Error(response.data);
                                else Error("Something went wrong!")
                            }
                        });
                }
            })
        } else {
            Swal.fire({
                title: 'Sorry, Could not send your bulk message because hotel name & filename did not match',
                showCancelButton: true,
                cancelButtonColor: '#d33',
                confirmButtonText: 'Ok',
                // imageUrl: 'assets/images/delete.png',
                customClass: {
                    popup: 'swal_pop',
                    title: 'swal_title_bulkmsg',
                    image: 'swal_image',
                    actions: 'swal_action',
                    confirmButton: 'swal_confirm',
                    cancelButton: 'swal_close',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                }
            })
        }
    }

    handlePhoneCode = e => {
        this.setState({ cc: e.value, defaultCC: '' })
    }

    copyHotelName = () => {
        navigator.clipboard.writeText((this.state.hotelName).toLowerCase().replaceAll(' ', '_'));
        Success('Copied')
        let btn = document.getElementById("closeModal");
        btn.click();
        this.setState({ show: false })
    }

    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }

    componentDidMount() {
        this.props.ListCountry();
        this.props.ListHotel();
        this.props.ListRoom();
        var phonecode = localStorage.getItem("phonecode");
        this.setState({ defaultCC: phonecode, hotelsList: this.props.HotelState.listhotels });
        // this.getmessageData(this.state.fromDate, this.state.toDate);
    }

    setDate = (e) => {
        const { name, value } = e.target;
        this.setState({ spinner: true })
        if (name == 'fromDate') {
            this.getmessageData(value, this.state.toDate)
        }
        if (name == 'toDate') {
            this.getmessageData(this.state.fromDate, value)
        }
        this.setState({
            [name]: value
        });
    }

    render() {
        const { HotelState, CountryState, AccountState, LandingState } = this.props;
        const account = AccountState.account;
        const listhotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[0] : [];
        // const listhotels = HotelState.listhotels;
        const phoneCode = CountryState.listcountry;
        const languageData = AccountState.account.SelectedlanguageData
        var selectedHotel = '';
        const hotelOptions = [];
        if (account?.allhotels?.length > 0) {
            account.allhotels.map((item) => {
                if (account.hotel == item.hotelid) {
                    selectedHotel = { label: `${item.name} ${item.city} ${item.state}`, value: item.hotelid, logo: item.logo, data: item }
                }
                hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item.hotelid, logo: item.logo, data: item });
            })
        } else {
            listhotels.filter(filterItem => filterItem.status === true && filterItem.tripsadvisorurl && filterItem.googlereviewurl && filterItem.guesttouchid).map((item) => {
                hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'hotel', data: item });
            })
        }

        let ccData = '';
        if (phoneCode) {
            phoneCode.map(item => {
                if (this.state.cc) {
                    if (item.phonecode == this.state.cc) {
                        ccData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                    if (item.code == this.state.cc) {
                        ccData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                }
                else {
                    if (item.phonecode === account.phonecode) {
                        ccData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                    if (item.code === account.phonecode) {
                        ccData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                }
            })
        }
        const bulkfileconfirmation = this.state.bulkfile?.name && this.state.bulkfile?.name.toLowerCase().split('.')[0];
        const formate = this.state.bulkfile?.name && this.state.bulkfile?.name.toLowerCase().split('.')[1];
        const hotelName = (this.state.hotelName).toLowerCase().replaceAll(' ', '_');

        const roomData = this.props.RoomState.listrooms;
        const RoomArray = [];
        var selectedRoom = "";

        if (roomData && roomData.length > 0) {
            roomData.filter(filterItem => filterItem.status === true).map((item, i) => {
                if (account?.allhotels?.length > 0) {
                    if (account?.hotel === item.hotelId) {
                        RoomArray.push(item)
                    }
                } else {
                    if (this.state?.selectedHotel === item.hotelId) {
                        RoomArray.push(item)
                    }
                }
                if (this.state.roomno) {
                    if (this.state.roomno == item.room) {
                        selectedRoom = { label: `${item.room}`, id: item._id }
                    }
                }
            })
        }

        RoomArray?.sort((a, b) => Number(a.room) - Number(b.room));
        return (
            <>
                <div className="section-body ">
                    <div className='review_sec'>
                        <div className="container-fluid pt-3">
                            <div className="breadcrump send-review-bread">
                                <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/guest-reviews"><h6 className="highlights_breadcrump">{languageData && languageData.GUEST_TOUCH ? languageData.GUEST_TOUCH : "Guest Touch"}</h6></Link></p>
                            </div>
                        </div>
                        <div className="container-fluid pt-3">
                            <div className="card mb-0">
                                <div className="card-header">
                                    <div className='rounded_icon'><i className="icon-star mr-2 "></i></div>
                                    <h3 className="card-title common_title_fs">{languageData && languageData.GUEST_TOUCH ? languageData.GUEST_TOUCH : "Guest Touch"}</h3>
                                </div>
                                {account.role == 'admin' ?
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="form-group" style={account.role == 'admin' ? {} : { fontSize: "18px" }}>
                                                    <label className="form-label" >{languageData && languageData.SEARCH ? languageData.SEARCH : "Search"} {languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel"}{account.role == 'admin' ? <span className="ml-1" style={{ color: 'red' }}>*</span> : ''}</label>
                                                    {
                                                        account.allhotels?.length > 0 ?
                                                            <>
                                                                <div className="text-center ribbon">
                                                                    <img className="rounded-circle img-thumbnail w100 h100 " src={ImportedURL.FILEURL + selectedHotel.logo} alt="fake_url" />
                                                                    <h6 className="mt-3 mb-0">{selectedHotel.label}</h6>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <Select
                                                                    value={this.state.hotelOverAll ? this.state.hotelOverAll : ''}
                                                                    placeholder="Select Hotel"
                                                                    name="hotel"
                                                                    options={hotelOptions}
                                                                    onChange={this.handleHotelChange}
                                                                    formatOptionLabel={(e) => (
                                                                        <div style={{ display: 'block', alignItems: 'center' }}>
                                                                            <img src={ImportedURL.FILEURL + e.logo} className='all_search_image_size contain_image' />
                                                                            <span className='all_search_text_size'>{e.label}</span>
                                                                        </div>
                                                                    )}
                                                                    isDisabled={this.state.modalType == "View"}
                                                                />
                                                                <span style={{ marginTop: '10px', display: 'inline-block' }}>Note : Select hotel to send a text message or bulk message</span>
                                                            </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                                {this.state.hotelName != '' || selectedHotel ?
                                    <div className='card-body' style={{ backgroundColor: '#fff' }}>
                                        <div className="d-flex justify-content-between align-items-center ">
                                            <ul className="nav nav-tabs page-header-tab bulk_tab">
                                                <li className="nav-item"><a className="nav-link active" id="user-tab" data-toggle="tab" href="#user-add">Bulk Message</a></li>
                                                <li className="nav-item"><a className="nav-link " id="user-tab" data-toggle="tab" href="#user-list">Text Message</a></li>
                                            </ul>
                                        </div>
                                        <div className="tab-content pt-3">
                                            <div className="tab-pane fade " id="user-list" role="tabpanel">
                                                <div className="col-lg-12 px-0">
                                                    <div className="card">
                                                        <div className="card-header px-0">
                                                            <div className='rounded_icon'><i className="fa fa-comments-o mr-2 "></i></div>
                                                            <h3 className="card-title common_title_fs">Text message</h3>
                                                        </div>
                                                        <div className="card-body  px-0">
                                                            <div className="row">
                                                                <div className="col-12">
                                                                    <div className="form-group">
                                                                        <label className="form-label" >Guest Room No.<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                        <Select
                                                                            value={selectedRoom}
                                                                            onChange={this.handleRoomChange}
                                                                            options={RoomArray && RoomArray.filter(filterItem => filterItem.status === true).map((item, i) => {
                                                                                return {

                                                                                    label: item.room, value: item._id, roomno: item.room, name: 'roomno',
                                                                                }
                                                                            })} />
                                                                        <div className="invalid-feedback" style={{ display: this.state.roomnoError ? "block" : 'none' }}> Guest Room is required </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label className="form-label" >Guest Email</label>
                                                                        <input type="email" className="form-control" placeholder="Guest Email" value={this.state.email} onChange={this.handleChange} name="email" />
                                                                        <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Enter valid guest email</div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-sm-12 col-md-12">
                                                                    <div className="form-group">
                                                                        <label className="form-label" >Guest Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                        <input type="text" className="form-control" placeholder="Guest Name" value={this.state.name} onChange={this.handleChange} name="name" />
                                                                        <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Guest Name is required</div>
                                                                    </div>
                                                                </div>
                                                                <Row>
                                                                    <label className="form-label" >Guest Mobile Number<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                    <div className="col-sm-4 col-md-2 pr-0">
                                                                        <div className="form-group country_code">
                                                                            <Select
                                                                                isDisabled={this.state.modalType == "View"}
                                                                                value={ccData ? ccData : ''}
                                                                                onChange={this.handlePhoneCode}
                                                                                options={phoneCode && phoneCode.filter(filterItem => filterItem.status === true).map(item => {
                                                                                    return {
                                                                                        label: item.phonecode,
                                                                                        flag: item.flag,
                                                                                        value: item.code,
                                                                                        name: 'cc'
                                                                                    }
                                                                                })}
                                                                                formatOptionLabel={(item) => {
                                                                                    return (
                                                                                        <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.label }} />
                                                                                    )
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-8 col-md-10 pl-0">
                                                                        <div className="form-group">
                                                                            <input type="text" className="form-control ml-1" placeholder="Guest Mobile Number" name="phone" value={this.state.phone} onChange={this.handleChange} />
                                                                            <div className="invalid-feedback" style={{ display: this.state.phoneError ? "block" : 'none' }}>Guest Mobile Number is required</div>
                                                                            <div className="invalid-feedback" style={{ display: this.state.phoneValidError ? "block" : 'none' }}>Guest Mobile Number should contain 10 digits</div>
                                                                        </div>
                                                                    </div>
                                                                </Row>
                                                            </div>
                                                            <div className="card-footer text-right" style={{ padding: "20px 0px" }}>
                                                                {this.state.name && <p style={{ margin: 'auto', width: '350px', textAlign: 'justify' }}>Hi {this.state.name}, Thank you for staying with us. Can you take 30 seconds to leave us your feedback? The link below makes it super easy.{ImportedURL.LOCALURL}</p>}
                                                                <button type="submit" className="btn btn-primary" onClick={this.sendReview}>
                                                                    <i className="fa fa-paper-plane-o mr-2" aria-hidden="true"></i>Send
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab-pane fade active show" id="user-add" role="tabpanel">

                                                <div className="col-lg-12">
                                                    <div className="card">
                                                        <div className="card-body px-0">
                                                            <div className='d-flex justify-content-between align-items-center pb-4 csv_main'>
                                                                <h3 className="card-title mb-0 common_title_fs">Bulk message</h3>
                                                                <div className="d-flex justify-content-between align-items-center">
                                                                    <ul className="nav nav-tabs page-header-tab">
                                                                    </ul>
                                                                    <div className="page-header" style={{ padding: "0px" }}>
                                                                        <div className="right">
                                                                            {hotelName != '' &&
                                                                                <ul className="nav nav-pills">
                                                                                    <li className="nav-item dropdown" style={{ background: '#be7b08', borderRadius: "8px" }}>
                                                                                        <a href="assets/review-sample.csv" download={hotelName + ".csv"} style={{ color: '#fff' }} className="nav-link" aria-haspopup="true"><i className='fa fa-download px-2'></i> Download Sample CSV</a>
                                                                                    </li>
                                                                                </ul>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-6 col-md-12">
                                                                    <div className="form-group">
                                                                        <label className="form-label">Upload CSV file<span className="ml-1" style={{ color: 'red' }}>*</span> <span class="form-label-small">{this.state.hoteldata && this.state.hoteldata.lastguesttouchreviewupdated ? `Last updated: ${moment(this.state.hoteldata.lastguesttouchreviewupdated).format('MM/DD/YYYY hh:mm A')}` : ''}</span></label>
                                                                        <input type="file" accept=".csv" className="form-control" id='fileSet' onChange={this.handleFileChange} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {this.state.bulkfile != '' ?
                                                                <div className='csv_log_setup' style={{ display: this.state.hotelName == '' ? 'none' : 'block' }}>
                                                                    <div className='img_icon' >
                                                                        <div className='img_size'>
                                                                            <img src='../assets/images/csv-viewer.png' alt='not found' />
                                                                        </div>
                                                                        <div className='csv_overlay' onClick={this.handleShow}></div>
                                                                        <span onClick={this.handleShow}><AiOutlineZoomIn className='zoom_i' /></span>
                                                                    </div>
                                                                </div>
                                                                : ''}

                                                            {/* --------------- csv data shhowin model --------------------- */}

                                                            <div className='csv_model'>
                                                                <Modal className={(bulkfileconfirmation == hotelName) ? 'info-modal' : ''} show={this.state.show} onHide={this.handleClose} size={(bulkfileconfirmation == hotelName) ? "lg" : ""} >
                                                                    {/* <div className="modal-content" style={(bulkfileconfirmation == hotelName) ? { height: '500px', overflow: 'auto' } : {}}> */}
                                                                    <Modal.Header closeButton>
                                                                        <Modal.Title>{formate === 'csv' ? "BULK MESSAGE PREVIEW" : "Invalid file format"}</Modal.Title>
                                                                    </Modal.Header>
                                                                    <Modal.Body style={(bulkfileconfirmation == hotelName) ? { height: '500px', overflow: 'auto' } : {}}>
                                                                        {(bulkfileconfirmation == hotelName) ?
                                                                            <>
                                                                                <div className='Over_all_table_style table_head_top'>
                                                                                    <table className='w-100 bulk_msg_table'>
                                                                                        <thead>
                                                                                            <tr>
                                                                                                {this.state.tableRows && this.state.tableRows.map((rows, index) => {
                                                                                                    return <th key={index} style={{ padding: '10px !important' }}>{rows}</th>;
                                                                                                })}
                                                                                            </tr>
                                                                                        </thead>
                                                                                        <tbody>
                                                                                            {this.state.values.length > 0 && this.state.values.map((value, index) => {
                                                                                                return (
                                                                                                    <tr key={index} className='px-2'>
                                                                                                        {value.map((val, i) => {
                                                                                                            return <td key={i} style={{ padding: '10px !important' }}>{val}</td>;
                                                                                                        })}
                                                                                                    </tr>
                                                                                                );
                                                                                            })}
                                                                                        </tbody>
                                                                                    </table>
                                                                                </div>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                {formate === 'csv' ?
                                                                                    <>
                                                                                        <div className='wrong_file_text'>
                                                                                            <p style={{ color: '#d9534f' }}>Upload file name should be same as the hotel name.
                                                                                            </p>
                                                                                            <p>Change file name as <span onClick={this.copyHotelName} id="closeModal" aria-label="Close">{hotelName} <i className="fa fa-clone ml-2" ></i></span></p>
                                                                                        </div>
                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        <div className='wrong_file_text'>
                                                                                            <p style={{ color: '#d9534f' }}>Invalid file formate.
                                                                                                Upload only (csv) file.
                                                                                            </p>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            </>
                                                                        }
                                                                    </Modal.Body>
                                                                    {/* </div> */}
                                                                </Modal>
                                                            </div>
                                                            <div className="card-footer text-right" style={{ padding: "20px 0px" }}>
                                                                {
                                                                    this.state.saving ?
                                                                        <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>sending</button>
                                                                        :
                                                                        <button type="submit" className="btn commor_save" onClick={this.SubmitForm}>
                                                                            <i className="fa fa-paper-plane-o mr-2" aria-hidden="true"></i> Send
                                                                        </button>
                                                                }

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
                <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div>
                {this.state.spinner &&
                    <div className="common_loader">
                        <img className='loader_img_style_common' src='../../assets/images/load.png' />
                        <Spinner className='spinner_load_common' animation="border" variant="info" >
                        </Spinner>
                    </div>
                }
            </>
        );
    }
}

const mapStateToProps = state => ({
    HotelState: state.hotel,
    CountryState: state.country,
    AccountState: state.account,
    ReviewState: state.review,
    RoomState: state.room,
    LandingState: state.landing,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListCountry: AC_LIST_COUNTRY, NewListHotel: AC_NEW_LIST_HOTEL, ListHotel: AC_LIST_HOTEL,
        ListRoom: AC_LIST_ROOM,
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Review);

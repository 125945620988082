import axios from 'axios';
import React, { Component } from 'react'
import { Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { AC_HANDLE_TODO_LIST_CHANGE, AC_HANDLE_TODO_LIST_ITEM_CHANGE, AC_HANDLE_TODO_LIST_ITEM_UPDATE, AC_VIEW_TODO_LIST_ITEM, AC_EMPTY_LIST_TODO_ITEM } from '../../actions/todolistAction';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import { AC_LIST_CATEGORY } from '../../actions/categoryAction';
import { onErrorImage } from '../../common/validate';
import showSwalDialog from '../../utilities/Modal/swal';


class AddTodoListItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: 1,
            itemvalue: [],
            duration: '',
            itemsError: [],
            durationError: false,
            hotelError: false,
            removeItem: -1,
            modalType: "Add",
            listRedirect: false,
            saving: false,
            initial: true,
            starttimeValidateError: false,
            endtimeValidateError: false,
            editSpi: false,
            allOption: true,
            item: { togglestatus: true }
        }
    }

    componentWillUpdate(nextProps) {
        const newaccount = nextProps.AccountState.account;
        if (this.state.initial) {
            if (!ImportedURL.SUPERADMIN.includes(nextProps.AccountState.role)) {
                this.props.handleChange('hotel', newaccount.hotel)
                this.setState({ owner: true });
            }
            this.setState({ initial: false })
        }
    }

    componentDidMount() {
        this.props.emptystate();
        this.props.ListCategory();
        const { params, path } = this.props.match;
        if (params.id) {
            this.props.viewtodolistitemdata(params.id, params.hotelid);
            this.setState({ modalType: path === "/view-to-do-list-item/:id" ? "View" : "Edit", id: params.id, editSpi: true })
        }
    }

    removeTodoitem = (id) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const { params } = this.props.match;
        showSwalDialog('Are you sure you want to delete?', '', 'Ok', 'Cancel', (ImportedURL.LOCALIMAGEURL + 'assets/images/delete.png')).then((result) => {
            if (result.isConfirmed) {
                axios.post(ImportedURL.API.softDeletetodolistitem + '/' + id, {})
                    .then((res) => {
                        Success((languageData && languageData.CHECK_LIST ? languageData.CHECK_LIST : "Check List listitem") + (languageData && languageData.DELETED_SUCCESSFULLY ? languageData.DELETED_SUCCESSFULLY : " Deleted Successfully"));
                        this.props.viewtodolistitemdata(params.id, params.hotelid);
                    }).catch(({ response }) => {
                        if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else if (response.status == 400) {
                            Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                        this.setState({ saving: false })
                    });
            }
        })
    }




    addFormFields = () => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        let temp = (this.state.item && this.state.item.itemvalue) ? { ...this.state.item, itemvalue: this.state.item.itemvalue.trim() } : { ...this.state.item }
        if (temp.itemvalue) {
            const { params, path } = this.props.match;
            // this.props.viewtodolistitemdata(params.id, params.hotelid);
            this.setState({ saving: true, })
            axios.post(ImportedURL.API.updatetodolistitem + '/' + params.id, { ...temp, hotel: params.hotelid })
                .then((res) => {
                    Success((languageData && languageData.CHECK_LIST ? languageData.CHECK_LIST : "Check List listitem") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"));
                    this.setState({ saving: false, item: { togglestatus: true, itemvalue: '' } })
                    this.props.viewtodolistitemdata(params.id, params.hotelid);
                }).catch(({ response }) => {
                    if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else if (response.status == 400) {
                        Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                    } else {
                        Error(response.statusMessage)
                    }
                    this.setState({ saving: false })
                });
        } else {
            this.setState({ itemvalueError: true, item: temp._id ? temp : { ...this.state.item, itemvalue: '' } })
        }
    }


    onChangeItem = (e) => {
        const { name, value } = e.target;
        const Error = name + "Error";
        // this.props.todolistitemChange("name", value, i);
        // let arr = this.state.itemsError ? this.state.itemsError.filter((e) => i !== e) : []
        this.setState({ item: { ...this.state.item, [name]: value }, [Error]: false })
    }

    onChangetoggle = (e) => {
        const { name, value, checked } = e.target;
        const Error = name + "Error";

        this.setState({ item: { ...this.state.item, [name]: checked }, [Error]: false })

    }
    onChangeStatus = (data, status) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const { params } = this.props.match;
        showSwalDialog('Are you sure you want to change the status?', '', 'Ok', 'Cancel', (ImportedURL.LOCALIMAGEURL + 'assets/images/status.png')).then((result) => {
            if (result.isConfirmed) {
                let item = { ...data, togglestatus: !status }
                axios.post(ImportedURL.API.updatetodolistitem + '/' + params.id, { ...item, hotel: params.hotelid })
                    .then((res) => {
                        Success((languageData && languageData.CHECK_LIST ? languageData.CHECK_LIST : "Check List listitem") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"));
                        this.setState({ saving: false, item: { togglestatus: true, itemvalue: '' } })
                        this.props.viewtodolistitemdata(params.id, params.hotelid);
                    }).catch(({ response }) => {
                        if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else if (response.status == 400) {
                            Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                        this.setState({ saving: false })
                    });
            }
        })
    }
    render() {
        const { AccountState, LandingState, TodolistitemState, HotelState, CategoryState } = this.props;
        if (this.state.listRedirect) return <Redirect to={'/to-do-list-item'} />
        const { ListCategoryFilter, ListUsernameFilter } = this.state;
        const languageData = AccountState.account.SelectedlanguageData
        const durationOption = [
            { value: 'Yearly', label: 'Yearly', name: 'duration' },
            { value: 'Half-Yearly', label: 'Half-Yearly', name: 'duration' },
            { value: 'Monthly', label: 'Monthly', name: 'duration' },
            { value: 'Bi-Weekly', label: 'Bi-Weekly', name: 'duration' },
            { value: 'Weekly', label: 'Weekly', name: 'duration' },
            { value: 'Daily', label: 'Daily', name: 'duration' },
            { value: 'Custom', label: 'Custom', name: 'duration' }
        ]
        const account = AccountState.account;
        const data = TodolistitemState.viewtodolistitem;
        const dataSpinner = TodolistitemState.spinner;
        const listHotelData = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[0] : [];

        var selectedHotel = '';
        const hotelOptions = [];
        if (account?.allhotels?.length > 0) {
            account.allhotels.map((item) => {
                if (account.hotel == item.hotelid) {
                    selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                }
                hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo });
            })
        } else {
            listHotelData.filter(e => e.status === true).map((item) => {
                if (data?.hotel == item._id) {
                    selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                }
                hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
            })
        }
        var itemArray = [];
        if (data.itemvalue && data.itemvalue.length > 0) {
            data.itemvalue.map((item, i) => {
                itemArray.push(

                )
            })
        }

        const categorySelect = []
        const categoryOption = []
        const listCategory = CategoryState.listcategories;
        if (listCategory && listCategory.length > 0) {
            if (this.state.allOption) categoryOption.push({ label: "All", value: 'All', name: 'category' })
            listCategory.filter((e) => e.status == true).map((temp) => {
                if (data.category == temp._id) {
                    categorySelect.push({ label: `${temp.name}`, value: temp._id, name: 'category' })
                }
                categoryOption.push({ label: `${temp.name}`, value: temp._id, name: 'category' })
            })
        }
        let item = this.state.item;
        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/to-do-list-item"><h6>{languageData && languageData.CHECK_LIST ? languageData.CHECK_LIST : "Check List"}</h6></Link><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>{this.state.modalType} {languageData && languageData.CHECK_LIST ? languageData.CHECK_LIST : "Check List"}</h6> </p>
                    </div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade show active"
                                    id="Departments-list"
                                    role="tabpanel"
                                >
                                    <div className="card">
                                        <div className="card-body border-0">
                                            <div className='title_mt'>
                                                <div className='d-flex align-items-center'>
                                                    <div className='rounded_icon'><i className="fa fa-calendar mr-2"></i></div>
                                                    <div className="d-flex align-items-center ">
                                                        <h3 className="card-title">{this.state.modalType} {languageData && languageData.CHECK_LIST ? languageData.CHECK_LIST : "TO-DO"} </h3>
                                                    </div>
                                                </div>
                                                <div className="header-action" style={{ marginTop: '5px' }}>
                                                    <Link to='/to-do-list-item'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="card-body">
                                            {ImportedURL.SUPERADMIN.includes(account.role) ?
                                                <div className="card c_grid c_yellow ">
                                                    <div className="card-body text-center view_hotel_card">
                                                        <Row>
                                                            <div className='col-xl-6 col-lg 6 col-md-12 col-sm-12 col-12'>
                                                                <div className='top_img_details'>
                                                                    <div className="img_size">
                                                                        <img src={ImportedURL.FILEURL + data.logo} alt="fake_url" onError={onErrorImage} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-xl-6 col-lg 6 col-md-12 col-sm-12 col-12'>
                                                                <div style={{ textAlign: '-webkit-center' }} className='hotel_details'>
                                                                    <h6 className='left_question mt-4 mb-1'>Hotel Name</h6>
                                                                    <p className='mb-1'>{data.hotelname}</p>
                                                                    <h6 className='left_question mt-4 mb-1'>User Category</h6>
                                                                    <p className='mb-1'>{data.categoryname}</p>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </div>
                                                : ''}
                                            {/* <div className="row">
                                                <div className="col-xl-6 col-lg 6 col-md-12 col-sm-12 col-12">
                                                    <div className="top_img_details">
                                                        <div className="img_size">
                                                            <img src="https://test-assets-myhotelai.s3.us-east-2.amazonaws.com/LocalData/ARK123/images/1706785679776banner-logo.png" alt="fake_url" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-xl-6 col-lg 6 col-md-12 col-sm-12 col-12">
                                                    <div className="hotel_details" style={{ textAlign: ' -webkit-center' }}>
                                                        <h6 className="left_question">Hotel</h6>
                                                        <p className="mb-1">{data.hotelname}</p>
                                                        <h6 className="left_question">Category</h6>
                                                        <p className="mb-1">{data.categoryname}</p>
                                                    </div>
                                                </div>
                                            </div> */}
                                            <form id='form_reset'>
                                                <Row>
                                                    <div className="col-sm-12 col-md-12">
                                                        <div className='row'>
                                                            {!ImportedURL.SUPERADMIN.includes(account.role) ? <div className="colxl-2 col-lg-2 col-md-2 col-sm-2 col-2 border-right">
                                                                <label className="label_black mb-2">User Category</label>
                                                                <p>{data.categoryname}</p>
                                                            </div> : ''}
                                                            <div className={ImportedURL.SUPERADMIN.includes(account.role) ? "col-sm-8" : "col-sm-5"}>
                                                                <div className="form-group">
                                                                    <label className="form-label">Item<span className='ml-1' style={{ color: 'red' }}>*</span></label>
                                                                    <div className=''>
                                                                        <p>
                                                                            <input type="text" className='form-control' name="itemvalue" value={item.itemvalue} onChange={this.onChangeItem} placeholder={"Item"} />
                                                                            <div className="invalid-feedback" style={{ display: this.state.itemvalueError ? 'block' : 'none' }}>Item is required</div>
                                                                        </p>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <label className="custom-control custom-switch">
                                                                    <input type="checkbox" className="custom-control-input" name="togglestatus"
                                                                        checked={item.togglestatus}
                                                                        onChange={this.onChangetoggle}
                                                                    />
                                                                    <span className="custom-control-label" style={{ cursor: 'pointer', marginLeft: '70px', marginTop: "32px" }}>Status</span>
                                                                </label>
                                                            </div>
                                                            <div className="col-sm-2">
                                                                <div className='todo_increment d-flex' style={{ marginLeft: "20px" }}>
                                                                    <span className='plus_minus'><i className={item._id ? "fa fa-check-circle" : "fa fa-plus-circle"} aria-hidden="true" onClick={() => this.addFormFields()}></i></span>
                                                                </div>
                                                            </div>

                                                        </div >
                                                    </div>
                                                    <div className="col-sm-12 col-md-12">
                                                        <div className='Over_all_table_style table-responsive edit_user_table_Custom to_do_list_items_edit'>
                                                            <table className="table table-striped mb-4">
                                                                <thead className='table_head'>
                                                                    <tr>
                                                                        <th style={{ width: "450px" }}>Item</th>
                                                                        <th style={{ width: "100px" }}>Status</th>
                                                                        <th style={{ width: "100px" }}>Action</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {data.itemvalue.map((item) => (
                                                                        <tr key={item._id}>
                                                                            <td className="text-muted" style={{ width: "530px" }}>{item.itemvalue}</td>
                                                                            <td style={{ width: "100px" }}>
                                                                                <span style={{ cursor: "pointer" }} className={item.togglestatus ? "tag tag-green" : "tag tag-danger"} onClick={() => this.onChangeStatus(item, item.togglestatus)}>
                                                                                    {item.togglestatus ? "Active" : "Inactive"}
                                                                                </span>
                                                                            </td>
                                                                            <td>
                                                                                <button type="button" className="btn btn-icon" onClick={() => { this.setState({ item: item, itemvalueError: false }) }} >
                                                                                    <i className="fa fa-edit" data-action-type="View"></i>
                                                                                </button>
                                                                                {/* <button type="button" className="btn btn-icon" onClick={() => this.removeTodoitem(item._id)}>
                                                                                    <i className="fa fa-trash-o text-danger" data-action-type="Delete"></i>
                                                                                </button> */}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </form>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="overlay" style={{ display: this.state.editSpi ? (dataSpinner ? 'block' : 'none') : 'none' }}></div>
                    {
                        (this.state.modalType != "Add") && this.state.editSpi && (dataSpinner &&
                            <div className='common_loader'>
                                <img className='loader_img_style_common' src='../../assets/images/load.png' />
                                <Spinner className='spinner_load_common' animation="border" variant="info" >
                                </Spinner>
                            </div>
                        )
                    }
                </div >

            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    TodolistitemState: state.todolistitem,
    AccountState: state.account,
    HotelState: state.hotel,
    CategoryState: state.category,
    LandingState: state.landing,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        todolistitemChange: AC_HANDLE_TODO_LIST_ITEM_CHANGE,
        todolistitemChangeUpdate: AC_HANDLE_TODO_LIST_ITEM_UPDATE,
        viewtodolistitemdata: AC_VIEW_TODO_LIST_ITEM,
        handleChange: AC_HANDLE_TODO_LIST_CHANGE,
        emptystate: AC_EMPTY_LIST_TODO_ITEM,
        ListCategory: AC_LIST_CATEGORY,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddTodoListItem);
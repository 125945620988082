
const initialState = {
	PhoneNumberBlockList: {
		phonenumber: '',
		cc: '',
	},
	listPhoneNumberBlockList: [],

	total: 0,

}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'HANDLE_PHONE_NUMBER_BLOCK_LIST_CHANGE':
			return Object.assign({}, state, {
				PhoneNumberBlockList: {
					...state.PhoneNumberBlockList,
					[action.name]: action.value
				}
			})
		case 'RESET_PHONE_NUMBER_BLOCK_LIST':
			return Object.assign({}, state, {
				PhoneNumberBlockList: initialState.PhoneNumberBlockList,

			})
		case 'AC_EMPTY_PHONE_NUMBER_BLOCK_LIST':
			return Object.assign({}, state, {
				spinner: true,
			})
		case 'LIST_PHONE_NUMBER_BLOCK_LIST':
			return Object.assign({}, state, {
				listPhoneNumberBlockList: action.payload,
				total: action.total,
				spinner: false
			})
		case 'VIEW_PHONE_NUMBER_BLOCK_LIST':
			return Object.assign({}, state, {
				PhoneNumberBlockList: action.payload
			})
		default:
			return state;
	}
}
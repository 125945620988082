import React, { Component } from 'react';
import { connect } from 'react-redux';
import './App.css';
import './assets/css/mediaquery.css'
import Layout from './components/Shared/Layout';
import Login from './components/Authentication/login';
import SignUp from './components/Authentication/signup';
import ForgotPassword from './components/Authentication/forgotpassword';
import NotFound from './components/Authentication/404';
import ReviewOpt from './components/Reviews/reviewoption';
import ReviewSuccess from './components/Reviews/reviewprocess';
import ReviewFailure from './components/Reviews/feedback';
import InternalServer from './components/Authentication/500';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AC_ACCOUNT_DETAILS, AC_GET_STATUS } from './actions/accountAction';
import { AC_GET_NOTIFICATION } from './actions/notificationAction';
import { AC_LIST_HOTEL } from './actions/hotelAction';
import Thanks from './components/Reviews/thanks';
import { AC_LIST_COUNTRY } from './actions/countryAction';
import { Spinner } from 'react-bootstrap';
import { AC_GET_HISTORY_DATA, AC_GET_LANDING_DATA, AC_GET_LANGUAGE_DATA } from './actions/landing';
import adddynamiccategory from './components/Employee/adddynamiccategory';

const token = localStorage.getItem("acwtoken");
const role = localStorage.getItem("role");
const lastDateToFetch = localStorage.getItem("lastDateToFetch");

class App extends Component {

	componentDidMount() {
		if (token) {
			this.props.AccountDetails();
			this.props.GetNotification({ perPage: 25 });
			this.props.landingData();
			this.props.getLanguageData();
		}
		if (token && role == 'admin') {
			this.props.historyData(lastDateToFetch);
		}
	}

	render() {
		const { darkMode, boxLayout, darkSidebar, iconColor, gradientColor, rtl, fontType, account } = this.props
		return (
			<div className={`${darkMode ? "dark-mode" : ""}${darkSidebar ? "" : "sidebar_dark"} ${iconColor ? "iconcolor" : ""} ${gradientColor ? "gradient" : ""} ${rtl ? "rtl" : ""} ${fontType ? fontType : ""}${boxLayout ? "boxlayout" : ""}`}>
				<Router>
					{token ?
						account.account.email ?
							<Switch>
								<Route path="/signup" component={SignUp} />
								<Route path="/review/:id" component={ReviewOpt} />
								<Route path="/review-success" component={ReviewSuccess} />
								<Route path="/review-failure/:id" component={ReviewFailure} />
								<Route path="/login" component={Login} />
								<Route path="/forgotpassword" component={ForgotPassword} />
								<Route path="/notfound" component={NotFound} />
								<Route path="/internalserver" component={InternalServer} />
								<Route path="/thanks" component={Thanks} />
								<Route path="/add-dynamic-category/:id" component={adddynamiccategory} />
								<Route path="/" component={Layout} />
							</Switch> :
							<><div className='common_loader' style={{ position: 'fixed', top: '50%', textAlign: 'center' }}>
								<img className='loader_img_style_common' src='../../assets/images/load.png' />
								<Spinner className='spinner_load_common' animation="border" variant="info" >
								</Spinner>
								<p className='pt-2'>Please wait while we are settings things for you</p>
							</div></> :
						<Switch>
							<Route path="/review/:id" component={ReviewOpt} />
							<Route path="/review-success" component={ReviewSuccess} />
							<Route path="/review-failure/:id" component={ReviewFailure} />
							<Route path="/thanks" component={Thanks} />

							<Route path="/add-dynamic-category/:id" component={adddynamiccategory} />
							<Route path="/forgotpassword" component={ForgotPassword} />
							<Route path="/" component={Login} />
						</Switch>
					}
				</Router>
			</div>
		);
	}
}
const mapStateToProps = state => ({
	darkMode: state.settings.isDarkMode,
	darkSidebar: state.settings.isDarkSidebar,
	iconColor: state.settings.isIconColor,
	gradientColor: state.settings.isGradientColor,
	rtl: state.settings.isRtl,
	fontType: state.settings.isFont,
	boxLayout: state.settings.isBoxLayout,
	notification: state.notification,
	account: state.account,
	landing: state.landing
})

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ GetStatus: AC_GET_STATUS, ListCountry: AC_LIST_COUNTRY, ListHotel: AC_LIST_HOTEL, historyData: AC_GET_HISTORY_DATA, AccountDetails: AC_ACCOUNT_DETAILS, GetNotification: AC_GET_NOTIFICATION, landingData: AC_GET_LANDING_DATA, getLanguageData: AC_GET_LANGUAGE_DATA }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
import React, { Component } from 'react';
import Select from 'react-select';

class MultiSelectComponent extends Component {
    onChange = (e) => {
        const { options, onChange, name } = this.props;
        const optionsData = options && options.length > 0 ? options.filter((o) => o.value !== "Add") : []
        let selectId = [];
        let allIncludes = e.some((o) => o.label == "All");
        let totalId = []
        optionsData.map((o) => { if (o.label != "All" && o.value) totalId.push(o.value) });
        if (e && e.length > 0) {
            e.map((item) => {
                if (item.label != "All") {
                    selectId.push(item.value)
                }
            })
        }
        onChange({ name: name, value: e.length > 0 ? (allIncludes ? totalId : selectId) : [], selectAll: !allIncludes })
    };
    render() {
        const { options, value, selectRef } = this.props;
        return (
            <Select
                isMulti
                options={options}
                onChange={this.onChange}
                value={value}
                ref={selectRef}
            />
        );
    }
}

export default MultiSelectComponent;
import React, { Component } from 'react'
import { connect } from 'react-redux';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { AC_HANDLE_LANGUAGE_CHANGE, AC_LIST_LANGUAGE, AC_RESET_LANGUAGE, AC_VIEW_LANGUAGE, AC_HANDLE_LANGUAGE, AC_EMPTY_LANGUAGE } from '../../actions/languageAction';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import Spinner from 'react-bootstrap/Spinner';
import { Link, Redirect } from 'react-router-dom';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import { Row } from 'react-bootstrap';
import { AC_ACCOUNT_DETAILS, AC_UPDATE_LANGUAGE_JSON } from '../../actions/accountAction';

class EditLanguage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            langData: {},
            listRedirect: false,
            saving: false,
        }
        this.submitlangData = this.submitlangData.bind(this);
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.EmptyLanguage();
        this.props.ViewLanguage(id)
    }

    onNormalChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false })
        this.props.AC_HANDLE_LANGUAGE({ [e.target.name]: e.target.value });
    }

    onChangeEdit = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false })
        this.props.HandleInputChange(name, value);
    }
    submitlangData() {
        const { AccountState, LanguageState } = this.props;
        const tabposition = LanguageState.tabposition;
        const data = LanguageState.language;
        const account = AccountState.account;
        let id = this.props.match.params.id;
        let valid = 1;
        data['name'] = data.name.trim()
        data['code'] = data.code.trim()
        if (!data.name) {
            this.setState({ nameError: true });
            valid = 0;
        }
        if (!data.code) {
            this.setState({ codeError: true });
            valid = 0;
        }
        let searchData = data.tempLanguageData
        for (const key in searchData) {
            if (data.languageData.hasOwnProperty(key)) {
                data.languageData[key] = searchData[key];
            }
        }
        if (valid) {
            this.setState({ saving: true })
            this.props.ListLanguage({ languagestatus: tabposition });
            axios.post(ImportedURL.API.updateLanguage + '/' + id, data)
                .then((res) => {
                    Success("Language updated successfully");
                    this.setState({ saving: false, listRedirect: true })
                    this.setState({ searchhoteloption: '', searchhotel: '' })
                    if (res.data.languagestatus == "Website") {
                        if (account.language == data.code) {
                            this.props.UpdateLangJson(data.code, data.languageData);
                        }
                    } else {
                        this.props.HandleLanguageTabPosition('tabposition', 'Mobile');
                    }
                }).catch(({ response }) => {
                    this.setState({ saving: false })
                    if (response != undefined) {
                        if (response.status == 500) {
                            Error(response.status + ' Internal Server Error')
                        } else if (response.status == 400) {
                            Error('Bad request')
                        } else if (response.status == 502) {
                            Error(response.status + ' Bad Gateway')
                        } else {
                            Error(response.status + " " + response.statusMessage)
                        }
                    }
                });
        }

    }

    handleSearch = (e) => {
        var value = e.target.value;
        const data = this.props.LanguageState.language;
        this.setState({ serachValue: value })
        if (value) {
            const resultObject = Object.fromEntries(
                Object.entries(data.languageData)
                    .filter(([key]) => key.toLowerCase().includes(value.toLowerCase()))
            );
            this.props.HandleInputChange('tempLanguageData', resultObject);
        } else {
            this.props.HandleInputChange('tempLanguageData', data.languageData);
        }
    }
    render() {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const data = this.props.LanguageState.language;
        const dataSpinner = this.props.LanguageState.spinner;
        if (this.state.listRedirect) return <Redirect to={'/list-language'} />
        if (data.tempLanguageData) {
            var languageListDashboard = [];
            var dashboardEnd = [];
            var languageListComponent = [];

            let array = Object.entries(data.tempLanguageData)
            let status = true
            for (let index = 0; index < array.length; index++) {
                if (status) {
                    if (array[index][0] == "DASHBOARD_DATA_END") {
                        dashboardEnd.push(array[index])
                        status = false
                    } else {
                        languageListDashboard.push(array[index])
                    }
                } else {
                    languageListComponent.push(array[index])
                }
            }

            var dashboardArray = []
            var componentArray = []
            {
                languageListDashboard.concat(dashboardEnd).map((item, index) => {
                    dashboardArray.push(<>
                        <div className="card set_permission">
                            <div className="cord-body">
                                <div className="category_prev_list">
                                    <div className="row">
                                        <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                            <label className="label_black label_language" style={{ textAlign: "center" }}>
                                                <div className='product_vendor' data-bs-toggle="tooltip" data-bs-placement="top" title={item[0].toUpperCase()}><span className="tooltiptext">{item[0].toUpperCase()}</span></div>
                                            </label>
                                        </div>

                                        <div className="col-lg-2">
                                            <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-4 col-4'>
                                            <div className="category_access">
                                                <ul>
                                                    <div className="form-group">
                                                        <input className="language_editinput" type="text" name={item[0]} value={item[1]} onChange={this.onNormalChange} />
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>)

                })
            }
            {
                languageListComponent.map((item, index) => {
                    componentArray.push(<>
                        <div className="card set_permission">
                            <div className="cord-body">
                                <div className="category_prev_list">
                                    <div className="row">
                                        <div className='col-lg-6 col-md-6 col-sm-6 col-6'>
                                            <label className="label_black label_language" style={{ textAlign: "center" }}>
                                                <div className='product_vendor' data-bs-toggle="tooltip" data-bs-placement="top" title={item[0].toUpperCase()}><span className="tooltiptext">{item[0].toUpperCase()}</span></div>
                                            </label>
                                        </div>

                                        <div className="col-lg-2">
                                            <i className="fa fa-long-arrow-right" aria-hidden="true"></i>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-4 col-4'>
                                            <div className="category_access">
                                                <ul>
                                                    <div className="form-group">
                                                        <input className="language_editinput" type="text" name={item[0]} value={item[1]} onChange={this.onNormalChange} />
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>)

                })
            }
        }

        return (


            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-language"><h6> Language List</h6></Link><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>Edit Language</h6> </p>
                    </div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade show active"
                                    id="Departments-list"
                                    role="tabpanel"
                                >
                                    <div className="card">
                                        <div className="card-body">
                                            <div className="header-action" style={{ marginTop: '5px' }}>
                                                <Link to='/list-language'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                            </div>
                                            <div className='card-title d-flex'>
                                                <div className='rounded_icon'><i className="fa fa-language mr-2 "></i></div>
                                                <h3 className="card-title" style={{ marginTop: '10px' }}>Edit Language</h3>
                                            </div>
                                            <Row >
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='name' onChange={this.onChangeEdit} value={data.name} placeholder="Name" />
                                                        <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Name is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Code<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='code' onChange={this.onChangeEdit} value={data.code.toLowerCase()} placeholder="Code" />
                                                        <div className="invalid-feedback" style={{ display: this.state.codeError ? "block" : 'none' }}>Code is required</div>
                                                    </div>
                                                </div>
                                                <div className='col-lg-6 col-md-12'>
                                                    <div class="form-group senti_search">
                                                        <label className='form-label'>Search</label>
                                                        <input type="text" class="form-control " id="formGroupExampleInput" placeholder="Search" value={this.state.serachValue} onChange={this.handleSearch} />
                                                        <i class="fa fa-search senti_serch_icon" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                            </Row >
                                            {dashboardArray && dashboardArray.length > 0 ?
                                                <form id='form_reset' className="language_border">
                                                    <Row >
                                                        <p className="user_permission">
                                                            SIDEBAR
                                                        </p>
                                                        <div id="language_Scrol" style={dashboardArray && dashboardArray.length > 5 ? { height: '350px' } : {}}>
                                                            {dashboardArray}
                                                        </div>
                                                    </Row>
                                                </form>
                                                : ''
                                            }
                                            {componentArray && componentArray.length > 0
                                                ?
                                                <form id='form_reset' className="language_border mt-3">
                                                    <Row >
                                                        <p className="user_permission">
                                                            COMPONENT
                                                        </p>
                                                        <div id="language_Scrol" style={componentArray && componentArray.length > 5 ? { height: '350px' } : {}}>
                                                            {componentArray}
                                                        </div>
                                                    </Row>
                                                </form>
                                                : ''
                                            }
                                            <div className="text-right mt-3">
                                                {
                                                    this.state.saving ?
                                                        <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                        :
                                                        <button type="button" className="btn commor_save" onClick={this.submitlangData}>
                                                            <i className="fe fe-save mr-2"></i>Update
                                                        </button>
                                                }
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="overlay" style={{ display: dataSpinner ? 'block' : 'none' }}></div>
                    {
                        dataSpinner &&
                        <div className='common_loader'>
                            <img className='loader_img_style_common' src='../../assets/images/load.png' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                        </div>
                    }
                </div>


            </>
        )
    }
}
const mapStateToProps = state => ({
    LanguageState: state.language,
    AccountState: state.account,
    HotelState: state.hotel,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        AC_HANDLE_LANGUAGE,
        HandleInputChange: AC_HANDLE_LANGUAGE_CHANGE, ListLanguage: AC_LIST_LANGUAGE, ViewLanguage: AC_VIEW_LANGUAGE, ResetLanguage: AC_RESET_LANGUAGE,
        AC_LIST_HOTEL,
        EmptyLanguage: AC_EMPTY_LANGUAGE,
        AccountDetails: AC_ACCOUNT_DETAILS,
        UpdateLangJson: AC_UPDATE_LANGUAGE_JSON
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(EditLanguage);
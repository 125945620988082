export const columnDefs = [
    { headerName: '#', valueGetter: "node.rowIndex+1", width: 50, filter: false, cellStyle: { 'text-align': 'center' }, sort: { direction: 'asc', priority: 0 }, suppressNavigable: false , hide: 'true'},
    { headerName: 'Country', field: 'name', width: 200, floatingFilter: true, sort: 'asc', sortIndex: 0 },
    { headerName: 'ISO Code', field: 'code', width: 150, floatingFilter: true, cellStyle: { 'text-align': 'center' } },
    // { headerName: 'Country Code', field: 'phonecode', width: 150,cellStyle: {'text-align': 'center'} },
    {
        headerName: 'Country Code', field: 'phonecode', width: 130, filter: false,
        cellRenderer: function (params) {
            if (params.data.flag) {
                let flag = `<div class=''><i class="country_ctr alignment-flag flag flag-` + params.data.flag + `"></i><span class="pl-2">` + params.data.phonecode + `</span></div>`;
                return flag
            }
        }
    },
    {
        headerName: 'Currency Symbol', field: 'symbol', width: 130, filter: false, cellStyle: { 'text-align': 'center' },
    },
    {
        headerName: 'Status', width: 85, field: 'status', filter: false, headerClass: 'ag-center-header', suppressMenu: true,
        cellRenderer: function (params) {
            if (params.data.status) {
                return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
            } else {
                return '<span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span>';
            }
        }
    },
    {
        headerName: 'Actions', width: 150, field: 'actions', headerClass: 'ag-center-header',cellStyle: { 'text-align': 'center' }, suppressMenu: true, sortable: false, filter: false,
        cellRenderer: function (params) {
            if (params.value) {
                return params.value;
            } else {
                return "---"
            }
        }
    },
]
import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_LIST_CURRENCY(params = {}) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.listCurrency, params)
            .then((res) => {
                dispatch({ type: "LIST_CURRENCY", payload: res.data.data, total: res.data.total, spinner: false })
            }).catch((err) => { console.log(err); });
    };
}

export function AC_VIEW_CURRENCY(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewCurrency + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_CURRENCY", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}

export function AC_HANDLE_CURRENCY_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_CURRENCY_CHANGE", name: name, value: value })
    };
}


export function AC_RESET_CURRENCY() {
    return function (dispatch) {
        dispatch({ type: "RESET_CURRENCY" })
    };
}
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Chart from 'react-apexcharts';

class CurrentPriority extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [{
                name: 'Net Profit',
                data: [44, 55, 57, 56, 61, 58, 63, 60, 66]
            },
            {
                name: 'Revenue',
                data: [76, 85, 101, 98, 87, 105, 91, 114, 94]
            }, {
                name: 'Free Cash Flow',
                data: [35, 41, 36, 26, 45, 48, 52, 53, 41]
            }
            ],
            options: {
                chart: {
                    type: 'bar',
                    id: "basic-bar",
                    events: {
                        mouseLeave: function (event, chartContext, config) {
                            chartContext.resetSeries()
                        }
                    },
                    height: 350
                },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: '55%',
                        endingShape: 'rounded'
                    },
                },
                dataLabels: {
                    enabled: false
                },
                stroke: {
                    show: true,
                    width: 2,
                    colors: ['transparent']
                },
                xaxis: {
                    categories: ['Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct'],
                },
                yaxis: {
                    title: {
                        text: '$ (thousands)'
                    }
                },
                fill: {
                    opacity: 1
                },
                tooltip: {
                    y: {
                        formatter: function (val) {
                            return "$ " + val + " thousands"
                        }
                    }
                }
            },
        };
    }

    render() {
        const { TicketState, status } = this.props;
        const ticketStatusCount = TicketState.userTickets;
        const data1 = [];
        const data2 = [];
        const data3 = [];
        const data4 = [];
        const data5 = [];
        const data6 = [];
        const data7 = [];
        const data8 = [];
        const categories = [];
        const countData = [];

        for (let item of ticketStatusCount) {
            data1.push(item.openCount)
            data2.push(item.assignedCount)
            data3.push(item.inprogressCount)
            data4.push(item.inreviewCount)
            data5.push(item.reopenedCount)
            // data6.push(item.reassignedCount)
            data7.push(item.completedCount)
            data8.push(item.isDeletedCount)
            categories.push(item.reporter_name)
            // countData.push(<li className="list-group-item">
            //     <div className="clearfix">
            //         <div className="float-left">
            //             <strong>{ticketStatusCount[i]}</strong>
            //         </div>
            //         <div className="float-right">
            //             <small className="text-muted" style={{ fontSize: "14px", fontWeight: "bold" }}>{i}</small>
            //         </div>
            //     </div>
            //     <div className="progress progress-xs">
            //         <div className="progress-bar" role="progressbar" aria-valuenow="42" aria-valuemin="0" aria-valuemax="100" style={{ backgroundColor: '#da7474', width: ticketStatusCount[i] }}></div>
            //     </div>
            // </li>)
        }

        const series = [
            {
                name: 'Open',
                data: data1,
                color: '#0080ff',
            },
            {
                name: 'Assigned',
                data: data2,
                color: '#00cccc',
            },
            {
                name: 'In-progress',
                data: data3,
                color: '#cccc00',
            },
            // {
            //     name: 'Re-opened',
            //     data: data5,
            //     color: '#4a80c2',
            // },
            // {
            //     name: 'Re-assigned',
            //     data: data6,
            //     color: '#cccc00',
            // },
            {
                name: 'Inreview',
                data: data4,
                color: '#ff9933',
            },
            {
                name: 'Closed',
                data: data7,
                color: '#55cc55',
            },
            {
                name: 'Deleted',
                data: data8,
                color: '#cc6666',
            }
        ]
        const options = {
            chart: {
                type: 'bar',
                id: "basic-bar",
                events: {
                    mouseLeave: function (event, chartContext, config) {
                        chartContext.resetSeries()
                    }
                },
                height: 350
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            xaxis: {
                categories: categories,
            },
            yaxis: {
                title: {
                    text: 'Tickets'
                }
            },
            fill: {
                opacity: 1,
                colors: ['#0080ff', // Blue
                    '#00cccc', // Light Cyan
                    '#cccc00', // Dark Yellow
                    '#4a80c2', // Light Blue
                    '#cccc00', // Light Green
                    '#ff9933', // Light Orange
                    '#55cc55', // Light Green
                    '#cc6666'  // Light Red
                ]
            },
            tooltip: {
                y: {
                    formatter: function (val) {
                        return val + " tickets"
                    }
                }
            }
        }
        return (
            <>
                <div className="col-xl-12 col-lg-12 col-md-6">
                    <div className="card">
                        <div className="Over_all_table_style card-body">
                            <div className="card-header"><h3 className="card-title">No. of tickets created by individual</h3></div>
                            <table className="table card-table">
                                <tbody>
                                    {countData}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {
                    ticketStatusCount && ticketStatusCount.length ?
                        <div className="card">
                            <div className="card-body">
                                {/* <div>
                                    <Chart
                                        options={options}
                                        series={seriesArray}
                                        type="bar"
                                        width="100%"
                                        height="350"
                                        className="apex-charts"
                                    />
                                </div> */}
                                <div id="chart">
                                    <Chart
                                        options={options}
                                        series={series}
                                        type="bar"
                                        height={350} />
                                </div>
                            </div>
                        </div>
                        :

                        <div className="card">
                            <div className="card-body no_records text-center d-flex align-items-center justify-content-center">
                                <div style={{ maxWidth: "340px" }}>
                                    <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid mb-4 mt-4" style={{ maxWidth: "272px" }} />
                                    <h5 className="mb-2">No records to display</h5>
                                </div>
                            </div>
                        </div>

                }
            </>
        )
    }
}
const mapStateToProps = state => ({
    TicketState: state.ticket,
})
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(CurrentPriority);
import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_ROOM_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_ROOM_CHANGE", name: name, value: value })
	};
}

export function AC_HANDLE_ALLOCATION_CHANGE(name, value, valtype = 'add') {
	return function (dispatch) {
		dispatch({ type: "HANDLE_ALLOCATION_CHANGE", name: name, value: value, valtype: valtype })
	};
}

export function AC_HANDLE_ROOM_INPUT_CHANGE(name, value, index) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_ROOM_INPUT_CHANGE", name: name, value: value, index: index })
	};
}

export function AC_HANDLE_ROOM_UPDATE_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_ROOM_UPDATE_CHANGE", name: name, value: value })
	};
}

export function AC_RESET_ROOM() {
	return function (dispatch) {
		dispatch({ type: "RESET_ROOM" })
	};
}

export function AC_RESET_ALLOCATION() {
	return function (dispatch) {
		dispatch({ type: "RESET_ALLOCATION" })
	};
}

export function AC_UPDATE_ROOMLIST(data) {
	return function (dispatch) {
		dispatch({ type: "UPDATE_ROOMLIST", payload: data })
	};
}

export function AC_LIST_ROOM(params = {}) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.listRoomWithoutAggregation, params)
			.then((res) => {
				dispatch({ type: "LIST_ROOM", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_LIST_ALLOCATION() {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listAllocation)
			.then((res) => {
				dispatch({ type: "LIST_ALLOCATION", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_DELETE_ALLOCATION(id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.deleteAllocation + "/" + id)
			.then((res) => {
				Success(res.statusText);
				dispatch({ type: "LIST_ALLOCATION", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_VIEW_ROOM(id) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewRoom + "/" + id)
			.then((res) => {
				dispatch({ type: "VIEW_ROOM", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_VIEW_ALLOCATION(id, hotel) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewAllocation + "/" + id, { params: { hotel: hotel } })
			.then((res) => {
				dispatch({ type: "VIEW_ALLOCATION", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_UPDATE_ROOM(formData, id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.updateRoom + "/" + id, formData)
			.then((res) => {
				Success(res.statusText);
				// dispatch({ type: "LIST_ROOM", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_DELETE_ROOM(id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.deleteRoom + "/" + id)
			.then((res) => {
				Success('Room deleted successfully');
				// Success(res.statusText);
				dispatch({ type: "LIST_ROOM", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_GET_ROOMS(id) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.getRoomsByHotel + "/" + id)
			.then((res) => {
				dispatch({ type: "LIST_ROOMS_BY_HOTEL", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_GET_HOUSE_KEEPER(id) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.getHKByHotel + "/" + id)
			.then((res) => {
				dispatch({ type: "LIST_HK_BY_HOTEL", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_EMPTY_VIEWROOM() {
	return function (dispatch) {
		dispatch({ type: 'EMPTY_ROOM' })
	}
}
export function AC_EMPTY_VIEWROOM_ALLOCATION() {
	return function (dispatch) {
		dispatch({ type: 'EMPTY_VIEWROOM_ALLOCATION' })
	}
}
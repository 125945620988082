
const initialState = {
    dynamicmanagement: {
        name: '',
        phonenumber: '',
    },
    listgroups: [],
    spinner: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_DYNAMIC_MANAGEMENT_CHANGE':
            return Object.assign({}, state, {
                dynamicmanagement: {
                    ...state.dynamicmanagement,
                    [action.name]: action.value
                }
            })

        default:
            return state;
    }
}
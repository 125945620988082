import React, { Component } from 'react'
import axios from 'axios';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AC_LIST_USER } from '../../actions/userAction';
import moment from 'moment';
import ImportedURL from '../../common/api';
import Spinner from 'react-bootstrap/Spinner';

class ViewReview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewNotificationData: {},
            spinner: true
        }
    }
    getRatings = (rating) => {
        let data = []
        for (let i = 1; i <= 5; i++) {
            if (i <= rating) data.push(<i className="fa fa-star text-orange" key={i}></i>)
            else data.push(<i className="fa fa-star" key={i}></i>)
        }
        return data;
    }
    getDate = (date) => {
        if (!date) return '';
        const newDate = new Date(date * 1000);
        return moment(newDate).format("MM/DD/yyyy");
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        axios.get(ImportedURL.API.getReviewById + '/' + id)
            .then((res) => {
                if (res.data)
                    this.setState({ viewNotificationData: res.data, spinner: false })
            })
    }
    render() {
        const { HotelState, AccountState } = this.props;
        const role = AccountState.account ? AccountState.account.role : '';
        const listHotels = HotelState.listhotels;
        var hotelName = ''
        let { viewNotificationData } = this.state;
        var reviewer_name = viewNotificationData.reviewer_name;
        var title = viewNotificationData.title;
        var text = viewNotificationData.text;
        var reviewer_picture_url = viewNotificationData.reviewer_picture_url;
        var rating = viewNotificationData.rating;
        var published_at = viewNotificationData.published_at;
        var url = viewNotificationData.url;
        var supplier = viewNotificationData.supplier;
        var hotel = viewNotificationData.hotel;
        if (listHotels) {
            listHotels.map((item) => {
                if (item._id === hotel) {
                    hotelName = item.name + " " + item.city + " " + item.state
                }
            })
        }
        return (
            <div>
                {this.state.spinner ?
                    <div>
                        {ImportedURL.SUPERADMIN.includes(role) ?
                            <div>
                                <img className='loader_img_style' src='../../assets/images/load.png' />
                                <Spinner className='spinner_load' animation="border" variant="info" >
                                </Spinner>
                            </div>
                            :
                            <div>
                                <img className='loader_img_style' src='../../assets/images/load.png' />
                                <Spinner className='spinner_load' animation="border" variant="info" >
                                </Spinner>
                            </div>
                        }
                    </div>
                    :
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-body">
                                    <div className="header-action" >
                                        <Link to='/'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button></Link>
                                    </div>
                                    <h3 className="card-title " style={{ marginTop: '12px' }}><i className="icon-star mr-2"></i> View Review</h3>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    {/* <p>{hotelName}</p>
                                <p>{notification}</p> */}
                                    <article className="media">
                                        <div className="mr-3">
                                            <img className="avatar avatar-xl" src={reviewer_picture_url ?
                                                reviewer_picture_url : ImportedURL.LOCALURL + 'assets/images/defaultuser.png'}
                                                onError={(e) => { if (e.target.src !== ImportedURL.LOCALURL + "assets/images/defaultuser.png") { e.target.onerror = null; e.target.src = ImportedURL.LOCALURL + "assets/images/defaultuser.png"; } }} alt=""
                                            />

                                        </div>
                                        <div className="media-body">
                                            <div className="content">
                                                <p className="h5"><span className='pr-2'>{reviewer_name}</span>
                                                    {this.getRatings(rating)}
                                                    <small className="float-right text-muted">{this.getDate(published_at)}</small>
                                                </p>
                                                <p>
                                                    <small className='pr-2'>Posted on : <a href={url} style={{ color: '#007bff' }} target='_blank'>{supplier?.charAt(0).toUpperCase() + supplier?.substr(1).toLowerCase()}</a></small>
                                                    <small className="float-right text-muted">{hotelName}</small>
                                                </p>
                                                <p><strong>{title}</strong></p>
                                                <p dangerouslySetInnerHTML={{ __html: text }}></p>
                                            </div>
                                        </div>
                                    </article>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    HotelState: state.hotel,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ListUser: AC_LIST_USER }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewReview);
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { AC_HANDLE_TAB_CHANGE_REVIEW_FLOLW } from '../../actions/reviewflowAction';
import Reviews from './reviews';
import ClientFeedback from './clientfeedback';

class Reviewflow extends Component {
    tapChange = (e) => {
        this.props.HandleChangeTab(e);
    }
    render() {
        const { AccountState, ReviewflowState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData;
        const tabposition = ReviewflowState.tabposition;
        const spinner = ReviewflowState.spinner;
        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/ticket-flow"><h6 className="highlights_breadcrump">Review Flow</h6></Link></p>
                    </div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="row clearfix">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-star-o mr-2 "></i></div>
                                            <h3 className="card-title">Review Flow</h3>
                                        </div>
                                        <div className='card-body dashboard_mom' style={{ paddingBottom: '40px' }}>
                                            <div className='upcommcomp_btn mb-3'>
                                                <div className='tb_size_reviewflow' style={{ position: 'relative' }}>
                                                    <button type='button' className={`${tabposition == 'socialreviews' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={(e) => this.tapChange("socialreviews")} >Social Reviews</button>
                                                </div>
                                                <div className='tb_size_reviewflow' style={{ position: 'relative' }}>
                                                    <button type='button' className={`${tabposition == 'clientfeedback' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={(e) => this.tapChange("clientfeedback")} >Client Feedback</button>
                                                </div>
                                            </div>
                                            {tabposition == 'socialreviews' ?
                                                <>
                                                    <Reviews />
                                                </> : ''}

                                            {tabposition == 'clientfeedback' ?
                                                <>
                                                    <ClientFeedback />
                                                </> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div id="overlay" style={{ display: spinner ? 'block' : 'none' }}></div>
                {
                    spinner &&
                    <div className='common_loader'>
                        <img className='loader_img_style_common' src='../../assets/images/load.png' />
                        <Spinner className='spinner_load_common' animation="border" variant="info" >
                        </Spinner>
                    </div>
                }
            </>

        )
    }
}

const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    CategoryState: state.category,
    ReviewflowState: state.reviewflow
})
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleChangeTab: AC_HANDLE_TAB_CHANGE_REVIEW_FLOLW
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Reviewflow);
import axios from 'axios';
import React, { Component } from 'react'
import { Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import { AC_HANDLE_TICKET_PRIORITY_CHANGE, AC_EMPTY_TICKET_PRIORITY, AC_VIEW_TICKET_PRIORITY, AC_DEFAULT_TICKET_PRIORITY } from '../../actions/ticketpriorityAction';
import { AC_EMPTY_MOM_REMINDER, AC_EMPTY_SPINNER, AC_HANDLE_MOM_REMINDER_CHANGE, AC_VIEW_MOM_REMINDER } from '../../actions/reminderAction';


class MinutesOfMeeting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            timeLimitError: false,
            lowLimitError: false,
            mediumLimitError: false,
            highError: false,
            mediumError: false,
            lowError: false,
        }
    }
    componentDidMount() {
        this.props.SpinnerMomReminder()
        this.props.EmptyMomReminder()
        this.props.ViewMomReminder({ type: 'mom' })
    }

    handleChange = (e) => {
        const { name, value } = e.target;
        const { HandleChange } = this.props;
        let Error = name + "Error"
        this.setState({ [Error]: false })

        if (name == 'time') {
            const regex = /^0|[^0-9\s]/g;
            const filtered = value.replace(regex, '');
            HandleChange(name, filtered);
        }
    }

    submit = (e) => {
        const { ReminderState, AccountState } = this.props;
        const data = ReminderState.momreminder;
        const languageData = AccountState.account.SelectedlanguageData
        let valid = 1;
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        }
        if (!data.time) {
            this.setState({ timeError: true });
            valid = 0;
        }
        if (valid) {
            data['type'] = 'mom'
            this.setState({ saving: true })
            axios.post(ImportedURL.API.updateReminder, data)
                .then((res) => {
                    this.props.SpinnerMomReminder()
                    this.props.EmptyMomReminder()
                    this.props.ViewMomReminder({ type: 'mom' });
                    this.setState({ saving: false })
                    Success(("Ticket Priority") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"));
                }).catch(({ response }) => {
                    this.setState({ saving: false })
                    if (response != undefined) {
                        if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else if (response.status == 400) {
                            Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                    }
                });
        }
    }
    render() {
        const { ReminderState } = this.props;
        const data = ReminderState.momreminder
        const spinner = ReminderState.spinner
        return (
            <>
                <div className='card-body  ticketpriority'>
                    <Row>
                        <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2'>
                            <Row>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2'>
                                    <div className='title'>
                                        <h5>MOM</h5>
                                    </div>

                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2'>
                                    <div className='title'>
                                        <h5>Minutes</h5>
                                    </div>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2 '>
                                    <label class="form-label">Meeting Reminder</label>
                                </div>
                                <div className='col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-2'>
                                    <div class="form-group">
                                        <input type="text" class="form-control placevalue" onChange={this.handleChange} name="time" placeholder="Minutes" value={data.time} />
                                        <div class="invalid-feedback" style={{ display: this.state.timeError ? 'block' : 'none' }}>Minutes is required</div>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Row >
                    <div className="card-footer text-right">
                        <button type="submit" style={{ marginBottom: '20px' }} className="btn commor_save" onClick={this.submit}><i className={this.state.saving ? "fa fa-spinner fa-spin mr-2" : "fe fe-save mr-2"}></i>{this.state.saving ? "Saving" : "Save"}</button>
                    </div>
                </div >
                <div id='overlay' style={{ display: spinner ? 'block' : 'none' }}></div>
                {spinner ?
                    <div className='model_loader_poss'>
                        <img className='loader_img_style_model' src='../../assets/images/load.png' />
                        <Spinner className='spinner_load_model' animation="border" variant="info" >
                        </Spinner>
                    </div>
                    : ''}
            </>
        )
    }
}


const mapStateToProps = state => ({
    AccountState: state.account,
    ReminderState: state.reminder,
})
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleChange: AC_HANDLE_MOM_REMINDER_CHANGE,
        ViewMomReminder: AC_VIEW_MOM_REMINDER,
        EmptyMomReminder: AC_EMPTY_MOM_REMINDER,
        SpinnerMomReminder: AC_EMPTY_SPINNER,
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(MinutesOfMeeting);

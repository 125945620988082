import React, { Component } from 'react';
import Select from 'react-select';

class SelectComponent extends Component {
    render() {
        const { options, onChange, value, selectRef } = this.props;
        return (
            <Select
                options={options}
                onChange={onChange}
                value={value}
                ref={selectRef}
            />
        );
    }
}

export default SelectComponent;
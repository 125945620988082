import React, { Component } from 'react'
import ErrorResponseComponent from '../../../utilities/ErrorStatus/errorresponse';
import ImportedURL from '../../../common/api';
import axios from 'axios';
import { Success } from '../../../common/swal';
import moment from 'moment';
// import { GoogleLogin } from 'react-google-login';
import ErrorResponse from '../../../utilities/Error/errorstatus';
// import { GoogleLogin } from '@react-oauth/google';

class SyncCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalType: "Add",
            title: '',
            description: '',
            tokenClient: null,
            gapiInited: false,
            gisInited: false,
            events: []
        };
        this.titleInputRef = React.createRef();
    }

    handleGoogle = async () => {
        const SCOPES = 'https://www.googleapis.com/auth/calendar.events';
        const client = window.google.accounts.oauth2.initCodeClient({
            client_id: '1032791995921-q6fikk2l5hslhh60csg0cig5m2aus47u.apps.googleusercontent.com', // Replace with your actual client ID
            scope: SCOPES,
            ux_mode: 'popup',
            callback: async (response) => {
                try {
                    if (!response.code) {
                        return;
                    }
                    axios.post(ImportedURL.API.googleSync, { code: response.code })
                        .then((res) => {
                            this.setState({ events: res.data })
                        }).catch(({ response }) => {
                            this.setState({ saving: false })
                            ErrorResponse(response)
                        });

                } catch (error) {
                    console.log(error);
                }
            },
        });

        client.requestCode();
    }



    render() {
        const { AccountState } = this.props
        const languageData = AccountState.account.SelectedlanguageData;
        return (
            <>
                <div className="modal fade country_model" id="synccalendar" autoComplete="off" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className='rounded_icon'><i className="icon-clock mr-2 "></i></div>
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{"Google Calendar Sync"}</h5>
                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <button className="btn google_sync_todo_button" onClick={this.handleGoogle}    >
                                            <img src='../../assets/images/google.webp' alt="Google Logo"
                                                style={{ marginRight: '10px', width: '24px', height: '24px' }}
                                            />
                                            Sign in with Google
                                        </button>
                                    </div>
                                    <div className="col-sm-12 col-md-12 mt-3">
                                        {
                                            this.state.events && this.state.events.length > 0
                                                ?
                                                <>
                                                    <ol class="list-group list-group-numbered">
                                                        {
                                                            this.state.events.map((item) => {
                                                                return (
                                                                    <li class="list-group-item d-flex justify-content-between align-items-start">
                                                                        <div class="ms-2 me-auto">
                                                                            <div class="fw-bold">{item.summary}</div>
                                                                            {
                                                                                item.description ?
                                                                                    <div dangerouslySetInnerHTML={{ __html: item.description }}></div>
                                                                                    : ''
                                                                            }
                                                                        </div>
                                                                        <span class="badge bg-primary rounded-pill">{moment(item.created).format("MM-DD-YYYY")}</span>
                                                                    </li>
                                                                )
                                                            })
                                                        }
                                                    </ol>
                                                </>
                                                : <></>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >

            </>
        )
    }
}

export default SyncCalendar;
import phonenumberformat from './phonenumberformat.json';
import timezonesData from './timezones.json';
import moment from 'moment-timezone';
import NoFoundImage from '../assets/images/notfoundimage.jpg';
import ImageNotFountObjectFit from '../assets/images/imagenotfound.jpg';
import ImportedURL from './api';
import ReactDOM from 'react-dom';

export function Emailvalidate(email) {
    const regemail = /^\w+([/.-]?\w+)*@\w+([/.-]?\w+)*(\.\w{2,3})+$/;
    if (regemail.test(email)) {
        return 1;
    }
    else {
        return 0;
    }
}
export function Urlvalidate(url) {
    const regex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/
    if (regex.test(url)) {
        return 1;
    }
    else {
        return 0;
    }
}

export function Validpassword(password) {
    const pattern = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#/$%/^&/*])(?=.{8,})");
    const passwordvalid = pattern.test(password)
    return passwordvalid
}

export function Facebookvalidate(facebook_link) {
    const facebook_pattern = /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/;
    const isvalidFacebooklink = facebook_pattern.test(facebook_link);
    return isvalidFacebooklink;
}

export function Twittervalidate(twitter_link) {
    const twitter_pattern = /^(https?:\/\/)?((w{3}\.)?)twitter.com\/.*/;
    const isvalidTwitterlink = twitter_pattern.test(twitter_link);
    return isvalidTwitterlink;
}

export function Googleplusvalidate(googleplus_link) {
    const googleplus_pattern = /plus\.google\.com\/.?\/?.?\/?([0-9]*)/;
    const isvalidGooglepluslink = googleplus_pattern.test(googleplus_link);
    return isvalidGooglepluslink;
}

export function Phonenumber(number) {
    const phoneno = /^\d{10}$/;
    if (phoneno.test(number)) {
        return 1;
    } else {
        return 0;
    }
}
export function isAlphabetic(value) {
    const regex = /^[a-zA-Z\s]+$/;

    if (regex.test(value)) {
        return 1;
    } else {
        return 0;
    }
}

export function NumberOnly(num) {
    const number = /^\d+$/;
    if (number.test(num)) {
        return 1
    } else {
        return 0
    }
}
export function NumberAndDotOnly(num) {
    const number = /^(?!0\d)\d*\.?\d*$/;  // Allow numbers and a dot
    if (number.test(num)) {
        return 1;
    } else {
        return 0;
    }
}
export function Imagevalidation(logo) {
    if (logo != undefined && logo) {
        const fileInfo = logo;
        const fileType = fileInfo.type;
        const type = fileType.split('/');
        if (type[1] === 'jpg' || type[1] === 'jpeg' || type[1] === 'png') {
            return 1;
        } else {
            return 0;
        }
    } else {
        return 0;
    }
}

export function Filevalidation(file) {
    const fileInfo = file;
    const fileType = fileInfo.type;
    const type = fileType.split('/');
    if (type[1] === 'pdf' || type[1] === 'doc' || type[1] === 'docx') {
        return 1;
    } else {
        return 0;
    }
}

export function FileAndImagevalidation(file) {
    const fileInfo = file;
    const fileType = fileInfo.type;
    const type = fileType.split('/');
    if (type[1] === 'pdf' || type[1] === 'doc' || type[1] === 'docx' || type[1] === 'jpg' || type[1] === 'jpeg' || type[1] === 'png') {
        return 1;
    } else {
        return 0;
    }
}
export function Zip(zip) {
    const zippattern = /^\d{5}[-\s]?(?:\d{4})?$/;
    const patternnumner = /(^\d{6}$)|(^\d{6}-\d{4}$)/;
    if ((zippattern.test(zip)) || (patternnumner.test(zip))) {
        return 1;
    } else {
        return 0;
    }
}
export function Hostvalid(host) {
    const pattern = /^\w+\.\w+\.[a-zA-z]{1,3}$/    //xxx.domain.in/com/...
    const hostnamevalid = pattern.test(host)
    return hostnamevalid
}
export function Portvalid(port) {  //takes port number with in the range of 1-65535
    const patt = /^((((([1-9])|([1-9][0-9])|([1-9][0-9][0-9])|([1-9][0-9][0-9][0-9])|([1-6][0-5][0-5][0-3][0-5])))))$/   //range from (1-65535)
    const postnamevalid = patt.test(port)
    return postnamevalid
}
export function Capitalize(text) {
    const capitalizedText = (text && text.length > 0) ? (text.charAt(0).toUpperCase() + text.slice(1)) : text;
    return capitalizedText;
}
export function CapitalizeFirstLetter(text) {
    const capitalizedText = (text && text.length > 0) ? (text.charAt(0).toUpperCase() + text.slice(1).toLowerCase()) : text;
    return capitalizedText;
}
export function CapitalizeFirstLetterWithoutSpace(text) {
    if (text) {
        let spaceRemoved = text.replace(/ /g, '');
        const capitalizedText = (spaceRemoved && spaceRemoved.length > 0) ? (spaceRemoved.charAt(0).toUpperCase() + spaceRemoved.slice(1).toLowerCase()) : spaceRemoved;
        return capitalizedText;
    } else {
        return ''
    }
}

export function SmallLetterWithoutSpace(text) {
    if (text) {
        let spaceRemoved = text.replace(/ /g, '');
        const capitalizedText = (spaceRemoved && spaceRemoved.length > 0) ? spaceRemoved.toLowerCase() : spaceRemoved;
        return capitalizedText;
    } else {
        return ''
    }
}

export function Currency(value) {
    if (value) {
        let amount = value.toString()
        amount = amount.replace(/(\d)(?=(\d{3})+(\.(\d){0,2})*$)/g, '$1,');
        if (amount.indexOf('.') === -1)
            return amount + '.00';
        var decimals = amount.split('.')[1];
        return decimals.length < 2 ? amount + '0' : amount;
    } else return 0;
};

export function DataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

export function TimeFormat(time) {
    if (time && time.length < 10) {
        const timeValue = time.split(' ');
        if (timeValue.length == 2) {
            return time
        }
        if (timeValue.length == 1) {
            const timeString12hr = new Date('1970-01-01T' + time + 'Z')
                .toLocaleTimeString('en-US',
                    { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
                );
            return timeString12hr
        }
    } else {
        return ''
    }
}

export function TimeFormat24(time) {
    if (time) {
        const timeValue = time.split(' ');
        if (timeValue.length == 2) {
            var [timeval, modifier] = time.split(' ');
            var [hours, minutes] = timeval.split(':');
            if (hours === '12') {
                hours = '00';
            }
            if (modifier == 'PM' || modifier == 'pm') {
                hours = parseInt(hours, 10) + 12;
            }
            if ((parseInt(hours) < 10)) hours = '0' + hours;
            return `${hours.toString()}:${minutes.toString()}`;
        }
        if (timeValue.length == 1) {
            return time
        }
    } else {
        return ''
    }
}

export function Textareavalidation(text) {
    var res = {}
    if (text.length <= 0) {
        res.notextErr = true;
        res.value = text
        res.textlimitErr = false;

    }
    else if (text.length < 256 && text.length >= 0) {
        res.notextErr = false;
        res.textlimitErr = false;
        res.value = text
    }
    else {
        res.notextErr = false;
        res.textlimitErr = true;
        res.value = text
    }
    return res
}

export function getCroppedImg(imageSrc, crop, outputSize) {
    const image = new Image();
    image.src = imageSrc;

    const canvas = document.createElement('canvas');
    canvas.width = outputSize.width;
    canvas.height = outputSize.height;

    const ctx = canvas.getContext('2d');
    ctx.drawImage(
        image,
        crop.x,
        crop.y,
        crop.width,
        crop.height,
        0,
        0,
        outputSize.width,
        outputSize.height
    );

    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            resolve(URL.createObjectURL(blob));
        }, 'image/jpeg');
    });
}

export function formatPhoneNumber(phoneNo, cc) {
    let phoneNumber = phoneNo ? (phoneNo).trim() : ''
    let countryCode = cc ? ((cc).trim()).slice(0, 2) : ''
    const findFormat = countryCode ? phonenumberformat.find(e => ((e.code).toLowerCase() == (countryCode).toLowerCase())) : '';
    var format = findFormat ? findFormat.format : ''
    if (format && phoneNumber && format.length >= 10) {
        const digitsOnly = phoneNumber.replace(/\D/g, '');
        let formattedNumber = '';
        let index = 0;
        for (let i = 0; i < format.length; i++) {
            if (format[i] === '#') {
                formattedNumber += digitsOnly[index] || '';
                index++;
            } else {
                formattedNumber += format[i];
            }
        }
        return formattedNumber;
    } else {
        return phoneNumber;
    }
}

function HoursAndMinutes(value) {
    var decimalTimeString = value;
    var decimalTime = parseFloat(decimalTimeString);
    decimalTime = decimalTime * 60 * 60;
    var hours = Math.floor((decimalTime / (60 * 60)));
    decimalTime = decimalTime - (hours * 60 * 60);
    var minutes = Math.floor((decimalTime / 60));
    decimalTime = decimalTime - (minutes * 60);
    var seconds = Math.round(decimalTime);
    return ({ hours, minutes, seconds })
}

function padNumber(number) {
    let value = (Math.sign(number) == -1) ? (-1 * number) : number
    return String(value).padStart(2, "0");
}

function addHoursAndMinutesToDate(dateString, hoursToAdd, minutesToAdd) {
    var [datePart, timePart] = dateString ? dateString.split("T") : [];
    var [year, month, day] = datePart ? datePart.split("-") : [];
    var [hours, minutes, seconds] = timePart ? timePart.split(":") : [];
    var newHours = parseInt(hours) - hoursToAdd;
    var newMinutes = parseInt(minutes) - minutesToAdd;
    if (newMinutes < 0) {
        newHours -= Math.ceil(Math.abs(newMinutes) / 60);
        newMinutes = 60 - Math.abs(newMinutes) % 60;
    }
    if (newHours < 0) {
        newHours = (24 + newHours) % 24;
        day = parseInt(day) - 1
    }
    if (newMinutes >= 60) {
        newHours += Math.floor(newMinutes / 60);
        newMinutes %= 60;
    }
    if (newHours >= 24) {
        newHours %= 24;
        day = parseInt(day) + 1
    }
    let newseconds = seconds.slice(0, 2)
    const newDate = `${year}-${padNumber(month)}-${padNumber(day)} ${padNumber(newHours)}:${padNumber(newMinutes)}:${padNumber(newseconds)}`;
    return newDate;
}

export function ConvertToCurrentTimezone(time) {
    if (time != undefined && time) {
        const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
        let clientTimeZone = timezonesData.find((e) => e.utc.some((x) => x == timeZone))
        let findHoursTime = HoursAndMinutes(clientTimeZone.offset)

        let addHoursAndMinutes = addHoursAndMinutesToDate(time, findHoursTime.hours, findHoursTime.minutes)
        const userLocalDate = moment.utc(addHoursAndMinutes).tz(timeZone).format();

        if (userLocalDate != 'Invalid date') {
            return userLocalDate;
        } else {
            return new Date();
        }
    } else {
        return new Date();
    }
};

export function convertImgToBase64(url) {
    var canvas = document.createElement('canvas');
    let img = document.createElement('img');
    img.src = url;
    canvas.height = img.height;
    canvas.width = img.width;
    const ctx = canvas.getContext('2d');
    var dataURL = canvas.toDataURL('image/jpeg')
    return dataURL;
}

export function GenerateUniqueColorCodeArray(number) {
    let numColors = number ? Number(number) : 0;
    const uniqueColors = [];
    function randomColor() {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    }
    while (uniqueColors.length < numColors) {
        const color = randomColor();
        if (color !== '#FFFFFF' && !uniqueColors.includes(color)) {
            uniqueColors.push(color);
        }
    }
    return uniqueColors;
}

export function imageCellRendererList(params) {
    const img = document.createElement('div');
    ReactDOM.render(
        <img
            src={ImportedURL.FILEURL + params.value}
            onError={(e) => {
                e.target.onerror = null;
                e.target.src = NoFoundImage;
            }}
            alt="Hotel Logo"
            width='65px' height='65px' class="contain_image"
        />,
        img
    );
    return img;
}

export function onErrorImage(e) {
    e.target.onerror = null;
    e.target.src = ImageNotFountObjectFit;
}

export function objectToQueryString(obj) {
    const queryString = Object.keys(obj)
        .filter((key) => {
            const value = obj[key];
            if (value === undefined || value === null || (Array.isArray(value) && value.length === 0) || value === '') {
                return false;
            }
            return true;
        })
        .map((key) => {
            const value = obj[key];
            if (Array.isArray(value)) {
                return value.map((item) => `${encodeURIComponent(key)}=${encodeURIComponent(item)}`).join('&');
            }
            return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
        })
        .join('&');
    return queryString ? `?${queryString}` : '';
}

export function WordMatch(word, sentence) {
    const pattern = new RegExp(`\\b${word}\\b`, 'i');
    if (pattern.test(sentence)) {
        return true
    } else {
        return false
    }
}
// ------------------------------------------------push to landing------start-------
export function AddInaList(list, data) {
    let rowData = list && list.length > 0 ? [...list] : []
    if (data) rowData.unshift(data)
    if (rowData.some((e) => e.hasOwnProperty('sort'))) {
        (rowData && rowData.length > 0) && rowData.sort((a, b) => Number(a.sort) - Number(b.sort))
    } else {
        (rowData && rowData.length > 0) && rowData.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
    }
    return rowData
}

export function UpdateInaList(list, data, id) {
    let rowData = list && list.length > 0 ? [...list] : []
    const index = rowData.findIndex((e) => e._id == id);
    if (index !== -1) {
        rowData = [
            ...rowData.slice(0, index),
            { ...rowData[index], ...data },
            ...rowData.slice(index + 1)
        ]
    } else {
        rowData.push(data)
    }
    if (rowData.some((e) => e.hasOwnProperty('sort'))) {
        (rowData && rowData.length > 0) && rowData.sort((a, b) => Number(a.sort) - Number(b.sort))
    } else {
        (rowData && rowData.length > 0) && rowData.sort((a, b) => Date.parse(b.createdAt) - Date.parse(a.createdAt))
    }
    return rowData
}

export function ListLandingUpdateStatus(data, id, index) {
    let allData = JSON.parse(localStorage.getItem('adminLandingData'))
    let array = id ? UpdateInaList((allData.data[index]), data, id) : AddInaList((allData.data[index]), data);
    let list = (data.status == false) ? array.filter((e) => e._id != data._id) : array
    let updatedData = {
        ...allData,
        data: [
            ...allData.data.slice(0, index),
            [...list],
            ...allData.data.slice(index + 1)
        ]
    };
    localStorage.setItem('adminLandingData', JSON.stringify(updatedData));
    return updatedData
}

export function RemoveDeletedObject(list, data, id) {
    let rowData = list && list.length > 0 ? [...list] : [];
    const index = rowData.findIndex((e) => e._id == id);
    if (index !== -1) {
        rowData = [
            ...rowData.slice(0, index),
            ...rowData.slice(index + 1)
        ];
    }
    return rowData;
}
export function ListLandingUpdateDelete(data, id, index) {
    let allData = JSON.parse(localStorage.getItem('adminLandingData'))
    let list = (data.isDeleted == true) ? RemoveDeletedObject((allData.data[index]), data, id) : AddInaList((allData.data[index]), data);
    let updatedData = {
        ...allData,
        data: [
            ...allData.data.slice(0, index),
            [...list],
            ...allData.data.slice(index + 1)
        ]
    };
    return updatedData
}
export function ListLandingUpdateData(data, id, index) {
    let allData = JSON.parse(localStorage.getItem('adminLandingData'))
    let list = id ? UpdateInaList((allData.data[index]), data, id) : AddInaList((allData.data[index]), data);
    let updatedData = {
        ...allData,
        data: [
            ...allData.data.slice(0, index),
            [...list],
            ...allData.data.slice(index + 1)
        ]
    };
    return updatedData
}

export function ReplaceArrayAtIndex(newArray, index) {
    let allData = JSON.parse(localStorage.getItem('adminLandingData'))
    let array = allData.data
    if (index >= 0 && index < array.length) {
        array[index] = newArray;
    }
    let landing = {
        status: 1,
        data: array
    }
    localStorage.setItem('adminLandingData', JSON.stringify(landing));
    return landing;
}
// ------------------------------------------------push to landing------end-------

export function addToLanding(responseData, index) {
    let allData = JSON.parse(localStorage.getItem('adminLandingData'))
    let updatedData = {
        ...allData,
        data: [
            [
                responseData,
                ...allData.data[index],
            ],
            ...allData.data.slice(1),
        ]
    };
    return updatedData
}

export function updateToLanding(responseData, index, id) {
    let allData = JSON.parse(localStorage.getItem('adminLandingData'));
    const temp = allData.data[index].map(item => (item._id == id ? responseData : item));
    let updatedData = {
        ...allData,
        data: [
            temp,
            ...allData.data.slice(1),
        ]
    };
    return updatedData
}

export function DuplicateArrayRemoved(arr) {
    let string = arr.map((e) => e && e.toString())
    return [...new Set(string)];
}

export function GetManagementUserStatus(managementdata, userId, categoryName, role) {
    let multipleUserId = (managementdata && managementdata.multipleuser) ? managementdata.multipleuser : []
    let individualUserId = (managementdata && managementdata.individualuser) ? managementdata.individualuser : []
    let userlist = DuplicateArrayRemoved((multipleUserId).concat(individualUserId));
    let status = false;
    if (role && ImportedURL.SUPERADMIN.includes(role)) {
        status = true;
    } else if (userId) {
        if (userlist && userlist.length > 0 && userlist.includes(userId.toString())) {
            status = true;
        }
    } else {
        if (ImportedURL.MANAGEMENTCATEGORY.includes(categoryName)) {
            status = true;
        }
    }
    return status
}
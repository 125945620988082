import moment from 'moment';
import React, { Component } from 'react'
import { Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AC_SETTING_ROOMTYPE_HANDLE_CHANGE, AC_VIEW_SETTING_ROOMTYPE, AC_EMPTY_ROOMTYPE } from '../../actions/settingRoomtypeAction';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import ImportedURL from '../../common/api';
import Carousel from 'react-multi-carousel';
import ViewAmenitycontent from './viewamenitycontent';
import { AC_LIST_CONFIGOPTIONS_BED_TYPE, AC_LIST_CONFIGOPTIONS_MATTRESS } from '../../actions/configurationAction';
import { onErrorImage } from '../../common/validate';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

class ViewGuest extends Component {
    constructor(props) {
        super(props);
        this.state = {
            viewData: ''
        }
    }
    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.EmptyRoomType();
        this.props.ListBedTypeConfigOption({ parentslug: 'room-type-beds' });
        this.props.ListMattressConfigOption({ parentslug: 'mattress' });
        this.props.ViewRoomtype(id);
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    handleback = e => {
        this.props.HandleInputChange('tabposition', 'roomtype');
    }
    render() {
        const { AccountState, ConfigurationState } = this.props;
        const account = AccountState.account;
        const languageData = AccountState.account.SelectedlanguageData
        let data = this.props.SettingRoomtypeState.room;
        // let viewposition = this.props.SettingRoomtypeState.viewPositionReport;
        let spinner = this.props.SettingRoomtypeState.spinner;
        var BedTypeConfigOptions = ConfigurationState.listBedTypeConfigOptions;
        var MattressConfigOptions = ConfigurationState.listMattressConfigOptions;
        var itemArray = [];
        if (data.itemvalue && data.itemvalue.length > 0) {
            data.itemvalue.map((item, i) => {
                if (item.bedname || item.width || item.length || item.quantity || item.mattress) {
                    var selectedRoomtypeBeds = ''
                    var selectedRoomMattress = ''
                    if (BedTypeConfigOptions && BedTypeConfigOptions.length > 0) {
                        BedTypeConfigOptions.map((e) => {
                            if (item.bedname == e._id) {
                                selectedRoomtypeBeds = e.name
                            }
                        })
                    }
                    if (MattressConfigOptions && MattressConfigOptions.length > 0) {
                        MattressConfigOptions.map((e) => {
                            if (item.mattressname == e._id) {
                                selectedRoomMattress = e.name
                            }
                        })
                    }
                    itemArray.push(
                        <tr key={i}>
                            <td style={{ width: '210px' }}>
                                <div className="form-group" style={{ marginBottom: '0px' }}>
                                    <label style={{ textAlign: "center" }} className="form-label">{selectedRoomtypeBeds ? selectedRoomtypeBeds : '---'}</label>
                                </div>
                            </td>
                            <td>
                                <div className="form-group" style={{ marginBottom: '0px' }}>
                                    <div className="form-group" style={{ marginBottom: '0px' }}>
                                        <label style={{ textAlign: "center" }} className="form-label">{item.width ? item.width : "---"}</label>
                                    </div>
                                </div>
                            </td>
                            <td>
                                <div className="form-group" style={{ marginBottom: '0px' }}>
                                    <div className="form-group" style={{ marginBottom: '0px' }}>
                                        <label style={{ textAlign: "center" }} className="form-label">{item.length ? item.length : '---'}</label>
                                    </div>
                                </div>
                            </td>
                            <td style={{ width: '210px' }}>
                                <div className="form-group" style={{ marginBottom: '0px' }}>
                                    <label style={{ textAlign: "center" }} className="form-label">{selectedRoomMattress ? selectedRoomMattress : '---'}</label>
                                </div>
                            </td>
                            <td>
                                <div className="form-group" style={{ marginBottom: '0px' }}>
                                    <div className="form-group" style={{ marginBottom: '0px' }}>
                                        <label style={{ textAlign: "center" }} className="form-label">{item.quantity ? item.quantity : '---'}</label>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    )
                }
            })
        }
        if (data.roomimage && data.roomimage.length > 0) {
            var imagesData = data.roomimage
            var testArray = [];
            if (imagesData && imagesData != '') {
                imagesData.map((data, i) => {
                    testArray.push(<img key={i} src={ImportedURL.FILEURL + data} style={{ width: '60px', height: '60px', marginRight: '15px' }} onError={onErrorImage} />)
                })
            }
        }

        // ----------------------------------------------------------------
        const contentRoomType = []
        const contentView = []
        const contentBathroom = []
        const contentBedroom = []
        const contentKitchen = []
        const contentTVInternetVideo = []
        const contentElectrical = []
        const contentAccessibility = []
        const contentFloor = []
        const contentOutdoors = []
        if (data.hotel) {
            data.viewamenity && data.viewamenity.map((item1, i) => {
                if (item1.amenity.type == 'Room Type') {
                    contentRoomType.push(
                        <>
                            <ViewAmenitycontent item1={item1} />
                        </>
                    )
                } else if (item1.amenity.type == 'View') {
                    contentView.push(
                        <>
                            <ViewAmenitycontent item1={item1} />
                        </>
                    )
                } else if (item1.amenity.type == 'Bathroom') {
                    contentBathroom.push(
                        <>
                            <ViewAmenitycontent item1={item1} />
                        </>
                    )
                } else if (item1.amenity.type == 'Bedroom') {
                    contentBedroom.push(
                        <>
                            <ViewAmenitycontent item1={item1} />
                        </>
                    )
                } else if (item1.amenity.type == 'Kitchen') {
                    contentKitchen.push(
                        <>
                            <ViewAmenitycontent item1={item1} />
                        </>
                    )
                } else if (item1.amenity.type == 'TV,Internet,Video') {
                    contentTVInternetVideo.push(
                        <>
                            <ViewAmenitycontent item1={item1} />
                        </>
                    )
                } else if (item1.amenity.type == 'Electrical Appliances') {
                    contentElectrical.push(
                        <>
                            <ViewAmenitycontent item1={item1} />
                        </>
                    )
                } else if (item1.amenity.type == 'Accessibility') {
                    contentAccessibility.push(
                        <>
                            <ViewAmenitycontent item1={item1} />
                        </>
                    )
                } else if (item1.amenity.type == 'Floor') {
                    contentFloor.push(
                        <>
                            <ViewAmenitycontent item1={item1} />
                        </>
                    )
                } else if (item1.amenity.type == 'Outdoors') {
                    contentOutdoors.push(
                        <>
                            <ViewAmenitycontent item1={item1} />
                        </>
                    )
                }
            })
        }
        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span> <i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-setting-roomtype"><h6>{((languageData && languageData.ROOM_TYPE_SETTINGS) ? languageData.ROOM_TYPE_SETTINGS + " List" : "Room Type List")}</h6></Link> <span> <i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>{((languageData && languageData.ROOM_TYPE_SETTINGS) ? "View " + languageData.ROOM_TYPE_SETTINGS : "View Room Type")}</h6></p>
                        </div>
                        <div className="section-body  pt-3">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div
                                        className="tab-pane fade show active"
                                        id="Departments-list"
                                        role="tabpanel"
                                    >
                                        <div className="card common_print_pageg">
                                            <div className="card-body">
                                                <div className="header-action">
                                                    <Link to={'/list-setting-roomtype'}><button type="button" className="btn btn-primary d-flex  align-items-center" id='Add' onClick={this.handleback}><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                                </div>
                                                <div className='card-title d-flex'>
                                                    <div className='rounded_icon'><i className="icon-home mr-2 "></i></div>
                                                    <h3 className="card-title " style={{ marginTop: '10px' }}>{((languageData && languageData.ROOM_TYPE_SETTINGS) ? "View " + languageData.ROOM_TYPE_SETTINGS : "View Room Type")}</h3>
                                                </div>
                                                <div className="card-body border-0" id='print_page'>
                                                    <div className="alignment_fix_roomins">
                                                        {data.hotel ?
                                                            <div className="card c_grid c_yellow">
                                                                <div className="card-body text-center view_hotel_card">
                                                                    <Row>
                                                                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                                                            <div className='top_img_guestdetails'>
                                                                                <div className="img_size">
                                                                                    <img src={ImportedURL.FILEURL + data.logo} alt="fake_url" className='contain_image' onError={onErrorImage} />
                                                                                </div>
                                                                                <div className='text-left'>
                                                                                    <h6 className="mt-5 mb-0">{(data.hotelName !== undefined) || (data.hotelName != null) ? data.hotelName : ''}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Row>
                                                                </div>
                                                            </div>
                                                            : ''}

                                                        <div className="view_hotel_sec card c_grid c_yellow ">
                                                            <div className='card-print'>
                                                                <Row>
                                                                    {imagesData && imagesData.length ?
                                                                        <div className='col-xl-12 col-md-12 col-sm-12 col-12'>
                                                                            <div className="card">
                                                                                <div className="card-header">
                                                                                    <h3 className="card-title">Roomtype Images</h3>
                                                                                </div>
                                                                                <div className="card-body">
                                                                                    <Carousel responsive={responsive}>
                                                                                        {imagesData ? imagesData.map((data, i) => (
                                                                                            <div className='hot_img_size' style={{ width: "100%", height: '250px' }} key={i} >
                                                                                                <img src={ImportedURL.FILEURL + data} alt='Not found' onError={onErrorImage} />
                                                                                            </div>
                                                                                        )
                                                                                        ) : <div>
                                                                                            <p>No data</p>
                                                                                        </div>}
                                                                                    </Carousel>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                        : []}
                                                                    <div className="Over_all_table_style col-lg-12 col-md-12">
                                                                        <div className='reserve_detailss'>
                                                                            <div className='card-view-pms'>
                                                                                <div style={{ borderBottom: '1px solid #cccccc' }}></div>
                                                                                <div className='card-body'>
                                                                                    <div className='reserve_details'>
                                                                                        <div className='row'>
                                                                                            <div className='col-lg-4'>
                                                                                                <h4>Room Name</h4>
                                                                                                <p>{data.roomname ? data.roomname : '---'}</p>
                                                                                            </div>
                                                                                            <div className='col-lg-4'>
                                                                                                <h4>Built Unit</h4>
                                                                                                <p>{data.builtunit ? data.builtunit : '---'}</p>
                                                                                            </div>
                                                                                            <div className='col-lg-4'>
                                                                                                <h4>Max Occupancy</h4>
                                                                                                <p>{data.maxoccupancy ? data.maxoccupancy : '---'}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='reserve_details'>
                                                                                        <div className='row'>
                                                                                            <div className='col-lg-4'>
                                                                                                <h4>Default Occupancy</h4>
                                                                                                <p>{data.defaultoccupancy ? data.defaultoccupancy : '---'}</p>
                                                                                            </div>
                                                                                            <div className='col-lg-4'>
                                                                                                <h4>Article Number</h4>
                                                                                                <p>{data.articlenumber ? data.articlenumber : '---'}</p>
                                                                                            </div>
                                                                                            <div className='col-lg-4'>
                                                                                                <h4>Max Adults</h4>
                                                                                                <p>{data.adults ? data.adults : '---'}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='reserve_details'>
                                                                                        <div className='row'>
                                                                                            <div className='col-lg-4'>
                                                                                                <h4>Max Children</h4>
                                                                                                <p>{data.children ? data.children : '---'}</p>
                                                                                            </div>
                                                                                            <div className='col-lg-4'>
                                                                                                <h4>Description</h4>
                                                                                                <p>{data.description ? <div dangerouslySetInnerHTML={{ __html: data.description }}></div> : "---"}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {itemArray && itemArray.length > 0
                                                                        ?
                                                                        <div className='mt-3 pl-0 pr-0'>
                                                                            <div className="card-header">
                                                                                <h3 className="card-title">Bed Details</h3>
                                                                            </div>
                                                                            <div className="Over_all_table_style col-md-12 col-md-12 pl-0 pr-0">
                                                                                <table class="table table-borderless">
                                                                                    <thead>
                                                                                        <tr style={{ textAlign: "center" }}>
                                                                                            <th>Bed Name</th>
                                                                                            <th>Width (cm)</th>
                                                                                            <th>Length (cm)</th>
                                                                                            <th>Mattress</th>
                                                                                            <th>Quantity</th>
                                                                                        </tr>
                                                                                    </thead>
                                                                                    <tbody>
                                                                                        {itemArray}
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>
                                                                        </div>
                                                                        : ''}
                                                                    {(contentRoomType && contentRoomType.length > 0 || contentView && contentView.length > 0 || contentBathroom && contentBathroom.length > 0 || contentBedroom && contentBedroom.length > 0 || contentKitchen && contentKitchen.length > 0 || contentTVInternetVideo && contentTVInternetVideo.length > 0 || contentElectrical && contentElectrical.length > 0 || contentAccessibility && contentAccessibility.length > 0 || contentFloor && contentFloor.length > 0 || contentOutdoors && contentOutdoors.length > 0)
                                                                        ?
                                                                        < div className="card-header mt-3">
                                                                            <h3 className="card-title">Facilities</h3>
                                                                        </div>
                                                                        : ''}
                                                                    {contentRoomType && contentRoomType.length > 0 ?
                                                                        <>
                                                                            <div className="row ml-0 mr-0 pl-0 mt-4">
                                                                                <h3 className="card-title ml-0 mr-0 mb-1">Room Type</h3>
                                                                                {contentRoomType ? contentRoomType : ''}
                                                                            </div>
                                                                        </> : ''}
                                                                    {contentView && contentView.length > 0 ?
                                                                        <>
                                                                            <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                                <h3 className="card-title ml-0 mr-0 mb-1">View</h3>
                                                                                {contentView ? contentView : ''}
                                                                            </div>
                                                                        </> : ''}
                                                                    {contentBathroom && contentBathroom.length > 0 ?
                                                                        <>
                                                                            <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                                <h3 className="card-title ml-0 mr-0 mb-1">Bathroom</h3>
                                                                                {contentBathroom ? contentBathroom : ''}
                                                                            </div>
                                                                        </> : ''}
                                                                    {contentBedroom && contentBedroom.length > 0 ?
                                                                        <>
                                                                            <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                                <h3 className="card-title ml-0 mr-0 mb-1">Bedroom</h3>
                                                                                {contentBedroom ? contentBedroom : ''}
                                                                            </div>
                                                                        </> : ''}
                                                                    {contentKitchen && contentKitchen.length > 0 ?
                                                                        <>
                                                                            <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                                <h3 className="card-title ml-0 mr-0 mb-1">Kitchen</h3>
                                                                                {contentKitchen ? contentKitchen : ''}
                                                                            </div>
                                                                        </> : ''}
                                                                    {contentTVInternetVideo && contentTVInternetVideo.length > 0 ?
                                                                        <>
                                                                            <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                                <h3 className="card-title ml-0 mr-0 mb-1">TV,Internet,Video</h3>
                                                                                {contentTVInternetVideo ? contentTVInternetVideo : ''}
                                                                            </div>
                                                                        </> : ''}
                                                                    {contentElectrical && contentElectrical.length > 0 ?
                                                                        <>
                                                                            <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                                <h3 className="card-title ml-0 mr-0 mb-1">Electrical Appliances</h3>
                                                                                {contentElectrical ? contentElectrical : ''}
                                                                            </div>
                                                                        </> : ''}
                                                                    {contentAccessibility && contentAccessibility.length > 0 ?
                                                                        <>
                                                                            <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                                <h3 className="card-title ml-0 mr-0 mb-1">Accessibility</h3>
                                                                                {contentAccessibility ? contentAccessibility : ''}
                                                                            </div>
                                                                        </> : ''}
                                                                    {contentFloor && contentFloor.length > 0 ?
                                                                        <>
                                                                            <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                                <h3 className="card-title ml-0 mr-0 mb-1">Floor</h3>
                                                                                {contentFloor ? contentFloor : ''}
                                                                            </div>
                                                                        </> : ''}
                                                                    <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                        {contentOutdoors && contentOutdoors.length > 0 ? <h3 className="card-title ml-0 mr-0 mb-1">Outdoors</h3> : ''}
                                                                        {contentOutdoors ? contentOutdoors : ''}
                                                                    </div>

                                                                </Row>
                                                            </div>
                                                        </div>


                                                        <div className='common_footer_print'>
                                                            <p><a href=''>info@myhotelai.com</a> | <a href=''>www.myhotelai.com </a>|<a href=''>admin.myhotelai.com</a></p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
                <div id="overlay" style={{ display: spinner ? 'block' : 'none' }}></div>
                {spinner &&
                    <div className="common_loader">
                        <img className='loader_img_style_common' src='../../assets/images/load.png' />
                        <Spinner className='spinner_load_common' animation="border" variant="info" >
                        </Spinner>
                    </div>
                }
            </>
        )
    }
}

const mapStateToProps = state => ({
    SettingRoomtypeState: state.settingroomtype,
    HotelState: state.hotel,
    AccountState: state.account,
    ConfigurationState: state.configuration,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ViewRoomtype: AC_VIEW_SETTING_ROOMTYPE,
        HandleInputChange: AC_SETTING_ROOMTYPE_HANDLE_CHANGE,
        EmptyRoomType: AC_EMPTY_ROOMTYPE,
        ListBedTypeConfigOption: AC_LIST_CONFIGOPTIONS_BED_TYPE,
        ListMattressConfigOption: AC_LIST_CONFIGOPTIONS_MATTRESS,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewGuest);
const initialState = {
	commonissue: {
		title: '',
		description: '',
		status: 1,
	},
	listcommonissues: []
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'HANDLE_COMMONISSUE_CHANGE':
			return Object.assign({}, state, {
				commonissue: {
					...state.commonissue,
					[action.name]: action.value
				}
			})
		case 'RESET_COMMONISSUE':
			return Object.assign({}, state, {
				commonissue: initialState.commonissue
			})
		case 'LIST_COMMONISSUE':
			return Object.assign({}, state, {
				listcommonissues: action.payload
			})
		case 'VIEW_COMMONISSUE':
			return Object.assign({}, state, {
				commonissue: action.payload
			})
		default:
			return state;
	}
}
import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_ROOMTYPE_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_ROOMTYPE_CHANGE", name: name, value: value })
	};
}

export function AC_ADD_ROOMTYPE(formData) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.addRoomtype, formData)
			.then((res) => {
				// Success(res.statusText);
				dispatch({ type: "LIST_ROOMTYPE", payload: res.data })
			}).catch(({ response }) => { Error(response.statusText) });
	};
}

export function AC_LIST_ROOMTYPE(params = {}) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.listRoomtype, params)
			.then((res) => {
				dispatch({ type: "LIST_ROOMTYPE", payload: res.data.data, total: res.data.total, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_VIEW_ROOMTYPE(id) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.viewRoomtype + "/" + id)
			.then((res) => {
				dispatch({ type: "VIEW_ROOMTYPE", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_UPDATE_ROOMTYPE(formData, id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.updateRoomtype + "/" + id, formData)
			.then((res) => {
				Success(res.statusText);
				dispatch({ type: "LIST_ROOMTYPE", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

// export function AC_DELETE_ROOMTYPE(id) {
// 	return function (dispatch) {
// 		return axios.post(ImportedURL.API.deleteRoomtype + "/" + id)
// 			.then((res) => {
// 				Success(res.statusText);
// 				dispatch({ type: "LIST_ROOMTYPE", payload: res.data })
// 			}).catch((err) => { console.log(err); });
// 	};
// }

export function AC_RESET_ROOMTYPE() {
	return function (dispatch) {
		dispatch({ type: "RESET_ROOMTYPE" })
	};
}
export function AC_EMPTY_ROOMTYPE() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_ROOMTYPE" })
	}
}
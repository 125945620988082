import axios from "axios";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from 'react-router-dom';
import { bindActionCreators } from "redux";
import Select from 'react-select';
import { AC_LIST_USER_OWNER, AC_ADD_HOTEL, AC_HANDLE_HOTEL_MULTIPLE_UPDATE, AC_HANDLE_HOTEL_CHANGE, AC_HANDLE_HOTEL_CHANGE_MULTIPLE, AC_LIST_HOTEL, AC_RESET_HOTEL, AC_UPDATE_HOTEL, AC_VIEW_HOTEL, AC_EMPTY_HOTEL, AC_HANDLE_HOTEL_ELFSIGHTS_MULTIPLE_UPDATE, AC_HANDLE_HOTEL_ELFSIGHTS_CHANGE, AC_HANDLE_HOTEL_SOCAILMEDIA_CHANGE } from '../../actions/hotelAction';
import ImportedURL from '../../common/api';
import { Success, Error } from '../../common/swal';
import CONSTANTS from "../../common/constants";
import { Emailvalidate, Imagevalidation, ListLandingUpdateData, addToLanding, onErrorImage, updateToLanding } from '../../common/validate';
import Spinner from 'react-bootstrap/Spinner';
import { Phonenumber, Urlvalidate } from "../../common/validate";
import moment from 'moment';
import {
    TimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { AC_PUSH_DATA_LANDING } from "../../actions/landing";
import validator from 'validator';
import TimeComponent from "../../utilities/Time/time";

class AddHotel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalType: "Add",
            groupError: false,
            nameError: false,
            emailError: false,
            emailExistValidError: false,
            emailValidError: false,
            gmemailError: false,
            gmemailValidError: false,
            owneremailError: false,
            owneremailValidError: false,
            hotelidError: false,
            id: "",
            communicationemail: '',
            communicationemailValidError: false,
            images: [],
            imageArray: [],
            logo: '',
            card: '',
            logoSrc: '',
            cardSrc: '',
            hotelOpt: 'newhotel',
            existingowner: false,
            contactperson: false,
            ListArrayHotel: [],
            ccError: false,
            ccownerError: false,
            latitudeError: false,
            longitudeError: false,
            radiusError: false,
            existingImage: [],
            urlIndex: [],
            socialMediaIndex: [],
            defaultCC: '',
            defaultCCOwner: '',
            defaultCCGm: '',
            defaultCCPerson: '',
            ccgmError: false,
            ccpersonError: false,
            buttonView: true,

            tripadvisorURL: false,
            tiktokURL: false, youtubeError: false, pinterestError: false, instagramError: false, twitterError: false, websiteError: false, linkedinError: false, bookingurlError: false,
            facebookURL: false,
            googleURL: false,
            facebookError: false,
            hotelsURL: false,
            bookingURL: false,
            yelpURL: false,
            mainTripadvisorURL: false,
            mainTgoogleURL: false,
            viewTripadvisorURL: false,
            viewTgoogleURL: false,

            initial: true,
            saving: false,
            phoneNoPatternError: false,
            ownerphonepatternError: false,
            gmphoneError: false,
            contactphoneError: false,
            emailValidError: false,
            spinner: false,
            timezoneError: false,
            messagingError: false,
            cctwilloValidError: false,
            radiusNumError: false,
            shiftinArrayError: [],
            shiftoutArrayError: [],
            shiftArrayError: [],
            currentdate: new Date()
        };
        this.groupinputRef = React.createRef();
        this.nameinputRef = React.createRef();
        this.emailinputRef = React.createRef();
        this.hotelidinputRef = React.createRef();
        this.phonenumberinputRef = React.createRef();
        this.addressinputRef = React.createRef();
        this.cityinputRef = React.createRef();
        this.stateinputRef = React.createRef();
        this.postcodeinputRef = React.createRef();
        this.countryinputRef = React.createRef();
        this.radiusinputRef = React.createRef();
        this.longitudeinputRef = React.createRef();
        this.latitudeinputRef = React.createRef();
        this.mainTripadvisorURLinputRef = React.createRef();
        this.viewTgoogleURLinputRef = React.createRef();
        this.viewTripadvisorURLinputRef = React.createRef();
        this.mainTgoogleURLinputRef = React.createRef();
        this.cardinputRef = React.createRef();
        this.logoinputRef = React.createRef();
        this.ownernameinputRef = React.createRef();
        this.owneremailinputRef = React.createRef();
        this.ownerphoneinputRef = React.createRef();
        this.twilloinputRef = React.createRef();
        this.timezoneinputRef = React.createRef();
    }

    componentWillUpdate(nextProps) {
        const newaccount = nextProps.AccountState.account.phonecode;
        if (this.state.initial) {
            if (newaccount) {
                this.props.HandleInputChange('cc', newaccount);
                this.props.HandleInputChange('ccowner', newaccount);
                this.props.HandleInputChange('ccgm', newaccount);
                this.props.HandleInputChange('ccperson', newaccount);
            }
            this.setState({ initial: false })
        }
    }


    setValue(event) {
        this.setState({ city: event.target.value })
        this.props.HandleInputChange('city', event.target.value);
    }
    onChangeValue(event) {
        const name = "city";
        const value = event;
        this.props.HandleInputChange(name, event.formatted_address.split(',')[0]);
        this.props.HandleInputChange('location', { lat: event.geometry.location.lat(), lon: event.geometry.location.lng() });
        this.setState({ city: event.formatted_address.split(',')[0] })
    }
    onChangeNumberOnly = e => {
        this.setState({ spinner: false })
        const { name, value } = e.target;
        if (name == 'radius') {
            var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if ((val)) {
                this.setState({ radiusNumError: false, radiusError: false });
                this.props.HandleInputChange('radius', val)
            } else {
                this.setState({ radiusNumError: true, radiusError: false, });
                this.props.HandleInputChange('radius', val)
            }
        }
    }
    onChange = e => {
        const { AccountState, LandingState } = this.props
        const hotelList = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 0 ? LandingState.landingdata.data[0] : [];
        const languageData = AccountState.account.SelectedlanguageData

        this.setState({ spinner: false })
        const { name, value } = e.target;
        const fieldError = name + "Error";

        if (name === 'communicationemail') {
            let communicationemailValidError = false;
            if (value) {
                if (!validator.isEmail(value)) {
                    communicationemailValidError = true;
                } else {
                    const tld = value.split('.').pop();
                    if (tld.length < 2 || tld.length > 3) {
                        communicationemailValidError = true;
                    }
                }
                this.setState({ communicationemail: value });
            } else {
                this.setState({ communicationemailValidError: false })
                this.setState({ communicationemail: value });
            }
            this.setState({ communicationemailValidError: communicationemailValidError });
        }
        else if (name == 'image') {
            this.setState({ images: [...this.state.images, ...e.target.files] });
            const files = Array.from(e.target.files);
            const newfiles = [];
            for (var i = 0; i < files.length; i++) {
                var file = files[i];
                const imgvalidate = Imagevalidation(file);
                if (imgvalidate) {
                    newfiles.push(file)
                } else {
                    Error('Invalid file is discarded');
                }
            }
            Promise.all(files.map(file => {
                return (new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.addEventListener('load', (ev) => {
                        resolve(ev.target.result);
                    });
                    reader.addEventListener('error', reject);
                    reader.readAsDataURL(file);
                }));
            }))
                .then(images => {
                    if (images.length > 0) {
                        images.map((item) => {
                            this.setState({ imageArray: [...this.state.imageArray, item] })
                        })
                    }
                }, error => {
                    console.error(error);
                });
        }
        else if (name == 'logo') {
            const imgvalidate = Imagevalidation(e.target.files[0]);
            if (imgvalidate) {
                this.setState({ logoError: false })
                this.setState({ logo: e.target.files[0] });
                var reader = new FileReader();
                var url = reader.readAsDataURL(e.target.files[0]);
                reader.onloadend = function (e) {
                    this.setState({
                        logoSrc: [reader.result]
                    })
                }.bind(this);
                this.props.HandleInputChange('logo', e.target.files[0]);
            } else {
                Error('Invalid file extension');
            }
        }
        else if (name == 'card') {
            const imgvalidate = Imagevalidation(e.target.files[0]);
            if (imgvalidate) {
                this.setState({ cardError: false });
                this.setState({ card: e.target.files[0] });
                var reader = new FileReader();
                var url = reader.readAsDataURL(e.target.files[0]);
                reader.onloadend = function (e) {
                    this.setState({
                        cardSrc: [reader.result]
                    })
                }.bind(this);
                this.props.HandleInputChange('card', e.target.files[0]);
            } else {
                Error('Invalid file extension');
            }

        }
        else if (name == 'lat' || name == 'lon') {
            const hotelInfo = this.props.HotelState.hotel;
            this.setState({ [fieldError]: false });
            this.props.HandleInputChange('location', { ...hotelInfo.location, [name]: value });
        }
        else if (name === 'phonenumber') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var s3 = s2.slice(0, 10);
            var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                this.setState({ [fieldError]: false });
                if (Phonenumber(val)) {
                    this.setState({ phoneNoPatternError: false })
                } else {
                    this.setState({ phoneNoPatternError: true })
                }
                this.props.HandleInputChange(name, val);
            } else {
                this.setState({ phoneNoPatternError: false })
                this.props.HandleInputChange(name, val);
            }
        }
        else if (name === 'ownerphone') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var s3 = s2.slice(0, 10);
            var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                this.setState({ [fieldError]: false });
                if (Phonenumber(val)) {
                    this.setState({ ownerphonepatternError: false })
                } else {
                    this.setState({ ownerphonepatternError: true })
                }
                this.props.HandleInputChange(name, val);
            } else {
                this.setState({ ownerphonepatternError: false })
                this.props.HandleInputChange(name, val);
            }
        }
        else if (name === 'gmphone') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var s3 = s2.slice(0, 10);
            var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                if (Phonenumber(val)) {
                    this.setState({ gmphoneError: false })
                } else {
                    this.setState({ gmphoneError: true })
                }
                this.props.HandleInputChange(name, val);
            } else {
                this.setState({ gmphoneError: false })
                this.props.HandleInputChange(name, val);
            }

        } else if (name === 'contactphone') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var s3 = s2.slice(0, 10);
            var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                if (Phonenumber(val)) {
                    this.setState({ contactphoneError: false })
                } else {
                    this.setState({ contactphoneError: true })
                }
                this.props.HandleInputChange(name, val);
            } else {
                this.setState({ contactphoneError: false })
                this.props.HandleInputChange(name, val);
            }
        } else if (name === 'email') {
            let emailValidError = false;
            if (value) {
                this.setState({ [fieldError]: false });
                if (!validator.isEmail(value)) {
                    emailValidError = true;
                } else {
                    const tld = value.split('.').pop();
                    if (tld.length < 2 || tld.length > 3) {
                        emailValidError = true;
                    }
                    let exHotelEmail = hotelList && hotelList.find(e => {
                        return (e.email === value && e._id != this.state.id)
                    });
                    if (exHotelEmail) {
                        this.setState({ emailExistValidError: true, emailValidError: false })
                    } else {
                        this.setState({ emailExistValidError: false })
                    }
                }
                this.props.HandleInputChange(name, value);
            } else {
                this.setState({ [fieldError]: false, emailValidError: false, emailExistValidError: false })
                this.props.HandleInputChange(name, value);
            }
            this.setState({ emailValidError: emailValidError });
        }
        else if (name === 'gmemail') {
            if (value) {
                this.setState({ [fieldError]: false });
                if (Emailvalidate(value)) {
                    this.setState({ gmemailValidError: false })
                } else {
                    this.setState({ gmemailValidError: true })
                }
                this.props.HandleInputChange(name, value);
            } else {
                this.setState({ [fieldError]: false, gmemailValidError: false })
                this.props.HandleInputChange(name, value);
            }
        } else if (name === 'owneremail') {
            let owneremailValidError = false;
            if (value) {
                this.setState({ [fieldError]: false });
                if (!validator.isEmail(value)) {
                    owneremailValidError = true;
                } else {
                    const tld = value.split('.').pop();
                    if (tld.length < 2 || tld.length > 3) {
                        owneremailValidError = true;
                    }
                }
                this.props.HandleInputChange(name, value);
            } else {
                this.setState({ [fieldError]: false, owneremailValidError: false })
                this.props.HandleInputChange(name, value);
            }
            this.setState({ owneremailValidError: owneremailValidError })
        } else if (name === 'messaging') {
            var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
            if (val) {
                this.setState({ messagingError: false })
            } else {
                this.setState({ messagingError: true })
            }
            this.props.HandleInputChange(name, val);
        } else if (name === 'name') {
            if (value.length <= 80) {
                this.props.HandleInputChange(name, value);
            } else {
                Error(languageData && languageData.MAXIMUM_CHAR_LIMIT_REACHED ? languageData.MAXIMUM_CHAR_LIMIT_REACHED : "Maximum character limit reached")
            }
        } else if (name === 'address') {
            if (value.length <= 150) {
                this.props.HandleInputChange(name, value);
            } else {
                Error(languageData && languageData.MAXIMUM_CHAR_LIMIT_REACHED ? languageData.MAXIMUM_CHAR_LIMIT_REACHED : "Maximum character limit reached")
            }
        } else if (name === 'state') {
            if (value.length <= 30) {
                this.props.HandleInputChange(name, value);
            } else {
                Error(languageData && languageData.MAXIMUM_CHAR_LIMIT_REACHED ? languageData.MAXIMUM_CHAR_LIMIT_REACHED : "Maximum character limit reached")
            }
        } else if (name === 'city') {
            if (value.length <= 30) {
                this.props.HandleInputChange(name, value);
            } else {
                Error(languageData && languageData.MAXIMUM_CHAR_LIMIT_REACHED ? languageData.MAXIMUM_CHAR_LIMIT_REACHED : "Maximum character limit reached")
            }
        } else if (name === 'country') {
            if (value.length <= 60) {
                this.props.HandleInputChange(name, value);
            } else {
                Error(languageData && languageData.MAXIMUM_CHAR_LIMIT_REACHED ? languageData.MAXIMUM_CHAR_LIMIT_REACHED : "Maximum character limit reached")
            }
        } else if (name === 'postcode') {
            if (value.length <= 12) {
                this.props.HandleInputChange(name, value);
            } else {
                Error(languageData && languageData.MAXIMUM_CHAR_LIMIT_REACHED ? languageData.MAXIMUM_CHAR_LIMIT_REACHED : "Maximum character limit reached")
            }
        }
        else {
            this.setState({ [fieldError]: false });
            this.props.HandleInputChange(name, value);
        }

        if (name == 'elfsighttripsadvisorurl') {
            if (value) {
                if (!Urlvalidate(value)) {
                    this.setState({ tripadvisorURL: true })
                } else {
                    this.setState({ tripadvisorURL: false })
                }
            } else {
                this.setState({ tripadvisorURL: false })
            }
        }
        if (name == 'elfsightgoogleurl') {
            if (value) {
                if (!Urlvalidate(value)) {
                    this.setState({ googleURL: true })
                } else {
                    this.setState({ googleURL: false })
                }
            } else {
                this.setState({ googleURL: false })
            }
        }
        if (name == 'elfsightfacebookurl') {
            if (value) {
                if (!Urlvalidate(value)) {
                    this.setState({ facebookURL: true })
                } else {
                    this.setState({ facebookURL: false })
                }
            } else {
                this.setState({ facebookURL: false })
            }
        }
        if (name == 'elfsighthotelsurl') {
            if (value) {
                if (!Urlvalidate(value)) {
                    this.setState({ hotelsURL: true })
                } else {
                    this.setState({ hotelsURL: false })
                }
            } else {
                this.setState({ hotelsURL: false })
            }
        }
        if (name == 'elfsightbookingurl') {
            if (value) {
                if (!Urlvalidate(value)) {
                    this.setState({ bookingURL: true })
                } else {
                    this.setState({ bookingURL: false })
                }
            } else {
                this.setState({ bookingURL: false })
            }
        }
        if (name == 'elfsightyelpurl') {
            if (value) {
                if (!Urlvalidate(value)) {
                    this.setState({ yelpURL: true })
                } else {
                    this.setState({ yelpURL: false })
                }
            } else {
                this.setState({ yelpURL: false })
            }
        }
        if (name == 'googlereviewurl') {
            if (value) {
                if (!Urlvalidate(value)) {
                    this.setState({ mainTripadvisorURL: true })
                } else {
                    this.setState({ mainTripadvisorURL: false })
                }
            } else {
                this.setState({ mainTripadvisorURL: false })
            }
        }
        if (name == 'tripsadvisorurl') {
            if (value) {
                if (!Urlvalidate(value)) {
                    this.setState({ mainTgoogleURL: true })
                } else {
                    this.setState({ mainTgoogleURL: false })
                }
            } else {
                this.setState({ mainTgoogleURL: false })
            }
        }
        if (name == 'googlereviewviewurl') {
            if (value) {
                if (!Urlvalidate(value)) {
                    this.setState({ viewTgoogleURL: true })
                } else {
                    this.setState({ viewTgoogleURL: false })
                }
            } else {
                this.setState({ viewTgoogleURL: false })
            }
        }
        if (name == 'tripsadvisorviewurl') {
            if (value) {
                if (!Urlvalidate(value)) {
                    this.setState({ viewTripadvisorURL: true })
                } else {
                    this.setState({ viewTripadvisorURL: false })
                }
            } else {
                this.setState({ viewTripadvisorURL: false })
            }
        }
        if (name == 'tiktok') {
            if (value) {
                if (!Urlvalidate(value)) {
                    this.setState({ tiktokURL: true })
                } else {
                    this.setState({ tiktokURL: false })
                }
            } else {
                this.setState({ tiktokURL: false })
            }
        }
        if (name == 'linkedin') {
            if (value) {
                if (Urlvalidate(value)) {
                    this.setState({ linkedinError: false })
                } else {
                    this.setState({ linkedinError: true })
                }
            } else {
                this.setState({ linkedinError: false })
            }
        }
        if (name == 'website') {
            if (value) {
                if (Urlvalidate(value)) {
                    this.setState({ websiteError: false })
                } else {
                    this.setState({ websiteError: true })
                }
            } else {
                this.setState({ websiteError: false })
            }
        }
        if (name == 'bookingurl') {
            if (value) {
                if (Urlvalidate(value)) {
                    this.setState({ bookingurlError: false })
                } else {
                    this.setState({ bookingurlError: true })
                }
            } else {
                this.setState({ bookingurlError: false })
            }
        }
        if (name == 'facebook') {
            if (value) {
                if (Urlvalidate(value)) {
                    this.setState({ facebookError: false })
                } else {
                    this.setState({ facebookError: true })
                }
            } else {
                this.setState({ facebookError: false })
            }
        }
        if (name == 'twitter') {
            if (value) {
                if (Urlvalidate(value)) {
                    this.setState({ twitterError: false })
                } else {
                    this.setState({ twitterError: true })
                }
            } else {
                this.setState({ twitterError: false })
            }
        }
        if (name == 'instagram') {
            if (value) {
                if (Urlvalidate(value)) {
                    this.setState({ instagramError: false })
                } else {
                    this.setState({ instagramError: true })
                }
            } else {
                this.setState({ instagramError: false })
            }
        }
        if (name == 'pinterest') {
            if (value) {
                if (Urlvalidate(value)) {
                    this.setState({ pinterestError: false })
                } else {
                    this.setState({ pinterestError: true })
                }
            } else {
                this.setState({ pinterestError: false })
            }
        }
        if (name == 'youtube') {
            if (value) {
                if (Urlvalidate(value)) {
                    this.setState({ youtubeError: false })
                } else {
                    this.setState({ youtubeError: true })
                }
            } else {
                this.setState({ youtubeError: false })
            }
        }
        if (name == 'mailstatus') {
            this.props.HandleInputChange(name, e.target.checked);
        }
        if (name == 'pullsocialreview') {
            this.props.HandleInputChange(name, e.target.checked);
        }
    }

    handleChange = e => {
        this.setState({ spinner: false })
        const { name, value, utc } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.HandleInputChange(name, value);
        this.props.HandleInputChange('timezoneutc', utc);
    }
    handlePhoneCode = e => {
        this.setState({ spinner: false })
        const { name, value } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        if (name == 'cc') {
            this.setState({ defaultCC: '' })
        } else if (name == 'ccowner') {
            this.setState({ defaultCCOwner: '' })
        } else if (name == 'ccgm') {
            this.setState({ defaultCCGm: '' })
        } else if (name == 'ccperson') {
            this.setState({ defaultCCPerson: '' })
        }
        this.props.HandleInputChange(name, value);

    }
    handleChangeTwillo = e => {
        this.setState({ spinner: false })
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ twilloError: false });
        let s1 = value.replace(/[^a-z\d\s]+/gi, "");
        let s2 = s1.replace(/[- )(]/g, '')
        var s3 = s2.slice(0, 10);
        var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
        if (val) {
            this.setState({ [Error]: false });
            if (Phonenumber(val)) {
                this.setState({ ownerphonetwilloError: false })
            } else {
                this.setState({ ownerphonetwilloError: true })
            }
            this.props.HandleInputChange(name, val);
        } else {
            this.setState({ ownerphonetwilloError: false })
            this.props.HandleInputChange(name, val);
        }

    }
    handleKeyDown = e => {
        this.setState({ spinner: false })
        const { value } = e.target;
        const { HotelState, AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const data = HotelState.hotel;
        if (e.key === 'Enter' && this.state.communicationemail !== '' && !this.state.communicationemailValidError) {
            let newData = [...data.communicationemail];
            if (newData.includes(value)) {
                Error((languageData && languageData.ALREADY_ADDED ? languageData.ALREADY_ADDED : " Email Already Added"))
            } else {
                newData.push(value);
                this.props.HandleInputChange("communicationemail", newData);
                this.setState({ communicationemail: '' });
            }

        }
    }
    removeEmail = (i) => {
        this.setState({ spinner: false })
        const { HotelState } = this.props;
        const data = HotelState.hotel.communicationemail;
        data.splice(i, 1);
        this.props.
            HandleInputChange("communicationemail", data);
    }
    scrollTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    }


    SubmitForm = () => {
        const { HotelState, CategoryState, history, AccountState, LandingState } = this.props;
        const accountHotel = AccountState?.account;
        const languageData = AccountState.account.SelectedlanguageData
        const data = HotelState.hotel;
        let valid = 1;
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        }

        if (this.state.ownerphonetwilloError) {
            valid = 0;
            this.twilloinputRef.current.focus();
        }
        if (!data.twillo) {
            this.setState({ twilloError: true });
            valid = 0;
            this.twilloinputRef.current.focus();
        }
        if (this.state.ownerphonepatternError) {
            valid = 0;
            this.ownerphoneinputRef.current.focus();
        }

        if (!data.ownerphone) {
            this.setState({ ownerphoneError: true });
            valid = 0;
            this.ownerphoneinputRef.current.focus();
        } else {
            this.setState({ ownerphoneError: false });
        }
        if (this.state.owneremailValidError) {
            valid = 0;
            this.owneremailinputRef.current.focus();
        }
        if (!data.owneremail) {
            this.setState({ owneremailError: true });
            valid = 0;
            this.owneremailinputRef.current.focus();
        }
        if (!data.ownername) {
            this.setState({ ownernameError: true });
            valid = 0;
            this.ownernameinputRef.current.focus();
        } else {
            this.setState({ ownernameError: false });
        }
        if (!data.card) {
            this.setState({ cardError: true });
            valid = 0;
            this.cardinputRef.current.focus();
        }
        if (!data.logo) {
            this.setState({ logoError: true });
            valid = 0;
            this.logoinputRef.current.focus();
        }
        if (this.state.mainTgoogleURL) {
            valid = 0;
            this.mainTgoogleURLinputRef.current.focus();
        }
        if (this.state.mainTripadvisorURL) {
            valid = 0;
            this.mainTripadvisorURLinputRef.current.focus();
        }
        if (this.state.viewTgoogleURL) {
            valid = 0;
            this.viewTgoogleURLinputRef.current.focus();
        }
        if (this.state.viewTripadvisorURL) {
            valid = 0;
            this.viewTripadvisorURLinputRef.current.focus();
        }
        if (!data.timezone) {
            this.setState({ timezoneError: true });
            valid = 0;
            this.timezoneinputRef.current.focus();
        }
        if (!data.longitude) {
            this.setState({ longitudeError: true });
            valid = 0;
            this.longitudeinputRef.current.focus();
        }
        if (!data.latitude) {
            this.setState({ latitudeError: true });
            valid = 0;
            this.latitudeinputRef.current.focus();
        }
        if (!data.radius) {
            this.setState({ radiusError: true, radiusNumError: false });
            valid = 0;
            this.radiusinputRef.current.focus();
        }
        if (!data.country) {
            this.setState({ countryError: true });
            valid = 0;
            this.countryinputRef.current.focus();
        }
        if (!data.postcode) {
            this.setState({ postcodeError: true });
            valid = 0;
            this.postcodeinputRef.current.focus();
        }
        if (!data.state) {
            this.setState({ stateError: true });
            valid = 0;
            this.stateinputRef.current.focus();
        }
        if (!data.city) {
            this.setState({ cityError: true });
            valid = 0;
            this.cityinputRef.current.focus();
        }
        if (!data.address) {
            this.setState({ addressError: true });
            valid = 0;
            this.addressinputRef.current.focus();
        }
        if (this.state.phoneNoPatternError) {
            valid = 0;
            this.phonenumberinputRef.current.focus();
        }
        if (!data.phonenumber) {
            this.setState({ phonenumberError: true });
            valid = 0;
            this.phonenumberinputRef.current.focus();
        }
        if (this.state.emailValidError) {
            valid = 0;
            this.emailinputRef.current.focus();
        }
        if (this.state.emailExistValidError) {
            valid = 0;
            this.emailinputRef.current.focus();
        }
        if (!data.email) {
            this.setState({ emailError: true });
            valid = 0;
            this.emailinputRef.current.focus();
        }
        if (!data.hotelid) {
            this.setState({ hotelidError: true });
            valid = 0;
            this.hotelidinputRef.current.focus();
        }
        if (!data.name) {
            this.setState({ nameError: true });
            valid = 0;
            this.nameinputRef.current.focus();
        }
        if (!data.group) {
            this.setState({ groupError: true });
            valid = 0;
            this.groupinputRef.current.focus();
        }





        if (this.state.gmphoneError) {
            valid = 0;
        }
        if (this.state.contactphoneError) {
            valid = 0;
        }
        if (this.state.communicationemailValidError) {
            valid = 0;
        }
        // -----------------------------------------------------------------------------------
        if (this.state.gmemailValidError) {
            valid = 0;
        }
        if (!data.cc && this.state.defaultCC == '') {
            this.setState({ ccError: true });
            valid = 0;
        }
        if (!data.ccowner && this.state.defaultCCOwner == '') {
            this.setState({ ccownerError: true });
            valid = 0;
        }
        if (data.gmphone && this.state.defaultCCGm == '') {
            if (!data.ccgm) {
                this.setState({ ccgmError: true });
                valid = 0;
            }
        }
        if (data.contactphone) {
            if (!data.ccperson && this.state.defaultCCPerson == '') {
                this.setState({ ccpersonError: true });
                valid = 0;
            }
        }
        if (this.state.tiktokURL) {
            valid = 0;
        }

        if (this.state.yelpURL) {
            valid = 0;
        }
        if (this.state.bookingURL) {
            valid = 0;
        }
        if (this.state.hotelsURL) {
            valid = 0;
        }
        if (this.state.googleURL) {
            valid = 0;
        }
        if (this.state.facebookError) {
            valid = 0;
        }
        if (this.state.bookingurlError) {
            valid = 0;
        }
        if (this.state.linkedinError) {
            valid = 0;
        }
        if (this.state.websiteError) {
            valid = 0;
        }
        if (this.state.twitterError) {
            valid = 0;
        }
        if (this.state.instagramError) {
            valid = 0;
        }
        if (this.state.pinterestError) {
            valid = 0;
        }
        if (this.state.youtubeError) {
            valid = 0;
        }
        if (this.state.tripadvisorURL) {
            valid = 0;
        }
        if (this.state.facebookURL) {
            valid = 0;
        }
        if (this.state.urlIndex && this.state.urlIndex.length > 0) {
            valid = 0;
        }
        data.shifttime && data.shifttime.length > 0 && data.shifttime.find((element, index) => {
            if (element.shiftintime || element.shiftouttime || element.shift) {
                if (!element.shiftintime) {
                    Error("Select Shift In Time")
                    valid = 0;
                } else if (!element.shiftouttime) {
                    Error("Select Shift Out Time")
                    valid = 0;
                } else if (!element.shift) {
                    Error("Select Shift")
                    valid = 0;
                }
            }
        });
        if (valid) {
            data['location'] = data.location.filter(item => item.lon.trim() !== '' && item.lat.trim() !== '');
            data['socialreviews'] = data.socialreviews.filter(item => item.socialmediaid && item.socialmediaid !== '');
            data['socialmedias'] = data.socialmedias.filter(item => item.socialmediaid && item.socialmediaid !== '');
            this.setState({ saving: true })
            if (this.state.logo) {
                data.logo = this.state.logo;
            }
            if (this.state.card) {
                data.card = this.state.card;
            }
            const formData = new FormData();
            for (let key in data) {
                if (key === 'communicationemail') formData.append(key, JSON.stringify(data[key]));
                else if (key === 'location') formData.append(key, JSON.stringify(data[key]));
                else if (key === 'socialreviews') formData.append(key, JSON.stringify(data[key]));
                else if (key === 'socialmedias') formData.append(key, JSON.stringify(data[key]));
                else if (key === 'shifttime') formData.append(key, JSON.stringify(data[key]));
                else if (key === 'images') continue;
                else formData.append(key, data[key])
            }
            if (this.state.images.length) {
                if (this.state.existingImage.length) {
                    var sendData = this.state.images.concat(this.state.existingImage)
                    for (const file of sendData) {
                        formData.append('images', file);
                    };
                }
                else {
                    for (const file of this.state.images) {
                        formData.append('images', file);
                    };
                }
            } else {
                if (data.images) {
                    formData.append('images', JSON.stringify(data.images));
                } else {
                }
            }
            if (this.state.modalType === "Add") {
                this.setState({ buttonView: false })
                axios.post(ImportedURL.API.addHotel, formData)
                    .then((res) => {
                        // let list = ListLandingUpdateData(res.data, null, 0);
                        // this.props.LandingData(list);
                        let responseData = res.data
                        Success((languageData && languageData.HOTEL_CREATED_SUCCESSFULLY ? languageData.HOTEL_CREATED_SUCCESSFULLY : "Hotel Created Successfully"))
                        if (res.data) {
                            this.setState({ buttonView: true })
                            const categories = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[7] : [];
                            const phoneCode = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[1] : [];
                            let phoneValue = '';
                            let phoneValueGM = '';
                            if (phoneCode.length > 0) {
                                phoneCode.map(item => {
                                    if (res.data.ccowner) {
                                        if (item.code == res.data.ccowner) {
                                            phoneValue = item
                                        }
                                        if (item.phonecode == res.data.ccowner) {
                                            phoneValue = item
                                        }
                                    }
                                    if (res.data.ccgm) {
                                        if (item.code == res.data.ccgm) {
                                            phoneValueGM = item
                                        }
                                        if (item.phonecode == res.data.ccgm) {
                                            phoneValueGM = item
                                        }
                                    }
                                })
                            }
                            if (res.data.owneremail) {
                                const ownercategory = categories.find(el => el.name === ImportedURL.HOTELOWNER)
                                const newData = {
                                    hotel: res.data._id,
                                    name: res.data.ownername ? res.data.ownername : '',
                                    email: res.data.owneremail,
                                    phonenumber: res.data.ownerphone,
                                    isowner: ImportedURL.HOTELOWNER,
                                    category: ownercategory ? ownercategory._id : '',
                                    mandatory: ownercategory ? ownercategory.mandatory : '',
                                    webaccess: ownercategory ? ownercategory.webaccess : '',
                                    cc: res.data.ccowner,
                                    phonecode: phoneValue.code,
                                    currencyname: phoneValue.currencyname,
                                    currencycode: phoneValue.currencycode,
                                    currencysymbol: phoneValue.symbol,
                                    mailstatus: data.mailstatus,
                                }
                                axios.post(ImportedURL.API.addUserPrevilege, newData)
                                    .then((res) => {
                                    });
                            }
                            if (res.data.communicationemail && res.data.communicationemail.length) {
                                for (let i = 0; i < res.data.communicationemail.length; i++) {
                                    const newData = {
                                        hotel: res.data._id,
                                        email: res.data.communicationemail[i],
                                        previleges: JSON.stringify(CONSTANTS.allprevileges),
                                    }
                                }
                            }
                        }
                        axios.get(ImportedURL.API.importSocialReviews, { params: { hotel: res.data._id } })
                            .then((res) => {
                            }).catch((err) => { console.log(err); });
                        this.props.ResetHotel({ type: "RESET_HOTEL" })
                        this.setState({ saving: false })
                        this.setState({ images: [], imageArray: [], logo: '', logoSrc: '', city: '', card: '', cardSrc: '' })
                        document.getElementById('imageSet').value = '';
                        history.push("/list-hotel");
                    }).catch(({ response }) => {
                        if (response) {
                            if (response.status == 409) {
                                this.emailinputRef.current.focus();
                                Error(((languageData && languageData.EMAIL) ? languageData.EMAIL : 'Email ') + ((languageData && languageData.ALREADY_EXIST) ? languageData.ALREADY_EXIST : 'already exist'))
                            } else if (response.status == 408) {
                                Error("Hotel ID already exist")
                                this.hotelidinputRef.current.focus();
                            } else if (response.status == 400) {
                                Error((languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : ' Bad Request'))
                            } else if (response.status == 500) {
                                Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                            } else if (response.status == 502) {
                                Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                            } else {
                                Error(response.statusMessage)
                            }
                        } else {
                            Error("Bad request")
                        }
                        this.setState({ buttonView: true, saving: false })
                    });
            } else {
                this.setState({ buttonView: false })
                axios.post(ImportedURL.API.updateHotel + "/" + this.state.id, formData)
                    .then((res) => {
                        // let list = ListLandingUpdateData(res.data, this.state.id, 0);
                        // this.props.LandingData(list);
                        this.setState({ buttonView: true })
                        axios.get(ImportedURL.API.importSocialReviews, { params: { hotel: this.state.id } })
                            .then((res) => {
                            }).catch((err) => { console.log(err); });
                        Success((languageData && languageData.HOTEL_UPDATED_SUCCESSFULLY ? languageData.HOTEL_UPDATED_SUCCESSFULLY : "Hotel Updated Successfully"))
                        this.setState({ saving: false })
                        this.setState({ images: [], logo: '' })
                        this.setState({ images: [], imageArray: [], logo: '', logoSrc: '', city: '', card: '', cardSrc: '' })
                        document.getElementById('imageSet').value = '';
                        this.props.ViewHotel(this.state.id);
                        const pageno = new URLSearchParams(this.props.location.search).get('page');
                        if (accountHotel?.allhotels && accountHotel.allhotels?.length > 0) {
                            history.push("/view-hotel/" + accountHotel.hotel);
                        } else {
                            history.push("/list-hotel?page=" + pageno);
                        }
                    }).catch(({ response }) => {
                        if (response) {
                            if (response.status == 409) {
                                this.emailinputRef.current.focus();
                                Error(((languageData && languageData.EMAIL) ? languageData.EMAIL : 'Email ') + ((languageData && languageData.ALREADY_EXIST) ? languageData.ALREADY_EXIST : 'already exist'))
                            } else if (response.status == 408) {
                                Error("Hotel ID already exist")
                                this.hotelidinputRef.current.focus();
                            } else if (response.status == 400) {
                                Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                            } else if (response.status == 500) {
                                Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                            } else if (response.status == 502) {
                                Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                            } else {
                                Error(response.statusMessage)
                            }
                        } else {
                            Error("Bad request")
                        }
                        this.setState({ buttonView: true, saving: false })
                    });
            }
        }
    }
    componentDidMount() {
        this.props.EmptyHotel()
        this.props.ListUser();
        const { params, path } = this.props.match;
        const { LandingState } = this.props;
        let socialMedia = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[2] : [];
        if (params.id) {
            this.props.ViewHotel(params.id);
            this.setState({ modalType: path === "/view-hotel/:id" ? "View" : "Edit", id: params.id, spinner: true })
        } else {
            this.props.ResetHotel();
            const checkInTime = moment().set({ hour: 12, minute: 0, second: 0 });
            const checkOutTime = moment(checkInTime).add(1, 'days');
            this.props.HandleInputChange('checkintime', checkInTime);
            this.props.HandleInputChange('checkouttime', checkOutTime);
            this.props.HandleInputChange('socialreviews', socialMedia && socialMedia.length > 0 ? [{ socialmediaid: socialMedia[0]._id, socialmediaurl: '', socialmediastatus: true }] : [{ socialmediaid: '', socialmediaurl: '', socialmediastatus: true }]);
        }
        var phonecode = localStorage.getItem("phonecode");
        this.setState({ defaultCC: phonecode, defaultCCOwner: phonecode, defaultCCGm: phonecode, defaultCCPerson: phonecode, })
        //default scroll top the page
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });

        const interval = setInterval(() => {
            this.setState({ currentdate: new Date() })
        }, 1000);

        return () => clearInterval(interval);
    }

    chosseOption = e => {
        this.setState({ spinner: false })
        const data = this.props.HotelState.hotel;
        data.owneremail = '';
        data.ownerphone = '';
        data.ownername = '';
        this.setState({ hotelOpt: e.target.value })
    }
    existingOwner = e => {
        this.setState({ spinner: false })
        const { AccountState } = this.props;
        const phonecode = AccountState?.account.phonecode;
        this.props.HandleInputChange('owneremail', '');
        this.props.HandleInputChange('ownerphone', '');
        this.props.HandleInputChange('ownername', '');
        this.props.HandleInputChange('ccowner', phonecode);

        const ownerlist = this.props.HotelState.ownerlist;
        this.setState({ existingowner: e.target.checked, ListArrayHotel: ownerlist });
    }
    existingContact = e => {
        this.setState({ spinner: false })
        const { AccountState } = this.props;
        const phonecode = AccountState?.account.phonecode;

        this.setState({ contactperson: e.target.checked });
        const data = this.props.HotelState.hotel;
        if (!this.state.owneremailValidError) {
            this.props.HandleInputChange('communicationemail', e.target.checked && data.owneremail ? [data.owneremail] : []);
        }
        this.props.HandleInputChange('contactphone', e.target.checked ? data.ownerphone : '');
        this.props.HandleInputChange('contactperson', e.target.checked ? data.ownername : '');
        this.props.HandleInputChange('ccperson', e.target.checked ? data.ccowner : phonecode);
    }

    onChangeUSer = e => {
        this.setState({ spinner: false })
        const { name, value } = e;
        const user = this.state.ListArrayHotel.find(obj => { return obj.email == value });
        if (user) {
            this.setState({ owneremailError: false, ownerphoneError: false, ownernameError: false });
            this.props.HandleInputChange('owneremail', value);
            this.props.HandleInputChange('ownerphone', user.phonenumber);
            this.props.HandleInputChange('ownername', user.name);
            this.props.HandleInputChange('ccowner', user.cc);
        }
    }

    onChangeLatLon(i, e) {
        this.setState({ spinner: false })
        const { name, value } = e.target;
        this.props.HandleInputChangeMultiple(name, value, i);
    }

    addFormFields(e) {
        this.setState({ spinner: false })
        this.props.HandleMultipleUpdate('location', { 'lat': '', 'lon': '' }, '');
    }

    removeFormFields(i) {
        this.setState({ spinner: false })
        const DataValue = this.props.HotelState.hotel.location;
        if (DataValue.length === 1 && i === 0) {
            this.props.HandleMultipleUpdate(true, { 'lat': '', 'lon': '' }, i);
        } else {
            this.props.HandleMultipleUpdate('location', '', i);
        }
    }

    elfighturlChange(i, e, tempname) {
        this.setState({ spinner: false })
        const { name, value } = e.target;
        if (tempname == 'socialreviewurl') {
            this.props.HandleInputChange(name, value, i);
            var elflink = value;
            if (elflink) {
                if (Urlvalidate(elflink)) {
                    let arr = this.state.urlIndex.filter((item) => item !== i)
                    this.setState({ urlIndex: arr })
                } else {
                    let repeatedMaintance = this.state.urlIndex.find(obj => { return obj == i })
                    if (!repeatedMaintance) {
                        this.setState({ urlIndex: [...this.state.urlIndex, i] })
                    }
                }
            } else {
                let arr = this.state.urlIndex.filter((item) => item !== i)
                this.setState({ urlIndex: arr })
            }
            this.props.HandleInputChangeElf(name, value, i);
        }
        if (tempname == 'socialmediaurl') {
            this.props.HandleInputChange(name, value, i);
            var socialmedialink = value;
            if (socialmedialink) {
                if (Urlvalidate(socialmedialink)) {
                    let arr = this.state.socialMediaIndex.filter((item) => item !== i)
                    this.setState({ socialMediaIndex: arr })
                } else {
                    let repeatedMaintance = this.state.socialMediaIndex.find(obj => { return obj == i })
                    if (!repeatedMaintance) {
                        this.setState({ socialMediaIndex: [...this.state.socialMediaIndex, i] })
                    }
                }
            } else {
                let arr = this.state.socialMediaIndex.filter((item) => item !== i)
                this.setState({ socialMediaIndex: arr })
            }
            this.props.HandleChangeSocialMedia(name, value, i);
        }
    }
    elfightmediaChange(i, e, tempname) {
        this.setState({ spinner: false })
        const { name, value } = e;
        const data = this.props.HotelState.hotel;
        if (tempname == 'socialreviewid') {
            let elfsights = [...data.socialreviews]
            elfsights = [
                ...elfsights.slice(0, i),
                { ...elfsights[i], [name]: value },
                ...elfsights.slice(i + 1)
            ]
            this.props.HandleInputChange("socialreviews", elfsights);
        } else {
            let socialmedias = [...data.socialmedias]
            socialmedias = [
                ...socialmedias.slice(0, i),
                { ...socialmedias[i], [name]: value },
                ...socialmedias.slice(i + 1)
            ]
            this.props.HandleInputChange("socialmedias", socialmedias);
        }

    }

    addelfsightsFields(e, i, name) {
        this.setState({ spinner: false })
        const data = this.props.HotelState.hotel;
        if (name == "socialreviews") {
            this.props.HandleMultipleUpdateElf('socialreviews', { socialmediaid: '', socialmediaurl: '', socialmediastatus: true }, '');
        } else {
            let obj = { socialmediaid: '', socialmediaurl: '', socialmediastatus: true }
            let socialmedias = [...data.socialmedias, obj]
            this.props.HandleInputChange("socialmedias", socialmedias);
        }
    }
    removeSocialMedia = (index) => {
        if (this.state.socialMediaIndex && this.state.socialMediaIndex.length > 0) {
            let arr = []
            this.state.socialMediaIndex.sort((a, b) => Number(a.sort) - Number(b.sort)).map((item) => {
                if (item > index) {
                    arr.push(item - 1)
                } else if (item < index) {
                    arr.push(item)
                }
            })
            this.setState({ socialMediaIndex: arr })
        }
        const data = this.props.HotelState.hotel.socialmedias;

        if (data && data.length > 1) {
            var temp = [...data.filter((event, i) => i !== index)]
            this.props.HandleInputChange('socialmedias', temp);
        }
    }
    removelfsightFields(i) {
        this.setState({ spinner: false });
        if (this.state.urlIndex.length > 0) {
            let arr = []
            this.state.urlIndex.sort((a, b) => Number(a.sort) - Number(b.sort)).map((item) => {
                if (item > i) {
                    arr.push(item - 1)
                } else if (item < i) {
                    arr.push(item)
                }
            })
            this.setState({ urlIndex: arr })
        }
        this.props.HandleMultipleUpdateElf('socialreviews', '', i);
    }
    urlChange = (i) => {
        let elflink = this.state.urlIndex;
        if (elflink) {
            if (elflink.includes(i)) {
                return 1
            } else {
                return 0
            }
        } else {
            return 0
        }
    }

    socialMediaChange = (i) => {
        let elflink = this.state.socialMediaIndex;
        if (elflink) {
            if (elflink.includes(i)) {
                return 1
            } else {
                return 0
            }
        } else {
            return 0
        }
    }

    handleRemove = (i, value) => {
        this.setState({ spinner: false })
        const file = this.state.imageArray.filter((item, index) => index !== i);
        const Store = this.state.images.filter((item, index) => index !== i);
        this.setState({ imageArray: file, images: Store })
        if (value == 'editFirst') {
            const data = this.props.HotelState.hotel.images;
            const file = data.filter((item, index) => index !== i);
            this.props.HandleInputChange('images', file);
        }
    }
    handleRemoveExisting = (i, value) => {
        this.setState({ spinner: false })
        const file = this.state.existingImage.filter((item, index) => index !== i);
        this.setState({ existingImage: file })
    }
    onChangeEdit = e => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        this.setState({ spinner: false })
        const data = this.props.HotelState.hotel.images;
        this.setState({ existingImage: data });

        this.setState({ images: [...this.state.images, ...e.target.files] });
        const files = Array.from(e.target.files);

        const newfiles = [];

        for (var i = 0; i < files.length; i++) {
            var file = files[i];
            const imgvalidate = Imagevalidation(file);
            if (imgvalidate) {
                newfiles.push(file)
            } else {
                Error((languageData && languageData.INVALID_FILE_DISCARDED ? languageData.INVALID_FILE_DISCARDED : ' Invalid file is discarded'));
            }
        }

        Promise.all(newfiles.map(file => {
            return (new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.addEventListener('load', (ev) => {
                    resolve(ev.target.result);
                });
                reader.addEventListener('error', reject);
                reader.readAsDataURL(file);
            }));
        }))
            .then(images => {
                if (images.length > 0) {
                    images.map((item) => {
                        this.setState({ imageArray: [...this.state.imageArray, item] })
                    })
                }
            }, error => {
                console.error(error);
            });
    }


    removeIndexError = (array, index, name) => {
        let arr = array ? [...array.filter((e) => e !== index)] : []
        this.setState({ [name]: arr })
    }
    onChangeShifTime = (name, e, i) => {
        const data = this.props.HotelState.hotel;
        let shifttime = data.shifttime ? [...data.shifttime] : []
        shifttime = [
            ...shifttime.slice(0, i),
            { ...shifttime[i], [name]: e },
            ...shifttime.slice(i + 1)
        ]
        this.props.HandleInputChange("shifttime", shifttime);
    }
    onchangeShiftInTime = (name, i, e) => {
        const data = this.props.HotelState.hotel;
        let shifttime = (data.shifttime && data.shifttime.length > 0) ? [...data.shifttime] : []
        let shiftouttime = shifttime[i]['shiftouttime'] ? shifttime[i]['shiftouttime'] : ''
        let status = moment(shiftouttime).format("hh:mm A") == moment(e).format("hh:mm A")
        if (!status) {
            this.onChangeShifTime(name, e, i);
        } else {
            this.onChangeShifTime(name, null, i);
        }
        this.removeIndexError(this.state.shiftinArrayError, i, "shiftinArrayError");
    }
    onchangeShiftOutTime = (name, i, e) => {
        const data = this.props.HotelState.hotel;
        let shifttime = (data.shifttime && data.shifttime.length > 0) ? [...data.shifttime] : []
        let shiftintime = shifttime[i]['shiftintime'] ? shifttime[i]['shiftintime'] : ''
        let status = moment(shiftintime).format("hh:mm A") == moment(e).format("hh:mm A")
        if (!status) {
            this.onChangeShifTime(name, e, i);
        } else {
            this.onChangeShifTime(name, null, i);
        }
        this.removeIndexError(this.state.shiftoutArrayError, i, "shiftoutArrayError");
    }
    onChangeShift = (i, e) => {
        const { name, value } = e;
        const data = this.props.HotelState.hotel;
        let shifttime = [...data.shifttime]
        let status = true
        if (shifttime && shifttime.length > 0) {
            shifttime.map((item, j) => {
                if (item.shift == value && j != i) {
                    status = false
                }
            })
            if (!status) {
                this.onChangeShifTime(name, '', i);
                Error("Shift Already Exist")
            } else {
                this.onChangeShifTime(name, value, i);
            }
        }
        this.removeIndexError(this.state.shiftArrayError, i, "shiftArrayError");
    }

    ShiftTimeError = () => {
        const data = this.props.HotelState.hotel
        let valid = 1
        if (data.shifttime && data.shifttime.length > 0) {
            let shiftinIndex = []
            let shiftoutIndex = []
            let shiftIndex = []
            data.shifttime.map((item, i) => {
                if (!item.shiftintime) {
                    valid = 0
                    shiftinIndex.push(i)
                }
                if (!item.shiftouttime) {
                    valid = 0
                    shiftoutIndex.push(i)
                }
                if (!item.shift) {
                    valid = 0
                    shiftIndex.push(i)
                }
            })
            this.setState({ shiftinArrayError: shiftinIndex, shiftoutArrayError: shiftoutIndex, shiftArrayError: shiftIndex })
        }
        return valid
    }
    FindShiftErrorStatus = (index, data) => {
        if (data && data.length > 0) {
            if (data.includes(index)) {
                return 1
            } else {
                return 0
            }
        } else {
            return 0
        }
    }
    addShiftFields = () => {
        const data = this.props.HotelState.hotel;
        let status = this.ShiftTimeError()
        if (status) {
            let obj = { "shiftintime": null, "shiftouttime": null, "shift": '' }
            let shifttime = [...data.shifttime, obj]
            this.props.HandleInputChange("shifttime", shifttime);
        }
    }
    removeShiftFields = (id) => {
        const data = this.props.HotelState.hotel;
        let shifttime = [...data.shifttime.filter((e, i) => i != id)]
        this.props.HandleInputChange("shifttime", shifttime);
        this.removeIndexError(this.state.shiftinArrayError, id, "shiftinArrayError");
        this.removeIndexError(this.state.shiftoutArrayError, id, "shiftoutArrayError");
        this.removeIndexError(this.state.shiftArrayError, id, "shiftArrayError");
    }
    // -------------------
    onChangeelfsights(i, e, tempname) {
        const { name, value, checked } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        if (tempname == "socialreviewstatus") {
            const data = this.props.HotelState.hotel.socialreviews
            var temp = [
                ...data.slice(0, i),
                { ...data[i], [name]: tempname == "socialreviewstatus" ? checked : value },
                ...data.slice(i + 1)
            ]
            this.props.HandleInputChange('socialreviews', temp);
        } else {
            const data = this.props.HotelState.hotel.socialmedias
            var temp = [
                ...data.slice(0, i),
                { ...data[i], [name]: tempname == "socialmediastatus" ? checked : value },
                ...data.slice(i + 1)
            ]
            this.props.HandleInputChange('socialmedias', temp);
        }
    }
    onChangeTime = (value, name) => {
        this.props.HandleInputChange(name, value);
    }

    render() {
        const { LandingState, HotelState, AccountState } = this.props;
        const accountHotel = AccountState.account;
        const data = HotelState.hotel;
        const dataSpinner = HotelState.spinner;
        const SocialMedia = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[2] : [];
        const listgroups = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[3] : [];
        const listtimezone = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[4] : [];
        const languageData = AccountState.account.SelectedlanguageData
        let selectedGroup = ''
        if (data.group) {
            selectedGroup = listgroups.find(e => e._id === data.group)
        }
        let selectTimeZone = '';
        if (data.timezone) {
            if (listtimezone && listtimezone.length > 0) {
                listtimezone.map((item) => {
                    if (item._id === data.timezone) {
                        selectTimeZone = { label: `${item.abbr} ${"( " + item.value + " )"}`, value: item._id, utc: item.utc }
                    }
                })
            }
        }
        let phoneCode = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[1] : [];
        phoneCode = phoneCode && phoneCode.length > 0 ? [...phoneCode.filter(item => item.code === "USA"), ...phoneCode.filter(item => item.code !== "USA")] : [];
        let ccData = '';
        let ccownerData = '';
        let ccgmData = '';
        let ccpersonData = '';
        let cctwilloData = '';
        if (phoneCode) {
            phoneCode.map(item => {
                if (data.cc) {
                    if (item.code === data.cc) {
                        ccData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                    if (item.phonecode === data.cc) {
                        ccData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                } else {
                    if (item.phonecode === accountHotel.phonecode) {
                        ccData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                    if (item.code === accountHotel.phonecode) {
                        ccData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                }
                if (data.ccowner) {
                    if (item.phonecode === data.ccowner) {
                        ccownerData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                    if (item.code === data.ccowner) {
                        ccownerData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                } else {
                    if (item.phonecode === accountHotel.phonecode) {
                        ccownerData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                    if (item.code === accountHotel.phonecode) {
                        ccownerData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                }
                if (data.ccgm) {
                    if (item.phonecode === data.ccgm) {
                        ccgmData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                    if (item.code === data.ccgm) {
                        ccgmData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                } else {
                    if (item.phonecode === accountHotel.phonecode) {
                        ccgmData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                    if (item.code === accountHotel.phonecode) {
                        ccgmData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                }
                if (data.ccperson) {
                    if (item.phonecode === data.ccperson) {
                        ccpersonData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                    if (item.code === data.ccperson) {
                        ccpersonData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                } else {
                    if (item.phonecode === accountHotel.phonecode) {
                        ccpersonData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                    if (item.code === accountHotel.phonecode) {
                        ccpersonData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                }
                if (data.cctwillo) {
                    if (item.phonecode === data.cctwillo) {
                        cctwilloData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                    if (item.code === data.cctwillo) {
                        cctwilloData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                } else {
                    if (item.phonecode === accountHotel.phonecode) {
                        cctwilloData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                    if (item.code === accountHotel.phonecode) {
                        cctwilloData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                }
            })
        }
        const pageno = new URLSearchParams(this.props.location.search).get('page');
        const lagLonData = data.location;

        const photos = [];
        if (this.state.imageArray.length > 0 && this.state.existingImage.length > 0) {
            this.state.existingImage.map((image, i) => {
                photos.push(
                    <div key={i} style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                        <div style={{ position: 'relative' }}>
                            <img src={image ? ImportedURL.FILEURL + image : ''} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} onError={onErrorImage} /><i className="fa fa-times img_remove" onClick={() => this.handleRemoveExisting(i, 'edit')} ></i>
                        </div>
                    </div>)
            })
            this.state.imageArray.map((image, i) => {
                photos.push(
                    <div key={i} style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                        <div style={{ position: 'relative' }}>
                            <img src={image} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} onError={onErrorImage} />
                        </div>
                        <i className="fa fa-times img_remove" onClick={() => this.handleRemove(i, 'add')} ></i>
                    </div>
                )
            })
        } else {
            if (this.state.imageArray.length) {
                this.state.imageArray.map((image, i) => {
                    photos.push(
                        <div style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                            <div style={{ position: 'relative' }}>
                                <img src={image} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} onError={onErrorImage} /><i className="fa fa-times img_remove" onClick={() => this.handleRemove(i, 'add')} ></i>
                            </div>
                        </div>
                    )
                })
            } else if (data.images) {
                data.images.map((image, i) => {
                    photos.push(
                        <div style={{ position: 'relative', marginRight: '10px', marginBottom: '15px' }}>
                            <div style={{ position: 'relative' }}>
                                <img src={image ? ImportedURL.FILEURL + image : ''} width="100px" alt='' style={{ marginRight: '10px', height: '100px', position: 'relative' }} onError={onErrorImage} /><i className="fa fa-times img_remove" onClick={() => this.handleRemove(i, 'editFirst')} ></i>
                            </div>
                        </div >)
                })
            }
        }
        const shiftOptions = [
            { value: '1', label: '1', name: "shift" },
            { value: '2', label: '2', name: "shift" },
            { value: '3', label: '3', name: "shift" },
            { value: '4', label: '4', name: "shift" },
            { value: '5', label: '5', name: "shift" }
        ]
        let hotel = JSON.parse(localStorage.getItem('adminLandingData'))

        return (
            <>
                <div>
                    {/* {this.state.modalType === "Edit" && data.name !== '' || this.state.modalType === "Add" ? */}
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <Link to="/list-hotel"><h6> {languageData && languageData.HOTELS ? languageData.HOTELS : "Hotels'  "} {languageData && languageData.LIST ? languageData.LIST : " List"}</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>{this.state.modalType} {languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel "} </h6></p>
                        </div>
                        <div className="section-body pt-3">
                            <div className="container-fluid">
                                <div className="tab-content pt-3">
                                    <div
                                        className="tab-pane fade show active"
                                        id="Departments-list"
                                        role="tabpanel"
                                    >
                                        <div className="card">
                                            <div className="card-body">
                                                {(accountHotel?.allhotels && accountHotel.allhotels?.length > 0) ?
                                                    <div className="header-action">
                                                        <Link to={"/view-hotel/" + accountHotel.hotel}><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button></Link>
                                                    </div>
                                                    :
                                                    <div className="header-action" >
                                                        <Link to={pageno ? '/list-hotel?page=' + pageno : '/list-hotel'}><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button></Link>
                                                    </div>
                                                }
                                                <div className='d-flex'>
                                                    <div className='rounded_icon'><i className="fa fa-building-o mr-2 "></i></div>
                                                    <h3 className="card-title mb-0" style={{ marginTop: '10px' }}>{this.state.id !== '' ? 'Edit HOTEL' : 'ADD HOTEL'}</h3>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card">
                                        <div className="card-body">
                                            <h3 className="card-title">HOTEL DETAILS</h3>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Group<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <Select
                                                            value={selectedGroup ? { label: selectedGroup.name, value: selectedGroup._id, name: 'group' } : ''}
                                                            onChange={this.handleChange}
                                                            options={listgroups ? listgroups.filter(filterItem => filterItem.status === true).map(item => {
                                                                return { label: item.name, value: item._id, name: 'group' }
                                                            }) : ''}
                                                            ref={this.groupinputRef}
                                                            isDisabled={this.state.modalType == "View"}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.groupError ? "block" : 'none' }}>Group is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='name' ref={this.nameinputRef} onChange={this.onChange} value={data.name} placeholder="Name" />
                                                        <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Name is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Hotel ID<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='hotelid' ref={this.hotelidinputRef} onChange={this.onChange} value={data.hotelid} placeholder="Hotel ID" />
                                                        <div className="invalid-feedback" style={{ display: this.state.hotelidError ? "block" : 'none' }}>Hotel ID is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Hotel Email<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='email' ref={this.emailinputRef} onChange={this.onChange} value={data.email} placeholder="Hotel Email" />
                                                        <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Hotel Email is required</div>
                                                        <div className="invalid-feedback" style={{ display: this.state.emailValidError ? "block" : 'none' }}>Enter valid email</div>
                                                        <div className="invalid-feedback" style={{ display: this.state.emailExistValidError ? "block" : 'none' }}>Email is alredy exist</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="row"> <label className="form-label">
                                                        Phone #<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                    </label>
                                                        <div className="col-xl-5 col-lg-5  col-md-5 col-sm-12 col-12 flg_col_res">
                                                            <div className="form-group mb-0 country_code">
                                                                {/* <label className="form-label">
                                                                        Country Code
                                                                    </label> */}
                                                                <Select
                                                                    isDisabled={this.state.modalType == "View"}
                                                                    value={ccData ? ccData : ''}
                                                                    onChange={this.handlePhoneCode}
                                                                    options={phoneCode && phoneCode.filter(filterItem => filterItem.status === true).map(item => {
                                                                        return {
                                                                            label: item.phonecode,
                                                                            flag: item.flag,
                                                                            value: item.code,
                                                                            name: 'cc'
                                                                        }
                                                                    })}
                                                                    formatOptionLabel={(item) => {
                                                                        return (
                                                                            <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.label }} />
                                                                        )
                                                                    }}
                                                                />
                                                                <div className="invalid-feedback" style={{ display: this.state.ccError ? "block" : 'none' }}>CC is required</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-7 col-lg-7  col-md-7 col-sm-12 col-12 num_col_res">
                                                            <div className="form-group mb-0">
                                                                <input type="text" className="form-control ml-1" disabled={this.state.modalType == "View"} ref={this.phonenumberinputRef} name='phonenumber' onChange={this.onChange} value={data.phonenumber} placeholder="Phone #" />
                                                            </div >
                                                        </div >
                                                        <div className="invalid-feedback" style={{ display: this.state.phonenumberError ? "block" : 'none' }}>Phone # is required</div>
                                                        <div className="invalid-feedback" style={{ display: this.state.phoneNoPatternError ? "block" : 'none' }}>Phone # should contain 10 digits</div>
                                                    </div >
                                                </div >
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Address<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='address' onChange={this.onChange} ref={this.addressinputRef} value={data.address} placeholder=" Address" />
                                                        <div className="invalid-feedback" style={{ display: this.state.addressError ? "block" : 'none' }}> Address is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            City<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='city' onChange={this.onChange} ref={this.cityinputRef} value={data.city} placeholder=" City" />
                                                        {/* <Autocomplete
                                                                    apikey="AIzaSyCxqAAwgryZh4UN3MZVZAgSSK64gGgkEv"
                                                                    className="form-control p-10" name="city"
                                                                    onChange={(e) => this.setValue(e)}
                                                                    onPlaceSelected={(place) => {
                                                                        this.onChangeValue(place);
                                                                    }}
                                                                    value={this.state.city ? this.state.city : data.city}
                                                                    placeholder="Hotel Address"
                                                                    disabled={this.state.modalType == "View"}
                                                                /> */}
                                                        <div className="invalid-feedback" style={{ display: this.state.cityError ? "block" : 'none' }}> City is required</div>

                                                        {/* <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='city' onChange={this.onChange} value={data.city} placeholder="Hotel City" /> */}
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            State<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='state' onChange={this.onChange} ref={this.stateinputRef} value={data.state} placeholder=" State" />
                                                        <div className="invalid-feedback" style={{ display: this.state.stateError ? "block" : 'none' }}> State is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Postcode<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='postcode' onChange={this.onChange} ref={this.postcodeinputRef} value={data.postcode} placeholder=" Postcode" />
                                                        <div className="invalid-feedback" style={{ display: this.state.postcodeError ? "block" : 'none' }}> Postcode is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Country<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='country' onChange={this.onChange} ref={this.countryinputRef} value={data.country} placeholder=" Country" />
                                                        <div className="invalid-feedback" style={{ display: this.state.countryError ? "block" : 'none' }}> Country is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Hotel Radius #<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='radius' value={data.radius} ref={this.radiusinputRef} onChange={this.onChangeNumberOnly} placeholder="Hotel Radius" />
                                                        <div className="invalid-feedback" style={{ display: this.state.radiusError ? "block" : 'none' }}> Hotel Radius is required</div>
                                                        <div className="invalid-feedback" style={{ display: this.state.radiusNumError ? "block" : 'none' }}>Enter Numeric only</div>
                                                    </div >
                                                </div >
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Latitude<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='latitude' onChange={this.onChange} ref={this.latitudeinputRef} value={data.latitude} placeholder=" Latitude" />
                                                        <div className="invalid-feedback" style={{ display: this.state.latitudeError ? "block" : 'none' }}> Latitude is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Longitude<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='longitude' onChange={this.onChange} ref={this.longitudeinputRef} value={data.longitude} placeholder=" Longitude" />
                                                        <div className="invalid-feedback" style={{ display: this.state.longitudeError ? "block" : 'none' }}> Longitude is required</div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">Time Zone<span className="ml-1" style={{ color: 'red' }}>*</span><span className="pl-2">{data.timezoneutc ? moment.tz(this.state.currentdate, data.timezoneutc).format('MM/DD/YYYY hh:mm A') : ''}</span></label>
                                                        <Select
                                                            value={selectTimeZone}
                                                            // value={selectTimeZone ? { label: selectTimeZone.label, value: selectTimeZone.value, name: 'timezone' } : ''}
                                                            onChange={this.handleChange}
                                                            options={listtimezone ? listtimezone.filter(filterItem => filterItem.status === true).map(item => {
                                                                return { label: `${item.abbr} ${"( " + item.value + " )"}`, value: item._id, utc: item.utc && item.utc[0], name: 'timezone' }
                                                            }) : ''}
                                                            ref={this.timezoneinputRef}
                                                            isDisabled={this.state.modalType == "View"}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.timezoneError ? "block" : 'none' }}>Time Zone is required</div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Google Review Add URL
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='googlereviewurl' onChange={this.onChange} ref={this.mainTripadvisorURLinputRef} value={data.googlereviewurl} placeholder="Google Review Add URL" />
                                                        <div className="invalid-feedback" style={{ display: this.state.mainTripadvisorURL ? "block" : 'none' }}>Google Review Add URL is not valid</div>
                                                    </div>

                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Trips Advisor Add URL
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='tripsadvisorurl' onChange={this.onChange} value={data.tripsadvisorurl} ref={this.mainTgoogleURLinputRef} placeholder="Trips Advisor Add URL" />
                                                        <div className="invalid-feedback" style={{ display: this.state.mainTgoogleURL ? "block" : 'none' }}>Trips Advisor Add URL is not valid</div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Google Review View URL
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='googlereviewviewurl' onChange={this.onChange} ref={this.viewTgoogleURLinputRef} value={data.googlereviewviewurl} placeholder="Google Review View URL" />
                                                        <div className="invalid-feedback" style={{ display: this.state.viewTgoogleURL ? "block" : 'none' }}>Google Review View URL is not valid</div>
                                                    </div>

                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Trips Advisor View URL
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='tripsadvisorviewurl' onChange={this.onChange} value={data.tripsadvisorviewurl} ref={this.viewTripadvisorURLinputRef} placeholder="Trips Advisor View URL" />
                                                        <div className="invalid-feedback" style={{ display: this.state.viewTripadvisorURL ? "block" : 'none' }}>Trips Advisor View URL is not valid</div>
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Guest Touch ID
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='guesttouchid' onChange={this.onChange} value={data.guesttouchid} ref={this.viewTripadvisorURLinputRef} placeholder="Guest Touch ID" />
                                                    </div>
                                                </div>

                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group ">
                                                        <label className="form-label">
                                                            Images
                                                        </label>
                                                        <input type="file" accept="image/*" className="form-control" multiple disabled={this.state.modalType === "View"} placeholder="Staff Image" name='image' onChange={this.state.modalType === "Edit" ? this.onChangeEdit : this.onChange} id="imageSet" />
                                                    </div>
                                                    {photos.length > 0 ?
                                                        <div className="form-group " >
                                                            <div className="photos_wid">
                                                                {photos}
                                                            </div>
                                                        </div>
                                                        : ''}
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Logo<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input type="file" accept="image/*" className="form-control" disabled={this.state.modalType === "View"} placeholder="Staff Image" name='logo' ref={this.logoinputRef} onChange={this.onChange} id="imageSet" />
                                                        <div className="invalid-feedback" style={{ display: this.state.logoError ? "block" : 'none' }}>Logo is required</div>
                                                    </div>
                                                    {data.logo ?
                                                        <div className="form-group">
                                                            {this.state.logoSrc ? <img src={this.state.logoSrc} width="100px" alt='' onError={onErrorImage} /> : <img src={data.logo ? ImportedURL.FILEURL + data.logo : ''} width="100px" alt='' onError={onErrorImage} />}
                                                        </div>
                                                        : ''}
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group ">
                                                        <label className="form-label">
                                                            Card<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                        </label>
                                                        <input type="file" accept="image/*" className="form-control" disabled={this.state.modalType === "View"} placeholder="Staff Image" name='card' ref={this.cardinputRef} onChange={this.onChange} id="imageSet" />
                                                        <div className="invalid-feedback" style={{ display: this.state.cardError ? "block" : 'none' }}> Card is required</div>
                                                    </div>
                                                    {data.card ?
                                                        <div className="">
                                                            {this.state.cardSrc ? <img src={this.state.cardSrc} height="100px" alt='' onError={onErrorImage} /> : <img src={data.card ? ImportedURL.FILEURL + data.card : ''} height="100px" alt='' onError={onErrorImage} />}
                                                        </div>
                                                        : ''}
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Wifi Password
                                                        </label>
                                                        <input type="text" className="form-control" name='wifipassword' onChange={this.onChange} value={data.wifipassword} placeholder=" Wifi Password" />
                                                    </div>
                                                </div>
                                            </div >
                                        </div >
                                    </div >

                                    <div className="card conatct_detaill">
                                        <div className="card-body">
                                            <h3 className="card-title">Contact Details</h3>
                                            {
                                                this.state.modalType == 'Add' && <div className="form-group">
                                                    <div className="custom-controls-stacked" style={{ marginLeft: "35px" }}>
                                                        <label className="custom-control custom-switch" style={{ width: '150px', }}>
                                                            <input type="checkbox" className="custom-control-input " name="example-checkbox1" value="option1" onChange={this.existingOwner} />
                                                            <span className="custom-control-label" style={{ cursor: 'pointer' }}>Existing Owner</span>
                                                        </label>
                                                    </div>
                                                </div>
                                            }
                                            <div className="row">
                                                {this.state.modalType == "Add" &&
                                                    <>
                                                        <div className="col-sm-6 col-md-4">
                                                            <div className="form-group">
                                                                <label className="form-label">
                                                                    Owner Name{this.state.modalType == "Edit" ? '' : <span className="ml-1" style={{ color: 'red' }}>*</span>}
                                                                </label>
                                                                <input type="text" className="form-control" style={{ background: this.state.modalType == "Edit" ? "#eee" : "" }} disabled={this.state.modalType == "View" || this.state.modalType == "Edit"} name='ownername' ref={this.ownernameinputRef} onChange={this.onChange} value={data.ownername} placeholder=" Owner Name" />
                                                                <div className="invalid-feedback" style={{ display: this.state.ownernameError ? "block" : 'none' }}>Owner Name is required</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-4">
                                                            <div className="form-group">
                                                                <label className="form-label">
                                                                    Owner Email{this.state.modalType == "Edit" ? '' : <span className="ml-1" style={{ color: 'red' }}>*</span>}
                                                                </label>
                                                                {this.state.existingowner ?
                                                                    <>
                                                                        <Select
                                                                            onChange={this.onChangeUSer}
                                                                            options={this.state.ListArrayHotel ? this.state.ListArrayHotel.filter(filterItem => filterItem.status === true).map(item => { return { label: item.email + ' ( ' + item.name + ' )', value: item.email, name: 'email' } }) : ''} />
                                                                        <div className="invalid-feedback" style={{ display: this.state.owneremailValidError ? "block" : 'none' }}>Enter valid email</div>
                                                                    </> :
                                                                    <>
                                                                        <input type="text" className="form-control" style={{ background: this.state.modalType == "Edit" ? "#eee" : "" }} disabled={this.state.modalType == "View" || this.state.modalType == "Edit"} name='owneremail' ref={this.owneremailinputRef} onChange={this.onChange} value={data.owneremail} placeholder=" Owner Email" />
                                                                        <div className="invalid-feedback" style={{ display: this.state.owneremailValidError ? "block" : 'none' }}>Enter valid email</div>

                                                                    </>
                                                                }
                                                                <div className="invalid-feedback" style={{ display: this.state.owneremailError ? "block" : 'none' }}>Owner Email is required</div>


                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-4">
                                                            <label className="form-label">
                                                                Owner Phone #{this.state.modalType == "Edit" ? '' : <span className="ml-1" style={{ color: 'red' }}>*</span>}
                                                            </label>
                                                            <div className="row">
                                                                <div className="col-xl-5 col-lg-5  col-md-5 col-sm-12 col-12 flg_col_res">
                                                                    <div className="form-group mb-0 country_code">
                                                                        {/* <label className="form-label">
                                                                        Country Code
                                                                    </label> */}
                                                                        <Select
                                                                            isDisabled={this.state.modalType == "View" || this.state.modalType == "Edit"}
                                                                            value={ccownerData ? ccownerData : ''}
                                                                            placeholder='+'
                                                                            onChange={this.handlePhoneCode}
                                                                            options={phoneCode && phoneCode.filter(filterItem => filterItem.status === true).map(item => {
                                                                                return {
                                                                                    label: item.phonecode,
                                                                                    flag: item.flag,
                                                                                    value: item.code,
                                                                                    name: 'ccowner'
                                                                                }
                                                                            })}
                                                                            formatOptionLabel={(item) => {
                                                                                return (
                                                                                    <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.label }} />
                                                                                )
                                                                            }}
                                                                        />
                                                                        {/* <div className="invalid-feedback" style={{ display: this.state.ccownerError ? "block" : 'none' }}>CC is required</div> */}
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-7 col-lg-7  col-md-7 col-sm-12 col-12 num_col_res">
                                                                    <div className="form-group mb-0">
                                                                        <input type="text" style={{ background: this.state.modalType == "Edit" ? "#eee" : "" }} className="form-control ml-1" disabled={this.state.modalType == "View" || this.state.modalType == "Edit"} name='ownerphone' ref={this.ownerphoneinputRef} onChange={this.onChange} value={data.ownerphone} placeholder="Owner Phone #" />
                                                                    </div >
                                                                </div >
                                                                <div className="invalid-feedback" style={{ display: this.state.ownerphoneError ? "block" : 'none' }}>Owner Phone # is required</div>
                                                                <div className="invalid-feedback" style={{ display: this.state.ownerphonepatternError ? "block" : 'none' }}>Owner Phone # should contain 10 digits</div>
                                                            </div >
                                                        </div >
                                                    </>
                                                }
                                                {
                                                    this.state.modalType !== 'View' && <div className="form-group">
                                                        <div className="custom-controls-stacked" style={{ marginLeft: "35px" }}>
                                                            <label className="custom-control custom-switch" style={{ width: '291px' }}>
                                                                <input type="checkbox" className="custom-control-input" name="example-checkbox1" value="option1" onChange={this.existingContact} />
                                                                <span className="custom-control-label" style={{ cursor: 'pointer' }}>Contact Person Same As Owner</span>
                                                            </label>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Contact Person Name
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='contactperson' onChange={this.onChange} value={data.contactperson} placeholder="Contact Person Name" />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <label className="form-label"> Contact Phone # </label>
                                                    <div className="row">
                                                        <div className="col-xl-5 col-lg-5  col-md-5 col-sm-12 col-12 flg_col_res">
                                                            <div className="form-group mb-0 country_code">
                                                                {/* <label className="form-label">
                                                                        Country Code
                                                                    </label> */}
                                                                <Select
                                                                    isDisabled={this.state.modalType == "View"}
                                                                    value={ccpersonData ? ccpersonData : ''}
                                                                    onChange={this.handlePhoneCode}
                                                                    options={phoneCode && phoneCode.filter(filterItem => filterItem.status === true).map(item => {
                                                                        return {
                                                                            label: item.phonecode,
                                                                            flag: item.flag,
                                                                            value: item.code,
                                                                            name: 'ccperson'
                                                                        }
                                                                    })}
                                                                    formatOptionLabel={(item) => {
                                                                        return (
                                                                            <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.label }} />
                                                                        )
                                                                    }}
                                                                />
                                                                {data.contactphone ? <div className="invalid-feedback" style={{ display: this.state.ccpersonError ? "block" : 'none' }}>CC is required</div> : ''}
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-7 col-lg-7  col-md-7 col-sm-12 col-12 num_col_res">
                                                            <div className="form-group mb-0">
                                                                <input type="text" className="form-control ml-1" disabled={this.state.modalType == "View"} name='contactphone' onChange={this.onChange} value={data.contactphone} placeholder="Contact Phone # " />
                                                            </div>
                                                        </div>
                                                        <div className="invalid-feedback" style={{ display: this.state.contactphoneError ? "block" : 'none' }}>Contact Phone # should contain 10 digits</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <label className="form-label">Message Phone #<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <div className="row">
                                                        <div className="col-xl-5 col-lg-5  col-md-5 col-sm-12 col-12 flg_col_res">
                                                            <div className="form-group mb-0 country_code">
                                                                <Select
                                                                    isDisabled={this.state.modalType == "View"}
                                                                    value={cctwilloData ? cctwilloData : ''}
                                                                    onChange={this.handlePhoneCode}
                                                                    options={phoneCode && phoneCode.filter(filterItem => filterItem.status === true).map(item => {
                                                                        return {
                                                                            label: item.phonecode,
                                                                            flag: item.flag,
                                                                            value: item.code,
                                                                            name: 'cctwillo'
                                                                        }
                                                                    })}
                                                                    formatOptionLabel={(item) => {
                                                                        return (
                                                                            <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.label }} />
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-xl-7 col-lg-7  col-md-7 col-sm-12 col-12 num_col_res">
                                                            <div className="form-group mb-0">
                                                                <input type="text" className="form-control ml-1" disabled={this.state.modalType == "View"} name='twillo' ref={this.twilloinputRef} onChange={this.handleChangeTwillo} value={data.twillo} placeholder="Message Phone #" />
                                                            </div >
                                                        </div >
                                                        <div className="invalid-feedback" style={{ display: this.state.twilloError ? "block" : 'none' }}>Message Phone # is required</div>
                                                        <div className="invalid-feedback" style={{ display: this.state.ownerphonetwilloError ? "block" : 'none' }}>Message Phone # should contain 10 digits</div>
                                                    </div >
                                                </div >
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Communication Email List
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='communicationemail' onKeyPress={this.handleKeyDown} value={this.state.communicationemail} onChange={this.onChange} placeholder="Add More Email By Hitting Enter Button" />
                                                        <div className="invalid-feedback" style={{ display: this.state.communicationemailValidError ? "block" : 'none' }}>Enter valid email</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-12">
                                                    <div className="form-group">
                                                        <div className="selectgroup selectgroup-pills">
                                                            {
                                                                data.communicationemail.map((data, i) => {
                                                                    return <label className="selectgroup-item">
                                                                        <input type="checkbox" name="value" className="selectgroup-input" value="CSS" checked={true} disabled />
                                                                        <span className="selectgroup-button">{data} {this.state.modalType !== 'View' && <i class="fa fa-times ml-2" aria-hidden="true" style={{ outline: 'none' }} onClick={() => this.removeEmail(i)}></i>}</span>
                                                                    </label>
                                                                })
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                            </div >
                                        </div >
                                    </div >

                                    {lagLonData ? <div className="card">
                                        < div className="card-body" >
                                            <h3 className="card-title">Hotel Location</h3>
                                            {
                                                lagLonData.map((element, index) => {
                                                    return (
                                                        <div className="row">
                                                            <div className="col-sm-6 col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label">
                                                                        Latitude
                                                                    </label>
                                                                    <input type="text" id={index} className="form-control" disabled={this.state.modalType == "View"} name='lat' onChange={e => this.onChangeLatLon(index, e)} value={element.lat} placeholder=" Latitude" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label">
                                                                        Longitude
                                                                    </label>
                                                                    <input type="text" id={index} className="form-control" disabled={this.state.modalType == "View"} name='lon' onChange={e => this.onChangeLatLon(index, e)} value={element.lon} placeholder=" Longitude" />
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3 col-md-2" style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                                                <div className="form-group">
                                                                    <button className="btn  locationButton location_add_btn" type="button" onClick={() => this.addFormFields(element, index)}>+</button>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-3 col-md-2" style={{ display: 'flex', justifyContent: 'left', alignItems: 'end' }}>
                                                                {
                                                                    lagLonData.length == 1
                                                                        ?
                                                                        <div className="form-group reset_button_hotel">
                                                                            <button type="button" className="btn  locationButton " onClick={() => this.removeFormFields(index)}><i className="fa fa-undo mr-2"></i>Reset</button>
                                                                        </div>
                                                                        :
                                                                        <div className="form-group">
                                                                            <button type="button" className="btn  locationButton" onClick={() => this.removeFormFields(index)}>-</button>
                                                                        </div>
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div >
                                    </div > : ""
                                    }

                                    {/* <div className="card">
                                        <div className="card-body">
                                            <h3 className="card-title">Social Media Details</h3>
                                            <div className="row">
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Website
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='website' onChange={this.onChange} value={data.website} placeholder=" Website" />
                                                        <div className="invalid-feedback" style={{ display: this.state.websiteError ? "block" : 'none' }}>Website URL is not valid</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Booking URL
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='bookingurl' onChange={this.onChange} value={data.bookingurl} placeholder=" Booking URL" />
                                                        <div className="invalid-feedback" style={{ display: this.state.bookingurlError ? "block" : 'none' }}>Booking URL is not valid</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Facebook
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='facebook' onChange={this.onChange} value={data.facebook} placeholder=" Facebook" />
                                                        <div className="invalid-feedback" style={{ display: this.state.facebookError ? "block" : 'none' }}>Facebook URL is not valid</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Twitter
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='twitter' onChange={this.onChange} value={data.twitter} placeholder=" Twitter" />
                                                        <div className="invalid-feedback" style={{ display: this.state.twitterError ? "block" : 'none' }}>Twitter URL is not valid</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Instagram
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='instagram' onChange={this.onChange} value={data.instagram} placeholder=" Instagram" />
                                                        <div className="invalid-feedback" style={{ display: this.state.instagramError ? "block" : 'none' }}>Instagram URL is not valid</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Pinterest
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='pinterest' onChange={this.onChange} value={data.pinterest} placeholder=" Pinterest" />
                                                        <div className="invalid-feedback" style={{ display: this.state.pinterestError ? "block" : 'none' }}>Pinterest URL is not valid</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            YouTube
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='youtube' onChange={this.onChange} value={data.youtube} placeholder=" YouTube" />
                                                        <div className="invalid-feedback" style={{ display: this.state.youtubeError ? "block" : 'none' }}>YouTube URL is not valid</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            LinkedIn
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='linkedin' onChange={this.onChange} value={data.linkedin} placeholder=" LinkedIn" />
                                                        <div className="invalid-feedback" style={{ display: this.state.linkedinError ? "block" : 'none' }}>LinkedIn URL is not valid</div>
                                                    </div>
                                                </div>
                                                <div className="col-sm-6 col-md-4">
                                                    <div className="form-group">
                                                        <label className="form-label">
                                                            Tiktok
                                                        </label>
                                                        <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='tiktok' onChange={this.onChange} value={data.tiktok} placeholder=" Tiktok" />
                                                        <div className="invalid-feedback" style={{ display: this.state.tiktokURL ? "block" : 'none' }}>Tiktok URL is not valid</div>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div> */}
                                    <>
                                        {
                                            data.socialmedias ?
                                                <div className="card">
                                                    <div className="card-body">
                                                        <h3 className="card-title">Social Media Details</h3>
                                                        {data.socialmedias.map((element, index) => {
                                                            let socialArray = []
                                                            let valueSelected = ''
                                                            if (SocialMedia.length > 0 && SocialMedia != undefined) {
                                                                SocialMedia.map(e => {
                                                                    let status = data.socialmedias.find(obj => { return obj.socialmediaid == e._id })
                                                                    if (!status && e.socialmediaid != e._id) {
                                                                        socialArray.push(e)
                                                                    }
                                                                    if (element.socialmediaid === e._id) {
                                                                        valueSelected = { label: e.socialmedianame, value: e._id, name: 'socialmediaid', logo: e.socialmediaimage }
                                                                    }
                                                                })
                                                            }
                                                            return (
                                                                <div className="row">
                                                                    <div className="col-sm-6 col-md-3">
                                                                        <div className="form-group" style={{ fontSize: "13px" }}>
                                                                            <label className="form-label">
                                                                                Social Media Name
                                                                            </label>
                                                                            <Select
                                                                                value={valueSelected ? valueSelected : ''}
                                                                                options={socialArray ? socialArray.map(item => {
                                                                                    return {
                                                                                        label: item.socialmedianame, value: item._id, name: 'socialmediaid', logo: item.socialmediaimage, _id: item._id
                                                                                    }
                                                                                }) : ''}
                                                                                onChange={e => this.elfightmediaChange(index, e, 'socialmediaid')}
                                                                                formatOptionLabel={(e) => (
                                                                                    <div style={{ display: 'block', alignItems: 'center' }}>
                                                                                        <img src={ImportedURL.FILEURL + e.logo} style={{ height: '35px', width: '45px', objectFit: 'contain' }} className="contain_image" onError={onErrorImage} />
                                                                                        <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                                                    </div>

                                                                                )}
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6 col-md-5">
                                                                        <div className="form-group">
                                                                            <label className="form-label">
                                                                                Social Media Url
                                                                            </label>
                                                                            <input type="text" id={index} className="form-control" disabled={this.state.modalType == "View"} name='socialmediaurl' onChange={e => this.elfighturlChange(index, e, 'socialmediaurl')} value={element.socialmediaurl} placeholder="Social Media Url" />
                                                                            <div className="invalid-feedback" style={{ display: this.socialMediaChange(index) ? "block" : 'none' }}>Social Media URL is not valid</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-6 col-md-4">
                                                                        <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                                                            <div style={{ marginBottom: "22px", marginRight: '20px' }}>
                                                                                <label className="custom-control custom-switch mr-3">
                                                                                    <input type="checkbox" className="custom-control-input" name="socialmediastatus" checked={element.socialmediastatus} onChange={e => this.onChangeelfsights(index, e, 'socialmediastatus')} />
                                                                                    <span className="custom-control-label" style={{ cursor: 'pointer' }}>Status</span>
                                                                                </label>
                                                                            </div>
                                                                            <div className="mt-3" >
                                                                                <div className="form-group" >
                                                                                    <button className="btn elfplusbtn locationButton location_add_btn" type="button" onClick={() => this.addelfsightsFields(element, index, 'socialmedias')}>+</button>
                                                                                </div>
                                                                            </div>
                                                                            <div className="mt-3" style={{ display: 'flex', justifyContent: 'left', alignItems: 'end', }}>
                                                                                {
                                                                                    data.socialmedias.length == 1
                                                                                        ? ""
                                                                                        :
                                                                                        <div className="form-group mx-2" >
                                                                                            <button type="button" className="btn elfminusbtn locationButton" onClick={() => this.removeSocialMedia(index)}>-</button>
                                                                                        </div>
                                                                                }
                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div> : ""
                                        }
                                    </>
                                    {ImportedURL.SUPERADMIN.includes(accountHotel.role) &&
                                        <>
                                            {
                                                data.socialreviews ?
                                                    <div className="card">
                                                        <div className="card-body">
                                                            <h3 className="card-title">OTA 's Review (elfsights)</h3>
                                                            <div className="custom-controls-stacked mb-4" style={{ marginLeft: "35px" }}>
                                                                <label className="custom-control custom-switch" style={{ width: '150px', }}>
                                                                    <input type="checkbox" className="custom-control-input " name="pullsocialreview" checked={data.pullsocialreview} onChange={this.onChange} />
                                                                    <span className="custom-control-label" style={{ cursor: 'pointer' }}>Pull Social review</span>
                                                                </label>
                                                            </div>
                                                            {data.socialreviews.map((element, index) => {
                                                                let socialArray = []
                                                                let valueSelected = ''
                                                                if (SocialMedia.length > 0 && SocialMedia != undefined) {
                                                                    SocialMedia.map(e => {
                                                                        let status = data.socialreviews.find(obj => { return obj.socialmediaid == e._id })
                                                                        if (!status && e.socialmediaid != e._id) {
                                                                            socialArray.push(e)
                                                                        }
                                                                        if (element.socialmediaid === e._id) {
                                                                            valueSelected = { label: e.socialmedianame, value: e._id, name: 'socialmediaid', logo: e.socialmediaimage }
                                                                        }
                                                                    })
                                                                }
                                                                return (
                                                                    <div className="row">
                                                                        <div className="col-sm-6 col-md-3">
                                                                            <div className="form-group" style={{ fontSize: "13px" }}>
                                                                                <label className="form-label">
                                                                                    OTA 's Name
                                                                                </label>
                                                                                <Select
                                                                                    value={valueSelected ? valueSelected : ''}
                                                                                    options={socialArray ? socialArray.map(item => {
                                                                                        return {
                                                                                            label: item.socialmedianame, value: item._id, name: 'socialmediaid', logo: item.socialmediaimage, _id: item._id
                                                                                        }
                                                                                    }) : ''}
                                                                                    onChange={e => this.elfightmediaChange(index, e, 'socialreviewid')}
                                                                                    formatOptionLabel={(e) => (
                                                                                        <div style={{ display: 'block', alignItems: 'center' }}>
                                                                                            <img src={ImportedURL.FILEURL + e.logo} style={{ height: '35px', width: '45px', objectFit: 'contain' }} className="contain_image" onError={onErrorImage} />
                                                                                            <span style={{ marginLeft: 5 }}>{e.label}</span>
                                                                                        </div>

                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6 col-md-5">
                                                                            <div className="form-group">
                                                                                <label className="form-label">
                                                                                    OTA 's  Url
                                                                                </label>
                                                                                <input type="text" id={index} className="form-control" disabled={this.state.modalType == "View"} name='socialmediaurl' onChange={e => this.elfighturlChange(index, e, 'socialreviewurl')} value={element.socialmediaurl} placeholder="OTA 's  Url" />
                                                                                <div className="invalid-feedback" style={{ display: this.urlChange(index) ? "block" : 'none' }}>Elf URL is not valid</div>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-sm-6 col-md-4">
                                                                            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'end' }}>
                                                                                <div style={{ marginBottom: "22px", marginRight: '20px' }}>
                                                                                    <label className="custom-control custom-switch mr-3">
                                                                                        <input type="checkbox" className="custom-control-input" name="socialmediastatus" checked={element.socialmediastatus} onChange={e => this.onChangeelfsights(index, e, 'socialreviewstatus')} />
                                                                                        <span className="custom-control-label" style={{ cursor: 'pointer' }}>Status</span>
                                                                                    </label>
                                                                                </div>
                                                                                <div className="mt-3 " >
                                                                                    <div className="form-group" >
                                                                                        <button className="btn elfplusbtn locationButton location_add_btn" type="button" onClick={() => this.addelfsightsFields(element, index, "socialreviews")}>+</button>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="mt-3" style={{ display: 'flex', justifyContent: 'left', alignItems: 'end', }}>
                                                                                    {
                                                                                        data.socialreviews.length == 1
                                                                                            ? ""
                                                                                            :
                                                                                            <div className="form-group mx-2" >
                                                                                                <button type="button" className="btn elfminusbtn  locationButton" onClick={() => this.removelfsightFields(index)}>-</button>
                                                                                            </div>
                                                                                    }
                                                                                </div>

                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                )
                                                            })}
                                                        </div>
                                                    </div> : ""
                                            }
                                        </>
                                    }

                                    <div className="card">
                                        <div className="card-body">
                                            <h3 className="card-title">Shift Timing</h3>
                                            {data.shifttime.map((element, index) => {
                                                return (
                                                    <div className="row" key={index}>
                                                        <div className='col-lg-3 col-md-12'>
                                                            <div className="form-group">
                                                                <label className="form-label">Shift In Time</label>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ position: 'relative' }}>
                                                                    <TimePicker value={element.shiftintime ? element.shiftintime : null} helperText={null} className="time-style" name="shiftintime" onChange={e => this.onchangeShiftInTime("shiftintime", index, e)} />
                                                                    <i className="fa fa-clock-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7', position: 'absolute', top: "37px", right: "20px" }}></i>
                                                                </MuiPickersUtilsProvider>
                                                                <div className="invalid-feedback" style={{ display: this.FindShiftErrorStatus(index, this.state.shiftinArrayError) ? "block" : 'none' }}>Shift In Time is required</div>
                                                            </div>
                                                        </div>

                                                        <div className='col-lg-3 col-md-12'>
                                                            <div className="form-group">
                                                                <label className="form-label">Shift Out Time</label>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ position: 'relative' }}>
                                                                    <TimePicker value={element.shiftouttime ? element.shiftouttime : null} helperText={null} className="time-style" name="shiftouttime" onChange={e => this.onchangeShiftOutTime("shiftouttime", index, e)} />
                                                                    <i className="fa fa-clock-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7', position: 'absolute', top: "37px", right: "20px" }}></i>
                                                                </MuiPickersUtilsProvider>
                                                                <div className="invalid-feedback" style={{ display: this.FindShiftErrorStatus(index, this.state.shiftoutArrayError) ? "block" : 'none' }}>Shift Out Time is required</div>
                                                            </div>
                                                        </div>
                                                        <div className='col-lg-3 col-md-12'>
                                                            <div className="form-group">
                                                                <label className="form-label">Shift</label>
                                                                <Select
                                                                    value={element.shift ? { label: element.shift } : ''}
                                                                    options={shiftOptions}
                                                                    onChange={e => this.onChangeShift(index, e)}
                                                                />
                                                                <div className="invalid-feedback" style={{ display: this.FindShiftErrorStatus(index, this.state.shiftArrayError) ? "block" : 'none' }}>Shift is required</div>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-3 col-md-12">
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'end' }}>
                                                                {
                                                                    data.shifttime.length !== 5
                                                                        ?
                                                                        <div className="mt-3 ml-3" >
                                                                            <div className="form-group" >
                                                                                <button className="btn elfplusbtn locationButton location_add_btn" type="button" onClick={() => this.addShiftFields(element, index)}>+</button>
                                                                            </div>
                                                                        </div>
                                                                        : ""
                                                                }
                                                                {
                                                                    data.shifttime && data.shifttime.length == 1
                                                                        ? ""
                                                                        :
                                                                        <div className="mt-3 ml-3" style={{ display: 'flex', justifyContent: 'left', alignItems: 'end' }}>
                                                                            <div className="form-group mx-2" >
                                                                                <button type="button" className="btn elfminusbtn  locationButton" onClick={() => this.removeShiftFields(index)}>-</button>
                                                                            </div>
                                                                        </div>
                                                                }

                                                            </div>

                                                        </div>
                                                    </div>
                                                )
                                            })}

                                            <h3 className="card-title">Stay Schedule</h3>
                                            <div className="row">
                                                <div className='col-lg-3 col-md-12'>
                                                    <div className="form-group">
                                                        <label className="form-label">Check In Time</label>
                                                        <TimeComponent value={data.checkintime} onChange={(e) => this.onChangeTime(e, 'checkintime')} name="checkintime" />
                                                    </div>
                                                </div>
                                                <div className='col-lg-3 col-md-12'>
                                                    <div className="form-group">
                                                        <label className="form-label">Check Out Time</label>
                                                        <TimeComponent value={data.checkouttime} onChange={(e) => this.onChangeTime(e, 'checkouttime')} name="checkouttime" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        this.state.modalType == "Edit" ?
                                            <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                <div className="text-right">
                                                    <div>
                                                        {
                                                            this.state.saving ?
                                                                <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                                :
                                                                <button type="submit" className="btn commor_save" onClick={this.SubmitForm}>
                                                                    <i className="fe fe-save mr-2"></i>Update
                                                                </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                <div className="text-right d-flex">
                                                    <div className="form-group d-flex" style={{ marginTop: '10px', marginRight: "30px" }}>
                                                        {
                                                            ImportedURL.SUPERADMIN.includes(accountHotel.role)
                                                            &&
                                                            <label className="custom-control custom-switch mr-3">
                                                                <input type="checkbox" className="custom-control-input" name="mailstatus" checked={data.mailstatus} onChange={this.onChange} />
                                                                <span className="custom-control-label" style={{ cursor: 'pointer' }}>Send Mail</span>
                                                            </label>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            this.state.saving ?
                                                                <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                                :
                                                                <button type="submit" className="btn commor_save" onClick={this.SubmitForm}>
                                                                    <i className="fe fe-save mr-2"></i>Save
                                                                </button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                    }
                                </div >
                            </div >
                        </div >
                    </div >

                    <div id="overlay" style={{ display: this.state.spinner ? (dataSpinner ? 'block' : 'none') : 'none' }}></div>
                    {
                        this.state.spinner && (dataSpinner &&
                            <div className="common_loader">
                                <img className='loader_img_style_common' src='../../assets/images/load.png' alt='' />
                                <Spinner className='spinner_load_common' animation="border" variant="info" >
                                </Spinner>
                            </div>)
                    }

                </div >
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    HotelState: state.hotel,
    AccountState: state.account,
    LandingState: state.landing,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleInputChangeElf: AC_HANDLE_HOTEL_ELFSIGHTS_CHANGE,
        HandleChangeSocialMedia: AC_HANDLE_HOTEL_SOCAILMEDIA_CHANGE,
        HandleMultipleUpdateElf: AC_HANDLE_HOTEL_ELFSIGHTS_MULTIPLE_UPDATE,
        EmptyHotel: AC_EMPTY_HOTEL,
        HandleMultipleUpdate: AC_HANDLE_HOTEL_MULTIPLE_UPDATE,
        HandleInputChangeMultiple: AC_HANDLE_HOTEL_CHANGE_MULTIPLE,
        ListUser: AC_LIST_USER_OWNER,
        HandleInputChange: AC_HANDLE_HOTEL_CHANGE,
        AddHotel: AC_ADD_HOTEL,
        ViewHotel: AC_VIEW_HOTEL,
        ResetHotel: AC_RESET_HOTEL,
        LandingData: AC_PUSH_DATA_LANDING,
    }, dispatch)

}
export default connect(mapStateToProps, mapDispatchToProps)(AddHotel);

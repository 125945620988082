import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ModalSpinnerComponent from '../../../utilities/Spinner/modalspinner';
import ImportedURL from '../../../common/api';
import { onErrorImage } from '../../../common/validate';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import ReactImageVideoLightbox from "react-image-video-lightbox";
import { AC_HANDLE_TO_DO_LIST_CHANGE } from '../../../actions/todolistAction';
import ErrorResponseComponent from '../../../utilities/ErrorStatus/errorresponse';
import axios from 'axios';
import moment from 'moment';

class ViewModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalType: "View",
            responsive: {
                superLargeDesktop: {
                    // the naming can be any, depends on you.
                    breakpoint: { max: 4000, min: 3000 },
                    items: 5
                },
                desktop: {
                    breakpoint: { max: 3000, min: 1024 },
                    items: 3
                },
                tablet: {
                    breakpoint: { max: 1024, min: 464 },
                    items: 2
                },
                mobile: {
                    breakpoint: { max: 464, min: 0 },
                    items: 1
                }
            },
        };
    }
    findImageSrc = (image) => {
        if (!image) return ''
        let src = ''
        if (typeof image == 'string') {
            const fileType = image.toLowerCase();
            const isPDF = fileType.endsWith('.pdf');
            if (isPDF) {
                src = '../../assets/images/pdf.png';
            } else {
                src = ImportedURL.FILEURL + image
            }
        } else {
            const fileType = image.type.toLowerCase();
            const isPDF = fileType.startsWith('application/');
            if (isPDF) {
                src = '../../assets/images/pdf.png';
            } else {
                src = window.URL.createObjectURL(image);
            }
        }
        return src
    }
    submit = (arr) => {
        axios.post(ImportedURL.API.todolistProgress + '/' + this.props.id, { tasklist: arr })
            .then((res) => {
            }).catch(({ response }) => {
                this.setState({ saving: false })
                ErrorResponseComponent(response)
            });
    }
    onChangeCheckList = (id) => {
        const data = this.props.TodoListState.todo;
        var tasklist = data.tasklistprogress ? [...data.tasklistprogress] : []
        let status = tasklist.some((e) => e == id)
        if (status) {
            let arr = tasklist.filter((e) => e != id)
            this.props.HandleInputChange('tasklistprogress', arr);
            this.submit(arr);
        } else {
            tasklist.push(id)
            this.props.HandleInputChange('tasklistprogress', tasklist);
            this.submit(tasklist);
        }
    }

    dateDifferenceInDays = (startDate, endDate, startdate) => {
        const oneMinute = 60 * 1000;
        const start = new Date(startDate);
        const end = new Date(endDate);
        const diffDays = ((end - start) / oneMinute);
        return this.addDaysToDate(startdate, diffDays);
    }

    addDaysToDate = (date, daysToAdd) => {
        const currentDate = moment(date);
        const newDate = currentDate.clone().add(daysToAdd, 'minutes');
        const result = newDate.format("MM-DD-YYYY hh:mm A");
        return result;
    }
    render() {
        const { AccountState, spinner, data, checkListStatus, startdate } = this.props
        const languageData = AccountState.account.SelectedlanguageData;
        var account = AccountState.account;
        var dtFormat = (account.dateformate != '' ? account.dateformate : 'MM-DD-YYYY') + ' ' + (account.timeformat != '' ? account.timeformat : "hh:mm A")
        var dFormat = (account.dateformate != '' ? account.dateformate : 'MM-DD-YYYY');

        let getStartDate = data.startdate ? startdate ? startdate.slice(0, 9) + data.startdate.slice(9) : data.startdate : '';
        let getDueDate = data.duedate ? startdate ? this.dateDifferenceInDays(data.startdate, data.duedate, getStartDate) : data.duedate : '';
        let getReminderDate = data.reminderdate ? startdate ? this.dateDifferenceInDays(data.startdate, data.reminderdate, getStartDate) : data.reminderdate : '';

        return (
            <>
                <div className="modal fade todo_list_mytask_view" id="exampleTodoModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-lg view_user_modal max_width" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className='rounded_icon'><i className="icon-clock mr-2 "></i></div>
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} {(languageData && languageData.VIEW_TODO ? languageData.VIEW_TODO : "Task")}</h5>
                                <button type="button" id="closeTodoModal" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='userView'>
                                    <div className='row'>
                                        <div className="col-lg-12 col-xl-12">
                                            <div className='ribbon'>
                                                <div className="ribbon-box orange">{data.title}</div>
                                            </div>
                                        </div>
                                        <div className='col-lg-5 mt-5'>
                                            <div className="col-lg-12 col-xl-12 mt-3" >
                                                <label className="mb-2 mt-1" style={{ fontWeight: "bolder" }}>Check List</label>
                                                {
                                                    data.tasklistName && data.tasklistName.length > 0 ?
                                                        <>
                                                            <h4 className={data.tasklistName.length > 5 ? "font-18 duration_scroll" : "font-18"}>
                                                                {
                                                                    checkListStatus ?
                                                                        <>
                                                                            {data.tasklistName.map((item, i) => {
                                                                                const checked = (data.tasklistprogress && data.tasklistprogress.length > 0) ? data.tasklistprogress.includes(data.tasklist[i]) : false;
                                                                                if (item && item != undefined) {
                                                                                    return (
                                                                                        <>
                                                                                            <li class="list-group-item" key={i}>
                                                                                                <div class="custom-control custom-checkbox">
                                                                                                    <input type="checkbox" class="custom-control-input" checked={checked} id={data.tasklist[i]} />
                                                                                                    <label class="custom-control-label" for={data.tasklist[i]} onClick={() => this.onChangeCheckList(data.tasklist[i])}>{item}</label>
                                                                                                </div>
                                                                                            </li>
                                                                                        </>
                                                                                    )
                                                                                }
                                                                            })}
                                                                        </> :
                                                                        <>
                                                                            {data.tasklistName.map((item, i) => {
                                                                                return (
                                                                                    <>
                                                                                        <p key={i} style={{ display: 'flex', paddingRight: "5px" }}><i className="fa fa-circle viewtask_todo_iconcircle mr-2" aria-hidden="true"></i>{item}</p></>
                                                                                )
                                                                            })}
                                                                        </>
                                                                }
                                                            </h4>
                                                        </> :
                                                        <div className="card-body no_records text-center d-flex align-items-center justify-content-center">
                                                            <div style={{ maxWidth: "340px" }}>
                                                                <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid mb-4 mt-0" style={{ maxWidth: "150px" }} />
                                                                <p className="mb-2">No records to display</p>
                                                            </div>
                                                        </div>}
                                            </div>
                                            <div className="col-lg-12 col-xl-12">
                                                {(data.attachments && data.attachments.length > 0) ? <label className="mb-2 mt-1" style={{ fontWeight: "bolder" }}>Attachment</label> : ""}
                                                <Carousel responsive={this.state.responsive}>
                                                    {(data.attachments && data.attachments.length > 0) ?
                                                        data.attachments.map((item, i) => {
                                                            if (data.attachments) {
                                                                const isPDF = (item && typeof item == 'string') ? item.toLowerCase().endsWith('.pdf') : '';
                                                                return (
                                                                    <div className='allmytask_img_size' key={i}>
                                                                        {isPDF ? (
                                                                            <a href={ImportedURL.FILEURL + item} className="pdf-image-link" target='_blank'>
                                                                                <img src={this.findImageSrc(item)} alt='Not found' onError={onErrorImage} />
                                                                            </a>
                                                                        ) : (
                                                                            <img src={this.findImageSrc(item)} alt='Not found' onError={onErrorImage} onClick={() => { this.setState({ showModalWsa: true, indexOfImagesWsa: i }) }} />
                                                                        )}
                                                                    </div>
                                                                );
                                                            }
                                                        }) : <></>}
                                                </Carousel>
                                            </div>
                                            {this.state.showModalWsa && (
                                                <div className='react-image-video-lightbox'>
                                                    <ReactImageVideoLightbox
                                                        data={data.attachments
                                                            // .filter((item) => !item.toLowerCase().endsWith('.pdf')) // Filter out PDF files
                                                            .map((item) => ({
                                                                url: this.findImageSrc(item), // Assuming findImageSrc() returns the image URL
                                                                type: 'photo',
                                                                altTag: 'Image File'
                                                            }))
                                                        }
                                                        startIndex={this.state.indexOfImagesWsa}
                                                        showResourceCount={true}
                                                        onCloseCallback={() => this.setState({ showModalWsa: false })}
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <div className='col-lg-7 px-3 mt-5'>
                                            <div className='User_part_right row'>
                                                {data.usersNameCategory ?
                                                    <div className='col-12'>
                                                        <label >User Name</label> <br />
                                                        <p className='mb-4'>{data.usersNameCategory}</p>
                                                    </div>
                                                    : ""}
                                                {data.description ?
                                                    <>
                                                        <div className='col-12'>
                                                            <label >Description</label> <br />
                                                            <p className='mb-4' style={{ wordWrap: 'break-word' }}>{data.description}</p>
                                                        </div>
                                                    </> : ''}
                                                {getStartDate ?
                                                    <div className='col-6'>
                                                        <label >Start Date</label> <br />
                                                        <p className='mb-4'>{moment(getStartDate).format(dtFormat)}</p>
                                                    </div>
                                                    : ""}
                                                {getDueDate ?
                                                    <div className='col-6'>
                                                        <label >Due Date</label> <br />
                                                        <p className='mb-4'>{moment(getDueDate).format(dtFormat)}</p>
                                                    </div>
                                                    : ''}
                                                {data.durationName ?
                                                    <div className='col-6'>
                                                        <label >Duration</label> <br />
                                                        <p className='mb-4'>{data.durationName}</p>
                                                    </div>
                                                    : ''}
                                                {data.numberofinterval ?
                                                    <div className='col-6'>
                                                        <label >Number Of Interval</label> <br />
                                                        <p className='mb-4'>{data.numberofinterval}</p>
                                                    </div>
                                                    : ''}
                                                {(data.endtype && data.endtype == "occurrence" && data.numberofoccurrence) ?
                                                    <div className='col-6'>
                                                        <label >Number Of Occurrence</label> <br />
                                                        <p className='mb-4'>{data.numberofoccurrence}</p>
                                                    </div> : ''}
                                                {(data.enddate && data.showEndDate) ?
                                                    <div className='col-6'><label >End Date</label> <br />
                                                        <p className='mb-4'>{moment(data.enddate).format(dFormat)}</p>
                                                    </div> : ''}
                                                {getReminderDate ?
                                                    <div className='col-6'>
                                                        <label >Reminder Date</label> <br />
                                                        <p className='mb-4'>{moment(getReminderDate).format(dtFormat)}</p>
                                                    </div> : ''}
                                                {data.priorityName ?
                                                    <div className='col-6'>
                                                        <label >Priority</label> <br />
                                                        <p className='mb-4'>{data.priorityName}
                                                            {data.priorityColour ?
                                                                <i className="fa fa-flag ml-2" aria-hidden="true" style={{ color: data.priorityColour }}></i>
                                                                : ''}
                                                        </p>
                                                    </div> : ''}
                                                {data.mylistName ?
                                                    <div className='col-6'>
                                                        <label >My List</label> <br />
                                                        <p className='mb-4'>{data.mylistName}</p>
                                                    </div> : ''}
                                                {data.tagsName ?
                                                    <div className='col-6'>
                                                        <label >Tag</label> <br />
                                                        <p className='mb-4'>{data.tagsName}</p>
                                                    </div> : ''}
                                                {data.createdbyName ?
                                                    <div className='col-6'>
                                                        <label >Created By</label> <br />
                                                        <p className='mb-4'>{data.createdbyName}</p>
                                                    </div>
                                                    : ''}
                                                {data.editedbyName && data.editedbyName.length > 0 ?
                                                    <div className='col-6'>
                                                        <label >Edited By</label> <br />
                                                        <p className='mb-4'>{data.editedbyName}</p>
                                                    </div>
                                                    : ''}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <ModalSpinnerComponent spinner={spinner} />
                        </div>
                    </div>
                </div >
            </>
        )
    }
}

const mapStateToProps = state => ({
    AccountState: state.account,
    TodoListState: state.todolist,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleInputChange: AC_HANDLE_TO_DO_LIST_CHANGE,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewModal);

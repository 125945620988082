import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ReactApexChart from 'react-apexcharts';

class Piechart extends Component {
    constructor(props) {
        super(props);
        this.state = {
            series: [],
            options: {
                chart: {
                    width: 380,
                    type: 'pie',
                },
                labels: ['Open', 'Assigned', 'In-Progress ', 'In-Review ', 'Completed', 'Deleted'],
                legend: {
                    position: 'bottom',
                    markers: {
                        fillColors: [
                            '#0080ff', // Blue
                            '#00cccc', // Light Cyan
                            '#cccc00', // Dark Yellow
                            '#4a80c2', // Light Blue
                            // '#00cc66', // Light Green
                            // '#ff9933', // Light Orange
                            '#55cc55', // Light Green
                            '#cc6666'  // Light Red
                        ]
                    }
                },
                fill: {
                    colors: [
                        '#0080ff', // Blue
                        '#00cccc', // Light Cyan
                        '#cccc00', // Dark Yellow
                        '#4a80c2', // Light Blue
                        // '#00cc66', // Light Green
                        // '#ff9933', // Light Orange
                        '#55cc55', // Light Green
                        '#cc6666'  // Light Red
                    ]
                },
                dataLabels: {
                    enabled: true
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                        chart: {
                            width: "100%"
                        },
                        legend: {
                            position: 'bottom'
                        }
                    }
                }]
            },
        };
    }

    render() {
        const { TicketState } = this.props;
        const ticketStatusCount = TicketState;
        let isShow = true;
        if (ticketStatusCount.openCount == 0 && ticketStatusCount.assignedCount == 0 &&
            ticketStatusCount.inprogressCount == 0 && ticketStatusCount.inreviewCount == 0 &&
            ticketStatusCount.completedCount == 0 && ticketStatusCount.deletedCount == 0) {
            isShow = false;
        }
        const seriesArray = [ticketStatusCount.openCount, ticketStatusCount.assignedCount,
        ticketStatusCount.inprogressCount, ticketStatusCount.inreviewCount,
        ticketStatusCount.completedCount, ticketStatusCount.deletedCount];
        return (
            <>{
                isShow ? <div className="col-xl-7 col-lg-7 col-md-7" >
                    <div className="card">
                        <div className="card-body">
                            <div id="chart">
                                <ReactApexChart options={this.state.options} series={seriesArray} type="pie" width="100%" height="350" />
                            </div>

                        </div>
                    </div>
                </div> :
                    <div className="col-xl-7 col-lg-7 col-md-7">
                        <div className="card">
                            <div className="card-body text-center d-flex align-items-center justify-content-center">
                                <div style={{ maxWidth: "340px" }}>
                                    <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid mb-4 mt-4" style={{ maxWidth: "272px" }} />
                                    <h5 className="mb-2">Tickets not yet added in this hotel</h5>
                                    <p className="text-muted">Once after successfull ticket creation you will get your report by chart</p>
                                </div>
                            </div>
                        </div>
                    </div>
            }
            </>
        );
    }
}

const mapStateToProps = state => ({
    TicketState: state.ticket,
})
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Piechart);

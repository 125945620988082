
const initialState = {

    room: {
        hotel: '',
        roomtype: '',
        area: '',
        maxquantity: '',
        floor: '',
        door: '',
        description: '',
        roomno: '',
        amenity: [],
        itemvalue: [{
            bedname: '',
            width: '',
            length: '',
            mattress: '',
            quantity: '',
        }],
        reason: '',
        startdate: '',
        enddate: '',
        tabposition: 'rooms',
        roomtypeCondition: false,
        rooms: [],
        from: '',
    },
    roomQrData: {},
    listSettingRoom: [],
    spinner: true,
    total: 0,
    loader: true
}

export default (state = initialState, action) => {

    switch (action.type) {
        case 'SETTING_ROOM_HANDLE_CHANGE':
            return Object.assign({}, state, {
                room: {
                    ...state.room,
                    [action.name]: action.value
                }
            })
        case 'HANDLE_ROOM_AMENITY_REMOVE':
            return {
                ...state,
                room: {
                    ...state.room,
                    amenity: [...state.room.amenity.filter((e, i) => e.amenity !== action.id)]
                }
            }
        case 'HANDLE_FACILITY_REMOVE':
            return {
                ...state,
                room: {
                    ...state.room,
                    facility: [...state.room.facility.filter((e, i) => e.facility !== action.id)]
                }
            }
        case 'SETTING_ROOM_HANDLE_FORM_CHANGE':
            return Object.assign({}, state, {
                roominfo: {
                    ...state.roominfo,
                    [action.name]: action.value
                }
            })
        case 'HANDLE_MULTIROW_CLICK':
            return {
                ...state,
                room: {
                    ...state.room,
                    itemvalue:
                        [
                            ...state.room.itemvalue.slice(0, action.i),
                            {
                                ...state.room.itemvalue[action.i],
                                [action.name]: action.value
                            },
                            ...state.room.itemvalue.slice(action.i + 1)
                        ]
                }
            }
        case 'ROOM_CHECKBOX_CHANGE':
            return {
                ...state,
                room: {
                    ...state.room,
                    amenity: [...state.room.amenity, action.value]
                }
            }
        case 'RESET_SETTING_ROOM':
            return Object.assign({}, state, {
                room: initialState.room
            })
        case 'LIST_SETTING_ROOM':
            return Object.assign({}, state, {
                listSettingRoom: action.payload,
                total: action.total,
                spinner: action.spinner
            })
        case 'VIEW_SETTING_ROOM':
            return Object.assign({}, state, {
                room: action.payload,
                spinner: false,
            })
        case 'PASS_VIEW_SETTING_ROOM_DATA':
            return Object.assign({}, state, {
                room: action.payload,
                spinner: false,
            })
        case 'VIEW_SETTING_ROOM_QRDATA':
            return Object.assign({}, state, {
                roomQrData: action.payload,
                loader: false,
            })
        case 'HANDLE_ROOMBED_UPDATE':
            if (action.id === '') {
                return {
                    ...state,
                    room: {
                        ...state.room,
                        itemvalue: [...state.room.itemvalue, action.value]
                    }
                }
            }
            else {
                return {
                    ...state,
                    room: {
                        ...state.room,
                        itemvalue: [...state.room.itemvalue.filter((event, i) => i !== action.id)]
                    }
                }
            }
        case 'HANDLE_ROOM_INPUT_CHANGE':
            return {
                ...state,
                room: {
                    ...state.room,
                    rooms: [
                        ...state.room.rooms.slice(0, action.index),
                        {
                            ...state.room.rooms[action.index],
                            [action.name]: action.value
                        },
                        ...state.room.rooms.slice(action.index + 1)
                    ]
                }
            }
        case 'EMPTY_ROOM':
            return Object.assign({}, state, {
                spinner: true
            })
        default:
            return state;
    }
}
import CONSTANTS from "../common/constants"

const initialState = {
	user: {
		name: '',
		status: 1,
		email: '',
		category: '',
		phonenumber: '',
		previleges: CONSTANTS.previleges,
		cc: '',
		locationrestrict: '',
		namechange: false,
		ccchange: false,
		phonenumberchange: false,
		emailchange: false,
		multiplechange: false,
		logo: '',
		mailstatus: true,
		mandatory: '',
		webaccess: true,
	},
	listusers: [],
	spinner: true,
	total: 0,
	totalStatus: 0,
	activecount: 0,
	inactivecount: 0,
	ticketworkflow: [],
	allUserData: []
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'HANDLE_USER_CHANGE':
			return Object.assign({}, state, {
				user: {
					...state.user,
					[action.name]: action.value
				}
			})
		case 'RESET_USER':
			return Object.assign({}, state, {
				user: initialState.user
			})
		case 'LIST_USER':
			return Object.assign({}, state, {
				listusers: action.payload,
				total: action.result.total,
				totalStatus: action.result.totalStatus,
				activecount: action.result.active,
				inactivecount: action.result.inactive,
				ticketworkflow: action.result.ticketworkflow,
				allUserData: action.result.allUserData,
				spinner: action.spinner
			})
		case 'VIEW_USER':
			return Object.assign({}, state, {
				user: action.payload,
				spinner: action.spinner,

			})
		case "SET_ALL_PREVILEGE":
			return {
				...state,
				user: {
					...state.user,
					previleges: action.payload
				}
			}
		case "CHANGE_PREVILEGE":
			var _i = action.index;
			return {
				...state,
				user: {
					...state.user,
					previleges: [
						...state.user.previleges.slice(0, _i),
						{
							...state.user.previleges[_i],
							[action.key]: !state.user.previleges[_i][action.key]
						},
						...state.user.previleges.slice(_i + 1),
					]
				}
			}
		case 'EMPTY_USER_LIST':
			return Object.assign({}, state, {
				user: {
					name: '',
					email: '',
					category: '',
					phonenumber: '',
					previleges: CONSTANTS.previleges,
					cc: '',
					locationrestrict: '',
				},
				listusers: [],
				spinner: true
			})
		case 'EMPTY_USER':
			return Object.assign({}, state, {
				user: {
					name: '',
					email: '',
					category: '',
					phonenumber: '',
					previleges: CONSTANTS.previleges,
					cc: '',
					locationrestrict: '',
				},
				spinner: true
			})
			break;
		default:
			return state;
	}
}
import React, { Component } from 'react'
import { connect } from 'react-redux';
import axios from 'axios';
import ImportedUrl from '../../common/api';
import { Success, Error } from '../../common/swal';
import { Link } from 'react-router-dom';
import ImportedURL from '../../common/api';
import { Validpassword } from '../../common/validate';

const initialState = {
  password: '',
  newpassword: '',
  confirmpassword: '',
  passwordError: false,
  newpasswordError: false,
  confirmpasswordError: false,
  saving: false,
  eye: false,
  eyeCon: false,
  newpassword1: '',
  confirmpassword1: '',
  newpassword1Error: false,
  confirmpassword1Error: false,
  timer: 60,
  otp: '',
  otpError: false
}
class ChangePassword extends Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  onChange = e => {
    const { name, value } = e.target;
    const Error = name + "Error";
    if (name === 'newpassword' || name === 'newpassword1') {
      if (value) {
        if (Validpassword(value))
          this.setState({ [Error]: false, [name]: value });
        else
          this.setState({ [Error]: 'Password should contain at least one uppercase letter, one lowercase letter, one number, one special character and minimum length of 8', [name]: value });
      }
      else {
        this.setState({ [Error]: 'Password is required', [name]: value });
      }
    } else
      this.setState({ [Error]: false, [name]: value });
  }

  submit = (e) => {
    const { newpassword, password, confirmpassword } = this.state;
    const { AccountState } = this.props;
    const languageData = AccountState.account.SelectedlanguageData
    const account = AccountState.account;
    // if (!password) this.setState({ passwordError: true });
    if (!newpassword) this.setState({ newpasswordError: "Password is required" });
    if (!confirmpassword) this.setState({ confirmpasswordError: "Confirm Password is required" });
    if (newpassword !== confirmpassword) this.setState({ confirmpasswordError: "Password doesn't match" });
    if (newpassword && (newpassword === confirmpassword)) {
      this.setState({ saving: true })
      const formData = { newpassword: newpassword }
      if (account.role == 'vendor') {
        this.setState({ saving: true })
        axios.post(ImportedURL.API.updateVendorProfile + '/' + account._id, formData)
          .then((res) => {
            this.setState({ saving: false })
            this.setState(initialState)
            Success((languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : "updated successfully"));
          })
      } else {
        axios.post(ImportedUrl.API.changePassword, formData)
          .then((res) => {
            this.setState({ saving: false })
            Success((languageData && languageData.PASSWORD ? languageData.PASSWORD : "Password") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : "updated successfully"));
            // Success(res.statusText);
            this.setState(initialState)
          }).catch(({ response }) => {
            if (response) {
              this.setState({ saving: false })
              if (response.status == 400) {
                Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
              }
              else if (response.status == 500) {
                Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
              } else if (response.status == 502) {
                Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
              } else {
                Error(response.statusMessage)
              }
              // Error(response.statusText)
            }
          });
      }
    }
  }

  startTimer = () => {
    this.timerID = setInterval(() => {
      const { timer } = this.state;
      if (timer > 0) {
        this.setState({ timer: timer - 1 });
      } else {
        this.setState({ sendingotp: '' });
        clearInterval(this.timerID);
      }
    }, 1000); // Update every second
  };

  sendotp = () => {
    this.setState({ sendingotp: 'sending' })
    axios.post(ImportedUrl.API.sendotpadminpassword)
      .then((res) => {
        this.setState({ saving: false })
        Success('OTP sent to your phone number');
        this.setState({ sendingotp: 'sentotp', timer: 60 })
        this.startTimer();
      }).catch(({ response }) => {
        Error('OTP not send try again!')
        this.setState({ sendingotp: '' })

      });

  }

  submitAdmin = (e) => {
    const { newpassword1, confirmpassword1, otp } = this.state;
    const { AccountState } = this.props;
    const account = AccountState.account;
    // if (!password) this.setState({ passwordError: true });
    let valid = 1;
    if (!newpassword1) { this.setState({ newpassword1Error: "Password is required" }); valid = 0 }
    if (!confirmpassword1) { this.setState({ confirmpassword1Error: "Confirm Password is required" }); valid = 0 }
    if (!otp) { this.setState({ otpError: "Confirm Password is required" }); valid = 0 }
    if (newpassword1 !== confirmpassword1) { this.setState({ confirmpassword1Error: "Password doesn't match" }); valid = 0 }
    if (valid && !this.state.newpassword1Error) {
      this.setState({ saving: true })
      const formData = { newpassword: newpassword1, otp: otp, type: this.state.changeuserpwd ? 'useradmin' : 'admin' }
      axios.post(ImportedUrl.API.setadminpassword, formData)
        .then((res) => {
          this.setState({ saving: false })
          Success(res.data?.message);
          this.setState(initialState)
        }).catch(({ response }) => {
          this.setState({ saving: false })
          Error(response.data?.message)
        });
    }
  }

  viewClick = (name) => {
    this.setState({ [name]: !this.state[name] })
  }

  render() {
    const languageData = this.props.AccountState.account.SelectedlanguageData;
    const account = this.props.AccountState.account;
    return (
      <>
        <div className="breadcrump">
          <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/change-password"><h6 className="highlights_breadcrump">Change Password</h6></Link></p>
        </div>
        <div className="section-body pt-3">
          <div className="container-fluid">
            <div className="row clearfix">
              {ImportedURL.SUPERADMIN.includes(account.role) ? <div className="d-flex justify-content-between align-items-center ">
                <ul className="nav nav-tabs page-header-tab bulk_tab">
                  <li className="nav-item"><a className="nav-link active" data-toggle="tab" href="#user-password" onClick={() => this.setState({ changeuserpwd: false })}>Admin Password</a></li>
                  <li className="nav-item"><a className="nav-link " data-toggle="tab" href="#admin-password" onClick={() => this.setState({ changeuserpwd: true })}>User Admin Password</a></li>
                </ul>
              </div> : ''}
              {ImportedURL.SUPERADMIN.includes(account.role) ?

                <div className="col-lg-6 pt-3">
                  <div className="card">
                    <div className="card-header">
                      <div className='rounded_icon'><i className="fa fa-key mr-2 "></i></div>
                      <h3 className="card-title"> Change {this.state.changeuserpwd ? 'All users password' : 'admin password'}</h3>
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="form-group ">
                          <label className="form-label">New Password<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                          <div className='site_pass_calander'>
                            <input type={this.state.eye1 ? 'text' : 'password'} className="form-control" placeholder="New Password" name={'newpassword1'} onChange={this.onChange} value={this.state.newpassword1} />
                            <i class={this.state.eye1 ? "fa fa-eye" : "fa fa-eye-slash"} style={{ cursor: 'pointer', fontSize: '17px' }} onClick={() => this.viewClick('eye1')}></i>
                          </div>
                          <div className="invalid-feedback" style={{ display: this.state.newpassword1Error ? "block" : 'none' }}>{this.state.newpassword1Error}</div>
                        </div>
                        <div className="form-group site_pass">
                          <label className="form-label">Confirm Password<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                          <div className='site_pass_calander'>
                            <input type={this.state.eyeCon1 ? 'text' : 'password'} className="form-control" placeholder="Confirm  Password" name={'confirmpassword1'} onChange={this.onChange} value={this.state.confirmpassword1} />
                            <i class={this.state.eyeCon1 ? "fa fa-eye" : "fa fa-eye-slash"} style={{ cursor: 'pointer', fontSize: '17px' }} onClick={() => this.viewClick('eyeCon1')}></i>
                          </div>
                          <div className="invalid-feedback" style={{ display: this.state.confirmpassword1Error ? "block" : 'none' }}>{this.state.confirmpassword1Error}</div>
                        </div>
                        <div className="form-group site_pass">
                          <label className="form-label">OTP<span className="ml-1" style={{ color: 'red' }}>*</span>
                            {
                              this.state.sendingotp === 'sending' ? <a type='button' className="float-right small" ><i className="fa fa-spinner fa-spin mr-2"></i>sending otp</a>
                                :
                                this.state.sendingotp === 'sentotp' ? <a type='button' className="float-right small" >Resend OTP in {this.state.timer} seconds</a>
                                  : <a type='button' className="float-right small" onClick={this.sendotp}>send otp</a>
                            }
                          </label>
                          <input type='text' className="form-control" placeholder="OTP" name={'otp'} onChange={this.onChange} value={this.state.otp} />

                          <div className="invalid-feedback" style={{ display: this.state.otpError ? "block" : 'none' }}>OTP is required</div>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer text-right mandatory">
                      <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                      <div className="" >
                        {
                          this.state.saving ?
                            <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                            :
                            <button type="submit" className="btn commor_save" onClick={this.submitAdmin}><i className="fe fe-save mr-2"></i>Save</button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                :

                <div className="col-lg-6">
                  <div className="card">
                    <div className="card-header">
                      <div className='rounded_icon'><i className="fa fa-key mr-2 "></i></div>
                      <h3 className="card-title"> Change password</h3>
                    </div>
                    <div className="card-body">
                      <form>
                        <div className="form-group ">
                          <label className="form-label">New Password<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                          <div className='site_pass_calander'>
                            <input type={this.state.eye ? 'text' : 'password'} className="form-control" placeholder="New Password" name={'newpassword'} onChange={this.onChange} value={this.state.newpassword} />
                            <i class={this.state.eye ? "fa fa-eye" : "fa fa-eye-slash"} style={{ cursor: 'pointer', fontSize: '17px' }} onClick={() => this.viewClick('eye')}></i>
                          </div>
                          <div className="invalid-feedback" style={{ display: this.state.newpasswordError ? "block" : 'none' }}>{this.state.newpasswordError}</div>
                        </div>
                        <div className="form-group site_pass">
                          <label className="form-label">Confirm Password<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                          <div className='site_pass_calander'>
                            <input type={this.state.eyeCon ? 'text' : 'password'} className="form-control" placeholder="Confirm  Password" name={'confirmpassword'} onChange={this.onChange} value={this.state.confirmpassword} />
                            <i class={this.state.eyeCon ? "fa fa-eye" : "fa fa-eye-slash"} style={{ cursor: 'pointer', fontSize: '17px' }} onClick={() => this.viewClick('eyeCon')}></i>
                          </div>
                          <div className="invalid-feedback" style={{ display: this.state.confirmpasswordError ? "block" : 'none' }}>{this.state.confirmpasswordError}</div>
                        </div>
                      </form>
                    </div>
                    <div className="card-footer text-right mandatory">
                      <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                      <div className="" >
                        {
                          this.state.saving ?
                            <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                            :
                            <button type="submit" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  fixNavbar: state.settings.isFixNavbar,
  AccountState: state.account,
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { AC_ADD_GROUP, AC_DELETE_GROUP, AC_HANDLE_GROUP_CHANGE, AC_LIST_GROUP, AC_RESET_GROUP, AC_UPDATE_GROUP, AC_VIEW_GROUP, } from '../../actions/groupAction';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import { AC_LIST_CATEGORY } from '../../actions/categoryAction';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import Select from 'react-select';
import jsPDF from 'jspdf';
import { Phonenumber } from '../../common/validate';
import Spinner from 'react-bootstrap/Spinner';
import { Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { AC_HANDLE_PHONE_NUMBER_BLOCK_LIST_CHANGE, AC_LIST_PHONE_NUMBER_BLOCK_LIST, AC_RESET_PHONE_NUMBER_BLOCK_LIST, AC_VIEW_PHONE_NUMBER_BLOCK_LIST, AC_EMPTY_PHONE_NUMBER_BLOCK_LIST } from '../../actions/phonenumberblocklistAction';
import ErrorResponse from '../../utilities/Error/errorstatus';
import showSwalDialog from '../../utilities/Modal/swal';

const initialState = {
    modalType: "Add",
    columnApi: '',
    gridApi: '',
    columnDefs: [
        {
            headerName: 'Phone #', field: 'phonenumber', width: 200, cellStyle: { 'text-align': 'center' }, floatingFilter: true, headerClass: 'ag-center-header',
            valueGetter: function (params) {
                var selectValue = ''
                if (params.data && params.data.phoneCode && params.data.phoneCode.length > 0) {
                    params.data.phoneCode.map((item) => {
                        if (item.code == params.data.cc) {
                            selectValue = item.phonecode
                        }
                        if (item.phonecode == params.data.cc) {
                            selectValue = params.data.cc
                        }
                    })
                }
                return params.data && params.data.phonenumber ? selectValue + " " + (params.data && params.data.phonenumber) : '';
            }
        },
        {
            headerName: 'Status', width: 100, field: 'status', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true,
            cellRenderer: function (params) {
                if (params.data && params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else if (params.value == 0) {
                    return '<span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                if (params.value) {
                    return params.value;
                } else {
                    return "---"
                }
            }
        },

    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true,
    },
    nameError: false,
    ccError: false,
    emailError: false,
    emailValidError: false,
    phonenumberError: false,
    phoneNoPatternError: false,
    id: '',
    cc: '+1',
    defaultCC: '',
    initial: true,
    initial: true,
    saving: false,
    perPage: 25,
    ccData: ''
}

class ListPhoneNumberBlockList extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    changeModal = (e) => {
        this.props.ResetPhoneNumberBlockList();
        this.setState({ modalType: e.target.id, nameError: false, phonenumberError: false, phoneNoPatternError: false });
        const { AccountState } = this.props;
        const newaccount = AccountState.account.phonecode;
        if (newaccount) {
            this.props.HandleInputChange('cc', newaccount);
        }
    }

    UNSAFE_componentWillMount() {
        this.props.ListCountry();
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const value = rowValue.getAttribute('data-action-type');
        // if (value === 'View') {
        //     this.props.ResetPhoneNumberBlockList();
        //     this.setState({ modalType: "View", ccError: false, nameError: false, phonenumberError: false });
        //     this.props.ViewPhoneNumberBlockList(event.data);
        // }
        if (value === 'Edit') {
            this.setState({ email: event.data.email, ccError: false, modalType: "Edit", id: event.data._id, nameError: false, phonenumberError: false, phoneNoPatternError: false });
            this.props.ViewPhoneNumberBlockList(event.data._id);
        }

        if (value === 'Delete') {
            showSwalDialog('Are you sure you want to delete?', '', 'Ok', 'Cancel', 'assets/images/delete.png').then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeletePhoneNumberBlockList + '/' + event.data._id)
                        .then((res) => {
                            Success((languageData && languageData.PHONE_NUMBER_BLOCK_LIST ? languageData.PHONE_NUMBER_BLOCK_LIST : 'Phone number block list') + (languageData && languageData.DELETED_SUCCESSFULLY ? languageData.DELETED_SUCCESSFULLY : ' deleted successfully'));
                            const dataSource = this.getDataSource();
                            this.gridApi.setDatasource(dataSource);
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
        if (value === 'Status') {
            showSwalDialog('Are you sure you want to change the status?', '', 'Ok', 'Cancel', 'assets/images/status.png').then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'phonenumberblocklist' })
                        .then((data) => {
                            const dataSource = this.getDataSource();
                            this.gridApi.setDatasource(dataSource);
                            Success((languageData && languageData.STATUS ? languageData.STATUS : 'Status') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " updated successfully"))
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
    }

    onChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        if (this.state.defaultCC) {
            this.props.HandleInputChange('cc', this.state.defaultCC);
        }
        if (name === 'phonenumber') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var s3 = s2.slice(0, 10);
            var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                this.setState({ [Error]: false });
                if (Phonenumber(val)) {
                    this.setState({ phoneNoPatternError: false })

                } else {
                    this.setState({ phoneNoPatternError: true })
                }
                this.props.HandleInputChange(name, val);

            } else {
                this.setState({ phoneNoPatternError: false })
                this.props.HandleInputChange(name, val);
            }
        } else {
            this.props.HandleInputChange(name, value);
        }
    }
    handleChange = e => {
        const { name, value } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.HandleInputChange(name, value);
    }
    handlePhoneCode = e => {
        const { name, value } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false, defaultCC: '' });
        this.props.HandleInputChange(name, value);
    }
    submit = () => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const { PhoneNumberBlockListState, } = this.props;
        const data = PhoneNumberBlockListState.PhoneNumberBlockList;
        let valid = 1;
        if (!data.phonenumber) {
            valid = 0;
            this.setState({ phonenumberError: true });
        }
        if (this.state.phoneNoPatternError) {
            valid = 0;
        }
        if (valid) {
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addPhoneNumberBlockList, data)
                    .then((res) => {
                        this.setState({ saving: false })
                        Success((languageData && languageData.PHONE_NUMBER_BLOCK_LIST ? languageData.PHONE_NUMBER_BLOCK_LIST : 'Phone number block list') + (languageData && languageData.CREATED_SUCCESSFULLY ? languageData.CREATED_SUCCESSFULLY : "created successfully"));
                        // Success(res.statusText);
                        const dataSource = this.getDataSource()
                        this.gridApi.setDatasource(dataSource);
                        this.props.ResetPhoneNumberBlockList();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        if (response.status == 409) {
                            Error((languageData && languageData.PHONE_NUMBER ? languageData.PHONE_NUMBER : 'Phone number') + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : 'already exist'))
                        } else if (response.status == 400) {
                            Error((languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request'))
                        }
                        else if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else {
                            Error(response.statusMessage)
                        }
                        this.setState({ saving: false })
                    });
            } else {
                axios.post(ImportedURL.API.updatePhoneNumberBlockList + '/' + this.state.id, data)
                    .then((res) => {
                        this.setState({ saving: false })
                        Success((languageData && languageData.PHONE_NUMBER_BLOCK_LIST ? languageData.PHONE_NUMBER_BLOCK_LIST : 'Phone number block list') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : "updated successfully"));
                        const dataSource = this.getDataSource();
                        this.gridApi.setDatasource(dataSource);
                        this.props.ResetPhoneNumberBlockList();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        // Error(response.statusText)
                        if (response.status == 400) {
                            Error((languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request'))
                        } else if (response.status == 409) {
                            Error((languageData && languageData.PHONE_NUMBER ? languageData.PHONE_NUMBER : 'Phone number') + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : 'already exist'))
                        } else if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else {
                            Error(response.statusMessage)
                        }
                    });
            }
        }
    }

    componentDidMount() {
        this.props.ListCountry();
        var phonecode = localStorage.getItem("phonecode");
        this.setState({ defaultCC: phonecode })
    }
    exportToCSV = () => {
        const account = this.props.AccountState.account;
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: ['phonenumber'],
            fileName: 'phonenumberblocklist' + "_" + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }
    exportPDF = () => {
        const account = this.props.AccountState.account;
        const exportData = this.props.PhoneNumberBlockListState.listPhoneNumberBlockList;
        const selectedProperties = ['phonenumber'];
        const selected = exportData.map((item, i) => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    selectedData[property] = item[property] ? item[property] : '---';
                }
            });
            return selectedData;
        });
        let data = {
            title: "Phone Number Block List",
            head: ['#', "Phone #"],
            body: selected,
            hotelNameExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hotelNameExport : '',
            hoteladdressExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "phonenumberblocklist" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }

    // Data source to set data in ag grid
    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {

                // Grid params for filter and sort
                const page = Math.floor(params.startRow / this.state.perPage);
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                // Action call for inspection list

                this.props.AC_EMPTY_PHONE_NUMBER_BLOCK_LIST();
                await this.props.ListPhoneNumberBlockList({ perPage: this.state.perPage, page: page, filter: filter, sort: sortModel.length ? sortModel[0] : {}, ...query });
                const data = this.props.PhoneNumberBlockListState.PhoneNumberBlockList;
                const total = this.props.PhoneNumberBlockListState.total;
                const { AccountState, CountryState } = this.props;
                const phoneCode = CountryState.listcountry;
                var rowData = []
                if (phoneCode && phoneCode.length > 0) {
                    rowData = this.props.PhoneNumberBlockListState.listPhoneNumberBlockList;
                }
                const previleges = AccountState.previleges;
                const Previlege = previleges.find(obj => { return obj.name == "Group" });
                const deleteOption = ImportedURL.SUPERADMIN.includes(AccountState.role) ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : '';
                const editOption = Previlege?.edit ? '<button title="Edit" type="button" class="btn btn-icon" data-action-type="Edit" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
                const template = '<div class="userlist_view"><button  title="View" type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
                    + editOption
                    + deleteOption
                    + '</div>';

                if (rowData != undefined && rowData.length > 0) {
                    rowData.forEach(object => {
                        object.actions = editOption + deleteOption;
                    });
                }
                if (rowData != undefined && rowData.length > 0) {
                    rowData.forEach(object => {
                        object.phoneCode = phoneCode;
                    });
                }
                if (phoneCode) {
                    phoneCode.map(item => {
                        if (item.code === data.cc) {
                            this.setState({ ccData: { label: item.phonecode, value: item.code, flag: item.flag } })
                        }
                        if (item.phonecode === data.cc) {
                            this.setState({ ccData: { label: item.phonecode, value: item.phonecode, flag: item.flag } })
                        }
                    })
                }
                const lbLastRowOnPageEl = document.querySelector(`[ref=\'lbLastRowOnPage\']`);
                if (lbLastRowOnPageEl) {
                    lbLastRowOnPageEl.innerHTML = (rowData) ? rowData.length + params.startRow : 0;
                }
                params.successCallback(rowData, total);

            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // To set data once grid api is updated
        if (this.gridApi) {
            if (this.props.AccountState.previleges && prevState.gridApi !== this.state.gridApi) {
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
            }
        }
        if (prevState.perPage !== this.state.perPage) {
            this.refreshGridDataSource();
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
        this.refreshGridDataSource();
    };

    refreshGridDataSource = (params) => {
        const newDataSource = this.getDataSource(params);
        if (this.gridApi) {
            this.gridApi.setDatasource(newDataSource);
        }
    };

    onPerPageChange = (newPerPage) => {
        this.setState({ perPage: newPerPage })
        this.refreshGridDataSource({ perPage: newPerPage });
    };

    render() {
        const { fixNavbar, PhoneNumberBlockListState, AccountState, HotelState, CountryState } = this.props;
        const phoneCode = CountryState.listcountry;
        var rowData = []
        if (phoneCode && phoneCode.length > 0) {
            rowData = PhoneNumberBlockListState.listPhoneNumberBlockList;
        }
        const data = PhoneNumberBlockListState.PhoneNumberBlockList;
        const spinner = PhoneNumberBlockListState.spinner;
        const languageData = AccountState.account.SelectedlanguageData
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Group" });
        // const deleteOption = AccountState.role == 'admin' ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : '';
        // const editOption = Previlege?.edit ? '<button title="Edit" type="button" class="btn btn-icon" data-action-type="Edit" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
        // const template = '<div class="userlist_view"><button  title="View" type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#exampleModal"><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
        //     + editOption
        //     + deleteOption
        //     + '</div>';

        // if (rowData != undefined && rowData.length > 0) {
        //     rowData.forEach(object => {
        //         object.actions = editOption + deleteOption;
        //     });
        // }
        // if (rowData != undefined && rowData.length > 0) {
        //     rowData.forEach(object => {
        //         object.phoneCode = phoneCode;
        //     });
        // }
        let ccData = '';
        if (phoneCode) {
            phoneCode.map(item => {
                if (item.code === data.cc) {
                    ccData = { label: item.phonecode, value: item.code, flag: item.flag }
                }
                if (item.phonecode === data.cc) {
                    ccData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                }
            })
        }
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]

        return (
            <>

                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/phone-number-block-list"><h6 className="highlights_breadcrump">{languageData && languageData.PHONE_BLOCK_LIST ? languageData.PHONE_BLOCK_LIST : "Phone Numbers Block "}{languageData && languageData.LIST ? languageData.LIST : " List"}</h6></Link></p>
                        </div>
                        <div className="section-body pt-3 user_sec">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header tit_res">
                                                <div className='d-flex'>
                                                    <div className='rounded_icon'><i className="fa fa-ban mr-2 "></i></div>
                                                    <div className='d-flex align-items-center'>
                                                        <h3 className="card-title"> {languageData && languageData.PHONE_BLOCK_LIST ? languageData.PHONE_BLOCK_LIST : "Phone Numbers Block "} {languageData && languageData.LIST ? languageData.LIST : " List"}</h3>
                                                    </div>
                                                </div>

                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="header-action" >
                                                            {Previlege?.add ? <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" id='Add' onClick={(e) => this.changeModal(e)}><i className="fe fe-plus mr-2" id='Add' />Add</button> : ''}
                                                        </div>
                                                    </div>
                                                    {rowData.length > 0 ?
                                                        <>
                                                            <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                            <div className="dropdown-menu">
                                                                <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                            </div>
                                                        </>
                                                        : []}
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                        <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={(e) => this.onPerPageChange(Number(e.value))}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <div className={(rowData && rowData.length == 0) ? "ag-theme-alpine ag_grid_no_record_show" : "ag-theme-alpine"}>
                                                        <AgGridReact
                                                            key={this.state.perPage}
                                                            rowHeight={82}
                                                            paginationPageSize={this.state.perPage}
                                                            cacheBlockSize={this.state.perPage}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            // rowData={rowData}
                                                            onGridReady={this.onGridReady}
                                                            columnDefs={this.state.columnDefs}
                                                            rowDragManaged={true}
                                                            wrapText={true}
                                                            animateRows={true}
                                                            overlayNoRowsTemplate={"No rows to display"}
                                                            rowModelType={'infinite'}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                    {
                                                        (rowData && rowData.length == 0)
                                                        &&
                                                        <span className='ag_grid_no_record_show_span' >No rows to display</span>
                                                    }
                                                    {/* <div id="overlay" style={{ display: spinner ? 'block' : 'none' }}></div> */}
                                                    {spinner ?
                                                        <div className='common_loader_ag_grid'>
                                                            <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                            </Spinner>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className='rounded_icon'><i className="fa fa-ban mr-2 "></i></div>
                                    <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} {languageData && languageData.PHONE_BLOCK_LIST ? languageData.PHONE_BLOCK_LIST : "Phone Numbers Block"} </h5>
                                    <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                            <img src='../../assets/images/cancel.png' />
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row clearfix">
                                        <div className="col-md-12">
                                            <label className="form-label">Phone #<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                            <Row>
                                                <div className="col-md-4 pr-0">
                                                    <div className="form-group country_code">

                                                        <Select
                                                            isDisabled={this.state.modalType == "View"}
                                                            value={ccData}
                                                            onChange={this.handlePhoneCode}
                                                            options={phoneCode && phoneCode.filter(filterItem => filterItem.status === true).map(item => {
                                                                return {
                                                                    label: item.phonecode,
                                                                    flag: item.flag,
                                                                    value: item.code,
                                                                    name: 'cc'
                                                                }
                                                            })}
                                                            formatOptionLabel={(item) => {
                                                                return (
                                                                    <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.label }} />
                                                                )
                                                            }}
                                                        />
                                                        <div className="invalid-feedback" style={{ display: this.state.ccError ? "block" : 'none' }}>Country Code is required</div>
                                                    </div>
                                                </div>
                                                <div className="col-md-8 pl-0">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control ml-1" disabled={this.state.modalType == "View"} name='phonenumber' onChange={this.onChange} value={data.phonenumber} placeholder="Phone #" />
                                                        <div className="invalid-feedback" style={{ display: this.state.phonenumberError ? "block" : 'none' }}>Phone # is required</div>
                                                        <div className="invalid-feedback" style={{ display: this.state.phoneNoPatternError ? "block" : 'none' }}>Phone # should contain 10 digits</div>
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                    </div>
                                </div>
                                {this.state.modalType == "Edit" ?
                                    <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="" >
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Update</button>
                                            }
                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                        </div>
                                    </div>
                                    : <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="" >
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                            }
                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    PhoneNumberBlockListState: state.phonenumberblocklist,
    CountryState: state.country,
    AccountState: state.account,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListCountry: AC_LIST_COUNTRY,
        HandleInputChange: AC_HANDLE_PHONE_NUMBER_BLOCK_LIST_CHANGE,
        ListPhoneNumberBlockList: AC_LIST_PHONE_NUMBER_BLOCK_LIST,
        ViewPhoneNumberBlockList: AC_VIEW_PHONE_NUMBER_BLOCK_LIST,
        ResetPhoneNumberBlockList: AC_RESET_PHONE_NUMBER_BLOCK_LIST,
        AC_EMPTY_PHONE_NUMBER_BLOCK_LIST
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPhoneNumberBlockList);
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import axios from 'axios';
import ImportedURL from '../../../common/api';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { AC_GET_DESCRIPTION_WISE_TICKET_BY_ID, AC_HANDLE_TICKET_KEY_SEARCH, AC_EMPTY_TICKET_COUNT, AC_GET_TICKET_REPORT } from '../../../actions/ticketAction';
import { AC_VIEW_HOTEL } from '../../../actions/hotelAction';
import moment from 'moment';
import { Row, Spinner } from 'react-bootstrap';
import { WordMatch } from '../../../common/validate';

const responsive = {
    superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 5
    },
    desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 3
    },
    tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2
    },
    mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1
    }
};

class sentimentalviewdescription extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hotel: "",
            ticketId: this.props.match.params.id,
            serachValue: "",
            viewredirect: false,
            viewTicketId: '',
            indexValue: 0,
            popupdata: {},
            seemore: false,
            descriptionKeys: {},
            highlight: false
        }
    }
    async componentDidMount() {
        axios.get(ImportedURL.API.getDescriptionWiseTickets + "?hotel=" + this.state.ticketId)
            .then((res) => {
                const descriptionKeys = Object.keys(res.data)
                const formData = {
                    keyword: descriptionKeys,
                    hotel: this.state.ticketId
                }
                this.setState({ keyword: descriptionKeys })
                if (descriptionKeys && descriptionKeys.length > 5) {
                    this.setState({ seemore: true })
                } else {
                    this.setState({ seemore: false })
                }
                this.props.EmptySpin();
                this.props.TicketByDescription(formData, 'initial');
            }).catch((err) => { console.log(err); });
        this.props.GetTicketReport({ hotel: this.state.ticketId });
    }
    handleClick = (id) => {
        window.open('/view-ticket/' + id, '_blank', 'noreferrer')
    }
    desctiptionClick = (e, i) => {
        const descriptionWiseTicketCount = this.props.TicketState.descriptionWiseTickets;
        const descriptionKeys = Object.keys(descriptionWiseTicketCount)
        let value = []
        if (e == "") {
            value = descriptionKeys
            this.setState({ highlight: false })
        } else {
            value.push(e)
            this.setState({ highlight: true })
        }
        const formData = {
            keyword: value,
            hotel: this.state.ticketId
        }
        this.setState({ keyword: value, serachValue: '' })
        this.setState({ indexValue: i })
        this.props.EmptySpin();
        this.props.TicketByDescription(formData);
    }
    handleSearch = (e) => {
        const { TicketState } = this.props;
        var value = e.target.value;
        const rowData = TicketState.listfilter;
        this.setState({ serachValue: value })

        if (value) {
            if (rowData != undefined && rowData.length > 0) {
                const data = rowData.filter((item) =>
                    WordMatch(value, item.title) || WordMatch(value, item.description)
                )
                this.props.handlekeySearch(data)
            } else {
                this.props.handlekeySearch([])
            }
        }
        else {
            const formData = {
                keyword: this.state.keyword,
                hotel: this.state.ticketId
            }
            this.props.TicketByDescription(formData);
            this.props.GetTicketReport({ hotel: this.state.ticketId });
        }
    }
    index = (i) => {
        if (this.state.indexValue == i) {
            return true
        } else {
            return false
        }
    }
    popup = (data) => {
        this.setState({ popupdata: data })
    }
    render() {
        const { TicketState, AccountState } = this.props;
        const account = AccountState.account;
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
        }
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const descriptionWiseTicketCount = TicketState.descriptionWiseTickets;
        const dataSpinner = TicketState.spin
        const descriptionByTicketData = TicketState.descriptionByTickets;
        const descriptionByTicket = descriptionByTicketData && descriptionByTicketData.length > 0 ? [...new Map(descriptionByTicketData.map(obj => [obj.id, obj])).values()] : [];
        const descriptionKeys = Object.entries(descriptionWiseTicketCount)
        const lengthData = Object.values(descriptionWiseTicketCount)
        const descriptionKeysArray = [];
        for (let i = 0; i < descriptionKeys.length; i++) {
            descriptionKeysArray.push({ name: descriptionKeys[i][0], data: [descriptionKeys[i][1]] })
        }
        let length = 0
        if (lengthData != undefined && lengthData.length > 0) {
            lengthData.map((item) => {
                length = length + Number(item)
            })
        }
        const TicketKeys = [
            <li class="nav-item mb-3 pl-0" role="presentation">
                <button class={this.index(0) ? "nav-link active senti_btn_view onchange_sentimental_value" : "nav-link active senti_btn_view"} id="pills-home-tab" data-toggle="pill" data-target="#pills-home" type="button" role="tab" aria-controls="pills-home" onClick={e => this.desctiptionClick('', 0)} aria-selected="true">
                    All <span class="badge badge-light sentiment_badge_count" >{TicketState.lengthofsentimentdata}</span>
                </button>
            </li>
        ];
        descriptionKeysArray && descriptionKeysArray.length > 0 &&
            descriptionKeysArray.map((item, i) => {
                TicketKeys.push(
                    <li class="nav-item mb-3 review_sentimental_analysis pl-0" role="presentation">
                        <button class={this.index(i + 1) ? "nav-link active senti_btn_view onchange_sentimental_value" : "nav-link active senti_btn_view"} id="pills-home-tab" data-toggle="pill" data-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true" onClick={e => this.desctiptionClick(item.name, i + 1)}>
                            {item.name}<span class="badge badge-light sentiment_badge_count" >{item.data[0]}</span>
                        </button>
                    </li>
                )
            })
        var ticketHighligtArray = [];
        for (let index = 0; index < descriptionByTicket.length; index++) {
            var priority = 'Low';
            // var highlightRe = '/<span class="highlight" >(.*?)<\/span>/g';
            // var highlightHtml = '<span class="highlight" style="background:none;border:0;color:red;font-size:15px;font-weight:700;line-height:1.2;">$1</span>';
            // let replaceTitle = descriptionByTicket[index].title;
            // var highlightTitle = replaceTitle.replace(highlightRe, '$1');
            // if (descriptionByTicket[index].keyword !== '') {
            //     highlightTitle = highlightTitle.replace(new RegExp('(' + descriptionByTicket[index].keyword + ')', 'gi'), highlightHtml);
            // }


            var highlightHtml = '<span class="highlight" style="background:none;border:0;color:red;font-size:15px;font-weight:700;line-height:1.2;">$1</span>';
            let replaceTitle = descriptionByTicket[index].title;

            if (descriptionByTicket[index].keyword && descriptionByTicket[index].keyword.length > 0 && this.state.highlight) {
                descriptionByTicket[index].keyword.forEach(keyword => {
                    var highlightRe = new RegExp(`\\b(${keyword})\\b`, 'gi');
                    replaceTitle = replaceTitle.replace(highlightRe, (match) => {
                        return match.replace(highlightRe, highlightHtml);
                    });
                });
            }

            // let replaceDesc = descriptionByTicket[index].description;
            // var highlightDesc = replaceDesc.replace(highlightRe, '$1');
            // if (descriptionByTicket[index].keyword !== '') {
            //     highlightDesc = highlightDesc.replace(new RegExp('(' + descriptionByTicket[index].keyword + ')', 'gi'), highlightHtml);
            // }

            let replaceDesc = descriptionByTicket[index].description;

            if (descriptionByTicket[index].keyword && descriptionByTicket[index].keyword.length > 0 && this.state.highlight) {
                descriptionByTicket[index].keyword.forEach(keyword => {
                    var highlightRe = new RegExp(`\\b(${keyword})\\b`, 'gi');
                    replaceDesc = replaceDesc.replace(highlightRe, (match) => {
                        return match.replace(highlightRe, highlightHtml);
                    });
                });
            }

            if (descriptionByTicket[index].priority == 1) {
                priority = 'High'
            } else if (descriptionByTicket[index].priority == 2) {
                priority = 'Medium'
            }
            ticketHighligtArray.push(<>
                <div class="col-lg-6 col-md-12" data-toggle="modal" data-target="#exampleModal" onClick={() => this.popup(descriptionByTicket[index])}>
                    <div class="card sent_card design__card">
                        <div class="card-header view_card_design">
                            <h3 class="card-title">
                                <p dangerouslySetInnerHTML={{ __html: replaceTitle }}></p>
                            </h3>
                        </div>
                        <div class="card-body">
                            <p><span class="tag tag-blue mb-3 sent_tickets" onClick={(event) => this.handleClick(descriptionByTicket[index].ticketid)}>{descriptionByTicket[index].ticketid}</span></p>
                            <p dangerouslySetInnerHTML={{ __html: replaceDesc }} className='read_more_read_less_height'></p>
                            <div class="row">
                                <div class="col-5 py-1">
                                    <label className='label_black'>Created</label>
                                </div>
                                <div class="col-1 py-1"><label className='label_black'>:</label></div>
                                <div class="col-6 py-1"><label>{moment(descriptionByTicket[index].createdAt).tz(tzone).format("MM/DD/YYYY ddd hh:mm A")}</label></div>
                                <div class="col-5 py-1">
                                    <label className='label_black'>Creator</label>
                                </div>
                                <div class="col-1 py-1"><label className='label_black'>:</label></div>
                                <div class="col-6 py-1"><p className='mb-0 creator_senti'>{descriptionByTicket[index].reporter}</p></div>
                                {descriptionByTicket[index].roomno ?
                                    <>
                                        <div class="col-5 py-1">
                                            <label className='label_black'>Room #</label>
                                        </div>
                                        <div class="col-1 py-1"><label className='label_black'>:</label></div>
                                        <div class="col-6 py-1"><p className='mb-0 creator_senti'>{descriptionByTicket[index].roomno}</p></div>
                                    </>
                                    :
                                    <>
                                        <div class="col-5 py-1">
                                            <label className='label_black'>Other Place</label>
                                        </div>
                                        <div class="col-1 py-1"><label className='label_black'>:</label></div>
                                        <div class="col-6 py-1"><p className='mb-0 creator_senti'>{descriptionByTicket[index].otherplace}</p></div>
                                    </>
                                }
                                <div class="col-5 py-1">
                                    <label className='label_black'>Priority</label>
                                </div>
                                <div class="col-1 py-1"><label className='label_black'>:</label></div>
                                <div class="col-6 py-1"><label>{priority}</label></div>
                            </div>
                        </div>
                    </div>
                </div>
            </>)
        }


        var priorityView = 'Low';
        if (this.state.popupdata && this.state.popupdata.ticketid) {
            if (this.state.popupdata.priority == 1) {
                priorityView = 'High'
            } else if (this.state.popupdata.priority == 2) {
                priorityView = 'Medium'
            }
        }

        let highCountKeys = []
        let allCountKeys = TicketKeys
        if (TicketKeys && TicketKeys.length > 5) {
            for (let index = 0; index < 5; index++) {
                highCountKeys.push(TicketKeys[index])
            }
        }
        return (
            <div>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/ticket-reports"><h6 className="highlights_breadcrump">Tickets Reports</h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>Sentimental-Viewdescription</h6></p>
                    </div>
                    <div className="section-body pt-3 view_ticket">
                        <div className="container-fluid">
                            <div className="card">
                                <div className="card-header" style={{ padding: "7px 0px 20px 0px", background: 'white' }}>
                                    <div className='rounded_icon'><i className="fa fa-ticket mr-2 "></i></div>
                                    <h3 className="card-title " style={{ marginTop: '5px' }}>View Sentimental Analysis Details</h3>
                                    <div className="header-action" style={{ marginTop: '5px', marginLeft: 'auto' }}>
                                        <Link to='/ticket-reports'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button></Link>
                                    </div>
                                </div>
                                <div className='card-body'>
                                    <div className="row clearfix">
                                        <div className='col-lg-12'>
                                            <div class="form-group senti_search">
                                                <label className='form-label'>Search</label>
                                                <input type="text" class="form-control " id="formGroupExampleInput" placeholder="Search" value={this.state.serachValue} onChange={this.handleSearch} />
                                                <i class="fa fa-search senti_serch_icon" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                        <div className={TicketKeys.length > 5 ? 'col-lg-10 justify-content-md-center' : 'col-lg-12 justify-content-md-center'}>
                                            {this.state.seemore ?
                                                <div className='d-flex flex-wrap align-items-center'>
                                                    {highCountKeys}
                                                </div> :
                                                <div className='d-flex flex-wrap align-items-center reviews_sentimental_buttons'>
                                                    {allCountKeys}
                                                </div>}
                                        </div>
                                        {TicketKeys.length > 5 ? <div className='col-lg-2'>
                                            <button className='nav-link active see_more_senti' onClick={() => this.setState({ seemore: !this.state.seemore })}><i className={this.state.seemore ? "fa fa-chevron-down mr-2" : "fa fa-chevron-up mr-2"}></i>{this.state.seemore ? "See More" : "See Less"}</button>
                                        </div> : ''}
                                        <div className='col-lg-12 mt-3'>
                                            {descriptionByTicket.length > 0 ?
                                                <div class="row">
                                                    {ticketHighligtArray}
                                                </div>
                                                : ""
                                            }
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal fade review_modal__" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div class="modal-dialog" role="document">
                        <div class="modal-content">
                            <div class="modal-header view_card___design">
                                <h5 class="modal-title card-title" id="exampleModalLabel"> {this.state.popupdata.title}</h5>
                                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true"><img src="../../assets/images/cancel.png" /></span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <div>
                                    <span class="tag tag-blue mb-3 sent_tickets" onClick={(event) => this.handleClick(this.state.popupdata.ticketid)}>{this.state.popupdata.ticketid}</span>
                                    <p>{this.state.popupdata ? this.state.popupdata.description : ''}</p>
                                    <div class="row">
                                        <div class="col-2 py-1">
                                            <label className='label_black'>Created</label>
                                        </div>
                                        <div class="col-1 py-1"><label className='label_black'>:</label></div>
                                        <div class="col-9 py-1"><label>{moment(this.state.popupdata ? this.state.popupdata.createdAt : '').tz(tzone).format("MM/DD/YYYY ddd hh:mm A")}</label></div>
                                        <div class="col-2 py-1">
                                            <label className='label_black'>Creator</label>
                                        </div>
                                        <div class="col-1 py-1"><label className='label_black'>:</label></div>
                                        <div class="col-9 py-1"><label>{this.state.popupdata ? this.state.popupdata.reporter : ''}</label></div>
                                        {(this.state.popupdata && this.state.popupdata.roomno) ?
                                            <>
                                                <div class="col-2 py-1">
                                                    <label className='label_black'>Room #</label>
                                                </div>
                                                <div class="col-1 py-1"><label className='label_black'>:</label></div>
                                                <div class="col-9 py-1"><label>{this.state.popupdata ? this.state.popupdata.roomno : ''}</label></div>
                                            </>
                                            :
                                            <>
                                                <div class="col-2 py-1">
                                                    <label className='label_black'>Other Place</label>
                                                </div>
                                                <div class="col-1 py-1"><label className='label_black'>:</label></div>
                                                <div class="col-9 py-1"><label>{this.state.popupdata ? this.state.popupdata.otherplace : ''}</label></div>
                                            </>
                                        }
                                        <div class="col-2 py-1">
                                            <label className='label_black'>Priority</label>
                                        </div>
                                        <div class="col-1 py-1"><label className='label_black'>:</label></div>
                                        <div class="col-9 py-1"><label>{priorityView ? priorityView : ''}</label></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="overlay" style={{ display: (dataSpinner ? 'block' : 'none') }}></div>
                {
                    (dataSpinner &&
                        <div className='common_loader'>
                            <img className='loader_img_style_common' src='../../assets/images/load.png' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                        </div>
                    )
                }
            </div>
        )
    }
}
const mapStateToProps = state => ({
    HotelState: state.hotel,
    TicketState: state.ticket,
    AccountState: state.account
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        GetTicketReport: AC_GET_TICKET_REPORT,
        ViewHotel: AC_VIEW_HOTEL,
        TicketByDescription: AC_GET_DESCRIPTION_WISE_TICKET_BY_ID,
        handlekeySearch: AC_HANDLE_TICKET_KEY_SEARCH,
        EmptySpin: AC_EMPTY_TICKET_COUNT
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(sentimentalviewdescription);

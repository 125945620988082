import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { onErrorImage } from '../../common/validate';

class ModalSpinnerComponent extends Component {
    render() {
        const { spinner, overlay } = this.props;
        return (
            <>
                {overlay === false ? '' : <div id='overlay-model' style={{ display: spinner ? 'block' : 'none' }}></div>}
                {
                    spinner ?
                        <div className='model_loader_poss'>
                            <img className='loader_img_style_model' src='../../assets/images/load.png' onError={onErrorImage} />
                            <Spinner className='spinner_load_model' animation="border" variant="info" >
                            </Spinner>
                        </div>
                        : ''
                }
            </>
        );
    }
}

export default ModalSpinnerComponent;
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { Link, Redirect } from 'react-router-dom';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import Select from 'react-select';
import moment from 'moment';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import ImportedURL from '../../common/api';
import Spinner from 'react-bootstrap/Spinner';
import { Calendar } from "react-multi-date-picker"
import { imageCellRendererList, onErrorImage } from '../../common/validate';
import Viewmodal from '../Todolist/Allmytask/viewmodal';
import { AC_EMPTY_TO_DO_LIST, AC_MODAL_SPINNER_TO_DO_LIST, AC_VIEW_TO_DO_LIST } from '../../actions/todolistAction';

const initialState = {
    columnDefs: [
        // { headerName: '#', valueGetter: "node.rowIndex+1", minWidth: '50', maxWidth: '50', sortable: false },

        {
            headerName: 'Notification #', field: 'ticket', width: '10%', cellStyle: { 'text-align': 'center' }, floatingFilter: false, suppressMenu: true, sortable: false,
            cellRenderer: function (params) {
                if (params.data) {
                    if (params.data.type == 'ticket') {
                        return params.data.ticket
                    } else if (params.data.type == 'review') {
                        let stars = '';
                        if (params.data.socialreview && params.data.socialreview.rating) {
                            for (let i = 1; i <= 5; i++) {
                                if (i <= params.data.socialreview.rating) stars += `<i class="fa fa-star text-orange"></i>`;
                                else stars += '<i  class="fa fa-star" ></i>'
                            }
                            return `<span >${stars}</span>`;
                        }
                    } else {
                        return '---'
                    }
                }
            }
        },
        {
            headerName: 'Type', field: 'typename', width: '5%', cellStyle: { 'text-align': 'center' },
            cellRenderer: function (params) {
                if (params.data) {
                    return params.data.type == 'guesttouch-feedback' ? 'MYHOTELAI-FEEDBACK' : params.data.typename
                }
            }
        },
        {
            headerName: 'Logo', width: 100, field: 'logo', hide: true, sortable: false, filter: false, "resizable": false,
            cellRenderer: imageCellRendererList,
        },
        { headerName: 'Hotel', field: 'hotel', width: '25%', hide: true, floatingFilter: false, suppressMenu: true },
        {
            headerName: 'Title', field: 'notification', width: '65%', floatingFilter: true,
            cellRenderer: function (params) {
                if (params.value) {
                    return params.value
                } else {
                    return '---'
                }
            }
        },
        // { headerName: 'User', field: 'user', width: '20%', },
        {
            headerName: 'Time', field: 'time', width: 50, cellStyle: { 'text-align': 'center' }, suppressMenu: true, sortable: false,
            cellRenderer: function (params) {
                if (params.data) {
                    return moment(params.value).format(params.data.dtFormat);
                }
            }
        },
        {
            headerName: 'Action', width: 5, sortable: false, filter: false, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true,
            cellRenderer: function (params) {
                if (params.data) {
                    if (params.data.type == "mom") {
                        return `<button type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#momModal"><i class="fa fa-eye" style='color: #1DC9B7 !important' data-action-type="View"></i></button>`
                    } else if (params.data.type == "todo") {
                        return `<button type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#exampleTodoModal"><i class="fa fa-eye" style='color: #1DC9B7 !important' data-action-type="View"></i></button>`
                    } else {
                        return (
                            params.data.type === 'feedback' || params.data.type === 'guesttouch-feedback' ? `<button type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#exampleModal1"><i class="fa fa-eye" style='color: #1DC9B7 !important' data-action-type="View"></i></button>` :
                                `<button type="button" class="btn btn-icon" data-action-type="View"><i class="fa fa-eye" style='color: #1DC9B7 !important' data-action-type="View"></i></button>`
                        );
                    }
                }
            },
            // template:
            //     `<div><button type="button" className="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#exampleModal1"><i className="fa fa-eye" style='color: #1DC9B7 !important' data-action-type="View"></i></button></div>`,
        },
    ],
    defaultColumDef: {
        // "width": '100%',
        // "filter": true,
        // "sortable": true,
        // "resizable": true,
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
    },
    notifications: [],
    orignalData: [],
    hotel: '',
    modalType: '',
    description: '',
    ticketRedirect: false,
    reviewRedirect: false,
    pettycashRedirect: false,
    redirectValue: '',
    reviewredirectValue: '',
    header: '',
    spinner: true,
    hideReset: false,
    perPage: 25,
    totalVal: 0,
    columnreset: true,
    hotelid: '',
    hotelName: { label: 'All', value: '', name: 'hotel' },
    selectedType: { value: '', label: 'All', name: "type" }
}

class ListNotification extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }


    handleChange = e => {
        const { name, value, label } = e;
        const dataSource = this.getDataSource({ hotel: value, type: this.state.type, fromDate: this.state.fromDate ? this.state.fromDate : '', toDate: this.state.toDate ? this.state.toDate + " " + "23:59" : '', });
        this.gridApi.setDatasource(dataSource);
        this.setState({
            hotelName: e, hotel: value
        });
        if (this.state.selectedType.label == "All" && label == "All" && !this.state.fromDate && !this.state.toDate) {
            this.setState({ hideReset: false })
        } else {
            this.setState({ hideReset: true })
        }
    }

    onchangeValue = async e => {
        const { name, value } = e.target;
        if (name == 'fromDate') {
            const dataSource = this.getDataSource({ hotel: this.state.hotel, fromDate: value ? value + "T00:00:00" : '', type: this.state.type, toDate: "" });
            this.gridApi.setDatasource(dataSource);
            this.setState({
                fromDate: value,
                toDate: "",
            });
            if (this.state.selectedType.label == "All" && this.state.hotelName.label == "All" && !value) {
                this.setState({ hideReset: false })
            } else {
                this.setState({ hideReset: true })
            }
        }
        else if (name == 'toDate') {
            var dataSource = this.getDataSource({ hotel: this.state.hotel, toDate: value ? value + "T23:59:59" : '', type: this.state.type, fromDate: this.state.fromDate ? this.state.fromDate + "T00:00:00" : '', });
            this.gridApi.setDatasource(dataSource);
            this.setState({
                toDate: value
            });
            if (this.state.selectedType.label == "All" && this.state.hotelName.label == "All" && !this.state.fromDate && !value) {
                this.setState({ hideReset: false })
            } else {
                this.setState({ hideReset: true })
            }
        }
    }
    onchangeType = async e => {
        const { name, value, label } = e;
        var formData = { hotel: this.state.hotel, type: value, fromDate: this.state.fromDate, toDate: this.state.toDate }
        if (name == 'type')
            var dataSource = this.getDataSource({ hotel: this.state.hotel, type: value, fromDate: this.state.fromDate ? this.state.fromDate + "T00:00:00" : '', toDate: this.state.toDate ? this.state.toDate + "T23:59:59" : '' });
        this.gridApi.setDatasource(dataSource);
        this.setState({ selectedType: e })
        this.setState({
            [name]: value
        });
        if (label == "All" && this.state.hotelName.label == "All" && !this.state.fromDate && !this.state.toDate) {
            this.setState({ hideReset: false })
        } else {
            this.setState({ hideReset: true })
        }
    }


    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        const { history } = this.props
        if (value === 'View') {
            if (event.data.type == 'ticket') {
                this.setState({ modalType: '', ticketRedirect: true, redirectValue: event.data.ticket, })
            } else if (event.data.type == 'incidentticket') {
                this.setState({ modalType: '', incidentTicketRedirect: true, redirectValue: event.data.incidentticket, })
            } else if (event.data.type == 'review') {
                this.setState({ modalType: value, reviewRedirect: true, reviewredirectValue: event.data._id })
            } else if (event.data.type == 'feedback') {
                this.setState({ modalType: value, description: event.data.notification, feedbackdata: event.data.feedbackdata, feedbackroomno: event.data.feedbackroomno, feedbackreceivedat: event.data.feedbackreceivedat, header: 'Feedback' })
            } else if (event.data.type == 'guesttouch-feedback') {
                this.setState({ modalType: value, description: event.data.notification, header: 'MyHotelAI Feedback' })
            } else if (event.data.type == 'mom') {
                // this.props.ViewMinutesOfMeeting(event.data.meetingminutes);
            } else if (event.data.type == 'todo') {
                this.setState({ id: event.data.todo });
                this.props.EmptyTodoList();
                this.props.spinnerModalTodo();
                this.props.ViewTodo(event.data.todo);
            } else if (event.data.type == 'cashcount') {
                this.setState({ modalType: '', cashcountRedirect: true, redirectId: event.data.cashcountlogsheet, })
            } else if (event.data.type == 'pettycash' || event.data.type == 'pettytally') {
                this.setState({ pettycashRedirect: true, redirectId: event.data.userid, hotelid: event.data.hotelid })
            }
        }
    }

    filterReset = async e => {
        this.setState({ fromDate: '', toDate: '', hotelName: { label: 'All', value: '', name: 'hotel' }, status: '', type: '', selectedType: { value: '', label: 'All', name: "type" } });
        const account = this.props.AccountState.account;

        if (ImportedURL.SUPERADMIN.includes(account.role)) {
            this.setState({ hotel: '' })
        }
        const dataSource = this.getDataSource();
        this.gridApi.setDatasource(dataSource);
        this.setState({ nullCategory: true, hideReset: false })
    }


    componentDidMount() {
        this.props.ListHotel();
    }


    // Data source to set data in ag grid
    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {

                // Grid params for filter and sort
                const page = Math.floor(params.startRow / this.state.perPage);
                const filter = params.filterModel;
                const sortModel = params.sortModel;

                const account = this.props.AccountState.account;

                // Action call for hotels list
                this.setState({ spinner: true })
                let formData = { hotel: this.state.hotel, type: this.state.type, fromDate: this.state.fromDate ? this.state.fromDate : '', toDate: this.state.toDate ? this.state.toDate + " " + "23:59" : '', perPage: this.state.perPage, page: page, filter: filter, sort: sortModel.length ? sortModel[0] : {}, ...query }
                await axios.post(ImportedURL.API.listNotification, formData)
                    .then((res) => {
                        this.setState({ notifications: res.data.data, orignalData: res.data, spinner: false, totalVal: res.data.total, spinner: false })
                        var dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat != '' ? account.timeformat : "hh:mm A")
                        var tzone = 'America/chicago'
                        if (account != undefined && account && account.tzone != undefined && account.tzone) {
                            tzone = account.tzone
                        }
                        res.data.data.forEach(object => {
                            object.dtFormat = dtFormat;
                            object.tzone = tzone;
                        });
                        const lbLastRowOnPageEl = document.querySelector(`[ref=\'lbLastRowOnPage\']`);
                        if (lbLastRowOnPageEl) {
                            lbLastRowOnPageEl.innerHTML = (res.data && res.data.data) ? res.data.data.length + params.startRow : 0;
                        }
                        params.successCallback(res.data.data, res.data.total);
                    }).catch((err) => {
                        this.setState({ spinner: false })
                        console.log(err);
                    });
            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const role = this.props.AccountState.role;
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role) && this.state.columnreset) {
            this.UpdateColumnDefs();
            this.setState({ columnreset: false })
        }
        if (prevState.gridApi !== this.state.gridApi) {
            const dataSource = this.getDataSource();
            this.gridApi.setDatasource(dataSource);
        }
        if (prevState.perPage !== this.state.perPage) {
            this.refreshGridDataSource();
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
        this.refreshGridDataSource();
    };

    refreshGridDataSource = (params) => {
        const newDataSource = this.getDataSource(params);
        if (this.gridApi) {
            this.gridApi.setDatasource(newDataSource);
        }
        this.UpdateColumnDefs();
    };

    onPerPageChange = (newPerPage) => {
        this.setState({ perPage: newPerPage })
        this.refreshGridDataSource({ perPage: newPerPage });
    };

    UpdateColumnDefs = () => {
        const role = this.props.AccountState.role;
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role)) {
            let updatedColumnDefs = [...this.state.columnDefs];
            updatedColumnDefs = updatedColumnDefs.map((col) => {
                if (col.field === 'hotel' || col.field === 'logo') {
                    return { ...col, hide: false };
                }
                return col;
            });
            this.gridApi.setColumnDefs(updatedColumnDefs);
        }
    }

    render() {
        if (this.state.ticketRedirect) return <Redirect to={'/view-ticket/' + this.state.redirectValue} />
        if (this.state.incidentTicketRedirect) return <Redirect to={'/view-incident-ticket/' + this.state.redirectValue} />
        if (this.state.reviewRedirect) return <Redirect to={'/view-notification/' + this.state.reviewredirectValue} />
        if (this.state.cashcountRedirect) return <Redirect to={'/view-cash-count-logsheet/' + this.state.redirectId} />
        if (this.state.pettycashRedirect) return <Redirect to={'view-petty-cash-all/' + this.state.hotelid + '/' + this.state.redirectId} />

        const { HotelState, TodoListState, AccountState } = this.props;
        const account = AccountState.account;
        const languageData = AccountState.account.SelectedlanguageData
        const rowData = this.state.notifications;
        const listHotels = HotelState.listhotels;
        const modalspinner = TodoListState.modalspinner;
        const todoData = TodoListState.todo;

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        var hotelOptions = [];
        if (listHotels.length > 1) {
            hotelOptions.push({ label: 'All', value: '', name: 'hotel' })
        }
        if (listHotels.length > 0) {
            listHotels.filter(filterItem => filterItem.status === true).map(item => {
                if (ImportedURL.SUPERADMIN.includes(account.role)) {
                    hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
                } else {
                    hotelOptions.push({ label: `${item.name} ${item.city} ${item.state}`, value: item.hotelid, logo: item.logo, name: 'hotel' });
                }
            })
        }
        var dtFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY') + ' ' + (account.timeformat != '' ? account.timeformat : "hh:mm A")
        var typeOption = [
            { value: '', label: 'All', name: "type" }
        ]
        const previleges = AccountState.previleges;

        previleges && previleges.length > 0 && previleges.map(item => {
            if (item.name === "Social_Review" && item.view === true) {
                typeOption.push({ value: 'review', label: 'Review', name: "type" })
            }
            if (item.name === "Feedback" && item.view === true) {
                typeOption.push({ value: 'feedback', label: 'Feedback', name: "type" })
                typeOption.push({ value: 'guesttouch-feedback', label: 'MyHotelAI Feedback', name: "type" })
            }
            if (item.name === "Ticket" && item.view === true) {
                typeOption.push({ value: 'ticket', label: 'Ticket', name: "type" })
            }
            if (item.name === "Incident_Ticket" && item.view === true) {
                typeOption.push({ value: 'incidentticket', label: 'Incident Ticket', name: "type" })
            }
            if (item.name === "Todo_List" && item.view === true) {
                typeOption.push({ value: 'todo', label: 'Todo List', name: "type" })
            }
        })

        let tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone && account.tzone != '' && account.tzone != null) {
            tzone = account.tzone
        }
        let feedbackdata = this.state.feedbackdata

        let ratingArr = []
        if (feedbackdata && feedbackdata.rating != undefined) {
            for (let i = 1; i <= 3; i++) {
                if (i <= feedbackdata.rating) ratingArr.push(<i className="fa fa-star text-orange  mr-1" key={i}></i>)
                else ratingArr.push(<i className="fa fa-star mr-1" key={i}></i>)
            }
        }
        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <Link to="/notifications"><h6 className="highlights_breadcrump"> {languageData && languageData.NOTIFICATIONS ? languageData.NOTIFICATIONS : "Notifications "}{languageData && languageData.LIST ? languageData.LIST : ' List'}</h6></Link></p>
                        </div>
                        <div className="section-body pt-3 listnotify">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="icon-bell mr-2 mr-2 "></i></div>
                                                <h3 className="card-title"> {languageData && languageData.NOTIFICATIONS ? languageData.NOTIFICATIONS : "Notifications"}{languageData && languageData.LIST ? languageData.LIST : ' List'}</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                        <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={(e) => this.onPerPageChange(Number(e.value))}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        ImportedURL.SUPERADMIN.includes(account.role) &&
                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-xl-4">
                                                            <label className="form-label">{languageData && languageData.SEARCH ? languageData.SEARCH : "Search"}  {languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel"}</label>
                                                            <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                <Select
                                                                    value={this.state.hotelName}
                                                                    onChange={this.handleChange}
                                                                    options={hotelOptions}
                                                                    formatOptionLabel={(e) => (
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                            <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>

                                                    }



                                                    <div className={ImportedURL.SUPERADMIN.includes(account.role) ? "col-xl-2 col-lg-4 col-md-4 col-sm-12 " : "col-xl-3 col-lg-3 col-md-4 col-sm-12 mb-3"}>
                                                        <label className="form-label">From Date</label>
                                                        <div className='site_pass_calander'>
                                                            <input type='date' className="form-control" name="fromDate" value={this.state.fromDate} onChange={this.onchangeValue}></input>
                                                            <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                        </div>
                                                    </div>
                                                    <div className={ImportedURL.SUPERADMIN.includes(account.role) ? "col-xl-2 col-lg-4 col-md-4 col-sm-12 " : "col-xl-3 col-lg-3 col-md-4 col-sm-12 mb-3"}>
                                                        <label className="form-label">To Date</label>
                                                        <div className='site_pass_calander'>
                                                            <input type='date' className="form-control" name="toDate" onChange={this.onchangeValue} min={this.state.fromDate ? this.state.fromDate : ''} value={this.state.toDate}></input>
                                                            <i className="fa fa-calendar-check-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7' }}></i>
                                                        </div>
                                                    </div>
                                                    <div className=" col-xl-2 col-lg-2 col-md-4 col-sm-12">
                                                        <label className="form-label">Type</label>
                                                        <div className="form-group" style={{ marginBottom: '10px' }}>
                                                            <Select
                                                                value={this.state.selectedType}
                                                                onChange={this.onchangeType}
                                                                options={typeOption}
                                                            // defaultValue={{ label: "All", value: 0 }}
                                                            />
                                                        </div>
                                                    </div>
                                                    {this.state.hideReset ?
                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-xl-3 d-flex align-items-end mb-3">
                                                            <button className='btn social_rest_btn btn-primarys' type='button' onClick={this.filterReset}><i class="fa fa-refresh" aria-hidden="true" style={{ fontSize: '20px !importent', marginRight: '7px' }}></i> Reset</button>
                                                        </div> : ''}
                                                </div>
                                                <div className="table-responsive">
                                                    <div className={(rowData && rowData.length == 0) ? "ag-theme-alpine ag_grid_no_record_show" : "ag-theme-alpine"}>
                                                        <AgGridReact
                                                            key={this.state.perPage}
                                                            rowHeight={82}
                                                            paginationPageSize={this.state.perPage}
                                                            cacheBlockSize={this.state.perPage}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            onGridReady={this.onGridReady}
                                                            columnDefs={this.state.columnDefs}
                                                            rowDragManaged={true}
                                                            wrapText={true}
                                                            animateRows={true}
                                                            overlayNoRowsTemplate={"No rows to display"}
                                                            rowModelType={'infinite'}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                    {
                                                        (rowData && rowData.length == 0)
                                                        &&
                                                        <span className='ag_grid_no_record_show_span' >No rows to display</span>
                                                    }
                                                    {this.state.spinner ?
                                                        <div className='common_loader_ag_grid'>
                                                            <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                            </Spinner>
                                                        </div> : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div>
                    </div>
                    {this.state.modalType !== 'ticket' ?
                        <div className="modal fade " id="exampleModal1" autoComplete="off" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                            <div className="modal-dialog" style={{ maxWidth: "50%" }} role="document">
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <div className='rounded_icon'><i className="fa fa-comments mr-2"></i></div>
                                        <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.header}</h5>
                                        <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                            <span aria-hidden="true" style={{ fontSize: "23px" }} id="closeModal" onClick={this.clear}>
                                                <img src='../../assets/images/cancel.png' />
                                            </span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <div className="row clearfix" style={{ padding: '10px' }}>

                                            {feedbackdata ?
                                                <>
                                                    <div className="col-md-4">
                                                        <label className='label_black'>Client Name</label> <br />
                                                        <p className='mb-4'>{feedbackdata.name ? feedbackdata.name : '---'}</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className='label_black'>Email</label> <br />
                                                        <p className='mb-4'>{feedbackdata.email ? feedbackdata.email : '---'}</p>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <label className='label_black'>Room Number</label> <br />
                                                        <p className='mb-4'>{this.state.feedbackroomno ? this.state.feedbackroomno : '---'}</p>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className='label_black'>Star Rating</label> <br />
                                                        <p className='mb-4'>{(ratingArr && ratingArr.length > 0) ? ratingArr : (<><i className="fa fa-star mr-1" /><i className="fa fa-star mr-1" /><i className="fa fa-star mr-1" /></>)}</p>
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        {
                                                            feedbackdata.phone
                                                                ?
                                                                <>
                                                                    <label className='label_black'>Phone Number</label>  <br />
                                                                    <p>{feedbackdata.cc + " " + feedbackdata.phone}</p>
                                                                </>
                                                                : ''
                                                        }
                                                    </div>
                                                    <div className="col-md-4 mt-3">
                                                        <label className='label_black'>Received At</label> <br />
                                                        <p className='mb-4'>{this.state.feedbackreceivedat ? moment(this.state.feedbackreceivedat).format(dtFormat) : '---'}</p>
                                                    </div>
                                                    <div className="col-md-12 mt-3">
                                                        <label className='label_black'>Feedback</label> <br />
                                                        <p className='mb-4' style={{ wordWrap: "break-word" }}>{feedbackdata.feedback ? feedbackdata.feedback : '---'}</p>
                                                    </div>
                                                </>
                                                : ""}

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''}
                    <Viewmodal {...this.props} data={todoData} spinner={modalspinner} id={this.state.id} />
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    UserState: state.user,
    NotificationState: state.notification,
    HotelState: state.hotel,
    AccountState: state.account,
    TodoListState: state.todolist,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ViewTodo: AC_VIEW_TO_DO_LIST,
        spinnerModalTodo: AC_MODAL_SPINNER_TO_DO_LIST,
        EmptyTodoList: AC_EMPTY_TO_DO_LIST,
        ListHotel: AC_LIST_HOTEL
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListNotification);
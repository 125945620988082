import React, { Component } from 'react';
import { connect } from 'react-redux';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import axios from 'axios';
import ImportedURL from '../../../common/api';
import { Success } from '../../../common/swal';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { AC_ADD_UPDATE_TODO_TAGS, AC_EMPTY_TODO_TAGS, AC_EMPTY_TO_DO_LIST, AC_HANDLE_CHANGE_TODO_BACK_TAB, AC_HANDLE_TODO_TAGS_CHANGE, AC_LIST_TODO_MY_TASK, AC_LIST_TODO_TAGS, AC_MODAL_SPINNER_TODO_TAGS, AC_MODAL_SPINNER_TO_DO_LIST, AC_SPINNER_TODO_TAGS, AC_SPINNER_TO_DO_LIST, AC_VIEW_TODO_TAGS, AC_VIEW_TO_DO_LIST } from '../../../actions/todolistAction';
import { AC_LIST_USER } from '../../../actions/userAction';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    arrayMove,
} from 'react-sortable-hoc';
import QuickAddTodoList from './quickaddtodo';
import { AC_LIST_CONFIGOPTIONS_TODO_STATUS } from '../../../actions/configurationAction';
import ErrorResponse from '../../../utilities/Error/errorstatus';
import Spinner from '../../../utilities/Spinner/spinner';
import showSwalDialog from '../../../utilities/Modal/swal';
import DateComponent from '../../../utilities/Date/date';
import ViewModal from '../Allmytask/viewmodal';
import SyncCalendar from './synccalendar';

const initialState = {
    modalType: "Add",
    todolist: ["Task 1", "Task 2", "Task 3", "Task 4"],
    todolistComplete: ["Task 1", "Task 2", "Task 3", "Task 4"],
    selectDate: '',
    startdate: '',
    viewStart: '',
    timeArray: [],
}

const DragHandle = SortableHandle(() =>
    <>
        <span>
            <i class="fa fa-bars" aria-hidden="true"></i>
        </span>
    </>);

const SortableItem = SortableElement(({ value, listTodoStatusArray, statusChange, handleDelete, viewTodo }) => {
    var statusArray = []
    var selectedStatus = ""
    listTodoStatusArray && listTodoStatusArray.map((temp) => {
        if (temp.status == true) {
            if (value.todostatus == temp.suboption) {
                selectedStatus = { label: `${temp.name}`, value: temp.suboption, name: 'status', id: value._id }
            }
            statusArray.push({ label: `${temp.name}`, value: temp.suboption, name: 'status', id: value._id })
        }
    })
    const handleStatusChange = (e) => {
        if (e.value != selectedStatus.value) {
            statusChange(e); // Call the parent component's statusChange function
        }
    };
    const handleDeleteChange = (id) => {
        handleDelete(id); // Call the parent component's statusChange function
    };
    let colorCode = (value && value.priority && value.priority.suboption) ? value.priority.suboption : '';
    let id = value._id ? value._id : '';
    let start = (value.todolist && value.workdate) ? moment(value.workdate).format("MM-DD-YYYY") : '';
    return (
        <>
            <div className='d-flex align-items-center row'>
                <div className='col-1 display_flex_center_in_both'>
                    <DragHandle />
                </div>
                <div className='col-11 todo_list_data'>
                    <div className='d-flex align-items-center'>
                        <div style={{ width: "150px" }}>
                            <Select
                                value={selectedStatus}
                                onChange={(e) => handleStatusChange(e)}
                                options={statusArray}
                                defaultValue={statusArray[0]}
                            />
                        </div>
                        <div style={{ width: "280px", cursor: 'pointer' }} data-toggle="modal" data-target="#exampleTodoModal" onClick={() => viewTodo(id, start)}>
                            <label className="form-label m-0 ml-4" style={{ cursor: 'pointer' }}> {value.todolist && value.todolist.title}</label>
                        </div>
                    </div>
                    <div>
                        {colorCode ? <i class="fa fa-flag mr-3" aria-hidden="true" style={{ color: colorCode }}></i> : ''}
                        {value.showreccurring ? <i class="fa fa-refresh mr-3" aria-hidden="true"></i> : ''}
                        <i class="fa fa-trash-o text-danger" style={{ cursor: "pointer" }} aria-hidden="true" onClick={() => handleDeleteChange(value._id)}></i>
                    </div>
                </div>
            </div>
        </>
    );
});

const SortableList = SortableContainer(({ items, listTodoStatusArray, statusChange, handleDelete, viewTodo }) => {

    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} listTodoStatusArray={listTodoStatusArray} statusChange={statusChange} handleDelete={handleDelete} viewTodo={viewTodo} />
            ))}
        </ul>
    );
});

class MyTask extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
        this.hotelInputRef = React.createRef();
        this.categoryInputRef = React.createRef();
        this.useridInputRef = React.createRef();
        this.tagInputRef = React.createRef();
        this.resetTask = React.createRef();
    }
    statusChange = (e) => {
        this.props.EnableSpinner()
        const languageData = this.props.AccountState.account.SelectedlanguageData
        axios.post(ImportedURL.API.updateTodoStatus + "/" + e.id, { todostatus: e.value })
            .then((res) => {
                this.props.ListMyTask()
                Success((languageData && languageData.STATUS ? languageData.STATUS : ' Status') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : "updated successfully"))
            }).catch((err) => {
                this.props.ListMyTask()
                ErrorResponse(err, languageData)
            });

    }
    handleDelete = (id) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        showSwalDialog('Are you sure you want to delete?', '', 'Yes', 'No', 'assets/images/delete.png').then((result) => {
            if (result.isConfirmed) {
                this.props.EnableSpinner()
                axios.get(ImportedURL.API.softDeleteToDoProgress + '/' + id)
                    .then((res) => {
                        Success((languageData && languageData.MY_TASK ? languageData.MY_TASK : 'My Task') + (languageData && languageData.DELETED_SUCCESSFULLY ? languageData.DELETED_SUCCESSFULLY : ' deleted successfully'));
                        this.props.ListMyTask();
                    }).catch(({ response }) => {
                        ErrorResponse(response, languageData)
                        this.props.ListMyTask();
                    });
            }
        })
    }
    componentDidMount() {
        const account = this.props.AccountState.account;
        // let today = new Date();
        // this.setState({ day: moment(today).format('ddd'), date: today.getDate(), month: moment(today).format('MMMM'), selectDate: moment(today).format('MM-DD-YYYY'), timeArray: this.getCurrentTimeArray(today) })
        const today = moment().tz(account.tzone);
        this.setState({
            day: today.format('ddd'),
            date: today.date(),
            month: today.format('MMMM'),
            selectDate: today.format('MM-DD-YYYY'),
            timeArray: this.getCurrentTimeArray(new Date(), true)
        });
        this.props.EnableSpinner()
        this.props.ListMyTask()
        this.props.ListTodoStatus({ parentslug: 'todo-status' });
    }
    // getCurrentTimeArray = (date) => {
    //     const account = this.props.AccountState.account;
    //     const userTimeZone = account.tzone;
    //     const todayStatus = moment().tz(userTimeZone).format('MM-DD-YYYY') == moment(date).format('MM-DD-YYYY');
    //     const currentTime = new Date(new Date(date).toLocaleString('en-US', { timeZone: userTimeZone }));
    //     currentTime.setHours(0, 0, 0, 0);
    //     // if (todayStatus) {
    //     //     currentTime.setHours(currentTime.getHours() + (new Date(new Date(date).toLocaleString('en-US', { timeZone: userTimeZone }))).getHours());
    //     // }
    //     const timeLength = 23 - currentTime.getHours();
    //     const timeArray = [];
    //     if (!todayStatus) timeArray.push(moment(currentTime).format("hh:mm A"));
    //     for (let i = 0; i < timeLength; i++) {
    //         currentTime.setHours(currentTime.getHours() + 1);
    //         timeArray.push(moment(currentTime).format("hh:mm A"));
    //     }
    //     return timeArray;
    // }
    onSortEnd = ({ oldIndex, newIndex }) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const data = this.props.TodoListState.mytaskinprogress
        let arr = arrayMove(data, oldIndex, newIndex)
        this.props.EnableSpinner()
        arr = arr.map((item, index) => {
            return {
                ...item,
                sort: index + 1 // Index starts from 0, so adding 1 for human-readable sort
            };
        });
        axios.post(ImportedURL.API.sortToDoProgress, { data: arr })
            .then((res) => {
                this.props.ListMyTask();
            }).catch(({ response }) => {
                ErrorResponse(response, languageData)
                this.props.ListMyTask();
            });
    };
    onSortEndComplete = ({ oldIndex, newIndex }) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const data = this.props.TodoListState.mytaskcompleted
        let arr = arrayMove(data, oldIndex, newIndex)
        this.props.EnableSpinner()
        arr = arr.map((item, index) => {
            return {
                ...item,
                sort: index + 1 // Index starts from 0, so adding 1 for human-readable sort
            };
        });
        axios.post(ImportedURL.API.sortToDoProgress, { data: arr })
            .then((res) => {
                this.props.ListMyTask();
            }).catch(({ response }) => {
                ErrorResponse(response, languageData)
                this.props.ListMyTask();
            });
    };
    // onChangeDate = e => {
    //     const account = this.props.AccountState.account;
    //     let date = moment(e?.toDate?.().toString()).format("MM-DD-YYYY")
    //     const todayStatus = moment().tz(account.tzone).format('MM-DD-YYYY') == moment(date).format('MM-DD-YYYY');
    //     let temp = todayStatus ? this.getCurrentTimeArray(moment().tz(account.tzone)) : this.getCurrentTimeArray(moment(date).tz(account.tzone))
    //     this.setState({ selectDate: date, timeArray: temp })
    // }

    getCurrentTimeArray = (date, todayStatus) => {
        const account = this.props.AccountState.account;
        const userTimeZone = account.tzone;
        const currentTime = new Date(new Date(date).toLocaleString('en-US', { timeZone: userTimeZone }));
        if (!todayStatus) currentTime.setHours(0, 0, 0, 0); else currentTime.setMinutes(0, 0);
        const timeArray = [];
        // If today, start from the current hour
        if (todayStatus) {
            const currentHour = currentTime.getHours() + 1;
            const endHour = 23; // End hour for today

            for (let i = currentHour; i <= endHour; i++) {
                currentTime.setHours(i);
                timeArray.push(moment(currentTime).format("hh:mm A"));
            }
        } else {
            // If not today, include every hour from 0 to 23
            for (let i = 0; i <= 23; i++) {
                currentTime.setHours(i);
                timeArray.push(moment(currentTime).format("hh:mm A"));
            }
        }
        return timeArray;
    }

    onChangeDate = e => {
        const account = this.props.AccountState.account;
        let date = moment(e?.toDate?.().toString()).format("MM-DD-YYYY")
        const userTimeZone = account.tzone;
        const todayStatus = moment().tz(userTimeZone).format('MM-DD-YYYY') === moment(date).format('MM-DD-YYYY');
        const temp = todayStatus ? this.getCurrentTimeArray(moment().tz(userTimeZone), todayStatus) : this.getCurrentTimeArray(moment(date).tz(userTimeZone), todayStatus);
        this.setState({ selectDate: date, timeArray: temp });
    }
    addQuickTodo = (e) => {
        let date = this.state.selectDate + " " + e;
        this.setState({ startdate: date })
        if (this.resetTask && this.resetTask.current) {
            this.resetTask.current.resetTaskFun();
        }
    }
    submitAddTodo = () => {
        this.props.EnableSpinner()
        this.props.ListMyTask()
        this.setState({ startdate: '' })
        let btn = document.getElementById("exampleModal1");
        btn.click();
    }
    onClickAdd = () => {
        this.props.HandleChangeBackTab('/todo-my-task')
    }
    viewTodo = (id, start) => {
        this.setState({ id: id, viewStart: start });
        this.props.EmptyTodoList();
        this.props.spinnerModalTodo();
        this.props.ViewTodo(id);
    }
    render() {
        const { AccountState, TodoListState, ConfigurationState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData;
        const account = AccountState.account;
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Todo_List" });
        const mytaskinprogress = TodoListState.mytaskinprogress
        const mytaskcompleted = TodoListState.mytaskcompleted
        const spinner = TodoListState.spinner
        const listTodoStatusArray = ConfigurationState.listTodoStatus;
        const modalspinner = TodoListState.modalspinner;
        const data = TodoListState.todo;

        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/todo-my-task"><h6 className="highlights_breadcrump">{languageData && languageData.TODO_MY_TASK ? languageData.TODO_MY_TASK : "My Task"}{languageData && languageData.LIST ? languageData.LIST : " List"}</h6></Link></p>
                        </div>
                        <div className="section-body pt-3 todo_my_task">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="icon-clock mr-2"></i></div>
                                                <h3 className="card-title">{languageData && languageData.TODO_MY_TASK ? languageData.TODO_MY_TASK : "My Task"}{languageData && languageData.LIST ? languageData.LIST : " List"}</h3>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className={Previlege?.add ? "col-9" : "col-12"} style={{ paddingRight: "15px" }}>
                                                        <div className='header_my_task'>
                                                            <div className='my_task_date'>
                                                                <div className='todo_day'>{this.state.day}</div>
                                                                <div className='todo_date'>{this.state.date}</div>
                                                                <div className='todo_month'>{this.state.month}</div>
                                                            </div>
                                                            <div>
                                                                <span className='todo_center_name'>Today Focus</span>
                                                            </div>
                                                            <div>
                                                                {
                                                                    Previlege?.add ?
                                                                        <Link to='/add-todo-list'>
                                                                            <button type="button" className="btn btn-primary" id='Add' onClick={this.onClickAdd}><i className="fe fe-plus mr-2" id='Add' />Add Task</button>
                                                                        </Link>
                                                                        :
                                                                        <>
                                                                            {/* <div className="card-options">
                                                                                <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-angle-down mr-2" id='Add' />Sync</a>
                                                                                <div className="dropdown-menu">
                                                                                    <a className="dropdown-item" type='button' data-toggle="modal" data-target="#synccalendar"><i className="dropdown-icon fa fa-google"></i>Google Calendar</a>
                                                                                    <a className="dropdown-item" type='button' data-toggle="modal" data-target="#synccalendar"><i className="dropdown-icon fa fa-calendar"></i>Outlook Calendar</a>
                                                                                </div>
                                                                            </div> */}
                                                                        </>
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className='body_my_task'>
                                                            <div className='my_task_pending'>
                                                                <div class="accordion" id="accordionExample">
                                                                    <div class="accordion-item">
                                                                        <h2 class="accordion-header" id="headingOne">
                                                                            <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                                                Today ( In-Progress )
                                                                            </button>
                                                                        </h2>
                                                                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                            {mytaskinprogress && mytaskinprogress.length > 0 ?
                                                                                <div class="">
                                                                                    <SortableList items={mytaskinprogress} listTodoStatusArray={listTodoStatusArray} statusChange={this.statusChange} handleDelete={this.handleDelete} onSortEnd={this.onSortEnd} useDragHandle={true} helperClass="dragging" viewTodo={(e, date) => this.viewTodo(e, date)} />
                                                                                </div>
                                                                                :
                                                                                <div className="card-body no_records text-center d-flex align-items-center justify-content-center">
                                                                                    <div style={{ maxWidth: "340px" }}>
                                                                                        <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid mb-4 mt-0" style={{ maxWidth: "150px" }} />
                                                                                        <p className="mb-2">No records to display</p>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='my_task_pending'>
                                                                <div class="accordion" id="accordionExample1">
                                                                    <div class="accordion-item">
                                                                        <h2 class="accordion-header" id="headingTwo">
                                                                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                                                Today ( Complete )
                                                                            </button>
                                                                        </h2>
                                                                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample1">
                                                                            {mytaskcompleted && mytaskcompleted.length > 0 ?
                                                                                <div class="">
                                                                                    <SortableList items={mytaskcompleted} listTodoStatusArray={listTodoStatusArray} statusChange={this.statusChange} handleDelete={this.handleDelete} onSortEnd={this.onSortEndComplete} useDragHandle={true} helperClass="dragging" viewTodo={(e) => this.viewTodo(e)} />
                                                                                </div>
                                                                                :
                                                                                <div className="card-body no_records text-center d-flex align-items-center justify-content-center">
                                                                                    <div style={{ maxWidth: "340px" }}>
                                                                                        <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid mb-4 mt-0" style={{ maxWidth: "150px" }} />
                                                                                        <p className="mb-2">No records to display</p>
                                                                                    </div>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        Previlege?.add
                                                            ?
                                                            <>
                                                                <div className="col-3 border_left" style={{ paddingLeft: "15px" }}>
                                                                    <div className='row'>
                                                                        <div className='col-12'>
                                                                            <DateComponent onChange={(e) => this.onChangeDate(e)} currentDate={moment().tz(account.tzone)} value={this.state.selectDate} minDate={moment().tz(account.tzone).format("MM-DD-YYYY")} editable={false} />
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className='side_header_my_task'>
                                                                        <div>
                                                                            <div className='side_arrow_font_desk'>
                                                                                <DateComponent onChange={(e) => this.onChangeDate(e)} value={this.state.selectDate} minDate={moment().tz(account.tzone).format("MM-DD-YYYY")} editable={false} />
                                                                            </div>
                                                                        </div>
                                                                        <div className="card-options">
                                                                            <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-angle-down mr-2" id='Add' />Sync</a>
                                                                            <div className="dropdown-menu">
                                                                                <a className="dropdown-item" type='button' data-toggle="modal" data-target="#synccalendar"><i className="dropdown-icon fa fa-google"></i>Google Calendar</a>
                                                                                <a className="dropdown-item" type='button' data-toggle="modal" data-target="#synccalendar"><i className="dropdown-icon fa fa-calendar"></i>Outlook Calendar</a>
                                                                            </div>
                                                                        </div>
                                                                    </div> */}
                                                                    <div className='side_body_my_task'>
                                                                        {
                                                                            this.state.timeArray && this.state.timeArray.length > 0
                                                                                ?
                                                                                <ul class="list_time_todo_my_task">
                                                                                    {this.state.timeArray.map((item, i) => {
                                                                                        return (
                                                                                            <li class="list-group-item" onClick={() => this.addQuickTodo(item)} key={i} data-toggle="modal" data-target="#exampleModal1">{item}</li>
                                                                                        )
                                                                                    })}
                                                                                </ul>
                                                                                :
                                                                                <div className="card-body no_records text-center d-flex align-items-center justify-content-center">
                                                                                    <div style={{ maxWidth: "340px" }}>
                                                                                        <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid mb-4 mt-0" style={{ maxWidth: "150px" }} />
                                                                                        <p className="mb-2">There's no schedule to display for today. Select a different date.</p>
                                                                                    </div>
                                                                                </div>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </> : ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Spinner spinner={spinner}></Spinner>
                    </div>
                </div>
                <QuickAddTodoList {...this.props} startdate={this.state.startdate} submit={this.submitAddTodo} ref={this.resetTask} />
                <ViewModal {...this.props} data={data} spinner={modalspinner} checkListStatus={true} id={this.state.id} startdate={this.state.viewStart ? moment(this.state.viewStart).format("MM-DD-YYYY") : ''} />
                <SyncCalendar {...this.props} />
            </>
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
    LandingState: state.landing,
    TodoListState: state.todolist,
    UserState: state.user,
    ConfigurationState: state.configuration,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ResetTodoTag: AC_EMPTY_TODO_TAGS,
        HandleInputChange: AC_HANDLE_TODO_TAGS_CHANGE,
        ListTodoTag: AC_LIST_TODO_TAGS,
        ViewTodoTag: AC_VIEW_TODO_TAGS,
        SpinnerTodoTag: AC_SPINNER_TODO_TAGS,
        ModalSpinnerTodoTag: AC_MODAL_SPINNER_TODO_TAGS,
        ListUser: AC_LIST_USER,
        AddUpdateTodotag: AC_ADD_UPDATE_TODO_TAGS,
        spinnerModalTodo: AC_MODAL_SPINNER_TO_DO_LIST,
        ListMyTask: AC_LIST_TODO_MY_TASK,
        ListTodoStatus: AC_LIST_CONFIGOPTIONS_TODO_STATUS,
        EnableSpinner: AC_SPINNER_TO_DO_LIST,
        HandleChangeBackTab: AC_HANDLE_CHANGE_TODO_BACK_TAB,
        ViewTodo: AC_VIEW_TO_DO_LIST,
        EmptyTodoList: AC_EMPTY_TO_DO_LIST,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(MyTask);
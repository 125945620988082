import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react'
import { Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { AC_HANDLE_INPUT_CHANGE } from '../../actions/accountAction';
import { AC_LIST_CATEGORY } from '../../actions/categoryAction';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import { AC_EMPTY_TICKET_WORK_FLOW, AC_HANDLE_TICKET_WORK_FLOW_CHANGE, AC_RESET_TICKET_WORK_FLOW, AC_VIEW_TICKET_WORK_FLOW } from '../../actions/ticketworkflow';
import { AC_LIST_USER } from '../../actions/userAction';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';

class Create extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isShowMulti: false,
            isShowIndividual: false,
            allOption: true,
            allOptionUser: true,
            isShowMultiCat: false,
            isShowMultiUser: false,
        }
    }
    componentDidMount() {
        this.props.EmptyTicketWorkFlow();
        this.props.ViewTicketWorkFlow({ ticketstatus: 'reopen' });
        this.props.ListCategory();
        this.props.ListUser();
    }
    handleChangeSelectMultiCreate = (e) => {
        const listCategory = this.props.CategoryState.listcategories;
        const data = this.props.TicketworkflowState.ticketworkflow;
        if (data && data.type.includes("multiple")) {
            let allData = false
            let multivalueArray = [];
            if (e && e.length > 0) {
                e && e.map((data) => {
                    if (data.value == "All") {
                        allData = true
                        this.setState({ allOption: false })
                    } else {
                        allData = false
                        multivalueArray.push(data.value)
                        this.setState({ allOption: true })
                    }
                })
            } else {
                this.setState({ allOption: true })
            }
            if (listCategory.length == e.length) {
                this.setState({ allOption: false })
            }
            let dataArray = []
            if (allData) {
                listCategory.filter(filterItem => filterItem.status === true).map(item => {
                    dataArray.push(item._id)
                })
            }
            let indexToRemove = dataArray && dataArray.length > 0 && dataArray.indexOf(data.individualcategory)
            if (indexToRemove !== -1) {
                dataArray.splice(indexToRemove, 1)
            }
            this.setState({ multiplecategoryError: false });
            this.props.HandleChange('multiplecategory', allData ? dataArray : multivalueArray);
        } else {
            Error('Select Multiple Checkbox')
        }
    };

    handleChangeSelectIndividualCreate = (e) => {
        const listUsers = this.props.UserState.listusers;
        const { name, lable, value } = e;
        this.setState({ individualcategoryError: false });
        this.props.HandleChange('individualcategory', value);
        this.props.HandleChange('ticketuser', []);
        let status = listUsers.find((e) => e.categoryid == value)

        if (status) {
            this.setState({ allOptionUser: true })
        } else {
            this.setState({ allOptionUser: false })
        }
    }
    handleChangeSelectUser = (e) => {
        const data = this.props.TicketworkflowState.ticketworkflow;
        const listUsers = this.props.UserState.listusers;
        let findAll = e.find((item) => item.value == "All")
        let pushedArr = []
        if (findAll) {
            this.setState({ allOptionUser: false })
            let category = (data.ticketuser && data.ticketuser.length > 0) ? [...data.ticketuser] : []
            let allData = []
            listUsers.map((e) => {
                if (e.categoryid == data.individualcategory) {
                    allData.push(e._id)
                }
            })
            let conact = allData.concat(category)
            pushedArr = [...new Set(conact)]
        } else {
            this.setState({ allOptionUser: true })
            if (e && e.length > 0) {
                e.map((item) => {
                    const { value } = item;
                    pushedArr.push(value)
                })
            }
        }
        let count = 0
        listUsers.map((e) => {
            if (e.categoryid == data.individualcategory) {
                count++
            }
        })
        if (count == pushedArr.length) this.setState({ allOptionUser: false })
        this.props.HandleChange("ticketuser", pushedArr)
        this.setState({ ticketuserError: false });

    }
    radioChange = (e) => {
        const data = this.props.TicketworkflowState.ticketworkflow;

        const { name, value, checked, id } = e.target;
        this.setState({ typeError: false });
        if (id == 'multiple') {
            if (value == 'multiple' && checked) {
                this.props.HandleChange('type', [...data.type, value]);
                this.setState({ isShowMultiUser: false, isShowMultiCat: true })
            } else {
                const index = data.type.indexOf(value)
                if (data && data.type.includes(value)) {
                    data.type.splice(index, 1)
                }
                this.props.HandleChange('type', data.type);
                this.setState({ isShowMultiUser: false, isShowMultiCat: false, multiplecategoryError: false })
                this.props.HandleChange('multiplecategory', []);

            }
        }

        if (id == 'individual') {
            if (value == 'individual' && checked) {
                this.props.HandleChange('type', [...data.type, value]);
                this.setState({ isShowMultiUser: true, isShowMultiCat: false })
            } else {
                const index = data.type.indexOf(value)
                if (data && data.type.includes(value)) {
                    data.type.splice(index, 1)
                }
                this.props.HandleChange('type', data.type);
                this.setState({ isShowMultiUser: false, })
                this.props.HandleChange('individualcategory', '');
                this.props.HandleChange('ticketuser', []);
                this.setState({ individualcategoryError: false, ticketuserError: false })
            }
        }

    }
    submit = (e) => {
        this.setState({ spinner: false })
        const { TicketworkflowState, AccountState } = this.props;
        const data = TicketworkflowState.ticketworkflow;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        let valid = 1
        if (!data.type.length > 0) {
            this.setState({ typeError: true });
            valid = 0;
        }
        if (data.type.includes("multiple")) {
            if (data.multiplecategory.length == 0) {
                this.setState({ multiplecategoryError: true });
                valid = 0;
            }
        }
        if (data.type.includes("individual")) {
            if (!data.ticketuser.length > 0) {
                this.setState({ ticketuserError: true });
                valid = 0;
            }
            if (!data.individualcategory) {
                this.setState({ individualcategoryError: true });
                valid = 0;
            }
        }
        if (valid) {
            this.setState({ saving: true, spinner: true })
            data['ticketstatus'] = e
            axios.post(ImportedURL.API.addTicketWorkFlow, data)
                .then((res) => {
                    this.props.EmptyTicketWorkFlow();
                    this.props.ViewTicketWorkFlow({ ticketstatus: 'reopen' });
                    this.setState({ saving: false, spinner: false })
                    Success((languageData && languageData.TICKET_WORKFLOW ? languageData.TICKET_WORKFLOW : "Ticket Workflow") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"));
                }).catch(({ response }) => {
                    this.setState({ saving: false, spinner: false })
                    if (response != undefined) {
                        if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else if (response.status == 400) {
                            Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                        } else {
                            Error(response.statusMessage)
                        }
                    }
                });
        }
    }
    changeCustom = (e) => {
        this.setState({ spinner: true })
        const { TicketworkflowState } = this.props;
        const data = TicketworkflowState.ticketworkflow;
        this.props.HandleChange('default', e.target.checked);
        const languageData = this.props.AccountState.account.SelectedlanguageData
        this.props.EmptyTicketWorkFlow();
        axios.post(ImportedURL.API.customTicketWorkFlow, data)
            .then((res) => {
                this.props.ViewTicketWorkFlow({ ticketstatus: 'reopen' });
                this.setState({ saving: false, spinner: false })
                Success("Updated default ticket workflow");
            }).catch(({ response }) => {
                this.props.ResetSpinner()
                this.setState({ saving: false, spinner: false })
                if (response != undefined) {
                    if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else if (response.status == 400) {
                        Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                    } else {
                        Error(response.statusMessage)
                    }
                }
            });
    }
    render() {
        const { AccountState, CategoryState, UserState, TicketworkflowState } = this.props;
        const data = TicketworkflowState.ticketworkflow;
        const spinner = TicketworkflowState.spinner;
        const listCategory = CategoryState.listcategories;
        const listUser = UserState.listusers;
        const languageData = AccountState.account.SelectedlanguageData
        // multiple 
        const categoryOption = [];
        const selectedCategoryArray = [];

        listCategory.filter(filterItem => filterItem.status === true).map(item => {
            if (data.multiplecategory != undefined && data.multiplecategory && data.multiplecategory.length > 0) {
                (data.multiplecategory).map((teamValue) => {
                    if (teamValue == item._id) {
                        selectedCategoryArray.push({ label: item.name, value: item._id, name: 'multicategory' });
                    }
                })
            }
            categoryOption.push({ label: item.name, value: item._id, name: 'multicategory' });
        })
        let indexToRemove = categoryOption && categoryOption.length > 0 && categoryOption.findIndex(obj => obj.value == data.individualcategory)
        if (indexToRemove !== -1) {
            categoryOption.splice(indexToRemove, 1)
        }
        if (selectedCategoryArray.length != categoryOption.length && this.state.allOption) {
            categoryOption.unshift({ label: "All", value: 'All', name: 'multicategory' })
        }

        // individual
        let individualselectedCategoryArray = '';
        if (data.individualcategory != undefined && data.individualcategory) {
            individualselectedCategoryArray = listCategory.find(e => e._id == data.individualcategory)
        }

        const nonCommonElements = listCategory && listCategory.filter(element1 => element1.status === true &&
            !selectedCategoryArray.some(element2 => element2.value === element1._id))

        const individualcategoryOption = [];
        nonCommonElements.map(item => {
            individualcategoryOption.push({ label: item.name, value: item._id, name: 'individualcategory' });
        })

        // user multiple 
        const userArrayOption = [];
        const selectedUserArray = [];
        // if (this.state.allOptionUser) {
        //     userArrayOption.push({ label: "All", value: 'All', name: 'ticketuser' })
        // }
        listUser.filter(e => e.status == true && e.ticketprevileges == true).map(item => {
            if (data.ticketuser != undefined && data.ticketuser && data.ticketuser.length > 0) {
                let status = data.ticketuser.find(e => e == item._id);
                if (status) {
                    selectedUserArray.push({ label: item.name, value: item._id, name: 'ticketuser' });
                }
            }
            if (item.categoryid == data.individualcategory) {
                userArrayOption.push({ label: item.name, value: item._id, name: 'ticketuser' });
            }
        })
        if (userArrayOption.length != selectedUserArray.length && this.state.allOptionUser) {
            userArrayOption.splice(0, 0, { label: "All", value: 'All', name: 'ticketuser' });
        }
        return (
            <>
                <div>
                    <div className="card-body ticket_flow_dynamic_radio_btn" style={{ borderTop: "1px solid #ccc" }}>
                        <label className="form-label">Choose Type</label>
                        <div className='d-flex mb-2 ticketflow_toggle'>
                            <div className='d-flex'>
                                <div class="form-check mr-5" style={{ cursor: 'pointer' }}>
                                    <label class="form-check-label " style={{ cursor: 'pointer' }}>
                                        <input type="checkbox" class="form-check-input " checked={data && data.type.includes("multiple")} name="type" id='multiple' value='multiple' style={{ cursor: 'pointer' }} onChange={this.radioChange} />Multiple
                                    </label>
                                </div>
                                <div class="form-check">
                                    <label class="form-check-label" style={{ cursor: 'pointer' }}>
                                        <input type="checkbox" class="form-check-input" checked={data && data.type.includes("individual")} name="type" id='individual' value='individual' style={{ cursor: 'pointer' }} onChange={this.radioChange} />Individual
                                    </label>
                                </div>
                            </div>
                            <div>
                                {!data.default &&
                                    <div className="form-check ticket_workflow_custom">
                                        <label className="custom-switch">
                                            <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                                                {!data.default ? "Change to default ticket workflow" : "Default"}
                                            </span>
                                            <input
                                                type="checkbox"
                                                name="locationrestrict"
                                                className="custom-switch-input"
                                                checked={data.default}
                                                disabled={this.state.modalType == "View"}
                                                onChange={this.changeCustom}
                                                style={{ cursor: 'pointer' }}
                                            />
                                            <span className="custom-switch-indicator" style={{ cursor: 'pointer' }}></span>
                                        </label>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="invalid-feedback" style={{ display: this.state.typeError ? "block" : 'none' }}>Choose Type is required</div>

                        <Row className='mt-3'>
                            <div className='col-xl-6 col-lg-6 col-md- 12 col-sm-12 col-12 mb-2'>
                                {/* {data && data.type.includes("multiple") ? */}
                                <>
                                    <label className="form-label">Users Category</label>
                                    <div className="form-group">
                                        <Select
                                            isMulti
                                            value={selectedCategoryArray}
                                            onChange={this.handleChangeSelectMultiCreate}
                                            options={categoryOption}
                                        />
                                        <div className="invalid-feedback" style={{ display: this.state.multiplecategoryError ? "block" : 'none' }}>Users Category is required</div>
                                    </div>
                                </>
                                {/* :
                                     ""} */}
                            </div>
                            <div className='col-xl-6 col-lg-6 col-md- 12 col-sm-12 col-12 mb-2'>
                                <>
                                    {data && data.type.includes("individual") ?
                                        <>
                                            <div className="col-sm-12 col-md-12">
                                                <label className="form-label">Users Category<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        value={individualselectedCategoryArray ? { label: individualselectedCategoryArray.name } : ''}
                                                        onChange={this.handleChangeSelectIndividualCreate}
                                                        options={individualcategoryOption}
                                                    />
                                                    <div className="invalid-feedback" style={{ display: this.state.individualcategoryError ? "block" : 'none' }}>Users Category is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <label className="form-label">User List<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        isMulti
                                                        value={selectedUserArray}
                                                        onChange={this.handleChangeSelectUser}
                                                        options={userArrayOption}
                                                    />
                                                    <div className="invalid-feedback" style={{ display: this.state.ticketuserError ? "block" : 'none' }}>User List is required</div>
                                                </div>
                                            </div>
                                        </>
                                        : ""
                                    }
                                </>
                            </div>
                        </Row>
                    </div>
                    <div className="card-footer text-right">
                        <button type="submit" style={{ marginBottom: '20px' }} className="btn commor_save" onClick={(e) => this.submit('reopen')}><i className={this.state.saving ? "fa fa-spinner fa-spin mr-2" : "fe fe-save mr-2"}></i>{this.state.saving ? "Saving" : "Save"}</button>
                    </div>
                </div>
                <div id='overlay' style={{ display: spinner ? 'block' : 'none' }}></div>
                {spinner ?
                    <div className='model_loader_poss'>
                        <img className='loader_img_style_model' src='../../assets/images/load.png' />
                        <Spinner className='spinner_load_model' animation="border" variant="info" >
                        </Spinner>
                    </div>
                    : ''}
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    CategoryState: state.category,
    UserState: state.user,
    TicketworkflowState: state.ticketworkflow,
})
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListCategory: AC_LIST_CATEGORY,
        HandleChange: AC_HANDLE_TICKET_WORK_FLOW_CHANGE,
        EmptyTicketWorkFlow: AC_EMPTY_TICKET_WORK_FLOW,
        ListUser: AC_LIST_USER,
        ViewTicketWorkFlow: AC_VIEW_TICKET_WORK_FLOW,
        ResetSpinner: AC_RESET_TICKET_WORK_FLOW
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Create);

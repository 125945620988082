import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';
import { ReplaceArrayAtIndex } from '../common/validate';

export function AC_HANDLE_TIMEZONE_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_TIMEZONE_CHANGE", name: name, value: value })
    };
}

export function AC_RESET_TIMEZONE() {
    return function (dispatch) {
        dispatch({ type: "RESET_TIMEZONE" })
    };
}

export function AC_ADD_TIMEZONE(formData) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.addTimezone, formData)
            .then((res) => {
                // Success(res.statusText);
                dispatch({ type: "LIST_TIMEZONE", payload: res.data })
                dispatch({ type: "RESET_TIMEZONE" })
            }).catch(({ response }) => { Error(response.statusText) });
    };
}

export function AC_LIST_TIMEZONE(params = {}) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.listTimezone, params)
            .then((res) => {
                let list = ReplaceArrayAtIndex(res.data.allData, 4);
                dispatch({ type: "PUSH_DATA_LANDING", payload: list })
                dispatch({ type: "LIST_TIMEZONE", payload: res.data.data, total: res.data.total, spinner: false })
            }).catch((err) => { console.log(err); });
    };
}
export function AC_VIEW_TIMEZONE(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewTimezone + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_TIMEZONE", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}
import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

const hotelslength = localStorage.getItem("hotelslength");
export function AC_ACCOUNT_DETAILS() {
	return function (dispatch) {
		return axios.get(ImportedURL.API.accountDetails)
			.then((res) => {
				if (res.data) {
					if (res.data.role != 'admin' && res.data.role != 'vendor' && res.data.allhotels) {
						if (!res.data.status) {
							Error('One of your account has been blocked')
							setTimeout(() => {
								localStorage.removeItem('acwtoken');
								window.location = '/login';
							}, 2000);
						}
						if (hotelslength != res.data.allhotels.length) {
							if (hotelslength < res.data.allhotels.length) {
								Error('New hotel has been added')
								setTimeout(() => {
									localStorage.removeItem('acwtoken');
									window.location = '/login';
								}, 2000);
							}
						}
					}
					if (res.data.role == 'vendor') {
						if (res.data.status == false || res.data.isDeleted == true) {
							Error('Account has been blocked')
							setTimeout(() => {
								localStorage.removeItem('acwtoken');
								window.location = '/login';
							}, 2000);
						}
					}
				}
				dispatch({ type: "ACCOUNT_DETAILS", payload: res.data })
			}).catch(({ response }) => { console.log(response); });
	};
}

// export function AC_PREVILEGE_DETAILS() {
// 	return function (dispatch) {
// 		return axios.get(ImportedURL.API.getPrevileges)
// 			.then((res) => {
// 				dispatch({ type: "PREVILEGE_DETAILS", payload: res.data })
// 			}).catch(({ response }) => { console.log(response); });
// 	};
// }

export function AC_LOGIN_ADMIN(formData) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.login, formData)
			.then((res) => {
				Success(res.statusText);
				localStorage.setItem('acwtoken', res.data.token);
				localStorage.setItem('role', res.data.role);
				window.location.href = "/";
				// dispatch({ type: "ADMIN_DATA", payload: res.data.userResult })
			}).catch(({ response }) => { Error(response.statusText) });
	};
}

export function AC_ADMIN_UPDATE(formData) {
	return function (dispatch) {
		// return axios.post(ImportedURL.API.changeAdmin, formData)
		// 	.then((res) => {
		dispatch({ type: "ADMIN_DATA", payload: formData })
		// 	Success(res.statusText);
		// }).catch(({ response }) => { if (response) Error(response.statusText) });
	}
}

// export function AC_VIEW_ADMIN() {
// 	return function (dispatch) {
// 		return axios.get(ImportedURL.API.viewAdmin)
// 			.then((res) => {
// 				dispatch({ type: "ADMIN_DATA", payload: res.data })
// 			}).catch((err) => { console.log(err); });
// 	};
// }

export function AC_HANDLE_INPUT_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "UPDATE_ADMIN_DATA", name: name, value: value });
	};
}

export function AC_LOGIN_HISTORY(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.getLoginHistory, { params: params })
			.then((res) => {
				dispatch({ type: "LOGIN_HISTORY", payload: res.data, spinner: false })
			}).catch(({ response }) => { console.log(response); });
	};
}

export function AC_LOG_HISTORY(params = {}) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.listLogHistory, params)
			.then((res) => {
				dispatch({ type: "LOG_HISTORY", payload: res.data.data, total: res.data.total, spinner: false })
			}).catch(({ response }) => { console.log("-LOG_HISTORY--", response); });
	};
}


export function AC_UPDATE_REPORT_DATA(name, value) {
	return function (dispatch) {
		dispatch({ type: "UPDATE_REPORT_DATA", name: name, value: value });
	};
}

export function AC_PERMISSION_CHANGE(index, key) {
	return function (dispatch) {
		dispatch({ type: "PERMISSION_CHANGE", index, key });
	};
}

export function AC_GET_STATUS() {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listBookingStatus, { params: { status: true } })
			.then((res) => {
				dispatch({ type: "GET_STATUS", payload: res.data });
			}).catch(({ response }) => { console.log(response); });
	};
}

export function AC_HANDLE_LANGUAGE_CHANGE(lang) {
	const currentData = JSON.parse(localStorage.getItem('languageData')) || [];
	const languageData = getLanaguageSpecificData(currentData, lang);
	return function (dispatch) {
		dispatch({ type: "UPDATE_LANGUAGE_DATA", updatedLanguageData: languageData, lang: lang });
	};
}

function getLanaguageSpecificData(data, lang) {
	const splithData = data.find(item => item.code === lang);
	return splithData ? splithData.data : null;
}

export function AC_UPDATE_LANGUAGE_JSON(lang, languageData) {
	return function (dispatch) {
		dispatch({ type: "UPDATE_LANGUAGE_JSON", updatedLanguageData: languageData, lang: lang });
	};
}


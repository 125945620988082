import React, { Component } from "react";
import { connect } from "react-redux";
import Table from 'react-bootstrap/Table';
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { bindActionCreators } from "redux";
import { Link } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import { AC_SETTING_ROOM_HANDLE_CHANGE, AC_RESET_SETTING_ROOM, AC_HANDLE_ROOM_INPUT_CHANGE, AC_HANDLE_ROOM_UPDATE, AC_VIEW_SETTING_ROOM, AC_HANDLE_MULTIROW_CLICK, AC_LIST_SETTING_ROOM } from '../../actions/settingroomAction';
import ImportedURL from "../../common/api";
import { Error, Success } from "../../common/swal";
import { AC_LIST_SETTING_ROOMTYPE, AC_SETTING_ROOMTYPE_HANDLE_CHANGE } from '../../actions/settingRoomtypeAction';
import Swal from "sweetalert2";
import { onErrorImage } from "../../common/validate";
import showSwalDialog from "../../utilities/Modal/swal";
import QRCode from 'qrcode.react';

class AddRoom extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalType: "Add",
            id: "",
            from: "",
            to: "",
            roomError: false,
            floorError: false,
            bedtypeError: false,
            hotelError: false,
            roomStartError: false,
            initial: true,
            saving: false,
            validToError: false,
            existValue: '',
            seqChechecked: false,
            order: 'sequence',
            qrCodeValue: [],
            uniqueQrArray: [],
            hotelLabel: ''
        };
        this.hotelInputRef = React.createRef();
        this.floorInputRef = React.createRef();
        this.fromnumberInputRef = React.createRef();
        this.tonumberInputRef = React.createRef();
    }
    componentWillUpdate(nextProps) {
        // const account = this.props.AccountState.account;
        const newaccount = nextProps.AccountState.account;
        if (this.state.initial) {
            this.props.HandleInputChange("rooms", []);
            this.props.HandleInputChange('floor', '');
            this.props.HandleInputChange('from', '');
            if (!ImportedURL.SUPERADMIN.includes(nextProps.AccountState.role)) {
                this.props.HandleInputChange('hotel', newaccount.hotel);
                this.props.ListRoom({ hotel: newaccount.hotel, isAllDetailsShows: true });
            }
            this.setState({ initial: false })
        }
    }


    onChange = e => {
        const { name, value } = e.target;
        var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
        if (name === 'from') {
            this.setState({ from: val });
            { value ? this.setState({ roomStartError: false }) : this.setState({ roomStartError: true }) }

        }
        if (name === 'to') {
            if (parseInt(this.state.from) <= parseInt(val)) {
                this.setState({ to: val, validToError: false });
            } else if (parseInt(this.state.from) > parseInt(val)) {
                this.setState({ to: val, validToError: true });
            } else {
                this.setState({ to: val, validToError: false });
            }
        }
        if (name != 'room') {
            this.setState({ [name + 'Error']: false });
            this.props.HandleInputChange(name, value);
        }
    }

    searchUser = e => {
        const { name, value, label } = e;
        const { RoomState } = this.props
        const data = this.props.RoomState.room;
        this.setState({ hotelError: false, hotelLabel: label })
        this.props.HandleInputChange(name, value);
        const roomListData = (RoomState.listSettingRoom && RoomState.listSettingRoom.length > 0) ? RoomState.listSettingRoom : [];
        if (data.rooms && data.rooms.length > 0) {
            let arr = []
            data.rooms.map((item) => {
                let existData = roomListData.find((e) => (e.roomno == item.roomno && e.hotel == value))
                let newRoomObj = {
                    roomno: item.roomno,
                    roomtype: existData ? existData.roomtypeId : ((data && data.roomtype != undefined && data.roomtype) ? data.roomtype : ''),
                    isDisable: existData ? true : false
                }
                arr.push(newRoomObj);
            })
            this.props.HandleInputChange("rooms", arr);
        }

    }
    back = () => {
        this.props.HandleInputChange("rooms", []);
        this.props.HandleInputChange('floor', '');
        this.props.HandleInputChange('from', '');
        this.props.HandleInputChange('hotel', '');
    }

    submit = async () => {
        const { history, AccountState, RoomState, SettingRoomtypeState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const roomListData = (SettingRoomtypeState.listSettingRoomtype && SettingRoomtypeState.listSettingRoomtype.length > 0) ? SettingRoomtypeState.listSettingRoomtype : [];
        const data = this.props.RoomState.room;
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        }
        let valid = 1;
        if (data.rooms.length > 0) {
            data.rooms.map((item) => {
                if (!item.roomtype) {
                    Error((languageData && languageData.SELECT_YOUR_ROOM_TYPE_IN ? languageData.SELECT_YOUR_ROOM_TYPE_IN : ' Select your room type in ') + item.roomno)
                    valid = 0;
                }
            })
        } else {
            Error((languageData && languageData.GENERATE_YOUR_RECORD_BEFORE_SAVE ? languageData.GENERATE_YOUR_RECORD_BEFORE_SAVE : " Generate your room before save !!!"))
            valid = 0;
            this.fromnumberInputRef.current.focus();
        }
        if (!data.floor) {
            this.setState({ floorError: true });
            valid = 0;
            this.floorInputRef.current.focus();
        }
        if (!data.hotel) {
            this.setState({ hotelError: true });
            valid = 0;
            this.hotelInputRef.current.focus();
        }
        if (valid) {
            let roomArrayList = []
            if (data.rooms && data.rooms.length > 0) {
                data.rooms.map((item) => {
                    if (item.isDisable == false) {
                        let roomtypeData = roomListData.find((e) => e._id == item.roomtype)
                        let obj = {}
                        obj["roomno"] = item.roomno
                        obj["floor"] = item.floor
                        obj["roomtype"] = item.roomtype
                        obj["amenity"] = roomtypeData ? roomtypeData.amenity : []
                        obj["area"] = roomtypeData ? roomtypeData.builtunit : ''
                        obj["description"] = roomtypeData ? roomtypeData.description : ''
                        obj["itemvalue"] = roomtypeData ? roomtypeData.itemvalue : []
                        roomArrayList.push(obj)
                    }
                })
            }
            let Qrcode = ""
            let promises = [];
            this.state.qrCodeValue.forEach((item, i) => {
                const canvas = document.getElementById("HpQrcode" + (i + 1));
                if (canvas) {
                    let promise = new Promise(async (resolve, reject) => {
                        Qrcode = canvas
                            .toDataURL("image/png")
                            .replace("image/png", "image/octet-stream");

                        const blob = await fetch(Qrcode).then((res) => res.blob());
                        resolve(blob);
                    });
                    promises.push(promise);
                }
            });
            Promise.all(promises)
                .then((blobs) => {
                    if (blobs && blobs.length > 0) {
                        this.setState({ saving: true })
                        var formData = new FormData();
                        formData.append("hotel", data.hotel);
                        formData.append("floor", data.floor);
                        formData.append("multiple", true);
                        for (const file of blobs) {
                            formData.append('qrcode', file);
                        };
                        formData.append("room", JSON.stringify(roomArrayList))
                        formData.append("qrcodevalue", JSON.stringify(this.state.uniqueQrArray))
                        if (this.state.modalType === "Add") {
                            axios.post(ImportedURL.API.addSettingRoom, formData)
                                .then((res) => {
                                    this.setState({ saving: false })
                                    Success((languageData && languageData.ROOM ? languageData.ROOM_SETTINGS + " " : 'Room ') + (languageData && languageData.CREATED_SUCCESSFULLY ? (languageData.CREATED_SUCCESSFULLY) : " created successfully"));
                                    this.props.HandleInputChange("rooms", []);
                                    this.props.HandleInputChange('floor', '');
                                    this.props.HandleInputChange('from', '');
                                    this.props.HandleInputChange('hotel', '');
                                    this.props.HandleTabChange('tabposition', 'rooms');
                                    this.props.HandleInputChange('roomtype', "");
                                    this.props.HandleInputChange('roomtypeCondition', false);
                                    history.push("/list-setting-roomtype");
                                }).catch(({ response }) => {
                                    this.setState({ saving: false })
                                    if (response.status == 500) {
                                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                                    } else if (response.status == 502) {
                                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                                    } else if (response.status == 409) {
                                        Error((languageData && languageData.ROOM_NUMBER ? languageData.ROOM_NUMBER + " " : ' Room Number') + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : ' already exist'))
                                    } else {
                                        Error(response.statusMessage)
                                    }
                                });
                        }
                    }
                })
                .catch((error) => {
                    // Handle any errors that occurred during blob creation
                    console.error("Error creating blobs:", error);
                });
        }
    }

    removeRoom = (i) => {
        const { RoomState } = this.props;
        const data = RoomState.room.rooms;
        data.splice(i, 1);
        this.props.HandleInputChange("rooms", data);
        let slicedQrCodeValue = this.state.qrCodeValue.filter((e, index) => index != i)
        let sliceduniqueQrArray = this.state.uniqueQrArray.filter((e, index) => index != i)
        this.setState({ qrCodeValue: slicedQrCodeValue, uniqueQrArray: sliceduniqueQrArray })
    }
    removeAllRoom = () => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        showSwalDialog('Are you sure you want to delete?', '', 'Ok', 'Cancel', 'assets/images/delete.png').then((result) => {
            if (result.isConfirmed) {
                Success((languageData && languageData.ROOM ? languageData.ROOM + " " : 'Room') + (languageData && languageData.DELETED_SUCCESSFULLY ? languageData.DELETED_SUCCESSFULLY : ' deleted successfully'));
                this.props.HandleInputChange("rooms", []);
                this.setState({ qrCodeValue: [], uniqueQrArray: [] })
            }
        })
    }

    handleColorCode = e => {
        const { name, value, index, length } = e;
        const data = this.props.RoomState.room;
        if (data.roomtypeCondition) {
            for (let i = 0; i < length; i++) {
                let status = data.rooms ? data.rooms[i].isDisable : false
                if (!status)
                    this.props.AC_HANDLE_ROOM_INPUT_CHANGE(name, value, i);
            }
        } else {
            this.props.AC_HANDLE_ROOM_INPUT_CHANGE(name, value, index);
        }
    }

    componentDidMount() {
        this.props.HandleInputChange('hotel', '');
        this.props.AC_LIST_SETTING_ROOMTYPE();
        this.props.ListRoom({ isAllDetailsShows: true });
        const { params, path } = this.props.match;
        if (params.id) {
            // this.props.ViewCategory(params.id);
            this.setState({ modalType: path === "/view-room/:id" ? "View" : "Edit", id: params.id })
        } else {
            //this.props.ResetCategory();
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }


    generateRoom = () => {
        const { AccountState, RoomState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const roomListData = (RoomState.listSettingRoom && RoomState.listSettingRoom.length > 0) ? RoomState.listSettingRoom : [];
        const account = AccountState?.account;
        this.setState({ order: 'sequence' })
        if (account?.allhotels?.length > 0) {
            this.props.HandleInputChange('hotel', account.hotel);
        }
        const data = RoomState.room;
        const dataRoom = RoomState.room.rooms;
        let start = this.state.from;
        let valid = 1;
        if (!data.hotel) {
            this.setState({ hotelError: true });
            valid = 0
        }
        if (!data.floor) {
            this.setState({ floorError: true });
            valid = 0
        }
        if (!start) {
            this.setState({ roomStartError: true })
            valid = 0
        }
        if (this.state.validToError) {
            valid = 0
        }
        if (valid) {
            let newData = [];
            let start = this.state.from;
            let end = this.state.to;
            if (start !== '') {
                if ((start).length > 2) {
                    if (!end) {
                        var odd = [], even = [];
                        for (var i = start; i <= start; i++) {
                            if (i % 2 !== 0) {
                                odd.push(parseInt(i));
                            } else {
                                even.push(parseInt(i));
                            }
                        }
                        if (dataRoom.length > 0) {
                            for (var j = 0; j < dataRoom.length; j++) {
                                if (dataRoom[j].roomno % 2 !== 0) {
                                    odd.push(parseInt(dataRoom[j].roomno));
                                } else {
                                    even.push(parseInt(dataRoom[j].roomno));
                                }
                            }
                            odd.sort();
                            even.sort();
                        }
                        var arrVal = even.concat(odd).sort();
                        let arr = arrVal.filter((item, index) => arrVal.indexOf(item) === index);
                        var duplicate = arrVal.filter((item, index) => arrVal.indexOf(item) !== index);
                        if (duplicate.length > 0) {
                            Error(duplicate.join(", ") + ' number already exists')
                        }
                        for (let index = 0; index < arr.length; index++) {
                            let existData = roomListData.find((e) => (e.roomno == arr[index] && e.hotel == data.hotel))
                            let findFloor = dataRoom.find((e) => e.roomno == parseInt(arr[index]))
                            let newRoomObj = {
                                roomno: parseInt(arr[index]),
                                floor: findFloor ? findFloor.floor : data.floor,
                                roomtype: existData ? existData.roomtypeId : ((data && data.roomtype != undefined && data.roomtype) ? data.roomtype : ''),
                                isDisable: existData ? true : false
                            }
                            newData.push(newRoomObj);

                            this.props.HandleInputChange("rooms", newData);
                            this.props.HandleInputChange("temprooms", newData);
                        }
                    } else {
                        var odd = [], even = [];
                        for (var i = start; i <= end; i++) {
                            if (i % 2 !== 0) {
                                odd.push(parseInt(i));
                            } else {
                                even.push(parseInt(i));
                            }
                        }
                        if (dataRoom.length > 0) {
                            for (var j = 0; j < dataRoom.length; j++) {
                                if (dataRoom[j].roomno % 2 !== 0) {
                                    odd.push(parseInt(dataRoom[j].roomno));
                                } else {
                                    even.push(parseInt(dataRoom[j].roomno));
                                }
                            }
                            odd.sort();
                            even.sort();
                        }
                        var arrVal = even.concat(odd).sort();
                        var arr = arrVal.filter((item, index) => arrVal.indexOf(item) === index);
                        var duplicate = arrVal.filter((item, index) => arrVal.indexOf(item) !== index);
                        if (duplicate.length > 0) {
                            Error(duplicate.join(", ") + ' number already exists')
                        }
                        for (let index = 0; index < arr.length; index++) {
                            let existData = roomListData.find((e) => (e.roomno == arr[index] && e.hotel == data.hotel))
                            let findFloor = dataRoom.find((e) => e.roomno == parseInt(arr[index]))
                            let newRoomObj = {
                                roomno: parseInt(arr[index]),
                                floor: findFloor ? findFloor.floor : data.floor,
                                roomtype: existData ? existData.roomtypeId : ((data && data.roomtype != undefined && data.roomtype) ? data.roomtype : ''),
                                isDisable: existData ? true : false
                            }
                            newData.push(newRoomObj);
                            this.props.HandleInputChange("rooms", newData);
                            this.props.HandleInputChange("temprooms", newData);
                        }
                    }
                } else {
                    Error((languageData && languageData.ENTER_VALID_ROOM_NUMBER ? languageData.ENTER_VALID_ROOM_NUMBER : ' Enter valid room number'))
                }
            }
            let qrcodeArray = []
            let uniqueQrArray = []
            newData.map((item) => {
                let qrCode = ImportedURL.LOCALURL + 'view-setting-room-qr/' + data.hotel + "/" + item.roomno + Math.floor(100000 + Math.random() * 900000);
                qrcodeArray.push(qrCode)
                uniqueQrArray.push(qrCode.split('/')[5])
            })
            this.setState({ qrCodeValue: qrcodeArray, uniqueQrArray: uniqueQrArray, from: "", to: "" })
        }
    }

    sameroomtype = e => {
        this.setState({ roomtypeCondition: e.target.checked })
        this.props.HandleInputChange("roomtypeCondition", e.target.checked);
    }

    position = e => {
        const { AccountState, RoomState } = this.props;
        const tempData = RoomState.room.rooms;
        this.setState({ order: e.target.value })
        if (e.target.value == 'sequence') {
            let newData = [];
            var arr = []
            tempData.map((item) => {
                arr.push(item.roomno)
            })
            arr.sort();
            for (let index = 0; index < arr.length; index++) {
                let roomtypeArray = tempData.find((e) => e.roomno == arr[index])
                let newRoomObj = {
                    roomno: parseInt(arr[index]),
                    roomtype: roomtypeArray.roomtype,
                    isDisable: roomtypeArray.isDisable,
                }
                newData.push(newRoomObj);
            }
            this.props.HandleInputChange("rooms", newData);
        } else if (e.target.value == 'even') {
            let newData = [];
            var odd = [], even = [], roomtypeOdd = [], roomtypeEven = [];
            for (var i = 0; i < tempData.length; i++) {
                if (tempData[i].roomno % 2 !== 0) {
                    odd.push(tempData[i].roomno);
                    roomtypeOdd.push({ roomtype: tempData[i].roomtype, isDisable: tempData[i].isDisable })
                } else {
                    even.push(tempData[i].roomno);
                    roomtypeEven.push({ roomtype: tempData[i].roomtype, isDisable: tempData[i].isDisable })
                }
            }

            var arr = even.concat(odd)
            var roomtypeArray = roomtypeEven.concat(roomtypeOdd)
            for (let index = 0; index < arr.length; index++) {
                let newRoomObj = {
                    roomno: parseInt(arr[index]),
                    roomtype: roomtypeArray[index].roomtype,
                    isDisable: roomtypeArray[index].isDisable,
                }
                newData.push(newRoomObj);
            }
            this.props.HandleInputChange("rooms", newData);
        } else if (e.target.value == 'odd') {
            let newData = [];
            var odd = [], even = [], roomtypeOdd = [], roomtypeEven = [];
            for (var i = 0; i < tempData.length; i++) {
                if (tempData[i].roomno % 2 !== 0) {
                    odd.push(tempData[i].roomno);
                    roomtypeOdd.push({ roomtype: tempData[i].roomtype, isDisable: tempData[i].isDisable })
                } else {
                    even.push(tempData[i].roomno);
                    roomtypeEven.push({ roomtype: tempData[i].roomtype, isDisable: tempData[i].isDisable })
                }
            }
            var arr = odd.concat(even)
            var roomtypeArray = roomtypeOdd.concat(roomtypeEven)
            for (let index = 0; index < arr.length; index++) {
                let newRoomObj = {
                    roomno: parseInt(arr[index]),
                    roomtype: roomtypeArray[index].roomtype,
                    isDisable: roomtypeArray[index].isDisable,
                }
                newData.push(newRoomObj);
            }
            this.props.HandleInputChange("rooms", newData);
        }
    }
    onChangeFloor = e => {
        const { name, value } = e;
        this.setState({ floorError: false })
        this.props.HandleInputChange(name, value);
    }
    handleback = e => {
        this.props.HandleTabChange('tabposition', 'rooms');
    }
    render() {
        const { RoomState, AccountState, HotelState, SettingRoomtypeState } = this.props;
        const account = AccountState.account;
        const roomtypeAll = SettingRoomtypeState.listSettingRoomtype;
        const listHotels = HotelState.listhotels;
        const data = RoomState.room;
        const tabposition = RoomState.room.tabposition;
        const languageData = AccountState.account.SelectedlanguageData
        const roomOptions = [];
        var roomtypeList = []
        if (data.hotel) {
            roomtypeAll.map((item) => {
                if (item?.hotel == data.hotel) {
                    roomtypeList.push(item)
                }
            })
        }
        var selectedHotel = '';
        const hotelOptions = [];
        if (listHotels && listHotels.length > 0) {
            listHotels.filter(e => e.status === true).map((item) => {
                if (data.hotel == item._id) {
                    selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                }
                hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
            })
        }
        if (data.rooms) {
            data.rooms.map((item, index) => {
                let colorCode = ''
                roomtypeList.map((item1) => {
                    if (item1._id == item.roomtype) {
                        colorCode = { label: item1.roomname, value: item1._id, name: 'roomtype' }
                    }
                })
                roomOptions.push(
                    <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{item.roomno}</td>
                        <td>{item.floor}</td>
                        {
                            item.isDisable
                                ?
                                <>
                                    <td>
                                        <label className="form-label">{colorCode ? colorCode.label : ''}
                                            <span style={{ color: '#DD3747' }} >{" ( Room already allocated in this hotel )"}</span>
                                        </label>
                                    </td>
                                </>
                                :
                                <>
                                    <td>
                                        <Select
                                            isDisabled={item.isDisable}
                                            value={colorCode ? colorCode : ''}
                                            onChange={this.handleColorCode}
                                            options={roomtypeList && roomtypeList.filter(e => (e.status === true && (!ImportedURL.SUPERADMIN.includes(AccountState.role) ? e.hotel == account.hotel : true))).map(item => {
                                                return {
                                                    label: item.roomname, value: item._id, name: 'roomtype', index: index, length: data.rooms.length
                                                }
                                            })}
                                        />
                                    </td>
                                </>
                        }

                        <td>
                            <button type="button" className="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete" onClick={() => this.removeRoom(index)}><i className="fa fa-trash-o generate-icon" style={{ color: 'red' }} /></button>
                        </td>
                    </tr >
                )
            })
        }
        const limitOptions = []
        for (let i = 1; i <= 100; i++) {
            limitOptions.push({
                value: i, label: i, name: 'floor'
            })
        }
        let qrCodeValueView = []
        this.state.qrCodeValue && this.state.qrCodeValue.length > 0 && this.state.qrCodeValue.map((item, i) => {
            qrCodeValueView.push(<div style={{ display: 'none' }} className={"HpQrcode" + (i + 1)}>
                <QRCode id={"HpQrcode" + (i + 1)} value={item} width="100px" height="100px" />
            </div>)
        })

        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-setting-roomtype"><h6 className="highlights_breadcrump">{tabposition == 'roomtype' ? ((languageData && languageData.ROOM_TYPE_SETTINGS) ? languageData.ROOM_TYPE_SETTINGS + " List" : "Room Type List") : tabposition == 'rooms' ? ((languageData && languageData.ROOM_SETTINGS) ? languageData.ROOM_SETTINGS + " List" : "Room List") : tabposition == 'service' ? ((languageData && languageData.OUT_OF_SERVICE_SETTINGS) ? languageData.OUT_OF_SERVICE_SETTINGS + " List" : "Out Of Service List") : ''}</h6></Link><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>{this.state.modalType} Multiple {(languageData && languageData.ROOM_SETTINGS) ? languageData.ROOM_SETTINGS : "Room"}</h6></p>
                        </div>
                        <div className="section-body pt-3">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div
                                        className="tab-pane fade show active"
                                        id="Departments-list"
                                        role="tabpanel"
                                    >
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="icon-home mr-2 "></i></div>
                                                <h3 className="card-title">{this.state.modalType} Multiple {(languageData && languageData.ROOM_SETTINGS) ? languageData.ROOM_SETTINGS : "Room"}</h3>
                                                <div className="header-action" style={{ marginTop: '5px', marginLeft: 'auto' }}>
                                                    <Link to='/list-setting-roomtype'><button type="button" className="btn btn-primary " id='Add' onClick={this.handleback}><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button></Link>
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    {ImportedURL.SUPERADMIN.includes(account.role) ?
                                                        <div className="col-sm-12 col-md-12">
                                                            <div className="form-group">
                                                                <label className="form-label">Hotel<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                    <Select
                                                                        value={selectedHotel}
                                                                        onChange={this.searchUser}
                                                                        options={hotelOptions}
                                                                        formatOptionLabel={(e) => (
                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                                <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                            </div>
                                                                        )}
                                                                        ref={this.hotelInputRef}
                                                                        isDisabled={this.state.modalType == "View"}
                                                                    />
                                                                    <div className="invalid-feedback" style={{ display: this.state.hotelError ? "block" : 'none' }}>Hotel is required</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                    {qrCodeValueView}
                                                    <div className="col-sm-12 col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Floor<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <Select
                                                                onChange={this.onChangeFloor}
                                                                options={limitOptions}
                                                                isDisabled={this.state.modalType == "View"}
                                                                ref={this.floorInputRef}
                                                            />
                                                            <div className="invalid-feedback" style={{ display: this.state.floorError ? "block" : 'none' }}>Floor is required</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-5 col-md-5">
                                                        <div className="form-group">
                                                            <label className="form-label">From Room #<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" ref={this.fromnumberInputRef} className="form-control" maxlength={4} placeholder="100" value={this.state.from} name="from" disabled={this.state.modalType == "View"}
                                                                onChange={this.onChange} />
                                                            <div className="invalid-feedback" style={{ display: this.state.roomStartError ? "block" : 'none' }}>Room  is required</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-5 col-md-5">
                                                        <div className="form-group">
                                                            <label className="form-label">To Room #<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" ref={this.tonumberInputRef} className="form-control" maxlength={4} placeholder="200" value={this.state.to} name="to" disabled={this.state.modalType == "View"}
                                                                onChange={this.onChange} />
                                                            {/* {this.state.validToError ? <span style={{ color: 'red' }}>Please enter greater than {this.state.from}</span> : ''} */}
                                                            <div className="invalid-feedback" style={{ display: this.state.validToError ? "block" : 'none' }}>Must be greater than {this.state.from}</div>

                                                        </div>
                                                    </div>
                                                    <div className="col-sm-2 col-md-2">
                                                        <button type="button" className="btn btn-primary generate-button" onClick={this.generateRoom}>
                                                            <i className="fe fe-share mr-2" ></i>Generate Room</button>
                                                    </div>

                                                </div>
                                            </div>
                                            {roomOptions.length > 0 && data.hotel ?
                                                <>
                                                    <div className="Over_all_table_style card-body">
                                                        <div className="col-sm-12 col-md-12">
                                                            <div className="form-group">
                                                                <input type="radio" className="radio-button" checked={this.state.order == "sequence"} id="sequence" value="sequence" name="order" onChange={this.position} /> <label className="radio-button-label" for="sequence" style={{ cursor: 'pointer' }}>Sequence order</label>
                                                                <input type="radio" className="radio-button" checked={this.state.order == "odd"} id="odd" value="odd" name="order" onChange={this.position} style={{ marginLeft: "30px" }} /> <label className="radio-button-label" for="odd" style={{ cursor: 'pointer' }}>Odd</label>
                                                                <input type="radio" className="radio-button" checked={this.state.order == "even"} id="even" value="even" name="order" onChange={this.position} style={{ marginLeft: "30px" }} /> <label className="radio-button-label" for="even" style={{ cursor: 'pointer' }}>Even</label>
                                                            </div>
                                                        </div>
                                                        <Table responsive="sm">
                                                            <thead>
                                                                <tr>
                                                                    <th style={{ maxWidth: '10px' }}>#</th>
                                                                    <th style={{ maxWidth: '30px' }}>Room #</th>
                                                                    <th style={{ maxWidth: '50px' }}>Floor</th>
                                                                    <th>
                                                                        {/* <div className="custom-controls-stacked"> */}
                                                                        <label className="custom-control custom-checkbox" style={{ width: '170px' }}>
                                                                            <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={data.roomtypeCondition} value="option1" onChange={this.sameroomtype} />
                                                                            <span className="custom-control-label" style={{ cursor: 'pointer' }}>Same Room Type</span>
                                                                            {/* <span style={{ color: '#DD3747' }} >{" ( Room already allocated in this hotel )"}</span> */}
                                                                        </label>
                                                                        {/* </div> */}
                                                                    </th>
                                                                    <th style={{ width: '20px' }}><span>Action</span> <button type="button" className="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete" onClick={() => this.removeAllRoom()}><i className="fa fa-trash-o generate-icon" style={{ color: 'red' }} /></button></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {roomOptions}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </>
                                                : ""}
                                            {this.state.modalType == "Edit" ? <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                <div className="text-right">
                                                    {
                                                        this.state.saving ?
                                                            <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                            :
                                                            <button type="submit" className="btn commor_save" onClick={this.submit}>
                                                                <i className="fe fe-save mr-2"></i>Update
                                                            </button>
                                                    }

                                                </div>
                                            </div> : <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                <div className="text-right">
                                                    {
                                                        this.state.saving ?
                                                            <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                            :
                                                            <button type="submit" className="btn commor_save" onClick={this.submit}>
                                                                <i className="fe fe-save mr-2"></i>Save
                                                            </button>
                                                    }

                                                </div>
                                            </div>}

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    RoomState: state.settingroom,
    HotelState: state.hotel,
    AccountState: state.account,
    RoomtypeState: state.roomtype,
    SettingRoomtypeState: state.settingroomtype,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListRoom: AC_LIST_SETTING_ROOM,
        HandleInputChange: AC_SETTING_ROOM_HANDLE_CHANGE,
        ResetRoom: AC_RESET_SETTING_ROOM,
        AC_HANDLE_ROOM_UPDATE,
        HandleTabChange: AC_SETTING_ROOMTYPE_HANDLE_CHANGE,
        AC_LIST_SETTING_ROOMTYPE, AC_HANDLE_MULTIROW_CLICK,
        ViewRoom: AC_VIEW_SETTING_ROOM,
        AC_HANDLE_ROOM_INPUT_CHANGE
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(AddRoom);
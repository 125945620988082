
const initialState = {
    ViewCurrency: {
        name: '',
        code: '',
        symbol: '',
        symbolnative: '',
        status: '',
        iseleted: '',
        id: ''
    },
    ListCurrency: [],
    spinner: true,
    total: 0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_CURRENCY_CHANGE':
            return Object.assign({}, state, {
                ViewCurrency: {
                    ...state.ViewCurrency,
                    [action.name]: action.value
                }
            })
        case 'RESET_CURRENCY':
            return Object.assign({}, state, {
                ViewCurrency: initialState.ViewCurrency
            })
        case 'LIST_CURRENCY':
            return Object.assign({}, state, {
                ListCurrency: action.payload,
                total: action.total,
                spinner: action.spinner
            })
        case 'VIEW_CURRENCY':
            return Object.assign({}, state, {
                ViewCurrency: action.payload
            })
        default:
            return state;
    }
}
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Chart from 'react-apexcharts';

class CurrentPriority extends Component {
    constructor(props) {
        super(props);
        this.state = {
            options: {
                chart: {
                    id: "basic-bar",
                },
                xaxis: {
                    categories: ["High",
                        "Medium",
                        "Medium",
                        "Medium",
                        "Medium",
                        "Low"]
                },
            },
            series: [
                {
                    name: "Priority",
                    data: [30,
                        40,
                        40,
                        40,
                        40,
                        45]
                }
            ]
        }
    }

    render() {
        const { TicketState, status } = this.props;
        const ticketStatusCount = TicketState.socialreviewsCount;
        // const data = [];
        const countData = [];
        const seriesArray = [];
        for (let item of ticketStatusCount) {
            // data.push(ticketStatusCount[i])
            seriesArray.push({ name: item.rating, data: [item.count] })
            // countData.push(
            //     <tr key={i}>
            //         <td>
            //             <strong>{ticketStatusCount[i]}</strong>
            //         </td>
            //         <td className="text-right">
            //             <small className="text-muted" style={{ fontSize: "14px", fontWeight: "bold" }}>{i}</small>
            //         </td>
            //     </tr>
            // )
        }
        const options = {
            chart: {
                type: 'bar',
                id: "basic-bar",
                events: {
                    mouseLeave: function (event, chartContext, config) {
                        chartContext.resetSeries()
                    }
                }
            },
            plotOptions: {
                bar: {
                    horizontal: false,
                    columnWidth: '55%',
                    endingShape: 'rounded'
                },
            },
            stroke: {
                show: true,
                width: 2,
                colors: ['transparent']
            },
            fill: {
                opacity: 1
            },
            xaxis: {
                categories: [''],
            }
        }
        var tempstatus = true
        if (ticketStatusCount && ticketStatusCount.length > 0) {
            if (ticketStatusCount[0].count == 0 && ticketStatusCount[1].count == 0 && ticketStatusCount[2].count == 0 && ticketStatusCount[2].count == 0 && ticketStatusCount[4].count == 0) {
                tempstatus = false
            }
        }

        return (
            <>
                <div className="card">
                    <div className="Over_all_table_style card-body">
                        <div className="card-header"><h3 className="card-title">Latest 100 Guest Reviews</h3></div>
                        <table className="table card-table">
                            <tbody>
                                {countData}
                            </tbody>
                        </table>
                    </div>
                </div>
                {
                    tempstatus ?
                        <div className="card">
                            <div className="card-body">
                                {/* <div>
                                    <Chart
                                        options={options}
                                        series={seriesArray}
                                        type="bar"
                                        width="100%"
                                        height="350"
                                        className="apex-charts"
                                    />
                                </div> */}
                                <div id="chart">
                                    <Chart
                                        options={options}
                                        series={seriesArray}
                                        type="bar"
                                        height={350} />
                                </div>
                            </div>
                        </div>
                        :

                        <div className="card">
                            <div className="card-body no_records text-center d-flex align-items-center justify-content-center">
                                <div style={{ maxWidth: "340px" }}>
                                    <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid mb-4 mt-4" style={{ maxWidth: "272px" }} />
                                    <h5 className="mb-2">No records to display</h5>
                                </div>
                            </div>
                        </div>

                }
            </>
        )
    }
}
const mapStateToProps = state => ({
    TicketState: state.ticket,
})
function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(CurrentPriority);
import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';


export function AC_GET_NOTIFICATION(params = {}) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.listNotification, params)
			.then((res) => {
				dispatch({ type: "LIST_NOTIFICATION", payload: res.data.data, total: res.data.total })
			}).catch((err) => { console.log(err); });
	};
}

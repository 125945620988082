import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { bindActionCreators } from 'redux';
import ReactDOM from 'react-dom';
import jsPDF from "jspdf";
import { AC_ADD_HOTEL, AC_DELETE_HOTEL, AC_HANDLE_HOTEL_CHANGE, AC_LIST_HOTEL, AC_RESET_HOTEL, AC_UPDATE_HOTEL, AC_VIEW_HOTEL, AC_EMPTY_HOTEL, AC_EMPTY_HOTEL_LIST } from '../../actions/hotelAction';
import { AC_LIST_GROUP } from '../../actions/groupAction';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import Spinner from 'react-bootstrap/Spinner';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import NoFoundImage from '../../assets/images/notfoundimage.jpg';
import { ListLandingUpdateStatus, imageCellRendererList } from '../../common/validate';
import { AC_PUSH_DATA_LANDING } from '../../actions/landing';
import ModalStatus from '../../utilities/Modal/modalstatus';

class ListHotel extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalType: "Add",
            columnDefs: [
                // { headerName: '#', valueGetter: "node.rowIndex+1", width: 45, sortable: false, filter: false },
                { headerName: 'Group', field: 'group.name', width: 100 },
                {
                    headerName: 'Logo', field: 'logo', sortable: false, filter: false, "resizable": false, width: 100,
                    cellRenderer: imageCellRendererList,
                },
                {
                    headerName: 'Hotel Name', field: 'name', width: 250, floatingFilter: true,
                    valueGetter: function (params) {
                        if (params.data) return `${params.data.name} ${params.data.city} ${params.data.state}`
                    }
                },
                {
                    headerName: 'Hotel Name', field: 'HotelName', hide: true,
                    valueGetter: function (params) {
                        if (params.data) return `${params.data.name}`
                    }
                },
                { headerName: 'Email', field: 'emailcsv', hide: true },
                { headerName: 'Hotel ID', field: 'hotelid', width: 130, floatingFilter: true },
                { headerName: 'Address', field: 'address', width: 200 },
                {
                    headerName: 'Address', field: 'HotelAddress', hide: true,
                    valueGetter: function (params) {
                        if (params.data) return params.data.address + ' , ' + params.data.city + ' , ' + params.data.state + ' , ' + params.data.postcode + ' , ' + params.data.country + '.'
                    }
                },
                {
                    headerName: 'Phone #', field: 'phonenumber', width: 150, floatingFilter: true, cellStyle: { 'text-align': 'center' },
                    valueGetter: function (params) {
                        if (params.data) {

                            var selectValue = ''
                            var phonenumberValue = '';

                            if (params.data.phoneCode && params.data.phoneCode.length > 0) {
                                params.data.phoneCode.map((item) => {
                                    if (item.code == params.data.cc) {
                                        selectValue = item.phonecode
                                    }
                                    if (item.phonecode == params.data.cc) {
                                        selectValue = params.data.cc
                                    }
                                })
                            }
                            if (params.data.phonenumber) {
                                phonenumberValue = params.data ? params.data.phonenumber : '';
                            }
                            return selectValue + " " + phonenumberValue;
                        }
                    }
                },
                {
                    headerName: 'Status', width: 110, field: 'status', filter: false, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true,
                    cellRenderer: function (params) {
                        if (params.data && params.data.group && !params.data.group.status) {
                            return '<span type="button" class="tag tag-default hotel_inactive" >Inactive</span>';
                        } else if (params.value) {
                            return `<span type="button" class="tag tag-green" data-action-type="Status" data-toggle="modal" data-target="#statusChange">Active</span>`;
                        } else if (params.value == 0) {
                            return '<span type="button" class="tag tag-danger" data-action-type="Status" data-toggle="modal" data-target="#statusChange">Inactive</span>';
                        }
                    }
                },
                {
                    headerName: 'Actions', width: 180, field: 'actions', pinned: 'right', resizable: 'false', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
                    cellRenderer: function (params) {
                        return params.value;
                    }
                },
            ],
            defaultColumDef: {
                editable: false,
                sortable: true,
                resizable: true,
                filter: true,
                flex: 1,
                minWidth: 100,
            },
            nameError: false,
            locationError: false,
            emailError: false,
            hotelidError: false,
            id: '',
            activeClick: false,
            inactiveClick: false,
            totalClick: true,
            groupoption: { label: 'All', value: '', name: 'group' },
            totalCount: 0,
            trueCount: 0,
            falseCount: 0,
            groupName: '',
            group: '',
            perPage: 25,
            spinner: false,
        }
        this.imageCellRenderer = this.imageCellRenderer.bind(this);
        this.agGridContainerRef = React.createRef();
    }

    imageCellRenderer(params) {
        const img = document.createElement('div');
        ReactDOM.render(
            <img
                src={ImportedURL.FILEURL + params.value}
                onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = NoFoundImage;
                }}
                alt="Hotel Logo"
                width='65px' height='65px' class="contain_image"
            />,
            img
        );
        return img;
    }

    onRowClicked = event => {
        const { AccountState } = this.props;
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'View') {
            this.setState({ modalType: "View", page: this.gridApi.paginationGetCurrentPage(), id: event.data._id });
            this.props.ResetHotel();
        }
        if (value === 'Status') {
            this.setState({ statusId: event.data._id, statusChanges: !event.data.status, })
        }
        if (value === 'Edit') {
            this.setState({ modalType: "Edit", page: this.gridApi.paginationGetCurrentPage(), id: event.data._id });
            this.props.ResetHotel();
        }
    }

    componentDidMount() {
        localStorage.removeItem("searchgroup");
        localStorage.removeItem("status");
    }

    // Data source to set data in ag grid
    getDataSource = (query = {}) => {
        let group = query.group && query.group != undefined ? query.group : localStorage.getItem("searchgroup");
        let status = query.status && query.status != undefined ? query.status : localStorage.getItem("status");
        if (group) {
            query['group'] = group;
        }
        if (status != null && status != "null") {
            query['status'] = status;
        }
        return {
            getRows: async (params) => {
                this.setState({ spinner: true });
                // Grid params for filter and sort
                const page = Math.floor(params.startRow / this.state.perPage);
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                // Action call for hotels list
                this.props.EmptyHotel()
                await this.props.ListHotel({ ...query, perPage: this.state.perPage, page: page, filter: filter, sort: sortModel.length ? sortModel[0] : {} });
                const data = this.props.HotelState.listhotels;
                const total = this.props.HotelState.totalhotels;
                const { AccountState, LandingState } = this.props;
                const phoneCode = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 0 ? LandingState.landingdata.data[1] : [];;
                var rowData = []
                if (phoneCode && phoneCode.length > 0) {
                    rowData = data;
                }
                const previleges = AccountState.previleges;
                const hotelPrevilege = previleges.find(obj => { return obj.name == "Hotel" });
                const editOption = hotelPrevilege.edit ? '<button type="button" class="btn btn-icon" title="Edit" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
                // const deleteOption = ImportedURL.SUPERADMIN.includes(AccountState.role) ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : '';
                const template = '<div><button title="View" type="button" class="btn btn-icon" data-action-type="View"><i class="fa fa-eye" style="color: #1DC9B7 !important" data-action-type="View"></i></button>'
                    + editOption
                    // + deleteOption
                    + '</div>'

                rowData.forEach(object => {
                    object.actions = template;
                    object.phoneCode = phoneCode;
                });
                this.setState({ spinner: false });
                const lbLastRowOnPageEl = document.querySelector(`[ref=\'lbLastRowOnPage\']`);
                if (lbLastRowOnPageEl) {
                    lbLastRowOnPageEl.innerHTML = (rowData) ? rowData.length + params.startRow : 0;
                }
                params.successCallback(rowData, total);

            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // To set data once grid api is updated
        if (this.gridApi) {
            if (this.props.AccountState.previleges && prevState.gridApi !== this.state.gridApi) {
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
            }
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };

    onPerPageChange = (e) => {
        const { value } = e;
        this.props.EmptyHotel()
        this.setState({ perPage: value }, () => {
            if (this.gridApi) {
                this.gridApi.paginationSetPageSize(parseInt(value, 10));
                this.gridApi.purgeInfiniteCache();
            }
        });
    };

    setGroup = e => {
        const { name, value, label } = e;
        localStorage.setItem('searchgroup', value)
        const dataSource = this.getDataSource({ group: value, status: this.state.status })
        this.gridApi.setDatasource(dataSource);
        this.setState({ [name + 'option']: e, groupName: label, group: value })
    }

    exportToCSV = () => {
        const account = this.props.AccountState.account;
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: ['group.name', 'HotelName', 'hotelid', 'HotelAddress', 'phonenumber'],
            fileName: "hotels" + "_" + ((this.state.groupName && this.state.groupName != "All") ? (this.state.groupName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const account = this.props.AccountState.account;
        const { HotelState, LandingState } = this.props;
        const phoneCode = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[1] : [];;
        const exportData = HotelState.listhotels;
        let ccData = '';
        const selectedProperties = (this.state.groupName && this.state.groupName != "All") ? ['name', 'hotelid', 'address', 'cc', 'phonenumber'] : ['group', 'name', 'hotelid', 'address', 'cc', 'phonenumber'];
        const selected = exportData.map(item => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    if (property == "name") {
                        selectedData[property] = item[property] ? item[property] : "---"
                    } else if (property == "cc") {
                        if (phoneCode.length > 0) {
                            phoneCode.map(temp => {
                                if (temp.code === item[property]) {
                                    ccData = temp.phonecode
                                }
                                if (temp.phonecode === item[property]) {
                                    ccData = temp.phonecode
                                }
                            })
                        }
                    } else if (property == "phonenumber") {
                        selectedData[property] = item[property] ? ccData + ' ' + item[property] : "";
                    } else if (property == "address") {
                        selectedData[property] = item['address'] + ' , ' + item['city'] + ' , ' + item['state'] + ' , ' + item['postcode'] + ' , ' + item['country'] + '.'
                    } else if (property == "group") {
                        selectedData[property] = (item[property] && item[property].name) ? item[property].name : "";
                    } else {
                        selectedData[property] = item[property];
                    }
                }
            });
            return selectedData;
        });
        let data = {
            title: (this.state.groupName) ? (this.state.groupName != "All" ? this.state.groupName + " - Hotels List" : "Hotels List") : "Hotels List",
            head: (this.state.groupName && this.state.groupName != "All") ? ['#', "Hotel Name", 'Hotel ID', 'Address', "Phone #"] : ['#', 'Group', "Hotel Name", 'Hotel ID', 'Address', "Phone #"],
            body: selected,
            hotelNameExport: (this.state.groupName && this.state.groupName != "All") ? account.hotelNameExport : '',
            hoteladdressExport: (this.state.groupName && this.state.groupName != "All") ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "hotels" + "_" + ((this.state.groupName && this.state.groupName != "All") ? (this.state.groupName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }
    total = e => {
        this.props.RestHotelList()
        this.setState({ activeClick: false, inactiveClick: false, totalClick: true, status: null })
        localStorage.removeItem('status')
        // Get data from API
        const dataSource = this.getDataSource({ group: this.state.group, })
        this.gridApi.setDatasource(dataSource);
    }
    active = e => {
        this.props.RestHotelList()
        this.setState({ activeClick: true, inactiveClick: false, totalClick: false, status: true })
        localStorage.setItem('status', true)
        // Get data from API
        const dataSource = this.getDataSource({ status: true, group: this.state.group })
        this.gridApi.setDatasource(dataSource);
    }
    inactive = e => {
        this.props.RestHotelList()
        this.setState({ inactiveClick: true, activeClick: false, totalClick: false, status: false })
        localStorage.setItem('status', false)
        // Get data from API
        const dataSource = this.getDataSource({ status: false, group: this.state.group })
        this.gridApi.setDatasource(dataSource);
    }
    disableModal = (modalname) => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        if (modalname == 'statusChange') {
            axios.post(ImportedURL.API.statusChange, { id: this.state.statusId, status: this.state.statusChanges, model: 'hotels' })
                .then((res) => {
                    const dataSource = this.getDataSource({ status: this.state.status, group: this.state.group })
                    this.gridApi.setDatasource(dataSource);
                    Success('Status updated successfully')
                }).catch(({ response }) => {
                    if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else {
                        Error(response.statusMessage)
                    }
                });
        }
    }
    render() {
        if (this.state.modalType === "View") return <Redirect to={'view-hotel/' + this.state.id} />
        if (this.state.modalType === "Edit") return <Redirect to={'edit-hotel/' + this.state.id + '?page=' + this.state.page} />
        const { HotelState, AccountState, LandingState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const rowData = HotelState.listhotels;
        const totalcount = HotelState.totalStatus;
        const activecount = HotelState.activecount;
        const inactivecount = HotelState.inactivecount;
        const spinner = HotelState.spinner || this.state.spinner;
        const previleges = AccountState.previleges;
        const hotelPrevilege = previleges.find(obj => { return obj.name == "Hotel" });
        const listGroup = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[3] : [];;
        const account = AccountState.account;
        var hotelOptions = (listGroup && listGroup.length > 0) ? [{ label: 'All', value: '', name: 'group' }] : [];
        listGroup && listGroup.filter(filterItem => filterItem.status === true).map(item => {
            hotelOptions.push({ label: item.name, value: item._id, name: 'group' });
        })
        const limitOptions = [
            // { value: '2', label: '2' },
            // { value: '4', label: '4' },
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        return (
            <>
                <div>
                    {ImportedURL.SUPERADMIN.includes(account.role) && <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-hotel"><h6 className="highlights_breadcrump">{languageData && languageData.HOTELS ? languageData.HOTELS : "Hotels"} {languageData && languageData.LIST ? languageData.LIST : "List"}</h6></Link></p>
                    </div>}
                    <div className="section-body mt-3 hotel_sec">
                        <div className="container-fluid">
                            <div className="row clearfix common_top_section">
                                <div className="col-12 col-md-12 col-xl-4">
                                    <div className="card">
                                        <div className="card-body ribbon" style={{ backgroundColor: this.state.totalClick ? '#1c3078' : '', borderRadius: this.state.totalClick ? '10px' : '', border: this.state.totalClick ? "2px solid grey" : '', boxShadow: this.state.totalClick ? '0 0 5px #555' : '' }} >
                                            <div className={`${!this.state.totalClick ? "ribbon-box orange" : "ribbon-box text-color white"} `}>{totalcount}</div>
                                            <a className="my_sort_cut text-muted" href="#" onClick={this.total}>
                                                <span className="dashbord_icons">
                                                    <i className="fe fe-search" style={{ color: this.state.totalClick ? '#1c3078' : '' }} id='total'></i>
                                                </span>
                                                <span className='dash_total' style={{ color: this.state.totalClick ? 'white' : '' }}>Total</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-xl-4">
                                    <div className="card">
                                        <div className="card-body ribbon" style={{ backgroundColor: this.state.activeClick ? '#1c3078' : '', borderRadius: this.state.activeClick ? '10px' : '', border: this.state.activeClick ? "2px solid grey" : '', boxShadow: this.state.activeClick ? '0 0 5px #555' : '' }}>
                                            <div className={`${!this.state.activeClick ? "ribbon-box orange" : "ribbon-box text-color white"} `}>{activecount}</div>
                                            <a className="my_sort_cut text-muted" href="#" onClick={this.active}>
                                                <span className="dashbord_icons">
                                                    <i className="fa fa-check" style={{ color: this.state.activeClick ? '#1c3078' : '' }}></i>
                                                </span>
                                                <span className='dash_total' style={{ color: this.state.activeClick ? 'white' : '' }}>Active</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 col-md-12 col-xl-4">
                                    <div className="card">
                                        <div className="card-body ribbon" style={{ backgroundColor: this.state.inactiveClick ? '#1c3078' : '', borderRadius: this.state.inactiveClick ? '10px' : '', border: this.state.inactiveClick ? "2px solid grey" : '', boxShadow: this.state.inactiveClick ? '0 0 5px #555' : '' }}>
                                            <div className={`${!this.state.inactiveClick ? "ribbon-box orange" : "ribbon-box text-color white"} `}>{inactivecount}</div>
                                            <a className="my_sort_cut text-muted" href="#" onClick={this.inactive}>
                                                <span className="dashbord_icons dashboard_icons_1">
                                                    <i className="fa fa-times" style={{ color: this.state.inactiveClick ? '#1c3078' : '' }}></i>
                                                </span>
                                                <span className='dash_total' style={{ color: this.state.inactiveClick ? 'white' : '' }}>Inactive</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-body hotel_sec">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                    <div className="card">
                                        <div className="card-header tit_res">
                                            <div className='d-flex'>
                                                <div className='rounded_icon'><i className="fa fa-building-o mr-2 "></i></div>
                                                <div className='d-flex align-items-center'>
                                                    <h3 className="card-title"> {languageData && languageData.HOTELS ? languageData.HOTELS : "Hotels"} {languageData && languageData.LIST ? languageData.LIST : "List"} </h3>
                                                </div>
                                            </div>

                                            <div className="card-options add_card_options">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <ul className="nav nav-tabs page-header-tab">
                                                    </ul>
                                                    <div className="header-action">
                                                        {hotelPrevilege.add ? <Link to='add-hotel'><button type="button" className="btn btn-primary" id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button></Link> : ''}
                                                    </div>
                                                </div>
                                                {rowData && rowData.length > 0 ? <>
                                                    <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                    <div className="dropdown-menu">
                                                        <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                        <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                    </div>
                                                </> : []}
                                            </div>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                    <label className="form-label"> {languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                    <div className="form-group">
                                                        <Select
                                                            onChange={this.onPerPageChange}
                                                            options={limitOptions}
                                                            defaultValue={limitOptions[0]}
                                                            className='limit_size'
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-lg-2 col-md-4 col-sm-6 col-xl-5">
                                                    <label className="form-label">{languageData && languageData.GROUP ? languageData.GROUP : "Group"}</label>
                                                    <div className="form-group">
                                                        <Select
                                                            value={this.state.groupoption ? { label: this.state.groupoption.label } : ''}
                                                            onChange={this.setGroup}
                                                            options={hotelOptions}
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="table-responsive">
                                                <div ref={this.agGridContainerRef} className={(rowData && rowData.length == 0) ? "ag-theme-alpine ag_grid_no_record_show" : "ag-theme-alpine"} style={{ textAlign: 'left' }}>
                                                    <AgGridReact
                                                        key={this.state.perPage}
                                                        rowHeight={82}
                                                        paginationPageSize={this.state.perPage}
                                                        cacheBlockSize={this.state.perPage}
                                                        pagination={true}
                                                        onRowClicked={this.onRowClicked}
                                                        domLayout={"autoHeight"}
                                                        defaultColDef={this.state.defaultColumDef}
                                                        onGridReady={this.onGridReady}
                                                        columnDefs={this.state.columnDefs}
                                                        rowDragManaged={true}
                                                        wrapText={true}
                                                        animateRows={true}
                                                        overlayNoRowsTemplate={"No rows to display"}
                                                        rowModelType={'infinite'}
                                                    >
                                                    </AgGridReact>
                                                </div>
                                                {
                                                    (rowData && rowData.length == 0)
                                                    &&
                                                    <span className='ag_grid_no_record_show_span' >No rows to display</span>
                                                }
                                                {(spinner || this.state.spinner) ?
                                                    <div className='common_loader_ag_grid'>
                                                        <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                        <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                        </Spinner>
                                                    </div>
                                                    : ""}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="overlay" style={{ display: (spinner || this.state.spinner) ? 'block' : 'none' }}></div>
                    </div>
                </div>
                <ModalStatus statusChange={() => this.disableModal('statusChange')} modalname="statusChange" image="status.png" content="Are you sure to change the status?" buttonok="Ok" buttoncancel="Cancel"></ModalStatus >
            </>
        )
    }
}
const mapStateToProps = state => ({
    HotelState: state.hotel,
    AccountState: state.account,
    LandingState: state.landing
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ LandingData: AC_PUSH_DATA_LANDING, ListCountry: AC_LIST_COUNTRY, RestHotelList: AC_EMPTY_HOTEL_LIST, HandleInputChange: AC_HANDLE_HOTEL_CHANGE, ListGroup: AC_LIST_GROUP, AddHotel: AC_ADD_HOTEL, ListHotel: AC_LIST_HOTEL, ViewHotel: AC_VIEW_HOTEL, ResetHotel: AC_RESET_HOTEL, UpdateHotel: AC_UPDATE_HOTEL, DeleteHotel: AC_DELETE_HOTEL, EmptyHotel: AC_EMPTY_HOTEL }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ListHotel);
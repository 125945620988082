import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_DYNAMIC_CATEGORY(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_DYNAMIC_CATEGORY", name: name, value: value })
	};
}

export function AC_HANDLE_DYNAMIC_CATEGORY_MULTIPLE(name, value, i) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_DYNAMIC_CATEGORY_MULTIPLE", name: name, value: value, i: i })
	};
}

export function AC_HANDLE_DYNAMIC_CATEGORY_MULTIPLE_UPDATE(name, value, i) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_DYNAMIC_CATEGORY_MULTIPLE_UPDATE", name: name, value: value, i: i })
	};
}

export function AC_LIST_DYNAMIC_CATEGORY(id) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listDynamicCategory + "/" + id)
			.then((res) => {
				dispatch({ type: "LIST_DYNAMIC_CATEGORY", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_LIST_DYNAMIC_CATEGORY_STATUS(id) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listCategoryStatus + "/" + id)
			.then((res) => {
				dispatch({ type: "LIST_DYNAMIC_CATEGORY_STATUS", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_LIST_HOTEL_DYNAMIC_CATEGORY(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.listHotelDynamicCatgory, { params: params })
			.then((res) => {
				dispatch({ type: "LIST_HOTEL_DYNAMIC_CATEGORY", payload: res.data, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}


export function AC_VIEW_DYNAMIC_CATEGORY(id) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.ViewDynamicCategory + "/" + id)
			.then((res) => {
				dispatch({ type: "VIEW_DYNAMIC_CATEGORY", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_EMPTY_DYNAMIC_CATEGORY() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_DYNAMIC_CATEGORY" })
	};
}

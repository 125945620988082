import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import Select from 'react-select';
import jsPDF from "jspdf";
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import reactCSS from 'reactcss'
import Spinner from 'react-bootstrap/Spinner';
import { Link } from 'react-router-dom';
import moment from 'moment';
import ListRoomtypeTrash from './settingroomtypetab';
import ListRoomTrash from './settingroomtab';
import { imageCellRendererList } from '../../common/validate';
// import OutofserviceTrash from '../SettingRoomtypeComponents/outofservicetab';
import showSwalDialog from '../../utilities/Modal/swal';
import ErrorResponse from '../../utilities/Error/errorstatus';


const initialState = {
    modalType: "Add",
    columnApi: '',
    gridApi: '',
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true,
    },
    tabposition: 'roomtype',
    columnDefs: [
        { headerName: '#', valueGetter: "node.rowIndex+1", width: 100, sortable: false, filter: false, cellStyle: { 'text-align': 'center' }, hide: 'true' },
        {
            headerName: 'Logo', width: 100, field: 'hotellogo', sortable: false, filter: false, "resizable": false,
            cellRenderer: imageCellRendererList,
        },
        { headerName: 'Hotel', field: 'hotelName', width: 300, floatingFilter: true },
        { headerName: 'Room Name', field: 'roomname', width: 200, cellStyle: { 'text-align': 'left' }, floatingFilter: true },
        {
            headerName: 'Auto Deleted', field: 'updatedAt', width: 130, cellStyle: { 'text-align': 'center' },
            cellRenderer: function (params) {
                var date1 = new Date(moment(params.data.updatedAt).tz(params.data.tzone).format(params.data.dFormat))
                var date2 = new Date(moment(new Date()).tz(params.data.tzone).format(params.data.dFormat))
                var difference = date2.getTime() - date1.getTime();
                var TotalDays = Math.ceil(difference / (1000 * 3600 * 24));
                var value = 30 - TotalDays
                if (value > 5) {
                    return value + ' days'
                } else {
                    if (value <= 0) {
                        return `<div style="color:red">` + 'Today' + `</div>`
                    } else {
                        if (value == 1) {
                            return `<div style="color:red">` + value + ' day' + `</div>`
                        } else {
                            return `<div style="color:red">` + value + ' days' + `</div>`
                        }
                    }
                }
            }
        },
        {
            headerName: 'Actions', width: 150, field: 'actions', headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                return '<div class="status_style"><span type="button" class="tag tag-danger" data-action-type="Restore">Restore</span></div>';
            }
        },
    ],
    rowData: [],
    spinner: true

}
class RoomType extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    onRowClicked = event => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Restore') {
            showSwalDialog('Do you want to recover data?', '', 'Yes', 'No', 'assets/images/restore.png').then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeleteSettingRoomtype + "/" + event.data._id)
                        .then((res) => {
                            Success((languageData && languageData.ROOM_TYPE ? languageData.ROOM_TYPE : "Room Type") + (languageData && languageData.RECOVERD_SUCCESSFULLY ? languageData.RECOVERD_SUCCESSFULLY : " recoverd successfully"));
                            axios.get(ImportedURL.API.listTrashSettingRoomtype)
                                .then((res) => {
                                    this.setState({ rowData: res.data })
                                })
                        }).catch((err) => { console.log(err); });
                }
            })
        }
    }

    roomtype = (e) => {
        this.setState({ tabposition: 'roomtype' })
    }
    rooms = (e) => {
        this.setState({ tabposition: 'rooms' })
    }
    render() {
        const { fixNavbar, AccountState, RoomtypeState, HotelState } = this.props;
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const account = this.props.AccountState.account;
        var dFormat = (account.dateformate != '' ? account.dateformate : 'MM/DD/YYYY');
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
        }
        if (this.state.rowData) {
            this.state.rowData.forEach(object => {
                object.dFormat = dFormat;
                object.tzone = tzone;
            });
        }
        return (
            <>
                <div>

                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump">{this.state.tabposition == 'roomtype' ? (languageData && languageData.ROOM_TYPE ? languageData.ROOM_TYPE + " Trash List" : "Room Type Trash List") : this.state.tabposition == 'rooms' ? (languageData && languageData.ROOMS ? languageData.ROOMS + " Trash List" : "Rooms Trash List") : ""} </h6></p>
                        </div>
                    </div>
                </div>
                <div>
                    <div className="section-body pt-3 ">
                        <div className="container-fluid">
                            <div className="card">
                                <div className='card-body dashboard_mom'>
                                    <div className='upcommcomp_btn'>
                                        <div style={{ position: 'relative' }}>
                                            <button type='button' className={`${this.state.tabposition == 'roomtype' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={this.roomtype} style={{ background: this.state.tabposition == 'roomtype' ? 'after_click' : 'before_click' }}>{((languageData && languageData.ROOM_TYPE_SETTINGS) ? languageData.ROOM_TYPE_SETTINGS : "Room Type")}</button><span className="badge badge-light" style={{ backgroundColor: '#17a2b8' }}></span>
                                        </div>

                                        <div style={{ position: 'relative' }}>
                                            <button type='button' className={`${this.state.tabposition == 'rooms' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={this.rooms}>{((languageData && languageData.ROOM_SETTINGS) ? languageData.ROOM_SETTINGS : "Room")}</button><span className="badge badge-light" style={{ backgroundColor: 'red' }}></span>
                                        </div>
                                    </div>

                                </div>

                                {this.state.tabposition == 'roomtype' ?
                                    <div>
                                        <ListRoomtypeTrash historyPush={this.props.history} />
                                    </div>
                                    : ''}
                                {this.state.tabposition == 'rooms' ?
                                    <div>
                                        <ListRoomTrash historyPush={this.props.history} />
                                    </div>
                                    : ''}
                                {/* {this.state.tabposition == 'service' ?
                                    <div>
                                        <Outofservice />
                                    </div>
                                    : ''} */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    SettingRoomtypeState: state.settingroomtype,
    HotelState: state.hotel,
    AccountState: state.account,

})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

        ListHotel: AC_LIST_HOTEL,

    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(RoomType);

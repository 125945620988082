const initialState = {
    dynamicCategory: {
        category: [{
            categoryname: '',
            name: '',
            email: '',
            phonenumber: '',
            _id: '',
            previleges: '',
        }],
        status: 1,
    },
    listDynamicCategory: [],
    listHotel: [],
    spinner: true,
    status: true
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_HOTEL_CHANGE':
            return Object.assign({}, state, {
                hotel: {
                    ...state.hotel,
                    [action.name]: action.value
                }
            })
        case 'HANDLE_DYNAMIC_CATEGORY_MULTIPLE':
            return {
                ...state,
                dynamicCategory: {
                    ...state.dynamicCategory,
                    category:
                        [
                            ...state.dynamicCategory.category.slice(0, action.i),
                            { ...state.dynamicCategory.category[action.i], [action.name]: action.value },
                            ...state.dynamicCategory.category.slice(action.i + 1)
                        ]
                }
            }
        case 'HANDLE_DYNAMIC_CATEGORY_MULTIPLE_UPDATE':
            if (action.name == 'Add') {
                return {
                    ...state,
                    dynamicCategory: {
                        ...state.dynamicCategory,
                        //    category: [...state.dynamicCategory.category, action.value]
                        category:
                            [
                                ...state.dynamicCategory.category.slice(0, action.i),
                                action.value,
                                ...state.dynamicCategory.category.slice(action.i)
                            ]
                    }
                }
            } else {
                return {
                    ...state,
                    dynamicCategory: {
                        ...state.dynamicCategory,
                        category: [...state.dynamicCategory.category.filter((event, i) => i !== action.i)]
                    }
                }
            }
        case 'LIST_DYNAMIC_CATEGORY':
            return Object.assign({}, state, {
                dynamicCategory: {
                    ...state.dynamicCategory,
                    category: action.payload
                },
                spinner: action.spinner
            })
        case 'LIST_DYNAMIC_CATEGORY_STATUS':
            return Object.assign({}, state, {
                status: action.payload,
                spinner: false
            })
        case 'LIST_HOTEL_DYNAMIC_CATEGORY':
            return Object.assign({}, state, {
                listHotel: action.payload,
                spinner: false
            })
        case 'VIEW_DYNAMIC_CATEGORY':
            return Object.assign({}, state, {
                dynamicCategory: {
                    ...state.dynamicCategory,
                    category: action.payload
                },
                spinner: false
            })
        default:
            return state;
    }
}
import axios from 'axios';
import React, { Component } from 'react';
import { Container, Row } from 'react-bootstrap';
import { BsHandThumbsUp } from "react-icons/bs";
import ImportedURL from '../../common/api';
import Banner from './banner.png';


export default class Thanks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hotelData: {},
        };
    }
    // componentDidMount() {
   

    //     const queryparams = new URLSearchParams(window.location.search);
    //     const hotel = queryparams.get('hotel');
    //     const { params } = this.props.match;
    //     this.setState({ review: params.id })
    //     // this.props.ViewHotel(hotel);
    //     axios.get(ImportedURL.API.getReview + "/" + params.id)
    //         .then((res) => {
    //             this.setState({ hotelData: res.data })
    //             // dispatch({ type: "VIEW_HOTEL", payload: res.data })
    //         }).catch((err) => { console.log(err); });
    // }
    render() {
       
        return (
            <section className='review_pro_sec'>
                <Container >
                    <div className='iprotex'>
                        <div className='img_size'>
                            {/* <img src={this.state.hotelData.hotelcard ? ImportedURL.FILEURL + this.state.hotelData.hotelcard : Banner} alt='not found' /> */}
                        </div>
                        <div className='content'>
                            <div className='icons_smile'>
                                <p style={{ color: '#1c3078', fontWeight: '600', fontSize: '25px' }}>Thank you for your valuable feedback </p>
                            </div>
                            <div className='thumcsup'>
                                <div style={{ width: "150px", height: '150px', margin: ' 0 auto' }}>
                                    <img src='../../assets/images/thums.png' style={{ width: '100%', height: '100%', objectFit: 'fill' }} />
                                </div>
                            </div>
                            <p style={{ color: '#000', fontWeight: '600', fontSize: '17px', marginTop: '20px' }}>We sincerely regret the inconvenience caused. We are giving our best to resolve the issue at the earliest and will ensure that you have one of the finest stays with us in the future.</p>
                            {/* <div className='complete_login'>
                                <div>
                                    <a href="/list-category">
                                        <button type="button" className="btn common_bc" id="Add"><i className="fa fa-arrow-left mr-2" data-toggle="tooltip" title="fa fa-arrow-left"></i>Back</button>
                                    </a>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </Container>
            </ section>
        )
    }
}

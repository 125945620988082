import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_SEND_REVIEW(formData) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.sendReview, formData)
			.then((res) => {
				dispatch({ type: "SEND_REVIEW", payload: res.data })
			}).catch(({ response }) => { console.log(response); });
	};
}

export function AC_SEND_FEEDBACK(formData) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.sendFeedback, formData)
			.then((res) => {
				Success(res.statusText);
				dispatch({ type: "SEND_REVIEW", payload: res.data })
			}).catch(({ response }) => { console.log(response); });
	};
}

export function AC_LIST_FEEDBACK(formData) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.getFeedback, formData)
			.then((res) => {
				dispatch({ type: "LIST_REVIEW", payload: res.data, spinner: false })
			}).catch(({ response }) => { console.log(response); });
	};
}

export function AC_GET_REVIEW_BY_ID(formData, type = 'last') {
	return function (dispatch) {
		return axios.post(ImportedURL.API.reviewByClient, formData)
			.then((res) => {
				dispatch({ type: "GET_REVIEW_BY_ID", payload: res.data, gettype: type })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_REVIEW_KEYWORD(params = {}) {
	return function (dispatch) {
		return axios.get(ImportedURL.API.reviewKeyword, { params: params })
			.then((res) => {
				dispatch({ type: "REVIEW_KEYWORD", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_REVIEW_KEYWORD_EMPTY() {
	return function (dispatch) {
		dispatch({ type: "REVIEW_KEYWORD_EMPTY", })
	};
}

export function AC_HANDLE_REVIEW_KEY_SEARCH(value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_REVIEW_KEY_SEARCH", value: value })
	};
}

export function AC_RESET_FEEDBACK() {
	return function (dispatch) {
		dispatch({ type: "RESET_FEEDBACK" })
	};
}

export function AC_EMPTY_SPIN() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_SPIN" })
	};
}

export function AC_LIST_FEEDBACK_TAB_CHANGES(value) {
	return function (dispatch) {
		dispatch({ type: "LIST_FEEDBACK_TAB_CHANGES", value: value })
	};
}

export function AC_SEND_SENTIMENTAL_ANALYSIS_DATA(data) {
	return function (dispatch) {
		dispatch({ type: 'REVIEW_KEYWORD', payload: data });
	};
}
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import { Link } from 'react-router-dom';
import { Imagevalidation, imageCellRendererList } from '../../common/validate';
import showSwalDialog from '../../utilities/Modal/swal';
import HotelSelectComponent from '../../utilities/SelectOption/hotelselect';
import ModalSpinnerComponent from '../../utilities/Spinner/modalspinner';
import AgGridDragAndDropComponent from '../../utilities/AgGridReact/aggriddraganddrop';
import SelectComponent from '../../utilities/SelectOption/select';
import { AC_MULTIPLE_LIST_CONFIGRATIONS } from '../../actions/configurationAction';
import MultiSelectComponent from '../../utilities/SelectOption/multiselect';
import { AC_EMPTY_PMS_PREVILEGES, AC_HANDLE_PMS_PREVILEGES_CHANGE, AC_LIST_PMS_PREVILEGES, AC_MODAL_SPINNER_PMS_PREVILEGES, AC_SPINNER_PMS_PREVILEGES, AC_VIEW_PMS_PREVILEGES } from '../../actions/pmsprivilegesAction';

const initialState = {
    modalType: "Add",
    columnApi: '',
    gridApi: '',
    columnDefs: [
        { headerName: 'Privilege Name', field: 'privilegename', width: 200, rowDrag: true, floatingFilter: true, },
        { headerName: 'Header Name', field: 'headername', width: 200, floatingFilter: true, },
        { headerName: 'Type', field: 'types', width: 200, floatingFilter: true, },
        {
            headerName: 'Mobile Status', field: 'ismobilestatus', width: 200,
            valueGetter: function (params) {
                if (params.data) {
                    if (params.data.ismobilestatus) return "Allow"
                    else return "Deny"
                } else {
                    return "---"
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                if (params.data)
                    if (params.value) {
                        return params.value;
                    } else {
                        return "---"
                    }
            }
        },
    ],
    defaultColDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
    },
    perPage: 25,
    headernameError: false,
    privilegenameError: false,
    typesError: false,
    columnreset: true,
    mainoptionError: false,
    typesSelectAll: true,
}
class PMSPrevileges extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
        this.privilegenameInputRef = React.createRef();
        this.headernameInputRef = React.createRef();
        this.typesInputRef = React.createRef();
    }
    componentDidMount() {
        const { AccountState } = this.props;
        this.setState({ hotelName: AccountState.account.hotelName })
        this.props.SpinnerPMSPrivileges();
        this.props.ListConfiguration({ parentslug: ['pms-privileges-name', 'pms-privilege-header-name', 'pms-privileges-type', 'pms-privileges-sub-name'] });
        this.props.ListPMSPrivileges();
    }
    componentDidUpdate(prevProps, prevState) {
        const { AccountState } = this.props;
        const role = AccountState.role;
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role) && this.state.columnreset) {
            this.UpdateColumnDefs();
            this.setState({ columnreset: false })
        }
    }
    UpdateColumnDefs = () => {
        const role = this.props.AccountState.role;
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role)) {
            let updatedColumnDefs = [...this.state.columnDefs];
            updatedColumnDefs = updatedColumnDefs.map((col) => {
                if (col.field === 'hotel' || col.field === 'logo' || col.field === 'mainoption') {
                    return { ...col, hide: false };
                }
                return col;
            });
            this.gridApi.setColumnDefs(updatedColumnDefs);
        }
    }
    changeModal = (e) => {
        this.setState({ modalType: "Add", headernameError: false, privilegenameError: false, typesError: false, typesSelectAll: true });
        this.props.ResetPMSPrivileges();
        const { AccountState } = this.props;
        const account = AccountState.account;
        if (!ImportedURL.SUPERADMIN.includes(AccountState.role)) {
            this.props.HandleInputChange('hotel', account.hotel);
        }
    }
    handleChange = e => {
        const { name, value, checked } = e.target;
        const ErrorName = name + "Error";
        this.setState({ [ErrorName]: false });
        if (name == 'ismobilestatus') {
            this.props.HandleInputChange(name, checked);
        } else {
            this.props.HandleInputChange(name, value);
        }
    }
    handleChangeSelect = e => {
        this.setState({ editSpi: false })
        const { name, value, selectAll } = e;
        const Error = name + "Error";
        const SelectAll = name + "SelectAll";
        this.setState({ [Error]: false, [SelectAll]: selectAll });
        this.props.HandleInputChange(name, value);
    }
    onRowClicked = event => {
        const { PMSPrivilegesState } = this.props
        const { mainoptionlist, total } = PMSPrivilegesState;
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (value === 'Edit') {
            this.props.ModalSpinnerPMSPrivileges();
            this.setState({ modalType: "Edit", id: event.data._id, headernameError: false, privilegenameError: false, typesError: false, typesSelectAll: true });
            this.props.ViewPMSPrivileges(event.data._id);
        }
        if (value === 'View') {
            this.setState({ modalType: "View" })
            this.props.ModalSpinnerPMSPrivileges()
            this.props.ViewPMSPrivileges(event.data._id)
        }
        if (value == 'Status') {
            if (!event.data.isDisable)
                showSwalDialog('Are you sure you want to change the status?', '', 'Ok', 'Cancel', 'assets/images/status.png').then((result) => {
                    if (result.isConfirmed) {
                        axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'suboptions' })
                            .then((data) => {
                                this.props.SpinnerPMSPrivileges();
                                this.props.ListPMSPrivileges();
                                Success((languageData && languageData.STATUS ? languageData.STATUS : "Status") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"))
                            }).catch(({ response }) => {
                                this.ErrorResponse(response)
                            });
                    }
                })
        }
    }
    submit = () => {
        const { PMSPrivilegesState, AccountState } = this.props
        const data = PMSPrivilegesState.pmsprevileges;
        const languageData = AccountState.account.SelectedlanguageData;
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        }

        let valid = 1;

        if (!data.types) {
            this.setState({ typesError: true });
            this.typesInputRef.current.focus();
            valid = 0;
        }
        if (!data.headername) {
            this.setState({ headernameError: true });
            this.headernameInputRef.current.focus();
            valid = 0;
        }
        if (!data.privilegename) {
            this.setState({ privilegenameError: true });
            this.privilegenameInputRef.current.focus();
            valid = 0;
        }

        if (valid) {
            data['ismobilestatus'] = data.ismobilestatus ? data.ismobilestatus : false
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addPMSPrivileges, data)
                    .then((res) => {
                        this.setState({ saving: false })
                        this.props.SpinnerPMSPrivileges();
                        this.props.ListPMSPrivileges();
                        Success((languageData && languageData.PMS_PRIVILEGES_LABEL ? languageData.PMS_PRIVILEGES_LABEL : "PMS Privileges") + (languageData && languageData.CREATED_SUCCESSFULLY ? languageData.CREATED_SUCCESSFULLY : " created Successfully"));
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.ErrorResponse(response)
                    });
            } else {
                axios.post(ImportedURL.API.updatePMSPrivileges + '/' + this.state.id, data)
                    .then((res) => {
                        this.setState({ saving: false })
                        this.props.SpinnerPMSPrivileges();
                        this.props.ListPMSPrivileges();
                        Success((languageData && languageData.PMS_PRIVILEGES_LABEL ? languageData.PMS_PRIVILEGES_LABEL : "PMS Privileges") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " updated Successfully"));
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.ErrorResponse(response)
                    });
            }
        }
    }
    ErrorResponse = (response) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData;
        this.setState({ saving: false });
        if (response)
            if (response.status == 500) {
                Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
            } else if (response.status == 400) {
                Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
            } else if (response.status == 502) {
                Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
            } else if (response.status == 409) {
                Error((languageData && languageData.PMS_PRIVILEGES_LABEL ? languageData.PMS_PRIVILEGES_LABEL : "PMS Privileges") + (languageData && languageData.ALREADY_EXIT ? languageData.ALREADY_EXIT : " Already exist"))
            } else {
                Error(response.status + " " + response.statusMessage)
            }
    }
    searchUser = e => {
        const { name, value, label } = e;
        this.props.SpinnerPMSPrivileges();
        this.props.ListPMSPrivileges({ hotel: value });
        this.setState({ searchhoteloption: e, searchhotel: value, hotelName: label })
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };
    exportToCSV = () => {
        const account = this.props.AccountState.account;
        this.gridApi.exportDataAsCsv({
            columnKeys: ['privilegename', 'headername', 'types', 'ismobilestatus'],
            fileName: 'pmsprevileges' + "_" + (this.state.hotelName && (this.state.hotelName).replace(/ /g, '_')) + (account.currentTime).replace(/ /g, '_') + ".csv",
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }
    exportPDF = () => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const account = this.props.AccountState.account;
        const exportData = this.props.PMSPrivilegesState.pmsprevilegeslist;
        const selectedProperties = ['privilegename', 'headername', 'types', 'ismobilestatus'];
        const selected = exportData.map((item, i) => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    if (property == 'ismobilestatus') {
                        selectedData[property] = item.ismobilestatus ? "Allow" : 'Deny';
                    } else {
                        selectedData[property] = item[property] ? item[property] : '---';
                    }
                }
            });
            return selectedData;
        });
        let data = {
            title: (languageData && languageData.PMS_PRIVILEGES_LABEL ? (languageData.PMS_PRIVILEGES_LABEL) : 'PMS Privileges') + (languageData && languageData.LIST ? languageData.LIST : " List"),
            head: ['#', "Privilege Name", "Header Name", "Type", "Mobile Status"],
            body: selected,
            hotelNameExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hotelNameExport : '',
            hoteladdressExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "pmsprevileges" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }
    onRowDragEnd = (e) => {
        let arr = []
        this.gridApi.forEachNode((rowNode, index) => {
            arr.push({ id: rowNode.data._id, sort: index })
        });
        axios.post(ImportedURL.API.sortCategory, { data: arr, model: 'pmsprivileges' })
            .then((res) => {
                this.props.ListPMSPrivileges();
            }).catch(({ response }) => {
            });
    };
    onFilterChanged = () => {
        const api = this.gridApi;
        if (api && api.getDisplayedRowCount() === 0) {
            api.showNoRowsOverlay();
            this.setState({ hideExport: true })
        } else {
            api.hideOverlay();
            this.setState({ hideExport: false })
        }
    };
    render() {
        const { AccountState, PMSPrivilegesState, LandingState, ConfigurationState } = this.props;
        const listHotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 0 ? LandingState.landingdata.data[0] : [];
        const rowData = PMSPrivilegesState.pmsprevilegeslist;
        const data = PMSPrivilegesState.pmsprevileges;
        const spinner = PMSPrivilegesState.spinner;
        const modalspinner = PMSPrivilegesState.modalspinner;
        const mainoptionlist = PMSPrivilegesState.mainoptionlist;
        const privilegesname = (ConfigurationState.configration && ConfigurationState.configration['pms-privileges-name']) ? ConfigurationState.configration['pms-privileges-name'] : [];
        const privilegeheadername = (ConfigurationState.configration && ConfigurationState.configration['pms-privilege-header-name']) ? ConfigurationState.configration['pms-privilege-header-name'] : [];
        const privilegestype = (ConfigurationState.configration && ConfigurationState.configration['pms-privileges-type']) ? ConfigurationState.configration['pms-privileges-type'] : [];
        const privilegessubtype = (ConfigurationState.configration && ConfigurationState.configration['pms-privileges-sub-name']) ? ConfigurationState.configration['pms-privileges-sub-name'] : [];

        const account = AccountState.account;
        const languageData = AccountState.account.SelectedlanguageData
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Main_Option" });
        const editOption = Previlege?.edit ? '<button type="button" class="btn btn-icon" data-toggle="modal" data-target="#exampleModal" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-toggle="modal" data-action-type="Edit"></i></button>' : '';
        const viewOption = Previlege?.view ? '<button type="button" class="btn btn-icon" data-toggle="modal" data-target="#exampleModal" data-action-type="View"><i class="fa fa-eye" style="color: #2196F3 !important" data-toggle="modal" data-action-type="View"></i></button>' : '';
        if (rowData && rowData.length > 0) {
            rowData.forEach(object => {
                object.actions = viewOption + editOption;
            });
        }

        var hotelOptionSearch = [];
        if (listHotels.filter(filterItem => filterItem.status === true).length > 1) {
            hotelOptionSearch.push({ label: 'All', value: '', name: 'searchhotel' })
        }
        if (listHotels.length > 0) {
            listHotels.filter(filterItem => filterItem.status === true).map(item => {
                hotelOptionSearch.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'searchhotel' });
            })
        }



        var privilegesnameOption = [];
        var privilegesnameSelect = "";
        if (privilegesname && privilegesname.length > 0) {
            privilegesname.filter(filterItem => filterItem.status === true).map((item) => {
                if (data.privilegename == item._id) {
                    privilegesnameSelect = { label: `${item.name}`, value: item._id, name: 'privilegename' }
                }
                privilegesnameOption.push({ label: `${item.name}`, value: item._id, name: 'privilegename' });
            })
        }
        var privilegeheadernameOption = [];
        var privilegeheadernameSelect = "";
        if (privilegeheadername && privilegeheadername.length > 0) {
            privilegeheadername.filter(filterItem => filterItem.status === true).map((item) => {
                if (data.headername == item._id) {
                    privilegeheadernameSelect = { label: `${item.name}`, value: item._id, name: 'headername' }
                }
                privilegeheadernameOption.push({ label: `${item.name}`, value: item._id, name: 'headername' });
            })
        }
        var privilegestypeOption = [];
        var privilegestypeSelect = [];
        if (privilegestype && privilegestype.length > 0) {
            privilegestype.map(item => {
                if (data.types && data.types.includes(item._id)) {
                    privilegestypeSelect.push({ label: item.name, value: item._id, name: 'types' });
                }
                privilegestypeOption.push({ label: item.name, value: item._id, name: 'types' });
            })
        }
        let showtypesOption = privilegestypeOption.filter((e) => !(data.types && data.types.includes(e.value)));
        if (showtypesOption.length > 1 && privilegestypeOption.length != privilegestypeSelect.length && this.state.typesSelectAll) {
            privilegestypeOption.unshift({ label: "All", value: '', name: 'types' })
        }
        var privilegessubtypeOption = [];
        var privilegesprivilegesubnameelect = [];
        if (privilegessubtype && privilegessubtype.length > 0) {
            privilegessubtype.map(item => {
                if (data.privilegesubname && data.privilegesubname.includes(item._id)) {
                    privilegesprivilegesubnameelect.push({ label: item.name, value: item._id, name: 'privilegesubname' });
                }
                privilegessubtypeOption.push({ label: item.name, value: item._id, name: 'privilegesubname' });
            })
        }
        let showprivilegesubnameOption = privilegessubtypeOption.filter((e) => !(data.privilegesubname && data.privilegesubname.includes(e.value)));
        if (showprivilegesubnameOption.length > 1 && privilegessubtypeOption.length != privilegesprivilegesubnameelect.length && this.state.typesSelectAll) {
            privilegessubtypeOption.unshift({ label: "All", value: '', name: 'privilegesubname' })
        }

        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/todo-tag"><h6 className="highlights_breadcrump">{languageData && languageData.PMS_PRIVILEGES_LABEL ? languageData.PMS_PRIVILEGES_LABEL : "PMS Privileges"}{languageData && languageData.LIST ? languageData.LIST : " List"}</h6></Link></p>
                        </div>
                        <div className="section-body pt-3 user_sec">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='rounded_icon'><i className="fa fa-universal-access mr-2"></i></div>
                                                <h3 className="card-title">{languageData && languageData.PMS_PRIVILEGES_LABEL ? languageData.PMS_PRIVILEGES_LABEL : "PMS Privileges"}{languageData && languageData.LIST ? languageData.LIST : " List"}</h3>
                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="header-action" >
                                                            {Previlege?.add ? <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" id='Add' onClick={(e) => this.changeModal(e)}><i className="fe fe-plus mr-2" id='Add' />Add</button> : ''}
                                                        </div>
                                                    </div>
                                                    {rowData && rowData.length > 0 ?
                                                        <>
                                                            <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                            <div className="dropdown-menu">
                                                                <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                            </div>
                                                        </>
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <AgGridDragAndDropComponent spinner={spinner || this.state.spinner} rowData={rowData} onRowClicked={this.onRowClicked} onGridReady={this.onGridReady} onFilterChanged={this.onFilterChanged} onRowDragEnd={this.onRowDragEnd.bind(this)} state={this.state} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Modal */}

                    <div className="modal fade country_model type_form" id="exampleModal" autoComplete="off" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className='rounded_icon'><i className="fa fa-universal-access mr-2 "></i></div>
                                    <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} {(languageData && languageData.PMS_PRIVILEGES_LABEL ? languageData.PMS_PRIVILEGES_LABEL : "PMS Privileges")}</h5>
                                    <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                            <img src='../../assets/images/cancel.png' />
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    {this.state.modalType == "View"
                                        ?
                                        <div className='row'>
                                            <div className='col-lg-4 mt-2'>
                                                <label className='label_black'>Privilege Name</label>
                                            </div>
                                            <div className='col-lg-2 mt-2'>
                                                <label className='label_black'>:</label>
                                            </div>
                                            <div className='col-lg-6 mt-2'>
                                                <span className='mb-4'>{data.privilegenameName ? data.privilegenameName : '---'}</span>
                                            </div>
                                            <div className='col-lg-4 mt-2'>
                                                <label className='label_black'>Header Name</label>
                                            </div>
                                            <div className='col-lg-2 mt-2'>
                                                <label className='label_black'>:</label>
                                            </div>
                                            <div className='col-lg-6 mt-2'>
                                                <span className='mb-4'>{data.headernameName ? data.headernameName : '---'}</span>
                                            </div>
                                            <div className='col-lg-4 mt-2'>
                                                <label className='label_black'>Privilege Sub Name</label>
                                            </div>
                                            <div className='col-lg-2 mt-2'>
                                                <label className='label_black'>:</label>
                                            </div>
                                            <div className='col-lg-6 mt-2'>
                                                <span className='mb-4'>{data.privilegesubnameName ? data.privilegesubnameName : "---"}</span>
                                            </div>
                                            <div className='col-lg-4 mt-2'>
                                                <label className='label_black'>Type</label>
                                            </div>
                                            <div className='col-lg-2 mt-2'>
                                                <label className='label_black'>:</label>
                                            </div>
                                            <div className='col-lg-6 mt-2'>
                                                <span className='mb-4'>{data.typesName ? data.typesName : "---"}</span>
                                            </div>
                                            <div className='col-lg-4 mt-2'>
                                                <label className='label_black'>Mobile Status</label>
                                            </div>
                                            <div className='col-lg-2 mt-2'>
                                                <label className='label_black'>:</label>
                                            </div>
                                            <div className='col-lg-6 mt-2'>
                                                <span className='mb-4'>{data.ismobilestatus ? "Allow" : "Deny"}</span>
                                            </div>
                                        </div>
                                        :
                                        <div className="row">
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label" >Privilege Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <SelectComponent value={privilegesnameSelect} options={privilegesnameOption} onChange={this.handleChangeSelect} selectRef={this.privilegenameInputRef} />
                                                    <div className="invalid-feedback" style={{ display: this.state.privilegenameError ? "block" : 'none' }}>Privilege Name is required</div>
                                                </div>
                                            </div>
                                            <div className="col-lg-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label" >Header Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <SelectComponent value={privilegeheadernameSelect} options={privilegeheadernameOption} onChange={this.handleChangeSelect} selectRef={this.headernameInputRef} />
                                                    <div className="invalid-feedback" style={{ display: this.state.headernameError ? "block" : 'none' }}>Header Name is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label" >Privilege Sub Name</label>
                                                    <MultiSelectComponent value={privilegesprivilegesubnameelect} onChange={this.handleChangeSelect} options={privilegessubtypeOption} name='privilegesubname' />
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label" >Type<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <MultiSelectComponent value={privilegestypeSelect} onChange={this.handleChangeSelect} options={privilegestypeOption} name='types' selectRef={this.typesInputRef} />
                                                    <div className="invalid-feedback" style={{ display: this.state.typesError ? "block" : 'none' }}>Type is required</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-12 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label" >Mobile Status</label>
                                                    <label className="custom-switch">
                                                        <input
                                                            type="checkbox"
                                                            name="ismobilestatus"
                                                            className="custom-switch-input"
                                                            checked={data.ismobilestatus}
                                                            onChange={this.handleChange}
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                                {this.state.modalType != "View"
                                    ?
                                    <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="" >
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>{this.state.modalType == "Edit" ? "Updating" : "Saving"}</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>{this.state.modalType == "Edit" ? "Update" : "Save"}</button>
                                            }
                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                        </div>
                                    </div>
                                    : ""}
                                <div id='overlay-model' style={{ display: modalspinner ? 'block' : 'none' }}></div>
                                <ModalSpinnerComponent spinner={modalspinner} />
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
    LandingState: state.landing,
    PMSPrivilegesState: state.pmsprivileges,
    ConfigurationState: state.configuration,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListConfiguration: AC_MULTIPLE_LIST_CONFIGRATIONS,
        ResetPMSPrivileges: AC_EMPTY_PMS_PREVILEGES,
        HandleInputChange: AC_HANDLE_PMS_PREVILEGES_CHANGE,
        ListPMSPrivileges: AC_LIST_PMS_PREVILEGES,
        ViewPMSPrivileges: AC_VIEW_PMS_PREVILEGES,
        SpinnerPMSPrivileges: AC_SPINNER_PMS_PREVILEGES,
        ModalSpinnerPMSPrivileges: AC_MODAL_SPINNER_PMS_PREVILEGES,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(PMSPrevileges);
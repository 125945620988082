import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';

export function AC_HANDLE_SOCIALMEDIA_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_SOCIALMEDIA_CHANGE", name: name, value: value })
    };
}

export function AC_ADD_SOCIALMEDIA(formData) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.addSocialMedia, formData)
            .then((res) => {
                Success(res.statusText);
                dispatch({ type: "LIST_SOCIALMEDIA", payload: res.data })
            }).catch(({ response }) => { Error(response.statusText) });
    };
}

export function AC_LIST_SOCIALMEDIA(hotelStatus) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.listSocialMedia)
            .then((res) => {
                if (hotelStatus != undefined && hotelStatus === true) {
                    let elfValue = '';
                    if (res.data != undefined && res.data.length > 0) {
                        for (let i = 0; i < res.data.length; i++) {
                            if (res.data[i].status) {
                                elfValue = res.data[i]._id
                                break;
                            }
                        }
                    } dispatch({ type: "HANDLE_HOTEL_ELFSIGHTS_CHANGE", name: "elfmedia", value: elfValue, i: 0 })
                }
                dispatch({ type: "LIST_SOCIALMEDIA", payload: res.data, spinner: false })
            }).catch((err) => { console.log(err); });
    };
}
export function AC_HANDLE_SOCIALMEDIA_SEARCH(value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_SOCIALMEDIA_SEARCH", value: value })
    };
}
export function AC_LIST__SOCIALMEDIA_DRAG_DROP(formData) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.listSocialMediaDragDrop, formData)
            .then((res) => {
                dispatch({ type: "LIST_SOCIALMEDIA", payload: res.data, spinner: false })
            }).catch((err) => { console.log(err); });
    };
}
export function AC_VIEW_SOCIALMEDIA(id) {
    return function (dispatch) {
        return axios.get(ImportedURL.API.viewSocialMedia + "/" + id)
            .then((res) => {
                dispatch({ type: "VIEW_SOCIALMEDIA", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}

export function AC_UPDATE_SOCIALMEDIA(formData, id) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.updateSocialMedia + "/" + id, formData)
            .then((res) => {
                Success(res.statusText);
                dispatch({ type: "LIST_SOCIALMEDIA", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}

export function AC_DELETE_SOCIALMEDIA(id) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.deleteSocialMedia + "/" + id)
            .then((res) => {
                Success(res.statusText);
                dispatch({ type: "LIST_SOCIALMEDIA", payload: res.data })
            }).catch((err) => { console.log(err); });
    };
}

export function AC_RESET_SOCIALMEDIA() {
    return function (dispatch) {
        dispatch({ type: "RESET_SOCIALMEDIA" })
    };
}

export function AC_SPINNER_SOCIALMEDIA() {
    return function (dispatch) {
        dispatch({ type: "SPINNER_SOCIALMEDIA" })
    };
}

export function AC_MODAL_SPINNER_SOCIALMEDIA() {
    return function (dispatch) {
        dispatch({ type: "MODAL_SPINNER_SOCIALMEDIA" })
    };
}
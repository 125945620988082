
const initialState = {
    todo: {
        category: [],
        users: [],
        tags: [],
        tasklist: [],
    },
    todotags: {},
    count: {},
    listtodotags: [],
    todomylist: {},
    listtodomylist: [],
    todolistitems: [],
    pendinglist: [],
    todolist: [],
    modalspinner: false,
    spinner: false,
    total: 0,
    mytaskinprogress: [],
    mytaskcompleted: [],
    todolistreport: [],
    changepath: '/todo-my-task',
}

export default (state = initialState, action) => {
    switch (action.type) {

        // ===========================  Todo tags  =================================

        case 'HANDLE_TODO_TAGS_CHANGE':
            return Object.assign({}, state, {
                todotags: {
                    ...state.todotags,
                    [action.name]: action.value
                }
            })
        case 'LIST_TODO_TAGS':
            return Object.assign({}, state, {
                listtodotags: action.payload,
                total: action.total,
                spinner: action.spinner,
            })
        case 'ADD_UPDATE_TODO_TAGS':
            return Object.assign({}, state, {
                listtodotags: action.payload,
                total: action.total
            })
        case 'VIEW_TODO_TAGS':
            return Object.assign({}, state, {
                todotags: action.payload,
                modalspinner: action.spinner,
            })
        case 'EMPTY_TODO_TAGS':
            return Object.assign({}, state, {
                todotags: {}
            })
        case 'SPINNER_TODO_TAGS':
            return Object.assign({}, state, {
                spinner: true
            })
        case 'MODAL_SPINNER_TODO_TAGS':
            return Object.assign({}, state, {
                modalspinner: true
            })

        // ===========================  Todo tags  =================================

        case 'HANDLE_TODO_MY_LIST_CHANGE':
            return Object.assign({}, state, {
                todomylist: {
                    ...state.todomylist,
                    [action.name]: action.value
                }
            })
        case 'LIST_TODO_MY_LIST':
            return Object.assign({}, state, {
                listtodomylist: action.payload,
                total: action.total,
                spinner: action.spinner,
            })
        case 'ADD_UPDATE_TODO_MY_LIST':
            return Object.assign({}, state, {
                listtodomylist: action.payload,
                total: action.total
            })
        case 'VIEW_TODO_MY_LIST':
            return Object.assign({}, state, {
                todomylist: action.payload,
                modalspinner: action.spinner,
                spinner: action.spinner,
            })
        case 'EMPTY_TODO_MY_LIST':
            return Object.assign({}, state, {
                todomylist: {}
            })
        case 'SPINNER_TODO_MY_LIST':
            return Object.assign({}, state, {
                spinner: true
            })
        case 'MODAL_SPINNER_TODO_MY_LIST':
            return Object.assign({}, state, {
                modalspinner: true
            })

        // ===========================  Todo List  =================================

        case 'LIST_TO_DO_LIST_ITEMS':
            return Object.assign({}, state, {
                todolistitems: action.payload,
            })
        case 'HANDLE_TO_DO_LIST_CHANGE':
            return Object.assign({}, state, {
                todo: {
                    ...state.todo,
                    [action.name]: action.value
                }
            })
        case 'VIEW_TO_DO_LIST':
            return Object.assign({}, state, {
                todo: action.payload,
                spinner: action.spinner,
                modalspinner: action.spinner,
            })
        case 'EMPTY_TO_DO_LIST':
            return Object.assign({}, state, {
                todo: {
                    category: [],
                    users: [],
                    tags: [],
                    tasklist: [],
                },
            })
        case 'SPINNER_TO_DO_LIST':
            return Object.assign({}, state, {
                spinner: true
            })
        case 'MODAL_SPINNER_TO_DO_LIST':
            return Object.assign({}, state, {
                modalspinner: true
            })
        case 'HANDLE_CHANGE_TODO_BACK_TAB':
            return Object.assign({}, state, {
                changepath: action.value
            })
        case 'LIST_TO_DO_LIST':
            return Object.assign({}, state, {
                todolist: action.payload,
                spinner: action.spinner,
                modalspinner: action.spinner,
            })
        case 'LIST_PENDING_TO_DO_LIST':
            return Object.assign({}, state, {
                pendinglist: action.payload,
                spinner: action.spinner,
                modalspinner: action.spinner,
            })

        // ===========================  Todo MyTask  =================================
        case 'LIST_TODO_MY_TASK':
            return Object.assign({}, state, {
                mytaskinprogress: action.payload.inprogress,
                mytaskcompleted: action.payload.completed,
                spinner: false
            })
        case 'EMPTY_TODO_LISTITEMS':
            return Object.assign({}, state, {
                todolistitems: [],
            })

        // ===========================  Todo Report  =================================
        case 'LIST_TO_DO_LIST_REPORT':
            return Object.assign({}, state, {
                todolistreport: action.payload,
                count: action.count,
                spinner: false
            })
        default:
            return state;
    }
}
import axios from 'axios';
import ImportedURL from '../common/api';
import { ReplaceArrayAtIndex } from '../common/validate';


export function AC_LIST_DESIGNATION(params = {}) {
    return function (dispatch) {
        return axios.post(ImportedURL.API.listDesignation, params)
            .then((res) => {
                if (params.landing) {
                    let list = ReplaceArrayAtIndex(res.data.allData, 6);
                    dispatch({ type: "PUSH_DATA_LANDING", payload: list })
                }
                dispatch({ type: "LIST_DESIGNATIONS", payload: res.data.data, total: res.data.total, spinner: false })
            }).catch((err) => { console.log(err); });
    };
}
export function AC_VIEW_DESIGNATION(id) {
    return function (dispatch) {
        axios.get(ImportedURL.API.viewDesignation + "/" + id)
            .then(({ data }) => {
                dispatch({ type: 'VIEW_DESIGNATION', payload: data });
            });
    }
}

export function AC_RESET_DESIGNATION() {
    return function (dispatch) {
        dispatch({ type: "RESET_DESIGNATION" })
    };
}
export function AC_EMPTY_DESIGNATION() {
    return function (dispatch) {
        dispatch({ type: "EMPTY_DESIGNATION" })
    };
}

export function AC_HANDLE_DESIGNATION_CHANGE(name, value) {
    return function (dispatch) {
        dispatch({ type: "HANDLE_DESIGNATION_CHANGE", name: name, value: value })
    };
}




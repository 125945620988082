import React, { Component } from 'react'
import { connect } from 'react-redux';
import axios from 'axios';
import ImportedUrl from '../../common/api';
import { Success, Error } from '../../common/swal';
import { onErrorImage } from '../../common/validate';

class SiteSettings extends Component {

  changeHotel = (id) => {
    const { AccountState } = this.props;
    const languageData = AccountState.account.SelectedlanguageData
    const account = AccountState.account;
    if (account.hotel !== id) {
      axios.get(ImportedUrl.API.changeHotelLogin + '/' + id)
        .then((res) => {
          Success((languageData && languageData.HOTEL_PREFERENCE_CHANGED ? languageData.HOTEL_PREFERENCE_CHANGED : ' Hotel preference changed')(languageData && languageData.SUCCESSFULLY ? languageData.SUCCESSFULLY : "  successfully"));
          // Success(res.statusText);
          localStorage.setItem('acwtoken', res.data);
          window.location.href = "/";
        }).catch(({ response }) => {
          if (response) {
            if (response.status == 400) {
              Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
            }
            else if (response.status == 500) {
              Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
            } else if (response.status == 502) {
              Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
            } else {
              Error(response.statusMessage)
            }
            // Error(response.statusText)
          }
        });
    }

  }
  render() {
    const { AccountState } = this.props;
    const account = AccountState.account;
    return (
      <>
        <div className="card  ">
          <div className="card-status bg-green"></div>
          <div className="card-header">
            <h3 className="card-title">Default hotel <small>Choose a hotel to see the updates</small>
            </h3>
          </div>
          <div className="card-body todo_list">
            {account.allhotels && account.allhotels.map((item, i) => {
              return (
                <div className='log_add_check'>
                  <div className='logo'>
                    <div className='img_size'>
                      <img src={ImportedUrl.FILEURL + item.logo} alt='not found' onError={onErrorImage} />
                    </div>
                  </div>
                  <div className='address'>
                    <p className='mb-1 h_name'>{item.name}</p>
                    <p>{item.address}</p>
                  </div>
                  <div className='ch_box'>
                    <div className="form-check px-0">
                      <label className="form-check-label" for="tick">
                        <input type="checkbox" className="form-check-input" name={"example-checkbox1" + i} checked={account.hotel == item.hotelid} onChange={() => this.changeHotel(item.hotelid)} />
                      </label>
                    </div>
                  </div>
                </div>
              )
            })}
          </div>
        </div>
      </>
    )
  }
}
const mapStateToProps = state => ({
  fixNavbar: state.settings.isFixNavbar,
  AccountState: state.account,
})

const mapDispatchToProps = dispatch => ({})
export default connect(mapStateToProps, mapDispatchToProps)(SiteSettings);
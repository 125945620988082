const initialState = {
    landingdata: JSON.parse(localStorage.getItem('landingData')) || [],
    historydata: JSON.parse(localStorage.getItem('historyData')) || [],
    languagedata: JSON.parse(localStorage.getItem('languageData')) || [],
};

export default (state = initialState, action) => {
    switch (action.type) {
        case 'GET_LANDING_DATA':
            return Object.assign({}, state, {
                landingdata: action.payload,
            });
        case 'GET_LANGUAGE_DATA':
            return Object.assign({}, state, {
                languagedata: action.payload,
            });
        case 'GET_HISTORY_DATA':
            return Object.assign({}, state, {
                historydata: action.payload,
            });
        case 'PUSH_DATA_LANDING':
            return Object.assign({}, state, {
                landingdata: action.payload,
            });
        default:
            return state;
    }
};

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import Minutesofmeeting from './minutesofmeeting';
import { AC_HANDLE_TAB_CHANGE_REMINDER } from '../../actions/reminderAction';
import Ticketpriority from './ticketpriority';
import Remainder from './remainder';

const initialState = {
    isShowMulti: false,
    isShowIndividual: false
}
class ReminderDashoard extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    tapChange = (e) => {
        this.props.HandleChangeTab(e);
    }
    render() {
        const { ReminderState, AccountState } = this.props;
        const tabposition = ReminderState.tabposition;
        const languageData = AccountState.account.SelectedlanguageData;

        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/reminder"><h6 className="highlights_breadcrump">Reminder</h6></Link></p>
                    </div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="row clearfix">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-bell-o mr-2 "></i></div>
                                            <h3 className="card-title">Reminder</h3>
                                        </div>
                                        <div className='card-body dashboard_mom' style={{ paddingBottom: '40px' }}>
                                            <div className='upcommcomp_btn mb-3'>
                                                <div className='tb_size_reviewflow' style={{ position: 'relative' }}>
                                                    <button type='button' className={`${tabposition == 'reminder' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={(e) => this.tapChange("reminder")} >Reminder</button>
                                                </div>
                                                <div className='tb_size_reviewflow' style={{ position: 'relative' }}>
                                                    <button type='button' className={`${tabposition == 'ticketpriority' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={(e) => this.tapChange("ticketpriority")} >Ticket Priority</button>
                                                </div>
                                                {/* <div className='tb_size_reviewflow' style={{ position: 'relative' }}>
                                                    <button type='button' className={`${tabposition == 'mom' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={(e) => this.tapChange("mom")} >Meeting Minutes</button>
                                                </div> */}
                                            </div>
                                            {tabposition == 'reminder' ?
                                                <>
                                                    <Remainder />
                                                </> : ''}
                                            {
                                                tabposition == 'ticketpriority' ?
                                                    <>
                                                        <Ticketpriority />
                                                    </> : ''
                                            }
                                            {/* {
                                                tabposition == 'mom' ?
                                                    <>
                                                        <Minutesofmeeting />
                                                    </> : ''
                                            } */}
                                        </div>
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
            </>
        )
    }
}


const mapStateToProps = state => ({
    AccountState: state.account,
    ReminderState: state.reminder,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleChangeTab: AC_HANDLE_TAB_CHANGE_REMINDER,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ReminderDashoard);
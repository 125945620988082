import React, { Component } from 'react'
import ImportedURL from '../../common/api'
import { AC_CHECKBOX_CHANGE, AC_HANDLE_AMENITY_REMOVE } from '../../actions/settingRoomtypeAction';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { onErrorImage } from '../../common/validate';

class ViewAmenitycontent extends Component {

    render() {
        return (
            <div className='col-lg-3 col-md-3 roomtype-setting-amenity mt-2'>
                <div>
                    <img className="icon-stepform" src={ImportedURL.FILEURL + this.props.item1.amenity.icon} width="100px" alt='' onError={onErrorImage} />
                </div>
                <div style={{ textAlign: "center", fontSize: '14px', marginLeft: '12px' }}>
                    <span>{this.props.item1.amenity.amenity}</span>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    SettingRoomtypeState: state.settingroomtype,
    HotelState: state.hotel,
    AccountState: state.account,
    RoomtypeState: state.roomtype,
    RoomareaAmenityState: state.roomareaamenity,
});
function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        AC_CHECKBOX_CHANGE, AC_HANDLE_AMENITY_REMOVE
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(ViewAmenitycontent);

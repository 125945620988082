import ImportedURL from '../../common/api';
import { imageCellRendererList } from '../../common/validate';
export const newColumnDefs = [
    {
        headerName: 'Logo', field: 'hotellogo', width: 80, sortable: false, filter: false, "resizable": false, cellStyle: { 'text-align': 'center' }, hide: false,
        cellRenderer: imageCellRendererList
    },
    {
        headerName: 'Hotel', field: 'hotel', hide: false, suppressMenu: true,
        cellRenderer: function (params) {
            return params.value ? `<span>${params.value}</span>` : ''
        }
    },
    { headerName: 'Users Category', field: 'category' },
    {
        headerName: 'Name / Email', field: 'email', floatingFilter: true,
        valueGetter: function (params) {
            return `${params.data ? params.data.name : ''} ${params.data ? params.data.email : ''}`
        },
        cellRenderer: function (params) {
            var name = '';
            var email = '';
            if (params.data) {
                if (params.data.email) {
                    name = params.data ? params.data.name : '';
                    email = params.data ? params.data.email : '';
                }
                return `
            <span style="color:#1c3078; position:absolute;bottom:7px">`+ name + `</span> 
            <span style="position:absolute;top:11px ;cursor: pointer;" data-action-type="Copyemail"> <i class="fa fa-clone mr-2" data-action-type="Copyemail"></i>` + email + `</span>
            `
            }
        }
    },
    { headerName: 'Name', field: 'name', hide: true },
    { headerName: 'Email', field: 'emailcsv', hide: true },
    { headerName: 'Designation', field: 'designation', width: 100, hide: true },
    {
        headerName: 'Location Restrict', field: 'locationrestrict', width: 80, filter: false, cellStyle: { 'text-align': 'center' }, pinned: 'right',
        cellRenderer: function (params) {
            if (params.value) {
                return `<span type="button" class="tag tag-green" data-action-type="locationrestrict" data-toggle="modal" data-target="#locationRestrict">Enable</span>`;
            } else if (params.value == 0) {
                return '<span type="button"  class="tag tag-danger" data-action-type="locationrestrict" data-toggle="modal" data-target="#locationRestrict">Disable</span>';
            }
        }
    },
    {
        headerName: 'Phone #', field: 'phonenumber', hide: true, cellStyle: { 'text-align': 'center' },
        valueGetter: function (params) {
            var selectValue = ''
            if (params.data && params.data.phoneCode && params.data.phoneCode.length > 0) {
                params.data.phoneCode.map((item) => {
                    if (item.code == params.data.cc) {
                        selectValue = item.phonecode
                    }
                    if (item.phonecode == params.data.cc) {
                        selectValue = params.data.cc
                    }
                })
            }
            return selectValue + " " + params.data.phonenumber;
        }
    },
    {
        headerName: 'Privilege', width: 80, field: 'customprevilege', filter: false, cellStyle: { 'text-align': 'center' }, pinned: 'right',
        cellRenderer: function (params) {
            if (params.value) {
                return `<div class="user_cus_def"><span class="badge badge-warning" style="cursor: pointer;" data-action-type="previlege" data-toggle="modal" data-target="#previlege" style="padding:7px">Custom</span></div>`;
            } else if (params.value == 0) {
                return '<div class="user_cus_def"><span class="badge badge-default" style="padding:7px">Default</span></div>';
            }
        }
    },
    {
        headerName: 'PMS Privilege', width: 80, field: 'custompmsprivilege', filter: false, cellStyle: { 'text-align': 'center' }, pinned: 'right',
        cellRenderer: function (params) {
            if (params.value) {
                return `<div class="user_cus_def"><span class="badge badge-warning" style="cursor: pointer;" data-action-type="pmsprevilege" data-toggle="modal" data-target="#pmsprevilege" style="padding:7px">Custom</span></div>`;
            } else if (params.value == 0) {
                return '<div class="user_cus_def"><span class="badge badge-default" style="padding:7px">Default</span></div>';
            }
        }
    },
    {
        headerName: 'Status', width: 80, field: 'status', filter: false, cellStyle: { 'text-align': 'center' }, suppressMenu: true, pinned: 'right',
        cellRenderer: function (params) {
            if (params.data) {
                if ((!params.data.hotelstatus) || (params.data.hotelIsDeleted)) {
                    return '<span type="button" class="tag tag-default hotel_inactive" >Inactive</span>';
                }
                else if (params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status" data-toggle="modal" data-target="#statusChange">Active</span>`;
                }
                else if (!params.data.status) {
                    return '<span type="button" class="tag tag-danger" data-action-type="Status" data-toggle="modal" data-target="#statusChange">Inactive</span>';
                }
            }
        }
    },
    {
        headerName: 'Actions', width: 90, field: 'actions', cellStyle: { 'text-align': 'center' }, pinned: 'right', suppressMenu: true, sortable: false, filter: false,
        cellRenderer: function (params) {
            return params.value;
        }
    },

]

export const columnDefs = [
    { headerName: 'Users Category', field: 'category', width: 100 },
    {
        headerName: 'Name / Email', field: 'email', floatingFilter: true,
        valueGetter: function (params) {
            if (params.data) {
                return `${params.data.name} ${params.data.email}`
            }
        },
        cellRenderer: function (params) {
            var logo = '';
            var name = '';
            var email = '';
            if (params.data) {
                if (params.data.email) {
                    logo = params.data.logo ? ImportedURL.FILEURL + params.data.logo : '../assets/images/xs/avatar2.jpg';
                    name = params.data ? params.data.name : '';
                    email = params.data ? params.data.email : '';
                }

                return ImportedURL.SUPERADMIN.includes(params.data.role) ? `<span style="color:#1c3078 ;position:absolute;bottom:7px"">` + name + `</span> 
            <span style="position:absolute;top:11px ;cursor: pointer;" data-action-type="Copyemail"> <i class="fa fa-clone mr-2" data-action-type="Copyemail"></i>` + email + `</span>` : (`<img class="avatar user_profile" style="vertical-align: middle" src=${logo} alt='' width='65px' height='65px'/>`) + `<span style="color:#1c3078 ;position:absolute;bottom:7px"">` + name + `</span> 
            <span style="position:absolute;top:11px ;cursor: pointer;" data-action-type="Copyemail"> <i class="fa fa-clone mr-2" data-action-type="Copyemail"></i>` + email + `</span>
            `
            }
        }
    },
    { headerName: 'Name', field: 'name', hide: true },
    { headerName: 'Email', field: 'emailcsv', hide: true },
    { headerName: 'Designation', field: 'designation', width: 100, hide: true },
    {
        headerName: 'Location Restrict', field: 'locationrestrict', filter: false, width: 100, pinned: 'right',
        cellRenderer: function (params) {
            if (params.value) {
                return `<span type="button" class="tag tag-green" data-action-type="locationrestrict" data-toggle="modal" data-target="#locationRestrict">Enable</span>`;
            } else if (params.value == 0) {
                return '<span type="button"  class="tag tag-danger" data-action-type="locationrestrict" data-toggle="modal" data-target="#locationRestrict">Disable</span>';
            }
        }
    },
    {
        headerName: 'Privilege', field: 'customprevilege', filter: false, cellStyle: { 'text-align': 'center' }, width: 100, pinned: 'right',
        cellRenderer: function (params) {
            if (params.value) {
                return `<div class="user_cus_def"><span class="badge badge-warning" style="cursor: pointer;" data-action-type="previlege" data-toggle="modal" data-target="#previlege" style="padding:7px">Custom</span></div>`;
            } else if (params.value == 0) {
                return '<div class="user_cus_def"><span class="badge badge-default">Default</span></div>';
            }
        }
    },
    {
        headerName: 'PMS Privilege', width: 80, field: 'custompmsprivilege', filter: false, cellStyle: { 'text-align': 'center' }, pinned: 'right',
        cellRenderer: function (params) {
            if (params.value) {
                return `<div class="user_cus_def"><span class="badge badge-warning" style="cursor: pointer;" data-action-type="pmsprevilege" data-toggle="modal" data-target="#pmsprevilege" style="padding:7px">Custom</span></div>`;
            } else if (params.value == 0) {
                return '<div class="user_cus_def"><span class="badge badge-default" style="padding:7px">Default</span></div>';
            }
        }
    },
    {
        headerName: 'Phone #', field: 'phonenumber', hide: true, cellStyle: { 'text-align': 'center' },
        valueGetter: function (params) {
            var selectValue = ''
            if (params.data) {
                if (params.data.phoneCode && params.data.phoneCode.length > 0) {
                    params.data.phoneCode.map((item) => {
                        if (item.code == params.data.cc) {
                            selectValue = item.phonecode
                        }
                        if (item.phonecode == params.data.cc) {
                            selectValue = params.data.cc
                        }
                    })
                }
                return selectValue + " " + params.data.phonenumber;
            }
        }
    },
    {
        headerName: 'Status', field: 'status', filter: false, cellStyle: { 'text-align': 'center' }, suppressMenu: true, width: 100, pinned: 'right',
        cellRenderer: function (params) {
            if (params.data) {
                if ((!params.data.hotelstatus) || (params.data.hotelIsDeleted)) {
                    return '<span type="button" class="tag tag-default hotel_inactive" >Inactive</span>';
                }
                else if (params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status" data-toggle="modal" data-target="#statusChange">Active</span>`;
                }
                else if (!params.data.status) {
                    return '<span type="button" class="tag tag-danger" data-action-type="Status" data-toggle="modal" data-target="#statusChange">Inactive</span>';
                }
            }
        }
    },
    {
        headerName: 'Actions', field: 'actions', pinned: 'right', suppressMenu: true, sortable: false, filter: false, width: 120,
        // cellRenderer: actionCellRenderer
        cellRenderer: function (params) {
            return params.value;
        }
    },

]


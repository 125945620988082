
const initialState = {
	problemkeyword: {
		problemkeyword: [
		],
		hotel: '',
		type:'',
	},
	listproblemkeyword: [],
	spinner: true
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'HANDLE_PROBLEM_KEY_WORD_CHANGE':
			return Object.assign({}, state, {
				problemkeyword: {
					...state.problemkeyword,
					[action.name]: action.value
				}
			})
		case 'RESET_PROBLEM_KEY_WORD':
			return Object.assign({}, state, {
				problemkeyword: initialState.problemkeyword
			})
		case 'LIST_PROBLEM_KEY_WORD':
			return Object.assign({}, state, {
				listproblemkeyword: action.payload,
				spinner: false

			})
		case 'VIEW_PROBLEM_KEY_WORD':
			return Object.assign({}, state, {
				problemkeyword: action.payload,
				spinner: false,
			})
		case 'EMPTY_PROBLEM_KEY_WORD':
			return Object.assign({}, state, {
				problemkeyword: {

					problemkeyword: [{
						name: '',
					}],
					hotel:'',
					type:''
				},
				spinner:true
			})
		case 'EMPTY_ARRAY_PROBLEM_KEY_WORD':
			return Object.assign({}, state, {
				problemkeyword: {

					problemkeyword: [{
						name: '',
					}],
					hotel:'',
					type:''

				},
				listproblemkeyword: [],
			})

		case 'HANDLE_AMENITY_CHANGE':
			return {
				...state,
				problemkeyword: {
					...state.problemkeyword,
					problemkeyword:
						[
							...state.problemkeyword.problemkeyword.slice(0, action.i),
							{ ...state.problemkeyword.problemkeyword[action.i], [action.name]: action.value },
							...state.problemkeyword.problemkeyword.slice(action.i + 1)
						]
				}
			}

		case 'HANDLE_PROBLEM_KEY_WORD_PLUS':
			if (action.id === '') {
				return {
					...state,
					problemkeyword: {
						...state.problemkeyword,
						problemkeyword: [...state.problemkeyword.problemkeyword, action.value]
					}
				}
			}
			else if (action.name === true) {
				return {
					...state,
					problemkeyword: {
						...state.problemkeyword,
						problemkeyword: [action.value]
					}
				}
			}
			else {
				return {
					...state,
					problemkeyword: {
						...state.problemkeyword,
						problemkeyword: [...state.problemkeyword.problemkeyword.filter((event, i) => i !== action.id)]
					}
				}
			}
		default:
			return state;
	}
}
import React, { Component } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
    TimePicker,
    MuiPickersUtilsProvider,
} from 'material-ui-pickers';

class TimeComponent extends Component {
    render() {
        const { value, onChange, showError, error, validateError, name, label } = this.props;
        return (
            <MuiPickersUtilsProvider utils={DateFnsUtils} style={{ position: 'relative' }}>
                <TimePicker value={value} helperText={null} className="time-style" name={name} onChange={onChange} />
                <i className="fa fa-clock-o" style={{ cursor: 'pointer', fontSize: '20px', opacity: '0.7', position: 'absolute', top: "37px", right: "20px" }}></i>
                {
                    showError ?
                        <>
                            <div className="invalid-feedback" style={{ display: validateError ? 'block' : 'none' }}>Choose a valid time</div>
                            <div className="invalid-feedback" style={{ display: error ? 'block' : 'none' }}>{label + " is required"}</div>
                        </> : ''
                }
            </MuiPickersUtilsProvider>
        );
    }
}

export default TimeComponent;
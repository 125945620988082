
const initialState = {
    listtodolistitem: [],
    total: "",
    viewtodolistitem: {
        hotel: '',
        itemvalue: [{
            name: '',
            togglestatus: true,
        }],
        duration: '',
        itemparentid: '',
        starttime: '',
        endtime: '',
        category: []
    },
    spinner: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_TODO_LIST_CHANGE':
            return Object.assign({}, state, {
                viewtodolistitem: {
                    ...state.viewtodolistitem,
                    [action.name]: action.value
                }
            })
        case 'HANDLE_TODO_LIST_ITEM_CHANGE':
            return {
                ...state,
                viewtodolistitem: {
                    ...state.viewtodolistitem,
                    itemvalue:
                        [
                            ...state.viewtodolistitem.itemvalue.slice(0, action.i),
                            { ...state.viewtodolistitem.itemvalue[action.i], [action.name]: action.value },
                            ...state.viewtodolistitem.itemvalue.slice(action.i + 1)
                        ]
                }
            }
        case 'HANDLE_TODO_LIST_ITEM_UPDATE':
            if (action.id === '') {
                return {
                    ...state,
                    viewtodolistitem: {
                        ...state.viewtodolistitem,
                        itemvalue: [...state.viewtodolistitem.itemvalue, action.value]
                    }
                }
            }
            else if (action.name === true) {
                return {
                    ...state,
                    viewtodolistitem: {
                        ...state.viewtodolistitem,
                        itemvalue: [action.value]
                    }
                }
            }
            else {
                return {
                    ...state,
                    viewtodolistitem: {
                        ...state.viewtodolistitem,
                        itemvalue: [...state.viewtodolistitem.itemvalue.filter((event, i) => i !== action.id)]
                    }
                }
            }
        case 'EMPTY_LIST_TODO_ITEM':
            return Object.assign({}, state, {
                viewtodolistitem: {
                    hotel: '',
                    itemvalue: [{
                        name: '',
                        togglestatus: true,
                    }],
                    duration: '',
                    itemparentid: '',
                    starttime: '',
                    endtime: '',
                    category: []
                },
                spinner: true,
            })
        case 'EMPTY_TODO_LIST_ITEM_INSPECTION':
            return Object.assign({}, state, {
                viewtodolistitem: {
                    hotel: '',
                    itemvalue: [{
                        name: '',
                        togglestatus: true,
                    }],
                    duration: '',
                    itemparentid: '',
                    starttime: '',
                    endtime: '',
                },
                listtodolistitem: [],

            })
        case 'LIST_TODO_LIST_ITEM':
            return Object.assign({}, state, {
                listtodolistitem: action.payload,
                spinner: false,
            })
        case 'VIEW_TODO_LIST_ITEM':
            return Object.assign({}, state, {
                viewtodolistitem: action.payload,
                spinner: false,
            })
        default:
            return state;
    }
}
import React, { Component } from 'react'
import axios from 'axios';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ImportedURL from '../../common/api';
import { Success, Error } from '../../common/swal';
import { AC_HANDLE_INPUT_CHANGE, AC_ADMIN_UPDATE, AC_ACCOUNT_DETAILS } from '../../actions/accountAction';
import { AC_LIST_COUNTRY } from '../../actions/countryAction';
import Select from 'react-select';
import { Form, Row } from 'react-bootstrap';
import Sitesettings from './sitesettings';
import { AC_LIST_USER } from '../../actions/userAction';
import { CapitalizeFirstLetter, GetManagementUserStatus, Imagevalidation, onErrorImage } from '../../common/validate';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import moment, { relativeTimeThreshold } from 'moment';
import { AC_LIST_CATEGORY } from '../../actions/categoryAction';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import { AC_HANDLE_LANGUAGE_CHANGE, AC_LIST_LANGUAGE, AC_RESET_LANGUAGE, AC_VIEW_LANGUAGE, AC_EMPTY_LANGUAGE, AC_HANDLE_LANGUAGE_TABPOSITION_CHANGE } from '../../actions/languageAction';
import showSwalDialog from '../../utilities/Modal/swal';
import Spinner from 'react-bootstrap/esm/Spinner';

const initialState = {
    name: '',
    nameError: false,
    logoSrc: '',
    logo: '',
    modalType: '',
    preview: false,
    saving: false,
    reviewUserSaving: false,
    reviewSaving: false,
    buttonView: true,
    radioValue: '',
    localimage: false,
    defaultticketworkflowError: [],
    allOption0: true,
    allOption1: true,
    allOption2: true,
    allOption3: true,
    allOption4: true,
    allOption5: true,
    allOption: true,
    spinner: false
}
class AdminSettings extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
        this.hotelInputRef = React.createRef();
    }
    componentDidMount() {
        const account = this.props.AccountState.account;
        if (ImportedURL.SUPERADMIN.includes(account.role)) {
            this.props.HandleInputChange('adminreviewsurlexpdays', "");
        }
        this.props.ListHotel();
        this.props.ListCountry();
        this.props.ListUser();
        this.props.ListCategory();
        this.props.ListLanguage({ languagestatus: 'Website' });
        this.props.AccountDetails();
        const href = window.location.href.split('/')[3];
        if (href.includes('#form')) {
            let formEnquiry = document.getElementById("review-form-data");
            if (formEnquiry) {
                formEnquiry.scrollIntoView()
            }
        }
    }

    onChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.HandleInputChange(name, value)
        if (name == 'name') {
            this.props.HandleInputChange('namechange', true);
        }
    }
    onChangeImage = e => {
        const { name, value } = e.target;
        if (name == 'logo') {
            this.props.HandleInputChange('imagechange', true);
            const imgvalidate = Imagevalidation(e.target.files[0]);
            if (imgvalidate) {
                this.setState({ logo: e.target.files[0] });
                var reader = new FileReader();
                var url = reader.readAsDataURL(e.target.files[0]);
                reader.onloadend = function (e) {
                    this.setState({
                        logoSrc: [reader.result],
                    })
                }.bind(this);
                this.props.HandleInputChange(name, e.target.files[0]);
            } else {
                Error('Invalid file extension');
            }
        }
    }
    handlePhoneCode = e => {
        const { currencycode, currencyname, currencysymbol, symbolnative } = e;
        this.props.HandleInputChange('phonecode', e.value);
        this.props.HandleInputChange('countrychange', true);
        this.props.HandleInputChange('currencycode', currencycode);
        this.props.HandleInputChange('currencyname', currencyname);
        this.props.HandleInputChange('currencysymbol', currencysymbol);
    }

    handleLanguage = e => {
        const { value } = e;
        this.props.HandleInputChange('deflanghotel', value);
    }

    handleDateChange = (e) => {
        const { name, value } = e.target;
        this.setState({ radioValue: value })
        this.props.HandleInputChange(name, value)
    }
    handleTimeChange = (e) => {
        const { name, value } = e.target;
        this.props.HandleInputChange(name, value)
    }
    SelectHandleChange = e => {
        const { name, value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.HandleInputChange(name, value);
    }
    radioHandleChangeReport = e => {
        const { name, value, id } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false, reportuserError: false });
        this.props.HandleInputChange(name, value);
        this.props.HandleInputChange('assignuser', '');
    }
    SelectHandleChangeUser = e => {
        const { name, value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.HandleInputChange(name, value);
    }

    // -------------User details-----start--------
    submit = (e) => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const data = AccountState.account;
        if (data.role == "vendor") {
            for (let key in data) {
                if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                    data[key] = data[key].trim();
                }
            }
            let valid = 1;
            if (!data.vendorname) {
                this.setState({ vendornameError: true });
                valid = 0;
            }
            var nameformData = ''
            var formData = new FormData();
            if (this.state.localimage) {
                formData.append("logo", data.logo)
                formData.append("image", true)
                formData.append("vendorname", data.vendorname)
            } else {
                nameformData = {
                    vendorname: data.vendorname
                }
            }
            if (valid) {
                this.setState({ saving: true })
                axios.post(ImportedURL.API.updateVendorProfile + '/' + data._id, this.state.localimage ? formData : nameformData)
                    .then((res) => {
                        this.setState({ saving: false })
                        Success((languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " updated successfully"));
                        this.props.AccountDetails();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        this.ErrorResponse(response);
                    });
            }
        } else {
            let valid = 1;
            if (!data.name) {
                this.setState({ nameError: true });
                valid = 0;
            }
            if (!data.imagechange) {
                data['imagechange'] = false;
            }
            if (!data.countrychange) {
                data['countrychange'] = false;
            }
            if (!data.namechange) {
                data['namechange'] = false;
            }
            if (valid) {
                this.setState({ buttonView: false })
                const formData = new FormData();
                formData.append('live', ImportedURL.LIVE)
                for (let key in data) {
                    if (key === 'defaultticketworkflow') continue;
                    else formData.append(key, data[key])
                }
                if (data.userCount && data.userCount > 1) {
                    showSwalDialog('Do you want to change in all your account ?', '', 'Yes', 'Cancel', ImportedURL.LOCALIMAGEURL + 'assets/images/status.png', "No").then((result) => {
                        if (result.isConfirmed) {
                            this.setState({ saving: true })
                            formData.append('multiplechange', true)
                            data["updatemultipleaccount"] = true
                            this.UpadteInSettings(formData, data);
                        } else if (result.isDenied) {
                            this.setState({ saving: true })
                            formData.append('multiplechange', false)
                            data["updatemultipleaccount"] = false
                            this.UpadteInSettings(formData, data);
                        }
                    })
                } else {
                    this.setState({ saving: true })
                    formData.append('multiplechange', false)
                    this.UpadteInSettings(formData, data);
                }
            }
        }
    }

    UpadteInSettings = (formData, data) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        axios.post(ImportedURL.API.changeAdmin, formData)
            .then((res) => {
                this.setState({ saving: false })
                this.setState({ buttonView: true })
                this.props.AC_ADMIN_UPDATE(res.data)
                this.props.HandleInputChange('imagechange', false);
                this.props.HandleInputChange('countrychange', false);
                this.props.HandleInputChange('namechange', false);
                Success((languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " updated successfully"));
            }).catch(({ response }) => {
                this.ErrorResponse(response);
                this.setState({ buttonView: true })
                this.setState({ saving: false })
            });
    }
    // -------------User details-----end--------

    // -------------ticket workflow-----start--------
    submitTicketworkflow = (e) => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const data = AccountState.account;
        let valid = 1;
        if (!ImportedURL.SUPERADMIN.includes(data.role)) {
            if (data.defaultticketworkflow && data.defaultticketworkflow.length > 0) {
                let indexArr = []
                data.defaultticketworkflow.map((item, i) => {
                    if (item.array && item.array.length == 0) indexArr.push(i)
                })
                if (indexArr.length !== 0) valid = 0;
                this.setState({ defaultticketworkflowError: indexArr })
            }
        }
        if (valid) {
            this.setState({ ticketSaving: true })
            axios.post(ImportedURL.API.updateDefaultTicketWorkFlow, data)
                .then((res) => {
                    this.setState({ ticketSaving: false })
                    Success("Default Ticket Workflow updated successfully");
                }).catch(({ response }) => {
                    this.setState({ ticketSaving: false })
                });
        }
    }
    // -------------ticket workflow------end--------

    // -------------review-----start--------
    submitReview = () => {
        const { AccountState } = this.props;
        const data = AccountState.account;
        let valid = 1;
        if (!data.selecthotel) {
            this.setState({ selecthotelError: true });
            valid = 0;
        }
        if (!data.adminreviewsurlexpdays) {
            this.setState({ adminreviewsurlexpdaysError: true });
            valid = 0;
        }
        if (valid) {
            if (data.defaultreviewexpstatus != undefined && data.defaultreviewexpstatus) {
                showSwalDialog('You wish to overwrite what the owner set?', '', 'Yes', 'No', 'assets/images/status.png').then((result) => {
                    if (result.isConfirmed) {
                        this.updateReviewForm(data);
                    }
                })
            } else {
                this.updateReviewForm(data);
            }
        }
    }
    updateReviewForm = (data) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        this.setState({ reviewSaving: true })
        axios.post(ImportedURL.API.updateReviewExpDaysByHotel, { hotel: data.selecthotel, reviewsurlexpdays: data.adminreviewsurlexpdays })
            .then((res) => {
                this.props.ListHotel();
                this.props.HandleInputChange('selecthotel', "");
                this.props.HandleInputChange('adminreviewsurlexpdays', "");
                this.props.HandleInputChange('defaultreviewexpstatus', false);
                this.setState({ reviewSaving: false })
                Success("Review Expire Days updated successfully");
            }).catch(({ response }) => {
                this.setState({ reviewSaving: false })
                this.ErrorResponse(response);
            });
    }
    submitReviewFormUser = () => {
        const { AccountState } = this.props;
        const data = AccountState.account;
        let valid = 1;
        if (!data.reviewsurlexpdays) {
            this.setState({ reviewsurlexpdaysError: true });
            valid = 0;
        }
        if (valid) {
            if (data.userCount && data.userCount > 1) {
                showSwalDialog('Do you want to change in all your account ?', '', 'Yes', 'Cancel', ImportedURL.LOCALIMAGEURL + 'assets/images/status.png', "No").then((result) => {
                    if (result.isConfirmed) {
                        this.setState({ reviewUserSaving: true })
                        data["updatemultipleaccount"] = true
                        this.updateReviewFormUser(data);
                    } else if (result.isDenied) {
                        this.setState({ reviewUserSaving: true })
                        data["updatemultipleaccount"] = false
                        this.updateReviewFormUser(data);
                    }
                })
            } else {
                this.setState({ reviewUserSaving: true })
                data["updatemultipleaccount"] = false
                this.updateReviewFormUser(data);
            }
        }
    }
    updateReviewFormUser = (data) => {
        this.setState({ reviewUserSaving: true })
        axios.post(ImportedURL.API.updateReviewExpDaysByHotel, { updatemultipleaccount: data.updatemultipleaccount, allhotels: data.allhotels, hotel: data.hotel, reviewsurlexpdays: data.reviewsurlexpdays, defaultreviewexpstatus: true })
            .then((res) => {
                this.setState({ reviewUserSaving: false })
                Success("Review Expire Days updated successfully");
            }).catch(({ response }) => {
                this.setState({ reviewUserSaving: false })
                this.ErrorResponse(response);
            });
    }
    // -------------review-----end--------

    ErrorResponse = (response) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (response) {
            if (response.status == 500) {
                Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
            } else if (response.status == 400) {
                Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
            } else if (response.status == 502) {
                Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
            } else if (response.status == 409) {
                Error((languageData && languageData.VENDOR ? languageData.VENDOR : ' Vendor') + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : 'already exist'))
            } else if (response.status == 406) {
                Error((languageData && languageData.EMAIL ? languageData.EMAIL : ' Email') + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : 'already exist'))
            } else {
                Error(response.statusMessage)
            }
        } else {
            Error("Bad request")
        }
    }

    onChangeVendor = e => {
        const { name, value, checked } = e.target;
        const Error1 = name + "Error";
        this.setState({ [Error1]: false });
        if (name == 'reviewsurlexpdays' || name == 'adminreviewsurlexpdays') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var s3 = s2.slice(0, 10);
            var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
            if (val && Number(val) > 0 && Number(val) <= 365) {
                this.props.HandleInputChange(name, Number(val))
            } else {
                this.props.HandleInputChange(name, '')
                if (val) Error('Days should less then 365');
            }
        } else if (name == 'showpositivefeedback') {
            this.props.HandleInputChange(name, checked)
        } else {
            this.props.HandleInputChange(name, value)
        }
    }

    onChangeVendorImage = e => {
        const { name, value } = e.target;
        if (name == 'logo') {
            const imgvalidate = Imagevalidation(e.target.files[0]);
            if (imgvalidate) {
                this.setState({ logo: e.target.files[0], localimage: true });
                var reader = new FileReader();
                var url = reader.readAsDataURL(e.target.files[0]);
                reader.onloadend = function (e) {
                    this.setState({
                        logoSrc: [reader.result],
                    })
                }.bind(this);
                this.props.HandleInputChange(name, e.target.files[0]);
            } else {
                Error('Invalid file extension');
            }
        }
    }
    onChangeSelectMultiple = (e, i) => {
        const { AccountState, CategoryState } = this.props;
        const data = AccountState.account;
        let defaultticketworkflow = data.defaultticketworkflow ? [...data.defaultticketworkflow] : []
        const listCategory = CategoryState.listcategories;
        let allData = false
        let multivalueArray = [];
        const dynamicKey = `allOption${i}`;
        if (e && e.length > 0) {
            e && e.map((data) => {
                if (data.value == "All") {
                    allData = true
                    this.setState({ [dynamicKey]: false })
                } else {
                    allData = false
                    multivalueArray.push(data.value)
                    this.setState({ [dynamicKey]: true })
                }
            })
        } else {
            this.setState({ [dynamicKey]: true })
        }
        if (listCategory.length == e.length) this.setState({ [dynamicKey]: false })
        let dataArray = []
        if (allData) {
            listCategory.filter(filterItem => filterItem.status === true).map(item => {
                dataArray.push(item._id)
            })
        }
        let indexToRemove = dataArray && dataArray.length > 0 && dataArray.indexOf(data.individualcategory)
        if (indexToRemove !== -1) {
            dataArray.splice(indexToRemove, 1)
        }
        defaultticketworkflow = [
            ...defaultticketworkflow.slice(0, i),
            { ...defaultticketworkflow[i], ['array']: allData ? dataArray : multivalueArray },
            ...defaultticketworkflow.slice(i + 1)
        ]
        this.props.HandleInputChange('defaultticketworkflow', defaultticketworkflow)
        let arr = this.state.defaultticketworkflowError.filter((index) => index !== i)
        this.setState({ defaultticketworkflowError: arr })
    }
    searchUser = e => {
        const { name, value, id } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.HandleInputChange(name, value);
        if (name == 'selecthotel') {
            this.props.HandleInputChange('adminshowpositivefeedback', id.showpositivefeedback ? id.showpositivefeedback : false);
            this.props.HandleInputChange('adminreviewsurlexpdays', id.reviewsurlexpdays ? id.reviewsurlexpdays : '');
            if (id.reviewsurlexpdays) {
                this.setState({ adminreviewsurlexpdaysError: false })
            }
            this.props.HandleInputChange('defaultreviewexpstatus', id.defaultreviewexpstatus ? id.defaultreviewexpstatus : false);
        }
    }
    defaultticketworkflowError = (index) => {
        const { defaultticketworkflowError } = this.state
        let error = 0
        if (defaultticketworkflowError && defaultticketworkflowError.length > 0) {
            if (defaultticketworkflowError.includes(index)) {
                error = 1
            }
        }
        return error
    }
    selectedticketworkflowError = (index) => {
        const { defaultticketworkflowError } = this.state
        let error = 0
        if (defaultticketworkflowError && defaultticketworkflowError.length > 0) {
            if (defaultticketworkflowError.includes(index)) {
                error = 1
            }
        }
        return error
    }


    // -----------------------------------------------default management
    radioChange = (e) => {
        const data = this.props.AccountState.account.defaultmanagementcategory;
        const { name, value, checked, id } = e.target;
        this.setState({ typeError: false });
        if (id == 'multiple') {
            if (value == 'multiple' && checked) {
                let updatedObject = {
                    ...data,
                    ["type"]: [...data.type, value]
                };
                this.props.HandleInputChange('defaultmanagementcategory', updatedObject);
                this.setState({ isShowMultiCat: true })
            } else {
                const index = data.type.indexOf(value)
                if (data && data.type.includes(value)) {
                    data.type.splice(index, 1)
                }
                let updatedObject = {
                    ...data,
                    ["type"]: data.type,
                    ["multiplecategory"]: [],
                };
                this.props.HandleInputChange('defaultmanagementcategory', updatedObject);
                this.setState({ isShowMultiCat: false, multiplecategoryError: false })
            }
        }

        if (id == 'individual') {
            if (value == 'individual' && checked) {
                let updatedObject = {
                    ...data,
                    ["type"]: [...data.type, value]
                };
                this.props.HandleInputChange('defaultmanagementcategory', updatedObject);
                this.setState({ isShowMultiUser: true, isShowMultiCat: false })
            } else {
                const index = data.type.indexOf(value)
                if (data && data.type.includes(value)) {
                    data.type.splice(index, 1)
                }
                let updatedObject = {
                    ...data,
                    ["type"]: data.type,
                    ["individualcategory"]: '',
                    ["individualuser"]: [],
                };
                this.props.HandleInputChange('defaultmanagementcategory', updatedObject);
                this.setState({ isShowMultiUser: false, })
                this.setState({ individualcategoryError: false, ticketuserError: false })
            }
        }

    }
    handleChangeSelectMultiCreate = (e) => {
        const listCategory = this.props.CategoryState.listcategories;
        const data = this.props.AccountState.account.defaultmanagementcategory;
        let allData = false
        let multivalueArray = [];
        if (e && e.length > 0) {
            e && e.map((data) => {
                if (data.value == "All") {
                    allData = true
                    this.setState({ allOption: false })
                } else {
                    allData = false
                    multivalueArray.push(data.value)
                    this.setState({ allOption: true })
                }
            })
        } else {
            this.setState({ allOption: true })
        }
        if (listCategory.length == e.length) {
            this.setState({ allOption: false })
        }
        let dataArray = []
        if (allData) {
            listCategory.filter(filterItem => filterItem.status === true).map(item => {
                dataArray.push(item._id)
            })
        }
        let indexToRemove = dataArray && dataArray.length > 0 && dataArray.indexOf(data.individualcategory)
        if (indexToRemove !== -1) {
            dataArray.splice(indexToRemove, 1)
        }

        this.setState({ multiplecategoryError: false });
        let updatedObject = {
            ...data,
            ["multiplecategory"]: allData ? dataArray : multivalueArray
        };
        this.props.HandleInputChange('defaultmanagementcategory', updatedObject);
    };
    handleChangeSelectIndividualCreate = (e) => {
        const listUsers = this.props.UserState.listusers;
        const data = this.props.AccountState.account.defaultmanagementcategory;
        const { name, lable, value } = e;
        this.setState({ individualcategoryError: false });
        let updatedObject = {
            ...data,
            ["individualcategory"]: value,
            ["individualuser"]: [],
        };
        this.props.HandleInputChange('defaultmanagementcategory', updatedObject);
        let status = listUsers.find((e) => e.categoryid == value)

        if (status) {
            this.setState({ allOptionUser: true })
        } else {
            this.setState({ allOptionUser: false })
        }
    }
    handleChangeSelectUser = (e) => {
        const data = this.props.AccountState.account.defaultmanagementcategory;
        const listUsers = this.props.UserState.listusers;
        let findAll = e.find((item) => item.value == "All")
        let pushedArr = []
        if (findAll) {
            this.setState({ allOptionUser: false })
            let category = (data.individualuser && data.individualuser.length > 0) ? [...data.individualuser] : []
            let allData = []
            listUsers.map((e) => {
                if (e.categoryid == data.individualcategory) {
                    allData.push(e._id)
                }
            })
            let conact = allData.concat(category)
            pushedArr = [...new Set(conact)]
        } else {
            this.setState({ allOptionUser: true })
            if (e && e.length > 0) {
                e.map((item) => {
                    const { value } = item;
                    pushedArr.push(value)
                })
            }
        }
        let count = 0
        listUsers.map((e) => {
            if (e.categoryid == data.individualcategory) {
                count++
            }
        })
        if (count == pushedArr.length) this.setState({ allOptionUser: false })
        let updatedObject = {
            ...data,
            ["individualuser"]: pushedArr,
        };
        this.props.HandleInputChange('defaultmanagementcategory', updatedObject);
        this.setState({ individualuserError: false });
    }
    defaultManagementSubmit = (e) => {
        this.setState({ spinner: false })
        const { TicketworkflowState, AccountState } = this.props;
        const data = this.props.AccountState.account.defaultmanagementcategory;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        let valid = 1

        if (data.type.includes("multiple")) {
            if (data.multiplecategory.length == 0) {
                this.setState({ multiplecategoryError: true });
                valid = 0;
            }
        }
        if (valid) {
            this.setState({ defaultSaving: true, spinner: true })
            axios.post(ImportedURL.API.updateDefaultManagement, data)
                .then((res) => {
                    this.props.AccountDetails();
                    this.setState({ defaultSaving: false, spinner: false })
                    Success((languageData && languageData.DEFAULT_MANAGEMENT ? languageData.DEFAULT_MANAGEMENT : "Default Management") + " updated successfully");
                }).catch(({ response }) => {
                    this.setState({ defaultSaving: false, spinner: false })
                });
        }
    }
    render() {
        const { AccountState, CountryState, UserState, CategoryState, LanguageState, HotelState } = this.props;
        const dataUser = UserState?.listusers;
        const data = AccountState.account;
        const account = AccountState.account;
        const languageData = AccountState.account.SelectedlanguageData
        const phoneCode = CountryState.listcountry;
        let ccData = '';
        const listLanguage = LanguageState.listLanguage;
        let deflang = '';
        if (phoneCode) {
            phoneCode.map(item => {
                if (item.code === data.phonecode) {
                    ccData = { label: item.name, value: item.code, flag: item.flag }
                }
                if (item.phonecode === data.phonecode) {
                    ccData = { label: item.name, value: item.phoneCode, flag: item.flag }
                }
            })
        }

        let LanguageList = [];
        for (let i = 0; i < listLanguage.length; i++) {
            let item = listLanguage[i]
            if (item.code === account.deflanghotel) {
                deflang = { label: item.name, value: item.code }
            }
            if (item.status == true)
                LanguageList.push({
                    label: item.name,
                    value: item.code,
                })
        }
        var selectData = '';
        if (data.email != "info@myhotelai.com") {
            selectData = dataUser && dataUser.find(e => e.email == data.email)
        }
        var ccDataSelect = ''
        if (selectData?.cc || account.role == "vendor") {
            phoneCode.map(item => {
                if (account.role == "vendor") {
                    if (item.code == account.vendorPhoneCode) {
                        ccDataSelect = `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + `<span class="code_align">` + item.phonecode + `</span>`
                    }
                } else {
                    if (item.phonecode == selectData.cc) {
                        ccDataSelect = `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + `<span class="code_align">` + item.phonecode + `</span>`
                    }
                    if (item.code == selectData.cc) {
                        ccDataSelect = `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + `<span class="code_align">` + item.phonecode + `</span>`
                    }
                }
            })
        }
        var tzone = 'America/chicago'
        if (account != undefined && account && account.tzone != undefined && account.tzone) {
            tzone = account.tzone
            // Date Format 
            var date = new Date();
            var dateFormat0 = moment(date).tz(tzone).format('MM-DD-YYYY');
            var dateFormat1 = moment(date).tz(tzone).format('YYYY-MM-DD');
            var dateFormat2 = moment(date).tz(tzone).format('MM-YYYY-DD');
            var dateFormat3 = moment(date).tz(tzone).format('DD-MM-YYYY');

            // Time Format 
            var timeFormat0 = moment(date).tz(tzone).format('h:mm A');
            var timeFormat1 = moment(date).tz(tzone).format('h:mm a');
            var timeFormat2 = moment(date).tz(tzone).format('hh:mm A');
            var timeFormat3 = moment(date).tz(tzone).format('hh:mm a');
        }


        const CategoryData = CategoryState.listcategories;
        const CategoryArray = [];
        // if (data.hotel) {
        CategoryData.filter(filterItem => filterItem.status === true).map((item, i) => {
            CategoryArray.push(
                item
            )
        })
        // }
        var selectCategoryData = '';
        if (data.usercategory !== undefined) {
            selectCategoryData = CategoryArray.find(e => e._id === data.usercategory)
        }

        const UserArray = [];
        const UserData = UserState.listusers;
        if (data.usercategory) {
            UserData && UserData.filter(filterItem => filterItem.status === true).map((item, i) => {
                if (data.hotel == item.hotelid) {
                    if (data.usercategory == item.categoryid) {
                        UserArray.push(
                            item
                        )
                    }
                }
            })
        }
        var selectUserData = '';
        if (data.assignuser !== undefined && data.assignuser) {
            selectUserData = UserArray.find(e => e._id === data.assignuser)
        }
        const listHotels = HotelState.listhotels;
        var selectedHotel = '';
        const hotelOptions = [];
        if (listHotels && listHotels.length > 0) {
            listHotels.filter(e => e.status === true).map((item) => {
                if (data.selecthotel == item._id) {
                    selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                }
                hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'selecthotel', id: item });
            })
        }

        // -----------------------------------------------default management
        const temp = AccountState.account.defaultmanagementcategory;
        const categoryOption = [];
        const selectedCategoryArray = [];
        CategoryData.filter(filterItem => filterItem.status === true).map(item => {
            if (temp.multiplecategory != undefined && temp.multiplecategory && temp.multiplecategory.length > 0) {
                let find = temp.multiplecategory.some((e) => (typeof e === 'string' ? e : e._id) == item._id)
                if (find) {
                    selectedCategoryArray.push({ label: item.name, value: item._id, name: 'multicategory' });
                }
            }
            categoryOption.push({ label: item.name, value: item._id, name: 'multicategory' });
        })

        let indexToRemove = categoryOption && categoryOption.length > 0 && categoryOption.findIndex(obj => obj.value == temp.individualcategory)
        if (indexToRemove !== -1) {
            categoryOption.splice(indexToRemove, 1)
        }
        if (selectedCategoryArray.length != categoryOption.length && this.state.allOption) {
            categoryOption.unshift({ label: "All", value: 'All', name: 'multicategory' })
        }

        // individual
        let individualselectedCategoryArray = '';
        if (temp.individualcategory != undefined && temp.individualcategory) {
            individualselectedCategoryArray = CategoryData.find(e => e._id == temp.individualcategory)
        }

        const nonCommonElements = CategoryData && CategoryData.filter(element1 => element1.status === true &&
            !selectedCategoryArray.some(element2 => element2.value === element1._id))

        const individualcategoryOption = [];
        nonCommonElements.map(item => {
            individualcategoryOption.push({ label: item.name, value: item._id, name: 'individualcategory' });
        })

        // user multiple 
        const userArrayOption = [];
        const selectedUserArray = [];
        UserData.filter(e => e.status == true && e.ticketprevileges == true).map(item => {
            if (temp.individualuser != undefined && temp.individualuser && temp.individualuser.length > 0) {
                let status = temp.individualuser.find(e => e == item._id);
                if (status) {
                    selectedUserArray.push({ label: item.name, value: item._id, name: 'individualuser' });
                }
            }
            if (item.categoryid == temp.individualcategory) {
                userArrayOption.push({ label: item.name, value: item._id, name: 'individualuser' });
            }
        })
        const managementStatus = GetManagementUserStatus(account.defaultmanagementcategory, account.userId, account.categoryType, null);
        return (
            <>
                <div className="breadcrump">
                    <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/site-setting"><h6 className="highlights_breadcrump">Site Settings</h6></Link></p>
                </div>
                <>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="row clearfix">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-gear mr-2 "></i></div>
                                            <h3 className="card-title">{data.role} DETAILS</h3>
                                        </div>
                                        <div className="card-body">
                                            <form autoComplete='off'>
                                                <div className="row">
                                                    {
                                                        !ImportedURL.SUPERADMIN.includes(data.role) &&
                                                        <>
                                                            <div className="alignment_fix_roomins">
                                                                <div className="card c_grid c_yellow">
                                                                    <div className="card-body text-center view_hotel_card mt-3">
                                                                        <Row>
                                                                            <div className='col-xl-4 col-lg-4 col-md-12 message_detail'>
                                                                                <label className="mt-1 label_black">Email </label>
                                                                                <p className="mt-1"><label>{data.email}</label></p>
                                                                            </div>
                                                                            <div className='col-xl-4 col-lg-4 col-md-12 message_detail'>
                                                                                <label className="mt-1 label_black">Users Category</label>
                                                                                <p className="mt-1"><label>{data.categoryType}</label></p>
                                                                            </div>
                                                                            {account.role == 'vendor' && account.vendorPhoneNo != undefined ?
                                                                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                                                                    <label className="mt-1 label_black">Phone #</label>
                                                                                    <p className="mt-1">{<span dangerouslySetInnerHTML={{ __html: ccDataSelect }} className="userFlag"></span>} <label>{account.vendorPhoneNo}</label></p>
                                                                                </div>
                                                                                :
                                                                                <div className='col-xl-4 col-lg-4 col-md-12'>
                                                                                    <label className="mt-1 label_black">Phone #</label>
                                                                                    <p className="mt-1">{<span dangerouslySetInnerHTML={{ __html: ccDataSelect }} className="userFlag"></span>} <label>{selectData?.phonenumber}</label></p>
                                                                                </div>
                                                                            }
                                                                        </Row>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }

                                                    {account.role == 'vendor' ?
                                                        <div className="col-sm-6 col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                <input type='text' className="form-control" placeholder="Name" name={'vendorname'} disabled={this.state.modalType == "View"} onChange={this.onChangeVendor} value={data.vendorname} />
                                                                <div className="invalid-feedback" style={{ display: this.state.vendornameError ? "block" : 'none' }}>Name is required</div>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className="col-sm-6 col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                <input type='text' className="form-control" placeholder="Name" name={'name'} disabled={this.state.modalType == "View"} onChange={this.onChange} value={data.name} />
                                                                <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Name is required</div>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        ImportedURL.SUPERADMIN.includes(data.role) ?
                                                            <div className="col-sm-6 col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">Number of Tickets</label>
                                                                    <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='ticket' onChange={this.onChange} value={data.ticket} placeholder="Tickets" />
                                                                    <div className="invalid-feedback" style={{ display: this.state.ticketError ? "block" : 'none' }}>Ticket is required</div>
                                                                </div>
                                                            </div>
                                                            : ''
                                                    }
                                                    {data.role != "vendor" &&
                                                        <>
                                                            <div className="col-sm-6 col-md-6">
                                                                <div className="form-group country_code">
                                                                    <label className="form-label">Default Country Code</label>
                                                                    <Select
                                                                        isDisabled={this.state.modalType == "View"}
                                                                        value={ccData ? ccData : ''}
                                                                        onChange={this.handlePhoneCode}
                                                                        options={phoneCode && phoneCode.filter(filterItem => filterItem.status === true).map(item => {
                                                                            return {
                                                                                label: item.name,
                                                                                flag: item.flag,
                                                                                value: item.code,
                                                                                currencycode: item.currencycode ? item.currencycode : '',
                                                                                currencyname: item.currencyname ? item.currencyname : '',
                                                                                currencysymbol: item.symbol ? item.symbol : '',
                                                                            }
                                                                        })}
                                                                        formatOptionLabel={(item) => (
                                                                            <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.label }} />
                                                                        )}
                                                                    />
                                                                    <div className="invalid-feedback" style={{ display: this.state.phonecodeError ? "block" : 'none' }}>Phone Code is required</div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    {account.categoryType == ImportedURL.HOTELOWNER &&
                                                        <>
                                                            <div className="col-sm-6 col-md-6">
                                                                <div className="form-group country_code">
                                                                    <label className="form-label">Default Language for Hotel</label>
                                                                    <Select
                                                                        isDisabled={this.state.modalType == "View"}
                                                                        value={deflang ? deflang : ''}
                                                                        onChange={this.handleLanguage}
                                                                        options={LanguageList}
                                                                    // formatOptionLabel={(item) => (
                                                                    //     <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.label }} />
                                                                    // )}
                                                                    />
                                                                    <div className="invalid-feedback" style={{ display: this.state.phonecodeError ? "block" : 'none' }}>Phone Code is required</div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    {data.role == "vendor" ?
                                                        <>
                                                            <div className="col-sm-6 col-md-6">
                                                                {data.local ?
                                                                    <>
                                                                        <label className="form-label">Company Logo</label>
                                                                        {data.logo ?
                                                                            <div style={{ width: '100px', height: '100px', marginBottom: '20px' }}>
                                                                                {this.state.logoSrc || data.logo ?
                                                                                    <img src={this.state.logoSrc ? this.state.logoSrc : ImportedURL.LIVEURL + 'uploads/' + data.logo} alt='' style={{ width: '100%', height: '100%', objectFit: 'contain' }} onError={onErrorImage} />
                                                                                    : <img className="avatar" src="../assets/images/xs/avatar2.jpg" data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" style={{ width: '100%', height: '100%', objectFit: 'cover' }} onError={onErrorImage} />}
                                                                            </div>
                                                                            :
                                                                            <div style={{ width: '100px', height: '100px', marginBottom: '20px' }}>
                                                                                <img className="avatar" src="../assets/images/xs/avatar2.jpg" data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" style={{ width: '100%', height: '100%', objectFit: 'cover' }} onError={onErrorImage} />
                                                                            </div>
                                                                        }
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Form.Group controlId="formFile" className="mb-3">
                                                                            <Form.Label>Company Logo</Form.Label>
                                                                            <Form.Control type="file" name='logo' accept='image/*' onChange={this.onChangeVendorImage} />
                                                                        </Form.Group>
                                                                        <div style={{ width: '100px', height: '100px', marginBottom: '20px' }}>
                                                                            {this.state.logoSrc || data.logo ?
                                                                                <img src={this.state.logoSrc ? this.state.logoSrc : ImportedURL.FILEURL + data.logo} alt='' style={{ width: '100%', height: '100%', objectFit: 'contain' }} onError={onErrorImage} />
                                                                                : <img className="avatar" src="../assets/images/xs/avatar2.jpg" data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" style={{ width: '100%', height: '100%', objectFit: 'cover' }} onError={onErrorImage} />}
                                                                        </div>
                                                                    </>
                                                                }
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className="col-sm-6 col-md-6">
                                                                <Form.Group controlId="formFile" className="mb-3">
                                                                    <Form.Label>Choose Profile Picture</Form.Label>
                                                                    <Form.Control type="file" name='logo' accept='image/*' onChange={this.onChangeImage} />
                                                                </Form.Group>
                                                                <div style={{ width: '100px', height: '100px', marginBottom: '20px' }}>
                                                                    {this.state.logoSrc || data.logo ?
                                                                        <img src={this.state.logoSrc ? this.state.logoSrc : ImportedURL.FILEURL + data.logo} alt='' style={{ width: '100%', height: '100%', objectFit: 'cover' }} onError={onErrorImage} />
                                                                        : <img className="avatar" src="../assets/images/xs/avatar2.jpg" data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" style={{ width: '100%', height: '100%', objectFit: 'cover' }} onError={onErrorImage} />}
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                    {data.role != "vendor" &&
                                                        <>
                                                            <div className='xl-6 col-lg-6 date_tim_for'>
                                                                <div className='formate'>
                                                                    <label >Date Format</label>
                                                                    <div className='lists' style={{ marginLeft: '27px' }}>
                                                                        <div className='form-group'>
                                                                            <div class="custom-control custom-switch" style={{ marginLeft: "23px" }}>
                                                                                <input type="checkbox" class="custom-control-input" name="dateformate" value="MM-DD-YYYY" id="0" onChange={this.handleDateChange} checked={data.dateformate == '' ? "MM-DD-YYYY" : data.dateformate == "MM-DD-YYYY"} />
                                                                                <label class="custom-control-label" for="0">{dateFormat0}<span>MM-DD-YYYY</span></label>
                                                                            </div>
                                                                        </div>

                                                                        <div className='form-group'>
                                                                            <div class="custom-control custom-switch mb-3">
                                                                                <input type="checkbox" class="custom-control-input" name="dateformate" id="1" value="YYYY-MM-DD" onChange={this.handleDateChange} checked={data.dateformate == "YYYY-MM-DD"} />
                                                                                <label class="custom-control-label" for="1">
                                                                                    {dateFormat1}<span>YYYY-MM-DD</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>

                                                                        <div className='form-group'>
                                                                            <div class="custom-control custom-switch mb-3">
                                                                                <input type="checkbox" class="custom-control-input" name="dateformate" id="2" value="MM-YYYY-DD" onChange={this.handleDateChange} checked={data.dateformate == "MM-YYYY-DD"} />
                                                                                <label class="custom-control-label" for="2">
                                                                                    {dateFormat2}<span>MM-YYYY-DD</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className='form-group'>
                                                                            <div class="custom-control custom-switch mb-3">
                                                                                <input type="checkbox" class="custom-control-input" name="dateformate" id="3" value="DD-MM-YYYY" onChange={this.handleDateChange} checked={data.dateformate == "DD-MM-YYYY"} />
                                                                                <label class="custom-control-label" for="3">
                                                                                    {dateFormat3}<span>DD-MM-YYYY</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='xl-6 col-lg-6 date_tim_for only_time_for'>
                                                                <div className='formate'>
                                                                    <label >Time Format</label>
                                                                    <div className='lists'>
                                                                        <div className='form-group'>
                                                                            <div class="custom-control custom-switch mb-3">
                                                                                <input type="checkbox" class="custom-control-input" name="timeformat" id="11" value="h:mm A" onChange={this.handleTimeChange} checked={data.timeformat == '' ? "h:mm A" : data.timeformat == "h:mm A"} />
                                                                                <label class="custom-control-label" for="11">
                                                                                    {timeFormat0}<span>h:mm A</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className='form-group'>
                                                                            <div class="custom-control custom-switch mb-3">
                                                                                <input type="checkbox" class="custom-control-input" name="timeformat" id="12" value="h:mm a" onChange={this.handleTimeChange} checked={data.timeformat == "h:mm a"} />
                                                                                <label class="custom-control-label" for="12">
                                                                                    {timeFormat1}<span>h:mm a</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className='form-group'>
                                                                            <div class="custom-control custom-switch mb-3">
                                                                                <input type="checkbox" class="custom-control-input" name="timeformat" id="13" value="hh:mm A" onChange={this.handleTimeChange} checked={data.timeformat == "hh:mm A"} />
                                                                                <label class="custom-control-label" for="13">
                                                                                    {timeFormat2}<span>hh:mm A</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                        <div className='form-group'>
                                                                            <div class="custom-control custom-switch mb-3">
                                                                                <input type="checkbox" class="custom-control-input" name="timeformat" id="14" value="hh:mm a" onChange={this.handleTimeChange} checked={data.timeformat == "hh:mm a"} />
                                                                                <label class="custom-control-label" for="14">
                                                                                    {timeFormat3}<span>hh:mm a</span>
                                                                                </label>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            </form>
                                        </div>
                                        <div className="card-footer text-right">
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                    :
                                                    <button type="submit" className="btn commor_save" onClick={() => this.submit()}><i className="fe fe-save mr-2"></i>Save</button>
                                            }
                                        </div>
                                    </div>
                                    {(managementStatus) ?
                                        <>
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className='rounded_icon'><i className="fa fa-ticket mr-2 "></i></div>
                                                    <h3 className="card-title">Default Ticket Workflow</h3>
                                                </div>
                                                <div className="card-body">
                                                    {
                                                        (data.defaultticketworkflow && data.defaultticketworkflow.length > 0 && (managementStatus))
                                                            ?
                                                            <>
                                                                <Row >
                                                                    {
                                                                        data.defaultticketworkflow.map((ticket, i) => {
                                                                            const categoryOption = [];
                                                                            const selectedCategoryArray = [];
                                                                            CategoryData.filter(filterItem => filterItem.status === true).map(item => {
                                                                                if (ticket.array && ticket.array.length > 0) {
                                                                                    (ticket.array).map((teamValue) => {
                                                                                        if (teamValue == item._id) {
                                                                                            selectedCategoryArray.push({ label: item.name, value: item._id, name: 'defaultticketworkflow' });
                                                                                        }
                                                                                    })
                                                                                }
                                                                                categoryOption.push({ label: item.name, value: item._id, name: 'defaultticketworkflow' });
                                                                            })
                                                                            const dynamicKey = `allOption${i}`;
                                                                            if (selectedCategoryArray.length != categoryOption.length && this.state[dynamicKey]) {
                                                                                categoryOption.unshift({ label: "All", value: 'All', name: 'defaultticketworkflow' })
                                                                            }
                                                                            const label = ticket.status == 'remainder' ? 'Reminder' : CapitalizeFirstLetter(ticket.status)
                                                                            return (
                                                                                <>
                                                                                    <div className="col-md-12" key={i}>
                                                                                        <div className="form-group" >
                                                                                            <div className="row" style={{ alignItems: 'center' }}>
                                                                                                <div className="col-md-2">
                                                                                                    <label className='form-label'>{label}</label>
                                                                                                </div>
                                                                                                <div className="col-md-1">
                                                                                                    <label className='form-label'>:</label>
                                                                                                </div>
                                                                                                <div className="col-md-6">
                                                                                                    <div className="form-group">
                                                                                                        <Select
                                                                                                            placeholder={'User Category'}
                                                                                                            isMulti
                                                                                                            value={selectedCategoryArray}
                                                                                                            onChange={(e) => this.onChangeSelectMultiple(e, i)}
                                                                                                            options={categoryOption}
                                                                                                        />
                                                                                                        <div className="invalid-feedback" style={{ display: this.defaultticketworkflowError(i) ? "block" : 'none' }}>User Category is required</div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </>
                                                                            )
                                                                        })
                                                                    }
                                                                </Row >
                                                            </>
                                                            : ""
                                                    }
                                                </div>
                                                <div className="card-footer text-right">
                                                    {
                                                        this.state.ticketSaving ?
                                                            <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                            :
                                                            <button type="submit" className="btn commor_save" onClick={this.submitTicketworkflow}><i className="fe fe-save mr-2"></i>Save</button>
                                                    }
                                                </div>
                                            </div>

                                            <div className="card">
                                                <div className="card-header">
                                                    <div className='rounded_icon'><i className="icon-star mr-2 "></i></div>
                                                    <h3 className="card-title">Review Expire Days</h3>
                                                </div>
                                                <div className="card-body">
                                                    {
                                                        (!ImportedURL.SUPERADMIN.includes(data.role) && data.role !== "vendor" && (managementStatus)) ?
                                                            <>
                                                                <Row >
                                                                    <div className="col-md-12">
                                                                        <div className="form-group" >
                                                                            <div className="row" style={{ alignItems: 'center' }}>
                                                                                <div className="col-md-2">
                                                                                    <label className='form-label'>{"Days"}</label>
                                                                                </div>
                                                                                <div className="col-md-1">
                                                                                    <label className='form-label'>:</label>
                                                                                </div>
                                                                                <div className="col-sm-6 col-md-6">
                                                                                    <div className="form-group">
                                                                                        <input type='text' className="form-control" placeholder="Days" name={'reviewsurlexpdays'} disabled={this.state.modalType == "View"} onChange={this.onChangeVendor} value={data.reviewsurlexpdays} />
                                                                                        <div className="invalid-feedback" style={{ display: this.state.reviewsurlexpdaysError ? "block" : 'none' }}>Days is required</div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Row >
                                                            </>
                                                            : ''
                                                    }
                                                </div>
                                                <div className="card-footer text-right">
                                                    {
                                                        this.state.reviewUserSaving ?
                                                            <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                            :
                                                            <button type="submit" className="btn commor_save" onClick={this.submitReviewFormUser}><i className="fe fe-save mr-2"></i>Save</button>
                                                    }
                                                </div>
                                            </div>
                                        </>
                                        : ''}
                                </div>
                            </div>
                        </div >
                    </div >
                    {
                        ImportedURL.SUPERADMIN.includes(account.role) ?
                            <div className="section-body pt-3" id='review-form-data'>
                                <div className="container-fluid">
                                    <div className="row clearfix">
                                        <div className="col-lg-12">
                                            <div className="card">
                                                <div className="card-header">
                                                    <div className='rounded_icon'><i className="fa fa-gear mr-2 "></i></div>
                                                    <h3 className="card-title">Review Expire Days</h3>
                                                </div>
                                                <div className="card-body">
                                                    <form autoComplete='off'>
                                                        <div className="row">

                                                            <div className="col-sm-6 col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">Hotel<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                    <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                        <Select
                                                                            value={selectedHotel}
                                                                            onChange={this.searchUser}
                                                                            options={hotelOptions}
                                                                            formatOptionLabel={(e) => (
                                                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                    {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                                    <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                                </div>
                                                                            )}
                                                                            ref={this.hotelInputRef}
                                                                            isDisabled={this.state.modalType == "View"}
                                                                        />
                                                                        <div className="invalid-feedback" style={{ display: this.state.selecthotelError ? "block" : 'none' }}>Hotel is required</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-sm-6 col-md-6">
                                                                <div className="form-group">
                                                                    <label className="form-label">Days<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                    <input type='text' className="form-control" placeholder="Days" name={'adminreviewsurlexpdays'} disabled={this.state.modalType == "View"} onChange={this.onChangeVendor} value={data.adminreviewsurlexpdays} />
                                                                    <div className="invalid-feedback" style={{ display: this.state.adminreviewsurlexpdaysError ? "block" : 'none' }}>Days is required</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                                < div className="card-footer text-right">
                                                    {
                                                        this.state.reviewSaving ?
                                                            <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                            :
                                                            <button type="submit" className="btn commor_save" onClick={this.submitReview}><i className="fe fe-save mr-2"></i>Save</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div >
                            : ''
                    }
                    {
                        (ImportedURL.HOTELOWNER == (account.categoryType)) ?
                            <div>
                                <div className="section-body" id='review-form-data'>
                                    <div className="container-fluid">
                                        <div className="row clearfix">
                                            <div className="col-lg-12">
                                                <div className="card ticket_flow_dynamic_radio_btn">
                                                    <div className="card-header">
                                                        <div className='rounded_icon'><i className="fa fa-gear mr-2 "></i></div>
                                                        <h3 className="card-title">{languageData && languageData.DEFAULT_MANAGEMENT ? languageData.DEFAULT_MANAGEMENT : "Default Management"}</h3>
                                                    </div>
                                                    <div className="card-body">
                                                        <Row className='mt-3'>
                                                            <div className='col-xl-6 col-lg-6 col-md- 12 col-sm-12 col-12 mb-2'>
                                                                <>
                                                                    <label className="form-label">Users Category</label>
                                                                    <div className="form-group">
                                                                        <Select
                                                                            isMulti
                                                                            value={selectedCategoryArray}
                                                                            onChange={this.handleChangeSelectMultiCreate}
                                                                            options={categoryOption}
                                                                        />
                                                                        <div className="invalid-feedback" style={{ display: this.state.multiplecategoryError ? "block" : 'none' }}>Users Category is required</div>
                                                                    </div>
                                                                </>

                                                            </div>
                                                        </Row>
                                                    </div>
                                                    <div className="card-footer text-right">
                                                        <button type="submit" style={{ marginBottom: '20px' }} className="btn commor_save" onClick={(e) => this.defaultManagementSubmit()}><i className={this.state.defaultSaving ? "fa fa-spinner fa-spin mr-2" : "fe fe-save mr-2"}></i>{this.state.defaultSaving ? "Saving" : "Save"}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''
                    }
                    {this.state.spinner ?
                        <div className='common_loader_ag_grid'>
                            <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                            </Spinner>
                        </div>
                        : ""}
                    <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div>
                </>
            </>
        )
    }
}

const mapStateToProps = state => ({
    AccountState: state.account,
    UserState: state.user,
    CountryState: state.country,
    CategoryState: state.category,
    HotelState: state.hotel,
    LanguageState: state.language,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        ListHotel: AC_LIST_HOTEL,
        ListCategory: AC_LIST_CATEGORY,
        ListLanguage: AC_LIST_LANGUAGE,
        ListUser: AC_LIST_USER,
        ListCountry: AC_LIST_COUNTRY,
        HandleInputChange: AC_HANDLE_INPUT_CHANGE,
        AC_ADMIN_UPDATE,
        AccountDetails: AC_ACCOUNT_DETAILS,
    }, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AdminSettings);
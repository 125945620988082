import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { bindActionCreators } from 'redux';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import Select from 'react-select';
import { AC_ADD_USER, AC_DELETE_USER, AC_HANDLE_USER_CHANGE, AC_LIST_USER, AC_RESET_USER, AC_UPDATE_USER, AC_VIEW_USER, AC_EMPTY_USER, AC_EMPTY_USER_LIST } from '../../actions/userAction';
import { Emailvalidate, onErrorImage } from '../../common/validate'
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import { newColumnDefs, columnDefs } from './userColumnDefinition';
import { Phonenumber } from "../../common/validate";
import Spinner from 'react-bootstrap/Spinner';
import ModalStatus from '../../utilities/Modal/modalstatus';
import validator from 'validator';

const initialState = {
    modalType: "Add",
    columnApi: '',
    gridApi: '',
    columnDefs: columnDefs,
    defaultColumDef: {
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
    },
    hotelError: false,
    categoryError: false,
    nameError: false,
    emailError: false,
    emailValidError: false,
    phonenumberError: false,
    id: '',
    cc: '+1',
    orignalData: [],
    ownerList: [],
    activeClick: false,
    inactiveClick: false,
    totalClick: true,
    ccError: false,
    defaultCC: '',
    searchhoteloption: { label: 'All', value: '', name: 'searchhotel' },
    searchcategoryoption: { label: 'All', value: '', name: 'searchcategory' },
    saving: false,
    phoneNoPatternError: false,
    // initial: true,
    statusId: '',
    statusChanges: '',
    disableId: '',
    disableChanges: '',
    totalCount: 0,
    trueCount: 0,
    falseCount: 0,
    viewSpin: false,
    columnreset: true,
    hotelName: '',
    status: '',
    perPage: 25,
    spinner: false,
    gridKey: 0,
}
class ListUser extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    changeModal = (e) => {
        this.props.ResetUser();
        this.setState({ modalType: e.target.id, existingowner: false, isowner: false, nameError: false, ccError: false, categoryError: false, emailError: false, hotelError: false, existingowner: false, phonenumberError: false, phoneNoPatternError: false, viewSpin: false });
        // const account = this.props.AccountState.account;
        const { AccountState } = this.props;
        const account = AccountState.account;
        if (!ImportedURL.SUPERADMIN.includes(AccountState.role)) {
            this.props.HandleInputChange('hotel', account.hotel);
        }
        const phonecode = AccountState.account.phonecode;
        if (phonecode) {
            this.props.HandleInputChange('cc', phonecode);
        }
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData;
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'View') {
            this.props.ResetUser();
            this.setState({ modalType: "View", nameError: false, ccError: false, categoryError: false, emailError: false, hotelError: false, existingowner: false, isowner: false, phonenumberError: false, phoneNoPatternError: false, viewSpin: true });
            this.props.ViewUser(event.data._id);
        }
        if (value === 'Edit') {
            this.setState({ id: event.data._id, modalType: "Edit", ccError: false, nameError: false, ccError: false, categoryError: false, emailError: false, hotelError: false, existingowner: false, isowner: false, phonenumberError: false, phoneNoPatternError: false, categoryError: false, page: this.gridApi.paginationGetCurrentPage(), });
        }
        if (value === 'Copyemail') {
            navigator.clipboard.writeText(event.data.email);
            Swal.fire({ title: 'Copied', showConfirmButton: false, timer: 500 })
        }
        if (value === 'sendinvite') {
            this.setState({ id: event.data._id })
        }
        if (value === 'locationrestrict') {
            this.setState({ disableId: event.data._id, disableChanges: !event.data.locationrestrict })
        }
        if (value === 'Status') {
            this.setState({ statusId: event.data._id, statusChanges: !event.data.status, })
        }
        if (value === 'previlege') {
            this.setState({ id: event.data._id, categoryid: event.data.categoryid })
        }
        if (value === 'pmsprevilege') {
            this.setState({ id: event.data._id, categoryid: event.data.categoryid })
        }
    }
    disableModal = (modalname) => {
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        if (modalname == 'locationRestrict') {
            axios.post(ImportedURL.API.locationRestrict, { id: this.state.disableId, locationrestrict: this.state.disableChanges, model: 'users' })
                .then((data) => {
                    const dataSource = this.getDataSource({ category: this.state.searchcategory, hotel: this.state.searchhotel, status: this.state.status })
                    this.gridApi.setDatasource(dataSource);
                    Success((languageData && languageData.LOCATION_RESTRICT ? languageData.LOCATION_RESTRICT : "Location restrict") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"))
                }).catch(({ response }) => {
                    if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else {
                        Error(response.statusMessage)
                    }
                });
        } else if (modalname == 'statusChange') {
            axios.post(ImportedURL.API.statusChange, { id: this.state.statusId, status: this.state.statusChanges, model: 'users' })
                .then((data) => {
                    const dataSource = this.getDataSource({ category: this.state.searchcategory, hotel: this.state.searchhotel, status: this.state.status })
                    this.gridApi.setDatasource(dataSource);
                    Success((languageData && languageData.STATUS ? languageData.STATUS : "Status") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"))
                }).catch(({ response }) => {
                    if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else {
                        Error(response.statusMessage)
                    }
                });
        } else if (modalname == 'previlege') {
            axios.post(ImportedURL.API.updatePrevilegeStatus, { id: this.state.id, category: this.state.categoryid })
                .then((data) => {
                    const dataSource = this.getDataSource({ category: this.state.searchcategory, hotel: this.state.searchhotel, status: this.state.status })
                    this.gridApi.setDatasource(dataSource);
                    Success((languageData && languageData.PRIVILEGE ? languageData.PRIVILEGE : "Privilege") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"))
                }).catch(({ response }) => {
                    if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else {
                        Error(response.statusMessage)
                    }
                });
        } else if (modalname == 'pmsprevilege') {
            axios.post(ImportedURL.API.updatePmsPrevilegeStatus, { id: this.state.id, category: this.state.categoryid })
                .then((data) => {
                    const dataSource = this.getDataSource({ category: this.state.searchcategory, hotel: this.state.searchhotel, status: this.state.status })
                    this.gridApi.setDatasource(dataSource);
                    Success((languageData && languageData.PRIVILEGE ? languageData.PRIVILEGE : "Privilege") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"))
                }).catch(({ response }) => {
                    if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else {
                        Error(response.statusMessage)
                    }
                });
        } else if (modalname == 'sendinvite') {
            axios.post(ImportedURL.API.sendInvite + "/" + this.state.id)
                .then((res) => {
                    Success((languageData && languageData.INVITE_SENT_SUCCESSFULLY ? languageData.INVITE_SENT_SUCCESSFULLY : " Invite sent successfully"));
                }).catch(({ response }) => {
                    if (response.status == 400) {
                        Error((languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : ' Bad request'))
                    }
                    else if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else {
                        Error(response.statusMessage)
                    }
                });
        }
    }
    handleInputChange = (e) => {
        const { name, value } = e.target;
        let emailValidError = false;
        if (value) {
            this.setState({ emailError: false });
            if (!validator.isEmail(value)) {
                emailValidError = true;
            } else {
                const tld = value.split('.').pop();
                if (tld.length < 2 || tld.length > 3) {
                    emailValidError = true;
                }
            }
        } else {
            this.setState({ emailError: false });
        }
        this.setState({ emailValidError: emailValidError });
        this.props.HandleInputChange(name, value);
    };
    onChange = e => {
        if (this.state.defaultCC) {
            this.props.HandleInputChange('cc', this.state.defaultCC);
        }
        const { name, value } = e.target;
        const Error = name + "Error";
        const ValidError = name + "ValidError";
        this.setState({ [Error]: false });
        this.props.HandleInputChange(name, value);
        if (name === 'email') {
            this.setState({ email: value });
            var email = value;
            if (email) {
                this.setState({ [Error]: false });
                if (Emailvalidate(email)) {
                    this.setState({ [ValidError]: false })
                } else {
                    this.setState({ [ValidError]: true })
                }
            } else {
                this.setState({ [Error]: false, [ValidError]: false })
            }
        }
        else if (name === 'cc') {
            this.setState({ cc: value });
        }
        else if (name === 'phonenumber') {
            let s1 = value.replace(/[^a-z\d\s]+/gi, "");
            let s2 = s1.replace(/[- )(]/g, '')
            var s3 = s2.slice(0, 10);
            var val = s3.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                this.setState({ [Error]: false });
                if (Phonenumber(val)) {
                    this.setState({ phoneNoPatternError: false })

                } else {
                    this.setState({ phoneNoPatternError: true })
                }
                this.props.HandleInputChange(name, val);

            } else {
                this.setState({ phoneNoPatternError: false })
                this.props.HandleInputChange(name, val);
            }
        } else if (name == 'mailstatus') {
            this.props.HandleInputChange(name, e.target.checked);
        }
        else {
            this.props.HandleInputChange(name, value);
        }
    }
    handleChange = e => {
        const { AccountState, LandingState } = this.props;
        const { name, value, label, pmsprivileges } = e;

        const listCategories = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[7] : [];;
        var selectedCategory = ""
        if (name == 'category') {
            selectedCategory = listCategories.find(e => e._id === value)
        }
        if (selectedCategory) {
            this.props.HandleInputChange('mandatory', selectedCategory.mandatory);
            this.props.HandleInputChange('webaccess', selectedCategory.webaccess);
        }

        const Error = name + "Error";
        this.setState({ [Error]: false });
        if (name == 'category' && label == ImportedURL.HOTELOWNER) {
            this.setState({ isowner: true })
        } else {
            this.setState({ isowner: false, existingowner: false })
        }
        if (name == 'category') {
            this.props.HandleInputChange('pmsprivileges', pmsprivileges);
            this.props.HandleInputChange('previleges', e.previleges);
            this.props.HandleInputChange('email', '');
            this.props.HandleInputChange('name', '');
            this.props.HandleInputChange('phonenumber', '');
        }
        if (name == "hotel") {
            this.props.HandleInputChange('category', '');
            this.props.HandleInputChange('email', '');
            this.props.HandleInputChange('name', '');
            this.props.HandleInputChange('phonenumber', '');
            const phonecode = AccountState.account.phonecode;
            if (phonecode) {
                this.props.HandleInputChange('cc', phonecode);
            }
        }
        this.props.HandleInputChange(name, value);
    }
    handleChangeEmail = e => {
        const { name, value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.HandleInputChange(name, value);
    }
    handlePhoneRestrict = e => {
        this.props.HandleInputChange("locationrestrict", e.target.checked);
    }
    handleaccess = e => {
        this.props.HandleInputChange("webaccess", e.target.checked);
    }

    handlePhoneCode = e => {
        const { name, value } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false, defaultCC: '' });
        this.props.HandleInputChange(name, value);
    }

    componentDidUpdate(prevProps, prevState) {
        const { AccountState } = this.props;
        const role = AccountState.role;

        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role) && this.state.columnreset) {
            this.gridApi.setColumnDefs(newColumnDefs);
            this.setState({ columnreset: false })
        }

        // To set data once grid api is updated
        if (this.gridApi) {
            if (this.props.AccountState.previleges && prevState.gridApi !== this.state.gridApi) {
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
            }
        }
    }

    // onPerPageChange = e => {
    //     const { value,  } = e;
    //     this.props.EmptyUserList()
    //     this.setState({ gridKey: this.state.gridKey + 1 });
    //     this.setState({ perPage: value })
    //     const dataSource = this.getDataSource({perPage:value, category: this.state.searchcategory, hotel: this.state.searchhotel })
    //     this.gridApi.setDatasource(dataSource);
    // }

    onPerPageChange = e => {
        const { value } = e;
        this.setState({ perPage: value }, () => {
            if (this.gridApi) {
                this.gridApi.paginationSetPageSize(parseInt(value, 10));
                this.gridApi.purgeInfiniteCache();
            }
        });
    };

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
    };

    getDataSource = (query = {}) => {
        let category = query.category && query.category != undefined ? query.category : localStorage.getItem("searchcategory");
        let hotel = query.hotel && query.hotel != undefined ? query.hotel : localStorage.getItem("searchhotel");
        let status = query.status && query.status != undefined ? query.status : localStorage.getItem("status");

        if (category) {
            query['category'] = category;
        }

        if (hotel) {
            query['hotel'] = hotel;
        }

        if (category && hotel) {
            query['category'] = category;
            query['hotel'] = hotel;
        }
        query['status'] = status;

        return {
            getRows: async (params) => {
                // Grid params for filter and sort
                const page = Math.floor(params.startRow / this.state.perPage);
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                // Action call for hotels list
                this.props.EmptyUserList()
                await this.props.ListUser({ ...query, perPage: this.state.perPage, page: page, filter: filter, allUserDataStatus: true, sort: sortModel.length ? sortModel[0] : {} });
                const rowData = this.props.UserState.listusers;
                const total = this.props.UserState.total;
                const { AccountState, } = this.props;
                const previleges = AccountState.previleges;
                const Previlege = previleges.find(obj => { return obj.name == "User" });
                const editOption = Previlege.edit ? '<button title="Edit" type="button" class="btn btn-icon" data-action-type="Edit" data-toggle="modal" ><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
                const sendOption = Previlege.invite ? '<button type="button" class="btn btn-icon" data-action-type="sendinvite" data-toggle="modal" data-target="#sendinvite" ><i class="fa fa-send" style="color: green !important;font-size:20px" data-toggle="tooltip" title="Send invite" data-action-type="sendinvite"></i></button>' : '';
                const template = '<div class="userlist_view"><button  title="View" type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-eye"  data-action-type="View"></i></button>'
                    + editOption
                    + sendOption
                    + '</div>';
                rowData.forEach(object => {
                    object.actions = template;
                });
                const lbLastRowOnPageEl = document.querySelector(`[ref=\'lbLastRowOnPage\']`);
                if (lbLastRowOnPageEl) {
                    lbLastRowOnPageEl.innerHTML = (rowData) ? rowData.length + params.startRow : 0;
                }
                params.successCallback(rowData, total);
            }
        }
    }

    submit = () => {
        const { UserState, AccountState, LandingState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const data = UserState.user;
        let valid = 1;

        if (!data.hotel) {
            this.setState({ hotelError: true });
            valid = 0;
        }
        data['name'] = data.name.trim()
        if (!data.name) {
            this.setState({ nameError: true });
            valid = 0;
        }
        if (!data.email) {
            this.setState({ emailError: true });
            valid = 0;
        }
        if (this.state.emailValidError) {
            valid = 0;
        }
        if (!data.phonenumber) {
            valid = 0;
            this.setState({ phonenumberError: true });
        }
        if (this.state.phoneNoPatternError) {
            valid = 0;
        }
        if (!data.category) {
            valid = 0;
            this.setState({ categoryError: true });
        }
        if (!data.cc && this.state.defaultCC == '') {
            valid = 0;
            this.setState({ ccError: true });
        }
        data['existingowner'] = this.state.existingowner;
        // const phoneCode = this.props.CountryState.listcountry;
        const phoneCode = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 5 ? LandingState.landingdata.data[1] : [];;

        let phoneValue = '';
        if (phoneCode.length > 0) {
            phoneCode.map(item => {
                if (data.cc) {
                    if (item.code == data.cc) {
                        phoneValue = item
                    }
                    if (item.phonecode == data.cc) {
                        phoneValue = item
                    }
                }
            })
        }
        if (phoneValue) {
            data['phonecode'] = phoneValue.code;
            data['currencyname'] = phoneValue.currencyname;
            data['currencycode'] = phoneValue.currencycode;
            data['currencysymbol'] = phoneValue.symbol;
        }
        if (valid) {
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addUser, data)
                    .then((res) => {
                        localStorage.removeItem("searchhotel");
                        localStorage.removeItem("searchcategory");
                        localStorage.removeItem("status");
                        this.setState({ saving: false })
                        this.setState({ searchhoteloption: { label: 'All', value: '', name: 'searchhotel' }, searchcategoryoption: { label: 'All', value: '', name: 'searchcategory' }, searchhotel: '', searchcategory: '', activeClick: false, inactiveClick: false, totalClick: true })
                        Success((languageData && languageData.USER ? languageData.USER : "User") + (languageData && languageData.CREATED_SUCCESSFULLY ? languageData.CREATED_SUCCESSFULLY : " created successfully"));
                        const dataSource = this.getDataSource()
                        this.gridApi.setDatasource(dataSource);
                        this.props.ResetUser();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                        this.setState({ isowner: false, existingowner: false });

                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        if (response.status == 409) {
                            Error((languageData && languageData.EMAIL_ALREADY_EXIT_FOR_THIS ? languageData.EMAIL_ALREADY_EXIT_FOR_THIS : " Email already exist for this hotel"))
                        } else if (response.status == 400) {
                            Error((languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        }
                        else if (response.status == 408) {
                            Error((languageData && languageData.PHONE_NUMBER ? languageData.PHONE_NUMBER : "Phone number") + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : " already exist"))
                        }
                        else if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else {
                            Error(response.statusMessage)
                        }
                    });
            } else {
                axios.post(ImportedURL.API.updateUser + "/" + this.state.id, data)
                    .then((res) => {
                        this.setState({ saving: false })
                        this.setState({ searchhoteloption: '', searchcategoryoption: '', searchhotel: '', searchcategory: '' })
                        let btn = document.getElementById("closeModal1");
                        btn.click();
                        Success((languageData && languageData.USER ? languageData.USER : "User") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"));
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        if (response.status == 409) {
                            Error((languageData && languageData.EMAIL_CANT_BE_UPDATED ? languageData.EMAIL_CANT_BE_UPDATED : " Email can't be updated, already tagged to tickets"))
                        } else if (response.status == 400) {
                            Error((languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else if (response.status == 403) {
                            Error('Phone # Already exist')
                        }
                        else if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else {
                            Error(response.statusMessage)
                        }
                    });
                // this.props.UpdateUser(data, this.state.id);
            }
        }
    }

    editsubmit = () => {
        const { UserState, AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData

        const data = UserState.user;
        let valid = 1;
        if (!data.hotel) {
            this.setState({ hotelError: true });
            valid = 0;
        }
        if (!data.category) {
            valid = 0;
            this.setState({ categoryError: true });
        }
        if (valid) {
            // this.props.UpdateUser(data, this.state.id);
            axios.post(ImportedURL.API.updateUser + "/" + this.state.id, data)
                .then((res) => {
                    this.setState({ saving: false })
                    this.setState({ searchhoteloption: '', searchcategoryoption: '', searchhotel: '', searchcategory: '' })
                    let btn = document.getElementById("closeModal1");
                    btn.click();
                    Success((languageData && languageData.USER ? languageData.USER : "User") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"));
                    // Success(res.statusText);
                }).catch(({ response }) => {
                    this.setState({ saving: false })
                    if (response.status == 409) {
                        Error((languageData && languageData.EMAIL_CANT_BE_UPDATED ? languageData.EMAIL_CANT_BE_UPDATED : "Email can't be updated, already tagged to tickets"))
                    } else if (response.status == 400) {
                        Error((languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : ' Bad request'))
                    }
                    else if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else {
                        Error(response.statusMessage)
                    }
                });

        }
    }

    componentDidMount() {
        this.props.EmptyUser();
        localStorage.removeItem("searchhotel");
        localStorage.removeItem("searchcategory");
        localStorage.removeItem("status");
        var phonecode = localStorage.getItem("phonecode");
        this.setState({ defaultCC: phonecode })
        const account = this.props.AccountState.account;
        this.setState({ hotelName: account.hotelName ? account.hotelName : '' });
    }

    onBtnExport = () => {
        this.gridApi.exportDataAsCsv();
    };

    existingOwner = e => {
        const { AccountState, UserState } = this.props;
        const phonecode = AccountState?.account.phonecode;
        this.setState({ existingowner: e.target.checked, emailValidError: false, phoneNoPatternError: false, });
        let allUserData = UserState.allUserData
        this.setState({ ownerList: allUserData })
        this.props.HandleInputChange('email', '');
        this.props.HandleInputChange('phonenumber', '');
        this.props.HandleInputChange('name', '');
        this.props.HandleInputChange('cc', phonecode);
    }
    AddSelectHandleChange = e => {
        const { name, value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.HandleInputChange(name, value);
    }

    onChangeUSer = e => {
        const { name, value, label } = e;
        const user = this.state.ownerList.find(obj => { return obj.email == value });
        if (user) {
            this.setState({ nameError: false, phonenumberError: false, emailError: false, ccError: false });
            this.props.HandleInputChange('email', value);
            this.props.HandleInputChange('phonenumber', user.phonenumber);
            this.props.HandleInputChange('name', user.name);
            this.props.HandleInputChange('cc', user.cc);
        }
    }

    exportToCSV = () => {
        const account = this.props.AccountState.account;
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: ['hotel', 'category', 'designation', 'name', 'emailcsv', 'phonenumber'],
            fileName: "users" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                // return true if index is less than first row or more than last row
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const { LandingState } = this.props;
        const account = this.props.AccountState.account;
        const exportData = this.props.UserState.listusers;
        const phoneCode = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[1] : [];;
        let ccData = '';
        const selectedProperties = (this.state.hotelName && this.state.hotelName != "All") ? ['category', 'designation', 'name', 'email', 'cc', 'phonenumber'] : ['hotel', 'category', 'designation', 'name', 'email', 'cc', 'phonenumber'];
        const selected = exportData.map(item => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    if (property == "designation") {
                        selectedData[property] = item[property] ? item[property] : "---"
                    } else if (property == "cc") {
                        if (phoneCode.length > 0) {
                            phoneCode.map(temp => {
                                if (temp.code === item[property]) {
                                    ccData = temp.phonecode
                                }
                                if (temp.phonecode === item[property]) {
                                    ccData = temp.phonecode
                                }
                            })
                        }
                    } else if (property == "phonenumber") {
                        selectedData[property] = item[property] ? ccData + ' ' + item[property] : "";
                    } else {
                        selectedData[property] = item[property];
                    }
                }
            });
            return selectedData;
        });
        let data = {
            title: (this.state.hotelName) ? (this.state.hotelName != "All" ? this.state.hotelName + " - Users List" : "Users List") : "Users List",
            head: (this.state.hotelName && this.state.hotelName != "All") ? ['#', 'Users Category', 'Designation', 'Name', 'Email', 'Phone #'] : ['#', 'Hotel', 'Users Category', 'Designation', 'Name', 'Email', 'Phone #'],
            body: selected,
            hotelNameExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hotelNameExport : '',
            hoteladdressExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "users" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }

    searchUser = e => {
        const { name, value, label } = e;
        if (name == 'searchhotel') {
            localStorage.setItem('searchhotel', value)
            if ((this.state.status == true) && this.state.status != '') {
                const dataSource = this.getDataSource({ category: this.state.searchcategory, hotel: value, status: this.state.status })
                this.gridApi.setDatasource(dataSource);
            } else if (!this.state.status) {
                const dataSource = this.getDataSource({ category: this.state.searchcategory, hotel: value, status: this.state.status })
                this.gridApi.setDatasource(dataSource);
            } else {
                const dataSource = this.getDataSource({ category: this.state.searchcategory, hotel: value })
                this.gridApi.setDatasource(dataSource);
            }
            this.setState({ [name + 'option']: e, hotelName: label })
        } else {
            localStorage.setItem('searchcategory', value)
            if ((this.state.status == true) && this.state.status != '') {
                const dataSource = this.getDataSource({ category: value, hotel: this.state.searchhotel, status: this.state.status })
                this.gridApi.setDatasource(dataSource);
            } else if (!this.state.status) {
                const dataSource = this.getDataSource({ category: value, hotel: this.state.searchhotel, status: this.state.status })
                this.gridApi.setDatasource(dataSource);
            } else {
                const dataSource = this.getDataSource({ category: value, hotel: this.state.searchhotel })
                this.gridApi.setDatasource(dataSource);
            }
            this.setState({ [name + 'option']: e })
        }
        this.setState({ [name]: value, [name + 'option']: e, });
    }

    total = e => {
        this.props.EmptyUserList()
        this.setState({ activeClick: false, inactiveClick: false, totalClick: true, status: "" })
        localStorage.removeItem('status')        // const dataSource = this.getDataSource();
        const dataSource = this.getDataSource({ category: this.state.searchcategory, hotel: this.state.searchhotel })
        this.gridApi.setDatasource(dataSource);
    }
    active = e => {
        this.props.EmptyUserList()
        this.setState({ activeClick: true, inactiveClick: false, totalClick: false, status: true })
        localStorage.setItem('status', true)
        // const dataSource = this.getDataSource({ status: true })
        const dataSource = this.getDataSource({ category: this.state.searchcategory, hotel: this.state.searchhotel, status: true })
        this.gridApi.setDatasource(dataSource);
    }
    inactive = e => {
        this.props.EmptyUserList()
        this.setState({ inactiveClick: true, activeClick: false, totalClick: false, status: false })
        localStorage.setItem('status', false)
        // const dataSource = this.getDataSource({ status: false })
        const dataSource = this.getDataSource({ category: this.state.searchcategory, hotel: this.state.searchhotel, status: false })
        this.gridApi.setDatasource(dataSource);
    }

    setmadatoryUser = (e) => {
        const { checked } = e.target;
        this.props.HandleInputChange('mandatory', checked);
    }

    render() {
        if (this.state.modalType === 'Edit') {
            return <Redirect to={'edit-user/' + this.state.id + '?page=' + this.state.page} />
        }
        const { UserState, AccountState, LandingState } = this.props;
        const listHotels = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[0] : [];;
        const phoneCode = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[1] : [];;
        const dataDesignation = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[6] : [];;
        const listCategories = LandingState.landingdata && LandingState.landingdata.data && LandingState.landingdata.data.length > 1 ? LandingState.landingdata.data[7] : [];;
        var rowData = []
        rowData = UserState.listusers
        const totalcount = UserState.totalStatus;
        const activecount = UserState.activecount;
        const inactivecount = UserState.inactivecount;
        const account = AccountState.account;
        const data = UserState.user;
        const spinner = UserState.spinner;
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "User" });
        const editOption = Previlege.edit ? '<button title="Edit" type="button" class="btn btn-icon" data-action-type="Edit" data-toggle="modal" ><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
        const sendOption = Previlege.invite ? '<button type="button" class="btn btn-icon" data-action-type="sendinvite" data-toggle="modal" data-target="#sendinvite"><i class="fa fa-send" style="color: green !important;font-size:20px"></i></button>' : '';
        const template = '<div class="userlist_view"><button  title="View" type="button" class="btn btn-icon" data-action-type="View" data-toggle="modal" data-target="#viewmodal"><i class="fa fa-eye"  data-action-type="View"></i></button>'
            + editOption
            + sendOption
            + '</div>';

        if (rowData) {
            rowData.forEach(object => {
                object.actions = template;
            });
        }
        if (rowData) {
            rowData.forEach(object => {
                object.phoneCode = phoneCode;
            });
        }
        if (rowData) {
            rowData.forEach(object => {
                object.role = account.role;
            });
        }

        let selectedCategory = ''
        if (data.category) {
            selectedCategory = listCategories.find(e => e._id === data.category)
        }
        var newListCategories = [];
        if (listCategories) {
            if (account.allhotels?.length > 0) {
                listCategories.filter(filterItem => filterItem.status == true).map((item) => {
                    newListCategories.push(item)
                })
            } else {
                listCategories.filter(filterItem => filterItem.status == true).map((item) => {
                    newListCategories.push(item)
                })
            }
        }
        // options for filters
        var hotelOptionSearch = [];
        if (listHotels.length > 1) {
            hotelOptionSearch.push({ label: 'All', value: '', name: 'searchhotel' })
        }
        if (listHotels.length > 0) {
            listHotels.map(item => {
                hotelOptionSearch.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'searchhotel' });
            })
        }
        var selectedHotel = '';
        const hotelOptions = [];
        if (account?.allhotels?.length > 0) {
            account.allhotels.map((item) => {
                if (account.hotel == item.hotelid) {
                    selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                }
                hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo });
            })
        } else {
            if (data.hotel?._id !== undefined) {
                listHotels.map((item) => {
                    if (data.hotel?._id == item._id) {
                        selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                    }
                    hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
                })
            } else {
                listHotels.map((item) => {
                    if (data.hotel == item._id) {
                        selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                    }
                    hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
                })
            }
        }
        const categoryOptions = [{ label: 'All', value: '', name: 'searchcategory' }];
        newListCategories.filter(filterItem => filterItem.status == true).map(item => {
            categoryOptions.push({ label: item.name, value: item._id, name: 'searchcategory' });
        })

        let ccData = '';
        if (phoneCode) {
            phoneCode && phoneCode.map(item => {
                if (data.cc) {
                    if (item.phonecode == data.cc) {
                        ccData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                    if (item.code == data.cc) {
                        ccData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                } else {
                    if (item.phonecode === account.phonecode) {
                        ccData = { label: item.phonecode, value: item.phonecode, flag: item.flag }
                    }
                    if (item.code === account.phonecode) {
                        ccData = { label: item.phonecode, value: item.code, flag: item.flag }
                    }
                }
            })
        }
        const limitOptions = [
            // { value: '2', label: '2' },
            // { value: '4', label: '4' },
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        const DesignationArray = [];
        if (data.hotel) {
            dataDesignation.filter(filterItem => filterItem.status == true).map((item, i) => {
                if (data.hotel == item.hotel) {
                    if (data.category == item.department || data.category == item.departmentId) {
                        DesignationArray.push(
                            item
                        )
                    }
                }
            })
        }

        var selectDesignationData = '';
        if (data.designation !== undefined) {
            selectDesignationData = DesignationArray.find(e => e._id === data.designation)
        }
        let emailLabel = ''
        if (this.state.ownerList && this.state.ownerList.length > 0) {
            this.state.ownerList.filter(filterItem => filterItem.status === true).map(item => {
                if (data.email == item.email)
                    emailLabel = { label: item.email + ' ( ' + item.name + ' )', value: item.email, name: 'email' }
            })
        }

        const languageData = this.props.AccountState.account.SelectedlanguageData
        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-user"><h6 className="highlights_breadcrump"> {languageData && languageData.USERS ? languageData.USERS : "Users"} {languageData && languageData.LIST ? languageData.LIST : 'List'}</h6></Link></p>
                        </div>
                        <div className="section-body mt-3">
                            <div className="container-fluid">
                                <div className="row clearfix common_top_section">
                                    <div className="col-12 col-md-12 col-xl-4">
                                        <div className="card">
                                            <div className="card-body ribbon" style={{ backgroundColor: this.state.totalClick ? '#1c3078' : '', borderRadius: this.state.totalClick ? '10px' : '', border: this.state.totalClick ? "2px solid grey" : '', boxShadow: this.state.totalClick ? '0 0 5px #555' : '' }} >
                                                <div className={`${!this.state.totalClick ? "ribbon-box orange" : "ribbon-box text-color white"} `}>{totalcount}</div>
                                                <a className="my_sort_cut text-muted" href="#" onClick={this.total}>
                                                    <span className="dashbord_icons">
                                                        <i className="fe fe-search" style={{ color: this.state.totalClick ? '#1c3078' : '' }} id='total'></i>
                                                    </span>
                                                    <span className='dash_total' style={{ color: this.state.totalClick ? 'white' : '' }}>Total</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-xl-4">
                                        <div className="card">
                                            <div className="card-body ribbon" style={{ backgroundColor: this.state.activeClick ? '#1c3078' : '', borderRadius: this.state.activeClick ? '10px' : '', border: this.state.activeClick ? "2px solid grey" : '', boxShadow: this.state.activeClick ? '0 0 5px #555' : '' }}>
                                                <div className={`${!this.state.activeClick ? "ribbon-box orange" : "ribbon-box text-color white"} `}>{activecount}</div>
                                                <a className="my_sort_cut text-muted" href="#" onClick={this.active}>
                                                    <span className="dashbord_icons">
                                                        <i className="fa fa-check" style={{ color: this.state.activeClick ? '#1c3078' : '' }}></i>
                                                    </span>
                                                    <span className='dash_total' style={{ color: this.state.activeClick ? 'white' : '' }}>Active</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 col-md-12 col-xl-4">
                                        <div className="card">
                                            <div className="card-body ribbon" style={{ backgroundColor: this.state.inactiveClick ? '#1c3078' : '', borderRadius: this.state.inactiveClick ? '10px' : '', border: this.state.inactiveClick ? "2px solid grey" : '', boxShadow: this.state.inactiveClick ? '0 0 5px #555' : '' }}>
                                                <div className={`${!this.state.inactiveClick ? "ribbon-box orange" : "ribbon-box text-color white"} `}>{inactivecount}</div>
                                                <a className="my_sort_cut text-muted" href="#" onClick={this.inactive}>
                                                    <span className="dashbord_icons dashboard_icons_1">
                                                        <i className="fa fa-times" style={{ color: this.state.inactiveClick ? '#1c3078' : '' }}></i>
                                                    </span>
                                                    <span className='dash_total' style={{ color: this.state.inactiveClick ? 'white' : '' }}>Inactive</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="section-body  user_sec">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header tit_res">
                                                <h3 className="card-title">
                                                    <i className="icon-user mr-2"></i>
                                                    {languageData && languageData.USERS ? languageData.USERS : "Users"} {languageData && languageData.LIST ? languageData.LIST : 'List'}</h3>
                                                <div className="card-options add_card_options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <ul className="nav nav-tabs page-header-tab">
                                                        </ul>
                                                        <div className="header-action">
                                                            {Previlege?.add ? <button type="button" className="btn btn-primary add_btn_grid" data-toggle="modal" data-target="#exampleModal" id='Add' onClick={(e) => this.changeModal(e)}><i className="fe fe-plus mr-2 " id='Add' />Add</button> : ''}
                                                        </div>
                                                    </div>
                                                    {rowData && rowData.length > 0 ? <>
                                                        <a className="btn btn-primary btn-sm nav-link dropdown-toggle export_btn_grid" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2 " aria-hidden="true"></i>Export</a>
                                                        <div className="dropdown-menu">
                                                            <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                            <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                        </div>
                                                    </> : []}
                                                </div>
                                            </div>

                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-2">
                                                        <label className="form-label">Limit</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={this.onPerPageChange}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>

                                                    {
                                                        account?.allhotels == undefined &&
                                                        <div className="col-lg-6 col-md-6 col-sm-6 col-xl-6">
                                                            <label className="form-label">{languageData && languageData.SEARCH ? languageData.SEARCH : "Search"} {languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel"} </label>
                                                            <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                <Select
                                                                    value={this.state.searchhoteloption}
                                                                    onChange={this.searchUser}
                                                                    options={hotelOptionSearch}
                                                                    formatOptionLabel={(e) => (
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                            <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                        </div>
                                                                    )}
                                                                    className="custom_select"
                                                                />
                                                            </div>
                                                        </div>
                                                    }

                                                    <div className="col-lg-4 col-md-4 col-sm-4 col-xl-4">
                                                        <label className="form-label"> {languageData && languageData.USER ? languageData.USER : "User"} {languageData && languageData.CATEGORY ? languageData.CATEGORY : "Category"} </label>
                                                        <div className="form-group" style={{ marginBottom: '10px' }}>
                                                            <Select
                                                                value={this.state.searchcategoryoption ? { label: this.state.searchcategoryoption.label } : ''}
                                                                onChange={this.searchUser}
                                                                options={categoryOptions}
                                                                className="custom_select"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <div className={(rowData && rowData.length == 0) ? "ag-theme-alpine ag_grid_no_record_show" : "ag-theme-alpine"}>
                                                        <AgGridReact
                                                            key={this.state.perPage}
                                                            rowHeight={82}
                                                            paginationPageSize={this.state.perPage}
                                                            cacheBlockSize={this.state.perPage}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            //rowData={rowData}
                                                            onGridReady={this.onGridReady}
                                                            columnDefs={this.state.columnDefs}
                                                            rowDragManaged={true}
                                                            wrapText={true}
                                                            animateRows={true}
                                                            overlayNoRowsTemplate={"No rows to display"}
                                                            rowModelType={'infinite'}
                                                        >
                                                        </AgGridReact>
                                                    </div>

                                                    {
                                                        (rowData && rowData.length == 0)
                                                        &&
                                                        <span className='ag_grid_no_record_show_span' >No rows to display</span>
                                                    }
                                                    {(spinner || this.state.spinner) ?
                                                        <div className='common_loader_ag_grid'>
                                                            <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                            </Spinner>
                                                        </div>
                                                        : ""}
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="overlay" style={{ display: (spinner || this.state.spinner) ? 'block' : 'none' }}></div>
                        </div>
                    </div>
                </div>
                {/* Modal */}
                <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <div className='rounded_icon'><i className="icon-user mr-2"></i></div>
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} {languageData && languageData.USER ? languageData.USER : "User"}</h5>
                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body" style={{ padding: "10px 22px" }}>
                                <div className="row clearfix">
                                    {ImportedURL.SUPERADMIN.includes(account.role) ?
                                        <div className="col-md-12">
                                            <div className="form-group" style={ImportedURL.SUPERADMIN.includes(account.role) ? {} : { display: "inline-flex", fontSize: "18px" }}>
                                                <label className="form-label pt-3">Hotel{ImportedURL.SUPERADMIN.includes(account.role) ? <span className="ml-1" style={{ color: 'red' }}>*</span> : <span className="ml-1" style={{ paddingLeft: "20px", margin: "0px 36px" }}>:</span>}</label>
                                                {
                                                    account.allhotels?.length > 0 ?
                                                        <>
                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img src={ImportedURL.FILEURL + selectedHotel.logo} style={{ height: '50px', width: '50px', borderRadius: "50%", marginRight: "20px", objectFit: 'contain' }} onError={onErrorImage} />
                                                                <span className='all_search_text_size'>{selectedHotel.label}</span>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <Select
                                                                value={selectedHotel}
                                                                onChange={this.handleChange}
                                                                options={hotelOptions}
                                                                formatOptionLabel={(e) => (
                                                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                        {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                        <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                    </div>
                                                                )}
                                                            />

                                                        </>
                                                }
                                                <div className="invalid-feedback" style={{ display: this.state.hotelError ? "block" : 'none' }}>Hotel is required</div>
                                            </div>
                                        </div>
                                        : ''}
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">User Category{this.state.modalType == "View" ? <span>:</span> : <span style={{ color: "red" }}> *</span>}</label>

                                            <Select
                                                value={selectedCategory ? { label: selectedCategory.name, value: selectedCategory._id, name: 'category' } : ''}
                                                onChange={this.handleChange}
                                                options={newListCategories ? newListCategories.map(item => {
                                                    return { label: item.name, value: item._id, name: 'category', previleges: item.previleges, pmsprivileges: item.pmsprivileges }
                                                }) : ''}
                                            />

                                            <div className="invalid-feedback" style={{ display: this.state.categoryError ? "block" : 'none' }}>User Category is required</div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className=" text-left">
                                            <input
                                                type="checkbox"
                                                name="locationrestrict"
                                                className="custom-switch-input"
                                                checked={data.locationrestrict}
                                                onChange={this.handlePhoneRestrict}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Designation</label>
                                            <Select
                                                value={selectDesignationData ? { label: selectDesignationData.name } : ''}
                                                onChange={this.AddSelectHandleChange}
                                                options={DesignationArray.filter(filterItem => filterItem.status === true).map(item => {
                                                    return {
                                                        label: item?.name, value: item?._id, name: 'designation',
                                                    }
                                                })} />
                                            <div className="invalid-feedback" style={{ display: this.state.designationError ? "block" : 'none' }}>Designation is required</div>
                                        </div>
                                    </div>
                                    {this.state.isowner && ImportedURL.SUPERADMIN.includes(account.role) && <div className="form-group">
                                        <div className="custom-controls-stacked" style={{ marginLeft: "35px" }}>
                                            <label className="custom-control custom-switch" style={{ width: '150px' }}>
                                                <input type="checkbox" className="custom-control-input " name="example-checkbox1" value="option1" checked={this.state.existingowner} onChange={this.existingOwner} />
                                                <span className="custom-control-label" style={{ cursor: 'pointer' }}>Existing owner</span>
                                            </label>
                                        </div>
                                    </div>}
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">User Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                            <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='name' onChange={this.onChange} value={data.name} placeholder="User Name" />
                                            <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>User Name is required</div>
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            {this.state.existingowner ?
                                                <>
                                                    <label className="form-label">Email<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <Select
                                                        value={emailLabel ? { label: emailLabel.label, value: emailLabel.value, name: 'email' } : ''}
                                                        onChange={this.onChangeUSer}
                                                        options={this.state.ownerList ? this.state.ownerList.filter(filterItem => filterItem.status === true).map(item => {
                                                            return { label: item.email + ' ( ' + item.name + ' )', value: item.email, name: 'email' }
                                                        }) : ''}
                                                    />
                                                    <div className="invalid-feedback" style={{ display: this.state.owneremailValidError ? "block" : 'none' }}>Enter valid email</div>
                                                </> :
                                                <>
                                                    <label className="form-label">Email<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                    <input type="email" className="form-control" disabled={this.state.modalType == "View"} name='email' onInput={this.handleInputChange} value={data.email} placeholder="Email" />
                                                </>
                                            }
                                            <div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
                                            <div className="invalid-feedback" style={{ display: this.state.emailValidError ? "block" : 'none' }}>Enter valid email</div>
                                        </div>
                                    </div>

                                    <div className="col-md-4 col-sm-12 pr-0">
                                        <div className="form-group country_code">
                                            <label className="form-label">Phone #<span style={{ color: 'red' }}> *</span></label>
                                            <Select

                                                isDisabled={this.state.modalType == "View"}
                                                value={ccData ? ccData : ''}
                                                onChange={this.handlePhoneCode}
                                                options={phoneCode && phoneCode.map(item => {
                                                    return {
                                                        label: item.phonecode,
                                                        flag: item.flag,
                                                        value: item.code,
                                                        name: 'cc'
                                                    }
                                                })}
                                                formatOptionLabel={(item) => {
                                                    return (
                                                        <span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + item.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + item.label }} />
                                                    )
                                                }}
                                            />
                                            <div className="invalid-feedback" style={{ display: this.state.ccError ? "block" : 'none' }}>CC is required</div>
                                        </div>
                                    </div>
                                    <div className="col-md-8 pl-0">
                                        <div className="form-group">
                                            <label className="form-label"><span style={{ color: 'red' }}></span></label>
                                            <input type="text" className="form-control ml-1 num_res" disabled={this.state.modalType == "View"} name='phonenumber' onChange={this.onChange} value={data.phonenumber} placeholder="Phone #" />
                                            <div className="invalid-feedback" style={{ display: this.state.phonenumberError ? "block" : 'none' }}>Phone # is required</div>
                                            <div className="invalid-feedback" style={{ display: this.state.phoneNoPatternError ? "block" : 'none' }}>Phone # should contain 10 digits</div>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6 mt-2 mb-4">
                                        <div data-toggle="tooltip" data-placement="right" title={data.mandatory ? "Media attachment is mandatory for this user in ticket management" : "Media attachment is not mandatory for this user in ticket management"}>
                                            <label className="tooltiptext form-label">Ticket-Media Attachment</label>
                                            <label className="custom-switch">
                                                <input
                                                    type="checkbox"
                                                    checked={data.mandatory}
                                                    name="custom-switch-checkbox"
                                                    className="custom-switch-input"
                                                    onChange={this.setmadatoryUser}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <span className="custom-switch-indicator"></span>
                                                <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                                                    {data.mandatory ? "Mandatory" : "Non Mandatory"}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="text-left mt-2 mb-4">
                                            <label className="form-label">Location</label>
                                            <label className="custom-switch">
                                                <input
                                                    type="checkbox"
                                                    name="locationrestrict"
                                                    className="custom-switch-input"
                                                    checked={data.locationrestrict}
                                                    disabled={this.state.modalType == "View"}
                                                    onChange={this.handlePhoneRestrict}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <span className="custom-switch-indicator"></span>
                                                <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                                                    Location Restrict
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-sm-6 col-md-6">
                                        <div className="text-left mt-2 mb-4">
                                            <label className="form-label">Web Access</label>
                                            <label className="custom-switch">
                                                <input
                                                    type="checkbox"
                                                    name="locationrestrict"
                                                    className="custom-switch-input"
                                                    checked={data.webaccess}
                                                    disabled={this.state.modalType == "View"}
                                                    onChange={this.handleaccess}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <span className="custom-switch-indicator"></span>
                                                <span className="custom-switch-description" style={{ cursor: 'pointer' }}>
                                                    {data.webaccess ? "Allow" : "Deny"}
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="card-footer text-right mandatory">
                                <label className="form-label text-left mandatory-label" style={{ fontSize: "12px" }}><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                <div className="text-right d-flex">
                                    <div className="form-group d-flex" style={{ marginTop: '10px', marginRight: "5px" }}>
                                        {
                                            ImportedURL.SUPERADMIN.includes(account.role)
                                            &&
                                            <label className="custom-control custom-switch mr-3">
                                                <input type="checkbox" className="custom-control-input" name="mailstatus" checked={data.mailstatus} onChange={this.onChange} />
                                                <span className="custom-control-label" style={{ cursor: 'pointer', fontSize: "15px" }}>Send Mail</span>
                                            </label>
                                        }
                                    </div>
                                    <div  >
                                        {
                                            this.state.saving ?
                                                <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                :
                                                <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                        }
                                        <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>
                </div >
                <div className="modal fade" id="viewmodal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog view_user_modal" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <img className="avatar user_profile" src={data.logo ? ImportedURL.FILEURL + data.logo : '../assets/images/xs/avatar2.jpg'} data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" onError={onErrorImage} ></img>
                                <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{languageData && languageData.VIEW ? languageData.VIEW : "View"} {languageData && languageData.USER ? languageData.USER : "User"}</h5>
                                <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                        <img src='../../assets/images/cancel.png' />
                                    </span>
                                </button>
                            </div>
                            <div className="modal-body">
                                <div className='userView'>
                                    <div className='row'>
                                        <div className='col-lg-5'>
                                            <div className='User_part_left '>
                                                <div className='ribbon'>
                                                    {/* <button type='button' className='ribbon-box orange'>{selectedCategory.name}</button> */}
                                                    <div className="ribbon-box orange ">{selectedCategory.name}</div>
                                                </div>
                                                <div className='user_hotel_logo'>
                                                    <img src={ImportedURL.FILEURL + selectedHotel.logo} alt="fake_url" onError={onErrorImage} />
                                                </div>
                                                <div className='User_part_right'>
                                                    <p className='hotel_name'>{selectedHotel.label}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-lg-7 px-4'>
                                            <div className='User_part_right'>
                                                <label >User Name</label> <br />
                                                <span className='mb-4'>{data.name}</span>
                                                <p className='mt-4'><label>Email </label><br /><span className='ml-1'>{data.email}</span></p>
                                                {
                                                    data.phonenumber
                                                    &&
                                                    <p className='mt-4'>
                                                        <label>Phone #</label>  <br />
                                                        <span>{<><span dangerouslySetInnerHTML={{ __html: `<i class="flag flag-` + ccData.flag + `"data-toggle="tooltip" title="flag flag-ad"></i>` + ' ' + ccData.label }}></span> <span>{data.phonenumber}</span></>}</span>
                                                    </p>
                                                }
                                                {
                                                    data.statuschangedbyuser
                                                    &&
                                                    <p className='mt-4'>
                                                        <label>Status Inactive By</label>  <br />
                                                        <span>{data.statuschangedbyuser}</span>
                                                    </p>
                                                }
                                                {data.statuschangedbyadmin
                                                    &&
                                                    <p className='mt-4'>
                                                        <label>Status Inactive By</label>  <br />
                                                        <span>{data.statuschangedbyadmin}</span>
                                                    </p>
                                                }
                                                {data.designationName
                                                    &&
                                                    <p className='mt-4'>
                                                        <label>Designation</label>  <br />
                                                        <span>{data.designationName}</span>
                                                    </p>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id='overlay-model' style={{ display: this.state.viewSpin && Object.keys(data.name).length <= 0 ? 'block' : 'none' }}></div>
                            {this.state.viewSpin && Object.keys(data.name).length <= 0 ?
                                <div className='model_loader_poss'>
                                    <img className='loader_img_style_model' src='../../assets/images/load.png' />
                                    <Spinner className='spinner_load_model' animation="border" variant="info" >
                                    </Spinner>
                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
                {/* ====================Send Invite====================== */}
                <ModalStatus sendinvite={() => this.disableModal('sendinvite')} modalname="sendinvite" image="invitenew.png" content="Send Invite?" buttonok="Send" buttoncancel="Cancel"></ModalStatus >
                {/* ====================Previlege====================== */}
                <ModalStatus previlege={() => this.disableModal('previlege')} modalname="previlege" image="status.png" content="Are you sure to change the privilege to default?" buttonok="Ok" buttoncancel="Cancel"></ModalStatus >
                {/* ====================PMS Previlege====================== */}
                <ModalStatus pmsprevilege={() => this.disableModal('pmsprevilege')} modalname="pmsprevilege" image="status.png" content="Are you sure to change the PMS privilege to default?" buttonok="Ok" buttoncancel="Cancel"></ModalStatus >
                {/* ====================Location Restrict Modal====================== */}
                <ModalStatus statusChange={() => this.disableModal('statusChange')} modalname="statusChange" image="status.png" content="Are you sure to change the status?" buttonok="Ok" buttoncancel="Cancel"></ModalStatus >
                {/* ====================Location Restrict Modal====================== */}
                <ModalStatus locationRestrict={() => this.disableModal('locationRestrict')} modalname="locationRestrict" image="location.png" content="Are you sure to change the location restrict?" buttonok="Ok" buttoncancel="Cancel"></ModalStatus >
            </>
        )
    }
}

const mapStateToProps = state => ({
    UserState: state.user,
    AccountState: state.account,
    LandingState: state.landing,
})


function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleInputChange: AC_HANDLE_USER_CHANGE,
        AddUser: AC_ADD_USER,
        ListUser: AC_LIST_USER,
        ViewUser: AC_VIEW_USER,
        ResetUser: AC_RESET_USER,
        UpdateUser: AC_UPDATE_USER,
        DeleteUser: AC_DELETE_USER,
        EmptyUser: AC_EMPTY_USER,
        EmptyUserList: AC_EMPTY_USER_LIST,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListUser);
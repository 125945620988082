
const initialState = {
    listticketpriority: [],
    ticketpriority: {
        high: '',
        medium: '',
        low: '',
    },
    spinner: true,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_TICKET_PRIORITY_CHANGE':
            return Object.assign({}, state, {
                ticketpriority: {
                    ...state.ticketpriority,
                    [action.name]: action.value
                }
            })

        case 'LIST_TICKET_WORK_FLOW':
            return Object.assign({}, state, {
                listticketworkflows: action.payload,
                total: action.total,

            })
        case 'DEFAULT_TICKET_PRIORITY':
            return Object.assign({}, state, {
                ticketpriority: action.payload,
            })
        case 'VIEW_TICKET_PRIORITY':
            return Object.assign({}, state, {
                ticketpriority: action.payload,
                spinner: false,
            })
        case 'EMPTY_TICKET_PRIORITY':
            return Object.assign({}, state, {
                ticketpriority: {
                    high: '',
                    medium: '',
                    low: '',
                },
                spinner: true,
            })

        default:
            return state;
    }
}
import moment from 'moment';
import React, { Component } from 'react'
import ImportedURL from '../../common/api';

export default class Ticketpdf extends Component {
    separateArrayIntoGroups = (array, groupSize) => {
        const result = [];
        for (let i = 0; i < array.length; i += groupSize) {
            result.push(array.slice(i, i + groupSize));
        }
        return result;
    }
    render() {

        // Discussed with the team to show only 5 records due to pagination issue
        const { data, title, role } = this.props;
        let showhotel = ImportedURL.SUPERADMIN.includes(role) && title == 'Overall Maintenance Reports';
        let showhotelAnalytics = ImportedURL.SUPERADMIN.includes(role) && title == 'Ticket Analytics';
        let splitarr = [...data].splice(5)
        let groups = this.separateArrayIntoGroups(splitarr, 5);
        let pdf1 = [];

        for (let i = 0; i < data.length; i++) {
            if (i < 5) {
                pdf1.push(data[i])
            } else break
        }
        return (
            <>
                {/* <Document>
                    <Page> */}
                {/* <div id='print_pagepdf' > */}
                <div id='print_pagepdf' style={{ visibility: 'hidden', overflow: "hidden", height: 0 }}>
                    <div id='print_pagepdf1' >
                        <div className='card'>
                            <div className='card-body'>
                                <div className="header_pdf">
                                    <div className="company-details">
                                        <span className="company-email">
                                            {title}
                                        </span>
                                    </div>
                                    <div className='hotel-logo'>
                                        <img src={"../../assets/images/logo.png"} />
                                    </div>
                                </div>
                                <div className='pdf-table-custom'>
                                    <table class="pdf-table table-striped">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col" style={{ width: '8%' }}>Ticket #</th>
                                                {showhotel || showhotelAnalytics ? <th scope="col">Hotel</th> : ''}
                                                <th scope="col">Problem</th>
                                                <th scope="col">Description</th>
                                                <th scope="col">Room #</th>
                                                <th scope="col">Reported By</th>
                                                <th scope="col">Status</th>
                                                <th scope="col">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                (pdf1 && pdf1.length > 0)
                                                    ?
                                                    <>
                                                        {
                                                            pdf1.map((item, i) => {
                                                                var roomPlace = item.roomno != '' ? item.roomno : 'Other place';
                                                                return (
                                                                    <>
                                                                        <tr key={i} style={{ height: '83.3px' }}>
                                                                            <td style={{ width: '2%' }}>{i + 1}</td>
                                                                            <td style={{ width: '8%' }}>{item.ticketid ? item.ticketid : '---'}</td>
                                                                            {showhotel || showhotelAnalytics ? <td style={{ width: '9%' }}>{item.hotel ? item.hotel : '---'}</td> : ''}
                                                                            <td style={{ width: '13%' }}>{item.title ? item.title : '---'}</td>
                                                                            <td style={{ width: '40%' }}>{item.description ? item.description : '---'}</td>
                                                                            <td style={{ width: '4%' }}>{roomPlace}</td>
                                                                            <td style={{ width: '5%' }}>{item.reporter ? item.reporter : '---'}</td>
                                                                            <td style={{ width: '4%' }}>{item.status ? item.status : '---'}</td>
                                                                            <td style={{ width: '8%' }}>{item.time ? moment(item.time).format(this.props?.dtFormat) : '---'}</td>
                                                                        </tr>
                                                                    </>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                    : ''
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    {
                        groups.map((val, index) => {
                            return <div id={'print_pagepdf' + index + 2} >
                                <div className='card'>
                                    <div className='card-body'>
                                        <div className='pdf-table-custom'>
                                            <table class="pdf-table table-striped">
                                                <tbody>
                                                    {
                                                        (val && val.length > 0)
                                                            ?
                                                            <>
                                                                {
                                                                    val.map((item, i) => {
                                                                        var roomPlace = item.roomno != '' ? item.roomno : 'Other place';
                                                                        return (
                                                                            <>
                                                                                <tr key={i + index + 8} style={{ height: '83.3px' }}>
                                                                                    <td style={{ width: '2%' }}>{i + (showhotel || showhotelAnalytics ? 6 : 8) * (index + 1)}</td>
                                                                                    <td style={{ width: '8%' }}>{item.ticketid ? item.ticketid : '---'}</td>
                                                                                    {showhotel || showhotelAnalytics ? <td style={{ width: '9%' }}>{item.hotel ? item.hotel : '---'}</td> : ''}
                                                                                    <td style={{ width: '13%' }}>{item.title ? item.title : '---'}</td>
                                                                                    <td style={{ width: '40%' }}>{item.description ? item.description : '---'}</td>
                                                                                    <td style={{ width: '4%' }}>{roomPlace}</td>
                                                                                    <td style={{ width: '5%' }}>{item.reporter ? item.reporter : '---'}</td>
                                                                                    <td style={{ width: '4%' }}>{item.status ? item.status : '---'}</td>
                                                                                    <td style={{ width: '8%' }}>{item.time ? moment(item.time).format(this.props?.dtFormat) : '---'}</td>
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })
                                                                }
                                                            </>
                                                            : ''
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    }

                </div>
            </>
        )
    }
}


const initialState = {
    timezone: {
        name: '',
        value: '',
        abbr: '',
        text: '',
        utc: '',
        offset: '',
        timezone: {},
        status: 1,
    },
    listtimezone: [],
    spinner: true,
    total: 0,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case 'HANDLE_TIMEZONE_CHANGE':
            return Object.assign({}, state, {
                timezone: {
                    ...state.timezone,
                    [action.name]: action.value
                }
            })
        case 'RESET_TIMEZONE':
            return Object.assign({}, state, {
                timezone: {
                    name: '',
                    timezone: {},
                    status: 1,
                },
            })
        case 'LIST_TIMEZONE':
            return Object.assign({}, state, {
                listtimezone: action.payload,
                total: action.total,
                spinner: action.spinner
            })
        case 'VIEW_TIMEZONE':
            return Object.assign({}, state, {
                timezone: action.payload
            })
        default:
            return state;
    }
}
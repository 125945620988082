import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { AC_HANDLE_LANGUAGE_CHANGE, AC_LIST_LANGUAGE, AC_RESET_LANGUAGE, AC_VIEW_LANGUAGE, AC_EMPTY_LANGUAGE, AC_HANDLE_LANGUAGE_TABPOSITION_CHANGE } from '../../actions/languageAction';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import Select from 'react-select';
import jsPDF from 'jspdf';
import Spinner from 'react-bootstrap/Spinner';
import { Link, Redirect } from 'react-router-dom';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import { AC_ACCOUNT_DETAILS } from '../../actions/accountAction';
import ErrorResponse from '../../utilities/Error/errorstatus';
import showSwalDialog from '../../utilities/Modal/swal';

const initialState = {
    modalType: "Add",
    columnApi: '',
    gridApi: '',
    columnDefs: [
        { headerName: '#', valueGetter: "node.rowIndex+1", width: 50, sortable: false, filter: false, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', hide: 'true' },
        // {
        //     headerName: 'Logo', width: 100, field: 'hotel.logo', sortable: false, filter: false, "resizable": false, cellStyle: { 'text-align': 'center' },
        //     cellRenderer: function (params) {
        //         if (params.data.hotel) {
        //             return `<div class='logo_center_fix'><img src=${ImportedURL.FILEURL + params.data.hotel.logo} alt='' width='65px' height='65px' position : 'relative' class="contain_image"/></div>`;
        //         } else {
        //             return "---"
        //         }
        //     }
        // },
        // {
        //     headerName: 'Hotel', field: 'hotel.name', width: 130, floatingFilter: true, cellStyle: { 'text-align': 'left' },
        //     valueGetter: function (params) {
        //         if (params.data.hotel) {
        //             return params.data.hotel.name
        //         } else {
        //             return "---"
        //         }
        //     }
        // },
        { headerName: 'Name', field: 'name', width: 200, floatingFilter: true, headerClass: 'ag-center-header', },
        { headerName: 'Code', field: 'code', width: 200, floatingFilter: true, headerClass: 'ag-center-header', },
        {
            headerName: 'Status', width: 100, field: 'status', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header',
            cellRenderer: function (params) {
                if (params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else {
                    return '<span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                if (params.value) {
                    return params.value;
                } else {
                    return "---"
                }
            }
        },

    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true,
    },
    nameError: false,
    codeError: false,
    id: '',
    initial: true,
    saving: false,
    hotelError: false,
    searchhotel: '',
    searchhoteloption: '',
    hotelName: '',
    editRedirect: false,
}

class ListLanguage extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    changeModal = (e) => {
        this.props.ResetLanguage();
        this.setState({ modalType: e.target.id, nameError: false, codeError: false, hotelError: false })
        const { AccountState } = this.props;
        const account = AccountState.account;
        if (!ImportedURL.SUPERADMIN.includes(AccountState.role)) {
            this.props.HandleInputChange('hotel', account.hotel);
        }
    }
    onRowClicked = event => {
        const rowValue = event.event.target;
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const value = rowValue.getAttribute('data-action-type');
        if (value === 'Edit') {
            this.setState({ editRedirect: true, id: event.data._id, nameError: false, codeError: false, hotelError: false });
        }

        if (value === 'Delete') {
            showSwalDialog('Are you sure you want to delete?', '', 'Ok', 'Cancel', 'assets/images/delete.png').then((result) => {
                if (result.isConfirmed) {
                    this.setState({ searchhoteloption: '', searchhotel: '' })
                    axios.get(ImportedURL.API.softDeleteLanguage + '/' + event.data._id)
                        .then((res) => {
                            Success((languageData && languageData.LANGUAGE ? languageData.LANGUAGE : 'Language') + (languageData && languageData.NAME ? languageData.NAME : "name") + (languageData && languageData.DELETED_SUCCESSFULLY ? languageData.DELETED_SUCCESSFULLY : ' deleted successfully'));
                            this.props.ListLanguage({ languagestatus: event.data.languagestatus });
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
        if (value === 'Status') {
            showSwalDialog('Are you sure you want to change the status?', '', 'Ok', 'Cancel', 'assets/images/status.png').then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'languages' })
                        .then((data) => {
                            this.setState({ searchhoteloption: '', searchhotel: '' })
                            this.props.ListLanguage({ languagestatus: event.data.languagestatus });
                            Success((languageData && languageData.STATUS ? languageData.STATUS : ' Status') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : "updated successfully"))
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
    }

    onNormalChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false })
        this.props.HandleInputChange(name, value);
    }

    submit = () => {
        const { LanguageState, AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const data = LanguageState.language;
        let valid = 1;
        data['name'] = data.name.trim()
        data['code'] = data.code.trim()

        if (!data.name) {
            this.setState({ nameError: true });
            valid = 0;
        }
        if (!data.code) {
            this.setState({ codeError: true });
            valid = 0;
        }
        const tabposition = LanguageState.tabposition;
        if (tabposition == "Website") {
            data['languagestatus'] = 'Website'
        } else {
            data['languagestatus'] = 'Mobile'
        }
        if (valid) {
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addLanguage, data)
                    .then((res) => {
                        this.setState({ searchhoteloption: '', searchhotel: '', saving: false })
                        Success((languageData && languageData.LANGUAGE ? languageData.LANGUAGE : 'Language') + (languageData && languageData.CREATED_SUCCESSFULLY ? languageData.CREATED_SUCCESSFULLY : "created successfully"));
                        this.props.ListLanguage({ languagestatus: res.data.languagestatus });
                        this.props.ResetLanguage();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        if (response.status == 409) {
                            Error((languageData && languageData.LANGUAGE ? languageData.LANGUAGE : 'Language') + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : 'already exist'))
                        } else if (response.status == 400) {
                            Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                        }
                        else if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else {
                            Error(response.statusMessage)
                        }
                        this.setState({ saving: false })
                    });
            }
        }
    }

    componentDidMount() {
        this.props.HandleInputChange("reload", false);
        // this.props.HandleLanguageTabPosition('tabposition', 'Website');
        const tabposition = this.props.LanguageState.tabposition;
        this.props.ListLanguage({ languagestatus: tabposition });
        // this.props.EmptyLanguage()

    }
    onGridReady = (params) => {
        this.gridColumnApi = params.columnApi;
        this.gridApi = params.api;
        this.gridApi.sizeColumnsToFit();
    }
    exportToCSV = () => {
        const account = this.props.AccountState.account;
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: ['name', 'code'],
            fileName: "language" + "_" + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const account = this.props.AccountState.account;
        const api = this.gridApi;
        const filteredRows = api.getModel().rowsToDisplay
        const exportDataTemp = filteredRows.map(rowNode => rowNode.data);
        const firstRow = api.getFirstDisplayedRow();
        const lastRow = api.getLastDisplayedRow();
        const exportData = exportDataTemp.slice(firstRow, lastRow + 1);
        const selectedProperties = ['name', 'code'];
        const selected = exportData.map((item, i) => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    selectedData[property] = item[property] ? item[property] : '---';
                }
            });
            return selectedData;
        });
        let data = {
            title: "Language List",
            head: ['#', 'Name', "Code"],
            body: selected,
            hotelNameExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hotelNameExport : '',
            hoteladdressExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "language" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }
    searchUser = e => {
        const { name, value, label } = e;
        this.props.ListLanguage({ hotel: value });
        this.setState({ searchhoteloption: e, searchhotel: value, hotelName: label })
    }

    website = (e) => {
        this.props.HandleLanguageTabPosition('tabposition', 'Website');
        // this.props.HandleInputChange('languagestatus', 'Website');
        this.setState({ searchhoteloption: '' });
        this.props.EmptyLanguage()
        this.props.ListLanguage({ languagestatus: 'Website' });
    }
    mobile = (e) => {
        this.props.HandleLanguageTabPosition('tabposition', 'Mobile');
        // this.props.HandleInputChange('languagestatus', 'Mobile');
        this.setState({ searchhoteloption: '' });
        this.props.EmptyLanguage()
        this.props.ListLanguage({ languagestatus: 'Mobile' });
    }
    handleFilterChanged = () => {
        const api = this.gridApi;
        if (api && api.getDisplayedRowCount() === 0) {
            api.showNoRowsOverlay();
            this.setState({ hideExport: true })
        } else {
            api.hideOverlay();
            this.setState({ hideExport: false })
        }
    };
    render() {
        const { LanguageState, AccountState, HotelState } = this.props;
        const rowData = LanguageState.listLanguage;
        const data = LanguageState.language;
        const spinner = LanguageState.spinner;
        const account = AccountState.account;
        const tabposition = LanguageState.tabposition;
        const languageData = AccountState.account.SelectedlanguageData
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Language" });
        const deleteOption = ImportedURL.SUPERADMIN.includes(AccountState.role) ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : '';
        const editOption = Previlege?.edit ? '<button title="Edit" type="button" class="btn btn-icon" data-action-type="Edit" ><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
        const template =
            editOption
            + deleteOption
            + '</div>';
        if (rowData) {
            rowData.forEach(object => {
                object.actions = template;
            });
        }
        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]

        if (this.state.editRedirect) return <Redirect to={'edit-language/' + this.state.id} />
        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-language"><h6 className="highlights_breadcrump">{languageData && languageData.LANGUAGE ? languageData.LANGUAGE : "Language "}{languageData && languageData.LIST ? languageData.LIST : " List"}</h6></Link></p>
                        </div>
                        <div className="section-body user_sec">
                            <div className="container-fluid">
                                <div className="tab-content pt-3">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header tit_res">
                                                <div className='d-flex'>
                                                    <div className='rounded_icon'><i className="fa fa-language mr-2"></i></div>
                                                    <div className='d-flex align-items-center'>
                                                        <h3 className="card-title">{languageData && languageData.LANGUAGE ? languageData.LANGUAGE : "Language "}{languageData && languageData.LIST ? languageData.LIST : " List"}</h3>
                                                    </div>
                                                </div>
                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="header-action" >
                                                            {Previlege?.add ?
                                                                <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" id='Add' onClick={(e) => this.changeModal(e)}><i className="fe fe-plus mr-2" id='Add' />Add</button>
                                                                : ''}
                                                        </div>
                                                    </div>
                                                    {rowData && rowData.length > 0 && !this.state.hideExport ? <>
                                                        <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                        <div className="dropdown-menu">
                                                            <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                            <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                        </div>
                                                    </> : []}
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className='upcommcomp_btn'>
                                                    <div style={{ position: 'relative' }}>
                                                        <button type='button' className={`${tabposition == 'Website' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={this.website}>Website</button>
                                                    </div>
                                                    <div style={{ position: 'relative' }}>
                                                        <button type='button' className={`${tabposition == 'Mobile' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={this.mobile}>Mobile</button>
                                                    </div>
                                                </div>
                                                <div className="row mt-3">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                        <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={(e) => this.gridApi.paginationSetPageSize(Number(e.value))}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <div className="ag-theme-alpine">
                                                        <AgGridReact
                                                            rowHeight={82}
                                                            paginationPageSize={25}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            rowData={rowData}
                                                            columnDefs={this.state.columnDefs}
                                                            rowDragManaged={true}
                                                            animateRows={true}
                                                            onGridReady={this.onGridReady}
                                                            overlayNoRowsTemplate={"No rows to display"}
                                                            onFilterChanged={this.handleFilterChanged}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                    {spinner ?
                                                        <div className='common_loader_ag_grid'>
                                                            <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                            </Spinner>
                                                        </div>
                                                        : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div id="overlay" style={{ display: spinner ? 'block' : 'none' }}></div>
                        </div>
                    </div>
                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className='rounded_icon'><i className="fa fa-language mr-2"></i></div>
                                    <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} {languageData && languageData.LANGUAGE ? languageData.LANGUAGE : "Language"}</h5>
                                    <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                            <img src='../../assets/images/cancel.png' />
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row clearfix">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='name' onChange={this.onNormalChange} value={data.name} placeholder="Name" />
                                                <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Name is required</div>
                                            </div>
                                            <div className="form-group">
                                                <label className="form-label">Code<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <input type="text" className="form-control" disabled={this.state.modalType == "View"} name='code' onChange={this.onNormalChange} value={data.code.toLowerCase()} placeholder="Code" />
                                                <div className="invalid-feedback" style={{ display: this.state.codeError ? "block" : 'none' }}>Code is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.modalType == "Edit" ?
                                    <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="" >
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save " ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Update</button>
                                            }
                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                        </div>
                                    </div>
                                    : <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="" >
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save " ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                            }
                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
const mapStateToProps = state => ({
    LanguageState: state.language,
    AccountState: state.account,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleInputChange: AC_HANDLE_LANGUAGE_CHANGE, ListLanguage: AC_LIST_LANGUAGE, ViewLanguage: AC_VIEW_LANGUAGE, ResetLanguage: AC_RESET_LANGUAGE,
        AC_LIST_HOTEL,
        EmptyLanguage: AC_EMPTY_LANGUAGE,
        AccountDetails: AC_ACCOUNT_DETAILS,
        HandleLanguageTabPosition: AC_HANDLE_LANGUAGE_TABPOSITION_CHANGE
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListLanguage);
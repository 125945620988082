import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Success, Error } from '../../common/swal';
import axios from 'axios';
import ImportedUrl from '../../common/api';
import Carousel from 'react-bootstrap/Carousel';
import { Emailvalidate } from '../../common/validate';


export default class ForgotPassword extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			emailError: false,
		}
	}
	onChange = e => {
		const { name, value } = e.target;
		const Error = name + "Error";
		const ValidError = name + "ValidError";
		this.setState({ [Error]: false, [name]: value });
		if (name === 'email') {
			this.setState({ email: value });
			var email = value;
			if (email) {
				if (Emailvalidate(email)) {
					this.setState({ [ValidError]: false, [Error]: false })
				} else {
					this.setState({ [ValidError]: true })
				}
			}
			else {
				this.setState({ emailError: true, [ValidError]: false });
			}
		}
	}
	submit = (e) => {
		e.preventDefault()
		const { AccountState } = this.props;
		const { email, emailValidError } = this.state;
		if (!email) this.setState({ emailError: true });
		if (email && !emailValidError) {
			const formData = { email: email }
			axios.post(ImportedUrl.API.forgotPassword, formData)
				.then((res) => {
					Success('Check mail for new password');
					// Success(res.statusText);
					setTimeout(() => { window.location.href = "/" }, 2000);
				}).catch(({ response }) => {
					if (response.status == 401) {
						Error('Something wrong, Retry again!')
					} else if (response.status == 510) {
						Error('Email does not exit')
					}
					else if (response.status == 502) {
						Error(response.status + ' Bad Gateway')
					}
					else if (response.status == 500) {
						Error(' Internal Server Error')
					} else if (response.status == 400) {
						Error('Bad request')
					}
					// Error(response.statusText)
				});
		}
	}

	render() {
		return (
			<div className="auth">
				<div className="auth_left">
					<div className="card">
						<div className="text-center" style={{ width: '100px', height: '100px', margin: ' 0 auto' }}>
							<Link className="header-brand" to="/">
								<img className="avatar w-100 login_logo" src="../assets/images/sitelogo.png" data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" style={{ height: '100%', objectFit: 'cover' }} />
							</Link>
						</div>
						<div className="card-body">
							<div className="card-title mb-2">Forgot password</div>
							<p className="text-muted">
								Enter your email address and your password will be reset and emailed to you.
							</p>
							<div className="form-group">
								<label className="form-label" htmlFor="exampleInputEmail1">
									Email<span className="ml-1" style={{ color: 'red' }}>*</span>
								</label>
								<input
									type="email"
									className="form-control"
									id="exampleInputEmail1"
									aria-describedby="emailHelp"
									placeholder="Enter Email"
									onChange={this.onChange}
									name="email"
								/>
								<div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
								<div className="invalid-feedback" style={{ display: this.state.emailValidError ? 'block' : 'none' }}>Enter valid email</div>
							</div>
							<div className="form-footer">
								<button type="button" className="btn  login_btn btn-block" onClick={this.submit}><i className="fa fa-paper-plane mr-2"></i>Send</button>
								{/* <Link className="btn btn-primary btn-block" to="/login" >
								</Link> */}
							</div>
						</div>
						<div className="text-center text-muted">
							Forget it, <Link to="/login">Send me Back</Link>
						</div>
					</div>
				</div>
				<div className="auth_right">
					<Carousel>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider1.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Effective Property Maintenance simplified with myhotelai.com</h5>
								<p>Manage the day-to-day operations of your hotels with ease using AI-supported software.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider2.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Managing multiple hotels? myhotelai.com is here to assist you.</h5>
								<p>Ensuring the flawless operation of multiple hotels at the same time could be challenging.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider3.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Track and monitor the performance of your hotels with real-time data.</h5>
								<p>Enhance the efficiency and profitability of your hotels by gauging their <br /> performance with accurate, auto-fetched data.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider4.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Fetch accurate data on the effectiveness of ticket resolution in your hotels</h5>
								<p>Catalyze the ticket resolution process with an automated workflow.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider5.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Assess the performance of your assets accurately with periodic reports.</h5>
								<p>Build a culture of data-based decision-making to give your customers a great stay.</p>
							</div>
						</Carousel.Item>
					</Carousel>
				</div>
			</div>
		);
	}
}

import React, { Component } from 'react';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ImportedURL from '../../common/api';
import jsPDF from 'jspdf';
import Spinner from 'react-bootstrap/Spinner';
import { Error, Success } from '../../common/swal';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { IoIosTimer } from "react-icons/io";
import { AC_HANDLE_TIMEZONE_CHANGE, AC_LIST_TIMEZONE, AC_RESET_TIMEZONE, AC_VIEW_TIMEZONE } from '../../actions/timeZoneAction';
import timezoneData from '../../common/timezones.json';
import ErrorResponse from '../../utilities/Error/errorstatus';
import showSwalDialog from '../../utilities/Modal/swal';

const initialState = {
    modalType: "Add",
    columnApi: '',
    gridApi: '',
    columnDefs: [
        { headerName: 'Name', field: 'value', width: 200, floatingFilter: true, headerClass: 'ag-center-header' },
        { headerName: 'Code', field: 'abbr', width: 200, floatingFilter: true, headerClass: 'ag-center-header' },
        {
            headerName: 'Status', width: 100, field: 'status', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true,
            cellRenderer: function (params) {
                if (params.data && params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else if (params.value == 0) {
                    return '<span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                return params.value;
            }
        },

    ],
    defaultColumDef: {
        "width": 200,
        "filter": true,
        "sortable": true,
        "resizable": true,
    },
    nameError: false,
    perPage: 25
}
class ListTimeZone extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    changeModal = (e) => {
        this.props.ResetTimeZone();
        this.setState({ modalType: e.target.id, nameError: false, });
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (value === 'Edit') {
            this.setState({ modalType: "Edit", id: event.data._id, nameError: false });
            this.props.ViewTimeZone(event.data._id);
        }

        if (value === 'Delete') {
            showSwalDialog('Are you sure you want to delete?', '', 'Ok', 'Cancel', 'assets/images/delete.png').then((result) => {
                if (result.isConfirmed) {
                    axios.get(ImportedURL.API.softDeleteTimezone + '/' + event.data._id)
                        .then((res) => {
                            Success((languageData && languageData.TIME_ZONE ? languageData.TIME_ZONE : "Time Zone") + (languageData && languageData.DELETED_SUCCESSFULLY ? languageData.DELETED_SUCCESSFULLY : " deleted successfully"));
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData, "hotels")
                        });
                }
            })
        }
        if (value === 'Status') {
            showSwalDialog('Are you sure you want to change the status?', '', 'Ok', 'Cancel', 'assets/images/status.png').then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'timezones' })
                        .then((data) => {
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);
                            Success((languageData && languageData.STATUS ? languageData.STATUS : "Status") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " updated successfully"))
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
    }
    handleChange = e => {
        const { name, value } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        if (value != undefined) {
            this.props.HandleInputChange("value", value.value);
            this.props.HandleInputChange("abbr", value.abbr);
            this.props.HandleInputChange("text", value.text);
            this.props.HandleInputChange("utc", value.utc);
            this.props.HandleInputChange("offset", value.offset);
        }
    }
    submit = () => {
        const { TimeZoneState, AccountState } = this.props;
        const data = TimeZoneState.timezone;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        let valid = 1;
        if (!data.value) {
            this.setState({ nameError: true });
            valid = 0;
        }
        if (valid) {
            this.setState({ saving: true })
            if (this.state.modalType === "Add") {
                axios.post(ImportedURL.API.addTimezone, data)
                    .then((res) => {
                        this.setState({ saving: false })
                        Success((languageData && languageData.TIME_ZONE ? languageData.TIME_ZONE : "Time Zone") + (languageData && languageData.CREATED_SUCCESSFULLY ? languageData.CREATED_SUCCESSFULLY : " Created Successfully"));
                        // Success(res.statusText);
                        const dataSource = this.getDataSource();
                        this.gridApi.setDatasource(dataSource);
                        this.props.ResetTimeZone();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        if (response.status == 409) {
                            Error((languageData && languageData.TIME_ZONE ? languageData.TIME_ZONE : "Time Zone") + (languageData && languageData.ALREADY_EXIT ? languageData.ALREADY_EXIT : " Already exist"))
                        } else if (response.status == 400) {
                            Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                        }
                        else if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else {
                            Error(response.statusMessage)
                        }
                        this.setState({ saving: false })
                    });
            } else {
                axios.post(ImportedURL.API.updateTimezone + '/' + this.state.id, data)
                    .then((res) => {
                        this.setState({ saving: false })
                        Success((languageData && languageData.TIME_ZONE ? languageData.TIME_ZONE : "Time Zone") + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " Updated Successfully"));
                        const dataSource = this.getDataSource()
                        this.gridApi.setDatasource(dataSource);
                        this.props.ResetTimeZone();
                        let btn = document.getElementById("closeModal");
                        btn.click();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        if (response.statusMessage == 400) {
                            Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                        } else if (response.status == 409) {
                            Error((languageData && languageData.TIME_ZONE ? languageData.TIME_ZONE : "Time Zone") + (languageData && languageData.ALREADY_EXIT ? languageData.ALREADY_EXIT : " Already exist"))
                        }
                        else if (response.status == 500) {
                            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                        } else if (response.status == 502) {
                            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                        } else {
                            Error(response.statusMessage)
                        }
                    });
            }
        }
    }

    componentDidMount() {
        const account = this.props.AccountState.account;
        this.setState({ hotelName: account.hotelName ? account.hotelName : '' });
    }

    exportToCSV = () => {
        const account = this.props.AccountState.account;
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: ['value', 'abbr'],
            fileName: 'timezone' + "_" + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                return params.value;
            }
        })
    }

    exportPDF = () => {
        const account = this.props.AccountState.account;
        const exportData = this.props.TimeZoneState.listtimezone;
        const selectedProperties = ['value', 'abbr'];
        const selected = exportData.map((item, i) => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    selectedData[property] = item[property] ? item[property] : '---';
                }
            });
            return selectedData;
        });
        let data = {
            title: "Time Zone List",
            head: ['#', 'Name', 'Code'],
            body: selected,
            hotelNameExport: account.hotelNameExport ? account.hotelNameExport : '',
            hoteladdressExport: account.hoteladdressExport ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "timezone" + "_" + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }
    // Data source to set data in ag grid
    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {

                // Grid params for filter and sort
                const page = Math.floor(params.startRow / this.state.perPage);
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                // Action call for inspection list
                await this.props.ListTimeZone({ perPage: this.state.perPage, page: page, filter: filter, sort: sortModel.length ? sortModel[0] : {}, ...query });
                const rowData = this.props.TimeZoneState.listtimezone;
                const total = this.props.TimeZoneState.total;
                const { AccountState } = this.props;
                const previleges = AccountState.previleges;
                const Previlege = previleges.find(obj => { return obj.name == "Time_Zone" });
                const editOption = Previlege?.edit ? '<button type="button" class="btn btn-icon" data-toggle="modal" data-target="#exampleModal" data-action-type="Edit"><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
                const deleteOption = ImportedURL.SUPERADMIN.includes(AccountState.role) ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : '';
                const template = editOption + deleteOption;
                rowData.forEach(object => {
                    object.actions = template;
                });
                const lbLastRowOnPageEl = document.querySelector(`[ref=\'lbLastRowOnPage\']`);
                if (lbLastRowOnPageEl) {
                    lbLastRowOnPageEl.innerHTML = (rowData) ? rowData.length + params.startRow : 0;
                }
                params.successCallback(rowData, total);

            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        // To set data once grid api is updated
        if (this.gridApi) {
            if (this.props.AccountState.previleges && prevState.gridApi !== this.state.gridApi) {
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
            }
        }
        if (prevState.perPage !== this.state.perPage) {
            this.refreshGridDataSource();
        }
    }

    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
        this.refreshGridDataSource();
    };

    refreshGridDataSource = (params) => {
        const newDataSource = this.getDataSource(params);
        if (this.gridApi) {
            this.gridApi.setDatasource(newDataSource);
        }
    };

    onPerPageChange = (newPerPage) => {
        this.setState({ perPage: newPerPage })
        this.refreshGridDataSource({ perPage: newPerPage });
    };

    render() {
        const { AccountState, TimeZoneState } = this.props;
        const rowData = TimeZoneState.listtimezone;
        var data = TimeZoneState.timezone;
        const languageData = AccountState.account.SelectedlanguageData
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Time_Zone" });

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]
        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-time-zone"><h6 className="highlights_breadcrump">{languageData && languageData.TIME_ZONE ? languageData.TIME_ZONE : "Time Zone "}{languageData && languageData.LIST ? languageData.LIST : " List"}</h6></Link></p>
                        </div>
                        <div className="section-body pt-3 user_sec">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header tit_res">
                                                <div className='d-flex'>
                                                    <div className='rounded_icon'><IoIosTimer className='rounded_icon_r' /></div>
                                                    <div className='d-flex align-items-center'>
                                                        <h3 className="card-title">{languageData && languageData.TIME_ZONE ? languageData.TIME_ZONE : "Time Zone  "}{languageData && languageData.LIST ? languageData.LIST : " List"}</h3>
                                                    </div>
                                                </div>

                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="header-action" >
                                                            {Previlege?.add ? <button type="button" className="btn btn-primary" data-toggle="modal" data-target="#exampleModal" id='Add' onClick={(e) => this.changeModal(e)}><i className="fe fe-plus mr-2" id='Add' />Add</button> : ''}
                                                        </div>
                                                    </div>
                                                    {rowData && rowData.length > 0 ?
                                                        <>
                                                            <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                            <div className="dropdown-menu">
                                                                <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                            </div>
                                                        </>
                                                        : ''}
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                        <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={(e) => this.onPerPageChange(Number(e.value))}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="table-responsive">
                                                    <div className={(rowData && rowData.length == 0) ? "ag-theme-alpine ag_grid_no_record_show" : "ag-theme-alpine"}>
                                                        <AgGridReact
                                                            key={this.state.perPage}
                                                            rowHeight={82}
                                                            paginationPageSize={this.state.perPage}
                                                            cacheBlockSize={this.state.perPage}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            // rowData={rowData}
                                                            onGridReady={this.onGridReady}
                                                            columnDefs={this.state.columnDefs}
                                                            rowDragManaged={true}
                                                            wrapText={true}
                                                            animateRows={true}
                                                            overlayNoRowsTemplate={"No rows to display"}
                                                            rowModelType={'infinite'}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                    {
                                                        (rowData && rowData.length == 0)
                                                        &&
                                                        <span className='ag_grid_no_record_show_span' >No rows to display</span>
                                                    }
                                                    <div id="overlay" style={{ display: this.state.spinner ? 'block' : 'none' }}></div>
                                                    {this.state.spinner ?
                                                        <div className='common_loader_ag_grid'>
                                                            <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                            </Spinner>
                                                        </div> : ""}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal */}
                    <div className="modal fade" id="exampleModal" tabIndex={-1} role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog" role="document">
                            <div className="modal-content">
                                <div className="modal-header">
                                    <div className='rounded_icon'><IoIosTimer className='rounded_icon_r' /></div>
                                    <h5 className="modal-title" id="exampleModalLabel" style={{ marginTop: '5px' }}>{this.state.modalType} {languageData && languageData.TIME_ZONE ? languageData.TIME_ZONE : "Time Zone"}</h5>
                                    <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                        <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                            <img src='../../assets/images/cancel.png' />
                                        </span>
                                    </button>
                                </div>
                                <div className="modal-body">
                                    <div className="row clearfix">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label className="form-label">Time Zone Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                <Select
                                                    className="react-select"
                                                    styles={{ border: '1px solid #868686' }}
                                                    isDisabled={this.state.modalType == "View"}
                                                    value={(data.abbr) ? { label: `${data.abbr} ${"( " + data.value + " )"} ` } : ''}
                                                    onChange={this.handleChange}
                                                    options={timezoneData.map(item => {
                                                        return { label: `${item.abbr} ${"( " + item.value + " )"} `, value: item, name: 'timezone' }
                                                    })}
                                                />
                                                <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}> Time Zone Name is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {this.state.modalType == "Edit" ?
                                    <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="" >
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Update</button>
                                            }
                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                        </div>
                                    </div>
                                    : <div className="card-footer text-right mandatory">
                                        <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                        <div className="" >
                                            {
                                                this.state.saving ?
                                                    <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                    :
                                                    <button type="button" className="btn commor_save" onClick={this.submit}><i className="fe fe-save mr-2"></i>Save</button>
                                            }
                                            <button type="button" className="btn btn-secondary" style={{ marginRight: '10px' }} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Close</button>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    TimeZoneState: state.timezone
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({ ResetTimeZone: AC_RESET_TIMEZONE, HandleInputChange: AC_HANDLE_TIMEZONE_CHANGE, ListTimeZone: AC_LIST_TIMEZONE, ViewTimeZone: AC_VIEW_TIMEZONE }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListTimeZone);
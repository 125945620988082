import React, { Component } from "react";
import { connect } from "react-redux";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { bindActionCreators } from "redux";
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import { AC_SETTING_ROOMTYPE_HANDLE_CHANGE, AC_RESET_SETTING_ROOMTYPE, AC_VIEW_SETTING_ROOMTYPE, AC_EMPTY_ROOMTYPE, AC_SETTING_ROOMTYPE_HANDLE_FORM_CHANGE, AC_HANDLE_MULTIROW_CLICK, AC_HANDLE_ROOMTYPEBED_UPDATE, AC_LIST_SETTING_ROOMTYPE } from '../../actions/settingRoomtypeAction';
import { Error, Success } from "../../common/swal";
import { Offcanvas, Row, Spinner } from 'react-bootstrap';
import { CapitalizeFirstLetterWithoutSpace, Textareavalidation, onErrorImage } from "../../common/validate";
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import ImportedURL from "../../common/api";
import Ckeditor from "react-ckeditor-component/lib/ckeditor";
import Dropzone from "react-dropzone";
import { AC_LIST_SETTING_ROOMAREA_AMENITY, AC_HANDLE_KEY_SEARCH } from '../../actions/roomareaAmenityAction';
import Amenitycontent from '../SettingRoomtypeComponents/amenitycontent';
import { AC_LIST_CONFIGURATION, AC_LIST_CONFIGOPTIONS, AC_LIST_CONFIGOPTIONS_BED_TYPE, AC_LIST_CONFIGOPTIONS_MATTRESS } from '../../actions/configurationAction';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    arrayMove,
} from 'react-sortable-hoc';
import { AC_SETTING_ROOM_HANDLE_CHANGE } from "../../actions/settingroomAction";
import Swal from "sweetalert2";
import { SketchPicker } from 'react-color';
import reactCSS from 'reactcss';
import showSwalDialog from "../../utilities/Modal/swal";

const DragHandle = SortableHandle(() => <span><i class="fa fa-arrows" aria-hidden="true"> </i></span>);
const SortableItem = SortableElement(({ value, index, onDeleteHandler }) => {
    let imgValue = ''
    if (typeof value == "string") {
        imgValue = ImportedURL.FILEURL + value
    } else {
        imgValue = window.URL.createObjectURL(value)
    }
    return (
        <li>
            <div className="modal_images_design">
                <div key={index}>
                    <div className="roomtype-image-div-M0odal">
                        <img src={imgValue} alt='' onError={onErrorImage} />
                    </div>
                </div>
                <div className="menu_imagews_drag">
                    <div>
                        <DragHandle />
                    </div>
                    <div>
                        <i class="fa fa-trash" aria-hidden="true" onClick={() => onDeleteHandler(value)}></i>
                    </div>
                </div>
            </div>
        </li>
    );
});

const SortableList = SortableContainer(({ items, onDeleteHandler }) => {
    return (
        <ul style={{ listStyleType: 'none' }}>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} index={index} value={value} sortIndex={index} onDeleteHandler={onDeleteHandler} />
            ))}
        </ul>
    );
});

class AddRoom extends Component {
    constructor(props) {
        super(props);
        this.fileInput = React.createRef();
        this.state = {
            modalType: "Add",
            id: "",
            from: "",
            to: "",
            imageArray: [],
            existingImage: [],
            initial: true,
            files: [],
            saving: false,
            addRedirect: false,
            listRedirect: false,
            editFilesList: [],
            bednameArrayError: [],
            mattressnameArrayError: [],
            quantityArrayError: [],
            showcanvas: false
        };
        this.hotelInputRef = React.createRef();
        this.nameInputRef = React.createRef();
        this.roomtypeInputRef = React.createRef();
        this.areaInputRef = React.createRef();
        this.maxoccupnacyInputRef = React.createRef();
        this.defaultoccupnacyInputRef = React.createRef();
        this.adultsInputRef = React.createRef();
        this.childrenInputRef = React.createRef();
        this.perferredInputRef = React.createRef();
        this.bedroomInputRef = React.createRef();
        this.mattressInputRef = React.createRef();
        this.quantityInputRef = React.createRef();
    }
    componentWillUpdate(nextProps) {
        const newaccount = nextProps.AccountState.account;

        if (this.state.initial) {
            if (newaccount) {
                this.props.SettingRoomtypeHandleChange('hotel', newaccount.hotel)
            }
            this.setState({ initial: false })
        }
    }
    onHandleChange = e => {
        const data = this.props.SettingRoomtypeState.room;
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false })
        if (name == 'description') {
            var textCount = e.target.value;
            textCount.trim()
            var textareaLimit = textCount.substring(0, 255);
            let resultObj = Textareavalidation(textareaLimit);
            this.props.SettingRoomtypeHandleChange(name, resultObj.value);
            this.setState({ descriptionError: resultObj.notextErr, textarealimitError: resultObj.textlimitErr })
        }
        if (name == 'roomname') {
            this.props.SettingRoomtypeHandleChange(name, value);
        }
        if (name == 'actype') {
            this.props.SettingRoomtypeHandleChange(name, value);
        }
        if (name == 'maxoccupancy') {
            var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                this.setState({ [Error]: false });
                this.props.SettingRoomtypeHandleChange(name, val);
                this.props.SettingRoomtypeHandleChange('defaultoccupancy', '');
                this.props.SettingRoomtypeHandleChange('adults', '');
                this.props.SettingRoomtypeHandleChange('children', '');
                this.setState({ defaultError: false, minimumadultError: false, valueadultsError: false, valuechildrenError: false, valueDefaultError: false });
            } else {
                this.props.SettingRoomtypeHandleChange(name, val);
            }
        }
        if (name == 'defaultoccupancy') {
            var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                if (parseInt(data.maxoccupancy) >= parseInt(val)) {
                    this.setState({ [Error]: false });
                    this.setState({ valueDefaultError: false, defaultError: false, minimumadultError: false, valueadultsError: false, valuechildrenError: false, });
                    if (parseInt(val) > 0) this.props.SettingRoomtypeHandleChange('defaultoccupancy', val);
                    else this.props.SettingRoomtypeHandleChange('defaultoccupancy', "");
                } else {
                    this.setState({ valueDefaultError: true });
                    this.props.SettingRoomtypeHandleChange('defaultoccupancy', '')
                }
            } else {
                this.props.SettingRoomtypeHandleChange('defaultoccupancy', '');
            }
        }
        if (name == 'articlenumber' || name == 'builtunit') {
            var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                this.setState({ [Error]: false });
                this.props.SettingRoomtypeHandleChange(name, val);
            } else {
                this.props.SettingRoomtypeHandleChange(name, val);
            }
        }
        if (name == 'children') {
            var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                if (parseInt(data.maxoccupancy) > parseInt(val)) {
                    this.props.SettingRoomtypeHandleChange(name, val);
                    this.setState({ [Error]: false });
                    this.setState({ valuechildrenError: false });
                } else {
                    this.props.SettingRoomtypeHandleChange(name, '');
                    this.setState({ valuechildrenError: true });
                }
            } else {
                this.props.SettingRoomtypeHandleChange(name, '');
            }
        }
        if (name == 'adults') {
            var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
            if (val) {
                if (parseInt(data.maxoccupancy) >= parseInt(val)) {
                    this.setState({ [Error]: false, valueadultsError: false, valuechildrenError: false });
                    if (parseInt(val) > 0) {
                        this.props.SettingRoomtypeHandleChange(name, val);
                        this.setState({ valueadultsError: false });
                    }
                } else {
                    this.props.SettingRoomtypeHandleChange(name, '');
                    this.setState({ valueadultsError: true });
                }
            } else {
                this.props.SettingRoomtypeHandleChange(name, '');
            }
        }
    }
    componentDidMount() {
        this.props.EmptyRoomType();
        this.props.ListHotel();
        this.props.ListConfigOption({ parentslug: 'room-type' });
        this.props.ListBedTypeConfigOption({ parentslug: 'room-type-beds' });
        this.props.ListMattressConfigOption({ parentslug: 'mattress' });
        this.props.ListSettingRoomareaAmenity();
        const { params, path } = this.props.match;
        if (params.id) {
            this.props.ViewRoomType(params.id)
            this.setState({ modalType: path === "/view-setting-roomtype/:id" ? "View" : "Edit", id: params.id })
            this.props.HandleInputChange('tabposition', 'roomtype');
        } else {
            this.props.ResetRoomtype();
            this.props.SettingRoomtypeHandleChange("actype", "AC");
            this.props.SettingRoomtypeHandleChange('colorcode', '#E19B64');
        }
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
    HotelChange = e => {
        const { name, value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        if (name == 'hotel') {
            this.props.SettingRoomtypeHandleChange(name, value);
        }
        if (name == 'roomtype') {
            var ConfigOptionData = this.props.ConfigurationState.listConfigOptions;
            let data = ConfigOptionData.find((e) => e._id == value)
            this.props.SettingRoomtypeHandleChange(name, value);
            this.props.SettingRoomtypeHandleChange("roomname", data.name);
            this.setState({ roomnameError: false });
        }
    }
    submit = e => {
        const data = this.props.SettingRoomtypeState.room;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        }
        let isValid = true;
        if (data.itemvalue && data.itemvalue.length > 0) {
            let bednameArray = []
            let mattressnameArray = []
            let quantityArray = []
            data.itemvalue.map((item, i) => {
                if (!item.bedname) {
                    bednameArray.push(i)
                    isValid = false;
                    this.bedroomInputRef.current.focus();
                }
                if (!item.mattressname) {
                    mattressnameArray.push(i)
                    isValid = false;
                    this.mattressInputRef.current.focus();
                }
                if (!item.quantity) {
                    quantityArray.push(i)
                    isValid = false;
                    this.quantityInputRef.current.focus();
                }
            })
            this.setState({ bednameArrayError: bednameArray, mattressnameArrayError: mattressnameArray, quantityArrayError: quantityArray })
        }
        // if (!data.actype) {
        //     this.setState({ actypeError: true })
        //     isValid = false;
        //     this.perferredInputRef.current.focus();
        // }
        if (!data.children) {
            this.setState({ childrenError: true })
            isValid = false;
            this.childrenInputRef.current.focus();
        }
        if (!data.adults) {
            this.setState({ adultsError: true })
            isValid = false;
            this.adultsInputRef.current.focus();
        }
        if (!data.defaultoccupancy) {
            this.setState({ defaultoccupancyError: true })
            isValid = false;
            this.defaultoccupnacyInputRef.current.focus();
        }
        if (!data.maxoccupancy) {
            this.setState({ maxoccupancyError: true })
            isValid = false;
            this.maxoccupnacyInputRef.current.focus();
        }
        if (this.state.valueadultsError || this.state.valuechildrenError || this.state.valueDefaultError || this.state.valueadultsError || this.state.defaultError) {
            isValid = false;
            this.maxoccupnacyInputRef.current.focus();
        }
        if (!data.builtunit) {
            this.setState({ builtunitError: true })
            isValid = false;
            this.areaInputRef.current.focus();
        }
        if (!data.roomtype) {
            this.setState({ roomtypeError: true })
            isValid = false;
            this.roomtypeInputRef.current.focus();
        }
        if (!data.roomname) {
            this.setState({ roomnameError: true })
            isValid = false;
            this.nameInputRef.current.focus();
        }
        if (!data.hotel) {
            this.setState({ hotelError: true })
            isValid = false;
            this.hotelInputRef.current.focus();
        }

        const formData = new FormData();
        for (let key in data) {
            if (key === 'roomimage') continue;
            else if (key === 'amenity') continue;
            else if (key === 'itemvalue') continue;
            else formData.append(key, data[key])
        }
        if (data.amenity && data.amenity.length > 0) {
            data.amenity.forEach((item, index) => {
                if (item) {
                    formData.append(`amenity[${index}][amenity]`, item.amenity);
                    formData.append(`amenity[${index}][status]`, item.status);
                }
            });
        }
        if (data.itemvalue && data.itemvalue.length > 0) {
            data.itemvalue.forEach((item, index) => {
                if (item) {
                    formData.append(`itemvalue[${index}][bedname]`, item.bedname ? item.bedname : '');
                    formData.append(`itemvalue[${index}][width]`, item.width ? item.width : '');
                    formData.append(`itemvalue[${index}][length]`, item.length ? item.length : '');
                    formData.append(`itemvalue[${index}][mattressname]`, item.mattressname ? item.mattressname : '');
                    formData.append(`itemvalue[${index}][quantity]`, item.quantity ? item.quantity : '');
                }
            });
        }
        if (this.state.modalType == "Edit") {
            let img = (this.state.files && this.state.files.length > 0) ? this.state.files : data.roomimage
            for (let file of img) {
                if (typeof file == "string") formData.append("roomimageExist", file)
                else formData.append("roomimage", file)
            }
        } else {
            if (this.state.files.length) {
                if (this.state.existingImage.length) {
                    var sendData = this.state.files.concat(this.state.existingImage)
                    for (const file of sendData) {
                        formData.append('roomimage', file);
                    };
                }
                else {
                    for (const file of this.state.files) {
                        formData.append('roomimage', file);
                    };
                }
            } else {
                if (data.roomimage) {
                    formData.append('roomimage', JSON.stringify(data.roomimage));
                }
            }
        }
        if (isValid) {
            if (this.state.modalType == "Add") {
                this.setState({ saving: true })
                axios.post(ImportedURL.API.addSettingRoomtype, formData)
                    .then((res) => {
                        this.setState({ saving: false })
                        Success((languageData && languageData.ROOM_TYPE_SETTINGS ? CapitalizeFirstLetterWithoutSpace(languageData.ROOM_TYPE_SETTINGS) : "Roomtype") + (languageData && languageData.CREATED_SUCCESSFULLY ? (languageData.CREATED_SUCCESSFULLY) : " created successfully"));
                        this.setState({ addRedirect: true })
                        this.props.HandleInputChange('tabposition', 'rooms');
                        this.props.HandleInputChangeRoomNumber('roomtype', res.data._id);
                        this.props.HandleInputChangeRoomNumber('hotel', data.hotel);
                        this.props.HandleInputChangeRoomNumber('roomtypeCondition', true);
                        this.props.ListSettingRoomType();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        this.ErrorResponse(response)
                    });
            } else {
                this.setState({ saving: true })
                axios.post(ImportedURL.API.updateSettingRoomtype + '/' + data._id, formData)
                    .then((res) => {
                        this.setState({ saving: false })
                        Success((languageData && languageData.ROOM_TYPE_SETTINGS ? CapitalizeFirstLetterWithoutSpace(languageData.ROOM_TYPE_SETTINGS) : "Roomtype") + (languageData && languageData.UPDATED_SUCCESSFULLY ? (languageData.UPDATED_SUCCESSFULLY) : " Updated Successfully"))
                        this.setState({ listRedirect: true })
                        this.props.HandleInputChange('tabposition', 'roomtype');
                        this.props.ListSettingRoomType();
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        this.ErrorResponse(response)
                    });
            }
        }
    }
    ErrorResponse = (response) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (response.status == 500) {
            Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
        } else if (response.status == 400) {
            Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
        } else if (response.status == 502) {
            Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
        } else if (response.status == 409) {
            Error((languageData && languageData.ROOM_TYPE_SETTINGS ? CapitalizeFirstLetterWithoutSpace(languageData.ROOM_TYPE_SETTINGS) : 'Roomtype') + (languageData && languageData.ALREADY_EXIST ? languageData.ALREADY_EXIST : ' already exist'))
            setTimeout(() => {
                this.nameInputRef.current.focus();
            }, 10);
        } else {
            Error(response.statusMessage)
        }
    }
    onChangeBedtype = e => {
        const { name, value } = e.target;
        const Error = name + 'Error';
        this.setState({ [Error]: false });
        this.props.HandleInputChange(name, value)
    }
    submitBedRoom = e => {
        const data = this.props.SettingRoomtypeState.room;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        }
        let valid = 1;
        if (!data.bedname) {
            valid = 0;
            this.setState({ bednameError: true });
        }
        if (valid) {
            var formData = {
                name: data.bedname,
            }
            this.setState({ saving: true })
            axios.post(ImportedURL.API.addConfigurationBedroomtype, formData)
                .then((res) => {
                    this.props.HandleInputChange('bedname', '')
                    this.setState({ saving: false, showcanvas: false })
                    Success("Bed type added successfully");
                    this.props.ListBedTypeConfigOption({ parentslug: 'room-type-beds' });
                }).catch(({ response }) => {
                    this.setState({ saving: false })
                    if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 400) {
                        Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else if (response.status == 409) {
                        Error('Bed type already exist')
                    } else {
                        Error(response.statusMessage)
                    }
                });
        }
    }
    submitMattress = e => {
        const data = this.props.SettingRoomtypeState.room;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof data[key] === 'string') {
                data[key] = data[key].trim();
            }
        }
        let valid = 1;
        if (!data.mattressname) {
            valid = 0;
            this.setState({ mattressnameError: true });
        }

        if (valid) {
            var formData = {
                name: data.mattressname,
            }
            this.setState({ saving: true })
            axios.post(ImportedURL.API.addConfigurationMattress, formData)
                .then((res) => {
                    this.setState({ saving: false, MattressCanvas: false })
                    Success("Mattress added successfully");
                    this.props.HandleInputChange('mattressname', '')
                    this.props.ListMattressConfigOption({ parentslug: 'mattress' });
                }).catch(({ response }) => {
                    this.setState({ saving: false })
                    if (response.status == 500) {
                        Error(response.status + (languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR : ' Internal Server Error'))
                    } else if (response.status == 400) {
                        Error(languageData && languageData.BAD_REQUEST ? languageData.BAD_REQUEST : 'Bad request')
                    } else if (response.status == 502) {
                        Error(response.status + (languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY : ' Bad Gateway'))
                    } else if (response.status == 409) {
                        Error('Mattress already exist')
                    } else {
                        Error(response.statusMessage)
                    }
                });
        }
    }
    addFormFields(e) {
        const data = this.props.SettingRoomtypeState.room;
        let isValid = true;
        if (data.itemvalue && data.itemvalue.length > 0) {
            let bednameArray = []
            let mattressnameArray = []
            let quantityArray = []
            data.itemvalue.map((e, i) => {
                let item = (e && typeof e === 'string') ? e.trim() : e
                if (!item.bedname) {
                    bednameArray.push(i)
                    isValid = false;
                }
                if (!item.mattressname) {
                    mattressnameArray.push(i)
                    isValid = false;
                }
                if (!item.quantity) {
                    quantityArray.push(i)
                    isValid = false;
                }
            })
            this.setState({ bednameArrayError: bednameArray, mattressnameArrayError: mattressnameArray, quantityArrayError: quantityArray })
        }
        if (isValid) {
            this.props.HandleRoomTypeUpdate('itemvalue', { 'bedname': '', 'width': '', 'length': '', 'matrass': '', 'quantity': '', }, '');
        }
    }
    removeFormFields(i) {
        this.setState({ spinner: false })
        const DataValue = this.props.SettingRoomtypeState.room;
        if (DataValue.length === 1 && i === 0) {
            this.props.HandleRoomTypeUpdate(true, { 'bedname': '' }, i);
        } else {
            this.props.HandleRoomTypeUpdate('itemvalue', '', i);
        }
        const { bednameArrayError, mattressnameArrayError, quantityArrayError } = this.state
        let bedname = bednameArrayError.filter((value) => value !== i)
        let mattress = mattressnameArrayError.filter((value) => value !== i)
        let quantity = quantityArrayError.filter((value) => value !== i)
        this.setState({ bednameArrayError: bedname, mattressnameArrayError: mattress, quantityArrayError: quantity })
    }
    handleDrop = (acceptedFiles) => {
        let outerCount = 0
        if (acceptedFiles.length < (11 - this.state.files.length)) {
            acceptedFiles.forEach((file, index) => {
                let existImg = (this.state.files && this.state.files.length > 0) ? this.state.files.find((e) => e.name == file.name) : undefined
                if (existImg) {
                    Error('Some file already exists');
                } else {
                    if (file.type.startsWith('image/')) {
                        const reader = new FileReader();
                        reader.onload = () => {
                            const image = new Image();
                            image.src = reader.result;
                            image.onload = () => {
                                let height = image.height;
                                let width = image.width;
                                const fileSizeInMB = file.size / (1024 * 1024)
                                if (fileSizeInMB < 20) {
                                    if (width >= 600 && height >= 400) {
                                        let counter = 0
                                        if (this.state.files && this.state.files.length > 0) {
                                            for (let i = 0; i < this.state.files.length; i++) {
                                                counter = this.state.files[this.state.files.length - 1].id
                                            }
                                        }
                                        file['id'] = counter + outerCount + 1
                                        outerCount++
                                        this.setState({ files: [...this.state.files, file] })
                                    } else {
                                        Error('Minimum width and height should be (600 x 400)');
                                    }
                                } else {
                                    Error('Image Size Should be less than 20 MB');
                                }
                            }
                        };
                        reader.readAsDataURL(file);
                    } else {
                        Error('Invalid file is discarded');
                    }
                }
            })
        } else {
            Error(`You can only upload 10 files`);
        }
    }
    onChangeEdit = (acceptedFiles) => {
        let outerCount = 0
        const data = this.props.SettingRoomtypeState.room.roomimage
        let concatImages = (data.concat(acceptedFiles)).concat(this.state.files)
        if ((concatImages.length) < (11)) {
            this.setState({ spinner: false })
            this.setState({ existingImage: data });
            let img = []
            acceptedFiles.forEach((file, i) => {
                let existImg = (data && data.length > 0) ? data.find((e) => (e && e.name) == file.name) : undefined
                if (existImg) {
                    Error('Some file already exists');
                } else {
                    if (file.type.startsWith('image/')) {
                        const reader = new FileReader();
                        reader.onload = () => {
                            const image = new Image();
                            image.src = reader.result;
                            image.onload = () => {
                                let height = image.height;
                                let width = image.width;
                                const fileSizeInMB = file.size / (1024 * 1024)
                                if (fileSizeInMB < 20) {
                                    if (width >= 600 && height >= 400) {
                                        let counter = 0
                                        if (data && data.length > 0) {
                                            for (let i = 0; i < data.length; i++) {
                                                counter++
                                            }
                                        } else {
                                            for (let i = 0; i < this.state.files.length; i++) {
                                                counter = this.state.files[this.state.files.length - 1].id
                                            }
                                        }
                                        file['id'] = counter + outerCount + 1
                                        outerCount++
                                        img.push(file)
                                        if (i == acceptedFiles.length - 1) {
                                            setTimeout(() => {
                                                let sendImages = (this.state.files).concat(data.concat(img))
                                                this.setState({ files: sendImages })
                                                this.props.SettingRoomtypeHandleChange("roomimage", []);
                                            }, 200);
                                        }
                                        // if (this.state.editFilesList.length > 0) {
                                        //     this.setState({ editFilesList: [...this.state.editFilesList, file] })
                                        // } else {
                                        //     this.setState({ editFilesList: [...data, ...this.state.editFilesList, file] })
                                        // }
                                    } else {
                                        Error('Minimum width and height should be (600 x 400)');
                                    }
                                } else {
                                    Error('Image Size Should be less than 20 MB');
                                }
                            }
                        };
                        reader.readAsDataURL(file);
                    } else {
                        Error('Invalid file is discarded');
                    }
                }
            })
        } else {
            Error(`You can only upload 10 files`);
        }
    }

    handleRemoveExisting = (i, value) => {
        this.setState({ spinner: false })
        const file = this.state.existingImage.filter((item, index) => index !== i);
        this.setState({ existingImage: file })
    }
    onChangeEditor = e => {
        const data = this.props.SettingRoomtypeState.room;
        let description = window.$(e.editor.getData()).text()
        data.descriptionCount = Math.floor(description.length % 159);
        data.count = Math.floor(description.length / 159);
        this.props.SettingRoomtypeHandleChange("description", e.editor.getData());
    }
    onChangeItem(i, e) {
        const { name, value } = e.target;
        this.setState({ spinner: false, itemsError: false })
        var val = value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1')
        this.props.HnadleMultiRowClick(name, val, i);
        const { quantityArrayError } = this.state
        if (name == 'quantity') {
            let arr = quantityArrayError.filter((value) => value !== i)
            this.setState({ quantityArrayError: arr })
        }
    }
    onChangeSelectItem(i, e) {
        this.setState({ spinner: false, itemsError: false })
        const { name, value } = e;
        if (value) {
            this.props.HnadleMultiRowClick(name, value, i);
        } else {
            if (name == 'mattressname') {
                this.setState({ MattressCanvas: true, mattressnameError: false })
            }
            if (name == 'bedname') {
                this.setState({ showcanvas: true, bednameError: false })
            }
        }
        const { bednameArrayError, mattressnameArrayError } = this.state
        if (name == 'mattressname') {
            let arr = mattressnameArrayError.filter((value) => value !== i)
            this.setState({ mattressnameArrayError: arr })
        }
        if (name == 'bedname') {
            let arr = bednameArrayError.filter((value) => value !== i)
            this.setState({ bednameArrayError: arr })
        }
    }
    handleback = e => {
        this.props.HandleInputChange('tabposition', 'roomtype');
    }
    handleSearch = e => {
        const { name, value } = e.target
        const rowData = this.props.RoomareaAmenityState.listsettingamenities;
        if (value) {
            if (rowData != undefined && rowData.length > 0) {
                const data = rowData.filter((item) =>
                    (item.amenity ? item.amenity.toLowerCase().includes(value.toLowerCase()) : ''))
                this.props.handlekeySearch(data)
            }
        }
        else {
            this.props.ListSettingRoomareaAmenity();
        }
    }
    onSortEnd = ({ oldIndex, newIndex }) => {
        const { files } = this.state;
        this.setState({
            files: arrayMove(files, oldIndex, newIndex),
        });
    };
    onSortEndEdit = ({ oldIndex, newIndex }) => {
        const data = this.props.SettingRoomtypeState.room;
        let img = data.roomimage
        let Store = arrayMove(img, oldIndex, newIndex)
        this.props.SettingRoomtypeHandleChange('roomimage', Store);
    };
    onSortEndCombined = ({ oldIndex, newIndex }) => {
        const { editFilesList } = this.state;
        this.setState({
            editFilesList: arrayMove(editFilesList, oldIndex, newIndex),
        });
    };
    onDeleteHandler = (data) => {
        const Store = this.state.files.filter((item, index) => item.id !== data.id);
        this.setState({ files: Store })
    };
    onDeleteHandlerEdit = (e) => {
        const data = this.props.SettingRoomtypeState.room;
        let img = data.roomimage
        const Store = img.filter((item, index) => item !== e);
        this.props.SettingRoomtypeHandleChange('roomimage', Store);
    };
    onDeleteHandlerCombined = (e) => {
        const data = this.props.SettingRoomtypeState.room;
        let img = data.roomimage
        if (typeof e == "string") {
            const Store = img.filter((item, index) => item !== e);
            var deleteData = this.state.editFilesList.filter((item, index) => item !== e);
            this.props.SettingRoomtypeHandleChange('roomimage', Store);
        } else {
            var deleteData = this.state.editFilesList.filter((item, index) => item.id !== e.id);
            const Store = this.state.files.filter((item, index) => item.id !== e.id);
            this.setState({ files: Store })
        }
        this.setState({ editFilesList: deleteData })
    };
    handleButtonClick = () => {
        this.fileInput.open();
    };
    BedRoomError = (index) => {
        const { bednameArrayError, } = this.state
        if (bednameArrayError && bednameArrayError.length > 0) {
            if (bednameArrayError.includes(index)) {
                return 1
            } else {
                return 0
            }
        } else {
            return 0
        }
    }
    MattressError = (index) => {
        const { mattressnameArrayError } = this.state
        if (mattressnameArrayError && mattressnameArrayError.length > 0) {
            if (mattressnameArrayError.includes(index)) {
                return 1
            } else {
                return 0
            }
        } else {
            return 0
        }
    }
    QuantityError = (index) => {
        const { quantityArrayError } = this.state
        if (quantityArrayError && quantityArrayError.length > 0) {
            if (quantityArrayError.includes(index)) {
                return 1
            } else {
                return 0
            }
        } else {
            return 0
        }
    }
    onClickColor = () => {
        this.setState({
            showPicker: !this.state.showPicker
        })
    };
    onChangeColor = (color) => {
        this.props.SettingRoomtypeHandleChange('colorcode', color.hex);
    };
    onClose = () => {
        this.setState({
            showPicker: false
        })
    };
    render() {
        if (this.state.addRedirect) {
            return <Redirect to={'/add-setting-multipleroom'} />
        }
        if (this.state.listRedirect) {
            return <Redirect to={'/list-setting-roomtype'} />
        }

        const { SettingRoomtypeState, AccountState, HotelState, ConfigurationState, RoomareaAmenityState } = this.props;
        const account = AccountState.account;
        const languageData = AccountState.account.SelectedlanguageData
        const listHotels = HotelState.listhotels;
        const data = this.props.SettingRoomtypeState.room;

        const tabposition = SettingRoomtypeState.room.tabposition;
        const spinner = this.props.SettingRoomtypeState.spinner
        const amenityData = RoomareaAmenityState.listsettingamenities;
        var ConfigOptionData = ConfigurationState.listConfigOptions;
        var BedTypeConfigOptions = ConfigurationState.listBedTypeConfigOptions;
        var MattressConfigOptions = ConfigurationState.listMattressConfigOptions;
        var selectedHotel = '';
        const hotelOptions = [];
        if (listHotels && listHotels.length > 0) {
            listHotels.filter(e => e.status === true).map((item) => {
                if (data.hotel == item._id) {
                    selectedHotel = { label: `${item.name} ${item.city}  ${item.state}`, value: item.hotelid, logo: item.logo }
                }
                hotelOptions.push({ label: `${item.name} ${item.city}  ${item.state}`, value: item._id, logo: item.logo, name: 'hotel' });
            })
        }
        var roomDataArray = []
        var selectedRoomtype = '';
        if (ConfigOptionData && ConfigOptionData.length > 0) {
            ConfigOptionData.filter((e) => e.status == true).map((temp) => {
                if (data.roomtype == temp._id) {
                    selectedRoomtype = { label: `${temp.name}`, value: temp._id, name: 'roomtype' }
                }
                roomDataArray.push({ label: `${temp.name}`, value: temp._id, name: 'roomtype' })
            })
        }
        var itemArray = [];
        if (data.itemvalue && data.itemvalue.length > 0) {
            data.itemvalue.map((item, i) => {
                var selectedRoomtypeBeds = ''
                var selectedRoomMattress = ''
                var bedArray = []
                var mattressArray = []
                if (BedTypeConfigOptions && BedTypeConfigOptions.length > 0) {
                    BedTypeConfigOptions.filter((e) => e.status == true).map((e) => {
                        if (item.bedname == e._id) {
                            selectedRoomtypeBeds = { label: `${e.name}`, value: e._id, name: 'bedname' }
                        }
                        bedArray.push({ label: `${e.name}`, value: e._id, name: 'bedname' })
                    })
                }
                bedArray.push({ label: 'Add', value: '', name: 'bedname' })
                if (MattressConfigOptions && MattressConfigOptions.length > 0) {
                    MattressConfigOptions.map((e) => {
                        if (item.mattressname == e._id) {
                            selectedRoomMattress = { label: `${e.name}`, value: e._id, name: 'mattressname' }
                        }
                        mattressArray.push({ label: `${e.name}`, value: e._id, name: 'mattressname' })
                    })
                }
                mattressArray.push({ label: 'Add', value: '', name: 'mattressname' })
                itemArray.push(
                    <tr key={i} className="required_tables_pms">
                        <td style={{ width: '210px' }}>
                            <div className="form-group" style={{ marginBottom: '0px' }}>
                                <Select
                                    value={selectedRoomtypeBeds ? { label: selectedRoomtypeBeds.label } : ''}
                                    onChange={e => this.onChangeSelectItem(i, e)}
                                    options={bedArray}
                                    ref={this.bedroomInputRef}
                                />
                                <div className="invalid-feedback" style={{ display: this.BedRoomError(i) ? "block" : 'none' }}>Bed Name is required</div>
                            </div>
                        </td>
                        <td>
                            <div className="form-group" style={{ marginBottom: '0px' }}>
                                <p style={{ marginBottom: '0px' }}>
                                    <input type="text" className='form-control' name="width" value={item.width} onChange={e => this.onChangeItem(i, e)} placeholder={"Width (cm)"} />
                                </p>
                            </div>
                        </td>
                        <td>
                            <div className="form-group" style={{ marginBottom: '0px' }}>
                                <p style={{ marginBottom: '0px' }}>
                                    <input type="text" className='form-control' name="length" value={item.length} onChange={e => this.onChangeItem(i, e)} placeholder={"Length (cm)"} />
                                </p>
                            </div>
                        </td>
                        <td style={{ width: '210px' }}>
                            <div className="form-group" style={{ marginBottom: '0px' }}>
                                <p style={{ marginBottom: '0px' }}>
                                    <Select
                                        value={selectedRoomMattress}
                                        onChange={e => this.onChangeSelectItem(i, e)}
                                        options={mattressArray}
                                        ref={this.mattressInputRef}
                                    />
                                    <div className="invalid-feedback" style={{ display: this.MattressError(i) ? "block" : 'none' }}>Mattress is required</div>
                                </p>
                            </div>
                        </td>
                        <td>
                            <div className="form-group" style={{ marginBottom: '0px' }}>
                                <p style={{ marginBottom: '0px' }}>
                                    <input type="text" ref={this.quantityInputRef} className='form-control' name="quantity" value={item.quantity} onChange={e => this.onChangeItem(i, e)} placeholder={"Quantity"} />
                                    <div className="invalid-feedback" style={{ display: this.QuantityError(i) ? "block" : 'none' }}>Quantity is required</div>
                                </p>
                            </div>
                        </td>
                        <td>
                            <div className="col-sm-6 col-md-3">
                                <div className='todo_increment' style={{ marginTop: '0px' }}>
                                    {data.itemvalue.length > 1 ?
                                        < span className='plus_minus' > <i className="fa fa-minus-circle" aria-hidden="true" onClick={() => this.removeFormFields(i)}></i></span>
                                        : ''}
                                </div>
                            </div>
                        </td>
                    </tr>
                )
            })
        }
        const contentRoomType = []
        const contentView = []
        const contentBathroom = []
        const contentBedroom = []
        const contentKitchen = []
        const contentTVInternetVideo = []
        const contentElectrical = []
        const contentAccessibility = []
        const contentFloor = []
        const contentOutdoors = []
        if (data.hotel) {
            amenityData && amenityData.filter(filterItem => filterItem.status === false).map((item1, i) => {
                if (item1.hotel == data.hotel) {
                    if (item1.type == 'Room Type') {
                        contentRoomType.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'View') {
                        contentView.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Bathroom') {
                        contentBathroom.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Bedroom') {
                        contentBedroom.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Kitchen') {
                        contentKitchen.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Tv,Internet,Video') {
                        contentTVInternetVideo.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Electrical Appliances') {
                        contentElectrical.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Accessibility') {
                        contentAccessibility.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Floor') {
                        contentFloor.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Outdoors') {
                        contentOutdoors.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    }
                }
                if (item1.hotel == 'All') {
                    if (item1.type == 'Room Type') {
                        contentRoomType.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'View') {
                        contentView.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Bathroom') {
                        contentBathroom.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Bedroom') {
                        contentBedroom.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Kitchen') {
                        contentKitchen.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Tv,Internet,Video') {
                        contentTVInternetVideo.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Electrical Appliances') {
                        contentElectrical.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Accessibility') {
                        contentAccessibility.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Floor') {
                        contentFloor.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    } else if (item1.type == 'Outdoors') {
                        contentOutdoors.push(
                            <>
                                <Amenitycontent item1={item1} data={data} i={i} />
                            </>
                        )
                    }
                }
            })
        }
        var firstData = ''
        if (this.state.editFilesList && this.state.editFilesList.length > 0) {
            for (let i = 0; i < this.state.editFilesList.length; i++) {
                firstData = this.state.editFilesList[0]
            }
        }
        const colorStyles = reactCSS({
            'default': {
                color: {
                    width: '60px',
                    height: '20px',
                    borderRadius: '3px',
                    background: data.colorcode,
                },
                popover: {
                    position: 'absolute',
                    zIndex: '3',
                },
                cover: {
                    position: 'fixed',
                    top: '0px',
                    right: '0px',
                    bottom: '0px',
                    left: '0px',
                },
                swatch: {
                    padding: '6px',
                    background: '#ffffff',
                    borderRadius: '2px',
                    cursor: 'pointer',
                    display: 'inline-block',
                    boxShadow: '0 0 0 1px rgba(0,0,0,.2)',
                },
            },
        });

        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-setting-roomtype"><h6 className="highlights_breadcrump">{tabposition == 'roomtype' ? ((languageData && languageData.ROOM_TYPE_SETTINGS) ? languageData.ROOM_TYPE_SETTINGS + " List" : "Room Type List") : tabposition == 'rooms' ? ((languageData && languageData.ROOM_SETTINGS) ? languageData.ROOM_SETTINGS + " List" : "Room List") : tabposition == 'service' ? ((languageData && languageData.OUT_OF_SERVICE_SETTINGS) ? languageData.OUT_OF_SERVICE_SETTINGS + " List" : "Out Of Service List") : ''}</h6></Link><span><i className="fa fa-angle-right" aria-hidden="true"></i> </span> <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>{this.state.modalType} {(languageData && languageData.ROOM_TYPE_SETTINGS) ? languageData.ROOM_TYPE_SETTINGS : "Room Type"}</h6></p>
                        </div>
                        <div className="section-body pt-3">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div
                                        className="tab-pane fade show active"
                                        id="Departments-list"
                                        role="tabpanel"
                                    >
                                        <div className="card">
                                            <div className="card-header">
                                                <div className='card-title d-flex'>
                                                    <div className='rounded_icon'><i className="icon-home mr-2 "></i></div>
                                                    <h3 className="card-title " style={{ marginTop: '10px' }}>{this.state.modalType} {(languageData && languageData.ROOM_TYPE_SETTINGS) ? languageData.ROOM_TYPE_SETTINGS : "Room Type"}</h3>
                                                </div>
                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <ul className="nav nav-tabs page-header-tab"></ul>

                                                        <div className="header-action">
                                                            <Link to="/list-setting-roomtype"><button type="button" className="btn btn-primary" onClick={this.handleback}><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <Row>
                                                    <div className="col-lg-12 col-md-12">
                                                        <Row>
                                                            <div className="col-lg-4 col-md-4">
                                                                <div className="form-group">
                                                                    <label className="form-label">Image Of Roomtype</label>
                                                                    <div className="upload-drag-drop" style={{ position: 'relative' }} data-toggle="modal" data-target="#exampleModalCenter">
                                                                        {
                                                                            this.state.files.length > 0 || data.roomimage.length > 0 ?
                                                                                <>
                                                                                    <div style={{ position: 'absolute', fontWeight: 'bold', textAlign: 'center' }}>
                                                                                        <i style={{ fontSize: '50px' }} class="fa fa-check" aria-hidden="true"></i>
                                                                                        <p>{this.state.files.length == 1 ? "Image Uploaded" : "Images Uploaded"}</p>
                                                                                    </div>
                                                                                    {
                                                                                        (data.roomimage && data.roomimage.length > 0)
                                                                                            ?
                                                                                            <>{
                                                                                                (typeof data.roomimage[0] == "string") ?
                                                                                                    <><img src={ImportedURL.FILEURL + data.roomimage[0]} style={{ objectFit: 'contain' }} onError={onErrorImage} /> </>
                                                                                                    :
                                                                                                    <> <img src={URL.createObjectURL(data.roomimage[0])} style={{ objectFit: 'contain' }} onError={onErrorImage} /></>
                                                                                            }</>
                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    (this.state.files && this.state.files.length > 0) ? <img src={typeof this.state.files[0] == 'string' ? ImportedURL.FILEURL + this.state.files[0] : URL.createObjectURL(this.state.files[0])} style={{ objectFit: 'contain' }} onError={onErrorImage} /> : ''
                                                                                                }
                                                                                            </>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                <>
                                                                                    <div style={{ position: 'absolute', fontWeight: 'bold', textAlign: 'center' }}>
                                                                                        <i style={{ fontSize: '50px' }} className="fa fa-cloud-upload" aria-hidden="true"></i>
                                                                                        <p>Click to upload image</p>
                                                                                    </div>
                                                                                </>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-8 col-md-8">
                                                                <Row>
                                                                    {ImportedURL.SUPERADMIN.includes(account.role) &&
                                                                        <>
                                                                            <div className="col-lg-12 col-md-12">
                                                                                <div className="form-group">
                                                                                    <label className="form-label">Hotel<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                                    <Select
                                                                                        value={selectedHotel}
                                                                                        onChange={this.HotelChange}
                                                                                        options={hotelOptions}
                                                                                        formatOptionLabel={(e) => (
                                                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                                                {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                                                <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                                            </div>
                                                                                        )}
                                                                                        isDisabled={this.state.modalType == "View"}
                                                                                        ref={this.hotelInputRef}
                                                                                    />
                                                                                    <div className="invalid-feedback" style={{ display: this.state.hotelError ? "block" : 'none' }}>Hotel is required</div>
                                                                                </div>
                                                                            </div>
                                                                        </>
                                                                    }

                                                                    <div className="col-lg-6 col-md-4">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Room Type<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                            <Select
                                                                                value={selectedRoomtype ? { label: selectedRoomtype.label } : ''}
                                                                                onChange={this.HotelChange}
                                                                                options={roomDataArray}
                                                                                ref={this.roomtypeInputRef}
                                                                            />
                                                                            <div className="invalid-feedback" style={{ display: this.state.roomtypeError ? "block" : 'none' }}>Room Type is required</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                            <input type="text" className="form-control" ref={this.nameInputRef} name='roomname' onChange={this.onHandleChange} value={data.roomname} placeholder="Name" />
                                                                            <div className="invalid-feedback" style={{ display: this.state.roomnameError ? "block" : 'none' }}>Name is required</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Area ( Sq. Feet )<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                            <input type="text" ref={this.areaInputRef} className="form-control" name='builtunit' onChange={this.onHandleChange} value={data.builtunit} placeholder="Area" />
                                                                            <div className="invalid-feedback" style={{ display: this.state.builtunitError ? "block" : 'none' }}>Area is required</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-6">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Article Number</label>
                                                                            <input type="text" className="form-control" name='articlenumber' onChange={this.onHandleChange} value={data.articlenumber} placeholder="Article Number" />
                                                                            <div className="invalid-feedback" style={{ display: this.state.articlenumberError ? "block" : 'none' }}>Article Number is required</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Max Occupancy<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                                                <div class="roomtype-tooltip">
                                                                                    <i class="fa fa-info ml-1" aria-hidden="true">
                                                                                    </i>
                                                                                    <span class="tooltiptext">Maximum number of guests per room (according to fire safety regulations)</span>
                                                                                </div>
                                                                            </label>
                                                                            <input type="text" ref={this.maxoccupnacyInputRef} className="form-control" name='maxoccupancy' onChange={this.onHandleChange} value={data.maxoccupancy} placeholder="Max Occupancy" />
                                                                            <div className="invalid-feedback" style={{ display: this.state.maxoccupancyError ? "block" : 'none' }}>Max Occupancy is required</div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-lg-6 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Default Occupancy<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                                                <div class="roomtype-tooltip">
                                                                                    <i class="fa fa-info ml-1" aria-hidden="true">
                                                                                    </i>
                                                                                    <span class="tooltiptext">Comfortable accommodation in guest rooms (examples: double room - 2 guests, triple room - 3 guests)</span>
                                                                                </div>
                                                                            </label>
                                                                            <input type="text" ref={this.defaultoccupnacyInputRef} className="form-control" name='defaultoccupancy' onChange={this.onHandleChange} value={data.defaultoccupancy} placeholder="Default Occupancy" />
                                                                            <div className="invalid-feedback" style={{ display: this.state.defaultoccupancyError ? "block" : 'none' }}>Default Occupancy is required</div>
                                                                            <div className="invalid-feedback" style={{ display: this.state.valueDefaultError ? "block" : 'none' }}>Default occupancy must be less than Max occupancy</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Max Adults<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                                                <div class="roomtype-tooltip">
                                                                                    <i class="fa fa-info ml-1" aria-hidden="true">
                                                                                    </i>
                                                                                    <span class="tooltiptext">Maximum number of adults for this room type</span>
                                                                                </div>
                                                                            </label>
                                                                            <input type="text" ref={this.adultsInputRef} className="form-control" name='adults' onChange={this.onHandleChange} value={data.adults} placeholder="Max Adults" />
                                                                            <div className="invalid-feedback" style={{ display: this.state.adultsError ? "block" : 'none' }}>Max Adults is required</div>
                                                                            <div className="invalid-feedback" style={{ display: this.state.valueadultsError ? "block" : 'none' }}>Max Adults should less than or equal to Max Occupancy</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-lg-6 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Max Children<span className="ml-1" style={{ color: 'red' }}>*</span>
                                                                                <div class="roomtype-tooltip">
                                                                                    <i class="fa fa-info ml-1" aria-hidden="true">
                                                                                    </i>
                                                                                    <span class="tooltiptext">Maximum number of children</span>
                                                                                </div></label>
                                                                            <input type="text" ref={this.childrenInputRef} className="form-control" name='children' onChange={this.onHandleChange} value={data.children} placeholder="Max Children" />
                                                                            <div className="invalid-feedback" style={{ display: this.state.valuechildrenError ? "block" : 'none' }}>Max Children should less than Max Occupancy</div>
                                                                            <div className="invalid-feedback" style={{ display: this.state.childrenError ? "block" : 'none' }}>Max Children is required</div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6 col-md-12 my-3">
                                                                        <div className="form-group" >
                                                                            <div className="row clearfix" style={{ alignItems: 'center' }}>
                                                                                <div className="col-md-3">
                                                                                    <label className='form-label'>Color Code </label>
                                                                                </div>
                                                                                <div className="col-md-9">
                                                                                    <div style={colorStyles.swatch} onClick={this.onClickColor}>
                                                                                        <div style={colorStyles.color} />
                                                                                    </div>
                                                                                    {this.state.showPicker ? <div style={colorStyles.popover}>
                                                                                        <div style={colorStyles.cover} onClick={this.onClose} />
                                                                                        <SketchPicker color={data.color} onChange={this.onChangeColor} />
                                                                                    </div> : null}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className="col-lg-6 col-md-12">
                                                                        <div className="form-group">
                                                                            <label className="form-label">Preferred Room Type<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                                            <input type="radio" ref={this.perferredInputRef} className="radio-button" checked={data.actype == "AC"} id="AC" value="AC" name="actype" onChange={this.onHandleChange} /> <label className="radio-button-label" for="AC" style={{ cursor: 'pointer' }}>AC</label>
                                                                            <input type="radio" ref={this.perferredInputRef} className="radio-button" checked={data.actype == "Non AC"} id="Non AC" value="Non AC" name="actype" onChange={this.onHandleChange} style={{ marginLeft: "45px" }} /> <label className="radio-button-label" for="Non AC" style={{ cursor: 'pointer' }}>Non AC</label>
                                                                            <div className="invalid-feedback" style={{ display: this.state.actypeError ? "block" : 'none' }}>Preferred Room Type is required</div>
                                                                        </div>
                                                                    </div> */}
                                                                </Row>
                                                            </div>
                                                        </Row>
                                                        <div className="col-md-12 pl-0 pr-0">
                                                            <div className="form-group">
                                                                <div className='d-flex' style={{ justifyContent: "space-between" }}>
                                                                    <label className="form-label" >Description</label>
                                                                </div>
                                                                <Ckeditor
                                                                    activeclassName="p10"
                                                                    content={data.description}
                                                                    events={{
                                                                        "change": this.onChangeEditor
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="Over_all_table_style col-md-12 pl-0 pr-0">
                                                            <table class="table table-borderless">
                                                                <thead>
                                                                    <tr style={{ textAlign: "center" }}>
                                                                        <th>Bed Name<span className="ml-1" style={{ color: 'red' }}>*</span></th>
                                                                        <th>Width</th>
                                                                        <th>Length</th>
                                                                        <th>Mattress<span className="ml-1" style={{ color: 'red' }}>*</span></th>
                                                                        <th>Quantity<span className="ml-1" style={{ color: 'red' }}>*</span></th>
                                                                        <th>
                                                                            <div className='todo_increment' style={{ marginTop: '0px' }}>
                                                                                <span className='plus_minus'><i className="fa fa-plus-circle" aria-hidden="true" onClick={() => this.addFormFields()}></i></span>
                                                                            </div>
                                                                        </th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {itemArray}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        {data.hotel ?
                                                            <>
                                                                <div className="row ml-0 mr-0 pl-0">
                                                                    <h3 className="card-title ml-0 mr-0 mb-3">Facilities</h3>
                                                                    <div style={{ position: 'relative' }}>
                                                                        <input type="text" className="form-control" style={{ position: 'relative', paddingLeft: '32px' }} name='builtunit' onChange={this.handleSearch} placeholder="Search Facilities..." />
                                                                        <i class="fa fa-search mr-2" style={{ position: 'absolute', top: "12px", left: '25px' }} aria-hidden="true"></i>
                                                                    </div>
                                                                </div>
                                                                {contentRoomType && contentRoomType.length > 0 ?
                                                                    <>
                                                                        <div className="row ml-0 mr-0 pl-0 mt-4">
                                                                            <h3 className="card-title ml-0 mr-0 mb-1">Room Type</h3>
                                                                            {contentRoomType ? contentRoomType : ''}
                                                                        </div>
                                                                    </> : ''}
                                                                {contentView && contentView.length > 0 ?
                                                                    <>
                                                                        <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                            <h3 className="card-title ml-0 mr-0 mb-1">View</h3>
                                                                            {contentView ? contentView : ''}
                                                                        </div>
                                                                    </> : ''}
                                                                {contentBathroom && contentBathroom.length > 0 ?
                                                                    <>
                                                                        <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                            <h3 className="card-title ml-0 mr-0 mb-1">Bathroom</h3>
                                                                            {contentBathroom ? contentBathroom : ''}
                                                                        </div>
                                                                    </> : ''}
                                                                {contentBedroom && contentBedroom.length > 0 ?
                                                                    <>
                                                                        <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                            <h3 className="card-title ml-0 mr-0 mb-1">Bedroom</h3>
                                                                            {contentBedroom ? contentBedroom : ''}
                                                                        </div>
                                                                    </> : ''}
                                                                {contentKitchen && contentKitchen.length > 0 ?
                                                                    <>
                                                                        <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                            <h3 className="card-title ml-0 mr-0 mb-1">Kitchen</h3>
                                                                            {contentKitchen ? contentKitchen : ''}
                                                                        </div>
                                                                    </> : ''}
                                                                {contentTVInternetVideo && contentTVInternetVideo.length > 0 ?
                                                                    <>
                                                                        <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                            <h3 className="card-title ml-0 mr-0 mb-1">TV,Internet,Video</h3>
                                                                            {contentTVInternetVideo ? contentTVInternetVideo : ''}
                                                                        </div>
                                                                    </> : ''}
                                                                {contentElectrical && contentElectrical.length > 0 ?
                                                                    <>
                                                                        <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                            <h3 className="card-title ml-0 mr-0 mb-1">Electrical Appliances</h3>
                                                                            {contentElectrical ? contentElectrical : ''}
                                                                        </div>
                                                                    </> : ''}
                                                                {contentAccessibility && contentAccessibility.length > 0 ?
                                                                    <>
                                                                        <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                            <h3 className="card-title ml-0 mr-0 mb-1">Accessibility</h3>
                                                                            {contentAccessibility ? contentAccessibility : ''}
                                                                        </div>
                                                                    </> : ''}
                                                                {contentFloor && contentFloor.length > 0 ?
                                                                    <>
                                                                        <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                            <h3 className="card-title ml-0 mr-0 mb-1">Floor</h3>
                                                                            {contentFloor ? contentFloor : ''}
                                                                        </div>
                                                                    </> : ''}
                                                                <div className="row ml-0 mr-0 pl-0 mt-5">
                                                                    {contentOutdoors && contentOutdoors.length > 0 ? <h3 className="card-title ml-0 mr-0 mb-1">Outdoors</h3> : ''}
                                                                    {contentOutdoors ? contentOutdoors : ''}
                                                                </div>
                                                                {(contentRoomType && contentRoomType.length > 0 || contentView && contentView.length > 0 || contentBathroom && contentBathroom.length > 0 || contentBedroom && contentBedroom.length > 0 || contentKitchen && contentKitchen.length > 0 || contentTVInternetVideo && contentTVInternetVideo.length > 0 || contentElectrical && contentElectrical.length > 0 || contentAccessibility && contentAccessibility.length > 0 || contentFloor && contentFloor.length > 0 || contentOutdoors && contentOutdoors.length > 0)
                                                                    ? '' :
                                                                    <div className="card-body no_records text-center d-flex align-items-center justify-content-center pt-0">
                                                                        <div style={{ maxWidth: "340px" }}>
                                                                            <img src="../assets/images/nothing-here.png" alt="..." className="img-fluid" style={{ maxWidth: "272px" }} onError={onErrorImage} />
                                                                            <h5 className="mb-2">No records to display ... Add Room Amenity..</h5>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                            : ''}
                                                        {this.state.modalType == 'Edit' ?
                                                            <div className="card-footer text-right mandatory">
                                                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                                <div className="text-right">
                                                                    {
                                                                        this.state.saving ?
                                                                            <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                                            :
                                                                            <button type="submit" className="btn commor_save" onClick={this.submit}>
                                                                                <i className="fe fe-save mr-2"></i>Update
                                                                            </button>
                                                                    }
                                                                </div>
                                                            </div> :
                                                            <div className="card-footer text-right mandatory">
                                                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                                <div className="text-right">
                                                                    {
                                                                        this.state.saving ?
                                                                            <button type="button" className="btn commor_save" ><i class="fa fa-spinner fa-spin mr-2"></i>Next Step</button>
                                                                            :
                                                                            <button type="submit" className="btn commor_save" onClick={this.submit}>
                                                                                <i className="fa fa-arrow-right mr-2"></i>Next Step
                                                                            </button>
                                                                    }
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </Row>

                                            </div>
                                        </div>
                                        <div id="overlay" style={{ display: this.state.modalType == "Edit" && spinner ? 'block' : 'none' }}></div>
                                        {this.state.modalType == "Edit" && spinner &&
                                            <div className="common_loader">
                                                <img className='loader_img_style_common' src='../../assets/images/load.png' />
                                                <Spinner className='spinner_load_common' animation="border" variant="info" >
                                                </Spinner>
                                            </div>
                                        }

                                        <div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                            <div class="modal-dialog modal-dialog-centered" role="document">
                                                <div class="modal-content">
                                                    <div class="modal-header">
                                                        <div className='rounded_icon'><i class="fa fa-picture-o" aria-hidden="true"></i></div>
                                                        <h5 class="modal-title" id="exampleModalLongTitle" style={{ marginTop: '5px' }}>Roomtype image preview</h5>
                                                        <button type="button" id="closeModal" className="close" data-dismiss="modal" aria-label="Close">
                                                            <span aria-hidden="true" style={{ fontSize: "23px" }}>
                                                                <img src='../../assets/images/cancel.png' />
                                                            </span>
                                                        </button>
                                                    </div>
                                                    <div class="modal-body">
                                                        <div className="d-flex" style={{ justifyContent: 'space-between', alignItems: 'center' }}>
                                                            <div>
                                                                <h6 style={{ marginTop: '5px', marginBottom: '0px', fontSize: '12px' }}>Note : Minimum width and height should be (600 x 400)</h6>
                                                                <h6 style={{ marginTop: '5px', marginBottom: '7px', fontSize: '12px' }}>Note : Image Size Should be less than 20 MB</h6>
                                                            </div>
                                                            <div className="uploaded_btn_room_rate" style={{ marginBottom: '10px' }}>
                                                                <label for="file">
                                                                    <button type="button" className="btn btn-primary ion-images" onClick={this.handleButtonClick}><i className="fa fa-folder-open-o mr-2"></i>Upload images</button>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="setting-roomimage-preview">
                                                            <div className="d-flex" style={{ flexWrap: "wrap", justifyContent: 'center' }} >
                                                                {
                                                                    (this.state.modalType === "Add")
                                                                        ?
                                                                        <>
                                                                            {
                                                                                (this.state.files && this.state.files.length > 0)
                                                                                &&
                                                                                <SortableList
                                                                                    items={this.state.files}
                                                                                    onSortEnd={this.onSortEnd}
                                                                                    useDragHandle={true}
                                                                                    onDeleteHandler={this.onDeleteHandler}
                                                                                />
                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            {
                                                                                (data.roomimage && data.roomimage.length > 0)
                                                                                    ?
                                                                                    <SortableList
                                                                                        items={data.roomimage}
                                                                                        onSortEnd={this.onSortEndEdit}
                                                                                        useDragHandle={true}
                                                                                        onDeleteHandler={this.onDeleteHandlerEdit}
                                                                                    />
                                                                                    :
                                                                                    <>
                                                                                        {
                                                                                            (this.state.files && this.state.files.length > 0)
                                                                                            &&
                                                                                            <SortableList
                                                                                                items={this.state.files}
                                                                                                onSortEnd={this.onSortEnd}
                                                                                                useDragHandle={true}
                                                                                                onDeleteHandler={this.onDeleteHandler}
                                                                                            />
                                                                                        }
                                                                                    </>
                                                                            }
                                                                        </>
                                                                }
                                                            </div>
                                                            <Dropzone ref={(ref) => { this.fileInput = ref }} accept="image/jpeg, image/png" onDrop={this.state.modalType === "Edit" ? this.onChangeEdit : this.handleDrop} multiple={true}>
                                                                {({ getRootProps, getInputProps }) => (
                                                                    <div {...getRootProps()}>

                                                                        <input {...getInputProps()} />
                                                                        <p className={this.state.files.length == 0 ? "text-center py-5" : "text-center"} style={{ margin: '40px 0px' }}> Drag and drop files here, or click to select files</p>
                                                                    </div>
                                                                )}
                                                            </Dropzone>
                                                        </div>
                                                        {
                                                            (this.state.files.length > 0 || data.roomimage.length > 0)
                                                            &&
                                                            <div className="d-flex" style={{ justifyContent: 'center', marginTop: '13px' }} >
                                                                <button type="button" id="closeModal" data-dismiss="modal" aria-label="Close" className="btn btn-primary" ><i className="fa fa-check-circle-o mr-2"></i>Done</button>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div >
                </div >
                <Offcanvas className="offcanvas_width_changes" show={this.state.showcanvas} onHide={(e) => this.setState({ showcanvas: false })} placement={'end'} style={{ width: '35%' }}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><h4>Add Bed Type</h4></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="row">
                            <div className="form-group">
                                <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                <input type='text' style={{ width: "100%" }} className="form-control" name="bedname" placeholder="Name" onChange={this.onChangeBedtype} value={data.bedname} />
                                <div className="invalid-feedback" style={{ display: this.state.bednameError ? "block" : 'none' }}>Name is required</div>
                            </div>
                        </div>
                        <div className='position_fixed_cnavas_small_contant'>
                            <div className="card-footer" style={{ padding: '20px 0px' }}>
                                <div className="canvas_footer_button">
                                    <div className='mr-5'>
                                        <button type="button" className="btn btn-secondary" onClick={(e) => this.setState({ showcanvas: false })} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Cancel</button>
                                    </div>
                                    <div>
                                        {
                                            this.state.saving ?
                                                <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>save</button>
                                                :
                                                <button type="submit" className="btn commor_save" onClick={this.submitBedRoom}>
                                                    <i className="fe fe-save mr-2"></i>Save
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
                <Offcanvas className="offcanvas_width_changes" show={this.state.MattressCanvas} onHide={(e) => this.setState({ MattressCanvas: false })} placement={'end'} style={{ width: '35%' }}>
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title><h4>Add Mattress</h4></Offcanvas.Title>
                    </Offcanvas.Header>
                    <Offcanvas.Body>
                        <div className="row">
                            <div className="form-group">
                                <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                <input type='text' style={{ width: "100%" }} className="form-control" name="mattressname" placeholder="Name" onChange={this.onChangeBedtype} value={data.mattressname} />
                                <div className="invalid-feedback" style={{ display: this.state.mattressnameError ? "block" : 'none' }}>Name is required</div>
                            </div>
                        </div>
                        <div className='position_fixed_cnavas_small_contant'>
                            <div className="card-footer" style={{ padding: '20px 0px' }}>
                                <div className="canvas_footer_button">
                                    <div className='mr-5'>
                                        <button type="button" className="btn btn-secondary" onClick={(e) => this.setState({ MattressCanvas: false })} data-dismiss="modal"><i className="fa fa-times mr-2"></i>Cancel</button>
                                    </div>
                                    <div>
                                        {
                                            this.state.saving ?
                                                <button type="button" className="btn commor_save"><i className="fa fa-spinner fa-spin mr-2"></i>save</button>
                                                :
                                                <button type="submit" className="btn commor_save" onClick={this.submitMattress}>
                                                    <i className="fe fe-save mr-2"></i>Save
                                                </button>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Offcanvas.Body>
                </Offcanvas>
            </>
        );
    }
}
const mapStateToProps = (state) => ({
    fixNavbar: state.settings.isFixNavbar,
    SettingRoomtypeState: state.settingroomtype,
    HotelState: state.hotel,
    AccountState: state.account,
    RoomtypeState: state.roomtype,
    RoomareaAmenityState: state.roomareaamenity,
    ConfigurationState: state.configuration,

});

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleInputChange: AC_SETTING_ROOMTYPE_HANDLE_CHANGE,
        ResetRoomtype: AC_RESET_SETTING_ROOMTYPE,
        ListHotel: AC_LIST_HOTEL,
        ListSettingRoomareaAmenity: AC_LIST_SETTING_ROOMAREA_AMENITY,
        SettingRoomtypeHandleFormChange: AC_SETTING_ROOMTYPE_HANDLE_FORM_CHANGE,
        SettingRoomtypeHandleChange: AC_SETTING_ROOMTYPE_HANDLE_CHANGE,
        HnadleMultiRowClick: AC_HANDLE_MULTIROW_CLICK,
        HandleRoomTypeUpdate: AC_HANDLE_ROOMTYPEBED_UPDATE,
        ListSettingRoomType: AC_LIST_SETTING_ROOMTYPE,
        ViewRoomType: AC_VIEW_SETTING_ROOMTYPE,
        handlekeySearch: AC_HANDLE_KEY_SEARCH,
        HandleInputChangeRoomNumber: AC_SETTING_ROOM_HANDLE_CHANGE,
        ListConfiguration: AC_LIST_CONFIGURATION,
        ListConfigOption: AC_LIST_CONFIGOPTIONS,
        ListBedTypeConfigOption: AC_LIST_CONFIGOPTIONS_BED_TYPE,
        ListMattressConfigOption: AC_LIST_CONFIGOPTIONS_MATTRESS,
        EmptyRoomType: AC_EMPTY_ROOMTYPE,
    }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(AddRoom);

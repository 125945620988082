import axios from 'axios';
import ImportedURL from '../common/api';
import { Success, Error } from '../common/swal';
import { ReplaceArrayAtIndex } from '../common/validate';

export function AC_HANDLE_GROUP_CHANGE(name, value) {
	return function (dispatch) {
		dispatch({ type: "HANDLE_GROUP_CHANGE", name: name, value: value })
	};
}

export function AC_RESET_GROUP() {
	return function (dispatch) {
		dispatch({ type: "RESET_GROUP" })
	};
}
export function AC_EMPTY_GROUP() {
	return function (dispatch) {
		dispatch({ type: "EMPTY_GROUP" })
	};
}
export function AC_ADD_GROUP(formData) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.addGroup, formData)
			.then((res) => {
				// Success(res.statusText);
				dispatch({ type: "LIST_GROUP", payload: res.data })
				dispatch({ type: "RESET_GROUP" })
			}).catch(({ response }) => { Error(response.statusText) });
	};
}

export function AC_LIST_GROUP(params = {}) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.listGroup, params)
			.then((res) => {
				let list = ReplaceArrayAtIndex(res.data.allData, 3);
				dispatch({ type: "PUSH_DATA_LANDING", payload: list })
				dispatch({ type: "LIST_GROUP", payload: res.data.data, total: res.data.total, spinner: false })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_VIEW_GROUP(data) {
	return function (dispatch) {
		// return axios.get(ImportedURL.API.viewGroup + "/" + id)
		// 	.then((res) => {
		dispatch({ type: "VIEW_GROUP", payload: data })
		// }).catch((err) => { console.log(err); });
	};
}

export function AC_UPDATE_GROUP(formData, id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.updateGroup + "/" + id, formData)
			.then((res) => {
				Success(res.statusText);
				dispatch({ type: "LIST_GROUP", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

export function AC_DELETE_GROUP(id) {
	return function (dispatch) {
		return axios.post(ImportedURL.API.deleteGroup + "/" + id)
			.then((res) => {
				// Success(res.statusText);
				dispatch({ type: "LIST_GROUP", payload: res.data })
			}).catch((err) => { console.log(err); });
	};
}

import React, { Component } from 'react'
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import AggridSpinnerComponent from '../Spinner/aggridspinner';

class AgGridDragAndDropComponent extends Component {
    render() {
        const { spinner, rowData, onRowClicked, onGridReady, onFilterChanged, onRowDragEnd, state } = this.props;
        const { defaultColDef, columnDefs } = state;
        return (
            <>
                <div className="table-responsive">
                    <div className="ag-theme-alpine">
                        <AgGridReact
                            rowHeight={82}
                            paginationPageSize={25}
                            rowData={rowData}
                            onRowClicked={(e) => onRowClicked(e)}
                            domLayout={"autoHeight"}
                            defaultColDef={defaultColDef}
                            onGridReady={(e) => onGridReady(e)}
                            columnDefs={columnDefs}
                            onRowDragEnd={(e) => onRowDragEnd(e)}
                            rowDragManaged={true}
                            suppressMoveWhenRowDragging={true}
                            onFilterChanged={(e) => onFilterChanged(e)}
                        >
                        </AgGridReact>
                    </div>
                    <AggridSpinnerComponent spinner={spinner} />
                </div>
            </>
        )
    }
}
export default AgGridDragAndDropComponent
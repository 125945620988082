const initialState = {
	listnotifications: [],
	total: 0
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'LIST_NOTIFICATION':
			return Object.assign({}, state, {
				listnotifications: action.payload,
				total: action.total,
			})

		default:
			return state;
	}
}

const initialState = {
	roomareaamenity: {
		amenity: '',
		hotel: '',
		type: '',
		icon: ''
	},
	listroomareaamenities: [],
	total: 0,
	listsettingamenities: [],
	spinner: true
}

export default (state = initialState, action) => {
	switch (action.type) {
		case 'HANDLE_ROOMAREA_AMENITY_CHANGE':
			return Object.assign({}, state, {
				roomareaamenity: {
					...state.roomareaamenity,
					[action.name]: action.value
				}
			})
		case 'RESET_ROOMAREA_AMENITY':
			return Object.assign({}, state, {
				roomareaamenity: initialState.roomareaamenity
			})
		case 'LIST_ROOMAREA_AMENITY':
			return Object.assign({}, state, {
				listroomareaamenities: action.payload,
				total: action.total,
				spinner: false
			})
		case 'LIST_SETTING_ROOMAREA_AMENITY':
			return Object.assign({}, state, {
				listsettingamenities: action.payload,
				spinner: false

			})
		case 'VIEW_ROOMAREA_AMENITY':
			return Object.assign({}, state, {
				roomareaamenity: action.payload,
				spinner: false,
			})
		case 'EMPTY_ROOMAREA_AMENITY':
			return Object.assign({}, state, {
				roomareaamenity: {
					amenity: '',
					hotel: '',
					type: '',
					icon: ''
				},
				spinner: true
			})
		case 'EMPTY_ARRAY_ROOMAREA_AMENITY':
			return Object.assign({}, state, {
				roomareaamenity: {
					amenity: '',
					hotel: '',
					type: '',
					icon: ''
				},
				listroomareaamenities: [],
			})
		case 'HANDLE_KEY_SEARCH':
			return Object.assign({}, state, {
				listsettingamenities: action.value
			})
		default:
			return state;
	}
}
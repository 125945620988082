import React, { Component } from 'react'
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from "@fullcalendar/interaction";
import timeGridPlugin from '@fullcalendar/timegrid';
import tippy from 'tippy.js';
import 'tippy.js/animations/scale.css';
import SpinnerComponent from '../../../utilities/Spinner/spinner';
import moment from 'moment-timezone';

class BigCalendar extends Component {
    calendarView = (id, startdate) => {
        this.props.viewTodo(id, startdate);
    }
    renderEventContent = (eventInfo) => {
        const title = eventInfo.event.title;
        const id = eventInfo.event.id;
        const startdate = eventInfo.event.startdate;
        return (
            <>
                <div >
                    <p onClick={() => this.calendarView(id, startdate)} id={id} data-toggle="modal" data-target="#exampleTodoModal">{title}</p>
                </div>
            </>
        )
    }
    render() {
        const { rowData, spinner, fullCalendarRef, calendarType, AccountState } = this.props;
        const { tzone } = AccountState.account;
        const currentDate = moment(new Date()).tz(tzone).format("YYYY-MM-DDTHH:mm:ss");
        const events = rowData.map((e) => {
            let obj = {
                id: e.id,
                title: e.title,
                description: e.description,
                start: e.start + "T10:00:00",
                end: e.start + "T11:00:00",
                color: '#1c3078',
            }
            return obj
        })
        return (
            <>
                <div className="card card-profile">
                    <FullCalendar
                        ref={fullCalendarRef}
                        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
                        displayEventTime={true}
                        headerToolbar={false}
                        eventContent={this.renderEventContent}
                        initialView="dayGridMonth"
                        weekends={true}
                        events={calendarType == 'months' ? rowData : events}
                        editable={true}
                        droppable={true}
                        navLinks={true}
                        dayMaxEvents={true}
                        eventDidMount={(info) => {
                            tippy(info.el, {
                                animation: 'scale',
                                // content: info.event.title,
                                content: ``,
                                allowHTML: true,
                                interactive: true,
                                interactiveDebounce: 75,
                            });
                        }}
                        nowIndicator={true}
                        now={currentDate}
                        allDaySlot={false}
                        dayMaxEventRows={true}
                        views={{
                            timeGridWeek: {
                                dayMaxEvents: true,
                                eventLimit: 4,
                            }
                        }}
                    />
                </div>
                <SpinnerComponent spinner={spinner} />
            </>
        )
    }
}

export default BigCalendar;

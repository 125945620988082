import axios from 'axios';
import moment, { relativeTimeThreshold } from 'moment';
import React, { Component } from 'react'
import { Row, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import Select from 'react-select';
import { bindActionCreators } from 'redux';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import "react-multi-date-picker/styles/backgrounds/bg-dark.css"
import { Capitalize, Imagevalidation, Textareavalidation, Urlvalidate } from '../../common/validate';
import { TimeFormat24 } from '../../common/validate';
import { AC_LIST_ROOM } from '../../actions/roomAction';
import { Phonenumber, Emailvalidate } from '../../common/validate';
import { AC_HANDLE_CONFIGURATION_CHANGE, AC_VIEW_CONFIGURATION, AC_EMPTY_CONFIGURATION, AC_RESET_CONFIGURATION, AC_VIEW_CONFIGOPTIONS, AC_HANDLE_CONFIGOPTIONS_CHANGE } from '../../actions/configurationAction';
import ErrorResponse from '../../utilities/Error/errorstatus';
import {
    SortableContainer,
    SortableElement,
    SortableHandle,
    arrayMove,
} from 'react-sortable-hoc';

const initialState = {
    modalType: "Add",
    id: "",
    previlegeupdated: false,
    saving: false,
    listRedirect: false,
    initial: true,
    owner: false,
    hotelError: false,
    saving: false,
    spinner: false,
    dateCalendar: '',
    userId: '',
    hotelId: '',
    editValue: false,
    fetchData: false,
    viewData: '',
    starttimeValidateError: false,
    endtimeValidateError: false,
    logoSrc: '',
    productname: '',
    typeArray: [],
    hideDrag: false
}


const DragHandle = SortableHandle(() =>
    <>
        <span>
            <i class="fa fa-bars" aria-hidden="true"></i>
        </span>
    </>);

const SortableItem = SortableElement(({ value, length, onChangeItem, typeArray, removeFormFields, addFormFields, hideDrag }) => {
    let i = typeArray.index
    const handleChange = (data, val) => {
        onChangeItem(data, val); // Call the parent component's statusChange function
    };
    const handleAddFormFields = () => {
        addFormFields(); // Call the parent component's statusChange function
    };
    const handleRemoveFormFields = (data) => {
        removeFormFields(data); // Call the parent component's statusChange function
    };
    return (
        <>
            <div className='d-flex align-items-center row'>
                {!hideDrag ?
                    <div className='col-1 config_style_dragHandle mb-4' style={{ cursor: "move" }}>
                        <DragHandle />
                    </div> : ""}

                <div className={hideDrag?"col-lg-12 col-md-12 config_style mb-4":"col-lg-11 col-md-12 config_style mb-4"}>
                    <div style={{ width: "30%" }}>
                        <input type="text" className='form-control' name="type" value={value.type && (value.type[0].toUpperCase() + value.type.substring(1))} onChange={(e) => handleChange(i, e)} placeholder={"Type"} />
                        <div className="invalid-feedback" style={{ display: typeArray.typeArray.includes(i) ? "block" : 'none' }}>Type is required</div>
                    </div>
                    <div style={{ width: "30%" }}>
                        <input type="text" className='form-control' name="suboption" value={value.suboption} onChange={(e) => handleChange(i, e)} placeholder={"Sub Option"} />
                    </div>
                    <div style={{ width: "20%" }}>
                        <div class="custom-control custom-switch confi_guration">
                            <label className="custom-control custom-switch">
                                <input type="checkbox" className="custom-control-input" name="status" checked={value.status} onChange={(e) => handleChange(i, e)} />
                                <span className="custom-control-label" style={{ cursor: 'pointer' }}>Status</span>
                            </label>
                        </div>
                    </div>
                    <div style={{ width: "100px" }}>
                        <div className='config_increment'>
                            {length > 1 ?
                                < span className='plus_minus' > <i className="fa fa-minus-circle" aria-hidden="true" onClick={() => handleRemoveFormFields(i)}></i></span>
                                : ''}
                            <span className='plus_minus ml-3'><i className="fa fa-plus-circle" aria-hidden="true" onClick={() => handleAddFormFields(value)}></i></span>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
});
const SortableList = SortableContainer(({ items, onChangeItem, typeArray, removeFormFields, addFormFields, hideDrag }) => {
    return (
        <ul style={{ paddingLeft: '0px' }}>
            {items.map((value, index) => (
                <SortableItem key={`item-${index}`} value={value} index={index} length={items.length} onChangeItem={onChangeItem} typeArray={{ typeArray, index }} removeFormFields={removeFormFields} addFormFields={addFormFields} hideDrag={hideDrag} />
            ))}
        </ul>
    );
});
class AddVendor extends Component {
    constructor(props) {
        super(props);
        this.state = (initialState)
    }

    HotelChange = e => {
        const { name, value, label } = e;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        this.props.HandleChange(name, value);
    }

    normalChange = e => {
        const { name, value } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        if (name == "name") {
            this.props.HandleChange(name, value);
            this.props.HandleChange('slug', value.replaceAll(' ', '-'));
            this.setState({ slugError: false });
        }
    }
    TypeError = () => {
        let dataOption = this.props.ConfigurationState.viewConfigOption.options;
        let valid = 1
        if (dataOption && dataOption.length > 0) {
            let typeArray = []
            dataOption.map((item, i) => {
                if (!item.type) {
                    valid = 0
                    typeArray.push(i)
                }
            })
            this.setState({ typeArray: typeArray })
        }
        return valid
    }
    submit = () => {
        let id = this.props.match.params.id;
        const { AccountState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        let data = this.props.ConfigurationState.viewConfiguration;
        let dataOption = this.props.ConfigurationState.viewConfigOption.options;
        let isValid = true;
        data['name'] = data.name.trim()
        if (id) {
            if (dataOption && dataOption.length == 0) {
                this.TypeError()
                isValid = false
            } else if (dataOption && dataOption.length > 0) {
                dataOption.map((e, i) => {
                    e['type'] = e.type.trim()
                    if (e.type == '') {
                        this.TypeError()
                        isValid = false
                    }
                })
            }
        }
        if (!data.name) {
            this.props.HandleChange('slug', '');
            this.setState({ nameError: true });
            isValid = false;
        }
        var formData = {
            name: data.name,
            slug: data.slug,
            options: dataOption,
        }
        if (isValid) {
            if (this.state.modalType == "Add") {
                this.setState({ saving: true })
                axios.post(ImportedURL.API.addConfiguration, data)
                    .then((res) => {
                        this.setState({ saving: false })
                        if (res) {
                            Success((languageData && languageData.CONFIGURATION ? languageData.CONFIGURATION : 'Configuration') + (languageData && languageData.CREATED_SUCCESSFULLY ? languageData.CREATED_SUCCESSFULLY : " created successfully"));
                            this.props.history.push('/edit-configuration/' + res.data._id)
                            this.props.AC_VIEW_CONFIGURATION(res.data._id);
                        }
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        let alreadyExistField = (languageData && languageData.NAME_AND_SLUG) ? languageData.NAME_AND_SLUG : 'Name and Slug'
                        ErrorResponse(response, languageData, '', alreadyExistField)
                    });
            } else {
                this.setState({ saving: true })
                axios.post(ImportedURL.API.updateConfiguration + '/' + id, formData)
                    .then((res) => {
                        this.setState({ saving: false })
                        Success((languageData && languageData.CONFIGURATION ? languageData.CONFIGURATION : 'Configuration') + (languageData && languageData.UPDATED_SUCCESSFULLY ? languageData.UPDATED_SUCCESSFULLY : " updated successfully"));
                        this.setState({ listRedirect: true })
                    }).catch(({ response }) => {
                        this.setState({ saving: false })
                        ErrorResponse(response, languageData)
                    });
            }
        }
    }
    onChangeItem = (i, e) => {
        const { AccountState, ConfigurationState } = this.props;
        const { name, value, checked } = e.target;
        const Error = name + "Error";
        this.setState({ [Error]: false });
        const data = ConfigurationState.viewConfigOption.options
        var temp = [
            ...data.slice(0, i),
            { ...data[i], [name]: name == "status" ? checked : value },
            ...data.slice(i + 1)
        ]
        this.props.AC_HANDLE_CONFIGOPTIONS_CHANGE('options', temp);
        if (name == 'type') {
            let arr = this.state.typeArray.filter((e) => e != i)
            this.setState({ typeArray: arr })
        }
        this.setState({ hideDrag: true })
    }
    removeFormFields = (index) => {
        const { ConfigurationState } = this.props;
        const data = ConfigurationState.viewConfigOption.options;

        if (data.length > 1) {
            var temp = [...data.filter((event, i) => i !== index)]
            this.props.AC_HANDLE_CONFIGOPTIONS_CHANGE('options', temp);
        }
    }

    addFormFields = () => {
        const { ConfigurationState } = this.props;
        const data = ConfigurationState.viewConfigOption.options;
        var temp = [...data]
        this.props.AC_HANDLE_CONFIGOPTIONS_CHANGE('options', [...temp, { type: '', status: true }]);
    }
    componentDidMount() {
        this.props.EmptyConfiguration()
        this.props.ListHotel();
        const { params, path } = this.props.match;
        if (params.id) {
            this.props.AC_VIEW_CONFIGURATION(params.id);
            this.props.AC_VIEW_CONFIGOPTIONS(params.id);
            this.setState({ modalType: "Edit", id: params.id, spinner: true })
        } else {
            this.props.AC_RESET_CONFIGURATION();
        }
    }

    onSortEnd = ({ oldIndex, newIndex }) => {
        const languageData = this.props.AccountState.account.SelectedlanguageData
        const data = this.props.ConfigurationState.viewConfigOption.options
        let arr = arrayMove(data, oldIndex, newIndex)
        this.props.EmptyConfiguration()
        arr = arr.map((item, index) => {
            return {
                ...item,
                sort: index + 1 // Index starts from 0, so adding 1 for human-readable sort
            };
        });
        const { params } = this.props.match;
        axios.post(ImportedURL.API.sortConfigOptions, { data: arr })
            .then((res) => {
                this.props.AC_VIEW_CONFIGURATION(params.id);
                this.props.AC_VIEW_CONFIGOPTIONS(params.id);
            }).catch(({ response }) => {
                ErrorResponse(response, languageData)
                this.props.AC_VIEW_CONFIGURATION(params.id);
                this.props.AC_VIEW_CONFIGOPTIONS(params.id);
            });
    };
    render() {
        if (this.state.listRedirect) {
            return <Redirect to={'/list-configuration'} />
        }
        const { AccountState, ConfigurationState } = this.props;
        const languageData = AccountState.account.SelectedlanguageData
        const data = ConfigurationState.viewConfiguration;
        const dataOptions = ConfigurationState.viewConfigOption;
        const dataSpinner = ConfigurationState.spinner;
        const { params } = this.props.match;
        return (
            <>
                <div>

                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-configuration"><h6> {languageData && languageData.CONFIGURATION ? languageData.CONFIGURATION : "Configuration "}{languageData && languageData.LIST ? languageData.LIST : " List"} </h6></Link> <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <h6 className="highlights_breadcrump" style={{ cursor: "pointer" }}>{this.state.modalType} {languageData && languageData.CONFIGURATION ? languageData.CONFIGURATION : " Configuration"} </h6></p>
                    </div>

                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="tab-content">
                                <div
                                    className="tab-pane fade show active"
                                    id="Departments-list"
                                    role="tabpanel"
                                >
                                    <div className="card">
                                        <div className="card-body">

                                            <div className="header-action" style={{ marginTop: '5px' }}>
                                                <Link to='/list-configuration'><button type="button" className="btn btn-primary" id='Add'><i className="fa fa-arrow-left mr-2"></i>Back</button></Link>
                                            </div>


                                            <h3 className="card-title d-flex "> <div className='rounded_icon'><i className="fa fa-cogs mr-2 "></i></div><span className='pt-2'> {this.state.modalType} {languageData && languageData.CONFIGURATION ? languageData.CONFIGURATION : " Configuration"} </span> </h3>
                                            <form id='form_reset'>
                                                <Row >
                                                    <div className="col-lg-4 col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Name<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" name='name' value={data.name} onChange={this.normalChange} placeholder="Name" />
                                                            <div className="invalid-feedback" style={{ display: this.state.nameError ? "block" : 'none' }}>Name is required</div>
                                                        </div>
                                                    </div>
                                                    <div className="col-lg-4 col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Slug</label>
                                                            <input type="text" className="form-control" name='slug' value={data.slug} onChange={this.normalChange} placeholder="Slug" />
                                                            <div className="invalid-feedback" style={{ display: this.state.slugError ? "block" : 'none' }}>Slug is required</div>
                                                        </div>
                                                    </div>
                                                </Row>

                                                {params && params.id ?
                                                    <div style={{ margin: '15px' }}>
                                                        <label className="form-label">Options<span className="ml-1" style={{ color: 'red' }}>*</span></label>
                                                        <SortableList
                                                            items={dataOptions.options}
                                                            onChangeItem={this.onChangeItem}
                                                            typeArray={this.state.typeArray}
                                                            removeFormFields={this.removeFormFields}
                                                            addFormFields={this.addFormFields}
                                                            onSortEnd={this.onSortEnd} // Pass onSortEnd prop here
                                                            useDragHandle={true}
                                                            helperClass="dragging"
                                                            hideDrag={this.state.hideDrag}
                                                        />
                                                    </div> : ""}

                                            </form>
                                        </div>
                                        {this.state.modalType == 'Edit' ?
                                            <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                <div className="text-right">
                                                    {
                                                        this.state.saving ?
                                                            <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Updating</button>
                                                            :
                                                            <button type="submit" className="btn commor_save" onClick={this.submit}>
                                                                <i className="fe fe-save mr-2"></i>Update
                                                            </button>
                                                    }
                                                </div>
                                            </div> :
                                            <div className="card-footer text-right mandatory">
                                                <label className="form-label text-left mandatory-label"><span className="mr-1" style={{ color: 'red' }}>*</span>Mandatory Fields </label>
                                                <div className="text-right">
                                                    {
                                                        this.state.saving ?
                                                            <button type="button" className="btn commor_save" ><i className="fa fa-spinner fa-spin mr-2"></i>Saving</button>
                                                            :
                                                            <button type="submit" className="btn commor_save" onClick={this.submit}>
                                                                <i className="fe fe-save mr-2"></i>Save
                                                            </button>
                                                    }
                                                </div>
                                            </div>
                                        }
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="overlay" style={{ display: this.state.spinner ? (dataSpinner ? 'block' : 'none') : 'none' }}></div>
                {
                    (this.state.modalType != "Add") && this.state.spinner && (dataSpinner &&
                        <div className='common_loader'>
                            <img className='loader_img_style_common' src='../../assets/images/load.png' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                        </div>
                    )
                }
            </>
        )
    }
}
const mapStateToProps = state => ({
    AccountState: state.account,
    ConfigurationState: state.configuration,
    RoomState: state.room,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

        HandleChange: AC_HANDLE_CONFIGURATION_CHANGE,
        AC_VIEW_CONFIGURATION,
        AC_RESET_CONFIGURATION,
        AC_VIEW_CONFIGOPTIONS,
        AC_HANDLE_CONFIGOPTIONS_CHANGE,
        EmptyConfiguration: AC_EMPTY_CONFIGURATION,
        ListHotel: AC_LIST_HOTEL,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(AddVendor);
import React, { Component } from 'react'
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import 'ag-grid-community/dist/styles/ag-theme-balham.css';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import { AC_EMPTY_ROOMAREA_AMENITY, AC_HANDLE_ROOMAREA_AMENITY_CHANGE, AC_LIST_ROOMAREA_AMENITY, AC_RESET_ROOMAREA_AMENITY, AC_UPDATE_ROOMAREA_AMENITY, AC_VIEW_ROOMAREA_AMENITY, } from '../../actions/roomareaAmenityAction';
import ImportedURL from '../../common/api';
import { Error, Success } from '../../common/swal';
import Select from 'react-select';
import jsPDF from 'jspdf';
import { AC_LIST_CATEGORY } from '../../actions/categoryAction';
import Spinner from 'react-bootstrap/Spinner';
import { AC_LIST_HOTEL } from '../../actions/hotelAction';
import { Link, Redirect } from 'react-router-dom';
import { imageCellRendererList, onErrorImage } from '../../common/validate';
import ErrorResponse from '../../utilities/Error/errorstatus';
import showSwalDialog from '../../utilities/Modal/swal';



const initialState = {
    modalType: "Add",
    columnApi: '',
    gridApi: '',
    columnDefs: [
        { headerName: '#', valueGetter: "node.rowIndex+1", width: 50, sortable: false, filter: false, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', hide: 'true' },
        {
            headerName: 'Logo', width: 100, field: 'logo', hide: true, sortable: false, filter: false, "resizable": false, cellStyle: { 'text-align': 'center' },
            cellRenderer: imageCellRendererList,
        },
        {
            headerName: 'Hotel', field: 'hotelName', hide: true, width: 130, cellStyle: { 'text-align': 'left' },
            cellRenderer: function (params) {
                if (params?.value) {
                    return params.data.hotelName
                } else if (params.value == "") {
                    return 'All'
                }
            }
        },
        { headerName: 'Room Type', field: 'type', width: 200, floatingFilter: true },
        {
            headerName: 'Amenity Icon', width: 150, field: 'icon', sortable: false, filter: false, "resizable": false, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header',
            cellRenderer: function (params) {
                return params.data ? `<img src=${ImportedURL.FILEURL + params.data?.icon} alt='' width='65px' height='65px' padding='10px' class="contain_image"/>` : '';
            }
        },
        { headerName: 'Room Amenity', field: 'amenity', width: 200, floatingFilter: true },
        {
            headerName: 'Status', field: 'status', width: 170, cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true,
            cellRenderer: function (params) {
                if (params.data && params.data.status) {
                    return `<span type="button" class="tag tag-green" data-action-type="Status">Active</span>`;
                } else if (params.value == 0) {
                    return '<span type="button" class="tag tag-danger" data-action-type="Status">Inactive</span>';
                }
            }
        },
        {
            headerName: 'Actions', width: 200, field: 'actions', cellStyle: { 'text-align': 'center' }, headerClass: 'ag-center-header', suppressMenu: true, sortable: false, filter: false,
            cellRenderer: function (params) {
                if (params.value) {
                    return params.value;
                }
            }
        },

    ],
    defaultColumDef: {
        // "width": '100%',
        // "filter": true,
        // "sortable": true,
        // "resizable": true,
        editable: false,
        sortable: true,
        resizable: true,
        filter: true,
        flex: 1,
        minWidth: 100,
    },
    amenityError: false,
    roomnameError: false,
    id: '',
    initial: true,
    saving: false,
    editSpi: false,
    searchhotel: '',
    searchhoteloption: "",
    hotelName: '',
    hideReset: false,
    perPage: 25,
    columnreset: true
}

class ListRoomareaAmenity extends Component {
    constructor(props) {
        super(props);
        this.state = initialState
    }

    onRowClicked = event => {
        const rowValue = event.event.target;
        const value = rowValue.getAttribute('data-action-type');
        const languageData = this.props.AccountState.account.SelectedlanguageData
        if (value === 'View') {
            this.props.EmptyRoomareaAmenity();
            this.props.ViewRoomareaAmenity(event.data._id);
        }
        if (value === 'Edit') {
            this.props.EmptyRoomareaAmenity();
            this.setState({ modalType: "Edit", id: event.data._id });
            this.props.HandleChange('hotel', "All");
        }
        if (value === 'Delete') {
            showSwalDialog('Are you sure you want to delete?', '', 'Ok', 'Cancel', 'assets/images/delete.png').then((result) => {
                if (result.isConfirmed) {
                    this.setState({ searchhoteloption: "", searchhotel: '' })
                    axios.get(ImportedURL.API.softDeleteRoomareaAmenity + '/' + event.data._id)
                        .then((res) => {
                            Success('Room amenity deleted successfully');
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);;
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        })
                }
            })
        }
        if (value === 'Status') {
            showSwalDialog('Are you sure you want to change the status?', '', 'Ok', 'Cancel', 'assets/images/status.png').then((result) => {
                if (result.isConfirmed) {
                    axios.post(ImportedURL.API.statusChange, { id: event.data._id, status: !event.data.status, model: 'roomamenities' })
                        .then((data) => {
                            this.setState({ searchhoteloption: "", searchhotel: '' })
                            const dataSource = this.getDataSource()
                            this.gridApi.setDatasource(dataSource);;
                            Success('Status updated successfully')
                        }).catch(({ response }) => {
                            ErrorResponse(response, languageData)
                        });
                }
            })
        }
    }

    componentDidMount() {
        this.props.ListHotel();
        const { AccountState } = this.props;
        this.setState({ hotelName: AccountState.account.hotelName })
    }

    exportToCSV = () => {
        const account = this.props.AccountState.account;
        var firstRow = this.gridApi.getFirstDisplayedRow();
        var lastRow = this.gridApi.getLastDisplayedRow();
        this.gridApi.exportDataAsCsv({
            columnKeys: ['hotelName', 'type', 'amenity'],
            fileName: 'roomareaamenities' + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".csv",
            shouldRowBeSkipped: (params) => {
                return params.node.rowIndex < firstRow || params.node.rowIndex > lastRow;
            },
            processCellCallback: function (params) {
                return params.value ? params.value : "---";
            }
        })
    }
    exportPDF = () => {
        const account = this.props.AccountState.account;
        const exportData = this.props.RoomareaAmenityState.listroomareaamenities;
        const selectedProperties = (this.state.hotelName && this.state.hotelName != "All") ? ['type', 'amenity'] : ['hotelName', 'type', 'amenity'];
        const selected = exportData.map(item => {
            const selectedData = {};
            selectedProperties.forEach(property => {
                if (item.hasOwnProperty(property)) {
                    selectedData[property] = item[property] ? item[property] : "---";
                }
            });
            return selectedData;
        });
        let data = {
            title: (this.state.hotelName) ? (this.state.hotelName != "All" ? this.state.hotelName + " - Room Amenities List" : "Room Amenities List") : "Room Amenities List",
            head: (this.state.hotelName && this.state.hotelName != "All") ? ['#', 'Room Type', 'Room Amenity'] : ['#', 'Hotel', 'Room Type', 'Room Amenity'],
            body: selected,
            hotelNameExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hotelNameExport : '',
            hoteladdressExport: (this.state.hotelName && this.state.hotelName != "All") ? account.hoteladdressExport : '',
            limit: this.state.perPage,
        }
        this.setState({ spinner: true })
        axios.post(ImportedURL.API.downloadPdf, data, { responseType: 'blob' })
            .then((res) => {
                const blob = new Blob([res.data], { type: 'application/pdf' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = "roomareaamenities" + "_" + ((this.state.hotelName && this.state.hotelName != "All") ? (this.state.hotelName).replace(/ /g, '_') : "") + (account.currentTime).replace(/ /g, '_') + ".pdf"
                link.click();
                link.remove();
                window.URL.revokeObjectURL(link.href);
                this.setState({ spinner: false });
            }).catch(({ err }) => {
                this.setState({ spinner: false })
            })
    }

    searchUser = e => {
        const { name, value, label } = e;
        // this.props.ListRoomareaAmenity({ hotel: value });
        const dataSource = this.getDataSource({ hotel: value })
        this.gridApi.setDatasource(dataSource);;
        this.setState({ searchhoteloption: e, searchhotel: value, hotelName: label, hideReset: true })
    }
    filterReset = e => {
        this.setState({ selectedOption: '', searchhoteloption: "", hotel: '', hotelName: '', searchhotel: "" });
        const dataSource = this.getDataSource()
        this.gridApi.setDatasource(dataSource);
        this.setState({ hideReset: false })
    }
    getDataSource = (query = {}) => {
        return {
            getRows: async (params) => {

                // Grid params for filter and sort
                const page = Math.floor(params.startRow / this.state.perPage);
                const filter = params.filterModel;
                const sortModel = params.sortModel;
                // Action call for hotels list
                this.props.EmptyRoomareaAmenity();
                await this.props.ListRoomareaAmenity({ hotel: this.state.searchhotel, perPage: this.state.perPage, page: page, filter: filter, sort: sortModel.length ? sortModel[0] : {}, ...query, });
                const rowData = this.props.RoomareaAmenityState.listroomareaamenities;

                const total = this.props.RoomareaAmenityState.total;
                const previleges = this.props.AccountState.previleges;
                const Previlege = previleges.find(obj => { return obj.name == "Room_Amenity" });
                const deleteOption = ImportedURL.SUPERADMIN.includes(this.props.AccountState.role) ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : '';
                const editOption = Previlege?.edit ? '<button title="Edit" type="button" class="btn btn-icon" data-action-type="Edit" ><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
                const template =
                    editOption
                    + deleteOption
                    + '</div>';

                if (rowData) {
                    rowData.forEach(object => {
                        object.actions = template;
                    });
                }
                const lbLastRowOnPageEl = document.querySelector(`[ref=\'lbLastRowOnPage\']`);
                if (lbLastRowOnPageEl) {
                    lbLastRowOnPageEl.innerHTML = (rowData) ? rowData.length + params.startRow : 0;
                }
                params.successCallback(rowData, total);

            }
        }
    }
    componentDidUpdate(prevProps, prevState) {
        const role = this.props.AccountState.role;
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role) && this.state.columnreset) {
            this.UpdateColumnDefs();
            this.setState({ columnreset: false })
        }
        if (this.gridApi) {
            if (this.props.AccountState.previleges && prevState.gridApi !== this.state.gridApi) {
                const dataSource = this.getDataSource()
                this.gridApi.setDatasource(dataSource);
            }
        }
        if (prevState.perPage !== this.state.perPage) {
            this.refreshGridDataSource();
        }
    }
    onGridReady = (params) => {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        this.setState({ gridApi: params.api })
        this.refreshGridDataSource();
    };

    refreshGridDataSource = (params) => {
        const newDataSource = this.getDataSource(params);
        if (this.gridApi) {
            this.gridApi.setDatasource(newDataSource);
        }
        this.UpdateColumnDefs();
    };

    onPerPageChange = (newPerPage) => {
        this.setState({ perPage: newPerPage })
        this.refreshGridDataSource({ perPage: newPerPage });
    };

    UpdateColumnDefs = () => {
        const role = this.props.AccountState.role;
        if (this.gridApi && role && ImportedURL.SUPERADMIN.includes(role)) {
            let updatedColumnDefs = [...this.state.columnDefs];
            updatedColumnDefs = updatedColumnDefs.map((col) => {
                if (col.field === 'hotelName' || col.field === 'logo') {
                    return { ...col, hide: false };
                }
                return col;
            });
            this.gridApi.setColumnDefs(updatedColumnDefs);
        }
    }

    render() {

        const { RoomareaAmenityState, AccountState, HotelState } = this.props;
        const listHotelData = HotelState.listhotels;
        const account = AccountState.account;
        const rowData = RoomareaAmenityState.listroomareaamenities;
        const data = RoomareaAmenityState.roomareaamenity;
        const languageData = AccountState.account.SelectedlanguageData
        const ViewData = RoomareaAmenityState.roomareaamenity;
        const spinner = RoomareaAmenityState.spinner;
        const previleges = AccountState.previleges;
        const Previlege = previleges.find(obj => { return obj.name == "Room_Amenity" });
        const deleteOption = ImportedURL.SUPERADMIN.includes(AccountState.role) ? '<button type="button" class="btn btn-icon js-sweetalert" title="Delete" data-action-type="Delete"><i class="fa fa-trash-o text-danger" style="color: red !important" data-action-type="Delete"/></button>' : '';
        const editOption = Previlege?.edit ? '<button title="Edit" type="button" class="btn btn-icon" data-action-type="Edit" ><i class="fa fa-edit" style="color: #2196F3 !important" data-action-type="Edit"></i></button>' : '';
        const template =
            editOption
            + deleteOption
            + '</div>';

        if (rowData) {
            rowData.forEach(object => {
                object.actions = template;
            });
        }

        const limitOptions = [
            { value: '25', label: '25' },
            { value: '50', label: '50' },
            { value: '100', label: '100' }
        ]

        if (this.state.modalType === "Edit") return <Redirect to={'edit-roomarea-amenity/' + this.state.id} />
        var hotelName = ViewData.hotelName;
        var logo = ViewData.logo;
        var roomname = ViewData.roomname;

        var hotelOptionSearch = [];
        if (listHotelData.length > 1) {
            hotelOptionSearch.push({ label: 'All', value: 'All', name: 'searchhotel' })
        }
        if (listHotelData.length > 0) {
            listHotelData.filter(filterItem => filterItem.status === true).map(item => {
                hotelOptionSearch.push({ label: `${item.name} ${item.city} ${item.state}`, value: item._id, logo: item.logo, name: 'searchhotel' });
            })
        }

        return (
            <>
                <div>
                    <div>
                        <div className="breadcrump">
                            <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/list-roomarea-amenity"><h6 className="highlights_breadcrump">{languageData && languageData.ROOM_AMENITIES ? languageData.ROOM_AMENITIES : "Room Amenities"}{languageData && languageData.LIST ? languageData.LIST : ' List'} </h6></Link></p>
                        </div>
                        <div className="section-body pt-3 user_sec">
                            <div className="container-fluid">
                                <div className="tab-content">
                                    <div className="tab-pane fade show active" id="Departments-list" role="tabpanel">
                                        <div className="card">
                                            <div className="card-header tit_res">
                                                <div className='d-flex'>
                                                    <div className='rounded_icon'><i className="fa fa-modx mr-2 "></i></div>
                                                    <div className='d-flex align-items-center'>
                                                        <h3 className="card-title">{languageData && languageData.ROOM_AMENITIES ? languageData.ROOM_AMENITIES : "Room Amenities"}{languageData && languageData.LIST ? languageData.LIST : ' List'}</h3>
                                                    </div>
                                                </div>

                                                <div className="card-options">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div className="header-action" >
                                                            {Previlege?.add ? <Link to='/add-roomarea-amenity'><button type="button" className="btn btn-primary" id='Add'><i className="fe fe-plus mr-2" id='Add' />Add</button></Link> : ''}
                                                        </div>
                                                    </div>
                                                    {rowData && rowData.length > 0 ?
                                                        <>
                                                            <a className="btn btn-primary btn-sm nav-link dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false"><i className="fa fa-download mr-2" aria-hidden="true"></i>Export</a>
                                                            <div className="dropdown-menu">
                                                                <a className="dropdown-item" type='button' onClick={this.exportToCSV}><i className="dropdown-icon fa fa-file-excel-o"></i> Excel</a>
                                                                <a className="dropdown-item" type='button' onClick={this.exportPDF}><i className="dropdown-icon fa fa-file-pdf-o"></i> PDF</a>
                                                            </div>
                                                        </>
                                                        : []}
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-2 col-md-2 col-sm-2 col-xl-3">
                                                        <label className="form-label">{languageData && languageData.LIMIT ? languageData.LIMIT : "Limit"}</label>
                                                        <div className="form-group">
                                                            <Select
                                                                onChange={(e) => this.onPerPageChange(Number(e.value))}
                                                                options={limitOptions}
                                                                defaultValue={limitOptions[0]}
                                                                className='limit_size'
                                                            />
                                                        </div>
                                                    </div>
                                                    {
                                                        account?.allhotels == undefined &&
                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-xl-6">
                                                            <label className="form-label">{languageData && languageData.SEARCH ? languageData.SEARCH : "Search"}  {languageData && languageData.HOTEL ? languageData.HOTEL : "Hotel"}</label>
                                                            <div className="form-group" style={{ marginBottom: '10px' }}>
                                                                <Select
                                                                    value={this.state.searchhoteloption}
                                                                    onChange={this.searchUser}
                                                                    options={hotelOptionSearch}
                                                                    formatOptionLabel={(e) => (
                                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                            {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                                                                            <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </div>
                                                        </div>
                                                    }
                                                    {this.state.hideReset ?
                                                        <div className="col-lg-2 col-md-4 col-sm-6 col-xl-3 d-flex align-items-center">
                                                            <button className='btn social_rest_btn btn-primarys' type='button' onClick={this.filterReset}><i className="fa fa-refresh" aria-hidden="true" style={{ fontSize: '20px !importent', marginRight: '7px' }}></i> Reset</button>
                                                        </div> : ''}
                                                </div>
                                                <div className="table-responsive">
                                                    <div className={(rowData && rowData.length == 0) ? "ag-theme-alpine ag_grid_no_record_show" : "ag-theme-alpine"}>
                                                        <AgGridReact
                                                            key={this.state.perPage}
                                                            rowHeight={82}
                                                            paginationPageSize={this.state.perPage}
                                                            cacheBlockSize={this.state.perPage}
                                                            pagination={true}
                                                            onRowClicked={this.onRowClicked}
                                                            domLayout={"autoHeight"}
                                                            defaultColDef={this.state.defaultColumDef}
                                                            onGridReady={this.onGridReady}
                                                            columnDefs={this.state.columnDefs}
                                                            rowDragManaged={true}
                                                            wrapText={true}
                                                            animateRows={true}
                                                            overlayNoRowsTemplate={"No rows to display"}
                                                            rowModelType={'infinite'}
                                                        >
                                                        </AgGridReact>
                                                    </div>
                                                    {
                                                        (rowData && rowData.length == 0)
                                                        &&
                                                        <span className='ag_grid_no_record_show_span' >No rows to display</span>
                                                    }
                                                    <div id="overlay" style={{ display: spinner ? 'block' : 'none' }}></div>
                                                    {spinner ?
                                                        <div className='common_loader_ag_grid'>
                                                            <img className='loader_img_style_common_ag_grid' src='../../assets/images/load.png' />
                                                            <Spinner className='spinner_load_common_ag_grid' animation="border" variant="info" >
                                                            </Spinner>
                                                        </div>
                                                        : ''}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
const mapStateToProps = state => ({
    RoomareaAmenityState: state.roomareaamenity,
    AccountState: state.account,
    HotelState: state.hotel,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleChange: AC_HANDLE_ROOMAREA_AMENITY_CHANGE, ListRoomareaAmenity: AC_LIST_ROOMAREA_AMENITY, ViewRoomareaAmenity: AC_VIEW_ROOMAREA_AMENITY, ResetRoomareaAmenity: AC_RESET_ROOMAREA_AMENITY, UpdateRoomareaAmenity: AC_UPDATE_ROOMAREA_AMENITY
        , ListCategory: AC_LIST_CATEGORY, EmptyRoomareaAmenity: AC_EMPTY_ROOMAREA_AMENITY,
        ListHotel: AC_LIST_HOTEL
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ListRoomareaAmenity);
import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { AC_HANDLE_TAB_CHANGE_TICKET_WORKFLOLW } from '../../actions/ticketworkflow';
import Assign from './assign';
import Complete from './complete';
import Create from './create';
import Edit from './edit';
import Received from './received';
import Reopen from './reopen';
import Inprogress from './inprogress';

const initialState = {
    isShowMulti: false,
    isShowIndividual: false
}
class TicketFlow extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }
    componentDidMount() {
        // axios.get(ImportedURL.API.defaultTicketFlow)
        //     .then((res) => {
        //     }).catch(({ response }) => {
        //         if (response.status == 500) {
        //            Error(response.status +(languageData && languageData.INTERNAL_SERVER_ERROR ? languageData.INTERNAL_SERVER_ERROR  : ' Internal Server Error'))
        //         } else if (response.status == 502) {
        //            Error(response.status +(languageData && languageData.BAD_GATEWAY ? languageData.BAD_GATEWAY  : ' Bad Gateway'))
        //         } else {
        //             Error(response.statusMessage)
        //         }
        //     })
    }
    tapChange = (e) => {
        this.props.HandleChangeTab(e);
    }
    render() {
        const { TicketworkflowState } = this.props;
        const tabposition = TicketworkflowState.tabposition;
        const spinner = TicketworkflowState.spinner;
        const languageData = this.props.AccountState.account.SelectedlanguageData
        return (
            <>
                <div>
                    <div className="breadcrump">
                        <p> <Link to="/"><h6>{languageData && languageData.DASHBOARD ? languageData.DASHBOARD : "Dashboard"}</h6></Link>  <span><i className="fa fa-angle-right" aria-hidden="true"></i> </span>  <Link to="/ticket-flow"><h6 className="highlights_breadcrump">Ticket Workflow</h6></Link></p>
                    </div>
                    <div className="section-body pt-3">
                        <div className="container-fluid">
                            <div className="row clearfix">
                                <div className="col-lg-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <div className='rounded_icon'><i className="fa fa-ticket mr-2 "></i></div>
                                            <h3 className="card-title">Ticket Workflow</h3>
                                        </div>
                                        <div className='card-body dashboard_ticktets' style={{ paddingBottom: '40px' }}>
                                            <div className='upcommcomp_btn'>
                                                <div className='tb_size' style={{ position: 'relative' }}>
                                                    <button type='button' className={`${tabposition == 'create' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={(e) => this.tapChange("create")} >Create</button>
                                                </div>
                                                <div className='tb_size' style={{ position: 'relative' }}>
                                                    <button type='button' className={`${tabposition == 'inprogress' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={(e) => this.tapChange("inprogress")} >Inprogress</button>
                                                </div>
                                                <div className='tb_size' style={{ position: 'relative' }}>
                                                    <button type='button' className={`${tabposition == 'receive' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={(e) => this.tapChange("receive")} >Receive</button>
                                                </div>
                                                <div className='tb_size' style={{ position: 'relative' }}>
                                                    <button type='button' className={`${tabposition == 'edit' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={(e) => this.tapChange("edit")} >Edit</button>
                                                </div>
                                                <div className='tb_size' style={{ position: 'relative' }}>
                                                    <button type='button' className={`${tabposition == 'assign' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={(e) => this.tapChange("assign")} >Assign</button>
                                                </div>
                                                <div className='tb_size' style={{ position: 'relative' }}>
                                                    <button type='button' className={`${tabposition == 'reopen' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={(e) => this.tapChange("reopen")} >Reopen</button>
                                                </div>
                                                <div className='tb_size' style={{ position: 'relative' }}>
                                                    <button type='button' className={`${tabposition == 'complete' ? 'btn btn-primery after_click ' : 'btn btn-primery before_click'}`} onClick={(e) => this.tapChange("complete")} >Complete</button>
                                                </div>
                                            </div>
                                        </div>
                                        {tabposition == 'create' ?
                                            <>
                                                <Create />
                                            </> : ''}
                                        {tabposition == 'receive' ?
                                            <>
                                                <Received />
                                            </> : ''}
                                        {tabposition == 'assign' ?
                                            <>
                                                <Assign />
                                            </> : ''}
                                        {tabposition == 'edit' ?
                                            <>
                                                <Edit />
                                            </> : ''}
                                        {tabposition == 'reopen' ?
                                            <>
                                                <Reopen />
                                            </> : ''}
                                        {tabposition == 'complete' ?
                                            <>
                                                <Complete />
                                            </> : ''}

                                        {tabposition == 'inprogress' ?
                                            <>
                                                <Inprogress />
                                            </> : ''}
                                    </div >
                                </div >
                            </div >
                        </div >
                    </div >
                </div >
                {/* <div id="overlay" style={{ display: spinner ? 'block' : 'none' }}></div>
                {
                    spinner ?
                        <div className='common_loader'>
                            <img className='loader_img_style_common' src='../../assets/images/load.png' />
                            <Spinner className='spinner_load_common' animation="border" variant="info" >
                            </Spinner>
                        </div> : ''
                } */}
            </>
        )
    }
}


const mapStateToProps = state => ({
    fixNavbar: state.settings.isFixNavbar,
    AccountState: state.account,
    TicketworkflowState: state.ticketworkflow,
})

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        HandleChangeTab: AC_HANDLE_TAB_CHANGE_TICKET_WORKFLOLW,
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketFlow);
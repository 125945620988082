import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import axios from 'axios';
import ReCAPTCHA from "react-google-recaptcha";

import { Success, Error } from '../../common/swal';
import { Emailvalidate } from '../../common/validate';
import Carousel from 'react-bootstrap/Carousel';
import { AC_LOGIN_ADMIN } from '../../actions/accountAction';
import ImportedURL from '../../common/api';

class Login extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			password: '',
			emailError: false,
			emailValidError: false,
			passwordError: false,
			saving: false,
			eye: false,
			eyeCon: false,
			checked: false,
			recaptcha: React.createRef()
		}
		this.secondInputRef = React.createRef();
		this.firstInputRef = React.createRef();
	}
	onChange = e => {
		const { name, value } = e.target;
		const Error = name + "Error";
		const ValidError = name + "ValidError";
		this.setState({ [name]: value, [Error]: false })
		if (name === 'email') {
			this.setState({ email: value });
			var email = value;
			if (email) {
				if (Emailvalidate(email)) {
					this.setState({ [ValidError]: false, [Error]: false })
				} else {
					this.setState({ [ValidError]: true })
				}
			}
			else {
				this.setState({ emailError: true, [ValidError]: false });
			}
		}
	}
	onChangeChecked = e => {
		const { name, value } = e.target;
		const Error = name + "Error";
		const ValidError = name + "ValidError";
		this.setState({ checked: e.target.checked, [Error]: false })
	}
	handleKeyPress = (e) => {
		if (e.key === 'Enter') {
			this.firstInputRef.current.focus();
			e.preventDefault();
			this.login();
		}
		if (e.key === "Enter") {
			this.secondInputRef.current.focus();
		}
	}
	submit = (e) => {
		e.preventDefault();
		this.login();
	}

	login = () => {
		const { email, password } = this.state;
		if (!email) this.setState({ emailError: true });
		if (!password) this.setState({ passwordError: true });
		if (email && password && !this.state.emailValidError) {
			const captchaValue = this.state.recaptcha.current.getValue();
			if (!captchaValue) {
				Error("Please verify the reCAPTCHA!");
				return;
			}
			this.setState({ saving: true })
			axios.get('https://ipapi.co/json/').then((response) => {
				let data = response.data;
				const formData = {
					email: email,
					password: password,
					time: new Date(),
					ip: data.ip,
					city: data.city,
					region: data.region,
					country: data.country_name,
					countrycode: data.country_code,
					latitude: data.latitude,
					longitude: data.longitude,
					postal: data.postal,
					captchaValue: captchaValue
				}
				axios.post(ImportedURL.API.login, formData)
					.then((res) => {
						this.setState({ saving: false })
						if (res.data.status == "1") {
							Success('Logged in successfully');
							localStorage.setItem('acwtoken', res.data.token);
							localStorage.setItem('role', res.data.role);
							if (res.data && res.data.allhotels) {
								localStorage.setItem('hotelslength', res.data.allhotels)
							}
							window.location.href = "/";
							this.setState({ saving: false })
						} else {
							Error(res.data.message)
						}
					}).catch(({ response }) => {
						this.setState({ saving: false })
						Error(response.message)
					});
			}).catch((error) => {
				console.log(error);
			});
		}
	}
	viewClick = () => {
		this.setState({ eye: !this.state.eye })
	}
	viewClickCon = () => {
		this.setState({ eyeCon: !this.state.eyeCon })
	}

	render() {
		return (
			<div className="auth">
				<div className="auth_left">
					<div className="card">
						<div className="text-center mb-2" style={{ width: '100px', height: '100px', margin: ' 0 auto' }}>
							<Link className="header-brand" to="/">
								<img className="avatar w-100 login_logo" src="../assets/images/sitelogo.png" data-toggle="tooltip" data-original-title="Avatar Name" alt="fake_url" style={{ height: '100%', objectFit: 'cover' }} />
							</Link>
						</div>
						<div className="card-body">
							<div className="card-title mb-4">Property Sign in</div>
							<div className="form-group">
								<label className="form-label">Email<span className="ml-1" style={{ color: 'red' }}>*</span></label>
								<input

									type="email"
									className="form-control"
									name="email"
									aria-describedby="emailHelp"
									placeholder="Enter Email"
									onChange={this.onChange}
									onKeyPress={this.handleKeyPress}
									ref={this.firstInputRef}

								/>
								<div className="invalid-feedback" style={{ display: this.state.emailError ? "block" : 'none' }}>Email is required</div>
								<div className="invalid-feedback" style={{ display: this.state.emailValidError ? 'block' : 'none' }}>Enter valid email</div>
							</div>
							<div className='pass_forgot'>
								<label className="form-label">Password<span className="ml-1" style={{ color: 'red' }}>*</span></label>
								<span>
									<Link className="" to="/forgotpassword">
										Forgot Password?
									</Link>
								</span>
							</div>
							<div className="form-group" style={{ position: 'relative' }}>
								<input
									type={this.state.eye ? 'text' : 'password'}
									className="form-control"
									name="password"
									placeholder="Enter Password"
									onChange={this.onChange}
									style={{ position: 'relative', paddingRight: "40px" }}
									onKeyPress={this.handleKeyPress}
									ref={this.secondInputRef}
								/>
								<i class={this.state.eye ? "fa fa-eye log_icon" : "fa fa-eye-slash "} style={{ cursor: 'pointer', fontSize: '20px', position: 'absolute ', top: '9px', right: '12px', color: '#1c3078' }} onClick={this.viewClick}></i>
								<div className="invalid-feedback" style={{ display: this.state.passwordError ? "block" : 'none' }}>Password is required</div>
							</div>

							<ReCAPTCHA ref={this.state.recaptcha} sitekey={'6Lf39EcpAAAAAI-YwP1vnqDTjX_z9mFrBf5kF1eZ'} />
							<div className="form-check my-2">
								<input type="checkbox" className="form-check-input log_checkbox" id="exampleCheck1" style={{ borderRadius: '0px !important', fontSize: '14px', backgroundColor: "#1c3078", borderColor: "#1c3078" }} name="checked" checked={this.state.checked} onChange={this.onChangeChecked} />
								<label className="form-check-label rememer_pwd" for="exampleCheck1" style={{ cursor: "pointer" }}>Remember me</label>
							</div>
							<div className="form-footer">
								{this.state.saving ?
									<a className="btn login_btn btn-block" href="/" onClick={this.submit}>
										<i className="fa fa-spinner fa-spin mr-2"></i>login
									</a> :
									<a className="btn login_btn btn-block" href="/" onClick={this.submit} >
										<i className="fa fa-sign-in mr-2"></i>login
									</a>
								}
							</div>
						</div>
					</div>
				</div>
				<div className="auth_right">
					<Carousel>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider1.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Effective Property Maintenance simplified with MyHotel AI</h5>
								<p>Manage the day-to-day operations of your properties  with ease using AI-supported software.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider2.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Managing multiple properties? MyHotel AI is here to assist you</h5>
								<p>Ensuring the flawless operation of multiple properties at the same time could be challenging.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider3.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Track and monitor the performance of your hotels with real-time data.</h5>
								<p>Enhance the efficiency and profitability of your hotels by gauging their <br /> performance with accurate, auto-fetched data</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider4.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Fetch accurate data on the effectiveness of ticket resolution in your properties</h5>
								<p>Catalyze the ticket resolution process with an automated workflow.</p>
							</div>
						</Carousel.Item>
						<Carousel.Item interval={3000}>
							<img src="assets/images/slider5.png" className="img-fluid" alt="login page" />
							<div className='mt-3'>
								<h5>Assess the performance of your assets accurately with periodic reports.</h5>
								<p>Build a culture of data-based decision-making to give your customers a great stay.</p>
							</div>
						</Carousel.Item>
					</Carousel>
				</div>
			</div>
		);
	}
}

function mapDispatchToProps(dispatch) {
	return bindActionCreators({ AC_LOGIN_ADMIN }, dispatch)
}
export default connect(mapDispatchToProps)(Login)
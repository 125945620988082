import axios from "axios";
import React, { Component } from "react";
import { Form, Row } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import Select from "react-select";
import { bindActionCreators } from "redux";
import ImportedURL from "../../common/api";
import { Error, Success } from "../../common/swal";
import "react-multi-date-picker/styles/backgrounds/bg-dark.css";
import { onErrorImage } from "../../common/validate";
import { Phonenumber, Emailvalidate } from "../../common/validate";
import { AC_HANDLE_CHANGE_PMS_PRIVILEGES, AC_HANDLE_USER_CHANGE } from "../../actions/userAction";
import { Table, Button, Collapse } from 'react-bootstrap';

const initialState = {
    modalType: "Add",
    id: "",
    existingData: [],
    collapse: '',
};

class PMSPrivileges extends Component {
    constructor(props) {
        super(props);
        this.state = initialState;
    }

    changePrevilege = async (e, value, type) => {
        const { UserState, CategoryState } = this.props;
        const { checked } = e.target;
        const { _id, suboption } = value;
        const data = this.props.type == 'user' ? UserState.user : CategoryState.category;
        var pmsprivileges = data.pmsprivileges ? data.pmsprivileges : [];

        let findIndex = pmsprivileges.findIndex((e, i) => e.pmsprivilegesid == _id)
        if (findIndex !== -1) {
            pmsprivileges = [
                ...pmsprivileges.slice(0, findIndex),
                {
                    ...pmsprivileges[findIndex],
                    privileges: {
                        ...pmsprivileges[findIndex].privileges,
                        [type.suboption]: checked
                    }
                },
                ...pmsprivileges.slice(findIndex + 1)
            ]
        } else {
            pmsprivileges.push({ pmsprivilegesid: _id, privileges: { name: suboption, [type.suboption]: checked } })
        }
        this.props.HandleInputChange('pmsprivileges', pmsprivileges, this.props.type);
        this.setState({ oldprevileges: true });
        this.props.changePmsPrivileges(true);
        this.props.HandleInputChange('custompmsprivilege', true, this.props.type);
        this.props.setAllPMSStatus(pmsprivileges);
    }
    changeSubPrevilege = async (e, value, type, subtype) => {
        const { UserState, CategoryState } = this.props;
        const { checked } = e.target;
        const { _id, suboption } = value;
        const data = this.props.type == 'user' ? UserState.user : CategoryState.category;
        var pmsprivileges = data.pmsprivileges ? data.pmsprivileges : [];
        let findIndex = pmsprivileges.findIndex((e, i) => e.pmsprivilegesid == _id)
        if (findIndex !== -1) {
            let findSubIndex = (pmsprivileges[findIndex].privileges && pmsprivileges[findIndex].privileges.subprivileges) ? pmsprivileges[findIndex].privileges.subprivileges.findIndex((e, i) => e.name == subtype.suboption) : -1
            if (findSubIndex !== -1) {
                pmsprivileges = [
                    ...pmsprivileges.slice(0, findIndex),
                    {
                        ...pmsprivileges[findIndex],
                        privileges: {
                            ...pmsprivileges[findIndex].privileges,
                            subprivileges: [
                                ...pmsprivileges[findIndex].privileges.subprivileges.slice(0, findSubIndex),
                                {
                                    ...pmsprivileges[findIndex].privileges.subprivileges[findSubIndex],
                                    [type.suboption]: checked
                                },
                                ...pmsprivileges[findIndex].privileges.subprivileges.slice(findSubIndex + 1),
                            ]
                        }
                    },
                    ...pmsprivileges.slice(findIndex + 1)
                ]
            } else {
                if (pmsprivileges[findIndex].privileges.subprivileges) {
                    pmsprivileges = [
                        ...pmsprivileges.slice(0, findIndex),
                        {
                            ...pmsprivileges[findIndex],
                            privileges: {
                                ...pmsprivileges[findIndex].privileges,
                                subprivileges: [
                                    ...pmsprivileges[findIndex].privileges.subprivileges,
                                    { name: subtype.suboption, [type.suboption]: checked }
                                ]
                            }
                        },
                        ...pmsprivileges.slice(findIndex + 1)
                    ]
                } else {
                    pmsprivileges = [
                        ...pmsprivileges.slice(0, findIndex),
                        {
                            ...pmsprivileges[findIndex],
                            privileges: {
                                ...pmsprivileges[findIndex].privileges,
                                subprivileges: [{ name: subtype.suboption, [type.suboption]: checked }]
                            }
                        },
                        ...pmsprivileges.slice(findIndex + 1)
                    ]
                }
            }
        } else {
            pmsprivileges.push({ pmsprivilegesid: _id, privileges: { name: suboption, subprivileges: { name: subtype.suboption, [type.suboption]: checked } } })
        }
        this.props.HandleInputChange('pmsprivileges', pmsprivileges, this.props.type);
        this.setState({ oldprevileges: true });
        this.props.changePmsPrivileges(true);
        this.props.HandleInputChange('custompmsprivilege', true, this.props.type);
        this.props.setAllPMSStatus(pmsprivileges);
    }
    setAll = e => {
        const { checked } = e.target
        const { UserState, CategoryState, PMSPrivilegesState } = this.props;
        const pmsprivilegeslist = PMSPrivilegesState.pmsprevilegeslist;
        const data = this.props.type == 'user' ? UserState.user : CategoryState.category;
        this.setState({ oldprevileges: true });
        let arr = []
        if (pmsprivilegeslist && pmsprivilegeslist.length > 0) {
            pmsprivilegeslist.map((item) => {
                let obj = {}
                if (item.typesOption && item.typesOption.length > 0) {
                    item.typesOption.map((type) => {
                        obj[type] = checked;
                    })
                }
                let subprivileges = []
                if (item.privilegesubnameOption && item.privilegesubnameOption.length > 0) {
                    item.privilegesubnameOption.map((type) => {
                        subprivileges.push({ name: type, ...obj })
                    })
                }
                arr.push({
                    pmsprivilegesid: item._id,
                    privileges: { name: item.suboption, ...obj, subprivileges: subprivileges }
                })
            })
        }
        this.props.changePmsPrivileges(true);
        this.props.HandleInputChange('setChecked', checked, this.props.type);
        this.props.HandleInputChange('pmsprivileges', arr, this.props.type);
        this.props.HandleInputChange('custompmsprivilege', true, this.props.type);
    }
    resetOldPermission = () => {
        this.setState({ oldprevileges: false });
        this.props.changePmsPrivileges(false);
        this.props.HandleInputChange('pmsprivileges', this.props.existingData, this.props.type);
        this.props.HandleInputChange('setChecked', false, this.props.type);
        this.props.HandleInputChange('custompmsprivilege', true, this.props.type);
        this.props.setAllPMSStatus(this.props.existingData);
    }
    toggleCollapse = (index) => {
        if (this.state.collapse === index) {
            this.setState({ collapse: "" })
        } else {
            this.setState({ collapse: index })
        }
    };

    render() {
        const { AccountState, UserState, ConfigurationState, CategoryState, PMSPrivilegesState, type } = this.props;
        const account = AccountState.account;
        const data = type == 'user' ? UserState.user : CategoryState.category;
        const pmsprivilegeslist = PMSPrivilegesState.pmsprevilegeslist;
        const privilegestype = (ConfigurationState.configration && ConfigurationState.configration['pms-privileges-type']) ? ConfigurationState.configration['pms-privileges-type'] : [];
        const privilegessubtype = (ConfigurationState.configration && ConfigurationState.configration['pms-privileges-sub-name']) ? ConfigurationState.configration['pms-privileges-sub-name'] : [];
        let labelHeader = []


        return (
            <>
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-header set_permission_res" >
                            <h3 className="card-title">Set PMS Permissons</h3>
                            <div className="card-options add_card_options">
                                <div className="d-flex justify-content-between align-items-center">
                                    <ul className="nav nav-tabs page-header-tab"></ul>
                                    <div className="header-action">
                                        <div className="input-group">
                                            {ImportedURL.SUPERADMIN.includes(account.role) && <> {this.state.oldprevileges && <span className="input-group-btn mr-2">
                                                <button className="btn btn-icon btn-sm show-fetch" type="submit" onClick={this.resetOldPermission} >
                                                    <span className="fa fa-refresh mr-2"></span>Reset old permissions
                                                </button>
                                            </span>}
                                            </>}
                                            <label className="custom-switch">
                                                <input
                                                    type="checkbox"
                                                    checked={data.setChecked}
                                                    name="custom-switch-checkbox"
                                                    className="custom-switch-input"
                                                    onChange={this.setAll}
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <span className="custom-switch-indicator"></span>
                                                <span className="custom-switch-description" style={{ cursor: 'pointer' }} >
                                                    Set All
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="card-body card-footer" style={{ borderTop: "none" }}>
                            <div data-mdb-treetable-init="true" className={pmsprivilegeslist.length > 8 ? "Over_all_table_style table-responsive edit_user_table" : 'Over_all_table_style table-responsive edit_user_table_Custom'}>
                                <table className="table table-striped mb-0">
                                    <thead>
                                        <tr>
                                            <th style={{ textAlign: 'center' }}>Management</th>
                                            {
                                                privilegestype.map((item, i) => {
                                                    return (
                                                        <th key={i} style={{ textAlign: 'center' }}>{item.name}</th>
                                                    )
                                                })
                                            }
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            (pmsprivilegeslist && pmsprivilegeslist.length > 0)
                                                ?
                                                <>
                                                    {
                                                        pmsprivilegeslist.map((item, i) => {
                                                            let privilegesdata = (data.pmsprivileges && data.pmsprivileges.length > 0) ? data.pmsprivileges.find((e) => e.pmsprivilegesid == item._id) : '';
                                                            let isHeaderName = false
                                                            if (!labelHeader.includes(item.headername)) {
                                                                isHeaderName = true;
                                                                labelHeader.push(item.headername);
                                                            }
                                                            return (
                                                                <>
                                                                    <tr key={i} >
                                                                        <td style={{ position: 'relative' }}>
                                                                            <p className="user_permission">
                                                                                {(isHeaderName) ? item.headername : ''}
                                                                            </p>
                                                                            <p style={{ marginTop: "revert", textAlign: 'center' }}>
                                                                                <span onClick={(item.privilegesubnameid && item.privilegesubnameid.length > 0) ? () => this.toggleCollapse(i) : undefined} style={{ cursor: (item.privilegesubnameid && item.privilegesubnameid.length > 0) ? 'pointer' : 'none' }}>
                                                                                    {item.privilegename}
                                                                                    {item.ismobilestatus ? <i className="fa fa-mobile ml-2 mt-1 mobile_permission_icon" aria-hidden="true"></i> : ''}
                                                                                    {item.privilegesubnameid && item.privilegesubnameid.length > 0 ? (this.state.collapse === i ? <i className={`fa fa-caret-up mobile_permission_icon ${item.ismobilestatus ? "ml-4" : "ml-2"}`} aria-hidden="true"></i> : <i className={`fa fa-caret-down mt-1 mobile_permission_icon ${item.ismobilestatus ? "ml-4" : "ml-2"}`} aria-hidden="true"></i>) : ''}
                                                                                </span>
                                                                            </p>
                                                                        </td>
                                                                        {
                                                                            privilegestype.map((type, j) => {
                                                                                let checked = (privilegesdata && privilegesdata.privileges) ? (privilegesdata.privileges[type.suboption] ? true : false) : false;
                                                                                let showChecked = (item.typesid && item.typesid.length > 0) ? (item.typesid).includes(type._id) : false;
                                                                                return (
                                                                                    <td className="user_category_previledge">
                                                                                        {
                                                                                            showChecked ?
                                                                                                <>
                                                                                                    <label className="custom_control_user_previleges custom-checkbox">
                                                                                                        <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={checked} onChange={(e) => this.changePrevilege(e, item, type)} />
                                                                                                        <span className="custom-control-label"></span>
                                                                                                    </label>
                                                                                                </> : '---'
                                                                                        }
                                                                                    </td>
                                                                                )
                                                                            })
                                                                        }
                                                                    </tr>
                                                                    {
                                                                        this.state.collapse === i
                                                                            ?
                                                                            <>
                                                                                {
                                                                                    (privilegessubtype && privilegessubtype.length > 0)
                                                                                        ?
                                                                                        <>
                                                                                            {
                                                                                                privilegessubtype.map((subtype, k) => {
                                                                                                    let subprivilegesdata = (privilegesdata.privileges && privilegesdata.privileges.subprivileges && privilegesdata.privileges.subprivileges.length > 0) ? privilegesdata.privileges.subprivileges.find((e) => e.name == subtype.suboption) : '';

                                                                                                    return (
                                                                                                        <tr key={i} >
                                                                                                            <td style={{ position: 'relative' }}>
                                                                                                                <p style={{ marginTop: "revert", textAlign: 'center' }}>
                                                                                                                    {subtype.name}
                                                                                                                </p>
                                                                                                            </td>
                                                                                                            {
                                                                                                                privilegestype.map((type, j) => {
                                                                                                                    let checked = (subprivilegesdata) ? (subprivilegesdata[type.suboption] ? true : false) : false;
                                                                                                                    let showChecked = (item.typesid && item.typesid.length > 0) ? (item.typesid).includes(type._id) : false;
                                                                                                                    return (
                                                                                                                        <td className="user_category_previledge">
                                                                                                                            {
                                                                                                                                showChecked ?
                                                                                                                                    <>
                                                                                                                                        <label className="custom_control_user_previleges custom-checkbox">
                                                                                                                                            <input type="checkbox" className="custom-control-input" name="example-checkbox1" checked={checked} onChange={(e) => this.changeSubPrevilege(e, item, type, subtype)} />
                                                                                                                                            <span className="custom-control-label"></span>
                                                                                                                                        </label>
                                                                                                                                    </> : '---'
                                                                                                                            }
                                                                                                                        </td>
                                                                                                                    )
                                                                                                                })
                                                                                                            }
                                                                                                        </tr>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </>
                                                                                        : ""
                                                                                }
                                                                            </>
                                                                            : ''
                                                                    }
                                                                </>

                                                            )
                                                        })
                                                    }
                                                </> :
                                                <tr colspan={privilegestype.length + 1}>
                                                    <div className="card-body no_records text-center d-flex align-items-center justify-content-center">
                                                        <div style={{ maxWidth: "340px" }}>
                                                            <h5 className="mb-2">No permission given</h5>
                                                        </div>
                                                    </div>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}
const mapStateToProps = (state) => ({
    UserState: state.user,
    AccountState: state.account,
    LandingState: state.landing,
    ConfigurationState: state.configuration,
    PMSPrivilegesState: state.pmsprivileges,
    CategoryState: state.category,
});

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        {
            HandleInputChange: AC_HANDLE_CHANGE_PMS_PRIVILEGES,
        },
        dispatch
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(PMSPrivileges);

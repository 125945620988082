import React, { Component, forwardRef } from 'react';
import Select from 'react-select';
import ImportedURL from '../../common/api';
import { onErrorImage } from '../../common/validate';

class HotelSelectComponent extends Component {
    render() {
        const { options, onChange, value, selectRef } = this.props;
        return (
            <Select
                onChange={(e) => onChange(e)}
                options={options}
                value={value}
                formatOptionLabel={(e) => (
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        {e.label != 'All' && <img className='all_search_image_size contain_image' src={ImportedURL.FILEURL + e.logo} onError={onErrorImage} />}
                        <div style={e.label != 'All' ? { paddingLeft: '10px' } : {}}><span className='all_search_text_size' >{e.label}</span></div>
                    </div>
                )}
                ref={selectRef}
            />
        );
    }
}
export default HotelSelectComponent;